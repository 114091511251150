import React from "react";
import styled from "styled-components";
import { tablet } from "styles";

const FilterBubbleTriangle = () => {
  return (
    <Triangle
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32px"
      height="28px"
      viewBox="0 0 32 28"
      version="1.1"
    >
      <title>Pointer</title>
      <defs>
        <path
          d="M6.63674493,21.0241249 C6.63674493,20.4021923 6.78174013,19.7888241 7.06025577,19.2327403 L12.5835425,8.20495672 C13.5728455,6.2297163 15.9760828,5.43045609 17.9513232,6.41975908 C18.7235356,6.80652316 19.3498277,7.43283258 19.7365704,8.20505571 L25.0757903,18.8754835 C25.4183993,19.5374312 25.5943578,20.2017058 25.6036659,20.8683075"
          id="path-1"
        />
      </defs>
      <g id="Rule-Search-V2.1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Pointer" transform="translate(16.120205, 13.509750) rotate(0.800000) translate(-16.120205, -13.509750) ">
          <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1" />
          <use stroke="#4223FF" strokeWidth="1" fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
        </g>
      </g>
    </Triangle>
  );
};

const Triangle = styled.svg`
  position: absolute;
  top: -20.7px;
  left: 495px;

  ${tablet} {
    left: 45px;
  }
`;

export default FilterBubbleTriangle;
