import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { hideForPrint } from "styles";
import { selectIsApplicationReadyToView } from "redux/selectors/application";
import useAuth from "hooks/useAuth";
import MyDownloadLink from "shared/application/DownloadLink";
import { GhostButton } from "shared/SimplyBind/styles";
import { ReactComponent as ReadyIcon } from "images/ready.svg";

interface PropTypes {
  readyToViewApplication: boolean;
}

const ActionButtons = ({ readyToViewApplication }: PropTypes) => {
  const { isIAUser, isNonlicensedUser } = useAuth();
  const params: { applicationId: string } = useParams();

  return (
    <ButtonContainer>
      <MyDownloadLink />
      {isIAUser && !isNonlicensedUser ? (
        <StyledGhostButton disabled={!readyToViewApplication} href={`/snapshot/${params.applicationId}`}>
          <StyledReadyIcon isDisabled={!readyToViewApplication}>
            <ReadyIcon title="File-Icon" width="24" height="24" transform="translate(-1,-3)" />
          </StyledReadyIcon>
          Quote Snapshot
        </StyledGhostButton>
      ) : null}
    </ButtonContainer>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  readyToViewApplication: selectIsApplicationReadyToView(state),
});

const ButtonContainer = styled.div`
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 13px;
  font-weight: 600;
  color: ${(props) => props.theme.blue};
  height: 36px;
  display: flex;
  align-items: center;
  column-gap: 16px;
  ${hideForPrint}
`;

const StyledGhostButton = styled(GhostButton)<{ disabled: boolean }>`
  color: ${(props) => props.theme.colors.secondary.darkTen};
  border: 1px solid ${(props) => props.theme.colors.secondary.darkTen};
  padding: 4px 16px 8px 12px;
  height: 32px;

  ${(props) =>
    props.disabled &&
    `
      color: ${props.theme.regularGray};
      border: 1px solid ${props.theme.regularGray};
      cursor: not-allowed;
      :hover {
        background: inherit;
        color: ${props.theme.regularGray};
      }
    `}

  :hover {
    ${(props) =>
      !props.disabled &&
      `
      border: 1px solid ${props.theme.white};
      path {
        fill: ${props.theme.white};
      }
    `}
  }
`;

const StyledReadyIcon = styled.span<{ isDisabled: boolean }>`
  path {
    fill: ${(props) => (props.isDisabled ? props.theme.regularGray : props.theme.colors.secondary.darkTen)};
  }
`;

export default connect(mapStateToProps)(ActionButtons);
