import Api from "utils/api";
import { useQuery } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const useGetPaymentSummary = (policyNumber: string, queryOptionOverrides?: { enabled: boolean }) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const paymentSummary = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return await api.getPaymentSummary(policyNumber);
  };

  return useQuery({
    queryKey: ["payment_summary", policyNumber],
    queryFn: paymentSummary,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
    staleTime: twentyFourHoursInMs,
    ...queryOptionOverrides,
  });
};

export default useGetPaymentSummary;
