import { addDays, compareDesc, isBefore, set } from "date-fns";

export const checkValidDate = (selectedDate: string, today: Date = new Date()) => {
  const year = new Date(selectedDate).getUTCFullYear();
  const month = new Date(selectedDate).getUTCMonth();
  const date = new Date(selectedDate).getUTCDate();

  const isPastFutureDatingPeriod = Boolean(
    isBefore(
      addDays(today, 90),
      set(new Date(year, month, date), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
    )
  );
  const isInPast = Boolean(
    compareDesc(
      set(today, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
      set(new Date(year, month, date), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
    ) === -1
  );

  if (!isPastFutureDatingPeriod && !isInPast) {
    return true;
  }

  return false;
};
