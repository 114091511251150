import { RuleOptions, Condition, Rule, Property } from "../Shared/types";
import { Dictionary } from "lodash";
import { ruleOperatorOptions } from "./constants";
import { stateAbbrevOptions } from "utils/constants";
import { cloneDeep, compact, isEmpty } from "lodash";

//adds a path string to nested rules for easy indexing
export const updateNestedPathNames = (rule: Rule) => {
  const assignPaths = (obj: Dictionary<any>, propStr = "") => {
    if (obj)
      Object.entries(obj).forEach(([key, val]) => {
        const nestedPropStr = propStr + (propStr ? "." : "") + key;
        if (typeof val === "object") assignPaths(val, nestedPropStr);
        if (key === "condition" || key === "variable") {
          obj.path = propStr;
          if (!obj.uniqueKey) obj.uniqueKey = Math.random();
        }
      });
  };
  assignPaths(rule);
  return rule;
};

//removes a trailing path portion to access object parents.
//subrules.0.options.0 -> subrules.0
export const getParentPath = (path: string, amountToSlice: number) => {
  return path.split(".").slice(0, amountToSlice).join(".");
};

export const buildConditionString = (ruleOption: RuleOptions) => {
  const conditionString = combineAggregatedConditions(ruleOption);
  return ruleOption.isNegated ? `not ( ${conditionString} )` : conditionString;
};

export const convertBoolStringsToUpperCase = (conditionObj: Condition) => {
  if (
    ["!=", "="].includes(conditionObj.conditionOperator) &&
    ["false", "true"].includes(conditionObj.conditionTarget.toLowerCase())
  ) {
    return conditionObj.conditionTarget.toUpperCase();
  } else {
    return conditionObj.conditionTarget;
  }
};

export const formatConditionObjectToString = (conditionObj: any) => {
  const conditionTarget = convertBoolStringsToUpperCase(conditionObj);
  switch (conditionObj.conditionOperator) {
    case "not in":
      return `not (in (${conditionTarget}))`;
    case "not in states":
      return `not (in (${conditionTarget}))`;
    case "does not contain any":
      return `not (contains any (${conditionTarget}))`;
    case "does not contain all":
      return `not (contains all (${conditionTarget}))`;
    case "not lookin":
      return `not (lookin (${conditionTarget}))`;
    case "in":
    case "contains any":
    case "contains all":
    case "lookin":
      return `${conditionObj.conditionOperator} (${conditionTarget})`;
    default:
      return `${conditionObj.conditionOperator} ${conditionTarget}`;
  }
};

export const combineAggregatedConditions = (ruleOption: RuleOptions) => {
  let combinedConditions = "";
  if (ruleOption.conditions?.length === 1) {
    combinedConditions = formatConditionObjectToString(ruleOption.conditions[0]);
  } else {
    const conditionString = ruleOption.conditions?.map((conditionObj: any) => {
      return `${formatConditionObjectToString(conditionObj)}`;
    });
    combinedConditions = `${ruleOption.aggregationType} (${conditionString?.join(", ")})`;
  }

  return ruleOption.isNegated ? `not(${combinedConditions})` : combinedConditions;
};

export const isNotCommaDelimitedList = (operator: string, inputValue: string) => {
  let hasError = false;
  if (operator.includes("in") && inputValue) {
    const parts = inputValue.trim().split(",");
    const partsWithSpaces = parts.filter((p) => p.trim().indexOf(" ") !== -1);
    hasError = partsWithSpaces.length > 0;
  }
  return hasError;
};

export const removeSpaces = (value: string) => {
  return value.replace(/ /g, "");
};

export const removeNonAlphaNumericChars = (value: string) => {
  return value.replace(/[^0-9a-z]/gi, "");
};

const getTextInsideParentheses = (str: string) => {
  return str.match(/\(([^)]+)\)/)?.[1] ?? "";
};

//Condition Deconstruction Utils

export const removeOuterNesting = (typeToRemove: string, str: string) => {
  str = str.replace(typeToRemove, "");
  //remove '(',  ')'
  str = str.trim().slice(0, -1).slice(1).trim();
  return str;
};

export const deconstructOuterNesting = (conditionStr: string) => {
  let parts = conditionStr.split("(");
  let aggregationType = "";
  let isNegated = false;
  if (parts[0].trim() === "not") {
    isNegated = true;
    conditionStr = removeOuterNesting("not", conditionStr);
  } else {
    aggregationType = parts[0].trim();
    conditionStr = removeOuterNesting(parts[0], conditionStr);
  }
  if (parts[1].trim() === "and" || parts[1].trim() === "or") {
    aggregationType = parts[1].trim();
    conditionStr = removeOuterNesting(parts[1], conditionStr);
  }

  return { string: conditionStr, aggregationType: aggregationType, isNegated: isNegated };
};

export const findNegatedConditions = (str: string) => {
  //regex matches pattern "not(" <text> ")""
  let conditions = str.match(/not\s*(.*?) ?\)?\)/g)?.map(function (val: any) {
    return val.trim();
  });
  return conditions;
};

export const findNonNegatedConditions = (str: string, negatedConditions: string[]) => {
  //filter found negated conditions out of condition string to reveal what's left over.
  negatedConditions?.forEach((c) => (str = str.replace(c, "")));

  const findListTypeConditions = () => {
    return ["lookin", "contains any", "contains all", "in"].reduce((accum: string[], operator: string) => {
      const listRegex = new RegExp(operator + "\\s*\\((.*?)\\)", "g");
      const listConditions = str.match(listRegex);
      listConditions?.forEach((conditionStr) => (str = str.replace(conditionStr, "")));
      return [...accum, ...(listConditions ?? [])];
    }, []);
  };

  let listTypeConditions: string[] = findListTypeConditions();
  //Once all list conditions have been extracted, it's safe to split the rest by a comma.
  const filtered = str
    .split(",")
    ?.map((c) => c.trim())
    .filter((c) => c);
  return [...filtered, ...listTypeConditions];
};

export const findOperator = (conditionStr: string) => {
  const operatorChars = ["<", ">", "=", "!"];
  return ruleOperatorOptions.find((r) => {
    //avoid false positives in cases such as "=" returning as a match for "!="
    let strWithoutOperator = conditionStr.replace(r.value, "");
    const cleanSplit = operatorChars.every((char) => !strWithoutOperator.includes(char));
    return cleanSplit;
  });
};

export const conditionStringsToObjects = (allConditions: any[]) => {
  const conditions: Condition[] = [];
  allConditions.forEach((c) => {
    let operator: string,
      target: string = "";
    if (c.substring(0, 3) !== "not") {
      //in (1,2,3)
      if (c.substring(0, 2) === "in") {
        operator = "in";
        target = getTextInsideParentheses(c);
      } else if (c.substring(0, 12) === "contains all" || c.substring(0, 12) === "contains any") {
        operator = c.substring(0, 12);
        target = getTextInsideParentheses(c);
      } else if (c.substring(0, 6) === "lookin") {
        operator = "lookin";
        target = getTextInsideParentheses(c);
      } else {
        operator = findOperator(c)?.value ?? "";
        target = c.replace(operator, "");
      }
    } else {
      if (c !== "notnull") c = removeOuterNesting("not", c);
      //not (in (1,2,3) )
      if (c.substring(0, 2) === "in") {
        target = removeOuterNesting("in", c);
        //check to see if all values are states values.
        const listItems = target.split(",").map((s) => s.trim());
        const allStates = stateAbbrevOptions.map(({ value }) => value);
        const allItemsAreStates = listItems.every((state) => allStates.includes(state));
        operator = allItemsAreStates ? "not in states" : "not in";
      } else if (c.substring(0, 12) === "contains any") {
        target = removeOuterNesting("contains any", c);
        operator = "does not contain any";
      } else if (c.substring(0, 12) === "contains all") {
        target = removeOuterNesting("contains all", c);
        operator = "does not contain all";
      } else if (c.substring(0, 6) === "lookin") {
        target = removeOuterNesting("lookin", c);
        operator = "not lookin";
      } else if (c === "notnull") {
        operator = "notnull";
      } else {
        //not (=/!=/>/</>=/<= 3)
        const operatorObj = findOperator(c);
        target = c.replace(operatorObj?.value ?? "", "");
        operator = operatorObj?.negated ?? "";
      }
    }
    conditions.push({ conditionOperator: operator, conditionTarget: target.trim() });
  });
  return conditions;
};

export const deconstructCondition = (conditionStr: string) => {
  const conditionDetails: { isNegated: boolean; aggregationType: string | null; conditions: Condition[] } = {
    isNegated: false,
    aggregationType: null,
    conditions: [],
  };
  //deconstructs condition strings for editing.
  //complex test string: conditionStr = "not ( and ( not ( in ( 1,2 ) ) , not (= 2) ,  = platinum) )"
  //condition: 'and (> 3, !=5)' => conditions: [{conditionOperator: '>', conditionTarget: '3'}, {conditionOperator: '!=', conditionTarget: '5'}];
  const isNested =
    conditionStr.match(/or\s*\(/) ||
    conditionStr.match(/and\s*\(/) ||
    (conditionStr.match(/not\s*\(/g) || []).length > 1;

  if (isNested) {
    const destructuredProperties = deconstructOuterNesting(conditionStr);
    conditionStr = destructuredProperties.string;
    conditionDetails.isNegated = destructuredProperties.isNegated;
    conditionDetails.aggregationType = destructuredProperties.aggregationType;
  }

  const negatedConditions: string[] = findNegatedConditions(conditionStr) ?? [];
  const leftoverConditions: string[] = findNonNegatedConditions(conditionStr, negatedConditions);
  const allConditions: string[] = [...negatedConditions, ...leftoverConditions];
  conditionDetails.conditions = conditionStringsToObjects(allConditions);

  return conditionDetails;
};

export const deconstructControl = (controlStr: string) => {
  const parts = controlStr?.match(/^(\S+)\s(.*)/)?.slice(1);
  return { controlAction: parts?.[0], controlTarget: parts?.[1] };
};

export const deconstructRuleObject = (obj: Dictionary<any>) => {
  for (let key in obj) {
    if (obj[key]?.hasOwnProperty("condition")) {
      const deconstructedConditions = deconstructCondition(obj[key].condition);
      obj[key].conditions = deconstructedConditions.conditions;
      obj[key].isNegated = deconstructedConditions.isNegated;
      obj[key].aggregationType = deconstructedConditions.aggregationType;
      delete obj[key].condition;
    }
    if (obj[key]?.hasOwnProperty("control")) {
      const deconstructedControl = deconstructControl(obj[key].control);
      obj[key].controlAction = deconstructedControl.controlAction;
      obj[key].controlTarget = deconstructedControl.controlTarget;
      delete obj[key].control;
    }
    if (key === "metadata") {
      obj.properties = Object.entries(obj.metadata).map(([key, value]) => ({ name: key, value: value }));
    }
    if (typeof obj[key] === "object") {
      deconstructRuleObject(obj[key]);
    }
  }
  return obj as Rule;
};

export const deconstructRules = (rule: Rule) => {
  return deconstructRuleObject(updateNestedPathNames(rule));
};

export const wrapInParentheses = (str: string) => {
  if (str[0] === "(" && str[str.length - 1] === ")") {
    return str;
  } else {
    return "(" + str + ")";
  }
};

const mappedMetaDataObject = (properties: Property[]) => {
  return Object.assign(
    {},
    ...properties.map((property: Property) => {
      return { [property.name]: property.value };
    })
  );
};

//combines properties that are broken up for rendering (e.g. !=, 3).
export const combineControlAndCondition = (ruleOption: RuleOptions, key: string) => {
  if (key === "condition" || key === "control") {
    switch (key) {
      case "control":
        ruleOption[key] =
          ruleOption.controlAction && ruleOption.controlTarget
            ? `${ruleOption.controlAction} ${wrapInParentheses(ruleOption.controlTarget)}`
            : null;
        break;
      case "condition":
        ruleOption[key] = combineAggregatedConditions(ruleOption);
        break;
    }
  }
};

const removeRenderKeys = (obj: Dictionary<any>) => {
  const keysToRemove = [
    "conditionOperator",
    "conditionTarget",
    "controlAction",
    "controlTarget",
    "path",
    "isNegated",
    "conditions",
    "uniqueKey",
    "aggregationType",
    "properties",
  ];
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && !isEmpty(obj[key])) {
      removeRenderKeys(obj[key]);
    } else {
      keysToRemove.forEach((key) => {
        delete obj[key as keyof RuleOptions];
      });
    }
  });
  return obj;
};

export const cleanRuleForPost = (rulesObj: Rule) => {
  const rules = cloneDeep(rulesObj);
  const clean = (obj: Dictionary<any>) => {
    if (obj)
      Object.keys(obj).forEach((key: string) => {
        if (key === "conditions") {
          combineControlAndCondition(obj as RuleOptions, "condition");
        } else if (key === "controlAction" || key === "controlTarget") {
          combineControlAndCondition(obj as RuleOptions, "control");
        } else if (key === "properties") {
          obj.metadata = mappedMetaDataObject(obj[key]);
        } else if (key === "tags" && !obj["tags"]?.length) {
          delete obj["tags"];
        }
        if (typeof obj[key] === "object") clean(obj[key]);
      });
    return obj;
  };
  return removeRenderKeys(clean(rules)) as Rule;
};

export const removeEmpty = (obj: Dictionary<any>) => {
  for (let key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key] = compact(obj[key]);
    }
    if (typeof obj[key] === "object") removeEmpty(obj[key]);
  }
};
