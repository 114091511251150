import React from "react";
import styled from "styled-components";
import { policyIDParser } from "utils/policyIDParser";

interface ClaimOverviewProps {
  policyID: string;
  description: string;
}

const ClaimOverview = ({ policyID, description }: ClaimOverviewProps) => {
  const { policyType, policyCarrier } = policyIDParser(policyID);

  return (
    <FormContainer>
      <p>
        Please fill out all of the information that you have available at this time. We will reach out to you to verify
        the information and start the claims process.
      </p>
      <p>
        <b>Policy ID:</b> {policyID}
      </p>
      <p>
        <b>Policy Type:</b> {policyType}
      </p>
      <p>
        <b>Policy Carrier:</b> {policyCarrier}
      </p>
      <p>
        <b>Claim Description:</b> {description}
      </p>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  min-width: 550px;
  padding: 0 25px;
`;

export default ClaimOverview;
