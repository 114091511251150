import { Text, View, StyleSheet, Rect } from "@react-pdf/renderer";
import { Svg } from "utils/proposal-pdf/SvgPatch";
import { theme } from "styles";

interface FormsRowPropTypes {
  formName: string;
  formNumber: string;
}

const chunkSubstr = (str: string, size: number) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }
  return chunks;
};

function breakName(word: string): string[] {
  if (word.length > 26) {
    return chunkSubstr(word, 26);
  } else {
    return [word];
  }
}

const FormsRows = ({ formName, formNumber }: FormsRowPropTypes) => (
  <View>
    <View style={styles.rowContainer}>
      <View style={styles.nameContainer}>
        <Text>{formName.toUpperCase()}</Text>
      </View>
      <View style={styles.numberContainer}>
        <Text hyphenationCallback={(e) => breakName(e)}>{formNumber}</Text>
      </View>
    </View>
    <Svg style={styles.line}>
      <Rect x="" y="" width="537px" height="1px" fill={theme.colors.neutral.light} />
    </Svg>
  </View>
);

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
    minHeight: 26,
    fontFamily: theme.font.typeface.secondary,
  },
  nameContainer: {
    width: 387,
    fontSize: "9px",
    marginRight: 3,
    paddingLeft: 8,
  },
  numberContainer: {
    width: 150,
    fontSize: "9px",
    marginLeft: 6,
    marginRight: 1,
  },
  line: {
    height: "1px",
  },
});

export default FormsRows;
