import React from "react";
import styled from "styled-components";
import { ReactComponent as TrashCanSVG } from "images/trashcan.svg";

interface DeleteButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  ariaLabel: string;
}

const DeleteButton = ({ onClick, isDisabled, ariaLabel }: DeleteButtonProps) => {
  return (
    <StyledButton
      aria-label={ariaLabel}
      disabled={isDisabled}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <TrashCanIcon isDisabled={isDisabled}>
        <TrashCanSVG height="16px" width="16px" title="Delete" />
      </TrashCanIcon>
    </StyledButton>
  );
};

const TrashCanIcon = styled.span<{ isDisabled?: boolean }>`
  path {
    fill: ${(props) => (props.isDisabled ? "grey" : "inherit")};
  }
`;

const StyledButton = styled.button`
  background: none;
  height: fit-content;
  padding: 0 0.5em;
`;

export default DeleteButton;
