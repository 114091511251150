import AirKitEmbed from "ia/AirKitEmbed";
import React from "react";
import ModalBasic from "shared/ModalBasic";
import { ModalContentContainer } from "shared/ModalComponents";
import styled from "styled-components";

interface PropTypes {
  modalIsOpen: boolean;
  closeModal: () => void;
  policy: Policy;
  email?: string;
}

const CreateCOIModal = ({ policy, modalIsOpen, closeModal, email }: PropTypes) => {
  return (
    <ModalBasic
      isOpen={modalIsOpen}
      closeModal={closeModal}
      label="create-coi"
      title="Generate Certificate of Insurance"
    >
      <ModalContainer>
        <AirKitEmbed
          body={{ policyNumber: policy.policyNumber, agent: email }}
          url={process.env.REACT_APP_AIRKIT_COI_URL as string}
          name="create-coi-modal-iframe"
        />
      </ModalContainer>
    </ModalBasic>
  );
};

const ModalContainer = styled(ModalContentContainer)`
  overflow: hidden;
  padding: 0;
`;

export default CreateCOIModal;
