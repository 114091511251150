import { useMutation } from "@tanstack/react-query";
import Api from "utils/api";
import useAuth from "./useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

type Options = {
  onSuccess: (res: any) => void;
};

const useDeleteAgencyLogo = (agencyId: string, options: Options) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const deleteAgencyLogo = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api.agencyLogo.delete(agencyId);
  };

  return useMutation({ mutationFn: deleteAgencyLogo, ...options });
};

export default useDeleteAgencyLogo;
