import { createSlice } from "@reduxjs/toolkit";
import { EndorsementPackages } from "types/enums";

const initialState: ApplicationProps = {
  form: {
    professionalLiability: {},
    limitDamagePremRented: 50000,
    endorsements: {
      endorsementPackage: EndorsementPackages.None,
    },
  },
  industryName: "",
  naic: 0,
  networkError: null,
  quoteErrors: [],
  availablePolicyTypes: [],
  bindable: false,
  quote: {},
  loadingQuote: false,
  updatingQuote: false,
  twoFourLimitsAvailable: false,
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    updateApplicationForm: (state, { payload }) => {
      state.form = { ...state.form, ...payload };
    },
    updateApplicationFormContactDetails: (state, { payload }) => {
      state.form = { ...state.form, ...payload };
    },
    setApplicationFromServer: (state, { payload }) => {
      state.form = payload;
    },
    setSimplybindApplicationFromServer: (state, { payload }) => {
      state.form = { ...state.form, ...payload };
    },
    updateApplicationFormIndustry: (state, { payload }) => {
      const zip = state.form.locations?.[0]?.zip;
      const locationState = state.form.locations?.[0]?.state;

      if (state.form.industryId !== payload) {
        state.form.industryId = payload.industryId;
        state.form.akHash = payload.akHash;

        if (zip && !locationState) {
          state.form.locations = [{ zip: state.form.locations?.[0]?.zip }];
        } else if (locationState && !zip) {
          state.form.locations = [{ state: state.form.locations?.[0]?.state }];
        } else if (zip && locationState) {
          state.form.locations = [{ zip: state.form.locations?.[0]?.zip, state: state.form.locations?.[0]?.state }];
        } else {
          state.form.locations = [];
        }
        state.form.applicationTypes = [];
        state.form.endorsements = {};
        state.quote = {};
      }
    },
    updateApplicationFormLocation: (state, { payload }) => {
      if (state.form.locations && state.form.locations.length > 0) {
        state.form.locations[0] = { ...state.form.locations[0], ...payload };
      } else {
        state.form.locations = [];
        state.form.locations[0] = payload;
      }
    },
    updateApplicationFormAddPreviousLoss: (state, { payload }) => {
      if (state.form.previousLosses) {
        const previousLosses = state.form.previousLosses ? [...state.form.previousLosses, payload] : [payload];
        state.form.previousLosses = previousLosses;
      } else {
        state.form.previousLosses = [];
        state.form.previousLosses = [payload];
      }
    },
    updateApplicationFormRemovePreviousLoss: (state, { payload }) => {
      if (state.form.previousLosses && state.form.previousLosses.length > 0) {
        state.form.previousLosses = state.form.previousLosses.filter((_, i) => i !== payload) || [];
      }
    },
    updateApplicationFormClearPreviousLoss: (state) => {
      state.form.previousLosses = [];
    },

    updateApplicationFormAuto: (state, { payload }) => {
      const hiredAutoAndNonOwnedAuto =
        payload === true
          ? {
              hiredCoverage: "hiredAutoLiability",
              nonOwnedCoverage: "nonOwnedAutoLiabilityWithoutDeliveryService",
              shouldInclude: true,
            }
          : payload || null;

      state.form.endorsements = { ...state.form.endorsements, hiredAutoAndNonOwnedAuto };
    },

    updateApplicationFormResetPreviousLosses: (state) => {
      state.form.previousLosses = undefined;
    },

    updateApplicationFormIndividualAdditionalInsured: (state, { payload }) => {
      if (state.form?.endorsements) {
        const additionalInsureds = state.form.endorsements?.additionalInsureds;
        let updatedAdditionalInsureds: AdditionalInsured[] = [];
        if (Array.isArray(additionalInsureds)) {
          const isEdit = Boolean(payload.id !== undefined);
          if (isEdit) {
            updatedAdditionalInsureds = additionalInsureds;
            updatedAdditionalInsureds[payload.id] = payload;
          } else {
            updatedAdditionalInsureds = [...additionalInsureds, payload];
          }
        } else {
          updatedAdditionalInsureds = [payload];
        }
        if (payload?.name !== "") {
          updatedAdditionalInsureds = updatedAdditionalInsureds.filter(
            (additionalInsured) => additionalInsured.name !== ""
          );
        }
        state.form.endorsements.additionalInsureds = updatedAdditionalInsureds;
      } else {
        state.form.endorsements = {};
        state.form.endorsements.additionalInsureds = [payload];
      }
    },
    updateApplicationFormDeleteIndividualAdditionalInsured: (state, { payload }) => {
      if (state.form?.endorsements?.additionalInsureds) {
        const additionalInsureds = state.form.endorsements.additionalInsureds;
        additionalInsureds.splice(payload.id, 1);
        state.form.endorsements.additionalInsureds =
          additionalInsureds.length > 0
            ? additionalInsureds
            : [{ name: "", email: "", street: "", city: "", state: "", zip: "" }];
      }
    },
    removeApplicationFormIndividualAdditionalInsured: (state) => {
      const endorsements = state.form.endorsements;
      delete endorsements?.additionalInsureds;
      state.form.endorsements = endorsements;
    },
    updateApplicationFormBlanketAdditionalInsured: (state, { payload }) => {
      if (state.form?.endorsements) {
        state.form.endorsements = { ...state.form?.endorsements, ...payload };
      } else {
        state.form.endorsements = payload;
      }
    },
    removeApplicationFormBlanketAdditionalInsured: (state) => {
      const endorsements = state.form.endorsements;
      delete endorsements?.additionalInsuredsPrimaryAndNonContributory;
      delete endorsements?.additionalInsuredsWaiverOfTransfer;
      delete endorsements?.additionalInsuredsConstructionContractRequirementEndorsement;
      delete endorsements?.additionalInsuredsManagersOrLessorsPremisesEndorsement;
      delete endorsements?.additionalInsuredsLessorOfLeasedEquipmentEndorsement;
      state.form.endorsements = endorsements;
    },

    setAvailablePolicyTypes: (state, { payload }) => {
      state.availablePolicyTypes = payload;
    },
    updateIndustryName: (state, { payload }) => {
      state.industryName = payload;
    },
    updateNaic: (state, { payload }) => {
      state.naic = payload;
    },
    setApplicationError: (state, { payload }) => {
      state.networkError = payload;
    },
    updateApplicationFormPL: (state, { payload }) => {
      state.form.professionalLiability = { ...state.form.professionalLiability, ...payload };
    },
    updateApplicationFormEndorsementsPackage: (state, { payload }) => {
      if (state.form.endorsements) {
        state.form.endorsements.endorsementPackage = payload;
      } else {
        state.form.endorsements = {};
        state.form.endorsements.endorsementPackage = payload;
      }
    },
    updateLiabilityEdgeExtensionPackage: (state) => {
      const currentCheckboxState = state.form.endorsements?.liabilityEdgeEndorsement?.shouldInclude;
      state.form.endorsements = {
        ...state.form.endorsements,
        liabilityEdgeEndorsement: { shouldInclude: !currentCheckboxState },
      };
    },
    updateEdgeCompleteExtensionPackage: (state) => {
      const currentCheckboxState = state.form.endorsements?.edgeCompleteEndorsement?.shouldInclude;
      state.form.endorsements = {
        ...state.form.endorsements,
        edgeCompleteEndorsement: { shouldInclude: !currentCheckboxState },
      };
    },
    updateBusinessInterruptionExtensionPackage: (state) => {
      const currentCheckboxState = state.form.endorsements?.businessInterruptionExtensionEndorsement?.shouldInclude;
      state.form.endorsements = {
        ...state.form.endorsements,
        businessInterruptionExtensionEndorsement: { shouldInclude: !currentCheckboxState },
      };
    },
    updateContractorsExtensionExtensionPackage: (state) => {
      const currentCheckboxState = state.form.endorsements?.contractorExtensionEndorsement?.shouldInclude;
      state.form.endorsements = {
        ...state.form.endorsements,
        contractorExtensionEndorsement: { shouldInclude: !currentCheckboxState },
      };
    },
    clearEndorsements: (state) => {
      state.form.endorsements = {};
    },
    updateLiquorLiabilityCoverage: (state, { payload }) => {
      const liquorLiabilityEndorsement = payload;
      state.form.endorsements = { ...state.form.endorsements, liquorLiabilityEndorsement };
    },
    updateRestaurantCoverage: (state, { payload }) => {
      const restaurantCoverage = payload;
      state.form.endorsements = { ...state.form.endorsements, restaurantCoverage };
    },
    updateEquipmentBreakdownCoverage: (state, { payload }) => {
      const equipmentBreakdownEndorsement = payload;
      state.form.endorsements = { ...state.form.endorsements, equipmentBreakdownEndorsement };
    },
    updateFloodCoverage: (state, { payload }) => {
      const floodEndorsement = payload;
      state.form.endorsements = { ...state.form.endorsements, floodEndorsement: { shouldInclude: floodEndorsement } };
    },
    updateCanadaEquipmentBreakdownCoverage: (state, { payload }) => {
      const canadaEquipmentBreakdownEndorsement = payload;
      state.form.endorsements = {
        ...state.form.endorsements,
        canadaEquipmentBreakdownEndorsement: { shouldInclude: canadaEquipmentBreakdownEndorsement },
      };
    },
    updateEarthquakeShockEndorsement: (state, { payload }) => {
      const earthquakeShockEndorsement = payload;
      state.form.endorsements = {
        ...state.form.endorsements,
        earthquakeShockEndorsement: { shouldInclude: earthquakeShockEndorsement },
      };
    },
    updateCrimeEndorsement: (state, { payload }) => {
      const crimeEndorsement = payload;
      state.form.endorsements = { ...state.form.endorsements, crimeEndorsement: { shouldInclude: crimeEndorsement } };
    },
    updatePrivacyBreachEndorsement: (state, { payload }) => {
      const privacyBreachEndorsement = payload;
      state.form.endorsements = {
        ...state.form.endorsements,
        privacyBreachEndorsement: { shouldInclude: privacyBreachEndorsement },
      };
    },
    updateNonOwnedAutomobileEndorsement: (state, { payload }) => {
      const nonOwnedAutomobileCoveragesEndorsement = payload;
      state.form.endorsements = {
        ...state.form.endorsements,
        nonOwnedAutomobileCoveragesEndorsement: { shouldInclude: nonOwnedAutomobileCoveragesEndorsement },
      };
    },
    updateProfitsActualLossSustainedEndorsement: (state, { payload }) => {
      const profitsActualLossSustainedEndorsement = payload;
      state.form.endorsements = {
        ...state.form.endorsements,
        profitsActualLossSustainedEndorsement: { shouldInclude: profitsActualLossSustainedEndorsement },
      };
    },
    updateSewerBackUpEndorsement: (state, { payload }) => {
      const sewerBackUpEndorsement = payload;
      state.form.endorsements = {
        ...state.form.endorsements,
        sewerBackUpEndorsement: { shouldInclude: sewerBackUpEndorsement },
      };
    },
    updateLegalGuardInformationServiceEndorsement: (state, { payload }) => {
      const legalGuardInformationServiceEndorsement = payload;
      state.form.endorsements = {
        ...state.form.endorsements,
        legalGuardInformationServiceEndorsement: { shouldInclude: legalGuardInformationServiceEndorsement },
      };
    },

    updateContractorsInstallationAndTools: (state, { payload }) => {
      state.form.endorsements = { ...state.form.endorsements, contractorsInstallationAndTools: payload };
    },
    updateLimitDamagePremRented: (state, { payload }) => {
      if (typeof payload === "number" && payload < 50_000) {
        return;
      } else {
        const limitDamagePremRented = typeof payload === "number" ? payload - 50_000 : payload;
        state.form = { ...state.form, limitDamagePremRented };
      }
    },
    updateApplicationFormStartDate: (state, { payload }) => {
      state.form.policyStartDate = payload;
    },
    resetApplication: (state) => {
      state.form = {};
      state.quote = {};
      state.availablePolicyTypes = [];
      state.networkError = null;
      state.updatingQuote = false;
    },
    setBindable: (state, { payload }) => {
      state.bindable = payload;
    },
    setQuote: (state, { payload }) => {
      state.quote = payload;
    },
    setLoadingQuote: (state, { payload }) => {
      state.loadingQuote = payload;
    },
    setUpdatingQuote: (state, { payload }) => {
      state.updatingQuote = payload;
    },
    setQuoteErrors: (state, { payload = [] }) => {
      const quoteErrors = payload
        .filter((item: any) => item.invalidFields.length > 0)
        .map(({ message, invalidFields }: any) => {
          return { field: invalidFields[0], message: message };
        });
      state.quoteErrors = quoteErrors;
    },
    setTwoFourLimitsAvailable: (state, { payload }) => {
      state.twoFourLimitsAvailable = payload;
    },
  },
});

export const {
  updateApplicationForm,
  updateApplicationFormContactDetails,
  setAvailablePolicyTypes,
  updateApplicationFormIndustry,
  updateApplicationFormLocation,
  updateApplicationFormAddPreviousLoss,
  updateApplicationFormRemovePreviousLoss,
  updateApplicationFormClearPreviousLoss,
  updateApplicationFormResetPreviousLosses,
  updateIndustryName,
  updateNaic,
  updateApplicationFormPL,
  updateApplicationFormIndividualAdditionalInsured,
  updateApplicationFormDeleteIndividualAdditionalInsured,
  removeApplicationFormIndividualAdditionalInsured,
  updateApplicationFormBlanketAdditionalInsured,
  removeApplicationFormBlanketAdditionalInsured,
  updateApplicationFormEndorsementsPackage,
  updateApplicationFormStartDate,
  setApplicationError,
  setBindable,
  setQuote,
  setLoadingQuote,
  setUpdatingQuote,
  setQuoteErrors,
  updateLiquorLiabilityCoverage,
  updateEquipmentBreakdownCoverage,
  updateFloodCoverage,
  updateCanadaEquipmentBreakdownCoverage,
  updateEarthquakeShockEndorsement,
  updateCrimeEndorsement,
  updatePrivacyBreachEndorsement,
  updateNonOwnedAutomobileEndorsement,
  updateProfitsActualLossSustainedEndorsement,
  updateSewerBackUpEndorsement,
  updateLegalGuardInformationServiceEndorsement,
  updateRestaurantCoverage,
  updateContractorsInstallationAndTools,
  updateLimitDamagePremRented,
  resetApplication,
  updateApplicationFormAuto,
  setApplicationFromServer,
  setSimplybindApplicationFromServer,
  clearEndorsements,
  updateLiabilityEdgeExtensionPackage,
  updateEdgeCompleteExtensionPackage,
  updateBusinessInterruptionExtensionPackage,
  updateContractorsExtensionExtensionPackage,
  setTwoFourLimitsAvailable,
} = applicationSlice.actions;
export default applicationSlice.reducer;
