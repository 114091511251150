import React from "react";
import styled, { css } from "styled-components";

interface PropTypes {
  title: string;
  selectedTab: number;
  setSelectedTab: any;
  tabOptions: { id?: number; label: string; value: string; disabled?: boolean; invalid?: boolean }[];
  styles?: { tabContainerStyles?: any; buttonStyles?: any; selectedIndicator?: string };
}

const TabList = ({ title, selectedTab, setSelectedTab, tabOptions, styles }: PropTypes) => (
  <Container role="tablist" aria-label={title} style={styles?.tabContainerStyles}>
    {tabOptions.map((tab) => (
      <Tab
        key={tab.value}
        role="tab"
        id={`tab-${tab.value}`}
        data-cy={`tab-${tab.value}`}
        aria-controls={`tabpanel-${tab.value}`}
        aria-selected={selectedTab === tab.id}
        onClick={() => setSelectedTab(tab.id)}
        disabled={tab?.disabled}
        style={styles?.buttonStyles}
        selectedIndicator={styles?.selectedIndicator}
        attention={tab.invalid}
        type="button"
      >
        {/* wrapping a 'span' and 'p' here to allow the invalid red dot to adhere to the size of the span */}
        <p>
          <span>{tab.label}</span>
        </p>
      </Tab>
    ))}
  </Container>
);

const Container = styled.div`
  display: flex;
  height: 64px;
  justify-content: center;
  background: ${({ theme }) => theme.colors.tertiary.zero};
`;

const Tab = styled.button<{ selectedIndicator?: string; attention?: boolean }>`
  color: ${({ theme }) => theme.white};
  font-weight: 600;
  font-size: 16px;
  background: ${({ theme }) => theme.colors.tertiary.zero};
  width: 176px;

  :disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }

  p {
    margin: auto;
    width: 97px;
  }

  span {
    position: relative;
  }

  ${(props) =>
    props.attention &&
    css`
      span:after {
        content: "";
        width: 6px;
        height: 6px;
        background-color: ${({ theme }) => theme.red};
        top: 0px;
        right: -6px;
        position: absolute;
        border-radius: 50%;
      }
    `}

  &[aria-selected="true"] {
    border-bottom: ${(props) => props?.selectedIndicator || `3px solid ${props.theme.green}`} !important;
  }

  :focus {
    outline: none;
  }

  :focus-visible {
    outline-color: ${({ theme }) => theme.blue};
    outline-style: dotted;
    outline-width: thin;
  }
`;

export default TabList;
