import React, { useContext } from "react";
import { Dropdown } from "styles";
import { OptionType } from "styles/Dropdown";
import useGetUnderwritingRuleTags from "hooks/underwritingRules/useGetUnderwritingRuleTags";
import { arrayToDropdownOptionsList } from "../../Shared/utils";
import { DropdownStyles } from "../../RuleBuilder/ruleBuildingBlocks/TagsBlock";
import { RuleSearchContext } from "../RuleSearchContext";
import { theme } from "styles";

const TagsDropdown = () => {
  const { filters, setTagsFilter } = useContext(RuleSearchContext);
  const { data: tags } = useGetUnderwritingRuleTags();

  return (
    <Dropdown
      onChange={(selection) => setTagsFilter(selection?.map((option: OptionType) => option.value) ?? [])}
      options={arrayToDropdownOptionsList(tags)}
      label="Tags"
      labelStyles={{ fontSize: "12px", color: theme.textNavyBlue }}
      full
      placeholder="Tags"
      isMulti
      customStyles={DropdownStyles}
      multiValues={arrayToDropdownOptionsList(filters.tags)}
    />
  );
};

export default TagsDropdown;
