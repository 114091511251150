import { useEffect } from "react";
import { Dictionary } from "lodash";
import { useHistory } from "react-router-dom";
import useAuth from "hooks/useAuth";
import { useSelector } from "react-redux";
import { selectUserAccountId, selectUserAgency } from "redux/selectors/user";

declare global {
  interface Window {
    formatic: any;
  }
}

const useFormotivRouteHandler = () => {
  const { listen } = useHistory();

  const { isAuthenticated, isIAUser } = useAuth();

  const accountUserId = useSelector((state: ReduxState) => selectUserAccountId(state));
  const agencyId = useSelector((state: ReduxState) => selectUserAgency(state)?.id);
  const hasFormatic = window.formatic?.client;
  const shouldLoadFormtotiv = isAuthenticated && isIAUser && hasFormatic;
  const hasUniqueAccountId = shouldLoadFormtotiv && window.formatic.session.passthru2 !== accountUserId;

  if (hasUniqueAccountId) {
    window.formatic.session.passthru1 = "NA";
    window.formatic.session.passthru2 = accountUserId;
    window.formatic.session.passthru3 = agencyId;
  }

  useEffect(() => {
    listen((url) => {
      const onConfirmationView = url.pathname.includes("/confirmation");
      const onNewQuote = url.pathname === "/new-quote";

      if (shouldLoadFormtotiv) {
        const tabIdRegex = /\/[1-5]\//g;
        const quoteURLRegex = new RegExp("quotes/.*", "g");

        const tabId = url.pathname.match(tabIdRegex) && url.pathname.match(tabIdRegex)![0];

        const isOnQuoteRoute = quoteURLRegex.test(url.pathname);

        const tabMapping: Dictionary<string> = {
          "/1/": "Business Details",
          "/2/": "Coverages",
          "/3/": "Contact Details",
          "/4/": "Additional Insureds",
          "/5/": "Endorsements",
        };

        if (isOnQuoteRoute) {
          window.formatic.client.triggerSubmit();
        }

        if (tabId && tabMapping[tabId]) {
          window.formatic.config.altFormURI = tabMapping[tabId];
          window.formatic.restart(true);
        }

        if (onNewQuote) {
          window.formatic.client.triggerMultipageComplete();
          window.formatic.client.triggerSubmit();
          window.formatic.config.altFormURI = "Instant Qualification Checker";
          window.formatic.restart(true);
        }
        if (onConfirmationView) {
          const policyId = url.pathname.replace("/policies/", "").replace("/confirmation", "");
          window.formatic.session.passthru4 = policyId;
          window.formatic.client.triggerMultipageComplete();
          window.formatic.client.triggerSubmit();
        }
      }
    });
  }, [isAuthenticated, isIAUser, accountUserId, agencyId]);
};

export default useFormotivRouteHandler;
