import { useQuery } from "@tanstack/react-query";
import Api from "utils/api";
import useAuth from "./useAuth";
import { reportToSentry } from "utils/sentry";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

type Options = {
  enabled?: boolean;
  retry?: boolean;
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
  staleTime?: number;
};

const useGetAgencyLogo = (agencyId: string, options?: Options) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const getAgencyLogo = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api.agencyLogo.get(agencyId).catch((err) => {
      if (err.response.status === 404) {
        return Promise.resolve("no logo found");
      } else {
        reportToSentry(err);
        return Promise.reject(err);
      }
    });
  };

  return useQuery({
    queryKey: ["agency_logo", agencyId],
    queryFn: getAgencyLogo,
    select: (data) => {
      if (data === "no logo found") {
        return null;
      } else {
        return data;
      }
    },
    ...options,
  });
};

export default useGetAgencyLogo;
