import styled from "styled-components";

export const Paragraph = styled.p`
  color: ${(props) => props.theme.gray};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 26px;
  margin: 0;
`;

export const Span = styled.span`
  color: ${(props) => props.theme.gray};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 26px;
  margin: 0;
`;

export const ParagraphLight = styled(Paragraph)`
  color: ${(props) => props.theme.white};
`;

export const ParagraphSmall = styled(Paragraph)`
  color: ${(props) => props.theme.lightGray};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
`;

export const ParagraphSmallLight = styled(ParagraphSmall)`
  color: ${(props) => props.theme.white};
`;

export const ParagraphHeader = styled.p`
  color: ${(props) => props.theme.gray};
  font-family: ${(props) => props.theme.primaryFont};
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 13px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const Breadcrumb = styled(Paragraph)`
  font-size: 13px;
  font-weight: 600;
`;
