import React from "react";
import { Label } from "styles";
import MultiRow from "shared/application/MultiRow";
import { formatNumber } from "utils/formatNumber";
import { connect } from "react-redux";
import { updateEndorsementQuestionState } from "redux/reducers/_underwritingProfileReducer";
import { selectUnderwritingProfile } from "redux/selectors/underwritingProfile";

interface PropTypes {
  action: (arg0: { selectedContractorsToolsLimit: number }) => void;
  updateEndorsementQuestionState: (endorsementDetails: { key: string; questionHasBeenAnswered: boolean }) => void;
  underwritingProfile: UnderwritingProfile;
}

const valueOptions = [10000, 15000, 20000, 25000];

const ContractorsInstallationAndTools = ({
  action,
  updateEndorsementQuestionState,
  underwritingProfile,
}: PropTypes) => {
  const value =
    underwritingProfile?.legacyApplication?.endorsements?.contractorsInstallationAndTools
      ?.selectedContractorsToolsLimit;

  const handleUpdateEndorsementValue = (value: number) => {
    updateEndorsementQuestionState({ key: "contractorsToolsEndorsement", questionHasBeenAnswered: true });
    action({ selectedContractorsToolsLimit: value });
  };

  const hasError = !valueOptions.find((valueOption) => valueOption === value);

  return (
    <>
      <Label>Select additional coverage above our standard of $5,000.</Label>
      <MultiRow
        money={true}
        options={valueOptions}
        value={formatNumber(value)}
        updateValue={handleUpdateEndorsementValue}
        label="Select additional coverage for tools above our standard of $5,000."
        propertyName="selectedContractorsToolsLimit"
        hasError={hasError}
        errorText="This is required if Contractors Tools coverage is selected"
      />
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  underwritingProfile: selectUnderwritingProfile(state),
});

const mapDispatchToProps = {
  updateEndorsementQuestionState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractorsInstallationAndTools);
