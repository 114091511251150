import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SmoothCollapse from "react-smooth-collapse";
import styled, { css } from "styled-components";
import {
  selectApplicationFormIndividualAdditionalInsureds,
  selectEndorsements,
  selectApplicationQuoteErrors,
  selectHasEmptyIndividualAI,
  selectApplicationForm,
  selectApplicationFormLocationState,
} from "redux/selectors/application";
import { selectAvailableEndorsements, selectLoadingAvailableEndorsements } from "redux/reducers/_availableEndorsements";
import {
  updateApplicationFormIndividualAdditionalInsured,
  removeApplicationFormIndividualAdditionalInsured,
  updateApplicationFormBlanketAdditionalInsured,
  removeApplicationFormBlanketAdditionalInsured,
} from "redux/reducers/_applicationReducer";
import BlanketInsured from "./BlanketInsured";
import IndividualInsured from "./IndividualInsured";
import ConfirmationModal from "./ConfirmationModal";
import { defaultBlanketDetail, defaultIndividual } from "./constants";
import { ErrorText } from "styles/Input";
import { Paragraph, Checkbox } from "styles";
import omit from "lodash/omit";
import formotivInstance from "utils/formotiv";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";

interface PropTypes {
  additionalInsureds: AdditionalInsured[];
  stateAbbrev: string;
  endorsements: EndorsementsType;
  hasEmptyIndividualAI: boolean;
  updateApplicationFormIndividualAdditionalInsured: (arg0: AdditionalInsured) => void;
  removeApplicationFormIndividualAdditionalInsured: ActionCreatorWithoutPayload<any>;
  updateApplicationFormBlanketAdditionalInsured: (arg0: EndorsementsType) => void;
  removeApplicationFormBlanketAdditionalInsured: ActionCreatorWithoutPayload<any>;
  invalidFields: InvalidField[];
  application: Application;
  availableEndorsements: any;
  loadingAvailableEndorsements: boolean;
}

const AdditionalInsureds = ({
  additionalInsureds,
  endorsements,
  hasEmptyIndividualAI,
  updateApplicationFormIndividualAdditionalInsured,
  removeApplicationFormIndividualAdditionalInsured,
  updateApplicationFormBlanketAdditionalInsured,
  removeApplicationFormBlanketAdditionalInsured,
  availableEndorsements,
  loadingAvailableEndorsements,
}: PropTypes) => {
  const defaultDetails = omit(
    defaultBlanketDetail,
    availableEndorsements?.additionalInsuredsConstructionContractRequirementEndorsement
      ? ""
      : "additionalInsuredsConstructionContractRequirementEndorsement"
  );

  // state of MT is on BOP 1.0 and gets undefined from /availableEndorsements
  const eligibleForBlanketAI = Boolean(availableEndorsements !== undefined);

  const hasBlanket = () => {
    if (!eligibleForBlanketAI) return false;
    if (
      !endorsements?.additionalInsuredsPrimaryAndNonContributory &&
      !endorsements?.additionalInsuredsWaiverOfTransfer &&
      !endorsements?.additionalInsuredsConstructionContractRequirementEndorsement &&
      !endorsements?.additionalInsuredsManagersOrLessorsPremisesEndorsement &&
      !endorsements?.additionalInsuredsLessorOfLeasedEquipmentEndorsement
    )
      return false;
    else return true;
  };
  const [isBlanket, setIsBlanket] = useState(hasBlanket());
  const [isIndividual, setIsIndividual] = useState(Boolean(additionalInsureds?.[0]));

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    setIsBlanket(hasBlanket());
    setIsIndividual(Boolean(additionalInsureds?.[0]));
  }, [endorsements]);

  const closeModal = () => setShowConfirmationModal(false);

  const cancelTracking = () => {
    formotivInstance.trackCustom("quote-remove-individual-insured-cancellation-button", "button");
    closeModal();
  };

  const confirmUserAction = () => {
    formotivInstance.trackCustom("quote-remove-individual-insured-confirmation", "button");
    removeApplicationFormIndividualAdditionalInsured();
    closeModal();
  };

  const toggleIsBlanket = () => {
    if (isBlanket) {
      removeApplicationFormBlanketAdditionalInsured();
    } else {
      updateApplicationFormBlanketAdditionalInsured(defaultDetails);
    }
  };

  const toggleIsIndividual = () => {
    if (isIndividual) {
      setShowConfirmationModal(true);
    } else {
      updateApplicationFormIndividualAdditionalInsured(defaultIndividual);
    }
  };

  if (loadingAvailableEndorsements) {
    return null;
  }

  return (
    <Wrapper>
      {eligibleForBlanketAI && (
        <Card className="AdditionalInsuredCard">
          <FlexDiv centered={true}>
            <Checkbox
              id="quote-blanket-insured-checkbox"
              checked={isBlanket}
              name="select-blanket-ai"
              handleClick={toggleIsBlanket}
              label="Blanket Additional Insured"
              labelStyleOverride={CheckboxLabelStyles}
            />
          </FlexDiv>
          <FlexDiv hasPadding={true}>
            <Paragraph>
              This policy comes with Blanket Additional Insured by default. This bundle includes commonly-requested
              forms with blanket language to comply with written contractual agreements.
            </Paragraph>
          </FlexDiv>
          <DetailsContainer>
            <SmoothCollapse expanded={isBlanket}>
              <CardContent>
                <BlanketInsured availableEndorsements={availableEndorsements} />
              </CardContent>
            </SmoothCollapse>
          </DetailsContainer>
        </Card>
      )}
      <Card className="AdditionalInsuredCard">
        <FlexDiv centered={true}>
          <Checkbox
            id="quote-individual-insured-checkbox"
            checked={isIndividual}
            name="select-individual-ai"
            handleClick={toggleIsIndividual}
            label="Individual Additional Insured"
            contentKey="INDIVIDUAL_ADDITIONAL_INSURED"
            labelStyleOverride={CheckboxLabelStyles}
          />
        </FlexDiv>
        <FlexDiv hasPadding={true}>
          <div>
            <Paragraph>
              Select to add specific individual or entities to your policy as an additional insured.
            </Paragraph>
            {hasEmptyIndividualAI && (
              <ErrorText>
                Please add information below to continue with adding additional insured to your quote.
              </ErrorText>
            )}
          </div>
        </FlexDiv>
        <DetailsContainer>
          <SmoothCollapse expanded={isIndividual} allowOverflowWhenOpen>
            <CardContent>{isIndividual && <IndividualInsured />}</CardContent>
          </SmoothCollapse>
        </DetailsContainer>
      </Card>

      <ConfirmationModal
        modalIsOpen={showConfirmationModal}
        closeModal={cancelTracking}
        handleContinue={confirmUserAction}
      />
    </Wrapper>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  additionalInsureds: selectApplicationFormIndividualAdditionalInsureds(state),
  stateAbbrev: selectApplicationFormLocationState(state),
  endorsements: selectEndorsements(state),
  invalidFields: selectApplicationQuoteErrors(state),
  hasEmptyIndividualAI: selectHasEmptyIndividualAI(state),
  application: selectApplicationForm(state),
  availableEndorsements: selectAvailableEndorsements(state),
  loadingAvailableEndorsements: selectLoadingAvailableEndorsements(state),
});

const mapDispatchToProps = {
  updateApplicationFormIndividualAdditionalInsured,
  removeApplicationFormIndividualAdditionalInsured,
  updateApplicationFormBlanketAdditionalInsured,
  removeApplicationFormBlanketAdditionalInsured,
};

const Wrapper = styled.div`
  div.AdditionalInsuredCard:last-of-type {
    border-bottom: none;
  }
`;

const CheckboxLabelStyles = css`
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.fontColors?.primary};
  line-height: 1.5;
`;

const Card = styled.div`
  margin: 0;
  border-radius: 0px;
  border: none;
  background-color: ${(props) => props.theme.white};
  padding: 0px;
  border-bottom: 1px solid ${(props) => props.theme.disabledBackground};
`;

const CardContent = styled.div`
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.lighterGray};
  margin-top: 8px;
  padding: 16px;
  background-color: ${(props) => props.theme.white};
`;

const FlexDiv = styled.div<{ centered?: boolean; hasPadding?: boolean }>`
  display: flex;
  ${(props) => props.centered && "align-items: center;"}
  ${(props) => props.hasPadding && "padding: 0px 48px;"}
`;

const DetailsContainer = styled.div`
  padding: 0px 48px;
  margin-bottom: 16px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInsureds);
