import styled, { css } from "styled-components";
import { GreenButton, NarrowButton } from "styles";
import { Input } from "reactstrap";

const ModalContainerStyles = css`
  position: relative;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const ModalFormContainer = styled.form<{ disabled: boolean }>`
  ${ModalContainerStyles}
`;

export const ModalDivContainer = styled.div`
  ${ModalContainerStyles}
`;

export const ModalContentContainer = styled.div<{ centered?: boolean; maxHeight?: string }>`
  padding: 24px 24px 0px;
  display: ${(props) => props.centered && "flex"};
  align-items: ${(props) => props.centered && "center"};
  flex-direction: ${(props) => props.centered && "column"};
  overflow: auto;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "426px")};
  height: 100%;
`;

export const ActionContainer = styled.div`
  display: flex;
  gap: 40px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 24px;
  margin-top: 10px;
  border-top: 1px solid ${(props) => props.theme.offWhite};
`;

export const TextButton = styled.button`
  color: ${(props) => props.theme.blue};
  background: transparent;
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
`;

export const ModalField = styled.label<{ lowercase?: boolean }>`
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  font-weight: 600;
  text-transform: ${(props) => (props.lowercase ? "none" : "capitalize")};
  color: ${(props) => props.theme.fontColors?.primary};
`;

export const LongModalInput = styled.input`
  max-width: 600px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.inputBorder};
  margin-bottom: 18px;
  padding-left: 8px;
  width: 100%;

  :focus {
    outline: none;
    box-shadow: 0 0 0 1px ${(props) => props.theme.blue};
  }
`;

export const ShortModalInput = styled.input`
  width: 264px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.inputBorder};
  margin-bottom: 18px;
  padding-left: 8px;

  :focus {
    outline: none;
    box-shadow: 0 0 0 1px ${(props) => props.theme.blue};
  }
`;

export const ShortModalDropDown = styled(Input)`
  width: 264px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.inputBorder};
  margin-bottom: 18px;
  padding-left: 8px;

  :focus {
    outline: none;
    box-shadow: 0 0 0 1px ${(props) => props.theme.blue};
  }
`;

export const ModalSubtitle = styled.div`
  background: ${(props) => props.theme.offWhite};
  margin-top: 0;
  padding: 8px 22px 4px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
`;

export const CancelButton = styled(NarrowButton)`
  height: 48px;
  width: 240px;
  margin-right: 21.71px;
  line-height: 15px;
  background-color: ${(props) => props.theme.white};
  font-size: 16px;
`;

export const SaveButton = styled(GreenButton)`
  height: 48px;
  width: 240px;
  font-size: 16px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  justify-content: space-between;
`;

export const ActionRow = styled(Row)`
  justify-content: center;
`;

export const WarningContainer = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.theme.backgroundOrange};
`;

export const WarningText = styled.p`
  color: ${(props) => props.theme.orange};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
  padding: 14px 18px;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.fontColors?.primary};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 36px;
`;

export const ModalText = styled.p`
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
`;
