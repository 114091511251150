import React, { useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import AgentsTable from "./AgentsTable";
import { useHistory, useLocation } from "react-router-dom";
import { AgentSortBy, SortDirection } from "types/enums";
import AjaxLoader from "shared/AjaxLoader";
import { LoadingDiv, SearchInput, Title, TitleBarAccessory, TitleContainer } from "styles/containerStyledComponents";
import { Breadcrumb } from "styles";
import InfiniteSearch from "shared/InfiniteSearch";

import getQueryParam from "utils/getQueryParam";

interface InfiniteSearchProps {
  loading: boolean;
  results: any;
  handleChangeSort: any;
  sortBy: AgentSortBy;
  sortDirection: SortDirection;
}

const AgentSearch = () => {
  const history = useHistory();
  const location = useLocation();
  const [searchInput, setSearchInput] = useState("");

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const viewAgent = (agentId: string | undefined) => {
    if (agentId) {
      history.push(`/agents/${agentId}`);
    }
  };

  useEffect(() => {
    let q = getQueryParam(location, "q");
    if (q !== null) {
      setSearchInput(q);
    }
  }, []);

  return (
    <InfiniteSearch query={searchInput}>
      {({ loading, results, handleChangeSort, sortBy, sortDirection }: InfiniteSearchProps) => (
        <ContentContainer data-cy="agent-search">
          <Breadcrumb style={{ marginBottom: "20px" }}>Agents</Breadcrumb>
          <TitleContainer>
            <Title style={{ flex: 1 }}>Agents</Title>
            <TitleBarAccessory>
              <LoadingDiv>{loading ? <AjaxLoader /> : <span />}</LoadingDiv>
              <SearchInput
                autoFocus
                data-cy="agent-search-input"
                type="text"
                value={searchInput}
                placeholder="Search Agents"
                onChange={handleSearchInputChange}
              />
            </TitleBarAccessory>
          </TitleContainer>

          <AgentsTable
            rows={results}
            search={searchInput}
            sortBy={sortBy}
            sortDirection={sortDirection}
            changeSort={handleChangeSort}
            viewAgent={viewAgent}
            loading={loading}
          />
        </ContentContainer>
      )}
    </InfiniteSearch>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export default AgentSearch;
