import React, { useState, useEffect } from "react";
import Api from "utils/api";
import PolicyReportTable from "kit/PolicyReportTable";
import { ContentContainer, Title } from "styles/containerStyledComponents";
import useAuth from "hooks/useAuth";
import { reportGetOpenProjectPolicyError } from "utils/reportError";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const OpenProjectPolicyReport = () => {
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = async () => {
    setIsLoading(true);
    setPolicies([]);

    const token = await getToken();
    const api = new Api(apiVersion, token);

    api
      .getOpenProjectPolicies()
      .then((policies) => setPolicies(policies))
      .catch((error) => reportGetOpenProjectPolicyError(error))
      .finally(() => setIsLoading(false));
  };

  return (
    <ContentContainer>
      <Title>Open Project Policy Report</Title>
      <PolicyReportTable
        policies={policies}
        isLoading={isLoading}
        onRefresh={() => onRefresh}
        highlightMultiDayRows={true}
      />
    </ContentContainer>
  );
};

export default OpenProjectPolicyReport;
