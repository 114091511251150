import React, { useState, useEffect } from "react";
import useAPI from "hooks/useAPI";
import AjaxLoader from "shared/AjaxLoader";
import { Switch, TextLink } from "styles";
import { SearchInput, SearchContainer, SearchIconWrapper } from "styles/containerStyledComponents";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "images/search_icon.svg";

const AppetiteToggler = () => {
  const [sleuthClassesAll, setSleuthClassAll] = useState<any>([]);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [sleuthClassesFiltered, setSleuthClassesFiltered] = useState<any>([]);

  const [getData, setGetData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sleuthId, setSleuthId] = useState("");
  const [toggleAction, setToggleAction] = useState<boolean | undefined>(undefined);
  const [amountToShow, setAmountToShow] = useState<number>(20);

  const { loading: loadingSleuthClass, results: resultsFromGetSleuthClass } = useAPI("getUnderwritingAppetite", [], {
    waitFor: getData,
    dependencyArray: [getData],
    onSuccess: () => {
      setGetData(false);
      setIsInitialLoad(false);
    },
  });

  const { results: resultsFromEnable } = useAPI("enableUnderwritingAppetite", [sleuthId], {
    waitFor: Boolean(sleuthId) && toggleAction === false,
    dependencyArray: [toggleAction],
    onSuccess: () => {
      setSleuthId("");
      setToggleAction(undefined);
    },
  });

  const { results: resultsFromDisable } = useAPI("disableUnderwritingAppetite", [sleuthId], {
    waitFor: Boolean(sleuthId) && toggleAction === true,
    dependencyArray: [toggleAction],
    onSuccess: () => {
      setSleuthId("");
      setToggleAction(undefined);
    },
  });

  useEffect(() => {
    if (resultsFromEnable?.sleuthId) {
      const sleuthClassToToggle = sleuthClassesAll.find((e: any) => e.sleuthId === resultsFromEnable.sleuthId);
      const toggled = (sleuthClassToToggle.enabled = resultsFromEnable.enabled);
      setSleuthClassAll([...sleuthClassesAll, toggled]);
    }
  }, [resultsFromEnable]);

  useEffect(() => {
    if (resultsFromDisable?.sleuthId) {
      const sleuthClassToToggle = sleuthClassesAll.find((e: any) => e.sleuthId === resultsFromDisable.sleuthId);
      const toggled = (sleuthClassToToggle.enabled = resultsFromDisable.enabled);
      setSleuthClassAll([...sleuthClassesAll, toggled]);
    }
  }, [resultsFromDisable]);

  useEffect(() => {
    if (resultsFromGetSleuthClass?.length) {
      setSleuthClassAll(resultsFromGetSleuthClass);
    }
  }, [resultsFromGetSleuthClass]);

  useEffect(() => {
    if (sleuthClassesAll.length > 0) {
      filterByQuery();
    }
  }, [sleuthClassesAll]);

  useEffect(() => {
    if (searchQuery && isInitialLoad) setGetData(true);
    filterByQuery();
  }, [searchQuery]);

  const filterByQuery = () => {
    const filtered = sleuthClassesAll?.filter((e: any) =>
      e.sleuthClassDescription?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );
    setSleuthClassesFiltered(filtered);
  };

  const handleToggleAppetite = (id: string, status: boolean) => {
    setSleuthId(id);
    setToggleAction(status);
  };

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchQuery(value);
  };

  return (
    <div>
      <StyledSearchContainer>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <SearchInput
          aria-label="Search Appetite"
          type="text"
          name="Get Appetites"
          value={searchQuery}
          placeholder="Search appetite"
          onChange={handleInputChange}
        />

        {loadingSleuthClass ? (
          <LoaderContainer>
            <AjaxLoader width={32} />
          </LoaderContainer>
        ) : null}
      </StyledSearchContainer>

      {sleuthClassesFiltered.length > 0 && (
        <>
          {sleuthClassesFiltered.slice(0, amountToShow).map((e: any) => (
            <SleuthClassCard key={e.sleuthId} isEnabled={e.enabled}>
              <SleuthClassCardInner>
                <SleuthClassName isEnabled={e.enabled}>{e.sleuthClassDescription}</SleuthClassName>
                <NaicsCodeContainer isEnabled={e.enabled}>
                  NAICS: <NaicsCode>{e.naics}</NaicsCode>
                </NaicsCodeContainer>
              </SleuthClassCardInner>
              <ToggleContainer>
                <Switch
                  onChange={() => handleToggleAppetite(e.sleuthId, e.enabled)}
                  checked={e.enabled}
                  ariaLabel={`${e.enabled ? "turn off" : "turn on"} ${e.sleuthClassDescription}`}
                  dataCy="toggle-appetite"
                  dataTestId="toggle-appetite"
                />
              </ToggleContainer>
            </SleuthClassCard>
          ))}
          {amountToShow < sleuthClassesFiltered.length ? (
            <LoadMoreBtn
              onClick={() => {
                setAmountToShow(amountToShow + 20);
              }}
            >
              Load More...
            </LoadMoreBtn>
          ) : null}
        </>
      )}
      {!isInitialLoad && sleuthClassesFiltered.length === 0 && <NoResults>No Results</NoResults>}
    </div>
  );
};

const SleuthClassCard = styled.div<{ isEnabled: boolean }>`
  alignItems: center,
  justifyContent: space-between;
  display: flex;
  background: ${(props) => props.theme.white};
  margin-bottom: 8px;
  padding: 12px;
  border:  ${(props) => (props.isEnabled ? `1px solid ${props.theme.blue}` : "")};
  border-radius: 4px;
  box-shadow:  ${(props) => (props.isEnabled ? "0 2px 10px 0 rgba(0,0,0,0.1)" : "")};
  .react-switch-bg {
    border: ${(props) => (props.isEnabled ? `1px solid ${props.theme.blue}` : `1px solid ${props.theme.connector}`)};
    background:  ${(props) => (props.isEnabled ? "#ECE9FF !important" : props.theme.disabledBackground)};
  }
`;

const SleuthClassCardInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const SleuthClassName = styled.p<{ isEnabled: boolean }>`
  margin-bottom: 0;
  color: ${(props) => (props.isEnabled ? props.theme.textNavyBlue : props.theme.gray)};
  font-size: 16px;
  font-weight: ${(props) => (props.isEnabled ? "600" : "400")};
`;

const NaicsCodeContainer = styled.p<{ isEnabled: boolean }>`
  margin-bottom: 0;
  font-size: 16px;
  color: ${(props) => props.theme.gray};
  white-space: nowrap;
  margin-left: 1em;
`;

const NaicsCode = styled.span`
  letter-spacing: 3px;
`;

const LoaderContainer = styled.div`
  position: absolute;
  right: 0;
`;

const StyledSearchContainer = styled(SearchContainer)`
  width 240px;
  position: relative;
  margin: 40px 0 32px 0;
`;

const NoResults = styled.p`
  padding: 16px;
  border-radius: 4px;
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.textNavyBlue};
  font-weight: 600;
`;

const ToggleContainer = styled.div`
  margin-left: 24px;
  display: flex;
  align-items: center;
`;

const LoadMoreBtn = styled(TextLink)`
  font-size: 16px;
  margin: 1em 0;
`;

export default AppetiteToggler;
