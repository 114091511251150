import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PolicyType } from "types/enums";
import { ActionContainer, Title } from "shared/ModalComponents";
import { policies } from "../application/edit/Coverages/Coverages";
import { FailFastContainer, StatusMessage } from "./styles";
import { GreenButtonStyles } from "styles";

interface PropTypes {
  availablePolicyTypes: [PolicyType];
  applicationId: string;
}

const Approved = ({ availablePolicyTypes, applicationId }: PropTypes) => {
  const continueApplicationRoute = `/simplybind/get-started`;

  return (
    <>
      <FailFastContainer>
        <Title>In Appetite!</Title>
        <StatusMessage>
          Please note that individual businesses may still decline or have different classification suggestions based on
          our digital underwriting.
        </StatusMessage>
        <PolicyTileContainer>
          {availablePolicyTypes.map((availablePolicy) => {
            const policy = policies.find((p) => p.policy === availablePolicy);
            return (
              policy && (
                <PolicyTile
                  data-testid={`tile-link-${policy.policy}`}
                  to={continueApplicationRoute}
                  key={`${policy.policy.toLowerCase()}-policy-tile`}
                >
                  <img src={policy.image} alt={`${policy.policy} policy icon`} />
                  <PolicyName>{policy.title}</PolicyName>
                </PolicyTile>
              )
            );
          })}
        </PolicyTileContainer>
      </FailFastContainer>
      <ActionContainer>
        <ContinueButton
          to={continueApplicationRoute}
          data-cy={"continue-application"}
          data-testid={"continue-application"}
        >
          <span>Start Quote</span>
        </ContinueButton>
      </ActionContainer>
    </>
  );
};

const PolicyTile = styled(Link)`
  margin: 0px 7px;
  text-align: center;
  display: flex;
  flex-direction: row;
`;

const PolicyName = styled.p`
  color: ${(props) => props.theme.fontColors?.primary};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  padding-left: 12px;
  max-width: 190px;
  width: 100%;
  margin: auto;
`;

const PolicyTileContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 43px; 
  grid-column-gap: 30px;
  max-width: 547px
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 15px;
  margin: auto;
`;

const ContinueButton = styled(Link)`
  ${GreenButtonStyles}

  width: 200px;
  height: 45px;
`;

export default Approved;
