import React, { useEffect, useState } from "react";
import Api from "utils/api";
import { Dropdown, Input } from "styles";
import { PAGES, dropdownAccountTypeOptions } from "utils/constants";
import { NextButton, Description } from "./StyledComponents";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface ChannelPartnerType {
  value: number;
  label: string;
  accountId: number;
}

interface AgencyType {
  value: number;
  label: string;
  channelPartnerId: number;
}

interface PropTypes {
  goToNextPage: (page: string, accountValues: AccountValues) => void;
  newAccount: AccountValues;
}

const AccountType = ({ goToNextPage, newAccount }: PropTypes) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const [agencyId, setAgencyId] = useState(0);
  const [channelPartnerId, setChannelPartnerId] = useState(0);
  const [channelPartnersIA, setChannelPartnersIA] = useState([]);
  const [agenciesByChannelPartner, setAgenciesByChannelPartner] = useState([]);
  const [accountType, setAccountType] = useState(0);

  const getChannelPartners = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    api.channelPartners
      .getAllIA()
      .then((channelPartners) => {
        const channelPartnersSorted = channelPartners
          .map((channelPartner: any) => ({ value: channelPartner.id, label: channelPartner.name }))
          .sort(function (a: ChannelPartnerType, b: ChannelPartnerType) {
            return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
          });
        setChannelPartnersIA(channelPartnersSorted);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  };

  const getAgenciesByChannelPartner = async (channelPartnerId: number) => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    api.agencies
      .getByChannelPartner(channelPartnerId)
      .then((agencies) => {
        const agenciesSorted = agencies
          .map((agency: any) => ({ value: agency.id, label: agency.name }))
          .sort(function (a: AgencyType, b: AgencyType) {
            return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
          });
        setAgenciesByChannelPartner(agenciesSorted);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  };

  const handleAccountTypeInputChange = (selectedOption: { value: number; label: string }) => {
    const { value } = selectedOption;
    if (value === 1 || value === 2) {
      getChannelPartners();
    }
    if (value === 2 && channelPartnerId !== 0) {
      getAgenciesByChannelPartner(channelPartnerId);
    }
    setAccountType(value);
  };

  useEffect(() => {
    if (newAccount?.accountType === 3) {
      setAccountType(3);
      getChannelPartners();
    }
  }, []);

  const handleChannelPartnerIdInputChange = (selectedOption: { value: number; label: string }) => {
    const { value } = selectedOption;
    if (accountType === 2 || accountType === 3) {
      getAgenciesByChannelPartner(value);
    }
    setChannelPartnerId(value);
  };

  const handleAgencyIdInputChange = (selectedOption: { value: number; label: string }) => {
    setAgencyId(selectedOption.value);
  };

  return (
    <>
      <Description>Please select the type of account you want to create.</Description>
      {accountType === 3 ? (
        <Input label="User Role" value="Non-Licensed User" name="nonLicensedUser" readOnly />
      ) : (
        <Dropdown
          onChange={handleAccountTypeInputChange}
          options={dropdownAccountTypeOptions}
          value={accountType}
          full={true}
          name="accountType"
          label="Account Type"
        />
      )}
      {accountType !== 0 && (
        <Dropdown
          onChange={handleChannelPartnerIdInputChange}
          options={channelPartnersIA}
          value={channelPartnerId}
          full={true}
          name="channelPartnerId"
          label="Channel Partner"
        />
      )}
      {(accountType === 2 || accountType === 3) && (
        <Dropdown
          onChange={handleAgencyIdInputChange}
          options={agenciesByChannelPartner}
          value={agencyId}
          full={true}
          name="agencyId"
          label="Agency"
        />
      )}
      <NextButton
        disabled={
          (accountType === 2 && agencyId === 0) ||
          (accountType === 1 && channelPartnerId === 0) ||
          (agencyId === 0 && channelPartnerId === 0 && accountType === 3)
        }
        type="button"
        onClick={() => goToNextPage(PAGES.BASIC_INFO, { accountType, channelPartnerId, agencyId })}
      >
        Next
      </NextButton>
    </>
  );
};

export default AccountType;
