import React, { useState } from "react";
import Modal from "shared/Modal";
import { ActionContainer, ModalContentContainer, ActionRow, ModalText } from "shared/ModalComponents";
import { GreenButton, Input, Label } from "styles";
import { useHistory } from "react-router-dom";
import { GhostButton } from "shared/SimplyBind/styles";
import styled from "styled-components";
import AddressSelector from "shared/application/AddressSelector";
import { Spinner } from "reactstrap";
import ToolTipIcon from "shared/ToolTipIcon";
import { updateUnderwritingProfile } from "redux/reducers/_underwritingProfileReducer";
import { updateApplicationForm, updateApplicationFormLocation } from "redux/reducers/_applicationReducer";
import { connect } from "react-redux";
import LoadingModal from "shared/SimplyBind/LoadingModal";
import { reportFailedToQuote } from "utils/reportError";
import useUnderwritingIngestApplication from "hooks/useUnderwritingIngestApplication";
import useVerifyAddressStateAndZip from "hooks/useVerifyAddressStateAndZip";

interface MissingInfoModalProps {
  onClose: () => void;
  onError: () => void;
  applicationId?: string;
  updateUnderwritingProfile: (arg0: underwritingProfileState | {}) => void;
  updateApplicationForm: (arg0: object) => void;
  updateApplicationFormLocation: (arg0: ApplicationLocation) => void;
  application?: Application;
}

const MissingInfoModal = ({
  onClose,
  onError,
  applicationId,
  updateUnderwritingProfile,
  updateApplicationForm,
  updateApplicationFormLocation,
  application,
}: MissingInfoModalProps) => {
  const defaultBusinessAge = application?.businessAgeInMonths
    ? Math.round((application?.businessAgeInMonths ?? 0) / 12)
    : undefined;
  const history = useHistory();
  const [squareFootage, setSquareFootage] = useState<number | undefined>(application?.locations?.[0]?.squareFootage);
  const [businessAge, setBusinessAge] = useState<number | undefined>(defaultBusinessAge);
  const [businessAddress, setBusinessAddress] = useState<Partial<PolicyLocation>>(application?.locations?.[0] ?? {});
  const [businessName, setBusinessName] = useState(application?.source === "API" ? application?.businessName : "");
  const applicationIsOnlyPL = application?.applicationTypes?.join("") === "PL";

  const { isError: stateAndZipAreInvalid, isLoading: isVerifyingStateAndZip } = useVerifyAddressStateAndZip({
    state: businessAddress.state,
    zip: businessAddress.zip,
  });

  const { mutate: ingestApplication, isPending: isLoadingIngestApplication } = useUnderwritingIngestApplication(
    applicationId,
    {
      policyStartDate: new Date(),
      businessAgeInMonths: Math.round((businessAge ?? 0) * 12),
      businessName,
      locations: [
        {
          ...businessAddress,
          squareFootage: squareFootage,
        },
      ],
    },
    {
      onSuccess: (res) => {
        history.push(`/simplybind/quote/${res.legacyApplication.applicationId}`);
        updateUnderwritingProfile(res);
        updateApplicationForm(res?.legacyApplication);
        updateApplicationFormLocation({ squareFootage: res?.legacyApplication?.locations?.[0]?.squareFootage });
      },
      onError: (err) => {
        //ignore relativity6 failure
        if (err.response.status === 400) {
          history.push(`/simplybind/quote/${err.response.data.legacyApplication.applicationId}`);
          updateUnderwritingProfile(err.response.data);
          updateApplicationForm(err.response.data?.legacyApplication);
          updateApplicationFormLocation({
            squareFootage: err.response.data?.legacyApplication?.locations?.[0]?.squareFootage,
          });
        } else {
          reportFailedToQuote(err, undefined, undefined, { forceMsgOverride: true });
          onError();
        }
      },
    }
  );

  const changeBusinessAddress = (address: Address, isVerified: boolean) => {
    if (isVerified) {
      setBusinessAddress({
        street: address.street,
        state: address.state,
        city: address.city,
        zip: address.zip,
      });
    }
  };

  const continueIsDisabled =
    stateAndZipAreInvalid ||
    isVerifyingStateAndZip ||
    businessAge === undefined ||
    (!applicationIsOnlyPL && squareFootage === undefined) ||
    businessName === undefined ||
    Object.values(businessAddress).some((val) => !val);

  return (
    <>
      {!isLoadingIngestApplication && (
        <Modal
          isOpen
          closeModal={onClose}
          label="One more step before you edit your quote"
          title="One more step before you edit your quote"
          loading={isLoadingIngestApplication}
        >
          <ModalContentContainer maxHeight="650px">
            <ModalText>
              We now require some additional details to create a bindable quote. In order to edit and re-rate this
              quote, please provide any missing information:
            </ModalText>
            <Input
              name="businessName"
              value={businessName}
              label="Legal Business Name"
              toolTipContent="BUSINESS_NAME_SIMPLYBIND"
              handleChange={(e: React.ChangeEvent) => setBusinessName((e.target as HTMLInputElement).value)}
              inputStyles={{ marginBottom: "10px" }}
            />
            <LegalBusinessNameInfo>
              We require the Legal Business Name in order to specify the First Named Insured on the Policy documents. If
              you previously entered the First Named Insured in the Business Name (DBA), you can edit the DBA in the
              next step.
            </LegalBusinessNameInfo>
            <FlexContainer>
              <Label htmlFor="business-address-address">Business Address</Label>
              <ToolTipIcon contentKey="BUSINESS_ADDRESS" />
            </FlexContainer>

            <AddressSelector
              street={businessAddress.street}
              city={businessAddress.city}
              state={businessAddress.state}
              zip={businessAddress.zip}
              changeAddress={changeBusinessAddress}
              name="business-address"
            />
            <Input
              type="number"
              label="Business Age"
              value={businessAge}
              name="business age"
              handleChange={(values) => setBusinessAge(values.floatValue)}
              halfwidth
              inputStyles={{ marginBottom: "30px", marginTop: "10px" }}
            />
            {!applicationIsOnlyPL && (
              <Input
                type="number"
                thousandSeparator=","
                label="Premises Square Footage"
                value={squareFootage}
                handleChange={(values) => setSquareFootage(values.floatValue)}
                name="squareFootage"
                halfwidth
              />
            )}
          </ModalContentContainer>

          <ActionContainer>
            <ActionRow>
              <StyledGhostButton dataCy="cancel-cta-button" onClick={onClose}>
                Cancel
              </StyledGhostButton>
              <StyledGreenButton dataCy="continue-cta-button" onClick={ingestApplication} disabled={continueIsDisabled}>
                {isLoadingIngestApplication ? <Spinner color="light" /> : "Continue"}
              </StyledGreenButton>
            </ActionRow>
          </ActionContainer>
        </Modal>
      )}
      {isLoadingIngestApplication && (
        <LoadingModal title="We're underwriting this business...">
          <p>
            Hang tight and make sure you don’t close or refresh this page.
            <br />
            <br />
            Our new underwriting engine may suggest additional coverages for your new quote. Please make sure you review
            all fields for completion before you bind.
          </p>
        </LoadingModal>
      )}
    </>
  );
};

const LegalBusinessNameInfo = styled.p`
  font-size: 12px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 35px;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const StyledGreenButton = styled(GreenButton)`
  min-width: 156px;
`;

const StyledGhostButton = styled(GhostButton)`
  min-width: 156px;
`;

const mapDispatchToProps = { updateUnderwritingProfile, updateApplicationForm, updateApplicationFormLocation };

export default connect(null, mapDispatchToProps)(MissingInfoModal);
