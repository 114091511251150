import React from "react";
import styled from "styled-components";
import { mobile, tablet } from "styles/responsive";
import { ParagraphSmall } from "styles/paragraph";
import { TextLink } from "styles";
import CoterieLogo from "images/coterie_logo.svg";
import { ReactComponent as TwitterLogo } from "images/social_twitter_icon.svg";
import { ReactComponent as FacebookLogo } from "images/social_facebook_icon.svg";
import { ReactComponent as InstagramLogo } from "images/social_instagram_icon.svg";
import { ReactComponent as LinkedinLogo } from "images/social_linkedin_icon.svg";

const Footer = () => (
  <Container>
    <Row>
      <a href={`https://${process.env.REACT_APP_DOMAIN}/`} target="_blank" rel="noopener noreferrer">
        <CoterieLogoImg src={CoterieLogo} width={70} alt="Coterie Logo" />
      </a>
      <SimpleRow>
        <SocialLink
          type="wide"
          href="https://www.twitter.com/CoterieIns/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter logo"
        >
          <TwitterLogo className="social-icon" />
        </SocialLink>
        <SocialLink
          type="narrow"
          href="https://www.facebook.com/CoterieIns/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook logo"
        >
          <FacebookLogo className="social-icon" />
        </SocialLink>
        <SocialLink
          type="instagram"
          href="https://www.instagram.com/coterie.insurance/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram logo"
        >
          <InstagramLogo className="social-icon" />
        </SocialLink>
        <SocialLink
          href="https://www.linkedin.com/company/coterieinsure/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn logo"
        >
          <LinkedinLogo className="social-icon" />
        </SocialLink>
      </SimpleRow>
    </Row>
    <Row>
      <div style={{ marginTop: 8 }}>
        <ParagraphSmall>Coterie Insurance Agency, National Producer Number: 19000811</ParagraphSmall>
        <ParagraphSmall>Ohio License Number: 1222680</ParagraphSmall>
      </div>
      <div>
        <SimpleRow>
          <StyledTextLink
            as="a"
            href={`https://${process.env.REACT_APP_DOMAIN}/privacy-policy/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </StyledTextLink>
          <StyledTextLink
            as="a"
            href={`https://${process.env.REACT_APP_DOMAIN}/terms-conditions/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </StyledTextLink>
        </SimpleRow>
        <ParagraphSmall style={{ marginTop: 6 }}>
          © {new Date().getFullYear()} Coterie Applications Inc. All rights reserved.
        </ParagraphSmall>
      </div>
    </Row>
  </Container>
);

const Container = styled.footer`
  width: 960px;
  margin: auto;
  padding: 40px 0 24px;
  ${tablet} {
    width: 100%;
    padding: 40px 32px 24px;
  }
  ${mobile} {
    text-align: center;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  ${mobile} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
`;

const SimpleRow = styled.div`
  display: flex;
  justify-content: flex-end;

  ${mobile} {
    margin-top: 24px;
    justify-content: space-around;
  }
`;

const CoterieLogoImg = styled.img`
  ${mobile} {
    width: 80px;
  }
`;

const SocialLink = styled.a<{ type?: string }>`
  width: 40px;
  height: 40px;
  padding: ${({ type }) => (type === "instagram" ? "9px" : "8px")} 0 0
    ${({ type }) => (type === "wide" ? "8px" : type === "narrow" ? "16px" : "12px")};

  ${mobile} {
    width: 60px;
    height: 60px;
    padding: ${({ type }) => (type === "instagram" ? "19px" : "18px")} 0 18px;
  }
`;

const StyledTextLink = styled(TextLink)`
  color: ${({ theme }) => theme.textDarkBlue};
  font-size: 14px;
  line-height: 17px;

  &:first-of-type {
    margin-right: 32px;
    ${mobile} {
      margin-right: 0;
    }
  }

  ${mobile} {
    margin-top: 8px;
  }
`;

export default Footer;
