import React from "react";
import { TableContainer } from "styles/containerStyledComponents";
import AccountSearchRow from "./AccountSearchRow";
import styled from "styled-components";
import UpImg from "images/up.svg";
import DownImg from "images/down.svg";
import { AccountSortBy, SortDirection } from "types/enums";

const TABLE_HEADERS = [
  { title: "Policy Holder", sort: AccountSortBy.Name, id: 1 },
  { title: "Email", sort: AccountSortBy.Email, id: 2 },
  { title: "Last Updated", sort: AccountSortBy.LastUpdated, id: 3 },
];

const getSortBy = (header: string): AccountSortBy =>
  TABLE_HEADERS.find((row) => row.title === header)?.sort || AccountSortBy.Name;

interface PropTypes {
  accounts: AccountType[];
  search?: string;
  showAccountLink?: boolean;
  changeSort: (sortBy: AccountSortBy, sortDirection: SortDirection) => void;
  sortBy: AccountSortBy;
  sortDirection: SortDirection;
}

const AccountSearchTable = ({ accounts, search, sortBy, sortDirection, changeSort }: PropTypes) => {
  const arrow = {
    [SortDirection.Descending]: DownImg,
    [SortDirection.Ascending]: UpImg,
  };

  const selectSortColumn = (column: AccountSortBy) => {
    changeSort(column, sortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending);
  };

  return (
    <AccountTableContainer>
      <table>
        <thead>
          <tr>
            {TABLE_HEADERS.map((header) => (
              <th key={header.title}>
                <HeaderRow>
                  <button
                    data-cy={`header-${header.title}`}
                    type="button"
                    disabled={!header.sort}
                    onClick={() => selectSortColumn(getSortBy(header.title))}
                  >
                    {header.title}
                  </button>
                  {sortBy === getSortBy(header.title) && header.sort && <img src={arrow[sortDirection]} alt="arrow" />}
                </HeaderRow>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(accounts) && accounts.length > 0 ? (
            accounts.map((account: AccountType) => (
              <AccountSearchRow key={account.id} account={account} searchTerm={search} />
            ))
          ) : (
            <tr>
              <td colSpan={10}>No accounts matching your criteria</td>
            </tr>
          )}
        </tbody>
      </table>
    </AccountTableContainer>
  );
};

const AccountTableContainer = styled(TableContainer)`
  > table {
    > tbody {
      > tr {
        > td {
          &:nth-child(3) {
            width: 125px;
          }
          &:nth-child(4) {
            width: 30px;
            padding-right: 8px;
            text-align: right;
          }
        }
      }
    }
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;

  button {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    color: ${(props) => props.theme.fontColors?.primary};
  }
`;

export default AccountSearchTable;
