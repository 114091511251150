import { Rule } from "./RuleSearch";
import { OptionType } from "styles/Dropdown";

export enum RuleSearchActionTypes {
  SET_FILTERED_RULES = "SET_FILTERED_RULES",
  SORT_RULES = "SORT_RULES",
  SET_AVAILABLE_VARIABLES = "SET_AVAILABLE_VARIABLES",
  SET_VARIABLE_QUERY = "SET_VARIABLE_QUERY",
  SET_STATUS_FILTER = "SET_STATUS_FILTER",
  SET_CONTROL_FILTER = "SET_CONTROL_FILTER",
  SET_TAGS_FILTER = "SET_TAGS_FILTER",
  SET_VARIABLE_OPTIONS = "SET_VARIABLE_OPTIONS",
  CLEAR_VARIABLE_FILTER = "CLEAR_VARIABLE_FILTER",
  CLEAR_FILTERS = "CLEAR_FILTERS",
  SET_VARIABLE_FILTER = "SET_VARIABLE_FILTER",
}

export interface Filters {
  variable: string;
  tags: string[];
  statuses: RuleStatus[];
  control: string;
}

export enum RuleStatus {
  Active = "Active",
  InActive = "Inactive",
}

export interface RuleSearchState {
  filteredRules: Rule[];
  availableVariables: string[];
  variableQuery: string;
  variableOptions: OptionType[];
  sortType: string;
  filters: Filters;
}

interface SetFilteredRules {
  type: "SET_FILTERED_RULES";
  payload: Rule[];
}

interface SortRules {
  type: "SORT_RULES";
  payload: string;
}

interface SetAvailableVariables {
  type: "SET_AVAILABLE_VARIABLES";
  payload: Rule[];
}

interface SetVariableQuery {
  type: "SET_VARIABLE_QUERY";
  payload: string;
}

interface SetTagsFilter {
  type: "SET_TAGS_FILTER";
  payload: string[];
}

interface SetStatusFilter {
  type: "SET_STATUS_FILTER";
  payload: RuleStatus[];
}

interface SetControlFilter {
  type: "SET_CONTROL_FILTER";
  payload: string;
}

interface SetVariableOptions {
  type: "SET_VARIABLE_OPTIONS";
  payload: string[];
}

interface SetVariableFilter {
  type: "SET_VARIABLE_FILTER";
  payload: string;
}
interface ClearVariableFilter {
  type: "CLEAR_VARIABLE_FILTER";
}

interface ClearFilters {
  type: "CLEAR_FILTERS";
}

export type RuleSearchActions =
  | SetFilteredRules
  | SetAvailableVariables
  | SetVariableQuery
  | SetTagsFilter
  | SetStatusFilter
  | SetControlFilter
  | SetVariableOptions
  | SetVariableFilter
  | ClearVariableFilter
  | ClearFilters
  | SortRules;
