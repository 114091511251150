import { Rule } from "../Shared/types";

export enum UnderwritingRulesActionTypes {
  SET_RULE = "SET_RULE",
  RESET_RULE = "RESET_RULE",
  UPDATE_VARIABLE = "UPDATE_VARIABLE",
  UPDATE_MESSAGE = "UPDATE_MESSAGE",
  UPDATE_CONDITION_OPERATOR = "UPDATE_CONDITION_OPERATOR",
  UPDATE_CONDITION_TARGET = "UPDATE_CONDITION_TARGET",
  UPDATE_CONTROL_ACTION = "UPDATE_CONTROL_ACTION",
  UPDATE_CONTROL_TARGET = "UPDATE_CONTROL_TARGET",
  UPDATE_PROPERTY_NAME = "UPDATE_PROPERTY_NAME",
  UPDATE_PROPERTY_VALUE = "UPDATE_PROPERTY_VALUE",
  ADD_TAG_BLOCK = "ADD_TAG_BLOCK",
  DELETE_TAG_BLOCK = "DELETE_TAG_BLOCK",
  SET_TAGS = "SET_TAGS",
  UPDATE_STATES = "UPDATE_STATES",
  UPDATE_ACTIVE_RULE_PATH = "UPDATE_ACTIVE_RULE_PATH",
  UPDATE_ACTIVE_LOGIC_BLOCK = "UPDATE_ACTIVE_LOGIC_BLOCK",
  ADD_MESSAGE_BLOCK = "ADD_MESSAGE_BLOCK",
  ADD_STATES_BLOCK = "ADD_STATES_BLOCK",
  ADD_CONTROL_BLOCK = "ADD_CONTROL_BLOCK",
  ADD_PROPERTY_BLOCK = "ADD_PROPERTY_BLOCK",
  ADD_MULTI_CONDITION = "ADD_MULTI_CONDITION",
  ADD_ROOT_RULE_OPTION = "ADD_ROOT_RULE_OPTION",
  DELETE_MULTI_CONDITION = "DELETE_MULTI_CONDITION",
  DELETE_PROPERTY = "DELETE_PROPERTY",
  ADD_SUBRULE = "ADD_SUBRULE",
  ADD_NESTED_SUBRULE = "ADD_NESTED_SUBRULE",
  DELETE_BLOCK = "DELETE_BLOCK",
  TOGGLE_RULE_DISABLED_STATE = "TOGGLE_RULE_DISABLED_STATE",
  TOGGLE_NEGATION = "TOGGLE_NEGATION",
  UPDATE_LABEL = "UPDATE_LABEL",
}
interface SetRule {
  type: UnderwritingRulesActionTypes.SET_RULE;
  payload: Rule;
}

interface UpdateVariable {
  type: UnderwritingRulesActionTypes.UPDATE_VARIABLE;
  payload: string;
}

interface UpdateMessage {
  type: UnderwritingRulesActionTypes.UPDATE_MESSAGE;
  payload: string;
}

interface UpdateConditionOperator {
  type: UnderwritingRulesActionTypes.UPDATE_CONDITION_OPERATOR;
  payload: {
    index: number;
    conditionOperator: string;
  };
}

interface UpdateConditionTarget {
  type: UnderwritingRulesActionTypes.UPDATE_CONDITION_TARGET;
  payload: {
    index: number;
    conditionTarget: string;
  };
}

interface UpdateControlTarget {
  type: UnderwritingRulesActionTypes.UPDATE_CONTROL_TARGET;
  payload: string;
}

interface UpdateControlAction {
  type: UnderwritingRulesActionTypes.UPDATE_CONTROL_ACTION;
  payload: string;
}

interface UpdateStates {
  type: UnderwritingRulesActionTypes.UPDATE_STATES;
  payload: string[];
}
interface UpdateActiveRulePath {
  type: UnderwritingRulesActionTypes.UPDATE_ACTIVE_RULE_PATH;
  payload: string;
}

interface UpdateActiveLogicBlock {
  type: UnderwritingRulesActionTypes.UPDATE_ACTIVE_LOGIC_BLOCK;
  payload: string;
}

interface UpdateLabel {
  type: UnderwritingRulesActionTypes.UPDATE_LABEL;
  payload: string;
}

interface UpdatePropertyName {
  type: UnderwritingRulesActionTypes.UPDATE_PROPERTY_NAME;
  payload: {
    name: string;
    index: number;
  };
}

interface UpdatePropertyValue {
  type: UnderwritingRulesActionTypes.UPDATE_PROPERTY_VALUE;
  payload: {
    value: string;
    index: number;
  };
}

interface AddMessageBlock {
  type: UnderwritingRulesActionTypes.ADD_MESSAGE_BLOCK;
}
interface AddControlBlock {
  type: UnderwritingRulesActionTypes.ADD_CONTROL_BLOCK;
}

interface AddStatesBlock {
  type: UnderwritingRulesActionTypes.ADD_STATES_BLOCK;
}

interface AddPropertyBlock {
  type: UnderwritingRulesActionTypes.ADD_PROPERTY_BLOCK;
}

interface DeleteProperty {
  type: UnderwritingRulesActionTypes.DELETE_PROPERTY;
  payload: number;
}

interface AddTagBlock {
  type: UnderwritingRulesActionTypes.ADD_TAG_BLOCK;
}

interface DeleteTagBlock {
  type: UnderwritingRulesActionTypes.DELETE_TAG_BLOCK;
}

interface SetTags {
  type: UnderwritingRulesActionTypes.SET_TAGS;
  payload: string[];
}

interface AddNestedSubrule {
  type: UnderwritingRulesActionTypes.ADD_NESTED_SUBRULE;
}

interface AddSubrule {
  type: UnderwritingRulesActionTypes.ADD_SUBRULE;
}

interface AddRootRuleOption {
  type: UnderwritingRulesActionTypes.ADD_ROOT_RULE_OPTION;
}

interface DeleteBlock {
  type: UnderwritingRulesActionTypes.DELETE_BLOCK;
  payload: string;
}

interface AddMultiCondition {
  type: UnderwritingRulesActionTypes.ADD_MULTI_CONDITION;
  payload: string;
}

interface DeleteMultiCondition {
  type: UnderwritingRulesActionTypes.DELETE_MULTI_CONDITION;
  payload: number;
}

interface ToggleRuleDisabledState {
  type: UnderwritingRulesActionTypes.TOGGLE_RULE_DISABLED_STATE;
}

interface ToggleNegation {
  type: UnderwritingRulesActionTypes.TOGGLE_NEGATION;
}

interface ResetRule {
  type: UnderwritingRulesActionTypes.RESET_RULE;
}

export type ActionsTypes =
  | UpdateVariable
  | UpdateMessage
  | UpdateActiveRulePath
  | UpdateActiveLogicBlock
  | UpdateControlAction
  | UpdateControlTarget
  | UpdateConditionOperator
  | UpdateConditionTarget
  | UpdateStates
  | UpdateLabel
  | UpdatePropertyName
  | UpdatePropertyValue
  | AddTagBlock
  | DeleteTagBlock
  | SetTags
  | AddMessageBlock
  | AddControlBlock
  | AddStatesBlock
  | AddNestedSubrule
  | AddSubrule
  | AddRootRuleOption
  | AddPropertyBlock
  | DeleteProperty
  | DeleteBlock
  | AddMultiCondition
  | DeleteMultiCondition
  | ToggleRuleDisabledState
  | ToggleNegation
  | ResetRule
  | SetRule;

export default ActionsTypes;
