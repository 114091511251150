import { StateType, Action } from "./types";

export const initialState = {
  focusedInput: null,
  error: undefined,
};

function reducer(state: StateType, action: Action) {
  switch (action.type) {
    case "focus_cc_number":
      return {
        ...state,
        focusedInput: action.type,
      };
    case "focus_expiration":
      return {
        ...state,
        focusedInput: action.type,
      };
    case "focus_cvc":
      return {
        ...state,
        focusedInput: action.type,
      };
    case "error":
      return {
        ...state,
        error: action.payload,
      };
    case "reset-error":
      return {
        ...state,
        error: "",
      };
    case "blur_input":
      return {
        ...state,
        focusedInput: null,
      };

    default:
      return state;
  }
}

export default reducer;
