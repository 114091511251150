import { useQuery } from "@tanstack/react-query";
import useAuth from "./useAuth";
import Api from "utils/api";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const useGetAllPolicyBalances = (policyNumbers: string[] = [], options: { enabled: boolean }) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const getPolicyBalanceQuery = async (): Promise<PolicyBalance[]> => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    const getPolicyBalance = async (policyNumber: string): Promise<PolicyBalance> => {
      return await api.dashboardBilling.getBillingBalance(policyNumber);
    };
    const allGetPolicyBalanceRequests = policyNumbers.map((policy) => getPolicyBalance(policy));

    return await Promise.all(allGetPolicyBalanceRequests);
  };

  return useQuery<PolicyBalance[]>({
    queryKey: ["policy_balance", policyNumbers.join(",")],
    queryFn: getPolicyBalanceQuery,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
};

export default useGetAllPolicyBalances;
