import React from "react";
import { Table } from "reactstrap";
import LogsTableRow from "./LogsTableRow";
import styled from "styled-components";

interface PropTypes {
  logItems: LogItem[];
  headers: string[];
  searchTerm?: string;
}

const HeaderTh = styled.th`
  border-bottom: none !important;
`;

export default class LogsTable extends React.Component<PropTypes> {
  static defaultProps = {
    searchTerm: "",
    headers: ["Status", "Service", "Description", "Created On"],
  };

  render(): React.ReactNode {
    const { logItems, searchTerm, headers } = this.props;

    let logRows = logItems.map((logItem: LogItem) => {
      return <LogsTableRow key={logItem.id} columns={headers.length} logItem={logItem} searchTerm={searchTerm} />;
    });

    let headerRows = headers.map((h, i) => <HeaderTh key={i}>{h}</HeaderTh>);

    return (
      <Table style={{ width: "100%" }}>
        <thead>
          <tr>{headerRows}</tr>
        </thead>
        <tbody>
          {logRows.length > 0 ? (
            logRows
          ) : (
            <tr>
              <td colSpan={headerRows.length}>
                <div>No logs matching your criteria</div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }
}
