import React from "react";
import { Text, View, Image, Rect, StyleSheet } from "@react-pdf/renderer";
import { Svg } from "utils/proposal-pdf/SvgPatch";
import coterie_logo from "images/coterie-pdf-proposal-logo.png";
import { theme } from "styles";

interface HeaderPropTypes {
  agentEmail?: string;
  agencyName?: string;
  agencyLogo?: string;
}

const Header = ({ agentEmail, agencyName, agencyLogo }: HeaderPropTypes) => {
  return (
    <View>
      <View style={styles.headerContainer}>
        <View style={styles.headerLeftContent}>
          <Text style={styles.proposalTitle}>Quote Proposal</Text>
          <View style={styles.contentContainer}>
            {agencyLogo && <Image style={styles.agencyLogo} src={agencyLogo} />}
            <View style={styles.leftTextContainer}>
              <Text style={styles.proposalSubtitle}>Fast & Affordable Insurance for Your Business with Coterie</Text>
              <Text style={styles.presentedBy}>Presented By</Text>
              {agencyName && <Text style={styles.agencyName}>{agencyName}</Text>}
              {agentEmail && <Text style={styles.agentEmail}>{agentEmail}</Text>}
            </View>
          </View>
        </View>
        <View style={styles.headerRightContent}>
          <Image style={styles.image} src={coterie_logo} />
          <Text style={styles.pTag}>
            Enjoy the peace of mind that your hard work is protected by an insurance policy that meets your needs.
          </Text>
          <Text style={styles.pTag}>
            We've partnered with Coterie Insurance to make this process seamless and stress free.
          </Text>
        </View>
      </View>
      <Svg style={styles.line}>
        <Rect x="" y="" width="1000px" height="1px" fill={theme.textNavyBlue} />
      </Svg>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    height: "157px",
  },
  headerLeftContent: {
    width: "373px",
  },
  headerRightContent: {
    width: "160px",
  },
  proposalTitle: {
    fontSize: "20px",
    margin: "8px 0",
    fontFamily: theme.font.typeface.secondary,
    color: theme.blue,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    width: "373px",
  },
  agencyLogo: {
    maxWidth: 153,
    maxHeight: 85,
    objectFit: "contain",
    width: "100%",
    margin: "0 10 0 0",
  },
  leftTextContainer: {
    marginRight: "20px",
    width: "203px",
  },
  proposalSubtitle: {
    fontFamily: theme.font.typeface.primary,
    fontWeight: 600,
    width: "100%",
    fontSize: "12px",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
  },
  headerTitle: {
    fontFamily: theme.font.typeface.secondary,
    fontSize: "20px",
    marginBottom: "18px",
  },
  image: {
    width: "141px",
    height: "26px",
    marginBottom: "16px",
  },
  pTag: {
    fontSize: "10px",
    marginBottom: "16px",
    width: "142px",
    fontFamily: theme.font.typeface.secondary,
    color: theme.gray,
    lineHeight: "1.2px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  line: {
    height: "1px",
    marginBottom: "5px",
    marginTop: "5px",
  },
  presentedBy: {
    fontFamily: theme.font.typeface.primary,
    fontWeight: 600,
    fontSize: "8px",
    letterSpacing: "1.23px",
    marginBottom: "4px",
    color: theme.mediumGray,
    textTransform: "uppercase",
  },
  agencyName: {
    fontFamily: theme.font.typeface.primary,
    fontSize: "11px",
    marginBottom: "4px",
  },
  agentEmail: {
    fontFamily: theme.font.typeface.secondary,
    fontSize: "11px",
    marginBottom: "4px",
    color: theme.buttonHover,
  },
});

export default Header;
