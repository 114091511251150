import React, { useState } from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { Paragraph, NarrowButton } from "styles";
import Input from "styles/Input";
import { ModalField } from "shared/ModalComponents";
import { PanelContainer } from "shared/application/StyledComponents";
import AddressSelector from "shared/application/AddressSelector";
import { selectApplicationFormIndividualAdditionalInsureds } from "redux/selectors/application";
import {
  updateApplicationFormIndividualAdditionalInsured,
  updateApplicationFormDeleteIndividualAdditionalInsured,
} from "redux/reducers/_applicationReducer";
import { ReactComponent as EditIcon } from "images/edit.svg";
import { ReactComponent as DeleteIcon } from "images/delete.svg";

interface PropTypes {
  additionalInsureds: AdditionalInsured[];
  updateApplicationFormIndividualAdditionalInsured: (arg0: AdditionalInsured) => void;
  updateApplicationFormDeleteIndividualAdditionalInsured: (arg0: AdditionalInsured) => void;
}

const IndividualInsured = ({
  additionalInsureds = [],
  updateApplicationFormIndividualAdditionalInsured,
  updateApplicationFormDeleteIndividualAdditionalInsured,
}: PropTypes) => {
  const [address, setAddress] = useState<Address>({ street: "", city: "", state: "", zip: "" });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [verifiedAddress, setVerifiedAddress] = useState(false);
  const [showAddAdditionalInsured, setShowAddAdditionalInsured] = useState(false);
  const [showEditAdditionalInsured, setShowEditAdditionalInsured] = useState(false);
  const [editingPersonIndex, setEditingPersonIndex] = useState<number | undefined>(undefined);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    let { name, value } = event.currentTarget;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      default:
        return;
    }
  };

  const changeAddress = (address: Address, isVerified: boolean) => {
    if (isVerified) {
      setAddress({ street: address.street, city: address.city, state: address.state, zip: address.zip });
    }
    setVerifiedAddress(isVerified);
  };

  const savePerson = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let newAdditionalInsured = {
      id: editingPersonIndex,
      name: name,
      email: email,
      street: address.street,
      city: address.city,
      state: address.state,
      zip: address.zip,
    };
    updateApplicationFormIndividualAdditionalInsured(newAdditionalInsured);

    setName("");
    setEmail("");
    setAddress({ street: "", city: "", state: "", zip: "" });
    setShowAddAdditionalInsured(false);
    setShowEditAdditionalInsured(false);
    setEditingPersonIndex(undefined);
    setVerifiedAddress(false);
  };

  const editPerson = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    person: AdditionalInsured,
    index: number
  ) => {
    event.preventDefault();

    if (showEditAdditionalInsured === false) {
      setEditingPersonIndex(index);
      setShowEditAdditionalInsured(true);
      setShowAddAdditionalInsured(false);
      setName(person.name);
      setEmail(person.email);
      setAddress({ street: person.street, city: person.city, state: person.state, zip: person.zip });
    } else {
      setEditingPersonIndex(undefined);
      setShowEditAdditionalInsured(false);
      setShowAddAdditionalInsured(false);
      setName("");
      setEmail("");
      setAddress({ street: "", city: "", state: "", zip: "" });
    }
  };

  const deletePerson = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    person: AdditionalInsured,
    index: number
  ) => {
    event.preventDefault();
    updateApplicationFormDeleteIndividualAdditionalInsured({ ...person, id: index });
  };

  const valid = name && address.street && address.city && address.state && address.zip && verifiedAddress;

  const isEdit = (index: number) => (editingPersonIndex === index ? true : false);

  const showAddAdditionalInsuredForm = additionalInsureds[0]?.name === "" || showAddAdditionalInsured;

  const renderAddOrEditForm = () => (
    <AddAdditionalInsuredContainer isShowEdit={showEditAdditionalInsured}>
      <Input
        datacy="add-insured-name"
        id="quote-additional-insured-name"
        name="name"
        value={name}
        handleChange={handleChange}
        placeholder="Enter name of individual or legal entity"
        label="Full Name of Individual or Legal Entity"
        required
      />
      <Input
        datacy="add-insured-email"
        id="quote-additional-insured-email"
        name="email"
        value={email}
        handleChange={handleChange}
        placeholder="Enter valid email address"
        label="Email Address"
      />
      <ModalField>Mailing Address</ModalField>
      <AddressSelector
        id="quote-additional-insured-address"
        street={address.street}
        city={address.city}
        state={address.state}
        zip={address.zip}
        changeAddress={changeAddress}
        name="additionalInsureds"
        shouldUpdateAddressOnChange={true}
      />
      <SaveInsuredButton
        type="submit"
        data-testid="save-person"
        disabled={!valid}
        isShowEdit={showEditAdditionalInsured}
      >
        Save Additional Insured
      </SaveInsuredButton>
    </AddAdditionalInsuredContainer>
  );

  return (
    <form onSubmit={savePerson}>
      <PanelContainer>
        <StyledParagraph>
          You can add additional insureds to your policy by filling out this form. Make sure to complete the process by
          clicking "Save Additional Insured" upon finishing.
        </StyledParagraph>

        <AdditionalInsuredsContainer>
          {Boolean(additionalInsureds?.[0]?.name) && (
            <PersonContainer isShowEdit={showEditAdditionalInsured}>
              {additionalInsureds
                .filter((e) => e?.name)
                .map((person: AdditionalInsured, index: number) => (
                  <div>
                    <PersonCard key={`additional-insured-${index}`} isEdit={isEdit(index)}>
                      <div>
                        <PersonDetail>{person.name}</PersonDetail>
                        <PersonDetail>{person.email}</PersonDetail>
                        <PersonDetail>
                          {person.street}, {person.city}, {person.state} {person.zip}
                        </PersonDetail>
                      </div>
                      <ButtonContainer>
                        <IconButton
                          aria-label={`edit-${person.name}`}
                          onClick={(e) => {
                            editPerson(e, person, index);
                          }}
                        >
                          <EditIconWrapper>
                            <EditIcon title="edit icon" />
                          </EditIconWrapper>
                        </IconButton>
                        <IconButton
                          aria-label={`delete-${person.name}`}
                          onClick={(e) => {
                            deletePerson(e, person, index);
                          }}
                        >
                          <DeleteIconWrapper>
                            <DeleteIcon title="delete" />
                          </DeleteIconWrapper>
                        </IconButton>
                      </ButtonContainer>
                    </PersonCard>
                    {isEdit(index) && showEditAdditionalInsured && renderAddOrEditForm()}
                  </div>
                ))}
            </PersonContainer>
          )}

          {!showEditAdditionalInsured &&
            (showAddAdditionalInsuredForm ? (
              renderAddOrEditForm()
            ) : (
              <SaveInsuredButton
                onClick={() => setShowAddAdditionalInsured(true)}
                data-testid="show-add-person"
                isShowEdit={showEditAdditionalInsured}
              >
                Add Additional Insured
              </SaveInsuredButton>
            ))}
        </AdditionalInsuredsContainer>
      </PanelContainer>
    </form>
  );
};

const EditIconWrapper = styled.div`
  svg {
    fill: ${(props) => props.theme.blue};
  }
`;

const DeleteIconWrapper = styled.div`
  svg {
    fill: ${(props) => props.theme.blue};
  }
`;

const StyledParagraph = styled(Paragraph)`
  line-height: 23px;
  margin-bottom: 23px;
  font-size: 15px;
`;

const AdditionalInsuredsContainer = styled.div`
  padding: 0px 16px;
`;

const PersonContainer = styled.div<{ isShowEdit: boolean }>`
  width: 100%;
  max-width: 600px;
  padding-bottom: 8px;
  margin-bottom: 24px;
  ${(props) =>
    !props.isShowEdit &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.disabledBackground};
    `}
`;

const PersonCard = styled.div<{ isEdit: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.isEdit ? props.theme.blue : props.theme.lighterGray)};
`;

const PersonDetail = styled(Paragraph)`
  line-height: 20px;
  margin-bottom: 0;
  font-size: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const IconButton = styled.button`
  background: ${(props) => props.theme.white};
`;

const AddAdditionalInsuredContainer = styled.div<{ isShowEdit: boolean }>`
  ${(props) =>
    props.isShowEdit &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.disabledBackground};
      margin-bottom: 24px;
    `}
`;

const SaveInsuredButton = styled(NarrowButton)<{ isShowEdit: boolean }>`
  &:disabled {
    background-color: ${(props) => props.theme.disabledBackground};
    color: ${(props) => props.theme.disabledText};
    opacity: 1;
    border: none;
  }
  width: 184px;
  background-color: ${(props) => props.theme.white};
  ${(props) =>
    props.isShowEdit &&
    css`
      margin-bottom: 24px;
    `}
`;

const mapStateToProps = (state: ReduxState) => ({
  additionalInsureds: selectApplicationFormIndividualAdditionalInsureds(state),
});

const mapDispatchToProps = {
  updateApplicationFormIndividualAdditionalInsured,
  updateApplicationFormDeleteIndividualAdditionalInsured,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndividualInsured);
