import { useQuery } from "@tanstack/react-query";
import { coterieAPI } from "./useAPI/instances";

const getAcknowledgements = async (industryId: number) => {
  const api = coterieAPI();
  return api.get(`/commercial/acknowledgements/${industryId}`);
};

type Response = {
  data: {
    pl?: {
      url?: string;
    };
  };
};

const selectAcknowledgements = (data: Response) => {
  return { pl_url: data.data?.pl?.url || "" };
};

export default function useGetAcknowledgements(industryId: number, options?: { enabled: boolean }) {
  return useQuery({
    queryKey: ["acknowledgements", industryId],
    queryFn: () => getAcknowledgements(industryId),
    select: selectAcknowledgements,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
}
