import React, { useContext } from "react";
import { RuleBuilderContext } from "./RuleBuilderContext";
import { ButtonContainer, LogicButton, SubmitButton, ResetButton, LogicButtonHelperText } from "./styles";
import get from "lodash/get";
import { getParentPath } from "./utils";
import { Condition } from "../Shared/types";

interface LogicButtonBarProps {
  openSubmitModal: () => void;
}

const LogicButtonBar = ({ openSubmitModal }: LogicButtonBarProps) => {
  const {
    addMessageBlock,
    addControlBlock,
    addStatesBlock,
    addNestedSubrule,
    addSubrule,
    addRootRuleOption,
    addPropertyBlock,
    addMultiCondition,
    resetRule,
    toggleNegation,
    addTagBlock,
    rule,
    activeRulePath,
    activeLogicBlock,
  } = useContext(RuleBuilderContext);

  const ifStatementIsInvalid = () => {
    const currentOption = get(rule, activeRulePath);
    const currentOptionParent = get(rule, getParentPath(activeRulePath, -2));
    const hasInvalidConditionField = currentOption?.conditions?.some((c: Condition) => {
      if (c.conditionOperator !== "notnull") {
        return !c.conditionOperator || !c.conditionTarget;
      } else {
        return null;
      }
    });
    const hasEmptyVariableField = !currentOptionParent?.variable;
    return hasInvalidConditionField && hasEmptyVariableField;
  };

  const isRootRule = activeRulePath.split(".").length === 2;
  const logicBlockButtonsAreDisabled = ifStatementIsInvalid();
  const multiConditionButtonsAreDisabled = activeLogicBlock !== "condition";
  const addAttributeButtonIsDisabled = activeRulePath !== "options.0";
  const hasUnfinishedProperty = rule.properties?.some((property) => !property.name || !property.value);

  return (
    <ButtonContainer>
      <SubmitButton onClick={openSubmitModal} disabled={logicBlockButtonsAreDisabled || hasUnfinishedProperty}>
        Submit Rule
      </SubmitButton>
      <ResetButton onClick={resetRule}>Reset</ResetButton>
      <LogicButtonHelperText>Use these buttons to build a rule.</LogicButtonHelperText>
      <LogicButton onClick={addControlBlock} disabled={logicBlockButtonsAreDisabled}>
        Then
      </LogicButton>
      <LogicButton onClick={addStatesBlock} disabled={logicBlockButtonsAreDisabled}>
        In States
      </LogicButton>
      <LogicButton onClick={addMessageBlock} disabled={logicBlockButtonsAreDisabled}>
        Add Message
      </LogicButton>
      <LogicButton onClick={addNestedSubrule} disabled={logicBlockButtonsAreDisabled}>
        Add Subrule
      </LogicButton>
      <LogicButton onClick={isRootRule ? addRootRuleOption : addSubrule} disabled={logicBlockButtonsAreDisabled}>
        And
      </LogicButton>
      <LogicButtonHelperText>Use these buttons to modify conditions.</LogicButtonHelperText>
      <LogicButton onClick={() => addMultiCondition("and")} disabled={multiConditionButtonsAreDisabled}>
        and
      </LogicButton>
      <LogicButton onClick={() => addMultiCondition("or")} disabled={multiConditionButtonsAreDisabled}>
        or
      </LogicButton>
      <LogicButton onClick={toggleNegation} disabled={multiConditionButtonsAreDisabled}>
        not
      </LogicButton>
      <LogicButtonHelperText>Use these buttons to attach an attribute.</LogicButtonHelperText>
      <LogicButton onClick={addPropertyBlock} disabled={addAttributeButtonIsDisabled}>
        Add Property
      </LogicButton>
      <LogicButton onClick={addTagBlock} disabled={addAttributeButtonIsDisabled}>
        Add Tags
      </LogicButton>
    </ButtonContainer>
  );
};

export default LogicButtonBar;
