import React from "react";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import {
  selectIsOptInLowerCost,
  selectLossControlOptOutPremium,
  selectOptInCostDifferenceYearly,
} from "redux/selectors/global";
import { selectApplicationQuotePremium, selectLossControlFees } from "redux/selectors/application";
import { selectUnderwritingProfile } from "redux/selectors/underwritingProfile";
import { updateApplicationForm } from "redux/reducers/_applicationReducer";
import { formatCurrency } from "utils/formatNumber";
import { Header4Blue, TextLink, GreenButton } from "styles";
import { GhostButton } from "shared/SimplyBind/styles";
import AjaxLoader from "shared/AjaxLoader";
import FireExtinguisher from "images/fire-extinguisher.svg";
import GraduationCap from "images/graduation-cap.svg";
import Clapperboard from "images/clapperboard-play.svg";

interface PropTypes {
  setShowLossControlBridging: (value: boolean) => void;
  updateApplicationForm: (arg0: Application) => void;
}

const BridgingLossControlScreen = ({ setShowLossControlBridging, updateApplicationForm }: PropTypes) => {
  const isOptInLowerCost = useSelector(selectIsOptInLowerCost);
  const lossControlOptOutPremium = useSelector(selectLossControlOptOutPremium);
  const optInCostDifferenceYearly = useSelector(selectOptInCostDifferenceYearly);
  const premium = useSelector(selectApplicationQuotePremium);
  const lossControlFees = useSelector(selectLossControlFees);
  const underwritingProfile = useSelector(selectUnderwritingProfile);

  const handleContinue = () => {
    setShowLossControlBridging(false);
  };
  const handleRemoveLossControl = () => {
    updateApplicationForm({ lossControl: "OptOut" });
    setShowLossControlBridging(false);
  };

  return (
    <Container data-testid="bridging-mmr-screen" data-cy="bridging-mmr-screen">
      <TextSmall>Before you finalize your quote, you might also be interested in</TextSmall>
      <StyledHeader4>Manage My Risk Program</StyledHeader4>
      <TextMedium>
        Self-guided and self-directed loss-control services to save money on your quote! Learn more about our{" "}
        <StyledTextLink
          as="a"
          href="https://explore.coterieinsurance.com/manage-my-risk-agentsbrokers"
          target="_blank"
          rel="noopener noreferrer"
        >
          program details
        </StyledTextLink>
        .
      </TextMedium>
      <List>
        <ListItem>
          <Img src={FireExtinguisher} alt="FireExtinguisher" />
          <TextSmall>Safety equipment marketplace</TextSmall>
        </ListItem>
        <ListItem narrowGap={true}>
          <Img src={GraduationCap} alt="GraduationCap" />
          <TextSmall>Industry-specific training materials</TextSmall>
        </ListItem>
        <ListItem>
          <Img src={Clapperboard} alt="Clapperboard" />
          <TextSmall>Curated loss prevention content</TextSmall>
        </ListItem>
      </List>
      {underwritingProfile.loadingUnderwritingProfile ? (
        <AjaxLoader />
      ) : (
        <FlexDiv>
          <LeftPane paddingShrink={!isOptInLowerCost}>
            <Row>
              {isOptInLowerCost && <TextBlue>Your quote with Manage My Risk</TextBlue>}
              {isOptInLowerCost ? (
                <TextGreen>Save {formatCurrency(optInCostDifferenceYearly)}/year</TextGreen>
              ) : (
                <TextGreen hasSpacing={true}>
                  Add Manage My Risk for just an additional {formatCurrency(lossControlFees * 12)}
                </TextGreen>
              )}
            </Row>
            {isOptInLowerCost && (
              <OptInPremiumContainer>
                <Row>
                  <TextSmall>Your New Premium:</TextSmall>
                  <PremiumContainer>
                    <Premium>{formatCurrency(premium)}</Premium>
                    <StrikethroughPremium>{formatCurrency(lossControlOptOutPremium)}</StrikethroughPremium>
                  </PremiumContainer>
                </Row>
                <Row>
                  <TextSmall>+ Manage My Risk Program Cost:</TextSmall>
                  <TextSmall>{formatCurrency(lossControlFees * 12)}</TextSmall>
                </Row>
              </OptInPremiumContainer>
            )}
            <ButtonContainer>
              <BlueButton onClick={handleContinue} dataCy="continue-add-button">
                Yes, add Manage My Risk to this quote
              </BlueButton>

              {!isOptInLowerCost && (
                <TextButton onClick={handleRemoveLossControl} dataCy="continue-remove-button">
                  Continue without Manage My Risk →
                </TextButton>
              )}
            </ButtonContainer>
          </LeftPane>

          {isOptInLowerCost && (
            <RightPane>
              <YourQuoteContainer>
                <TextNavy>Your quote</TextNavy>
              </YourQuoteContainer>
              <OptOutPremiumContainer>
                <RowNarrow>
                  <TextSmall>Premium:</TextSmall>
                  <TextSmall>{formatCurrency(lossControlOptOutPremium)}</TextSmall>
                </RowNarrow>
              </OptOutPremiumContainer>
              <GhostButton onClick={handleRemoveLossControl} dataCy="continue-remove-button">
                Continue without Manage My Risk
              </GhostButton>
            </RightPane>
          )}
        </FlexDiv>
      )}
    </Container>
  );
};

const mapDispatchToProps = { updateApplicationForm };

const Container = styled.div`
  margin: 32px 48px 0px;
`;

const FlexDiv = styled.div`
  display: flex;
  margin-top: 16px;
`;

const LeftPane = styled.div<{ paddingShrink: boolean }>`
  width: 440px;
  padding: ${(props) => (props.paddingShrink ? "8px" : "16px")} 32px 16px 0;
`;

const OptInPremiumContainer = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const RightPane = styled.div`
  border-left: 1px solid ${(props) => props.theme.colors.neutral.regular};
  padding: 16px 0 16px 32px;
`;

const YourQuoteContainer = styled.div`
  height: 48px;
`;

const OptOutPremiumContainer = styled.div`
  height: 70px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  width: fit-content;
  row-gap: 24px;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RowNarrow = styled(Row)`
  justify-content: flex-start;
  column-gap: 16px;
`;

const TextSmall = styled.p`
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.gray};
  margin: 0;
`;

const TextMedium = styled(TextSmall)`
  font-size: 18px;
  line-height: 28px;
`;

const TextGreen = styled(TextSmall)<{ hasSpacing?: boolean }>`
  color: ${(props) => props.theme.colors.secondary.dark};
  background-color: ${(props) => props.theme.colors.secondary.twentyFive};
  font-weight: 600;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  ${(props) => props.hasSpacing && "margin-bottom: 40px;"}
`;

const TextBlue = styled(TextSmall)`
  color: ${(props) => props.theme.blue};
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
`;

const TextNavy = styled(TextBlue)`
  color: ${(props) => props.theme.textNavyBlue};
`;

const StyledHeader4 = styled(Header4Blue)`
  font-size: 30px;
  line-height: 32px;
  margin: 24px 0 16px;
`;

const List = styled.ul`
  margin-top: 16px;
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const ListItem = styled.div<{ narrowGap?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: ${(props) => (props.narrowGap ? "12px" : "18px")};
`;

const Img = styled.img`
  margin-bottom: 4px;
`;

const PremiumContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 4px;
`;

const Premium = styled.span`
  color: ${(props) => props.theme.blue};
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
`;

const StrikethroughPremium = styled.span`
  color: ${(props) => props.theme.blueStrikethroughColor};
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: 16px;
  font-weight: 400;
  text-decoration: line-through;
  line-height: 24px;
`;

const StyledTextLink = styled(TextLink)`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.gray};
  text-decoration: underline;
  :hover {
    color: ${(props) => props.theme.blue};
  }
`;

const BlueButton = styled(GreenButton)`
  color: ${(props) => props.theme.blue};
  background-color: ${(props) => props.theme.colors.primary.ten};
  border: 1px solid ${(props) => props.theme.blue};
  box-shadow: none;
`;

const TextButton = styled(GhostButton)`
  border: none;
  border-radius: 0;
  padding: 0;
  width: fit-content;

  :hover {
    cursor: pointer;
    border-bottom: 1px dotted ${({ theme }) => theme.blue};
    padding-bottom: 0px;
    background-color: transparent;
    color: ${(props) => props.theme.blue};
  }
`;

export default connect(null, mapDispatchToProps)(BridgingLossControlScreen);
