import React, { useState } from "react";
import Api from "utils/api";

import { Input } from "reactstrap";
import Label from "reactstrap/lib/Label";
import Button from "reactstrap/lib/Button";
import Alert from "reactstrap/lib/Alert";
import styled from "styled-components";
import AjaxLoader from "shared/AjaxLoader";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface RequestType {
  payerAccountId: string;
  asOfDate: string;
}

const AccountCharger = () => {
  const { getToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [request, setRequest] = useState<RequestType>({ payerAccountId: "", asOfDate: new Date().toISOString() });
  const [result, setResult] = useState<any>(null);
  const { apiVersion } = useFeatureFlags();

  const onChargeChannelPartnerClick = () => {
    setLoading(true);
    chargeChannelPartner();
  };

  const onChargePolicyHolderClick = () => {
    setLoading(true);
    chargePolicyHolder();
  };

  const chargeChannelPartner = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);
    api
      .chargeChannelPartner(request.asOfDate, request.payerAccountId)
      .then((result: any) => {
        setLoading(false);
        setResult(result);
        setError(null);
      })
      .catch((error: any) => {
        setLoading(false);
        setResult(null);
        setError(error);
      });
  };

  const chargePolicyHolder = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);
    api
      .chargePolicyHolder(request.asOfDate, request.payerAccountId)
      .then((result: any) => {
        setLoading(false);
        setResult(result);
        setError(null);
      })
      .catch((error: any) => {
        setLoading(false);
        setResult(null);
        setError(error);
      });
  };

  const onRequestFieldChange = (e: any) => {
    setRequest({ ...request, [e.target.name]: e.target.value });
  };

  const renderInputField = (name: keyof RequestType) => {
    return (
      <div className={"input"}>
        <Label>{name}</Label>
        <Input name={name} value={request[name]} onChange={onRequestFieldChange}></Input>
      </div>
    );
  };

  return (
    <div>
      <RequestContainer>
        <RequestFieldGroup>
          {renderInputField("payerAccountId")}
          {renderInputField("asOfDate")}
        </RequestFieldGroup>
      </RequestContainer>
      <div style={{ paddingTop: "1em" }}>
        <Button onClick={onChargeChannelPartnerClick} color="danger">
          Charge Channel Partner
        </Button>
        <Button onClick={onChargePolicyHolderClick} color="danger" style={{ marginLeft: "1em" }}>
          Charge Policy Holder
        </Button>
        {loading && <AjaxLoader />}
      </div>
      {result && (
        <div style={{ paddingTop: "1em" }}>
          <Alert color="success" style={{ whiteSpace: "pre", fontFamily: "courier" }}>
            {JSON.stringify(result, null, 2)}
          </Alert>
        </div>
      )}
      {error && (
        <div style={{ paddingTop: "1em" }}>
          <Alert color="danger" style={{ whiteSpace: "pre", fontFamily: "courier" }}>
            {JSON.stringify(error, null, 2)}
          </Alert>
        </div>
      )}
    </div>
  );
};

const RequestContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RequestFieldGroup = styled.div`
  flex: 1 1 33%;
  max-width: 33%;
  padding: 5px;
  .input {
    padding-bottom: 0.5em;
  }
  label {
    font-weight: bold;
    margin: 0;
  }
`;

export default AccountCharger;
