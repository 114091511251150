import React from "react";
import OneTimePaymentStripe from "./OneTimePaymentCardForm";
import styled from "styled-components";
import { Checkbox, Label } from "styles";
import { InfoText } from "./ExistingCard";

interface PropTypes {
  customerProfileId: string;
  isMakeDefaultPaymentSelected: boolean;
  setIsPaymentMethodSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMakeDefaultPaymentSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setTokenData: React.Dispatch<
    React.SetStateAction<{
      id: string;
      lastFourDigits: string;
    }>
  >;
  isStripeTriggered: boolean;
  submitActions: () => void;
}

const OneTimePaymentCard = ({
  setIsPaymentMethodSelected,
  isMakeDefaultPaymentSelected,
  setIsMakeDefaultPaymentSelected,
  setTokenData,
  isStripeTriggered,
  submitActions,
}: PropTypes) => {
  const handleMakeDefaultPaymentClick = () => {
    setIsMakeDefaultPaymentSelected(!isMakeDefaultPaymentSelected);
  };

  return (
    <AddNewCardContainer>
      <Label>Add New Card</Label>
      <OneTimePaymentStripe
        isStripeTriggered={isStripeTriggered}
        setIsPaymentMethodSelected={setIsPaymentMethodSelected}
        setTokenData={setTokenData}
        submitActions={submitActions}
      />
      <MakeDefaultPaymentContainer onClick={handleMakeDefaultPaymentClick}>
        <Checkbox
          checked={isMakeDefaultPaymentSelected}
          name="defaultPayment"
          handleClick={handleMakeDefaultPaymentClick}
        />
        <MakeDefaultPaymentLabel isMakeDefaultPaymentSelected={isMakeDefaultPaymentSelected}>
          Make default method for future payments
        </MakeDefaultPaymentLabel>
      </MakeDefaultPaymentContainer>
    </AddNewCardContainer>
  );
};

const AddNewCardContainer = styled.div`
  position: relative;
  margin: "40px 0px";
`;
const MakeDefaultPaymentContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;
`;
const MakeDefaultPaymentLabel = styled(InfoText)<{ isMakeDefaultPaymentSelected: boolean }>`
  font-size: 15px;
  margin: 0px 0px 0px 10px;
  color: ${(props) => (props.isMakeDefaultPaymentSelected ? props.theme.blue : props.theme.gray)};
  &:hover {
    cursor: pointer;
  }
`;
export default OneTimePaymentCard;
