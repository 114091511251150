import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setBindable,
  setLoadingQuote,
  setUpdatingQuote,
  setQuote,
  setQuoteErrors,
} from "redux/reducers/_applicationReducer";
import Api from "utils/api";
import applicationValidator from "utils/applicationValidator";
import { reportError } from "utils/reportError";
import getQuoteEstimate from "./getQuoteEstimate";

export const setLoading = (isUpdating: boolean, dispatch: any, value: boolean) => {
  if (isUpdating) {
    dispatch(setUpdatingQuote(value));
  } else {
    dispatch(setLoadingQuote(value));
  }
};

interface GetQuoteThunk {
  isSuccess?: boolean;
  isUpdating?: boolean;
  token: Token;
  apiVersion: string;
}

const getQuote = createAsyncThunk(
  "quote/getQuote",
  ({ isSuccess, isUpdating = false, token, apiVersion }: GetQuoteThunk, thunkAPI: any) => {
    const { getState, dispatch } = thunkAPI;
    const {
      application: { form },
    } = getState();
    const api = new Api(apiVersion, token);

    setLoading(isUpdating, dispatch, true);

    if (isSuccess) {
      api.application
        .getQuote(applicationValidator(form))
        .then((response) => {
          if (response.isSuccess) {
            dispatch(setBindable(true));
            dispatch(setQuote(response.quote));
            dispatch(setQuoteErrors([]));
            setLoading(isUpdating, dispatch, false);
          } else {
            dispatch(setQuoteErrors(response.errors));
            dispatch(setBindable(false));
            dispatch(getQuoteEstimate({ isUpdating, token, apiVersion }));
          }
        })
        .catch((err) => {
          reportError(err);
          setLoading(isUpdating, dispatch, false);
        });
    } else {
      dispatch(getQuoteEstimate({ isUpdating, token }));
    }
  }
);

export default getQuote;
