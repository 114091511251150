import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { theme } from "styles";

interface CoverageHeaderProps {
  title: string;
  subtitle: string;
}

const CoverageHeader = ({ title, subtitle }: CoverageHeaderProps) => {
  return (
    <View>
      <Text style={styles.pageHeaderTitle}>{title}</Text>
      <View style={styles.subHeaderContainer}>
        <Text style={styles.subHeaderText}>{subtitle}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  pageHeaderTitle: {
    marginBottom: 16,
    fontSize: "18px",
    color: theme.colors.tertiary.zero,
  },
  subHeaderContainer: {
    backgroundColor: theme.colors.tertiary.zero,
    paddingTop: 3,
    paddingBottom: 3,
    marginBottom: 10,
    borderRadius: 3,
  },
  subHeaderText: {
    fontSize: 11,
    color: theme.white,
    marginLeft: 6,
  },
});

export default CoverageHeader;
