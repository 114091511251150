import React from "react";
import styled from "styled-components";
import { uniq } from "lodash";
import Modal from "shared/ModalBasic";
import { ModalContentContainer, ActionRow, ActionContainer } from "shared/ModalComponents";
import { GhostButton } from "shared/SimplyBind/styles";
import { PolicyData } from "./Coverages";
import { ReactComponent as CloseIcon } from "images/close.svg";
import { PRODUCT_LABELS } from "utils/constants/product";

interface DeclinationModalProps {
  closeModal: () => void;
  policyData: PolicyData;
  declinationMessages: string[];
}

const DeclinationModal = ({ closeModal, policyData, declinationMessages }: DeclinationModalProps) => {
  const nonEmptyMessages = declinationMessages.filter((message) => message);
  const uniqueMessages = !nonEmptyMessages.length
    ? ["This coverage has been declined due to underwriting program guidelines."]
    : uniq(nonEmptyMessages);

  return (
    <Modal
      isOpen
      closeModal={closeModal}
      title="This product was declined for the following reasons"
      label="This product was declined for the following reasons"
    >
      <PolicyTypeHeader>
        <PolicyImage src={policyData.image}></PolicyImage>
        <PolicyTitle>{PRODUCT_LABELS[policyData.policy]}</PolicyTitle>
      </PolicyTypeHeader>
      <ModalContentContainer>
        {uniqueMessages.slice(0, 3).map((message: string) => (
          <DeclinationMessageContainer>
            <CloseIconContainer>
              <CloseIcon width={10} height={10} />
            </CloseIconContainer>
            <DeclinationMessage>{message}</DeclinationMessage>
          </DeclinationMessageContainer>
        ))}
      </ModalContentContainer>
      <ActionContainer>
        <ActionRow>
          <StyledGhostButton onClick={closeModal}>Close</StyledGhostButton>
        </ActionRow>
      </ActionContainer>
    </Modal>
  );
};

const PolicyTypeHeader = styled.div`
  background: ${(props) => props.theme.offWhite};
  min-height: 64px;
  display: flex;
  align-items: center;
`;

const PolicyTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
`;

const PolicyImage = styled.img`
  width: 50px;
  margin: 0 16px 0 24px;
`;

const CloseIconContainer = styled.div`
  border-radius: 50%;
  border: ${(props) => `1px solid ${props.theme.red}`};
  width: fit-content;
  padding: 7px;
  display: flex;
  margin-right: 16px;
  svg path {
    fill: ${(props) => props.theme.red};
  }
`;

const DeclinationMessage = styled.p`
  margin-bottom: 0;
  font-size: 15px;
  font-family: ${(props) => props.theme.secondaryFont};
`;

const DeclinationMessageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledGhostButton = styled(GhostButton)`
  width: 165px;
`;

export default DeclinationModal;
