import { Body, CTAButton, Header, Modal } from "coterie-ui-library";
import { useState } from "react";
import CreditCardForm from "./CreditCardForm";
import { AxiosError } from "axios";
import { StyledModalBody } from "./styles";

interface DetachPolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
  policy: Policy;
}

const renderErrorMsg = (error?: AxiosError<any> | null) => {
  const defaultErrorMsg = "A problem occurred while processing this request.";

  if (!error) return defaultErrorMsg;
  else if (error?.response?.data?.errors?.length) {
    return error.response.data.errors.reduce((accum: string[], err: any) => {
      err?.message && accum.push(err.message);

      return accum;
    }, []);
  } else if (error?.response?.data?.message) {
    return error.response.data.message;
  } else {
    return error.message;
  }
};

const DetachPolicyModal = ({ isOpen, onClose, policy }: DetachPolicyModalProps) => {
  const [error, setError] = useState<AxiosError<any> | null>(null);
  const handleOnSubmit = (networkError?: AxiosError<any>) => {
    if (networkError) {
      setError(networkError);
    } else {
      onClose();
      setError(null);
    }
  };

  return (
    <Modal
      title={`Detach Policy ${policy.policyNumber}`}
      label="detach policy from existing account"
      isOpen={isOpen}
      closeModal={onClose}
    >
      {!error && <CreditCardForm onSubmit={handleOnSubmit} policy={policy} />}
      {Boolean(error) && (
        <StyledModalBody>
          <Header as="h2">Looks like there was a problem...</Header>
          <hr />
          <Body size="large">{renderErrorMsg(error)}</Body>
          <hr />
          <CTAButton onClick={() => setError(null)}>Try Again</CTAButton>
        </StyledModalBody>
      )}
    </Modal>
  );
};

export default DetachPolicyModal;
