import styled, { css } from "styled-components";

const ToolsBtnIconStyles = css`
  position: fixed;
  bottom: 9px;
  left: 110px;
  z-index: 100;
  height: 44px;
  width: 44px;
  cursor: pointer;
`;

export const ToolsBtn = styled.button`
  background: none;
  border: none;
  ${ToolsBtnIconStyles}
`;

export const ToolsIcon = styled.img`
  ${ToolsBtnIconStyles}
`;
