import React, { useState, useEffect, useCallback } from "react";
import { createGlobalStyle } from "styled-components";
import { connect } from "react-redux";
import { ModalField, Column, Row } from "shared/ModalComponents";
import AddressSelector from "../AddressSelector";
import {
  selectApplicationFormFirstName,
  selectApplicationFormLastName,
  selectApplicationFormPhone,
  selectApplicationFormEmail,
  selectApplicationFormStreet,
  selectApplicationFormCity,
  selectApplicationFormState,
  selectApplicationFormZip,
  selectApplicationQuoteErrors,
  isFieldInvalid,
  selectApplicationFormLocation,
} from "redux/selectors/application";
import { updateApplicationFormContactDetails, setQuoteErrors } from "redux/reducers/_applicationReducer";
import Input, { StyledInput } from "styles/Input";
import { findAndFormatErrorMessage } from "utils/formatErrorString";
import { PanelContainer } from "../StyledComponents";
import { debounce } from "lodash";
import useValidateEmail, { getEmailValidationMessage } from "hooks/useValidateEmail";
import { selectApplicationFormUserEmail } from "redux/selectors/user";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
  contactEmail: string;
  mailingAddressStreet: string;
  mailingAddressCity: string;
  mailingAddressState: string;
  mailingAddressZip: string;
  updateApplicationFormContactDetails: (arg0: Application) => void;
  invalidFields: InvalidField[];
  isMailingAddressInvalid: boolean;
  businessLocation: any;
  setQuoteErrors: (arg0: QuoteErrors[]) => void;
  isSimplybind?: boolean;
  emailValidationError: string;
  setEmailValidationError: (arg0: string) => void;
  phoneValidationError?: boolean;
  setPhoneValidationError?: React.Dispatch<React.SetStateAction<boolean>>;
  isEmailInvalid: boolean;
  userEmail: string;
  payButtonWasTouched?: boolean;
}

const ContactInfo = ({
  updateApplicationFormContactDetails,
  setQuoteErrors,
  emailValidationError,
  setEmailValidationError,
  phoneValidationError,
  setPhoneValidationError,
  isEmailInvalid,
  userEmail,
  payButtonWasTouched,
  ...props
}: PropTypes) => {
  const [contactFirstName, setContactFirstName] = useState(props.contactFirstName);
  const [contactLastName, setContactLastName] = useState(props.contactLastName);
  const [contactPhone, setContactPhone] = useState(props.contactPhone);
  const [contactEmail, setContactEmail] = useState(props.contactEmail);
  const [emailValidationWarning, setEmailValidationWarning] = useState("");
  const [showEmailValidationWarningArrow, setShowEmailValidationWarningArrow] = useState(true);
  const emailValidator = useValidateEmail();
  const { useValidateEmailEndpoint } = useFeatureFlags();

  const debounceApplicationUpdate = useCallback(
    debounce((name, value) => {
      updateApplicationFormContactDetails({ [name]: value });
    }, 400),
    []
  );

  const validateEmail = useCallback(
    async (email: string) => {
      return await emailValidator(email);
    },
    [emailValidator]
  );

  useEffect(() => {
    setContactFirstName(props.contactFirstName);
  }, [props.contactFirstName]);

  useEffect(() => {
    setContactLastName(props.contactLastName);
  }, [props.contactLastName]);

  useEffect(() => {
    setContactPhone(props.contactPhone);
    if (props.contactPhone.includes("_")) {
      setPhoneValidationError && setPhoneValidationError(true);
    } else {
      setPhoneValidationError && setPhoneValidationError(false);
    }
  }, [props.contactPhone]);

  useEffect(() => {
    setContactEmail(props.contactEmail);
  }, [props.contactEmail]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    let { name, value } = event.currentTarget;
    debounceApplicationUpdate(name, value);
    switch (name) {
      case "contactFirstName":
        setContactFirstName(value);
        break;
      case "contactLastName":
        setContactLastName(value);
        break;
      case "contactPhone":
        setContactPhone(value);
        break;
      case "contactEmail":
        setContactEmail(value);
        break;
      default:
        return;
    }
  };

  const handleEmailBlur = async (event: React.FormEvent<HTMLInputElement>) => {
    if (!useValidateEmailEndpoint) return;

    const { value } = event.currentTarget;

    setEmailValidationError("");
    setEmailValidationWarning("");

    if (value.toLowerCase() !== userEmail.toLowerCase() && value !== "") {
      const result = await validateEmail(value);
      if (result.isWarning) {
        setEmailValidationWarning(getEmailValidationMessage(result, value));
        setShowEmailValidationWarningArrow(result.issueType !== "PossibleTypo");
      } else if (!result.isValid) {
        setEmailValidationError(getEmailValidationMessage(result, value));
      }
    }
  };

  const changeAddress = (address: Address, isVerified: boolean) => {
    if (isVerified && address.street) {
      updateApplicationFormContactDetails({
        mailingAddressStreet: address.street,
        mailingAddressCity: address.city,
        mailingAddressState: address.state,
        mailingAddressZip: address.zip,
      });
    }
  };

  return (
    <PanelContainer id="contact-info">
      <OverrideStyle />
      <Row>
        <Column>
          <Input
            id="quote-first-name"
            datacy="first-name"
            name="contactFirstName"
            value={contactFirstName}
            handleChange={handleChange}
            placeholder="Enter first name"
            label="First Name"
            halfwidth={!props.isSimplybind}
            error={payButtonWasTouched && Boolean(props.invalidFields.find((f) => f.field === "ContactFirstName"))}
            errorText={findAndFormatErrorMessage("ContactFirstName", props.invalidFields)}
          />
        </Column>
        <Column>
          <Input
            id="quote-last-name"
            datacy="last-name"
            name="contactLastName"
            value={contactLastName}
            handleChange={handleChange}
            placeholder="Enter last name"
            label="Last Name"
            halfwidth={!props.isSimplybind}
            error={payButtonWasTouched && Boolean(props.invalidFields.find((f) => f.field === "ContactLastName"))}
            errorText={findAndFormatErrorMessage("ContactLastName", props.invalidFields)}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Input
            id="quote-contact-phone"
            name="contactPhone"
            value={contactPhone}
            handleChange={handleChange}
            type="tel"
            placeholder="Enter phone number with area code"
            label="Phone Number"
            halfwidth={!props.isSimplybind}
            error={
              (payButtonWasTouched && Boolean(props.invalidFields.find((f) => f.field === "ContactPhone"))) ||
              phoneValidationError
            }
            errorText={
              findAndFormatErrorMessage("ContactPhone", props.invalidFields) ?? "Please enter a valid phone number"
            }
          />
        </Column>
        <Column>
          <Input
            id="quote-contact-email"
            name="contactEmail"
            value={contactEmail}
            handleChange={handleChange}
            handleBlur={handleEmailBlur}
            placeholder="Enter a valid Policyholder email address"
            label="Policyholder Email Address"
            halfwidth={!props.isSimplybind}
            error={
              useValidateEmailEndpoint
                ? payButtonWasTouched && (Boolean(emailValidationError) || isEmailInvalid)
                : payButtonWasTouched && Boolean(props.invalidFields.find((f) => f.field === "ContactEmail"))
            }
            errorText={
              useValidateEmailEndpoint
                ? findAndFormatErrorMessage("ContactEmail", props.invalidFields) ?? emailValidationError
                : findAndFormatErrorMessage("ContactEmail", props.invalidFields)
            }
            warning={Boolean(emailValidationWarning) && !Boolean(emailValidationError) && !isEmailInvalid}
            warningText={emailValidationWarning}
            showWarningArrow={showEmailValidationWarningArrow}
            toolTipContent="EMAIL_ADDRESS"
            data-testid="contactEmail"
          />
        </Column>
      </Row>
      <form autoComplete={props.isSimplybind ? "off" : "on"} onSubmit={(e) => e.preventDefault()}>
        <ModalField>Mailing address</ModalField>
        <AddressSelector
          id="quote-mailing-address"
          street={props.mailingAddressStreet}
          city={props.mailingAddressCity}
          state={props.mailingAddressState}
          zip={props.mailingAddressZip}
          changeAddress={changeAddress}
          name="mailing"
          invalid={props.isMailingAddressInvalid}
          alreadyEnteredAddress={props.businessLocation}
          shouldUpdateAddressOnChange={props.isSimplybind}
        />
      </form>
    </PanelContainer>
  );
};

const OverrideStyle = createGlobalStyle`
  input${StyledInput} {
    max-width: 600px;
  }
`;

const mapStateToProps = (state: ReduxState) => ({
  contactFirstName: selectApplicationFormFirstName(state),
  contactLastName: selectApplicationFormLastName(state),
  contactPhone: selectApplicationFormPhone(state),
  contactEmail: selectApplicationFormEmail(state),
  mailingAddressStreet: selectApplicationFormStreet(state),
  mailingAddressCity: selectApplicationFormCity(state),
  mailingAddressState: selectApplicationFormState(state),
  mailingAddressZip: selectApplicationFormZip(state),
  invalidFields: selectApplicationQuoteErrors(state),
  isMailingAddressInvalid: isFieldInvalid("MailingAddressStreet")(state),
  isEmailInvalid: isFieldInvalid("ContactEmail")(state),
  businessLocation: selectApplicationFormLocation(state),
  userEmail: selectApplicationFormUserEmail(state),
});

const mapDispatchToProps = { updateApplicationFormContactDetails, setQuoteErrors };

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
