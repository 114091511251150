import styled from "styled-components";
import formotivInstance from "utils/formotiv";
import { isCanadaEnv } from "utils/environmentChecker";
import { NavListItem } from "./styles";
import { NavLinkStyles } from "styles";

const AgentResources = () => (
  <>
    <br />
    <NavListItem onClick={() => formotivInstance.config && formotivInstance.submitFinal()} isResourceLink>
      <AgentResourcesLink
        target="_blank"
        rel="noopener noreferrer"
        href={`https://${process.env.REACT_APP_DOMAIN}/producer-resources`}
      >
        {isCanadaEnv() ? "Broker Resources" : "Resources"}
      </AgentResourcesLink>
    </NavListItem>
  </>
);

export default AgentResources;

const AgentResourcesLink = styled.a`
  ${NavLinkStyles}
  padding: 12px 20px;

  border: 1px solid ${(props) => props.theme.borderGray};
  border-radius: 24px;
  font-size: 13px;
  padding: 8px 24px;
  display: inline;
  width: 150px;

  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.blue};
    color: ${(props) => props.theme.link};
  }

  &.active,
  &:hover {
    background-color: ${(props) => props.theme.blue};
    border: 1px solid ${(props) => props.theme.blue};
    color: ${(props) => props.theme.white};
    border-radius: 24px;
  }
`;
