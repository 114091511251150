import React, { useState } from "react";
import LabelLarge from "shared/LabelLarge";
import styled from "styled-components";
import ColorSelect from "./ColorSelect";
import CompanyLogoUpload from "./CompanyLogoUpload";
import DropInScriptPreview, { DropInScriptPrevBtn } from "./DropInScriptPreview";
import MultiSelectIndustrySearch from "./MultiSelectIndustrySearch";
import OptionalDropin from "./OptionalDropin";

interface DesignTabProps {
  channelPartnerGuid: string;
}

const defaultStates = {
  color: {
    primary: "",
    primaryOne: "",
    secondary: "",
  },
  modalSize: {
    modalHeight: "",
    modalWidth: "",
  },
  industries: [],
};

const DesignTab = ({ channelPartnerGuid }: DesignTabProps) => {
  const [modalSize, updateModalSize] = useState<SizeAttrTypes>(defaultStates.modalSize);
  const [color, setColor] = useState<ColorAttrTypes>(defaultStates.color);
  const [selectedIndustries, setSelectedIndustries] = useState<IndustryListType[]>(defaultStates.industries);
  const [logoSrc, setLogoSrc] = useState<string>("");

  return (
    <>
      <Section>
        <LabelLarge>Quote Flow</LabelLarge>
        <QuoteFlowItems>
          <ColorSelect color={color} setColor={setColor} />
          <CompanyLogoUpload setLogoSrc={setLogoSrc} />
        </QuoteFlowItems>
      </Section>
      <Hr />
      <Section>
        <DropInHeaderCont>
          <LabelLarge>Drop-In</LabelLarge>
          <DropInScriptPrevBtn
            selectedIndustries={selectedIndustries}
            token={channelPartnerGuid}
            modalSizes={modalSize}
            color={color}
            logoSrc={logoSrc}
          />
        </DropInHeaderCont>
        <OptionalDropin modalSize={modalSize} updateModalSize={updateModalSize} />
        <MultiSelectIndustrySearch
          selectedIndustries={selectedIndustries}
          setSelectedIndustries={setSelectedIndustries}
        />
        <DropInScriptPreview
          selectedIndustries={selectedIndustries}
          token={channelPartnerGuid}
          modalSizes={modalSize}
          color={color}
          logoSrc={logoSrc}
        />
      </Section>
    </>
  );
};

const DropInHeaderCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;

const QuoteFlowItems = styled.div`
  display: flex;
  flex-direction: row;
`;
const Hr = styled.hr`
  width: 100%;
  background: ${(props) => props.theme.regularGray};
  margin: 22px 0;
`;

export default DesignTab;
