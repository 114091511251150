import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import { theme, GlobalStyle } from "styles";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import "bootstrap/dist/css/bootstrap.min.css";
import "styles/index.css";
import configureStore from "redux/configureStore";
import AuthRoutes from "layout/AuthRoutes";
import Auth0ProviderWithHistory from "layout/auth0-provider-with-history";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { FeatureFlagProvider } from "toggle_tools/featureFlagTools";
import { sentrySetup } from "./utils/sentry";

const { store, persistor } = configureStore();
export const reduxStore = store;

const loadHubspot = () => {
  const script = document.createElement("script");
  script.id = "hs-script-loader";
  script.type = "text/javascript";
  script.src = "//js.hs-scripts.com/8588529.js";
  document.head.appendChild(script);
};

if (!window.Cypress) {
  if (window.location.hostname.includes("dashboard")) {
    loadHubspot();
  }
} else {
  const shouldUseAccept = window.Cypress.env("use_accept_js");

  if (!shouldUseAccept) {
    // mock accept js for Cypress
    window.Accept = {
      dispatchData: (_request, callback) => {
        callback({
          messages: {
            resultCode: "Ok",
            message: "",
          },
          opaqueData: {
            dataValue: "asdf",
          },
        });
      },
    };
  }
}

window.purgeState = () => persistor.purge();

sentrySetup();

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/login/callback",
});

const App = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <ToastContainer />
            <AuthRoutes />
            <Route path="/login/callback" component={LoginCallback} />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Security>
  );
};

// Commenting axe code out due to cypress failures
// if (process.env.NODE_ENV !== "production" && isCypressEnv) {
//   const axe = require("@axe-core/react");
//   const config = {
//     rules: [
//       {
//         id: "region",
//         enabled: false,
//       },
//       { id: "empty-heading", enabled: false },
//       { id: "landmark-one-main", enabled: false },
//       { id: "heading-order", enabled: false },
//     ],
//   };
//   axe(React, ReactDOM, 1000, config);
// }

(async () => {
  const LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    logger: "none",
  });

  const container = document.getElementById("root");
  const root = createRoot(container);

  root.render(
    <Router>
      <Auth0ProviderWithHistory>
        <LaunchDarklyProvider>
          <FeatureFlagProvider>
            <App />
          </FeatureFlagProvider>
        </LaunchDarklyProvider>
      </Auth0ProviderWithHistory>
    </Router>
  );
})();
