import { useMutation } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { portalApi } from "./useAPI/instances";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

type RequestPayloadType = {
  password: string;
};

const useUpdateAgentPassword = (accountId: string) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const mutationFn = async (requestPayload: RequestPayloadType) => {
    const token = await getToken();
    const api = portalApi(apiVersion, token);

    return api.post(`/accounts/${accountId}/updatePassword`, requestPayload);
  };

  return useMutation({ mutationFn });
};

export default useUpdateAgentPassword;
