import React, { useState } from "react";
import { Modal, ModalActionBar, ModalBody } from "coterie-ui-library";
import { Row } from "shared/ModalComponents";
import { Input } from "styles";
import { reportError } from "utils/reportError";

interface PropTypes {
  account: AccountType;
  editAccount: (accountId: number, updateRequest: UpdateAccountRequest) => Promise<void>;
  closeModal: () => void;
  modalIsOpen: boolean;
}

const EditAccountModal = ({ account, editAccount, closeModal, modalIsOpen }: PropTypes) => {
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState(account?.firstName);
  const [lastName, setLastName] = useState(account?.lastName);
  const [email, setEmail] = useState(account?.email);

  const handleSubmit = (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    setIsEditing(true);

    const firstNameUpdate = account?.firstName === firstName ? undefined : firstName;
    const lastNameUpdate = account?.lastName === lastName ? undefined : lastName;
    const emailUpdate = account?.email === email ? undefined : email;

    editAccount(account.id, {
      firstName: firstNameUpdate,
      lastName: lastNameUpdate,
      email: emailUpdate,
    })
      .catch((error) => reportError(error))
      .finally(() => setIsEditing(false));
  };

  return (
    <Modal
      title="Policyholder Account"
      label="Edit Policyholder Account Screen"
      isOpen={modalIsOpen}
      closeModal={closeModal}
    >
      <ModalBody>
        <Row>
          <Input
            label="First Name"
            name="firstName"
            value={firstName}
            type="text"
            handleChange={(e) => setFirstName(e.target.value)}
            halfwidth
          />
          <Input
            label="Last Name"
            name="lastName"
            value={lastName}
            type="text"
            handleChange={(e) => setLastName(e.target.value)}
            halfwidth
          />
        </Row>
        <Row style={{ width: "100%" }}>
          <Input
            label="Policyholder Email Address"
            name="email"
            value={email}
            type="text"
            handleChange={(e) => setEmail(e.target.value)}
            halfwidth
          />
        </Row>
      </ModalBody>
      <ModalActionBar
        handleCTAOnClick={handleSubmit}
        ctaText={isEditing ? "..." : "Edit Account"}
        ctaDisabled={isEditing}
        handleSecondaryOnClick={closeModal}
        secondaryText={"Cancel"}
      />
    </Modal>
  );
};

export default EditAccountModal;
