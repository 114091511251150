import { useQuery } from "@tanstack/react-query";
import useAuth from "./useAuth";
import Api from "utils/api";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const useGetCancelPreview = (policyNumber: string, cancelDate: string) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const getCancelPreview = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api.cancel.getCancelPreview(policyNumber, cancelDate);
  };

  return useQuery({
    queryKey: ["getCancelPreview", policyNumber, cancelDate],
    queryFn: getCancelPreview,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!cancelDate,
  });
};

export default useGetCancelPreview;
