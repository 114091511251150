import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Header as UIHeader, Body as UIBody } from "coterie-ui-library";
import styled from "styled-components";
import Coverage from "./Coverage";
import BopOptions from "./BopOptions";
import { theme } from "styles";
import { updateApplicationForm } from "redux/reducers/_applicationReducer";
import { selectApplicationFormId } from "redux/selectors/application";
import { PolicyType } from "types/enums";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import { statesExcludedFromProfessionalLiability } from "utils/constants";

const selectionsToArray = (bopSelected: boolean, glSelected: boolean, plSelected: boolean) => {
  return [
    [bopSelected, PolicyType.BOP],
    [glSelected, PolicyType.GL],
    [plSelected, PolicyType.PL],
  ]
    .map(([condition, value]) => {
      if (condition) return value as string;
      return "";
    })
    .filter((x) => x !== "");
};

interface PropTypes {
  updateApplicationForm: (updates: any) => void;
  businessAddressState: string;
  onValidate: (valid: boolean) => void;
  applicationFormId: string;
}

const SelectCoverages = ({ updateApplicationForm, onValidate, applicationFormId, businessAddressState }: PropTypes) => {
  const [bopSelected, setBopSelected] = useState(false);
  const [bopUnavailable, setBopUnavailable] = useState(false);
  const [glSelected, setGlSelected] = useState(false);
  const [glUnavailable, setGlUnavailable] = useState(false);

  const [plSelected, setPlSelected] = useState(false);

  const { showPlUnavailableByState } = useFeatureFlags();

  const [bopBuildingCoverage, setBopBuildingCoverage] = useState(false);
  const [bopBuildingPPCoverage, setBopBuildingPPCoverage] = useState(false);

  const plUnavailable = statesExcludedFromProfessionalLiability.includes(businessAddressState);

  useEffect(() => {
    if (plUnavailable && showPlUnavailableByState) {
      setPlSelected(false);
    }
  }, [businessAddressState]);

  useEffect(() => {
    if (applicationFormId) {
      return;
    }

    setBopUnavailable(false);
    setGlUnavailable(false);

    if (bopSelected) {
      setGlUnavailable(true);
    }
    if (glSelected) {
      setBopUnavailable(true);
    }

    const selections = selectionsToArray(bopSelected, glSelected, plSelected);

    onValidate(Boolean(selections.length) && (bopSelected ? bopBuildingCoverage || bopBuildingPPCoverage : true));

    updateApplicationForm({
      desiredPolicyType: selectionsToArray(bopSelected, glSelected, plSelected),
      bppCoverage: bopBuildingPPCoverage,
      buildingCoverage: bopBuildingCoverage,
    });
  }, [
    bopSelected,
    glSelected,
    plSelected,
    updateApplicationForm,
    bopBuildingCoverage,
    bopBuildingPPCoverage,
    onValidate,
    applicationFormId,
  ]);

  const clearBop = () => {
    setBopSelected(false);
    setBopBuildingCoverage(false);
    setBopBuildingPPCoverage(false);
  };

  return (
    <CoveragesContainer>
      <Title>
        <Header as="h3">Select Coverages:</Header>
        <Body size="small">please select at least one line of business to continue</Body>
      </Title>
      <Coverage
        title="Business Owner's Policy"
        policyType={PolicyType.BOP}
        titleColor={theme.yellow30}
        value={bopSelected}
        onChange={(value) => {
          setGlSelected(false);
          clearBop();
          setBopSelected(value);
        }}
        unavailable={bopUnavailable}
        unavailableMessage="BOP is not available when GL is selected. Select BOP if both liability and property coverage are needed."
      >
        <BopOptions
          options={[
            {
              value: bopBuildingCoverage,
              onChange: setBopBuildingCoverage,
              label: "Include Building coverage",
            },
            {
              value: bopBuildingPPCoverage,
              onChange: setBopBuildingPPCoverage,
              label: "Include Building Personal Property coverage",
            },
          ]}
        />
      </Coverage>
      <Coverage
        title="General Liability"
        policyType={PolicyType.GL}
        titleColor={theme.green80}
        value={glSelected}
        onChange={(value) => {
          clearBop();
          setGlSelected(value);
        }}
        unavailable={glUnavailable}
        unavailableMessage="Standalone General Liability is not available when BOP is selected"
      />
      <Coverage
        title="Professional Liability"
        policyType={PolicyType.PL}
        titleColor={theme.colors.pl.fifty}
        value={plSelected}
        onChange={(value) => {
          if (!showPlUnavailableByState || !plUnavailable) {
            setPlSelected(value);
          } else {
            setPlSelected(false);
          }
        }}
        noPointer={showPlUnavailableByState && plUnavailable}
        unavailable={showPlUnavailableByState && plUnavailable}
        unavailableMessage={`PL is not available in the state of ${businessAddressState}`}
      />
    </CoveragesContainer>
  );
};

const CoveragesContainer = styled.div`
  padding-bottom: 50px;
`;

const Title = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: columns;
  justify-content: space-between;
  margin-top: 18px;
`;

const Header = styled(UIHeader)`
  margin-bottom: 0;
`;

const Body = styled(UIBody)`
  margin-bottom: 1px;
`;

const mapStateToProps = (state: ReduxState) => ({
  applicationFormId: selectApplicationFormId(state),
});

const mapDispatchToProps = { updateApplicationForm };

export default connect(mapStateToProps, mapDispatchToProps)(SelectCoverages);
