export enum Role {
  User = "User",
  Developer = "Developer",
  ChannelPartner = "ChannelPartner",
  IAProducer = "IAProducer",
  IAPartner = "IAPartner",
  Agency = "Agency",
  NonLicensed = "NonLicensed",
}

export enum PolicyType {
  GL = "GL",
  PL = "PL",
  BOP = "BOP",
}

export enum PolicyStatus {
  Pending = "Pending",
  Active = "Active",
  Expired = "Expired",
  Cancelled = "Cancelled",
  PendingCancellation = "PendingCancellation",
}

export enum PaymentFrequency {
  None = "None",
  Monthly = "Monthly",
}

export enum PolicyPaymentType {
  PolicyHolder = "PolicyHolder",
  ChannelPartner = "ChannelPartner",
}

export enum LocationTypes {
  Home = "Home",
  BuildingLeased = "BuildingLeased",
  BuildingOwned = "BuildingOwned",
}

export enum HiredCoverageTypes {
  None = "None",
  HiredAutoLiability = "HiredAutoLiability",
}

export enum NonOwnedCoverageTypes {
  None = "None",
  NonOwnedAutoLiabilityWithoutDeliveryService = "NonOwnedAutoLiabilityWithoutDeliveryService",
  NonOwnedAutoLiabilityWithDeliveryService = "NonOwnedAutoLiabilityWithDeliveryService",
}

export enum EndorsementPackages {
  Silver = "Silver",
  Gold = "Gold",
  Platinum = "Platinum",
  None = "None",
}

export enum ExtensionPackageTypes {
  LiabilityEdge = "liabilityEdge",
  EdgeComplete = "edgeComplete",
  BusinessInterruption = "businessInterruptionExtension",
  ContractorsExtension = "contractorExtension",
}

export enum ApplicationSortBy {
  Status = "Status",
  Name = "Name",
  BusinessName = "BusinessName",
  ApplicationTypes = "ApplicationTypes",
  CreatedOn = "CreatedOn",
}

export enum PolicySortBy {
  Status = "Status",
  Name = "Name",
  Email = "Email",
  PolicyNumber = "PolicyNumber",
  EndDate = "EndDate",
  StartDate = "StartDate",
}

export enum AgentSortBy {
  Name = "Name",
  Email = "Email",
  Id = "Id",
}

export enum AccountSortBy {
  Name = "Name",
  Email = "Email",
  LastUpdated = "LastUpdated",
}

export enum AccountSearchSortBy {
  AccountId = "AccountId",
  PolicyNumber = "PolicyNumber",
  Email = "Email",
}

export enum AccountSearchQueryType {
  Status = "Status",
  Email = "Email",
  PolicyNumber = "PolicyNumber",
  AccountId = "AccountId",
}

export enum AccountSearchOrderByType {
  AccountId = "accountId",
  AccountEmail = "accountEmail",
  PolicyNumber = "policyNumber",
  Status = "status",
  Premium = "premium",
  PremiumPaid = "premiumPaid",
}

export enum SortDirection {
  Ascending = "Ascending",
  Descending = "Descending",
}

export enum ApplicationStatus {
  Incomplete = "Incomplete",
  ReadyToQuote = "ReadyToQuote",
}

export enum ApplicationSource {
  kit = "kit",
  underwriting = "UWE",
  API = "API",
  dashboard = "dashboard",
}

export enum AccountPage {
  AccountSlide = "AccountSlide",
  AgencySlide = "AgencySlide",
  PartnerSlide = "PartnerSlide",
  AffiliationSlide = "AffiliationSlide",
}

export enum AffiliatedStatus {
  NonAffiliated = "NonAffiliated",
  Affiliated = "Affiliated",
  InReview = "InReview",
  ContractOut = "ContractOut",
}

export enum ProfessionalLiability {
  yearsOfPriorActs = "yearsOfPriorActs",
  deductibleAmount = "deductibleAmount",
  occurrenceLimit = "occurrenceLimit",
  aggregateLimit = "aggregateLimit",
  yearsOfProfessionalExperience = "yearsOfProfessionalExperience",
  certificationsRequired = "certificationsRequired",
  certificationsMaintained = "certificationsMaintained",
  periodLoading = "periodLoading",
  grossRevenue = "grossRevenue",
}

export enum CoterieAPIVersion {
  v1 = "v1",
  v1_2 = "v1.2",
  v1_4 = "v1.4",
}

export enum InvalidApplicationKeys {
  PlDeductibleAmount = "PlDeductibleAmount",
}

export enum UnderwritingActions {
  ConfirmIndustry = "ConfirmIndustry",
  Confirmed = "Confirmed",
  Underwrite = "Underwrite",
  AssessRisk = "AssessRisk",
  PreBind = "PreBind",
}

export enum Environments {
  Canada = "Canada",
  Core = "Core",
}

export enum ChargeType {
  Payment = "Payment",
  Refund = "Refund",
  Dispute = "Dispute",
}

export enum FormCategory {
  AdditionalInsured = "AdditionalInsured",
  Endorsement = "Endorsement",
  PolicyInformation = "PolicyInformation",
}
