import React, { useState } from "react";
import styled, { css } from "styled-components";
import green_check_shield from "images/checkmark_shield_green.svg";
import orange_check_shield from "images/checkmark_shield_orange.svg";

export const Container = styled.div<{ expanded?: boolean }>`
  background-color: rgba(${(props) => props.color}, 0.2);
  display: flex;
  padding: 0px 15px;
  height: 30px;
  border-radius: 16px;
  align-items: center;
  margin: 0px 10px 0px 0px;
  cursor: pointer;

  ${(props) =>
    !props.expanded &&
    css`
      max-width: 50px;
    `}
`;

const StatusText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 13px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.charcoalGrey};
`;

const Icon = styled.img`
  margin-right: 10px;
`;

interface PropTypes {
  status: "Affiliated" | "NonAffiliated";
  expanded?: boolean;
}

export const AFFILICATED_STATUSES = {
  Affiliated: { img: green_check_shield, color: "56, 224, 156", label: "Affiliated" },
  NonAffiliated: { img: orange_check_shield, color: "255, 175, 69", label: "Not affiliated" },
};

const AffiliatedStatusBadge = ({ status = "NonAffiliated", expanded = false }: PropTypes) => {
  const [show, toggleshow] = useState(false);
  const currentStatus = AFFILICATED_STATUSES[status];
  return (
    <Container
      data-testid="affiliated-status-badge"
      onMouseEnter={() => toggleshow(!show)}
      onMouseLeave={() => toggleshow(!show)}
      color={currentStatus.color}
      expanded={show || expanded}
    >
      <Icon src={currentStatus.img} alt={`Status is ${currentStatus.label}`} />
      {(show || expanded) && <StatusText>{currentStatus.label || status}</StatusText>}
    </Container>
  );
};

export default AffiliatedStatusBadge;
