export const selectUnderwritingProfile = (state: ReduxState) => state.underwritingProfile || {};
export const selectUnderwritingProfileId = (state: ReduxState) =>
  state.underwritingProfile?.underwritingProfileId || "";
export const selectUnderwritingProfileDetailsData = (state: ReduxState) =>
  state.underwritingProfile?.details?.data || {};
export const selectUnderwritingProfileEndorsementDetails = (state: ReduxState) =>
  state.underwritingProfile?.endorsementDetails || {};
export const selectUnderwritingProfilePaymentFrequency = (state: ReduxState) =>
  state.underwritingProfile?.paymentFrequency;
export const selectUnderwritingProfileDeniedCoverages = (state: ReduxState) =>
  state.underwritingProfile?.deniedCoverages;
export const selectUnderwritingProfileDeclinationLedger = (state: ReduxState) =>
  state.underwritingProfile?.declinationLedger;
export const selectUnderwritingProfileIsLoading = (state: ReduxState) =>
  state.underwritingProfile?.loadingUnderwritingProfile;
export const selectUnderwritingProfileIndustrySuggestions = (state: ReduxState) =>
  state.underwritingProfile?.industrySuggestions;
export const selectUnderwritingProfileExclusions = (state: ReduxState) => state.underwritingProfile?.exclusions;
