import { useMutation } from "@tanstack/react-query";
import Api from "utils/api";
import useAuth from "./useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface UpdateApplicationArgs {
  application: Application;
  token?: string;
}

const usePostPublicApplication = (onSuccess: () => void) => {
  const { apiVersion } = useFeatureFlags();

  const updateApplication = async ({ application, token }: UpdateApplicationArgs) => {
    const api = new Api(apiVersion, token);

    return api.application.publicPostApplication(application, token);
  };

  return useMutation({
    mutationFn: updateApplication,
    onSuccess,
  });
};

export default usePostPublicApplication;
