import LogRocket from "logrocket";

export const logrocketIdentify = (externalId: string, userName: string, userEmail: string) => {
  if (
    (process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "sandbox") &&
    externalId &&
    LogRocket.identify
  ) {
    LogRocket.identify(externalId, {
      name: userName,
      email: userEmail,
    });
  }
};

export const logrocketInit = () => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    LogRocket.init("jkmawz/dashboard-diabj");
  }
  if (process.env.REACT_APP_ENVIRONMENT === "sandbox") {
    LogRocket.init("jkmawz/sandbox-dashboard-agsot");
  }
};
