import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import { theme } from "styles";
import { useEffect } from "react";

const titleStyle = `color: ${theme.textNavyBlue}; font-size: 20px; background-color: white; padding: 0 10px`;
const textStyle = `color: ${theme.textNavyBlue}; background-color: white; padding: 0 10px`;

const initCoterieTool = (featureFlags: any) => {
  // @ts-ignore
  if (window.coterieTool) {
    // @ts-ignore
    window.coterieTool.featureFlags = () => {
      return console.table(featureFlags);
    };
    // @ts-ignore
    return window.coterieTool;
  }

  console.log("%cWelcome to Coterie Tool", titleStyle);

  const coterieTool = {
    featureFlags: () => {
      return console.table(featureFlags);
    },
    help: (command?: string) => {
      if (!command) {
        console.group();
        console.log("%cCommands:", textStyle);
        console.log("%cfeatureFlags: show all feature flag settings", textStyle);
        console.groupEnd();
      }
      switch (command) {
        case "featureFlags":
          console.log("%cshow all feature flag settings", textStyle);
      }
    },
  };

  // @ts-ignore
  window.coterieTool = window.c = coterieTool;
  return coterieTool;
};

const useCoterieTool = () => {
  const featureFlags = useFeatureFlags();

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "production") {
      initCoterieTool(featureFlags);
    }
  }, [featureFlags]);
};

const CoterieTool = () => {
  useCoterieTool();
  return null;
};

export default CoterieTool;
