import React from "react";
import styled from "styled-components";
import Dropdown, { StyleTypes, OptionType, dropdownStyles } from "styles/Dropdown";
import { Dictionary } from "lodash";
import { theme } from "styles";

const sortingOptions = [
  {
    label: "All Rules",
    value: "All Rules",
  },
  { label: "Oldest", value: "Oldest" },
  { label: "Newest", value: "Newest" },
  { label: "Active Rules", value: "Active" },
  { label: "Inactive Rules", value: "Inactive" },
];

interface SortByDropdownProps {
  onChange: (option: OptionType) => void;
}

const SortByDropdown = ({ onChange }: SortByDropdownProps) => {
  return (
    <SortByContainer>
      <SortByText>Sort By</SortByText>
      <DropdownContainer>
        <Dropdown
          options={sortingOptions}
          onChange={onChange}
          defaultValue={sortingOptions.find((option) => option.value === "All Rules")}
          customStyles={DropdownStyles}
          full
          labelIsHidden
          isSearchable={false}
          label="Sort Rules"
        />
      </DropdownContainer>
    </SortByContainer>
  );
};

const SortByContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SortByText = styled.p`
  margin: 0 16px 0 0;
  font-size: 16px;
  font-weight: 600;
`;

const DropdownContainer = styled.div`
  width: fit-content;
`;

const DropdownStyles = {
  container: (styles: Dictionary<any>) => {
    return {
      ...styles,
      margin: 0,
    };
  },
  control: (styles: Dictionary<any>, { isFocused }: StyleTypes) => {
    return {
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "center",
      justifyContent: "right",
      fontSize: "16px",
      border: isFocused && `1px dotted ${theme.blue}`,
    };
  },
  option: dropdownStyles.option,
  singleValue: () => {
    return {
      width: "100%",
      marginRight: "0",
      color: theme.blue,
      fontWeight: 600,
    };
  },
  indicatorsContainer: () => {
    return {
      cursor: "pointer",
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      width: 220,
      right: 0,
    };
  },
  dropdownIndicator: () => {
    return {
      color: theme.blue,
    };
  },
  valueContainer: () => {
    return {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    };
  },
};

export default SortByDropdown;
