const matchCardType = (cardType: string) => {
  switch (cardType) {
    case "American Express":
      return "amex";
    case "Diner's Club":
      return "diners";
    case "Discover":
      return "discover";
    case "JCB":
      return "jcb";
    case "MasterCard":
      return "master-card";
    case "Visa":
      return "visa";
    case "UnionPay":
      return "unionpay";
    case "Unknown":
      return "unknown";
    default:
      return cardType;
  }
};

export default matchCardType;
