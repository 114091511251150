import React from "react";
import JSONPretty from "react-json-pretty";

interface PropTypes {
  endorsements: EndorsementsType;
}

export default class Endorsements extends React.Component<PropTypes> {
  getProjectBasedCoverage(): React.ReactNode {
    let { endorsements } = this.props;
    if (!endorsements.projectBasedCoverage) return <span />;

    let coverage = endorsements.projectBasedCoverage;

    return (
      <div>
        <div className="title">Project Based Coverage</div>

        <div>ProjectId: {coverage.projectId}</div>
        <div>Description: {coverage.description}</div>
        <div>Hourly Rate: ${coverage.hourlyRate}</div>

        <div>{coverage.street}</div>
        <div>
          {coverage.city}, {coverage.state} {coverage.zip}
        </div>
      </div>
    );
  }

  render(): React.ReactNode {
    let jsonString = JSON.stringify(this.props.endorsements);

    return (
      <div>
        <JSONPretty id="json-pretty" json={jsonString}></JSONPretty>
      </div>
    );
  }
}
