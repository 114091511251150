import { createAsyncThunk } from "@reduxjs/toolkit";
import { setBindable, setQuote } from "redux/reducers/_applicationReducer";
import Api from "utils/api";
import { reportError } from "utils/reportError";
import { setLoading } from "./getQuote";

const getQuoteEstimate: any = createAsyncThunk(
  "quote/getQuoteEstimate",
  ({ isUpdating, token, apiVersion }: any, thunkAPI: any) => {
    const { getState, dispatch } = thunkAPI;
    const {
      application: { form },
    } = getState();

    const api = new Api(apiVersion, token);

    api.application
      .getEstimateQuote(form)
      .then((response: any) => {
        if (response.isSuccess === true) {
          dispatch(setQuote(response.quote));
        } else {
          dispatch(setBindable(false));
        }
      })
      .catch((err: any) => reportError(err))
      .finally(() => setLoading(isUpdating, dispatch, false));
  }
);

export default getQuoteEstimate;
