import React, { useCallback } from "react";
import { FileRejection } from "react-dropzone";
import FileUploader from "shared/FileUploader";
import trashcan from "images/delete-trash-can.svg";
import alert from "images/alert-red.svg";
import checkmark from "images/checkmark-circle.svg";
import styled from "styled-components";
import { filesize } from "filesize";
import { v4 as uuidv4 } from "uuid";
import { Label } from "coterie-ui-library";
import { CancelFile, CancellationForm } from "./types";
import { useFormContext, useWatch } from "react-hook-form";
import { CancelError } from "./CancelStyles";
import { ReactComponent as AlertIcon } from "images/alert-red.svg";
import uniqBy from "lodash/uniqBy";

export default function CancelDocuments() {
  const {
    setValue,
    formState: { errors },
  } = useFormContext<CancellationForm>();
  const { files: cancelFiles } = useWatch<CancellationForm>();
  const files = cancelFiles as CancelFile[];

  const removeFile = (docId: string) => {
    const updatedFiles = files?.filter((file) => file.id !== docId);
    setValue("files", updatedFiles as CancelFile[], { shouldValidate: true });
  };

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejectsions: FileRejection[]) => {
      if (acceptedFiles.length > 0) {
        const mapAcceptedFiles = acceptedFiles.map((file) => ({ id: uuidv4(), file, status: "valid" })) as CancelFile[];
        setValue("files", uniqBy([...mapAcceptedFiles, ...files], "file.name"), { shouldValidate: true });
      }

      if (fileRejectsions.length > 0) {
        const mapRejectedFiles = fileRejectsions.map((file) => ({
          id: uuidv4(),
          file: file.file,
          status: "invalid",
          errors: file.errors,
        })) as CancelFile[];
        setValue("files", uniqBy([...mapRejectedFiles, ...files], "file.name"), { shouldValidate: true });
      }
    },
    [files, setValue]
  );
  return (
    <div>
      <Label size="large">Upload any supporting documents</Label>
      <LeadText>An insured’s written cancellation request is required to submit your cancellation request.</LeadText>
      <FileUploader
        accept={{ "application/pdf": [".pdf"] }}
        onDrop={onDrop}
        acceptedFormatsText=".pdf"
        acceptedSizeText="5 MB"
        maxSize={5242880}
        width="493px"
        height="238px"
      />
      {files.length > 0 && (
        <PreviewTable>
          <thead>
            <tr>
              <th colSpan={4}>Files uploaded</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file) => {
              const { id, status, errors } = file;
              return (
                <tr key={id}>
                  <FileStatus>
                    <img
                      src={status === "valid" ? checkmark : alert}
                      alt={`${status} file icon for ${file.file?.name}`}
                      aria-label={`${status} file icon for ${file.file?.name}`}
                    />
                  </FileStatus>
                  <FileName>
                    {file.file?.name}
                    {errors && <ErrorMessage>{errors[0].message}</ErrorMessage>}
                  </FileName>
                  <FileSize>{filesize(file.file?.size as number)}</FileSize>
                  <RemoveFile>
                    <button
                      onClick={() => removeFile(id as string)}
                      aria-label={`remove file button for ${file.file?.name}`}
                    >
                      <img src={trashcan} alt="remove file icon" />
                    </button>
                  </RemoveFile>
                </tr>
              );
            })}
          </tbody>
        </PreviewTable>
      )}
      {errors.files && (
        <CancelError>
          <AlertIcon />
          {errors.files.message}
        </CancelError>
      )}
    </div>
  );
}

const PreviewTable = styled.table`
  width: 100%;
  max-width: 493px;
  border-collapse: collapse;
  margin-top: 8px;
  thead {
    background-color: ${({ theme }) => theme.colors.neutral.special};
    th {
      overflow: hidden;
      color: ${({ theme }) => theme.colors.tertiary.zero};
      text-overflow: ellipsis;
      font-family: ${({ theme }) => theme.font.typeface.primary};
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 15px;
      padding: 12px 8px 12px 16px;
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.neutral.special};
    }
    td {
      padding: 8px 16px;
    }
  }
`;

const FileName = styled.td`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.tertiary.zero};
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
`;

const FileSize = styled.td`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.neutral.dark};
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const FileStatus = styled.td`
  min-width: 50px;
  img {
    margin: 0 auto;
    max-width: 100%;
  }
`;

const RemoveFile = styled.td`
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.alert.zero};
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.09px;
`;

const LeadText = styled.p`
  color: ${({ theme }) => theme.colors.neutral.dark};
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
