import styled, { css } from "styled-components";
import { mobile, tablet, laptop, desktop, hideForPrint, Breadcrumb } from "styles";

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: ${(props) => props.theme.primaryFont};
  color: ${(props) => props.theme.fontColors.primary};
  max-width: 1120px;

  .card {
    background: none;
    border: none;
    margin-bottom: 24px;

    .additional-insureds-body {
      background: ${(props) => props.theme.white};
      min-height: 40px;
      padding: 5px;
      line-height: 32px;
    }

    .endorsements-card-body {
      background: ${(props) => props.theme.white};
      padding: 8px;
    }

    .charges-body {
      min-height: 40px;
      background: ${(props) => props.theme.white};
    }

    .card-title {
      color: ${(props) => props.theme.fontColors.primary};
      font-family: ${(props) => props.theme.primaryFont};
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 22px;
      margin: 8px 0;
    }

    .card-body {
      padding: 0;

      li {
        background: ${(props) => props.theme.white};
        padding: 5px;
        line-height: 32px;
        margin-bottom: 2px;
      }

      .form-group {
        margin-bottom: 2px;

        .input-group {
          height: 40px;

          .input-group-prepend {
            background-color: ${(props) => props.theme.labelGray};
          }

          .input-group-text {
            text-transform: uppercase;
            font-family: ${(props) => props.theme.primaryFont};
            font-size: 11px;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 16px;
            color: ${(props) => props.theme.fontColors.primary};

            border: 0;
            border-radius: 0;
            width: 150px;

            height: 100%;
          }

          textarea {
            flex: 1;
            border: 0;
            background: ${(props) => props.theme.white};
            padding: 10px;
            color: ${(props) => props.theme.fontColors.primary};
            resize: none;
            color: ${(props) => props.theme.gray};
            font-family: ${(props) => props.theme.secondaryFont};
            font-size: 15px;
            letter-spacing: 0;
            line-height: 24px;
          }

          .form-control {
            border: 0;
            border-radius: 0;
            height: 100%;
            background: ${(props) => props.theme.white};
            color: ${(props) => props.theme.gray};
            font-family: ${(props) => props.theme.secondaryFont};
            font-size: 15px;
            letter-spacing: 0;
            line-height: 24px;
          }
        }
      }
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  grid-column-gap: 48px;
  margin-bottom: 48px;

  ${desktop} {
    grid-column-gap: 3vw;
  }

  ${tablet} {
    flex-direction: column;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 536px;
  width: 536px;

  ${desktop} {
    min-width: 440px;
    width: auto;
  }

  ${laptop} {
    min-width: 340px;
    width: auto;
  }

  ${tablet} {
    width: 536px;
  }

  .card-title {
    font-weight: bold;
  }
  .form-control:disabled {
    background: #f7f7f7;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  .input-group-text {
    min-width: 10em;
  }
`;

export const BreadcrumbContainer = styled.div<{ useUpdatedBackground?: boolean }>`
  width: 100%;
  max-width: 1120px;

  ${hideForPrint}

  .breadcrumb {
    background-color: ${({ theme, useUpdatedBackground }) =>
      useUpdatedBackground ? theme.pageComponents.global.background : theme.pageComponents.global.legacyBackground};
    padding: 0px 0px 10px 0px;
  }

  a {
    color: ${(props) => props.theme.blue};
    font-family: ${(props) => props.theme.primaryFont};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    text-decoration: none;

    &:hover {
      color: ${(props) => props.theme.fontColors.primary};
      text-decoration: none;
    }
  }

  li {
    color: ${(props) => props.theme.fontColors.primary};
    font-family: ${(props) => props.theme.primaryFont};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;

  > table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 15px;

    th {
      text-transform: uppercase;
    }

    th,
    td {
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 18px;
      letter-spacing: 0;
      color: ${(props) => props.theme.fontColors.primary};
      font-family: ${(props) => props.theme.primaryFont};
    }

    ${mobile} {
      th,
      td {
        font-size: 12px;
      }
    }

    > tbody {
      > tr {
        height: 50px;
        border-radius: 2px;
        background-color: ${(props) => props.theme.white};

        > td {
          &:first-child {
            padding-left: 20px;
          }
        }
      }
    }
  }
`;

export const PageContainer = styled.div`
  width: 100%;
`;

export const CheckboxLabel = styled.label`
  margin-left: 20px;
`;

export const LoadingDiv = styled.div`
  position: absolute;
  bottom: 10%;
  right: 0;
`;

export const SearchInput = styled.input`
  width: ${(props) => {
    switch (props.width) {
      case "full":
        return "100%";
      case "half":
        return "50%";
      case "quarter":
        return "25%";
      default:
        return "100%";
    }
  }};
  margin: 0 0;
  padding: 7px 28px;
  border: none;
  font-size: 15px;
  background: none;

  &:focus {
    box-shadow: none;
    border-bottom: 1px solid ${(props) => props.theme.blue};
  }
`;

export const SearchContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.lighterGray};
  display: flex;
  flex-direction: row;
  margin-top: -10px;
  ${mobile} {
    margin-top: 0px;
  }
`;

export const TitleBarAccessory = styled.div`
  width: 50%;
  position: relative;
`;

export const SearchIconWrapper = styled.div`
  svg {
    fill: ${(props) => props.theme.blue};
  }
  margin-top: 9px;
  position: absolute;
`;

export const Title = styled.h1<{ hasStatus?: boolean }>`
  color: ${(props) => props.theme.fontColors.primary};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  display: inline-block;
  flex: 1;
  width: max-content;

  ${(props) =>
    props.hasStatus &&
    css`
      flex: none;
      margin-right: 10px;
      margin-bottom: 0px;
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 8px;

  ${tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Space = styled.div`
  margin-bottom: 20px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  font-family: ${(props) => props.theme.primaryFont};
`;

export const Subtitle = styled.div`
  display: flex;
  color: ${(props) => props.theme.gray};
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 2px;
  padding-bottom: 16px;

  ${mobile} {
    flex-direction: column;
  }
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  width: 100%;
`;

export const StyledTitle = styled(Title)`
  flex: 1;
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
  marginbottom: 20px;
`;
