import { useState } from "react";
import { Modal, ModalBody, ModalActionBar, Label, Body, TextInput } from "coterie-ui-library";
import styled from "styled-components";
import { Radio as RadioInput } from "styles/RadioButton";
import Api from "utils/api";
import useAuth from "hooks/useAuth";
import { ReactComponent as AlertIcon } from "images/alert.svg";
import AjaxLoader from "shared/AjaxLoader";
import { ReactComponent as Check } from "images/checkmark_securus.svg";
import ToolTipIcon from "shared/ToolTipIcon";
import { usePostAddAccountSelfService } from "hooks/usePostAddAccountSelfService";
import { validateEmail } from "utils/validateEmail";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  isOpen: boolean;
  closeModal: () => void;
  onAddUser: () => void;
}

const roles = [
  {
    name: "Licensed Producer",
    description: "Add the individual's NPN (national producer number) to attribute accounts to this user.",
    value: "producer",
  },
  {
    name: "Non-Licensed User",
    description: "Non-licensed users must write business using a licensed producer’s NPN.",
    value: "user",
  },
];

const NpnLink = () => (
  <a href="https://nipr.com/help/look-up-your-npn" target="_blank" rel="noreferrer">
    NPN look up tool
  </a>
);

const ErrorMsg = ({ children }: { children: React.ReactNode }) => (
  <ErrorContainer>
    <FieldErrorIcon />
    <ErrorText>{children}</ErrorText>
  </ErrorContainer>
);

const NpnNotFound = () => (
  <ErrorMsg>
    This NPN is invalid. Please ensure this is the individual’s NPN and not their state license number. If you do not
    know the NPN, you can use this <NpnLink />. If this user is not licensed, select “Non-Licensed User” as their role
    above.
  </ErrorMsg>
);

const RequiredError = ({ fieldName }: { fieldName: string }) => <ErrorMsg>{fieldName} is a required field.</ErrorMsg>;

interface MutationError {
  response: any;
}

const AddUserModal = ({ isOpen, closeModal, onAddUser }: PropTypes) => {
  const [role, setRole] = useState<string | undefined>(undefined);
  const [npn, setNpn] = useState<string | undefined>(undefined);
  const [showNpnError, setShowNpnError] = useState<boolean>(false);
  const [npnValid, setNpnValid] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [npnLoading, setNpnLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailValid, setEmailValid] = useState<boolean | undefined>(undefined);
  const [phone, setPhone] = useState<string>("");
  const [duplicateError, setDuplicateError] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const addUserMutation = usePostAddAccountSelfService({
    onSuccess: () => {
      reset();
      onAddUser();
      closeModal();
    },
    onError: (error: MutationError) => {
      setSubmitLoading(false);
      if (error.response.status === 409) {
        setDuplicateError(true);
      }
    },
  });

  const reset = () => {
    setRole(undefined);
    setNpn(undefined);
    setFirstName("");
    setLastName("");
    setNpnValid(false);
    setShowNpnError(false);
    setNpnLoading(false);
    setEmail("");
    setPhone("");
    setDuplicateError(false);
    setEmailValid(undefined);
    setSubmitError(false);
    setSubmitLoading(false);
  };

  const close = () => {
    reset();
    closeModal();
  };

  const changeRole = (roleValue: string) => {
    reset();
    setRole(roleValue);
  };

  const checkNpn = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const npnValue = String(e.target.value);
    setNpn(npnValue);
    setDuplicateError(false);

    if (npnValue.length < 7 || npnValue.length > 8) {
      setNpnValid(false);
      setFirstName("");
      setLastName("");
      return;
    }

    const token = await getToken();
    const api = new Api(apiVersion, token);
    setNpnLoading(true);

    api
      .verifyNPN(npnValue)
      .then((response) => {
        setShowNpnError(false);
        setNpnValid(true);
        setFirstName(response.firstName);
        setLastName(response.lastName);
        setNpnLoading(false);
      })
      .catch(() => {
        setShowNpnError(true);
        setNpnValid(false);
        setNpnLoading(false);
      });
  };

  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = String(e.target.value);
    setEmail(emailValue);
    if (Boolean(emailValue) && !validateEmail(emailValue)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }
    setDuplicateError(false);
  };

  const changePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, "");
    let formattedPhoneNumber = "";

    if (input.length > 0) {
      formattedPhoneNumber = input.slice(0, 3);

      if (input.length > 3) {
        formattedPhoneNumber += "-" + input.slice(3, 6);
      }

      if (input.length > 6) {
        formattedPhoneNumber += "-" + input.slice(6, 10);
      }
    }

    setPhone(formattedPhoneNumber);
  };

  const submitEnabled = () => {
    const shared = firstName && lastName && email && phone && !duplicateError && emailValid;
    switch (role) {
      case "producer":
        return npnValid && shared;
      case "user":
        return shared;
      default:
        return false;
    }
  };

  const submit = () => {
    setSubmitError(false);

    if (!submitEnabled()) {
      setSubmitError(true);
      return;
    }

    setSubmitLoading(true);

    const user = {
      firstName: firstName!,
      lastName: lastName!,
      email: email!,
      contactPhone: phone!,
      nationalProducerNumber: npn!,
      accountType: role === "producer" ? 2 : 3,
    };

    // TODO - asserting to any temp fix to get around typescript bug
    addUserMutation.mutate(user as any);
  };

  return (
    <Modal isOpen={isOpen} closeModal={close} label="add-user" title="Add User">
      <Content>
        <RolesLabel size="large">Select Role:</RolesLabel>
        {roles.map((option) => (
          <RadioGroup key={option.value} role="radiogroup">
            <Radio
              type="radio"
              role="radio"
              name="role"
              value={option.value}
              onChange={() => changeRole(option.value)}
            />
            <RadioLabel selected={role === option.value}>
              <Label size="large">{option.name}</Label>
              <Body size="small">{option.description}</Body>
            </RadioLabel>
          </RadioGroup>
        ))}
        {submitError && !role && <RequiredError fieldName="Role selection" />}

        {role && (
          <UserDetails>
            <DetailLabel size="large">User Details:</DetailLabel>

            {role === "producer" && (
              <DetailSection>
                <DetailLabel size="large">
                  Individual National Producer Number
                  <ToolTipIcon contentKey="NPN" />
                </DetailLabel>
                <TextInput
                  aria-label="Individual National Producer Number"
                  value={npn}
                  onChange={checkNpn}
                  placeholder="XXXXXXXX"
                />
                {npnLoading && <AjaxLoader styles={loaderStyles} width={40} height={24} />}
                {!npnLoading && npnValid && <Checkmark />}
                {!showNpnError && !npnValid && (
                  <InfoText>
                    Please use the individual’s NPN (not your agency's NPN or state license number). If you do not know
                    the NPN, you can use this <NpnLink />.
                  </InfoText>
                )}
                {!showNpnError && npnValid && <InfoText>NPN Verified!</InfoText>}
                {showNpnError && <NpnNotFound />}
                {submitError && !npn && <RequiredError fieldName="Individual National Producer Number" />}
              </DetailSection>
            )}
            {(role === "user" || (role === "producer" && npnValid)) && (
              <>
                <NameFields>
                  <DetailSection>
                    <DetailLabel size="large">First Name</DetailLabel>
                    <TextInput
                      value={firstName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                      disabled={role === "producer"}
                      aria-label="First Name"
                    />
                    {submitError && !firstName && <RequiredError fieldName="First Name" />}
                  </DetailSection>
                  <DetailSection>
                    <DetailLabel size="large">Last Name</DetailLabel>
                    <TextInput
                      value={lastName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                      disabled={role === "producer"}
                      aria-label="Last Name"
                    />
                    {submitError && !lastName && <RequiredError fieldName="Last Name" />}
                  </DetailSection>
                </NameFields>
                <DetailSection>
                  <DetailLabel size="large">Email</DetailLabel>
                  <TextInput value={email} type="email" onChange={changeEmail} aria-label="Email" />
                  {emailValid === false && (
                    <ErrorMsg>
                      "{email}" is not a valid email. Please correct and double check for spaces at the beginning or
                      end.
                    </ErrorMsg>
                  )}
                  {submitError && !email && <RequiredError fieldName="Email" />}
                </DetailSection>
                <DetailSection>
                  <DetailLabel size="large">Phone Number</DetailLabel>
                  <TextInput
                    value={phone}
                    type="tel"
                    placeholder="XXX-XXX-XXXX"
                    onChange={changePhoneNumber}
                    aria-label="Phone"
                  />
                  {submitError && !phone && <RequiredError fieldName="Phone Number" />}
                </DetailSection>
              </>
            )}
            {duplicateError && (
              <Error id="add-user-error">
                <ErrorIcon />
                <div>
                  <Label size="large">An account with the same email or NPN already exists.</Label>
                  <Body size="regular">
                    If you believe this is an error, please reach out to producer@coterieinsurance.com.
                  </Body>
                </div>
              </Error>
            )}
          </UserDetails>
        )}
      </Content>
      <ModalActionBar
        handleCTAOnClick={submit}
        handleSecondaryOnClick={close}
        ctaDisabled={submitLoading}
        secondaryDisabled={submitLoading}
      />
    </Modal>
  );
};

const Content = styled(ModalBody)`
  align-items: flex-start;
  padding: 32px;

  @media (min-height: 960px) {
    max-height: fit-content;
  }
`;

const RolesLabel = styled(Label)`
  margin-bottom: 16px;
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const Radio = styled(RadioInput)`
  width: 24px;
  height: 24px;
`;

const RadioLabel = styled.div<{ selected: boolean }>`
  label {
    color: ${({ selected, theme }) => (selected ? theme.fontColors.tertiary : theme.fontColors.primary)};
  }

  ${Body} {
    color: ${({ selected, theme }) => (selected ? theme.fontColors.tertiary : theme.colors.neutral.dark)};
    margin: 0;
  }
`;

const UserDetails = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.regular};
  margin-top: 24px;
  padding-top: 24px;
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

const DetailSection = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const DetailLabel = styled(Label)`
  display: block;
`;

const InfoText = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.09px;
  color: ${({ theme }) => theme.colors.neutral.medium};
`;

const ErrorContainer = styled.div`
  display: flex;
  gap: 6px;
`;

const FieldErrorIcon = styled(AlertIcon)`
  width: 16px;
  min-width: 16px;
  height: 16px;
  padding-left: 2px;

  g {
    fill: ${(props) => props.theme.colors.alert.zero};
  }
`;

const ErrorText = styled(InfoText)`
  color: ${(props) => props.theme.colors.alert.zero};
`;

const loaderStyles = `
  position: absolute;
  right: 16px;
  top: 42px;
`;

const Checkmark = styled(Check)`
  position: absolute;
  right: 20px;
  top: 44px;

  g {
    fill: ${(props) => props.theme.colors.primary.zero};
  }
`;

const NameFields = styled.div`
  display: flex;
  gap: 16px;
  flex-grow: 1;
`;

const Error = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.alert.twenty};
  color: ${({ theme }) => theme.colors.alert.zero};
  display: flex;
  gap: 16px;
`;

const ErrorIcon = styled(AlertIcon)`
  width: 40px;
  height: 40px;
  margin: 8px;

  g {
    fill: ${(props) => props.theme.colors.alert.zero};
  }
`;

export default AddUserModal;
