import { Icon } from "coterie-ui-library";
import React, { useCallback, useContext, useEffect } from "react";
import { ToastOptions, toast } from "react-toastify";
import styled from "styled-components";
import { theme } from "styles";
import OverduePolicyToastContent from "./OverduePolicyToastContent";
import { PaymentContext } from "context/payment";

type OverduePolicyToastProps = {
  policies: Policy[];
  balances: PolicyBalance[];
};

function OverduePolicyToast({ policies, balances }: OverduePolicyToastProps) {
  const { actions } = useContext(PaymentContext);
  const { setPolicyToCharge } = actions;

  const toastContainer = document.querySelector(".Toastify__toast-container") as HTMLElement;

  const openPaymentDrawer = useCallback(
    (policy: Policy, balance: PolicyBalance): void => {
      setPolicyToCharge({
        ...policy,
        overdue: balance.overdueAmount,
        transactionFeeAmount: balance.transactionFeeAmount,
        transactionFeeEnabled: balance.transactionFeeEnabled,
      });
    },
    [setPolicyToCharge]
  );

  const displayOverduePolicyToast = useCallback(
    (policy: Policy, balance: PolicyBalance, options?: ToastOptions): void => {
      toast.error(
        <OverduePolicyToastContent policy={policy} balance={balance} openPaymentDrawer={openPaymentDrawer} />,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: "overdue-policy-toast",
          autoClose: false,
          closeButton: (
            <div>
              <CloseButton onClick={() => toast.dismiss(policy.policyNumber)}>
                <Icon
                  name="close"
                  label="overdue policy toast close icon"
                  size={10}
                  colorOverride={theme.colors.neutral.medium}
                />
              </CloseButton>
            </div>
          ),
          closeOnClick: false,
          draggable: false,
          icon: (
            <Icon
              name="alert"
              label="overdue policy toast alert icon"
              size={40}
              colorOverride={theme.colors.alert.dark}
            />
          ),
          toastId: policy.policyNumber,
          ...options,
        }
      );
    },
    [openPaymentDrawer]
  );

  const loadPolicyBalanceToasts = useCallback((): void => {
    balances?.forEach((balance, index) => {
      if (balance.isOverdue) {
        const policy = policies.find((policy: Policy) => policy.policyNumber === balance.policyNumber) as Policy;
        displayOverduePolicyToast(policy, balance, { delay: index * 100 });
      }
    });
  }, [policies, balances, displayOverduePolicyToast]);

  useEffect(() => {
    loadPolicyBalanceToasts();
    return () => toast.dismiss();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calcScrollY = useCallback(() => {
    if (toastContainer) {
      if (window.scrollY === 0) {
        toastContainer.style.top = "5em";
      } else {
        toastContainer.style.top = "1em";
      }
    }
  }, [toastContainer]);

  useEffect(() => {
    calcScrollY(); // Set initial position of toast container
    window.addEventListener("scroll", () => calcScrollY());
  }, [calcScrollY]);

  return <div />;
}

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;

export default OverduePolicyToast;
