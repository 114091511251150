import { Text, StyleSheet } from "@react-pdf/renderer";
import FormsHeader from "./FormsHeader";
import FormsRows from "./FormsRows";
import { theme } from "styles";
import { Forms } from "utils/proposal-pdf/constants";

interface FormsTablePropTypes {
  forms: Forms;
}

const FormsTable = ({ forms }: FormsTablePropTypes) => {
  const isISOExclusion = (formKey: string) => formKey.includes("BP") && forms[formKey].includes("EXCLUSION");
  const isISOCoverageForm = (formKey: string) =>
    (formKey.includes("BP") && !forms[formKey].includes("EXCLUSION")) ||
    (formKey.includes("IL") && !forms[formKey].includes("EXCLUSION"));
  const excluded_coterie_forms = ["COTERIECWCLAIM1120S", "CTBCWNONCDEC0720", "COTERIECWOFAC0519S", "COTERIECWSIG0519S"];
  const isCoterieForm = (formKey: string) =>
    !isISOExclusion(formKey) && !isISOCoverageForm(formKey) && !excluded_coterie_forms.includes(formKey);
  const coterieForms = Object.keys(forms).filter(isCoterieForm);
  const isoCoverageForm = Object.keys(forms).filter(isISOCoverageForm);
  const policyExclusionForms = Object.keys(forms).filter(isISOExclusion);

  return (
    <>
      <FormsHeader />
      {coterieForms.length > 0 && (
        <>
          <Text style={styles.sectionTitle}>Coterie Forms</Text>
          {coterieForms.map((formNumber) => {
            return <FormsRows key={`${formNumber}`} formNumber={formNumber} formName={forms[formNumber]} />;
          })}
        </>
      )}
      {isoCoverageForm.length > 0 && (
        <>
          <Text style={styles.sectionTitle}>ISO Coverage Forms</Text>
          {isoCoverageForm.map((formNumber) => {
            return <FormsRows key={`${formNumber}`} formNumber={formNumber} formName={forms[formNumber]} />;
          })}
        </>
      )}
      {policyExclusionForms.length > 0 && (
        <>
          <Text style={styles.sectionTitle}>Policy Exclusion Forms</Text>
          {policyExclusionForms.map((formNumber) => {
            return <FormsRows key={`${formNumber}`} formNumber={formNumber} formName={forms[formNumber]} />;
          })}
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  sectionTitle: {
    color: theme.colors.tertiary.zero,
    fontFamily: theme.font.typeface.secondary,
    fontSize: 11,
    marginTop: 16,
    marginBottom: 8,
  },
});

export default FormsTable;
