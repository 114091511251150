import React, { useState, useEffect } from "react";
import Api from "utils/api";
import ChargeReportTable from "kit/ChargeReportTable";
import { ContentContainer, Title } from "styles/containerStyledComponents";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const ChargeReport = () => {
  const [charges, setCharges] = useState<PolicyChargeReportItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = async () => {
    setCharges([]);
    setIsLoading(true);

    const token = await getToken();
    const api = new Api(apiVersion, token);

    api
      .getCharges()
      .then((charges) => {
        setCharges(charges.items);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ContentContainer>
      <Title>Charge Report</Title>
      <ChargeReportTable charges={charges} isLoading={isLoading} onRefresh={onRefresh} />
    </ContentContainer>
  );
};

export default ChargeReport;
