import { formatCurrency } from "./formatNumber";

export const endorsementLimitValue = (value: number | boolean, addOnKey: string) => {
  if (typeof value === "number") {
    if (addOnKey === "limitDamagePremRented") {
      return formatCurrency(value + 50000);
    } else if (
      addOnKey === "endorsements.liquorLiabilityEndorsement.alcoholicBeverageSales" ||
      addOnKey === "liquorLiabilityEndorsement"
    ) {
      return "";
    }
    return formatCurrency(value);
  } else {
    return "";
  }
};
