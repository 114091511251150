import { isCanadaEnv } from "utils/environmentChecker";
import { simplyBindEndorsementsMap } from "shared/SimplyBind/constants";
import { cloneDeep, isEmpty } from "lodash";
import { ExtensionPackageTypes } from "types/enums";

export const mapSimplyBindAvailableEndorsements = (recommendedEndorsements: {
  [key: string]: Partial<EndorsementsType>;
}) => {
  let availableEndorsements: Partial<EndorsementsType> = {};
  const endorsementPackages = [];

  if (isEmpty(recommendedEndorsements)) return {};

  if (recommendedEndorsements.SILVEE) {
    endorsementPackages.push("Silver");
  }
  if (recommendedEndorsements.GOLDEE) {
    endorsementPackages.push("Gold");
  }
  if (recommendedEndorsements.PLATEE) {
    endorsementPackages.push("Platinum");
  }

  const extensionPackages = [];
  if (isCanadaEnv()) {
    if (recommendedEndorsements.BusInterExt20) {
      extensionPackages.push(ExtensionPackageTypes.BusinessInterruption);
    }
    if (recommendedEndorsements.EdgeComp20) {
      extensionPackages.push(ExtensionPackageTypes.EdgeComplete);
    }
    if (recommendedEndorsements.LiabEdge30) {
      extensionPackages.push(ExtensionPackageTypes.LiabilityEdge);
    }
    if (recommendedEndorsements.ContrExt20) {
      extensionPackages.push(ExtensionPackageTypes.ContractorsExtension);
    }
    if (extensionPackages.length) {
      availableEndorsements.extensionPackages = extensionPackages;
    }
  }

  if (endorsementPackages.length) {
    availableEndorsements.endorsementPackage = endorsementPackages;
  }

  for (let key in recommendedEndorsements) {
    const matchingEndorsement = simplyBindEndorsementsMap[key];
    if (matchingEndorsement) {
      availableEndorsements = { ...availableEndorsements, ...matchingEndorsement };
    }
  }

  return availableEndorsements;
};

export const mapSimplybindEndorsementDetails = (
  endorsements: EndorsementsType,
  endorsementDetails: EndorsementDetails,
  updateEndorsementDetails: (endorsementDetails: EndorsementDetails) => void,
  isLiquorLiabilityException: boolean = false
) => {
  const endorsementsWithQuestions: Record<string, string> = {
    liquorLiabilityEndorsement: "liquorLiabilityEndorsement",
    hiredAutoAndNonOwnedAuto: "hiredAutoAndNonOwnedAuto",
    contractorsInstallationAndTools: "contractorsToolsEndorsement",
    limitDamagePremRented: "limitDamagePremRented",
  };
  if (isLiquorLiabilityException) {
    delete endorsementsWithQuestions.liquorLiabilityEndorsement;
  }

  const selectedEndorsementKeys = Object.keys(endorsementsWithQuestions).filter((key) =>
    Object.keys(endorsements).includes(key)
  );

  let updatedEndorsementDetails = cloneDeep(endorsementDetails);

  selectedEndorsementKeys?.forEach((key) => {
    if (!Object.keys(updatedEndorsementDetails).includes(endorsementsWithQuestions[key])) {
      updatedEndorsementDetails[endorsementsWithQuestions[key] as keyof EndorsementDetails] = {
        questionHasBeenAnswered: false,
      };
    }
  });

  updateEndorsementDetails(updatedEndorsementDetails);
};
