import get from "lodash/get";
import { fetchAvailableEndorsements } from "redux/reducers/_availableEndorsements";
import { selectApplicationForm } from "redux/selectors/application";

const AVAILABLE_ENDORSEMENTS_APPLICATION_KEYS = [
  "industryId",
  "akHash",
  "mailingAddressStreet",
  "applicationTypes",
  "locations[0].street",
  "locations[0].state",
  "locations[0].zip",
];

const shouldFetchAvailableEndorsements = (oldApplication: Application, newApplication: Application) => {
  return AVAILABLE_ENDORSEMENTS_APPLICATION_KEYS.some((key) => {
    const oldValue = get(oldApplication, key);
    const newValue = get(newApplication, key);
    const hasValue = oldValue && newValue;
    const addingCoverage = oldValue === undefined && Array.isArray(newValue);
    const hasArrayValueChanged = hasValue && Array.isArray(oldValue) && oldValue.join("") !== newValue.join("");
    const hasStringValueChanged = hasValue && typeof oldValue === "string" && oldValue !== newValue;
    const checkForChange = Boolean(hasArrayValueChanged || hasStringValueChanged || addingCoverage);

    return checkForChange;
  });
};

const fetchAvailableEndorsementsMiddleware = (store: any) => (next: any) => (action: any) => {
  const old_application = selectApplicationForm(store.getState());
  const result = next(action);
  const new_application = selectApplicationForm(store.getState());
  const isSimplybind = window.location.pathname.includes("simplybind");

  if (!isSimplybind && shouldFetchAvailableEndorsements(old_application, new_application)) {
    store.dispatch(fetchAvailableEndorsements({ application: { ...new_application }, apiVersion: "v1" }));
  }
  return result;
};

export default fetchAvailableEndorsementsMiddleware;
