import styled from "styled-components";

export const Header1 = styled.h1`
  color: ${(props) => props.theme.blue};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 80px;
  font-weight: 800;
  letter-spacing: -0.67px;
  line-height: 74px;
  margin: 25px 0;
`;

export const Header2 = styled.h2`
  color: ${(props) => props.theme.blue};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 56px;
  font-weight: 800;
  letter-spacing: -0.47px;
  line-height: 60px;
  margin: 25px 0;
`;

export const Header3 = styled.h3`
  color: ${(props) => props.theme.fontColors.primary};
  font-family: ${(props) => props.theme.primaryFont};
  font-weight: 600;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 32px;
  margin: 16px 0;
`;

export const Header3Green = styled(Header3)`
  color: ${(props) => props.theme.green};
`;

export const Header3White = styled(Header3)`
  color: ${(props) => props.theme.white};
`;

export const Header4 = styled.h4`
  color: ${(props) => props.theme.fontColors.primary};
  font-family: ${(props) => props.theme.primaryFont};
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  margin: 8px 0;
`;

export const Header4Blue = styled(Header4)`
  color: ${(props) => props.theme.blue};
`;

export const Header4Green = styled(Header4)`
  color: ${(props) => props.theme.green};
`;

export const Header4White = styled(Header4)`
  color: ${(props) => props.theme.white};
`;
