import React from "react";
import SmoothCollapse from "react-smooth-collapse";
import { CardContent, CardBar, CardTitle, CardSubtitle, RedDot } from "../styles";
import { ReactComponent as CaretDownIcon } from "images/caret_down.svg";
import { ReactComponent as CaretUpIcon } from "images/caret_up.svg";
import styled from "styled-components";
import { theme } from "styles";

interface CardDetails {
  expanded: boolean;
  toggleExpanded: (isExpanded: boolean) => void;
  id: string;
  title: string;
  subtitle?: string;
  subtitleIcon?: any;
  subtitleIsWarning?: boolean;
  content: React.ReactNode;
  isInvalid?: boolean;
}

interface CardProps {
  details: CardDetails;
  isInvalid?: boolean;
  isLast?: boolean;
  smoothCollapseCompleted?: () => void;
  showErrorBorder?: boolean;
}

const Card = ({ details, isInvalid, isLast, smoothCollapseCompleted, showErrorBorder = true }: CardProps) => {
  const showSubtitle = Boolean(details?.subtitle);

  return (
    <CardContainer expanded={details.expanded} isInvalid={isInvalid} isLast={isLast} showErrorBorder={showErrorBorder}>
      <CardBar
        onClick={() => details.toggleExpanded(!details.expanded)}
        data-cy={`${details.id}-section`}
        id={`${details.id}-section`}
      >
        <CardTitle>
          {isInvalid && <StyledRedDot />} {details.title}
        </CardTitle>
        {showSubtitle && (
          <CardSubtitle warningStyle={details.subtitleIsWarning}>
            <img src={details.subtitleIcon} alt="Subtitle Icon" />
            <span>{details.subtitle}</span>
          </CardSubtitle>
        )}
        <CaretIconWrapper aria-label={`${details.expanded ? "close" : "open"} ${details.title} section`}>
          {details.expanded ? <CaretUpIcon /> : <CaretDownIcon />}
        </CaretIconWrapper>
      </CardBar>
      <SmoothCollapse expanded={details.expanded} eagerRender={true} onChangeEnd={smoothCollapseCompleted}>
        <CardContent>{details.content}</CardContent>
      </SmoothCollapse>
    </CardContainer>
  );
};

const CaretIconWrapper = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  svg {
    fill: ${(props) => props.theme.blue};
  }
`;

export const CardContainer = styled.div<{
  expanded?: boolean;
  isInvalid?: boolean;
  isLast?: boolean;
  showErrorBorder: boolean;
}>`
  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      props.isInvalid && props.showErrorBorder ? props.theme.red : props.expanded ? theme.blue : "lightgrey"};
  margin-bottom: ${(props) => (props.isLast ? "64px" : "16px")};
  width: 90%;
  max-width: 1044px;
`;

const StyledRedDot = styled(RedDot)`
  top: 11px;
  right: 15px;
`;

export default Card;
