import styled, { css } from "styled-components";

export const ModalContents = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
`;

// Rather than import this element, hard coding here so it will be easier to to an NPM package later
export const TitleBar = styled.h1`
  height: 64px;
  padding-left: 24px;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: #0d0d46;
  font-size: 20px;
  font-family: Questrial;
  color: #ffffff;
  @media screen and (max-width: 768px) {
    padding-right: 56px;
  }
`;

export const ItemCont = styled.div`
  overflow: auto;
`;

const ToolsBtnIconStyles = css`
  position: fixed;
  bottom: -1px;
  left: 50px;
  z-index: 100;
  height: 64px;
  width: 64px;
  cursor: pointer;
`;

export const ToolsBtn = styled.button`
  background: none;
  border: none;
  ${ToolsBtnIconStyles}
`;

export const ToolsIcon = styled.img`
  ${ToolsBtnIconStyles}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid blue;
`;

export const ControlsCont = styled(Row)`
  padding: 20px 24px 20px;
  border-bottom: none;
  margin: auto;
  column-gap: 16px;
`;

export const Block = styled.div<{ halfWidth?: boolean }>`
  width: ${(props) => (props.halfWidth ? "10%" : "22%")};
  padding: 5px 10px;
  overflow-wrap: anywhere;
`;

export const RawEdit = styled.textarea<{ hasError?: boolean }>`
  width: 100%;
  height: 200px;
  margin: 20px 0;
  border: ${(props) => props.hasError && "1px solid red"};
`;

const SharedInputStyles = css`
  :focus {
    outline: 1px solid ${(props) => props.theme.blue} !important;
  }
`;

export const TextInput = styled.input`
  width: 90%;
  ${SharedInputStyles}
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  padding: 0;
  top: 0px;
  right: 0px;
  width: 16px;
  height: 16px;
  margin: 16px;
`;

export const ContentCont = styled.div`
  padding: 20px;
  box-sizing: border-box;
  overflow: scroll;
`;

interface PrimaryButtonProps {
  theme?: ThemeInterface;
  full?: boolean;
  small?: boolean;
}

// Copying these here to make it easier to one day move into an NPM library
const ButtonTemplate = styled.button<PrimaryButtonProps>`
  max-width: 232px;
  min-width: 120px;
  height: 46px;
  padding: 0 24px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  font-family: Gilroy;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  transition: all 0.2s;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);

  :focus {
    outline: 1px dashed ${(props) => props.theme.blue} !important;
  }

  @media (hover: hover) {
    :hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.blue};
      color: #ffffff;
    }
  }

  :disabled {
    pointer-events: none;
    color: #7c7d98;
    background-color: #ebedf2;
  }
`;

export const PrimaryButton = styled(ButtonTemplate)`
  color: #0d0d46;
  background-color: #40ffb1;
  width: 160px;

  :focus {
    outline: 1px dashed ${(props) => props.theme.blue} !important;
  }
`;

export const TextButton = styled.button`
  display: block;
  font-family: Gilroy;
  color: ${(props) => props.theme.blue};
  font-size: 15px;
  font-weight: bold;
  border: none;
  background: none;
  padding: 0;
  border-bottom: 2px solid transparent;

  :hover {
    cursor: pointer;
    outline: none !important;
    border-bottom: 2px dotted ${(props) => props.theme.blue};
  }
  :focus {
    outline: none !important;
  }
  :focus-visible {
    cursor: pointer;
    outline: none !important;
    border-bottom: 2px dotted ${(props) => props.theme.blue};
  }
  :disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`;

export const GhostButton = styled(ButtonTemplate)`
  color: ${(props) => props.theme.textNavyBlue};
  border: 1px solid ${(props) => props.theme.regularGray};
  background-color: ${(props) => props.theme.white};
  box-shadow: none;
  width: 160px;
`;

export const RawEditToggle = styled.button`
  background-color: transparent;
  padding: 10px 0;
  text-decoration: underline;
`;
