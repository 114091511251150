import React, { useEffect, useReducer, useRef } from "react";
import useGetAgencyLogo from "hooks/useGetAgencyLogo";
import usePutAgencyLogo from "hooks/usePutAgencyLogo";
import useDeleteAgencyLogo from "hooks/useDeleteAgencyLogo";
import usePostLogoToImgur from "hooks/usePostLogoToImgur";
import { toastSuccess, toastError } from "utils/toast";
import {
  IMAGE_ACTION,
  imageReducer,
  imageDefaultState,
  IMAGE_UPLOAD_ERROR_MESSAGES,
  IMAGE_UPLOAD_SUCCESS_MESSAGE,
} from "./reducer";
import UploadPreviewArea from "./UploadPreviewArea";
import ImageActions from "./ImageActions";
import { ImageUploadContainer } from "./styles";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

type PropTypes = {
  setLogoSrc?: React.Dispatch<React.SetStateAction<string>>;
  agencyId?: string;
};

const formatFileSize = (fileSize: number | null) => {
  if (fileSize === null) return "";
  else if (fileSize < 1000) return `${fileSize} bytes`;
  else if (fileSize >= 1000 && fileSize < 1_000_000) return `${(fileSize / 1000).toFixed(1)} KB`;
  else if (fileSize >= 1_000_000) return `${(fileSize / 1_000_000).toFixed(1)} MB`;
};

const DragDropImageUpload = ({ setLogoSrc, agencyId = "" }: PropTypes) => {
  const isAgencyLogoUploadForQuoteProposal = Boolean(agencyId);
  const isCompanyLogoUploadForQuoteFlow = !Boolean(agencyId); // this is not a live feature, and is hidden in production

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const imageUploadAreaRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [imageState, imageDispatch] = useReducer(imageReducer, imageDefaultState);

  const resetImageState = () => imageDispatch({ type: IMAGE_ACTION.RESET_STATE });
  const setPreviewSrc = (imageUrl: string) => imageDispatch({ type: IMAGE_ACTION.SET_PREVIEW, payload: imageUrl });
  const setFileName = (fileName: string) => imageDispatch({ type: IMAGE_ACTION.SET_NAME, payload: fileName });
  const setFileSize = (fileSize: number) => imageDispatch({ type: IMAGE_ACTION.SET_SIZE, payload: fileSize });
  const setImageExtError = () => imageDispatch({ type: IMAGE_ACTION.SET_EXT_ERROR });
  const setImageSizeError = () => imageDispatch({ type: IMAGE_ACTION.SET_SIZE_ERROR });
  const setImageReadError = () => imageDispatch({ type: IMAGE_ACTION.SET_READ_ERROR });
  const setImageUploadError = () => imageDispatch({ type: IMAGE_ACTION.SET_UPLOAD_ERROR });

  const { apiVersion } = useFeatureFlags();

  const { data: agencyLogo, isSuccess } = useGetAgencyLogo(agencyId, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (isSuccess && agencyLogo) {
      setPreviewSrc(`${process.env.REACT_APP_PORTAL_API_BASE_URL}${apiVersion}/logo/${agencyId}`);
      setFileName(agencyLogo.fileName);
      setFileSize(agencyLogo.contentLength);
    }
  })

  const { mutate: putAgencyLogo, isPending: isLoadingAgencyLogoPut } = usePutAgencyLogo(agencyId);

  const { mutate: deleteAgencyLogo } = useDeleteAgencyLogo(agencyId, { onSuccess: () => resetImageState() });

  // TODO hook up to Portal API once endpoint is available
  // * IMGUR MUST NOT BE USED IN PRODUCTION
  // * this is hidden in production behind feature flag showChannelPartnerDesignTab
  const { mutate: postLogoToImgur } = usePostLogoToImgur(setLogoSrc, setImageUploadError);

  const removeImage = () => {
    if (isAgencyLogoUploadForQuoteProposal) {
      deleteAgencyLogo();
    } else if (isCompanyLogoUploadForQuoteFlow) {
      resetImageState();
      setLogoSrc && setLogoSrc("");
    }
  };

  type FileDataType = { file: File; dataUrl: string };

  const readFile = (file: File): Promise<FileDataType> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        const result = e?.target?.result;
        const dataUrl = (result as string).replace(";base64", ";name=" + file.name + ";base64");
        resolve({ file, dataUrl });
      };
      reader.onerror = function (e) {
        reject(e);
      };

      reader.readAsDataURL(file);
    });

  const handleFile = async (files: FileList) => {
    const file = files[0];

    await readFile(file)
      .then((fileData: FileDataType) => {
        const { file, dataUrl } = fileData;
        const validTypes = ["image/jpeg", "image/png"];
        const maxImageSizeBytes = 5_000_000;
        const maxSize = maxImageSizeBytes;
        const hasInvalidType = validTypes.indexOf(file.type) === -1;
        const hasInvalidSize = file.size > maxSize;

        if (hasInvalidType) {
          setImageExtError();
        } else if (hasInvalidSize) {
          setImageSizeError();
        } else {
          setPreviewSrc(dataUrl);
          setFileName(file.name);
          setFileSize(file.size);
          isAgencyLogoUploadForQuoteProposal
            ? putAgencyLogo(file, {
                onSuccess: () => toastSuccess(IMAGE_UPLOAD_SUCCESS_MESSAGE),
                onError: () => toastError(IMAGE_UPLOAD_ERROR_MESSAGES.UPLOAD_ERROR),
              })
            : postLogoToImgur(file);
        }
      })
      .catch((e) => {
        console.error(e);
        setImageReadError();
      });
  };

  const handleDrop = (e: DragEvent) => {
    const files = e.dataTransfer?.files;
    if (files) {
      handleFile(files);
    }
  };

  const handleImageUpload = (inputFile: HTMLInputElement) => {
    const files = inputFile.files;
    if (files) {
      handleFile(files);
    }
  };

  const preventDefault = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dropEvent = (e: DragEvent) => {
    preventDefault(e);
    handleDrop(e);
  };

  useEffect(() => {
    imageUploadAreaRef.current.addEventListener("dragenter", preventDefault, false);
    imageUploadAreaRef.current.addEventListener("dragleave", preventDefault, false);
    imageUploadAreaRef.current.addEventListener("dragover", preventDefault, false);
    imageUploadAreaRef.current.addEventListener("drop", dropEvent, false);
    return () => {
      imageUploadAreaRef.current?.removeEventListener("dragenter", preventDefault);
      imageUploadAreaRef.current?.removeEventListener("dragleave", preventDefault);
      imageUploadAreaRef.current?.removeEventListener("dragover", preventDefault);
      imageUploadAreaRef.current?.removeEventListener("drop", dropEvent);
    };
  }, [imageUploadAreaRef]);

  return (
    <ImageUploadContainer>
      <UploadPreviewArea
        imageState={imageState}
        formatFileSize={formatFileSize}
        imageUploadAreaRef={imageUploadAreaRef}
        inputRef={inputRef}
        handleImageUpload={handleImageUpload}
        isLoadingAgencyLogoPut={isLoadingAgencyLogoPut}
      />

      {imageState.previewSrc && (
        <ImageActions
          fileName={imageState.fileName}
          fileSize={imageState.fileSize}
          formatFileSize={formatFileSize}
          removeImage={removeImage}
          logoUrl={imageState.previewSrc}
        />
      )}
    </ImageUploadContainer>
  );
};

export default DragDropImageUpload;
