import { coterieAPI } from "./useAPI/instances";
import { useQuery } from "@tanstack/react-query";
import { isCanadaEnv } from "utils/environmentChecker";

const useVerifyAddressStateAndZip = (addressData: { state?: string; zip?: string }) => {
  const verifyAddressStateAndZip = async () => {
    const api = coterieAPI();
    const isMissingStateOrZip = !addressData.state || !addressData.zip;
    const zipInput = addressData.zip || "";
    if (isMissingStateOrZip || isCanadaEnv()) {
      return;
    } else if (zipInput.length > 4) {
      return await api.get(`/commercial/address/verifyStateZip/${addressData.state}${addressData.zip}`);
    }
  };

  return useQuery({
    queryKey: ["verify_state_and_zip", addressData],
    queryFn: verifyAddressStateAndZip,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: false,
  });
};

export default useVerifyAddressStateAndZip;
