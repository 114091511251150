export const carriers = {
  BENCHMARK: "Benchmark",
  CLEARSPRING: "ClearSpring",
  SPINNAKER: "Spinnaker",
  INTACT: "Intact",
};

export const clientKey = {
  [carriers.BENCHMARK]: {
    PROD: "526aUcSPzgq3egP445EftcSW9HvM53EtWN6w8uewTYHtZ8dasphyX688hj5Das7X",
    TEST: "2aLLXCZ2k26a6ezNzGAg5puJgm97MkYzFgtUGpv8P5W36Pq3t9UsgC7YBsqV4MsK",
  },
  [carriers.CLEARSPRING]: {
    PROD: "9qNz9P2hgVzTpcNMk5PS5n44mNPq9TvH5ggW7Wtg4468rb9s6Q8YpT5enjBGwjLw",
    TEST: "7s2cXk9nT47z67EZRc7Wk9e8eQ4pYW6PRcy95eL9xS6e74Z3dDhKUwNCwYCN5jHf",
  },
  [carriers.SPINNAKER]: {
    PROD: "5537SvP47S8dT4UnG7z45XXLscCpkfV9c2T3xF3TB9nRaqBBvrL595V2cjXUu8g3",
    TEST: "7555gMz7sLkANntG4JNuYv4PV7K8yFDjFPjC6kPA67vyTDYqtdK666qSX9m44Ps2",
  },
};
export const apiLoginId = {
  [carriers.BENCHMARK]: {
    PROD: "5ws49L8UP",
    TEST: "9rWk7QmN8bNs",
  },
  [carriers.CLEARSPRING]: {
    PROD: "4eL3PSs23",
    TEST: "6F3zAS5w82",
  },
  [carriers.SPINNAKER]: {
    PROD: "7ZH39wTWw4",
    TEST: "66nQaE3d6",
  },
};

export const getAuthData = (insuranceCarrier = carriers.BENCHMARK) => {
  const isProd = process.env.REACT_APP_ENVIRONMENT === "production";
  const env = isProd ? "PROD" : "TEST";

  return {
    clientKey: clientKey[insuranceCarrier][env],
    apiLoginID: apiLoginId[insuranceCarrier][env],
  };
};

export const formatCreditCardData = ({ exp, number, cvc }) => {
  const cardArray = exp.split("/");
  // TODO: FIgure out why I need to trim cardCode, where is the extra space coming from
  return {
    cardNumber: number.split(" ").join(""),
    month: cardArray[0],
    year: cardArray[1],
    cardCode: cvc.trim(),
  };
};
export const stripeOptionsObject = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Questrial",
    },
  ],
};
