import React from "react";
import Modal from "shared/Modal";
import { ActionContainer, ModalContentContainer } from "shared/ModalComponents";
import AjaxLoader from "shared/AjaxLoader";
import styled from "styled-components";

interface LoadingModalProps {
  children: React.ReactNode;
  title: string;
}

const LoadingModal = ({ children, title }: LoadingModalProps) => {
  return (
    <Modal renderCloseButton={false} isOpen={true} closeModal={() => null} title={title} label={title}>
      <ModalContentContainer>
        {children}
        <StyledActionContainer>
          <AjaxLoader height={64} width={80} />
        </StyledActionContainer>
      </ModalContentContainer>
    </Modal>
  );
};

const StyledActionContainer = styled(ActionContainer)`
  border-top: none;
`;

export default LoadingModal;
