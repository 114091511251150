import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { PolicyType } from "types/enums";
import {
  selectApplicationFormApplicationTypes,
  selectApplicationAvailablePolicyTypes,
  selectIsCoveragesSelected,
  selectIsCoveragesCompleted,
  selectIsPLCoverageCompleted,
  selectIsBOPCoverageCompleted,
} from "redux/selectors/application";
import {
  selectUnderwritingProfileDeclinationLedger,
  selectUnderwritingProfileDeniedCoverages,
} from "redux/selectors/underwritingProfile";
import BOPCoverages from "./BOPCoverages";
import GLCoverages from "./GLCoverages";
import PLCoverages from "./PLCoverages";
import gl_policy_src from "images/gl_policy.svg";
import pl_policy_src from "images/pl_policy.svg";
import bop_policy_src from "images/bop_policy.svg";
import { updateApplicationForm, clearEndorsements } from "redux/reducers/_applicationReducer";
import { ErrorText } from "styles/Input";
import CoverageTabs from "./CoverageTabs";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";

export interface PolicyData {
  policy: PolicyType;
  title: string;
  image: string;
  description: string;
  coverageComponents: () => React.ReactElement;
}

export const policies: PolicyData[] = [
  {
    policy: PolicyType.PL,
    title: "Professional Liability Policy",
    image: pl_policy_src,
    description: "Protects the business from failure to uphold contractual promises.",
    coverageComponents: () => <PLCoverages />,
  },
  {
    policy: PolicyType.GL,
    title: "General Liability Policy",
    image: gl_policy_src,
    description: "Protects the business from liability arising from loss due to damage or injury.",
    coverageComponents: () => (
      <>
        <GLCoverages />
      </>
    ),
  },
  {
    policy: PolicyType.BOP,
    title: "Business Owner’s Policy",
    image: bop_policy_src,
    description: "Protects the business from losses due to damage, injury or property loss.",
    coverageComponents: () => (
      <>
        <GLCoverages />
        <BOPCoverages />
      </>
    ),
  },
];

interface PropTypes {
  applicationTypes: PolicyType[];
  availablePolicyTypes: PolicyType[];
  updateApplicationForm: (application: Application) => void;
  isCoveragesSelected: boolean;
  isCoveragesCompleted: boolean;
  clearEndorsements: ActionCreatorWithoutPayload<any>;
  BOPIsCompleted: boolean;
  PLIsCompleted: boolean;
  deniedCoverages: PolicyType[];
  declinationLedger: DeclinationLedger;
}

const Coverages = ({
  applicationTypes,
  availablePolicyTypes,
  updateApplicationForm,
  isCoveragesSelected,
  isCoveragesCompleted,
  clearEndorsements,
  BOPIsCompleted,
  PLIsCompleted,
  deniedCoverages,
  declinationLedger,
}: PropTypes) => {
  const onTogglePolicyType = (selected: PolicyType) => {
    const addingGL = selected === PolicyType.GL;
    const addingBOP = selected === PolicyType.BOP;
    const BOPtoGL = addingGL && applicationTypes?.includes(PolicyType.BOP);
    const glToBOP = addingBOP && applicationTypes?.includes(PolicyType.GL);
    const newApplicationTypes = () => {
      if (BOPtoGL) {
        return [...applicationTypes?.filter((item) => item !== PolicyType.BOP), selected];
      } else if (glToBOP) {
        return [...applicationTypes?.filter((item) => item !== PolicyType.GL), selected];
      } else if (applicationTypes && !applicationTypes.includes(selected)) {
        return [...applicationTypes, selected];
      } else {
        return applicationTypes?.filter((item) => item !== selected);
      }
    };

    clearEndorsements();
    updateApplicationForm({ applicationTypes: newApplicationTypes() });
  };

  return (
    <>
      {!isCoveragesSelected && <ErrorText>Select a coverage type to continue with quote</ErrorText>}
      {!isCoveragesCompleted && <ErrorText>Complete coverage details to continue with quote</ErrorText>}
      <OuterContainer>
        <CoverageTabs
          selectPolicy={(policyType) => onTogglePolicyType(policyType)}
          selectedPolicyTypes={applicationTypes}
          policyData={policies}
          availablePolicyTypes={availablePolicyTypes}
          BOPHasError={!BOPIsCompleted}
          PLHasError={!PLIsCompleted}
          deniedCoverages={deniedCoverages}
          declinationLedger={declinationLedger}
        ></CoverageTabs>
      </OuterContainer>
    </>
  );
};

const OuterContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

const mapStateToProps = (state: ReduxState) => ({
  applicationTypes: selectApplicationFormApplicationTypes(state),
  availablePolicyTypes: selectApplicationAvailablePolicyTypes(state),
  isCoveragesSelected: selectIsCoveragesSelected(state),
  isCoveragesCompleted: selectIsCoveragesCompleted(state),
  BOPIsCompleted: selectIsBOPCoverageCompleted(state),
  PLIsCompleted: selectIsPLCoverageCompleted(state),
  deniedCoverages: selectUnderwritingProfileDeniedCoverages(state),
  declinationLedger: selectUnderwritingProfileDeclinationLedger(state),
});

const mapDispatchToProps = { updateApplicationForm, clearEndorsements };

export default connect(mapStateToProps, mapDispatchToProps)(Coverages);
