import React from "react";
import LogsContainer from "./LogsContainer";

const Logs = () => {
  return (
    <LogsContainer
      apiRequest="logs.get"
      headers={["Status", "Host", "Description", "Created On"]}
      title="Logs"
      pathName="/logs"
      enableSearch={true}
    />
  );
};

export default Logs;
