import React from "react";
import styled from "styled-components";
import { GhostButton } from "shared/SimplyBind/styles";
import { ModalContentContainer, ActionRow, ActionContainer, ModalText } from "shared/ModalComponents";
import ModalBasic from "shared/ModalBasic";

interface RequoteModalProps {
  onClose: () => void;
}

const RequoteModal = ({ onClose }: RequoteModalProps) => {
  return (
    <ModalBasic
      isOpen={true}
      closeModal={onClose}
      label="Requote Legacy Account Notice"
      title="Requote Legacy Account Notice"
    >
      <ModalContentContainer>
        <ModalText>
          Unfortunately, we don’t have enough information to complete this quote in the new SimplyBind experience.
          Please start a new quote for this applicant.
        </ModalText>
      </ModalContentContainer>
      <ActionContainer>
        <ActionRow>
          <StyledGhostButton onClick={onClose}>Close</StyledGhostButton>
        </ActionRow>
      </ActionContainer>
    </ModalBasic>
  );
};

const StyledGhostButton = styled(GhostButton)`
  min-width: 156px;
`;

export default RequoteModal;
