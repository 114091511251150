import React from "react";
import styled from "styled-components";

const Text = styled.p`
  font-size: 20px;
  font-family: ${(props) => props.theme.primaryFont};
  color: ${(props) => (props.white ? "white" : props.theme.fontColors?.primary)};
  margin: ${(props) => (props.display === "inline" ? "10px 5px 5px 0" : "10px 0px 16px 0")};
  font-weight: bold;
  line-height: 1.3;
  display: ${(props) => props.display || "block"};
  text-align: ${(props) => (props.center ? "center" : "left")};
`;
const LabelLarge = (props) => (
  <Text display={props.display} {...props}>
    {props.children}
  </Text>
);
export default LabelLarge;
