import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  policies: [],
  application: {
    lossControl: "",
    policyStartDate: undefined,
  },
  agent: {},
  partner: {},
  quote: {},
  alternateQuote: {},
};

const snapshotSlice = createSlice({
  name: "snapshot",
  initialState,
  reducers: {
    setSnapshotPolicies: (state, { payload }) => {
      state.policies = payload;
    },
    setSnapshotApplication: (state, { payload }) => {
      state.application = payload;
    },
    setSnapshotApplicationLossControl: (state, { payload }) => {
      state.application.lossControl = payload;
    },
    setSnapshotApplicationStartDate: (state, { payload }) => {
      state.application.policyStartDate = payload;
    },
    setSnapshotAgent: (state, { payload }) => {
      state.agent = payload;
    },
    setSnapshotPartner: (state, { payload }) => {
      state.partner = payload;
    },
    setSnapshotQuote: (state, { payload }) => {
      state.quote = payload;
    },
    setSnapshotAlternateQuote: (state, { payload }) => {
      state.alternateQuote = payload;
    },
  },
});

const getSnapshot = (state: ReduxState) => state.snapshot || {};
export const getSnapshotPolicies = (state: ReduxState) => getSnapshot(state).policies || [];
export const getSnapshotApplication = (state: ReduxState) => getSnapshot(state).application || {};
export const getSnapshotAgencyName = (state: ReduxState) => getSnapshot(state).agent?.agencyName || "";
export const getSnapshotProducerId = (state: ReduxState) => getSnapshot(state).agent?.producerExternalID || "";
export const getSnapshotPartnerId = (state: ReduxState) => getSnapshot(state).partner?.id || "";
export const getSnapshotQuotePremium = (state: ReduxState) => getSnapshot(state).quote?.premium || 0;
export const getSnapshotApplicationTypes = (state: ReduxState) =>
  getSnapshot(state).application?.applicationTypes || [];
export const getSnapshotApplicationIndustryId = (state: ReduxState) => getSnapshotApplication(state).industryId || 0;
export const getSnapshotApplicationStartDate = (state: ReduxState) =>
  getSnapshotApplication(state).policyStartDate || new Date();

export const selectFees = (state: ReduxState) => getSnapshot(state)?.quote?.quotes?.[0]?.fees ?? [];

// policy fees and installment fees
export const selectMonth1Fees = (state: ReduxState) => getSnapshot(state)?.quote?.month1Fees;

// monthly premium + month1Fees
export const selectMonth1Owed = (state: ReduxState) => getSnapshot(state)?.quote?.month1Owed;

// policy fees + installment fees, when user pays yearly
export const selectTotalYearlyFees = (state: ReduxState) => getSnapshot(state)?.quote?.totalYearlyFees;

// yearly premium + totalYearlyFees, when user pays yearly
export const selectTotalYearlyOwed = (state: ReduxState) => getSnapshot(state)?.quote?.totalYearlyOwed;

// future monthly fees per month
export const selectMonthlyFees = (state: ReduxState) => getSnapshot(state)?.quote?.monthlyFees;

// future monthly premium + monthlyFees
export const selectMonthlyOwed = (state: ReduxState) => getSnapshot(state)?.quote?.monthlyOwed;

export const selectPolicyFees = (state: ReduxState) => getSnapshot(state)?.quote?.policyFees;
export const selectInstallmentFees = (state: ReduxState) => getSnapshot(state)?.quote?.installmentFees;
export const selectLossControlFees = (state: ReduxState) => getSnapshot(state)?.quote?.lossControlFees;

export const selectHasFees = (state: ReduxState) => Boolean(selectTotalYearlyFees(state));
export const selectHasPolicyFee = (state: ReduxState) => Boolean(selectPolicyFees(state));
export const selectFeesSavings = (state: ReduxState) => selectInstallmentFees(state) * 11;

export const selectHasFirstMonthWaived = (state: ReduxState) =>
  selectFees(state).find((fee) => fee?.description === "Installment Fee")?.offset === 1;

export const selectSnapshotAlternateQuote = (state: ReduxState) => getSnapshot(state)?.alternateQuote || {};

export const {
  setSnapshotPolicies,
  setSnapshotApplication,
  setSnapshotApplicationLossControl,
  setSnapshotAgent,
  setSnapshotPartner,
  setSnapshotQuote,
  setSnapshotAlternateQuote,
  setSnapshotApplicationStartDate,
} = snapshotSlice.actions;

export default snapshotSlice.reducer;
