import React, { useEffect, useState } from "react";
import Modal from "shared/Modal";
import {
  ModalField,
  LongModalInput,
  ShortModalInput,
  ModalContentContainer,
  Row,
  Column,
} from "shared/ModalComponents";

interface PropTypes {
  isLoading: boolean;
  closeModal: () => void;
  modalIsOpen: boolean;
  save: (firstName: string, lastName: string, email: string) => void;
  firstName: string;
  lastName: string;
  email: string;
}

const EditAccountModal = (props: PropTypes) => {
  const [values, setValues] = useState({
    firstName: props.firstName,
    lastName: props.lastName,
    email: props.email,
  });

  useEffect(() => {
    setValues({
      firstName: props.firstName,
      lastName: props.lastName,
      email: props.email,
    });
  }, [props.firstName, props.lastName, props.email]);

  const editAccount = () => {
    const { firstName, lastName, email } = values;
    props.save(firstName, lastName, email);
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    let { name, value } = event.currentTarget;

    setValues((values) => ({ ...values, [name]: value }));
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      closeModal={props.closeModal}
      label="edit-account"
      title="Edit Account Info"
      onSubmit={editAccount}
      loading={props.isLoading}
    >
      <ModalContentContainer>
        <Row>
          <Column>
            <ModalField>First Name</ModalField>
            <ShortModalInput
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              aria-label="First Name"
              required
              data-testid="firstName"
              placeholder="Enter first name"
              data-cy="edit-first-name"
            />
          </Column>
          <Column>
            <ModalField>Last Name</ModalField>
            <ShortModalInput
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              aria-label="Last Name"
              required
              data-testid="lastName"
              placeholder="Enter last name"
              data-cy="edit-last-name"
            />
          </Column>
        </Row>
        <ModalField>Email Address</ModalField>
        <LongModalInput
          name="email"
          value={values.email}
          onChange={handleChange}
          type="email"
          data-cy="edit-email"
          aria-label="Email Address"
          required
          data-testid="email"
          placeholder="Enter valid email address"
        />
      </ModalContentContainer>
    </Modal>
  );
};

export default EditAccountModal;
