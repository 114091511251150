import styled from "styled-components";
import { formatCurrency } from "utils/formatNumber";

interface PolicyInformationProps {
  policy: Policy;
}

export const PLCoverage = ({ policy }: PolicyInformationProps) => {
  return (
    <tbody>
      <tr data-cy="professional-liability">
        <TData>Professional Liability</TData>
        <TData>{`${formatCurrency(policy.professionalLiability!.occurrenceLimit!)} / ${formatCurrency(
          policy.professionalLiability!.aggregateLimit!
        )}`}</TData>
        <TData style={{ paddingLeft: 0 }}>{formatCurrency(policy.professionalLiability!.deductibleAmount!)}</TData>
      </tr>
    </tbody>
  );
};

const TData = styled.td`
  font-weight: 400;
  padding: 10px 0px 10px 20px;
  font-family: ${({ theme }) => theme.secondaryFont};
  color: ${({ theme }) => theme.gray};
  font-size: 15px;
`;
