import React from "react";
import Modal from "shared/ModalBasic";
import { ModalContentContainer } from "shared/ModalComponents";
import useAPI from "hooks/useAPI";
import UpdatePaymentMethodForm from "shared/Payment/UpdatePaymentMethodForm";
import styled from "styled-components";
import AjaxLoader from "shared/AjaxLoader";

interface PropTypes {
  modalIsOpen: boolean;
  onRequestClose: () => void;
  afterPaymentUpdate?: () => void;
  accountId: number;
}

const UpdatePaymentMethodModal = ({ onRequestClose, afterPaymentUpdate, accountId, modalIsOpen }: PropTypes) => {
  const { loading: isLoading } = useAPI("getAccount", [String(accountId)], {
    errorMessage: "Error retrieving account details",
    dependencyArray: [accountId],
  });

  return (
    <Modal isOpen={modalIsOpen} closeModal={onRequestClose} label="update-payment-method" title="Update Payment Method">
      {isLoading ? (
        <LoadingCont data-testId="loading-spinner">
          <AjaxLoader />
        </LoadingCont>
      ) : (
        <ModalContentContainer>
          <UpdatePaymentMethodForm
            accountId={accountId}
            onRequestClose={onRequestClose}
            afterPaymentUpdate={afterPaymentUpdate}
          />
        </ModalContentContainer>
      )}
    </Modal>
  );
};

const LoadingCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 426px;
`;

export default UpdatePaymentMethodModal;
