import { cloneDeep } from "lodash";
import get from "lodash/get";
import unset from "lodash/unset";
import omit from "lodash/omit";
import produce, { Draft } from "immer";
import { updateNestedPathNames, getParentPath, removeEmpty, deconstructRules } from "./utils";
import { Reducer } from "react";
import ActionsTypes from "./types";
import { Rule } from "../Shared/types";
import { UnderwritingRulesActionTypes } from "./types";

const emptyRule: Rule = {
  variable: "",
  options: [
    {
      condition: "",
      conditions: [{ conditionOperator: "", conditionTarget: "" }],
      isNegated: false,
      path: "",
    },
  ],
  disabled: false,
  label: "",
  properties: [],
  id: "",
};

export const underwritingRulesInitialState = {
  rule: updateNestedPathNames(cloneDeep(emptyRule)),
  ruleIsCreate: true,
  activeRulePath: "options.0",
  activeLogicBlock: "condition",
};

export interface UnderwritingRulesState {
  rule: Rule;
  ruleIsCreate: boolean;
  activeRulePath: string;
  activeLogicBlock: string;
}

export const underwritingRulesReducer: Reducer<UnderwritingRulesState, ActionsTypes> = produce(
  (draft: Draft<UnderwritingRulesState>, action: ActionsTypes) => {
    const currentOption = get(draft.rule, draft.activeRulePath);
    const optionParent = get(draft.rule, getParentPath(draft.activeRulePath, -2)) ?? draft.rule;
    const parentSubrules = get(draft.rule, getParentPath(draft.activeRulePath, -4))?.subRules;
    switch (action.type) {
      case UnderwritingRulesActionTypes.SET_RULE:
        draft.rule = deconstructRules(action.payload);
        draft.activeRulePath = "options.0";
        draft.activeLogicBlock = "condition";
        draft.ruleIsCreate = false;
        return draft;
      case UnderwritingRulesActionTypes.RESET_RULE:
        draft.rule = underwritingRulesInitialState.rule;
        draft.ruleIsCreate = true;
        return draft;
      case UnderwritingRulesActionTypes.UPDATE_VARIABLE:
        const parentSubruleMatchingOption = parentSubrules?.find((rule: Rule) => rule.variable === action.payload);
        if (parentSubruleMatchingOption) {
          //if new variable exists as a subrule on this level, push option into matching options array.
          parentSubruleMatchingOption.options.push(currentOption);
          omit(draft.rule, optionParent.options.length === 1 ? optionParent.path : draft.activeRulePath);
        } else {
          if (optionParent.options.length === 1 || draft.activeRulePath === "options.0") {
            optionParent.variable = action.payload;
          } else {
            //create a new subrule
            const newSubrule = {
              variable: action.payload,
              options: [currentOption],
            };
            omit(draft.rule, draft.activeRulePath);
            parentSubrules.push(newSubrule);
          }
        }
        removeEmpty(draft.rule);
        draft.rule = updateNestedPathNames(draft.rule);
        draft.activeRulePath = currentOption.path;
        return draft;
      case UnderwritingRulesActionTypes.UPDATE_MESSAGE:
        currentOption.message = action.payload;
        return draft;
      case UnderwritingRulesActionTypes.UPDATE_CONDITION_OPERATOR:
        currentOption.conditions[action.payload.index].conditionOperator = action.payload.conditionOperator;
        return draft;
      case UnderwritingRulesActionTypes.UPDATE_CONDITION_TARGET:
        currentOption.conditions[action.payload.index].conditionTarget = action.payload.conditionTarget;
        return draft;
      case UnderwritingRulesActionTypes.UPDATE_CONTROL_ACTION:
        currentOption.controlAction = action.payload;
        return draft;
      case UnderwritingRulesActionTypes.UPDATE_CONTROL_TARGET:
        currentOption.controlTarget = action.payload;
        return draft;
      case UnderwritingRulesActionTypes.UPDATE_STATES:
        currentOption.states = action.payload;
        return draft;
      case UnderwritingRulesActionTypes.UPDATE_ACTIVE_RULE_PATH:
        draft.activeRulePath = action.payload;
        return draft;
      case UnderwritingRulesActionTypes.UPDATE_ACTIVE_LOGIC_BLOCK:
        draft.activeLogicBlock = action.payload;
        return draft;
      case UnderwritingRulesActionTypes.ADD_MESSAGE_BLOCK:
        if (!currentOption.hasOwnProperty("message")) currentOption.message = "";
        return draft;
      case UnderwritingRulesActionTypes.ADD_STATES_BLOCK:
        if (!currentOption.hasOwnProperty("states")) currentOption.states = [];
        return draft;
      case UnderwritingRulesActionTypes.ADD_PROPERTY_BLOCK:
        draft.rule.properties = [...(draft.rule.properties ? draft.rule.properties : []), { name: "", value: "" }];
        return draft;
      case UnderwritingRulesActionTypes.UPDATE_PROPERTY_NAME:
        if (draft.rule.properties) draft.rule.properties[action.payload.index].name = action.payload.name;
        return draft;
      case UnderwritingRulesActionTypes.UPDATE_PROPERTY_VALUE:
        if (draft.rule.properties) draft.rule.properties[action.payload.index].value = action.payload.value;
        return draft;
      case UnderwritingRulesActionTypes.DELETE_PROPERTY:
        draft.activeLogicBlock = "";
        omit(draft.rule, `properties[${action.payload}]`);
        removeEmpty(draft.rule);
        return draft;
      case UnderwritingRulesActionTypes.ADD_TAG_BLOCK:
        if (!draft.rule.tags) draft.rule.tags = [];
        return draft;
      case UnderwritingRulesActionTypes.DELETE_TAG_BLOCK:
        delete draft.rule.tags;
        return draft;
      case UnderwritingRulesActionTypes.SET_TAGS:
        draft.rule.tags = action.payload;
        return draft;
      case UnderwritingRulesActionTypes.ADD_CONTROL_BLOCK:
        if (!currentOption.hasOwnProperty("controlAction")) {
          currentOption.controlTarget = "";
          currentOption.controlAction = "";
        }
        return draft;
      case UnderwritingRulesActionTypes.ADD_MULTI_CONDITION:
        if (action.payload === currentOption.aggregationType || !currentOption.aggregationType) {
          currentOption.conditions.push({ conditionOperator: "", conditionTarget: "" });
        }
        currentOption.aggregationType = action.payload;
        return draft;
      case UnderwritingRulesActionTypes.DELETE_MULTI_CONDITION:
        omit(currentOption, `conditions[${action.payload}]`);
        removeEmpty(currentOption);
        if (currentOption.conditions.length === 1) currentOption.aggregationType = null;
        return draft;
      case UnderwritingRulesActionTypes.ADD_ROOT_RULE_OPTION:
        draft.rule.options.push(cloneDeep(emptyRule.options[0]));
        draft.rule = updateNestedPathNames(draft.rule);
        return draft;
      case UnderwritingRulesActionTypes.ADD_SUBRULE:
        parentSubrules.push(cloneDeep(emptyRule));
        draft.rule = updateNestedPathNames(draft.rule);
        return draft;
      case UnderwritingRulesActionTypes.ADD_NESTED_SUBRULE:
        if (currentOption.hasOwnProperty("subRules")) {
          currentOption.subRules.push(cloneDeep(emptyRule));
        } else {
          currentOption.subRules = [cloneDeep(emptyRule)];
        }
        draft.rule = updateNestedPathNames(draft.rule);
        return draft;
      case UnderwritingRulesActionTypes.DELETE_BLOCK:
        if (action.payload === "condition") {
          unset(draft.rule, optionParent.options.length === 1 ? optionParent.path : draft.activeRulePath);
          removeEmpty(draft.rule);
        } else if (action.payload === "control") {
          unset(currentOption, "controlAction");
          unset(currentOption, "controlTarget");
        } else {
          unset(currentOption, [action.payload]);
        }
        draft.rule = updateNestedPathNames(draft.rule);
        return draft;
      case UnderwritingRulesActionTypes.TOGGLE_RULE_DISABLED_STATE:
        optionParent.disabled = !optionParent.disabled;
        return draft;
      case UnderwritingRulesActionTypes.TOGGLE_NEGATION:
        currentOption.isNegated = !currentOption.isNegated;
        return draft;
      case UnderwritingRulesActionTypes.UPDATE_LABEL:
        draft.rule.label = action.payload;
        return draft;
      default:
        throw new Error("Must use a valid Action Type for the RuleBuilder Reducer");
    }
  }
);
