import { useEffect } from "react";
import useAuth from "hooks/useAuth";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import AjaxLoader from "shared/AjaxLoader";

const Logout = () => {
  const { logout, isAuthenticated } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      logout();
    } else {
      history.push("/login/callback");
    }
  }, [isAuthenticated, logout, history]);

  return (
    <LoadingContainer data-testid="ajax-loader">
      <AjaxLoader />
    </LoadingContainer>
  );
};

export default Logout;

export const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
