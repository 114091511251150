import styled, { css } from "styled-components";
import { GreenButton, NarrowButton, hideForPrint, tablet, GreenButtonStyles } from "styles";
import { IndustrySearch } from "@coterieinsure/ux-components";
import { isCanadaEnv } from "utils/environmentChecker";

export const ReadyToBind = styled.div<{ shouldResponsiveShrink: boolean }>`
  border-radius: 16px;
  background-color: ${(props) => props.theme.readyToBind};
  height: 32px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  gap: 8px;

  ${hideForPrint}

  ${tablet} {
    width: ${(props) => (props.shouldResponsiveShrink ? "32px" : "152px")};
    margin-right: ${(props) => (props.shouldResponsiveShrink ? "16px" : "0px")};
  }

  p {
    color: ${(props) => props.theme.colors?.secondary.dark};
    font-family: ${(props) => props.theme.primaryFont};
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 15px;
    margin-bottom: 0px;
  }

  img {
    height: 20px;
  }
`;

export const PendingCompletion = styled.div<{ shouldResponsiveShrink: boolean }>`
  border-radius: 16px;
  background-color: ${(props) => props.theme.pendingCompletion};
  height: 32px;
  width: ${isCanadaEnv() ? "155px" : "203px"};
  display: flex;
  flex-direction: row;

  ${hideForPrint}

  ${tablet} {
    width: ${(props) => (props.shouldResponsiveShrink ? "32px" : "203px")};
    margin-right: ${(props) => (props.shouldResponsiveShrink ? "16px" : "0px")};
  }

  p {
    color: ${(props) => props.theme.charcoalGrey};
    font-family: ${(props) => props.theme.primaryFont};
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 13px;
    margin-bottom: 0px;
    padding: 9px 0px 0px 10px;
  }

  img {
    padding-left: 16px;
    ${tablet} {
      padding-left: ${(props) => (props.shouldResponsiveShrink ? "6px" : "16px")};
    }
  }
`;

export const RequoteRequired = styled.div<{ shouldResponsiveShrink: boolean }>`
  border-radius: 16px;
  background-color: ${(props) => props.theme.disabledBackground};
  color: ${(props) => props.theme.gray};
  height: 32px;
  width: ${isCanadaEnv() ? "138px" : "175px"};
  display: flex;
  flex-direction: row;
  align-items: center;
  ${isCanadaEnv() &&
  css`
    padding-left: 10px;
  `}

  ${hideForPrint}

  ${tablet} {
    width: ${(props) => (props.shouldResponsiveShrink ? "32px" : "152px")};
    margin-right: ${(props) => (props.shouldResponsiveShrink ? "16px" : "0px")};
    justify-content: center;
  }

  p {
    color: ${(props) => props.theme.charcoalGrey};
    font-family: ${(props) => props.theme.primaryFont};
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 13px;
    margin-bottom: 0px;
  }

  svg {
    margin-right: 5px;
    margin-left: 15px;
    ${tablet} {
      margin: 0;
    }
    path {
      fill: ${(props) => props.theme.gray};
    }
  }
`;

export const StyledStatus = styled.img<{ noPaddingTop?: boolean }>`
  height: 25px;
  width: auto;
  padding-top: ${(props) => (props.noPaddingTop ? "0" : "5px")};
`;

export const SubTitle = styled.h2`
  color: ${(props) => props.theme.fontColors?.primary};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin: 15px 0px;
`;

export const SuggestionsContainer = styled.div`
  border: 1px solid lightgrey;
  border-radius: 4px;
  position: absolute;
  background: white;
  width: 480px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  height: fit-content;
  max-height: 272px;
  overflow-y: auto;
  position: relative;
`;

export const SuggestionsList = styled.p`
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
  padding: 12px 20px;
  margin-bottom: 0;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.green};
    path {
      fill: ${(props) => props.theme.textNavyBlue};
    }
  }
`;

export const NoMatch = styled.p`
  padding-left: 8px;
  color: ${(props) => props.theme.errorText};
  font-size: 12px;
  font-weight: 600;
`;

export const ManualAddressButton = styled(NarrowButton)`
  margin-top: 6px;
  margin-bottom: 24px;
  width: 132px;
  background-color: ${(props) => props.theme.white};
`;

export const BindButton = styled(GreenButton)`
  ${GreenButtonStyles}
  width: 160px;
  height: 42px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &.disabledLink {
    opacity: 0.2;
    pointer-events: none;
    background-color: #f3f2f5;
    color: ${(props) => props.theme.fontColors?.primary};
    border: solid 1px #3b364d;
  }

  :disabled {
    background-color: #f3f2f5;
    color: ${(props) => props.theme.fontColors?.primary};
    border: solid 1px #3b364d;
  }

  ${hideForPrint}
`;

export const Subtitle = styled.p`
  color: ${(props) => props.theme.gray};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
  line-height: 20px;
`;

export const NextButton = styled(GreenButton)`
  width: 200px;
  height: 45px;
  opacity: ${(props) => (!props.disabled ? 1 : 0.2)};
  :hover {
    cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
    background-color: ${(props) => (!props.disabled ? props.theme.buttonHover : props.theme.green)};
    opacity: ${(props) => (!props.disabled ? 1 : 0.2)};
    color: ${(props) => (!props.disabled ? props.theme.buttonTextHover : props.theme.buttonText)};
    text-decoration: none;
  }
`;

export const Error = styled.p`
  color: ${(props) => props.theme.red};
  font-size: 13px;
  margin-bottom: 0;
`;

export const StyledIndustrySearch = styled(IndustrySearch)`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 32px;

  > div {
    z-index: 2;
  }

  p.header {
    font-family: ${(props) => props.theme.secondaryFont};
    padding-bottom: 6px;
    margin-bottom: 0px;
    letter-spacing: 1px;
    font-size: 11px;
    margin-left: 10px;
  }

  img {
    max-width: 40px;
    position: absolute;
    right: 0;
    top: 16px;
  }

  input {
    height: 48px;
    width: 100%;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.lighterGray};
    :focus {
      outline: none;
      box-shadow: 0 0 0 0.75pt ${(props) => props.theme.blue};
    }
    :read-only {
      cursor: not-allowed;
      background-color: ${(props) => props.theme.offWhite};
      box-shadow: none;
    }
  }

  ul {
    padding: 0;
    margin: 10px 0;
    list-style: none;
    max-height: 300px;
    overflow: auto;
    max-width: 560px;
    border: 1px solid #d4d4d9;
    border-radius: 4px;

    li {
      background-color: white;
      border: 1px solid transparent;
      margin: 2px 0;
      padding: 10px 0 10px 5px;
      :hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.green};
      }

      &.active {
        background-color: ${(props) => props.theme.green};
        border-radius: 2px;
      }

      h3 {
        font-family: ${(props) => props.theme.secondaryFont};
        font-size: 15px;
        margin: 0;
      }
    }
  }
`;

export const ViewSubTitle = styled.h2`
  font-family: ${(props) => props.theme.primaryFont};
  padding-bottom: 8px;
  letter-spacing: 1px;
  font-size: 11px;
  color: ${(props) => props.theme.fontColors?.primary};
  text-transform: uppercase;
`;

export const BusinessName = styled.h2`
  color: ${(props) => props.theme.fontColors?.primary};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 20px;
  display: inline-block;
  margin: 0px;
  font-weight: 600;
`;

export const StateNoticeText = styled.p`
  color: #808080;
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 11px;
  margin: 0px;
  padding-bottom: 8px;
`;

export const SimplybindStateNoticeText = styled(StateNoticeText)`
  color: ${(props) => props.theme.regularGray};
  font-size: 13px;
  margin-top: 16px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  > div:nth-of-type(2) {
    padding-left: 16px;
  }
`;

export const EditButton = styled.button`
  margin-left: 8px;
  padding-bottom: 4px;

  ${hideForPrint}
`;

export const PolicyName = styled.p`
  color: ${(props) => props.theme.fontColors?.primary};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  max-width: 155px;
  width: 100%;
  margin: auto;
  padding-top: 9px;
`;

export const PolicyTile = styled.div<any>`
  padding: 13px 10px;
  background-color: ${(props) => (props.white ? props.theme.white : props.theme.offWhite)};
  margin: 0px 7px;
  text-align: center;
  ${(props) =>
    props.invalid &&
    css`
      border: 2px solid ${props.theme.red};
    `}

  button {
    background: transparent;
    font-weight: bold;
    margin-top: 8px;
  }
`;

export const CoverageType = styled.p`
  color: ${(props) => props.theme.fontColors?.primary};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 16px;
  margin-bottom: 0px;
  margin-top: 8px;
`;

export const CoverageCard = styled.div`
  background-color: white;
  margin-bottom: 16px;
`;

export const PanelContainer = styled.div`
  width: 100%;
`;

export const CoverageInputContainer = styled.div`
  max-width: 530px;
  width: 100%;
`;
