import React, { useState, useContext } from "react";
import { ModalField, ModalContentContainer, ActionContainer } from "shared/ModalComponents";
import Modal from "shared/Modal";
import RuleDSL from "../Shared/RuleDSL";
import { Rule } from "../Shared/types";
import { useEffect } from "react";
import { cleanRuleForPost } from "./utils";
import { GreenButton, Input } from "styles";
import { GhostButton } from "shared/SimplyBind/styles";
import BasicModal from "shared/ModalBasic";
import styled from "styled-components";
import { RuleBuilderContext } from "./RuleBuilderContext";
import { Spinner } from "reactstrap";

interface UpdatePreviewModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleSubmit: () => void;
  rule: Rule;
  loading: boolean;
}
export const UpdatePreviewModal = ({ isOpen, setIsOpen, handleSubmit, rule, loading }: UpdatePreviewModalProps) => {
  const { updateLabel } = useContext(RuleBuilderContext);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    setInputValue(rule.label);
  }, [rule.label]);

  useEffect(() => {}, [inputValue]);
  return (
    <BasicModal
      isOpen={isOpen}
      closeModal={() => setIsOpen(false)}
      label="Submit Rule"
      title="Submit Rule"
      loading={loading}
    >
      <ModalContentContainer>
        <RuleDSL rule={cleanRuleForPost(rule)} />
        <ModalField>Name This Rule</ModalField>
        <Input
          name="Rule Name"
          handleBlur={(e: React.ChangeEvent) => updateLabel((e.currentTarget as HTMLInputElement).value)}
          handleChange={(e: React.ChangeEvent) => setInputValue((e.currentTarget as HTMLInputElement).value)}
          label="Rule Name"
          labelIsHidden
          placeholder="Enter a rule name"
          defaultValue={rule.label ?? ""}
          value={inputValue}
          halfwidth
        />
        <ActionContainer>
          <GreenButton
            disabled={!inputValue || loading}
            onClick={() => {
              handleSubmit();
            }}
          >
            {loading ? <Spinner color="light" /> : "Submit"}
          </GreenButton>
        </ActionContainer>
      </ModalContentContainer>
    </BasicModal>
  );
};

interface SuccessModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  ruleLabel?: string;
  isNewRule: boolean;
}

export const SuccessModal = ({ isOpen, setIsOpen, ruleLabel, isNewRule }: SuccessModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => setIsOpen(false)}
      label="Confirmation"
      title="Confirmation"
      onSubmit={() => setIsOpen(false)}
      buttonText="Done"
    >
      <ModalContentContainer>
        <p>
          {ruleLabel ? ruleLabel : "Rule"} has been successfully {isNewRule ? "created" : "updated"}.
        </p>
      </ModalContentContainer>
    </Modal>
  );
};

interface DeleteModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleSubmit: () => void;
}

export const DeleteVariableModal = ({ isOpen, setIsOpen, handleSubmit }: DeleteModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => setIsOpen(false)}
      label="Are you sure?"
      title="Are you sure?"
      onSubmit={() => {
        handleSubmit();
        setIsOpen(false);
      }}
      buttonText="Delete"
    >
      <ModalContentContainer>
        <p>By deleting this condition, you will delete all of the proceeding rule.</p>
      </ModalContentContainer>
    </Modal>
  );
};

export const EditRuleModal = ({ isOpen, setIsOpen, handleSubmit }: DeleteModalProps) => {
  return (
    <BasicModal isOpen={isOpen} closeModal={() => setIsOpen(false)} label="Edit Rule" title="Edit Rule">
      <StyledModalContentContainer>
        <StyledModalText>Are you sure that you want to edit this rule?</StyledModalText>
        <StyledModalText>If so, you will be automatically redirected to Rule Builder. </StyledModalText>
      </StyledModalContentContainer>
      <ActionContainer>
        <StyledGhostButton onClick={() => setIsOpen(false)}>Cancel</StyledGhostButton>
        <GreenButton
          onClick={() => {
            handleSubmit();
            setIsOpen(false);
          }}
        >
          Confirm
        </GreenButton>
      </ActionContainer>
    </BasicModal>
  );
};

const StyledGhostButton = styled(GhostButton)`
  margin-right: 16px;
`;

const StyledModalText = styled.p`
  text-align: center;
  font-size: 15px;
`;

const StyledModalContentContainer = styled(ModalContentContainer)`
  margin: 1.5em 0 2em 0;
`;
