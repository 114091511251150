import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Modal from "shared/Modal";
import { LabelLarge } from "./FormFields";
import GLicon from "images/gl_policy.svg";
import { formatNumberLong } from "utils/formatNumber";
import MoreDetailsTable from "./MoreDetailsTable";
import { TextLink } from "styles";

export const createRows = (glLimit?: number, deductible?: number, glAggregatePcoLimit?: number) => [
  {
    coverage: "Liability and Medical Expenses",
    toolTipContentKey: "",
    limit: `$${formatNumberLong(glLimit)}`,
    deductible: `$${formatNumberLong(deductible)}`,
  },
  {
    coverage: "Medical Expenses",
    toolTipContentKey: "",
    limit: "$5,000/person",
    deductible: `$${formatNumberLong(deductible)}`,
  },
  {
    coverage: "Damage to Premises Rented to You",
    toolTipContentKey: "",
    limit: "$50,000",
    deductible: `$${formatNumberLong(deductible)}`,
  },
  {
    coverage: "Products/Completed Operations Annual Aggregate",
    toolTipContentKey: "",
    limit: `$${formatNumberLong(glAggregatePcoLimit)}`,
    deductible: `$${formatNumberLong(deductible)}`,
  },
];

interface PropTypes {
  glLimit?: number;
  deductible?: number;
  glAggregatePcoLimit?: number;
}

const GlMoreDetails = ({ glLimit, deductible, glAggregatePcoLimit }: PropTypes) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Fragment>
      <MoreDetailsButton onClick={openModal} data-cy="gl-more-details-button">
        More details
      </MoreDetailsButton>
      <Modal isOpen={modalIsOpen} closeModal={closeModal} label="GL more details modal" title="Coverage Details">
        <ModalHeader>
          <LabelLarge>
            <PolicyIcon src={GLicon} alt="" aria-hidden="true" />
            General Liability Policy Details
          </LabelLarge>
        </ModalHeader>
        <MoreDetailsTable rows={createRows(glLimit, deductible, glAggregatePcoLimit)} />
      </Modal>
    </Fragment>
  );
};

export default GlMoreDetails;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.neutral.white};
`;

const MoreDetailsButton = styled(TextLink)`
  margin-top: -8px;
  padding: 0px 20px;
  float: right;
  background: white;
`;

const ModalHeader = styled(Header)`
  padding: 5px 20px;
`;

const PolicyIcon = styled.img`
  height: 35px;
  width: 50px;
  margin-right: 20px;
  vertical-align: middle;
`;
