import React from "react";
import { coverageDetailsPL, coverageDetailsGL, coverageDetailsBOP } from "utils/proposal-pdf/constants";
import { Card, CardSectionFullWidth, CardSectionTitle, Row, Label, Value, Description } from "./tabContentStyles";
import { CoverageExclusionAccordion } from "shared/application/edit/Coverages/CoverageExclusionAccordion";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const CoverageOverview = ({ application, quote }: { application: Application; quote: BindableQuote }) => {
  const hasBOP = application?.applicationTypes?.includes("BOP");
  const hasGL = application?.applicationTypes?.includes("GL");
  const hasPL = application?.applicationTypes?.includes("PL");

  const { showExclusions, increaseFlPropertyMin } = useFeatureFlags();

  return (
    <Card data-cy="coverage-overview">
      {hasBOP && (
        <CardSectionFullWidth>
          <CardSectionTitle>{coverageDetailsBOP(application)?.title}</CardSectionTitle>
          {coverageDetailsBOP(application, increaseFlPropertyMin)
            ?.coverages.filter((coverage) => coverage.value)
            .map((c) => (
              <Row key={c.label}>
                <Label>{c.label}</Label>
                <Value isNarrow>{c.value}</Value>
                {c.description && <Description>{c.description}</Description>}
              </Row>
            ))}
        </CardSectionFullWidth>
      )}
      {(hasGL || hasBOP) && (
        <CardSectionFullWidth>
          <CardSectionTitle>{coverageDetailsGL(application)?.title}</CardSectionTitle>
          {coverageDetailsGL(application)?.coverages.map((c) => (
            <Row key={c.label}>
              <Label>{c.label}</Label>
              <Value isNarrow>{c.value}</Value>
              {c.description && <Description>{c.description}</Description>}
            </Row>
          ))}
        </CardSectionFullWidth>
      )}
      {hasPL && (
        <CardSectionFullWidth>
          <CardSectionTitle>{coverageDetailsPL(application)?.title}</CardSectionTitle>
          {coverageDetailsPL(application)?.coverages.map((c) => (
            <Row key={c.label}>
              <Label>{c.label}</Label>
              <Value isNarrow>{c.value}</Value>
            </Row>
          ))}
        </CardSectionFullWidth>
      )}
      {showExclusions && quote && (hasGL || hasBOP) && (
        <CoverageExclusionAccordion isBOP={Boolean(hasBOP)} exclusions={quote.exclusions} initialExpanded />
      )}
    </Card>
  );
};

export default CoverageOverview;
