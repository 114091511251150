import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { YesNoQuestion } from "coterie-ui-library";
import SmoothCollapse from "react-smooth-collapse";
import { Textarea, Label } from "styles";
import MultiButton from "../MultiButton";
import { ErrorText } from "styles/Input";
import Loss from "./Loss";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";

interface PropTypes {
  previousLosses?: Claim[];
  add: (claim: Claim) => void;
  remove: (index: number) => void;
  clear: ActionCreatorWithoutPayload<any>;
  reset: ActionCreatorWithoutPayload<any>;
}

const lossValueOptions = [
  {
    title: "$3,000 or less",
    values: [100, 500, 1000, 1500, 2000, 2500, 3000],
  },
  {
    title: "$3,500 - $10,000",
    values: [3500, 4000, 5000, 6000, 7000, 8000, 9000, 10000],
  },
  {
    title: "Over $10,000",
    customInput: ">$20k", // shouldn't be boolean, should be value to use at end of values array
    values: [11000, 12000, 13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000],
    placeholder: "Amount of claim",
  },
];

const PreviousLosses = ({ add, remove, previousLosses, reset, clear }: PropTypes) => {
  const [lossValue, updateLossValue] = useState<any>(0);
  const [lossDescription, updateLossDescription] = useState("");
  const [showClaimsForm, updateShowClaimsForm] = useState<boolean | null>(null);

  const handleUpdateLossValue = (value: any) => {
    if (value && value > 0 && value < 9999999) {
      updateLossValue(value);
    }
  };

  useEffect(() => {
    if (previousLosses === null) {
      updateShowClaimsForm(null);
    }
  }, [previousLosses]);

  const addLoss = () => {
    const claim = {
      amount: lossValue,
      description: lossDescription,
    };

    add(claim);
    updateLossValue(null);
    updateLossDescription("");
  };

  const removeLoss = (lossIndex: number) => {
    remove(lossIndex);
  };

  const handleShowClaimsForm = () => {
    updateShowClaimsForm(true);
    reset();
  };

  const handleHideClaimsForm = () => {
    updateShowClaimsForm(false);
    clear();
  };

  const yesNoSelectedValue = () => {
    if (previousLosses === null && showClaimsForm === null) {
      return null;
    } else if ((previousLosses && previousLosses.length > 0) || showClaimsForm) {
      return true;
    } else {
      return false;
    }
  };

  const isAboveClaimLimit = previousLosses && previousLosses.length > 3;
  const isClaimValueTooHigh = previousLosses && previousLosses?.filter((loss) => loss.amount > 15000).length > 0;
  const shouldOpenPreviousLossForm = Boolean(
    (!showClaimsForm && previousLosses && previousLosses.length > 0) || showClaimsForm
  );
  const isDisabled = Boolean(!lossDescription || !lossValue || isAboveClaimLimit || isClaimValueTooHigh);

  return (
    <Container>
      <YesNoQuestion
        question="Any claims in the past 5 years?"
        value={yesNoSelectedValue()}
        selectYes={handleShowClaimsForm}
        selectNo={handleHideClaimsForm}
        name="previous-loss"
      />
      <SmoothCollapse expanded={shouldOpenPreviousLossForm}>
        {Array.isArray(previousLosses) &&
          previousLosses.map((loss, index) => (
            <Loss
              amount={loss.amount}
              description={loss.description}
              removeLoss={removeLoss}
              index={index}
              key={index}
            />
          ))}
        {shouldOpenPreviousLossForm && (
          <>
            <StyledLabel>Add all claims from previous 5 years</StyledLabel>
            <MultiButton
              value={lossValue}
              available={true}
              updateValue={handleUpdateLossValue}
              directLabel="Enter the amount of the claim"
              money={true}
              options={lossValueOptions}
            />
            <StyledLabel htmlFor="claim-description">Claim Description</StyledLabel>
            <Textarea
              id="claim-description"
              value={lossDescription}
              onChange={(e) => updateLossDescription(e.target.value)}
              placeholder="Describe the claim"
            />
            <AddLossButton onClick={addLoss} disabled={isDisabled}>
              Save Claim
            </AddLossButton>

            {isAboveClaimLimit && (
              <StyledErrorText>Cannot have more than 3 claims in the last 5 years.</StyledErrorText>
            )}
            {isClaimValueTooHigh && <StyledErrorText>Cannot have a claim amount over $15,000.</StyledErrorText>}
          </>
        )}
      </SmoothCollapse>
    </Container>
  );
};

const Container = styled.section`
  max-width: 600px;
  width: 100%;
  margin: 16px 0;
`;

const AddLossButton = styled.button`
  appearance: none;
  border: none;
  padding: 10px 15px;
  margin-top: 8px;
  font-size: 12px;
  background-color: ${(props) => props.theme.button};
  font-family: ${(props) => props.theme.primaryFont};
  color: ${(props) => props.theme.fontColors.primary};
  transition: all 0.25s ease;
  border-radius: 20px;
  font-weight: bold;
  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.charcoalGrey};
    color: white;
  }
  :disabled {
    color: ${(props) => props.theme.disabledText};
    background-color: ${(props) => props.theme.disabledBackground};
    pointer-events: none;
  }
`;

const StyledLabel = styled(Label)`
  padding-top: 16px;
`;

const StyledErrorText = styled(ErrorText)`
  margin-top: 8px;
`;

export default PreviousLosses;
