import Api from "utils/api";
import { useQuery } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const useGetBillingBalance = (policyNumber: string, queryOptionOverrides?: any) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const billingBalance = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return await api.dashboardBilling.getBillingBalance(policyNumber);
  };

  return useQuery({
    queryKey: ["billing_balance", policyNumber],
    queryFn: billingBalance,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 1,
    staleTime: twentyFourHoursInMs,
    ...queryOptionOverrides,
    enabled: !!policyNumber,
  });
};

export default useGetBillingBalance;
