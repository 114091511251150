import * as Sentry from "@sentry/react";
import { Dictionary } from "lodash";
import { StripeError } from "@stripe/stripe-js";
import { AxiosError } from "axios";

const isLocal = (hostname: string) =>
  hostname.includes("kit-test.local") || hostname.includes("dashboard-test.local") || hostname.includes("localhost");

const errorsToExclude = ["Payment failed: This transaction has been declined.", "Agent with NPN"];

export type SentryContext = {
  name: string;
  data: Dictionary<any>;
};

export type SentryTag = {
  key: string;
  value: string;
};

export type ErrorToReport = string | Error | StripeError | AxiosError;

/**
 * Logs an event to Sentry.io
 * @arg error - Captures error events in Sentry.io https://docs.sentry.io/platforms/javascript/usage/
 * @arg context - Adds additional context to events https://docs.sentry.io/platforms/javascript/enriching-events/context/
 * @arg tag - Adds a indexed and searchable tag to events https://docs.sentry.io/platforms/javascript/enriching-events/tags/
 */
export function reportToSentry(error: ErrorToReport, context?: SentryContext, tag?: SentryTag | SentryTag[]) {
  if (context) {
    Sentry.configureScope((scope) => scope.setContext(context.name, context.data));
  }
  if (tag) {
    Sentry.configureScope((scope) => {
      if (Array.isArray(tag)) {
        tag.forEach((tagElem) => scope.setTag(tagElem.key, tagElem.value));
      } else {
        scope.setTag(tag.key, tag.value);
      }
    });
  }
  const stringifiedError = JSON.stringify(error, Object.getOwnPropertyNames(error));
  const shouldExclude = errorsToExclude.some((err) => stringifiedError.includes(err));

  if (!shouldExclude) {
    Sentry.captureException(error);
  }
}

export function sentrySetup() {
  const hostname = window.location.hostname.toLowerCase();
  const environment = isLocal(hostname) ? "local" : process.env.REACT_APP_ENVIRONMENT || "test";

  Sentry.init({
    release: `kit@${process.env.REACT_APP_GIT_SHA}`,
    dsn: "https://532be4ad414c4768a20af0caf242cecf@o242535.ingest.sentry.io/5542120",
    environment,
  });
}
