import { useMutation } from "@tanstack/react-query";
import Api from "utils/api";
import useAuth from "./useAuth";
import { useSelector } from "react-redux";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

export const usePostAddAccountSelfService = (options?: any) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const agencyId = useSelector((state: ReduxState) => state.user.agency.id);

  const postUser = async (payload: CreateAccountRequest) => {
    const token = await getToken();
    const api = new Api(apiVersion, token);
    return api.createAccountSelfService({ agencyId: agencyId, ...payload });
  };

  return useMutation({
    mutationFn: postUser,
    ...options,
  });
};
