import React, { useState } from "react";
import { Form } from "reactstrap";
import styled from "styled-components";
import { Label, Input, theme } from "styles";
import { PAGES } from "utils/constants";
import Api from "utils/api";
import Loader from "shared/AjaxLoader";
import checkmark from "images/checkmark.svg";
import { NextButton, Description } from "./StyledComponents";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  goToNextPage: (page: string, accountValues: AccountValues) => void;
  setVerifiedCalled: (arg0: boolean) => void;
}

const NpnVerification = ({ goToNextPage, setVerifiedCalled }: PropTypes) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const [nationalProducerNumber, setNationalProducerNumber] = useState<string | undefined>(undefined);
  const [verifiedFirstName, updateVerifiedFirstName] = useState("");
  const [verifiedLastName, updateVerifiedLastName] = useState("");
  const [verifying, toggleVerifying] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const [verifyErrorMessage, setVerifyErrorMessage] = useState("");

  const verifiedName = verifiedFirstName && verifiedLastName && `${verifiedFirstName} ${verifiedLastName}`;

  const handleInputChange = ({ value }: ValuesType) => {
    setNationalProducerNumber(value);
  };

  const handleVerify = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    toggleVerifying(true);

    const token = await getToken();
    const api = new Api(apiVersion, token);

    api
      .verifyNPN(String(nationalProducerNumber))
      .then((res) => {
        if (res.firstName && res.lastName) {
          updateVerifiedFirstName(res.firstName);
          updateVerifiedLastName(res.lastName);
        } else {
          setVerifyError(true);
          setVerifyErrorMessage("Error: Enter your NPN again, or visit nipr.com for help.");
        }
      })
      .catch((err) => {
        goToNextPage(PAGES.ACCOUNT_TYPE, {
          nationalProducerNumber,
        });
        // TODO: fix verify npn bypass error messaging [sc32122]
        // if (err.status === 500) {
        //   goToNextPage(PAGES.ACCOUNT_TYPE, {
        //     nationalProducerNumber,
        //   });
        // } else {
        //   const errorMessage = (err: any) => {
        //     if (err.message === "Invalid NPN format.") {
        //       return `Error: ${err.message}`;
        //     } else {
        //       return "Error: Enter your NPN again, or visit nipr.com for help.";
        //     }
        //   };

        // setVerifyError(true);
        // setVerifyErrorMessage(errorMessage(err));
        // }
      })
      .finally(() => {
        toggleVerifying(false);
        setVerifiedCalled(true);
      });
  };

  const handleNextPage = () =>
    goToNextPage(PAGES.ACCOUNT_TYPE, {
      nationalProducerNumber,
      firstName: verifiedFirstName,
      lastName: verifiedLastName,
    });

  return (
    <>
      <Form onSubmit={verifiedName ? handleNextPage : handleVerify}>
        <StyledDescription>
          To add a user, enter the National Producer Number after adding them to agent sync.
        </StyledDescription>

        {verifiedName ? (
          <>
            <StyledLabel data-cy="verified">
              Verified! <span>{nationalProducerNumber}</span>
            </StyledLabel>
            <InputContainer>
              <img src={checkmark} alt="" />
              <Input type="text" value={verifiedName} name="verifiedName" readOnly />
            </InputContainer>
          </>
        ) : (
          <InputContainer>
            {verifying && <Loader color={theme.green} height={12} />}
            <Input
              label="Agent's NPN"
              type="number"
              value={nationalProducerNumber}
              handleChange={handleInputChange}
              name="nationalProducerNumber"
              required
              data-testid="nationalProducerNumber"
              error={verifyError}
              errorText={verifyErrorMessage}
              loading={verifying}
              format={(val: any) => (val.length <= 10 ? val : String(nationalProducerNumber))}
              placeholder="National Producer Number"
            />
          </InputContainer>
        )}
        <NextButton type="submit" disabled={nationalProducerNumber === undefined || verifying}>
          Next
        </NextButton>
      </Form>
    </>
  );
};

const StyledDescription = styled(Description)`
  text-align: center;
`;

const StyledLabel = styled(Label)`
  margin-bottom: 10px;
  span {
    font-weight: normal;
  }
`;

const InputContainer = styled.div`
  position: relative;

  svg {
    right: 5px;
    position: absolute;
    top: 47px;
  }
  img {
    right: 10px;
    position: absolute;
    bottom: 23px;
  }
`;

export default NpnVerification;
