import React, { useState } from "react";
import styled from "styled-components";
import { CardBody } from "reactstrap";
import { connect } from "react-redux";
import { Header4 } from "styles";
import { Wrapper } from "styles/IAStyledComponents";
import { ReactComponent as EditIcon } from "images/edit.svg";
import EditPartnerModal from "./EditPartnerModal";
import AgenciesTable from "./AgenciesTable";
import { getUser } from "redux/reducers/_userReducer";
import { selectChannelPartner } from "redux/selectors/user";
import Field from "shared/Field";
import { Row, EditButton } from "shared/application/StyledComponents";
import useAPI from "hooks/useAPI";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  channelPartner: ChannelPartner;
  getUser: (auth: APIVersionAuth) => void;
}

const Partner = (props: PropTypes) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const { channelPartner, getUser } = props;
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const onSuccess = async () => {
    const token = await getToken();
    getUser({ apiVersion, token });
    setShowEditModal(false);
  };

  const { callback, loading: editing } = useAPI("updateChannelPartner", [channelPartner.id], {
    onSuccess,
    successMessage: "Partner Updated!",
  });

  const handleSubmit = (channelPartnerUpdate: UpdateChannelPartnerRequest) => {
    callback(channelPartnerUpdate);
  };

  const displayModal = () => {
    setShowEditModal(true);
  };

  const closeModal = () => {
    setShowEditModal(false);
  };

  const { name = "", id } = channelPartner;
  const phoneNumber = "";
  const mailingAddress = "";

  return (
    <React.Fragment>
      <Row>
        <Header4 data-cy="agency-page-title">Partner Information</Header4>
        <EditButton aria-label="edit icon" data-cy="edit-account-info" onClick={displayModal}>
          <EditIconWrapper>
            <EditIcon title="edit icon" />
          </EditIconWrapper>
        </EditButton>
      </Row>
      <Wrapper>
        <StyledCardBody>
          <Field value={name} label="Partner Name" aria-label="Partner Name" data-testid="partner-name" />
          <Field
            value={phoneNumber}
            label="Phone Number"
            aria-label="Partner Phone Number"
            data-testid="phone-number"
          />
          <Field
            value={mailingAddress}
            label="Mailing Address"
            aria-label="Partner Mailing Address"
            data-testid="mailing-address"
          />
        </StyledCardBody>
        <EditPartnerModal
          isLoading={editing}
          closeModal={closeModal}
          modalIsOpen={showEditModal}
          name={name}
          phoneNumber={phoneNumber}
          save={handleSubmit}
          channelPartnerId={id}
        />
      </Wrapper>

      <AgenciesTable />
    </React.Fragment>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  channelPartner: selectChannelPartner(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getUser: (auth: APIVersionAuth) => dispatch(getUser(auth)),
});

const EditIconWrapper = styled.div`
  svg {
    fill: ${(props) => props.theme.blue};
  }
`;

const StyledCardBody = styled(CardBody)`
  padding: 0;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Partner);
