export const salesforceLiveAgentInit = (externalId: string) => {
  const embedded_svc = window.embedded_svc;
  const initESW = function (
    initArg1: string,
    initArg2: string,
    initArg3: string,
    initArg4: string,
    initArg5: {
      baseLiveAgentContentURL: string;
      deploymentId: string;
      buttonId: string;
      baseLiveAgentURL: string;
      eswLiveAgentDevName: string;
      isOfflineSupportEnabled: boolean;
    }
  ) {
    embedded_svc.settings.displayHelpButton = true;
    embedded_svc.settings.language = "en-US";

    embedded_svc.settings.defaultMinimizedText = "Need help? Chat with a specialist";

    embedded_svc.settings.enabledFeatures = ["LiveAgent"];
    embedded_svc.settings.entryFeature = "LiveAgent";

    embedded_svc.settings.extraPrechatFormDetails = [
      {
        label: "Coterie GUID",
        value: externalId,
        transcriptFields: ["Coterie_GUID__c"],
        displayToAgent: false,
      },
    ];

    embedded_svc.init(initArg1, initArg2, "https://service.force.com", initArg3, initArg4, initArg5);
  };

  if (!window.embedded_svc) {
    const script = document.createElement("script");
    if (isProdEnvironment()) {
      script.setAttribute("src", "https://coterie.my.salesforce.com/embeddedservice/5.0/esw.min.js");
    } else if (isTestEnvironment()) {
      script.setAttribute("src", "https://coterie--chatbot.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js");
    }
    script.onload = function () {
      if (isProdEnvironment()) {
        initESW(
          "https://coterie.my.salesforce.com",
          "https://coterie.my.site.com/CoterieForms",
          "00D5c000000leJG",
          "Dashboard_Chat",
          {
            baseLiveAgentContentURL: "https://c.la4-c1-ia5.salesforceliveagent.com/content",
            deploymentId: "5725c000000TcOx",
            buttonId: "5735c0000010zdE",
            baseLiveAgentURL: "https://d.la4-c1-ia5.salesforceliveagent.com/chat",
            eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04I5c0000010xLeEAI_1895689ba25",
            isOfflineSupportEnabled: false,
          }
        );
      } else if (isTestEnvironment()) {
        initESW(
          "https://coterie--chatbot.sandbox.my.salesforce.com",
          "https://coterie--chatbot.sandbox.my.site.com/CoterieForms",
          "00D7e00000GLELT",
          "Dashboard_Chatbot",
          {
            baseLiveAgentContentURL: "https://c.la3-c1cs-ia5.salesforceliveagent.com/content",
            deploymentId: "5727j00000001AV",
            buttonId: "5737e0000008TIV",
            baseLiveAgentURL: "https://d.la3-c1cs-ia5.salesforceliveagent.com/chat",
            eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04I7e0000008OfjEAE_188b66cb831",
            isOfflineSupportEnabled: false,
          }
        );
      }
    };
    document.body.appendChild(script);
  } else {
    if (isProdEnvironment()) {
      initESW(
        "https://coterie.my.salesforce.com",
        "https://coterie.my.site.com/CoterieForms",
        "00D5c000000leJG",
        "Dashboard_Chat",
        {
          baseLiveAgentContentURL: "https://c.la4-c1-ia5.salesforceliveagent.com/content",
          deploymentId: "5725c000000TcOx",
          buttonId: "5735c0000010zdE",
          baseLiveAgentURL: "https://d.la4-c1-ia5.salesforceliveagent.com/chat",
          eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04I5c0000010xLeEAI_1895689ba25",
          isOfflineSupportEnabled: false,
        }
      );
    } else if (isTestEnvironment()) {
      initESW(
        "https://coterie--chatbot.sandbox.my.salesforce.com",
        "https://coterie--chatbot.sandbox.my.site.com/CoterieForms",
        "00D7e00000GLELT",
        "Dashboard_Chatbot",
        {
          baseLiveAgentContentURL: "https://c.la3-c1cs-ia5.salesforceliveagent.com/content",
          deploymentId: "5727j00000001AV",
          buttonId: "5737e0000008TIV",
          baseLiveAgentURL: "https://d.la3-c1cs-ia5.salesforceliveagent.com/chat",
          eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04I7e0000008OfjEAE_188b66cb831",
          isOfflineSupportEnabled: false,
        }
      );
    }
  }
};

const isTestEnvironment = () => {
  if (
    window.location.hostname.toLowerCase() === `dashboard-test.${process.env.REACT_APP_DOMAIN}` ||
    window.location.hostname.toLowerCase() === "kit-test.local" ||
    window.location.hostname.toLowerCase() === `dashboard-sandbox.${process.env.REACT_APP_DOMAIN}` ||
    window.location.hostname.toLowerCase() === `kit-test.${process.env.REACT_APP_DOMAIN}`
  ) {
    return true;
  } else {
    return false;
  }
};

const isProdEnvironment = () => {
  if (window.location.hostname.toLowerCase() === `dashboard.${process.env.REACT_APP_DOMAIN}`) {
    return true;
  } else {
    return false;
  }
};
