import styled from "styled-components";

export const CancelError = styled.div`
  color: ${({ theme }) => theme.colors.alert.zero};
  font-size: 12px;
  margin-top: 8px;
  line-height: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
`;
