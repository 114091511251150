import React, { useState } from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import styled from "styled-components";
import { Label, theme } from "styles";
import { Row } from "shared/application/StyledComponents";
import { Column } from "shared/ModalComponents";
import formotivInstance from "utils/formotiv";

interface PaymentCreditCardProps {
  errorMessage: string;
  setErrorMessage: (error: string) => void;
  isSnapshot?: boolean;
}

const PaymentCreditCard = ({ errorMessage, setErrorMessage, isSnapshot }: PaymentCreditCardProps) => {
  const [focusedInput, setFocusedInput] = useState("");

  const inputStyle = {
    fontWeight: "400",
    fontFamily: theme.secondaryFont,
    fontSize: "16px",
    lineHeight: "25px",
  };

  const options = {
    style: {
      base: inputStyle,
    },
  };

  const handleFocus = (id: string) => {
    if (!isSnapshot) {
      formotivInstance.trackCustom(id, "text-focus");
    }
    setFocusedInput(id);
  };

  const handleChange = (e: any) => {
    if (errorMessage) {
      setErrorMessage("");
    }
  };

  const handleBlur = () => setFocusedInput("");

  return (
    <div data-testid="stripe-checkout">
      <Label htmlFor="card_number">Credit Card Number</Label>
      <CardInputWrapper hasFocus={focusedInput === "card_number"}>
        <CardNumberElement
          id="card_number"
          options={{ ...options, placeholder: "" }}
          onChange={(e) => handleChange(e)}
          onFocus={() => handleFocus("card_number")}
          onBlur={handleBlur}
        />
      </CardInputWrapper>
      <Row>
        <Column>
          <Label htmlFor="card_expiration">Expiration</Label>
          <CardInputWrapper hasFocus={focusedInput === "card_expiration"}>
            <CardExpiryElement
              id="card_expiration"
              options={{ ...options, placeholder: "" }}
              onChange={(e) => handleChange(e)}
              onFocus={() => handleFocus("card_expiration")}
              onBlur={handleBlur}
            />
          </CardInputWrapper>
        </Column>
        <Column>
          <Label htmlFor="card_cvc">CVC (Security Code)</Label>
          <CardInputWrapper hasFocus={focusedInput === "card_cvc"}>
            <CardCvcElement
              id="card_cvc"
              options={{ ...options, placeholder: "" }}
              onChange={(e) => handleChange(e)}
              onFocus={() => handleFocus("card_cvc")}
              onBlur={handleBlur}
            />
          </CardInputWrapper>
        </Column>
      </Row>
    </div>
  );
};

const CardInputWrapper = styled.div<{ hasFocus: boolean }>`
  background-color: ${(props) => props.theme.white};
  border: 1px solid ${(props) => (props.hasFocus ? props.theme.blue : props.theme.lighterGray)};
  padding: 11px 16px;
  border-radius: 4px;
  margin: 8px 0 24px;
`;

export default PaymentCreditCard;
