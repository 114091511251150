import styled from "styled-components";
import blueHalfCircles from "images/half_circles_background_blue.svg";

export const ImageUploadContainer = styled.div`
  display: flex;
`;

export const ImageUploadArea = styled.div<{ errorMessage: string | null; hasImagePreview: boolean }>`
  border: 1px ${({ hasImagePreview }) => (hasImagePreview ? "solid" : "dashed")}
    ${({ errorMessage, hasImagePreview, theme }) =>
      errorMessage
        ? theme.pageComponents.kit.imageUpload.imageUploadArea.border.error
        : hasImagePreview
        ? theme.pageComponents.kit.imageUpload.imageUploadArea.border.hasPreview
        : theme.pageComponents.kit.imageUpload.imageUploadArea.border.default};
  width: 304px;
  height: 146px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ hasImagePreview, theme }) =>
    hasImagePreview
      ? `
    background-color: ${theme.offWhite};
    background-image: linear-gradient(45deg, ${theme.lighterGray} 25%, transparent 25%), linear-gradient(-45deg, ${theme.lighterGray} 25%, transparent 25%), linear-gradient(45deg, transparent 75%, ${theme.lighterGray} 75%), linear-gradient(-45deg, transparent 75%, ${theme.lighterGray} 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  `
      : `background-image: url(${blueHalfCircles}); background-size: cover; background-color: white;`}
`;

export const MessageLabelsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
`;

export const ButtonsContainer = styled.div`
  margin-top: 16px;
`;

export const Button = styled.button`
  background: none;
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.pageComponents.kit.imageUpload.buttonColor};
  padding: 0px;
  &:first-child {
    margin-bottom: 8px;
  }
`;

export const FileIcon = styled.img`
  margin-right: 4px;
`;

export const TrashIcon = styled.img`
  margin: 0 5px 5px 0;
`;

export const PreviewImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: relative;
  padding: 8px;
`;

export const UploadLogo = styled.img`
  margin-bottom: 16px;
`;

export const DragAndDropLabel = styled.label`
  color: ${({ theme }) => theme.pageComponents.kit.imageUpload.labelColor.dragAndDrop};
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 16px;
`;

export const BrowseLabel = styled.span`
  color: ${({ theme }) => theme.pageComponents.kit.imageUpload.labelColor.browse};
`;

export const AcceptedLabel = styled.label`
  color: ${({ theme }) => theme.pageComponents.kit.imageUpload.labelColor.accepted};
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: 13px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 0;
`;

export const FileInput = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  position: absolute;
`;

export const ImageDetails = styled.div`
  margin-left: 16px;
`;

export const FileNameText = styled.p`
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.pageComponents.kit.imageUpload.textColor.fileName};
  overflow-wrap: anywhere;
  line-height: 1.3;
`;

export const FileSizeText = styled.p`
  margin-bottom: 0;
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.pageComponents.kit.imageUpload.textColor.fileSize};
`;

export const LoadingContainer = styled.div`
  margin-top: 16px;
`;
