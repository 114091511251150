import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { QuestionRow } from "../StyledComponents";
import Input from "styles/Input";
import { selectUnderwritingProfile } from "redux/selectors/underwritingProfile";
import { updateEndorsementQuestionState } from "redux/reducers/_underwritingProfileReducer";

interface PropTypes {
  action: (value: number) => void;
  setQuestionHasBeenAnsweredByUser: () => void;
  underwritingProfile: UnderwritingProfile;
  updateEndorsementQuestionState: (endorsementDetails: { key: string; questionHasBeenAnswered: boolean }) => void;
}

const errors = [
  {
    errorText: "An amount is required to increase your total damage protection",
    error: (amount: number | undefined) => Boolean(amount === undefined),
  },
  {
    errorText: "Enter limit of $1,000,000 or lower",
    error: (amount: number) => Boolean(amount && amount > 1_000_000),
  },
  {
    errorText: "$50,000 of coverage included in the base policy",
    error: (amount: number) => Boolean(amount && amount <= 50000) || amount === 0,
  },
];

const LimitDamagePremRentedAddOn = ({ action, underwritingProfile, updateEndorsementQuestionState }: PropTypes) => {
  const endorsementAmount = () => {
    const limitDamagePremRented = underwritingProfile?.legacyApplication?.limitDamagePremRented;
    if (limitDamagePremRented === 0 || limitDamagePremRented === undefined) {
      return undefined;
    } else {
      return limitDamagePremRented + 50_000;
    }
  };

  const [amount, setAmount] = useState(endorsementAmount());

  const handleChange = (value: number) => {
    setAmount(value);
    verify(value);
  };

  const verify = useCallback(
    debounce((newAmount) => {
      const hasValidationErrors = errors.some((e) => e.error(newAmount));
      if (newAmount && newAmount !== 0 && !hasValidationErrors) {
        updateEndorsementQuestionState({ key: "limitDamagePremRented", questionHasBeenAnswered: true });
        action(newAmount);
      }
    }, 2000),
    []
  );

  const amountError = errors?.find((e) => e.error(amount))?.errorText;
  const errorProps = amountError ? { error: true, errorText: amountError } : {};

  return (
    <QuestionRow>
      <Input
        type="text"
        id="quote-limit-damage-prem-rented"
        name="limitDamagePremRented"
        value={amount}
        handleChange={(event: { floatValue: number }) => handleChange(event.floatValue)}
        prefix="$"
        label="Choose your total coverage amount up to $1,000,000:"
        placeholder="Enter higher limit (e.g. 100,000)"
        data-testid="limitDamagePremRented"
        inputStyles={{ maxWidth: 256 }}
        {...errorProps}
      />
    </QuestionRow>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  underwritingProfile: selectUnderwritingProfile(state),
});

const mapDispatchToProps = {
  updateEndorsementQuestionState,
};

export default connect(mapStateToProps, mapDispatchToProps)(LimitDamagePremRentedAddOn);
