import { useQuery } from "@tanstack/react-query";
import { reportError } from "utils/reportError";
import { coterieAPI_v1_4 } from "./useAPI/instances";

const getCommercialApplication = async (applicationId: string) => {
  const api = coterieAPI_v1_4();
  const { data } = await api.get(`/commercial/applications/${applicationId}`).catch((error) => {
    reportError(error);
    return Promise.reject(error);
  });

  return data;
};

type Options = {
  enabled?: boolean;
  select?: (data: any) => any;
};

const useGetPublicApplication = (applicationId: string, options?: Options) => {
  return useQuery({
    queryKey: ["commercial_application", applicationId],
    queryFn: () => getCommercialApplication(applicationId),
    retry: 1,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export default useGetPublicApplication;
