import React, { createContext, useReducer } from "react";
import { ruleSearchReducer, ruleSearchInitialState } from "./RuleSearchReducer";
import { RuleSearchState, RuleSearchActionTypes, RuleStatus } from "./types";
import { Rule } from "./RuleSearch";

interface RuleSearchContextProps extends RuleSearchState {
  setFilteredRules: (rules: Rule[]) => void;
  setVariableOptions: (variables: string[]) => void;
  setVariableQuery: (query: string) => void;
  setAvailableVariablesFromRules: (rules: Rule[]) => void;
  setVariableFilter: (variable: string) => void;
  setStatusFilter: (statuses: RuleStatus[]) => void;
  setControlFilter: (control: string) => void;
  setTagsFilter: (tags: string[]) => void;
  clearVariableFilter: () => void;
  clearFilters: () => void;
  sortRules: (sortType: string) => void;
}

export const RuleSearchContext = createContext<RuleSearchContextProps>({} as RuleSearchContextProps);

const RuleSearchProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(ruleSearchReducer, ruleSearchInitialState);

  const value = {
    filteredRules: state.filteredRules,
    variableOptions: state.variableOptions,
    variableQuery: state.variableQuery,
    filters: state.filters,
    availableVariables: state.availableVariables,
    sortType: state.sortType,
    setFilteredRules: (rules: Rule[]) => {
      dispatch({ type: RuleSearchActionTypes.SET_FILTERED_RULES, payload: rules });
    },
    setVariableOptions: (variables: string[]) => {
      dispatch({ type: RuleSearchActionTypes.SET_VARIABLE_OPTIONS, payload: variables });
    },
    setVariableQuery: (query: string) => {
      dispatch({ type: RuleSearchActionTypes.SET_VARIABLE_QUERY, payload: query });
    },
    setAvailableVariablesFromRules: (rules: Rule[]) => {
      dispatch({ type: RuleSearchActionTypes.SET_AVAILABLE_VARIABLES, payload: rules });
    },
    setVariableFilter: (variable: string) => {
      dispatch({ type: RuleSearchActionTypes.SET_VARIABLE_FILTER, payload: variable });
    },
    setTagsFilter: (tags: string[]) => {
      dispatch({ type: RuleSearchActionTypes.SET_TAGS_FILTER, payload: tags });
    },
    setStatusFilter: (statuses: RuleStatus[]) => {
      dispatch({ type: RuleSearchActionTypes.SET_STATUS_FILTER, payload: statuses });
    },
    setControlFilter: (control: string) => {
      dispatch({ type: RuleSearchActionTypes.SET_CONTROL_FILTER, payload: control });
    },
    clearVariableFilter: () => {
      dispatch({ type: RuleSearchActionTypes.CLEAR_VARIABLE_FILTER });
    },
    clearFilters: () => {
      dispatch({ type: RuleSearchActionTypes.CLEAR_FILTERS });
    },
    sortRules: (sortType: string) => {
      dispatch({ type: RuleSearchActionTypes.SORT_RULES, payload: sortType });
    },
  };

  return <RuleSearchContext.Provider value={value}>{children}</RuleSearchContext.Provider>;
};

export default RuleSearchProvider;
