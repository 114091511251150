import React from "react";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { NavLinkStyles, GreenButtonStyles, mobile, hideForPrint } from "styles";
import intersection from "lodash/intersection";
import useAuth from "hooks/useAuth";
import { isCanadaEnv } from "utils/environmentChecker";
import poweredByCoterieLogo from "images/Powered-by-Coterie-logo.svg";
import formotivInstance from "utils/formotiv";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import { navigation } from "./utils/routes";
import { NavListItem } from "./styles";
import { useSelector } from "react-redux";
import { selectIsChannelPartnerOwner } from "redux/selectors/user";

interface PropTypes {
  isHidden: boolean;
}

const SidebarNav = ({ isHidden }: PropTypes) => {
  const { isIAUser, isAuthenticated, roles } = useAuth();

  const { showCommissions, showClaims, showMikesAppetiteChecker, apiVersion } = useFeatureFlags();
  const isChannelPartnerOwner = useSelector((state: ReduxState) => selectIsChannelPartnerOwner(state));

  if (!isAuthenticated || isHidden) {
    return <div />;
  }

  return (
    <NavListContainer>
      <NavList>
        {apiVersion === "v2" ? (
          <NewQuoteAnchor
            href={`${process.env.REACT_APP_NEW_DASHBOARD_URL}/quote`}
            data-cy="new-quote-link"
            onClick={() => formotivInstance.config && formotivInstance.submitFinal()}
          >
            + New Quote
          </NewQuoteAnchor>
        ) : (
          <NewQuoteNavLink
            onClick={() => formotivInstance.config && formotivInstance.submitFinal()}
            data-cy="new-quote-link"
            to="/simplybind/get-started"
          >
            + New Quote
          </NewQuoteNavLink>
        )}
        <>
          {navigation

            .filter((item) => showClaims || item.name !== "First Notice of Loss") // TODO remove when showClaims flag is deprecated
            .filter((item) => showCommissions || item.name !== "Commissions") // TODO remove when showCommissions flag is deprecated
            .filter((item) => isChannelPartnerOwner || item.name !== "Commissions")
            .filter((item) => showMikesAppetiteChecker || item.name !== "Appetite Checker")
            .filter((item) => {
              const notIAAndHasCorrectRole = !item.ia && (intersection(item.roles, roles).length > 0 || !item.roles);
              return isIAUser ? item.ia : notIAAndHasCorrectRole;
            })
            .map((item) =>
              item.custom ? (
                <item.custom key={item.id} />
              ) : (
                <NavListItem onClick={() => formotivInstance.config && formotivInstance.submitFinal()} key={item.id}>
                  <StyledNavLink
                    data-cy={item.dataCy}
                    to={item.to}
                    exact={item.exact}
                    isActive={(match, location) => {
                      if (item.to === "/my-account/account") {
                        return location.pathname.includes("/my-account/");
                      } else if (item.to === "/") {
                        return location.pathname === "/";
                      } else {
                        return location.pathname.includes(item.to);
                      }
                    }}
                  >
                    {item.name}
                  </StyledNavLink>
                </NavListItem>
              )
            )}
        </>
        {isCanadaEnv() && (
          <LogoContainer>
            <Image src={poweredByCoterieLogo} alt="powered By Coterie Logo" />
          </LogoContainer>
        )}
      </NavList>
    </NavListContainer>
  );
};

const NavListContainer = styled.div`
  display: flex;
  flex: none;
  background-color: ${(props) => props.theme.white};
  border-right: ${(props) => (isCanadaEnv() ? "none" : `1px solid ${props.theme.borderGrayLight}`)};
  min-width: 240px;
  z-index: 3;
  ${isCanadaEnv() &&
  css`
    box-shadow: -1px 3px 6px 1px rgb(0 0 0 / 20%);
    flex-direction: column;
  `}

  ${mobile} {
    min-width: 200px;
  }

  ${hideForPrint}
`;

const NavList = styled.ul`
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  padding: 0px;
  ${isCanadaEnv() &&
  css`
    flex: 1;
    padding-bottom: 45px;
  `}

  ${hideForPrint}
`;

const StyledNavLink = styled(NavLink)`
  ${NavLinkStyles}
  padding: 12px 20px;

  &.active {
    border-radius: 20px 0 0 20px;
    color: ${(props) => props.theme.blue};
    background-color: ${(props) => props.theme.lightestPurple};

    ${mobile} {
      border-radius: 0;
    }
  }
`;

const NewQuoteAnchor = styled.a`
  ${GreenButtonStyles}
  margin-bottom: 20px;
  margin-left: 30px;
  width: 162px;

  ${mobile} {
    margin-left: 15px;
  }
`;

const NewQuoteNavLink = styled(NavLink)`
  ${GreenButtonStyles}
  margin-bottom: 20px;
  margin-left: 30px;
  width: 162px;

  ${mobile} {
    margin-left: 15px;
  }
`;

const LogoContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 240px;
  ${mobile} {
    width: 200px;
  }
  background-color: ${(props) => props.theme.white};
  opacity: 0.9;
  padding-top: 10px;
`;

const Image = styled.img`
  padding-left: 30px;
  margin-left: 30px;
  ${mobile} {
    padding-left: 20px;
    margin-left: 0;
  }
  height: 40px;
  flex: none;
  margin-bottom: 22px;
`;

export default SidebarNav;
