import { Header, Icon, ModalActionBar } from "coterie-ui-library";
import styled from "styled-components";
import { StyledModalBody } from "./styles";

type PreviewType = {
  oldAccount: AccountType;
  newAccount: AccountType;
};

interface DetachPreviewProps {
  preview: PreviewType;
  onConfirm: () => void;
}

const DetachPreview = ({ preview, onConfirm }: DetachPreviewProps) => {
  return (
    <>
      <StyledModalBody>
        <Container>
          <Header as="h1">Does this look correct?</Header>
          <hr />
          <div>
            <Header as="h2">Old Account</Header>
            <ul>
              <li>
                <b>First Name</b>: {preview?.oldAccount?.firstName}
              </li>
              <li>
                <b>Last Name</b>: {preview?.oldAccount?.lastName}
              </li>
              <li>
                <b>Email</b>: {preview?.oldAccount?.email}
              </li>
            </ul>
          </div>
          <Row>
            <Icon name="arrow-down" />
          </Row>
          <div>
            <Header as="h2">New Account</Header>
            <ul>
              <li>
                <b>First Name</b>: {preview?.newAccount?.firstName}
              </li>
              <li>
                <b>Last Name</b>: {preview?.newAccount?.lastName}
              </li>
              <li>
                <b>Email</b>: {preview?.newAccount?.email}
              </li>
            </ul>
          </div>
        </Container>
      </StyledModalBody>
      <ModalActionBar handleCTAOnClick={onConfirm} ctaText="Confirm" />
    </>
  );
};

export default DetachPreview;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
  align-self: center;
`;
