import React from "react";
import { ErrorText } from "styles/Input";
import upload from "images/upload.svg";
import AjaxLoader from "shared/AjaxLoader";
import {
  ImageUploadArea,
  PreviewImage,
  FileInput,
  MessageLabelsWrapper,
  FileNameText,
  FileSizeText,
  LoadingContainer,
  UploadLogo,
  DragAndDropLabel,
  BrowseLabel,
  AcceptedLabel,
} from "./styles";
import { ImageStateType } from "./reducer";

type PropTypes = {
  imageState: ImageStateType;
  imageUploadAreaRef: React.RefObject<HTMLDivElement>;
  inputRef: React.MutableRefObject<HTMLInputElement>;
  handleImageUpload: (inputFile: HTMLInputElement) => void;
  isLoadingAgencyLogoPut: boolean;
  formatFileSize: (fileSize: number | null) => React.ReactNode;
};

type MessagePropTypes = {
  isLoadingAgencyLogoPut: boolean;
  formatFileSize: (fileSize: number | null) => React.ReactNode;
  fileName: string | null;
  fileSize: number | null;
};

const UploadAreaMessage = ({ isLoadingAgencyLogoPut, formatFileSize, fileName, fileSize }: MessagePropTypes) => (
  <MessageLabelsWrapper>
    {isLoadingAgencyLogoPut ? (
      <>
        <FileNameText>{fileName}</FileNameText>
        <FileSizeText>{formatFileSize(fileSize)}</FileSizeText>
        <LoadingContainer>
          <AjaxLoader />
        </LoadingContainer>
      </>
    ) : (
      <>
        <UploadLogo src={upload} alt="upload image" />
        <DragAndDropLabel>
          Drag and drop or <BrowseLabel>Browse files</BrowseLabel>
        </DragAndDropLabel>
        <AcceptedLabel>Accepted formats: .jpg, .png</AcceptedLabel>
        <AcceptedLabel>Maximum file size: 5 MB</AcceptedLabel>
      </>
    )}
  </MessageLabelsWrapper>
);

const UploadPreviewArea = ({
  imageState,
  imageUploadAreaRef,
  inputRef,
  handleImageUpload,
  isLoadingAgencyLogoPut,
  formatFileSize,
}: PropTypes) => (
  <>
    <ImageUploadArea
      errorMessage={imageState.errorMsg}
      hasImagePreview={Boolean(imageState.previewSrc)}
      ref={imageUploadAreaRef}
    >
      {Boolean(imageState.previewSrc) ? (
        <PreviewImage src={imageState.previewSrc || ""} alt="logo preview" />
      ) : (
        <>
          <FileInput
            ref={inputRef}
            multiple={false}
            type="file"
            id="file-upload"
            name="file-upload"
            accept="image/png, image/jpeg"
            data-cy="file-upload"
            data-testid="file-upload"
            onChange={() => handleImageUpload(inputRef.current)}
          />
          <UploadAreaMessage
            isLoadingAgencyLogoPut={isLoadingAgencyLogoPut}
            fileName={imageState.fileName}
            fileSize={imageState.fileSize}
            formatFileSize={formatFileSize}
          />
        </>
      )}
    </ImageUploadArea>
    {Boolean(imageState.errorMsg) ? <ErrorText>{imageState.errorMsg}</ErrorText> : null}
  </>
);

export default UploadPreviewArea;
