import React from "react";
import { Card, CardBody } from "reactstrap";
import Field from "./Field";
import styled from "styled-components";
import { Header4 } from "styles";
import { BLANKET_TEXT } from "./application/edit/Endorsements/AdditionalInsured/constants";
import useAuth from "hooks/useAuth";

interface PropTypes {
  endorsements: any;
}

const AdditionalInsuredsCard = ({ endorsements = {} }: PropTypes) => {
  const { isIAUser } = useAuth();
  const additionalInsureds = endorsements?.additionalInsureds ?? [];
  const showIndividual = Boolean(additionalInsureds.length);
  const showBlanket = "additionalInsuredsPrimaryAndNonContributory" in endorsements;

  return (
    <>
      {showIndividual && (
        <StyledCard data-cy="additional-insureds-view" data-testid="individual-ai">
          <Header4>Additional Insureds</Header4>

          <CardBody>
            {additionalInsureds.map((person: any, i: number) => (
              <AdditionalInsured key={`person-${i}`}>
                <Field value={person.name} label="Name" slug="AddInsuredName" />
                <Field value={person.email} label="Email Address" slug="AddInsuredEmail" />
                <Field
                  value={`${person.street} \n${person.city}, ${person.state} ${person.zip}`}
                  label="Location"
                  slug="AddInsuredAddress"
                />
              </AdditionalInsured>
            ))}
          </CardBody>
        </StyledCard>
      )}
      {showBlanket && (
        <StyledCard data-cy="additional-insureds-view" data-testid="blanket-ai">
          <Header4>Additional Insureds</Header4>

          <CardBody>
            {endorsements.additionalInsuredsPrimaryAndNonContributory === true && (
              <Field label="Blanket" value={BLANKET_TEXT.PRIMARY} />
            )}
            {endorsements.additionalInsuredsWaiverOfTransfer === true && (
              <Field label="Blanket" value={BLANKET_TEXT.SUBROGATION} />
            )}
            {"additionalInsuredsConstructionContractRequirementEndorsement" in endorsements && (
              <Field label="Blanket" value={BLANKET_TEXT.CONSTRUCTION} />
            )}
            {"additionalInsuredsManagersOrLessorsPremisesEndorsement" in endorsements && (
              <>
                <Field label="Blanket" value={BLANKET_TEXT.PREMISES} />
                {!isIAUser && (
                  <Field
                    label="Details"
                    value={endorsements.additionalInsuredsManagersOrLessorsPremisesEndorsement?.formText}
                  />
                )}
              </>
            )}
            {"additionalInsuredsLessorOfLeasedEquipmentEndorsement" in endorsements && (
              <>
                <Field label="Blanket" value={BLANKET_TEXT.EQUIPMENT} />
                {!isIAUser && (
                  <Field
                    label="Details"
                    value={endorsements.additionalInsuredsLessorOfLeasedEquipmentEndorsement?.formText}
                  />
                )}
              </>
            )}
          </CardBody>
        </StyledCard>
      )}
    </>
  );
};

const StyledCard = styled(Card)`
  margin-top: 24px;
`;

const AdditionalInsured = styled.div`
  margin-bottom: 20px;
`;

export default AdditionalInsuredsCard;
