import React, { useState } from "react";
import styled from "styled-components";
import { ContentContainer, Title, TitleContainer } from "styles/containerStyledComponents";
import { ReactComponent as BackArrow } from "images/back-arrow.svg";
import { useParams, useLocation } from "react-router";
import useGetPolicy from "hooks/useGetPolicy";
import AjaxLoader from "shared/AjaxLoader";
import ToolTipIcon from "shared/ToolTipIcon";
import { Alert, CTAButton, SecondaryButton } from "coterie-ui-library";
import CancellationReasons from "./CancellationReasons";
import CancellationDate from "./CancellationDate";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CancelDocuments from "./CancelDocuments";
import { CancellationForm, CancelRequest } from "./types";
import { cancelRequestSchema } from "./schema";
import CancelPreviewModal from "./CancelPreviewModal";
import { usePostCreateCancelRequest } from "hooks/usePostCreateCancelRequest";
import { reportError } from "utils/reportError";

const CancelPolicy = () => {
  const { policyNumber } = useParams<{ policyNumber: string }>();
  const { data, isLoading, isError, error } = useGetPolicy([policyNumber]);
  const { mutateAsync, isPending, isSuccess, isError: cancelRequestError } = usePostCreateCancelRequest(policyNumber);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const referrer = params.get("referrer");

  const [open, setOpen] = useState<boolean>(false);

  const methods = useForm<CancellationForm>({
    resolver: yupResolver(cancelRequestSchema),
    defaultValues: {
      reason: null,
      files: [],
    },
  });

  const {
    trigger,
    getValues,
    formState: { isValid },
  } = methods;

  const form = getValues();

  const { cancelDate, reason, files, additionalInformation, isNonRenew } = form;

  if (isLoading) return <AjaxLoader />;
  if (isError)
    return (
      <Alert
        message={error?.message || "There was an error while fetching the policy information. Please try again."}
        color="red"
        title="Server Error"
      />
    );

  const policy = data && (data[0] as Policy);

  const openPreviewModal = () => {
    // Manually trigger validation before opening the preview modal
    trigger();
    if (isValid) {
      // Open the preview modal
      setOpen(true);
    }
  };

  const getCancelFiles = (file: File): Promise<CancelRequest["files"][number]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onerror = () => reject(reader.error);
      reader.onload = () => {
        resolve({
          fileName: file.name,
          base64File: reader.result?.toString().replace(/^data:.+;base64,/, "") as string,
        });
      };
    });
  };

  const cancelRequest = async () => {
    // Submit cancellation request
    const request = {
      cancellationTypeCode: reason?.value,
      isNonRenew: isNonRenew === "false" ? false : true,
      cancelDate: isNonRenew === "false" ? (cancelDate as Date).toISOString() : undefined,
      additionalInformation,
      files: await Promise.all(
        files.map(async ({ file }) => {
          return await getCancelFiles(file);
        })
      ),
    } as CancelRequest;

    await mutateAsync(request)
      .then(() => {
        setOpen(false);
      })
      .catch((error) => reportError(error));
  };

  if (!policy) return null;

  return (
    <PageWrapper>
      <CancelPolicyContainer>
        <CancelHeader>
          <a href={referrer || `/policy?policyIds=${policyNumber}`}>
            <BackArrow />
            Return to Policy Information
          </a>
        </CancelHeader>
        <CancelPolicySection noBorder={isSuccess}>
          <CancelTitleContainer>
            <Title>Policy Cancellation Request</Title>
            <ToolTipIcon content="As the agent, you may submit the insured’s written cancellation request below on their behalf. " />
          </CancelTitleContainer>
          <PolicyLead>
            Complete your cancellation request by submitting the insured’s written request and all necessary
            information. Please be advised that the policy will remain in effect until the appropriate documentation is
            received and reviewed. We will reach out to you if any follow-up is needed.
          </PolicyLead>
          <PolicyNumber>
            <span>Policy number:</span> {policy.policyNumber}
          </PolicyNumber>
        </CancelPolicySection>
        {isSuccess && (
          <CancelRequestConfirmation>
            <h3>Request sent!</h3>
            <p>
              Your request has been submitted to Coterie’s support team. We will review your request and reach out to
              you with any questions if necessary. You will receive an email once your request has been completed.
            </p>
            <div>
              <a href={referrer || `/policy?policyIds=${policyNumber}`} aria-label="Back to Policy Page link">
                <SecondaryButton>Back to Policy Page</SecondaryButton>
              </a>
            </div>
          </CancelRequestConfirmation>
        )}
        {!isSuccess && (
          <FormProvider {...methods}>
            <CancelPolicySection>
              <CancellationReasons />
            </CancelPolicySection>
            <CancelPolicySection>
              <CancellationDate policy={policy} />
            </CancelPolicySection>
            <CancelPolicySection noBorder>
              <CancelDocuments />
            </CancelPolicySection>
          </FormProvider>
        )}
      </CancelPolicyContainer>
      {!isSuccess && (
        <CancelFooter>
          <PreviewButton onClick={openPreviewModal}>Preview cancel</PreviewButton>
          {open && (
            <CancelPreviewModal
              open={open}
              openModal={setOpen}
              cancelDate={(cancelDate as Date)?.toISOString()}
              isPending={isPending}
              cancelRequestError={cancelRequestError}
              cancelRequest={cancelRequest}
            />
          )}
        </CancelFooter>
      )}
    </PageWrapper>
  );
};

const CancelPolicyContainer = styled(ContentContainer)`
  padding: 0 40px;
  max-width: 1000px;
  height: 100%;
  min-height: calc(100vh - 80px);
`;

const CancelRequestConfirmation = styled.div`
  margin-top: 12px;
  h3 {
    color: ${({ theme }) => theme.colors.tertiary.zero};
    font-family: ${({ theme }) => theme.font.typeface.primary};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  p {
    color: ${({ theme }) => theme.colors.neutral.dark};
    font-family: ${({ theme }) => theme.font.typeface.primary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 8px 0 24px 0;
  }

  button {
    max-width: fit-content;
    min-width: 143px;
  }

  a {
    display: inline-flex;
  }
`;

const PageWrapper = styled.div`
  position: relative;
`;

const CancelHeader = styled.div`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.neutral.special};
  padding: 32px 0;

  a {
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    gap: 16px;
    color: ${({ theme }) => theme.blueSavingsTextColor};
    font-family: ${({ theme: { font } }) => font.typeface.primary};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
  }
`;

const CancelFooter = styled.div`
  background: ${({ theme: { colors } }) => colors.neutral.special};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  position: sticky;
  bottom: 0;
  z-index: 99;
`;

const PreviewButton = styled(CTAButton)`
  min-width: 195px;
`;

const CancelPolicySection = styled.section<{ noBorder?: boolean }>`
  border-bottom: ${({ noBorder, theme: { colors } }) => (!noBorder ? `1px solid ${colors.neutral.special}` : `none`)};
  padding: 32px 0 24px 0;
`;

const CancelTitleContainer = styled(TitleContainer)`
  h1 {
    flex: none;
  }
`;

const PolicyNumber = styled.p`
  color: ${({ theme }) => theme.colors.neutral.black};
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 16px 0 0 0;
  span {
    font-weight: 600;
  }
`;

const PolicyLead = styled.p`
  color: ${({ theme }) => theme.colors.neutral.dark};
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export default CancelPolicy;
