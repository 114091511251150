import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import styled from "styled-components";
import { faDownload, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import useAuth from "hooks/useAuth";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface PropTypes {
  failedTransactions: FailedTransaction[];
  isLoading?: boolean;
  onRefresh?: () => void;
}

const FailedTransactionsReportTable = ({ failedTransactions, isLoading, onRefresh }: PropTypes) => {
  const { isIAUser } = useAuth();
  const getFilename = (): string => {
    return `Coterie_FailedTransactionsReport_${new Date().toLocaleDateString()}`;
  };

  const mapTransactions = (): any[] => {
    return failedTransactions.map((f) => {
      return {
        policyNumber: f.policyNumber,
        accountId: f.accountId,
        declineReason: f.declineReason,
        name: f.name,
        phone: f.phone,
        email: f.email,
        daysPastDue: f.daysPastDue,
      };
    });
  };

  const csvHeaders = [
    { label: "Policy", key: "policyNumber" },
    { label: "Account ID", key: "accountId" },
    { label: "Decline Reason", key: "declineReason" },
    { label: "Name", key: "name" },
    { label: "Phone", key: "phone" },
    { label: "Email", key: "email" },
    { label: "Days Past Due", key: "daysPastDue" },
  ].filter((csvHeader) => {
    return isIAUser && csvHeader.key === "accountId" ? undefined : csvHeader;
  });

  const columns = [
    {
      Header: "Policy",
      accessor: "policyNumber",
      width: 170,
      Cell: (props: any) => (
        <Link to={isIAUser ? `/policy?policyIds=${props.value}` : `/policies/${props.value}`}>{props.value}</Link>
      ),
    },
    {
      Header: "Account ID",
      accessor: "accountId",
      width: 170,
      Cell: (props: any) => <Link to={`/accounts/${props.value}`}>{props.value}</Link>,
    },
    {
      Header: "Decline Reason",
      accessor: "declineReason",
      width: 170,
    },
    {
      Header: "Name",
      accessor: "name",
      width: 170,
    },
    {
      Header: "Phone",
      accessor: "phone",
      width: 120,
    },
    {
      Header: "Email",
      accessor: "email",
      width: 200,
    },
    {
      Header: "Days Past Due",
      id: "daysPastDue",
      width: 170,
      style: { textAlign: "center" },
      accessor: (x: FailedTransaction) => `${x.daysPastDue.toFixed(0)}`,
    },
  ];

  return (
    <Container>
      <ReactTable
        data={failedTransactions}
        columns={columns.filter((column) => {
          return isIAUser && column.accessor === "accountId" ? undefined : column;
        })}
        defaultSorted={[{ id: "daysPastDue", desc: true }]}
        noDataText={isLoading ? "Loading..." : "No transactions found"}
      />
      <Actions>
        {onRefresh && (
          <button onClick={onRefresh}>
            <FontAwesomeIcon icon={faSync as IconProp} style={{ marginRight: "0.5em" }} />
            Refresh
          </button>
        )}
        {(failedTransactions || []).length > 1 ? (
          <CSVLink data={mapTransactions()} headers={csvHeaders} filename={getFilename()}>
            <FontAwesomeIcon icon={faDownload as IconProp} style={{ marginRight: "0.3em" }} /> Download CSV
          </CSVLink>
        ) : (
          <span />
        )}
      </Actions>
    </Container>
  );
};

const Actions = styled.div`
  margin: 1em 0;
  text-align: right;
  a,
  .btn-link,
  svg {
    margin-left: 0.5em;
  }
`;

const Container = styled.div`
  margin-top: 50px;
`;

export default FailedTransactionsReportTable;
