import { useQuery } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { portalApi } from "./useAPI/instances";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

type Options = { enabled: boolean } | {};

const useGetPolicy = (policyNumbers: string[] = [], options: Options = {}) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const getPolicyQuery = async () => {
    const token = await getToken();
    const api = portalApi(apiVersion, token);

    const getPolicyRequest = (policyNumber: string) => {
      return api.get(`/policies/${policyNumber}`);
    };
    if (policyNumbers) {
      const allGetPolicyRequests = policyNumbers.map((p) => getPolicyRequest(p));
      return Promise.all(allGetPolicyRequests);
    } else {
      return Promise.resolve([]);
    }
  };

  return useQuery({
    queryKey: ["policies", policyNumbers.join(",")],
    queryFn: getPolicyQuery,
    select: (response: any[]) => response.map((data: any) => data.data),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
};

export default useGetPolicy;
