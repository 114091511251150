import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Header4, tablet } from "styles";
import styled from "styled-components";
import Api from "utils/api";
// import SearchIcon from "images/search_icon.svg";
import AddAgencyModal from "./AddAgencyModal";
import EditAgenciesModal from "./EditAgenciesModal";
import { toastSuccess } from "utils/toast";
import AjaxLoader from "shared/AjaxLoader";
import Field from "shared/Field";
import { reportGetAgencyError } from "utils/reportError";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  channelPartnerId: number;
}

const AgenciesTable = ({ channelPartnerId }: PropTypes) => {
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [agencies, setAgencies] = useState<AgencyType[]>([]);
  const { getToken } = useAuth();

  const { apiVersion } = useFeatureFlags();

  useEffect(() => {
    loadAgencies();
  }, []);

  const loadAgencies = async () => {
    setIsLoading(true);

    const token = await getToken();
    const api = new Api(apiVersion, token);

    api.agencies
      .getByChannelPartner(channelPartnerId)
      .then((agencies) => setAgencies(agencies))
      .catch((error) => reportGetAgencyError(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saveChanges = (name: string, email: string) => {
    setName(name);
    setEmail(email);
  };

  const addAgency = async (name: string, email: string, npn: string) => {
    setIsAdding(true);

    const token = await getToken();
    const api = new Api(apiVersion, token);

    api
      .createAccountDashboard({
        accountType: 1,
        channelPartnerId: channelPartnerId,
        email: email,
        nationalProducerNumber: Number(npn),
        name: name,
      })
      .then((response) => {
        if (response) {
          toastSuccess("Agency added");
          setAgencies([...(agencies ?? []), { name: name, owner: { id: "", email: email } }]);
          setShowAddModal(false);
        }
      })
      .catch((error) => reportGetAgencyError(error))
      .finally(() => {
        setIsAdding(false);
      });
  };

  const agencyList = agencies.map((agency, i) => (
    <Agency key={agency.owner?.email || i}>
      <Field value={agency.name} label="Name" aria-label="Name" />
      <Field value={agency.owner?.email} label="Email" aria-label="Agent Email" />
    </Agency>
  ));

  return (
    <Container>
      <Title>Agencies</Title>
      {/* TODO Uncomment "Add Agency" button once sign up issues are figured out */}
      {/* <GreenButton onClick={() => setShowAddModal(true)} data-cy="add-agency-button">
        + Add agency
      </GreenButton> */}
      {/* TODO search to be implemented later */}
      {/* <SearchImage src={SearchIcon} alt="magnifying glass search icon" /> */}
      <LoadingDiv>{isLoading ? <AjaxLoader /> : <AgencyContainer>{agencyList}</AgencyContainer>}</LoadingDiv>
      <AddAgencyModal
        isLoading={isAdding}
        closeModal={() => setShowAddModal(false)}
        modalIsOpen={showAddModal}
        save={addAgency}
        key={agencies.length}
      />
      <EditAgenciesModal
        isLoading={false}
        closeModal={() => setShowEditModal(false)}
        modalIsOpen={showEditModal}
        name={name}
        email={email}
        save={saveChanges}
      />
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  channelPartnerId: state.user.channelPartner.id,
});

const Container = styled.div`
  max-width: 1121px;
  width: 100%;
`;

const Title = styled(Header4)`
  padding: 16px 0;
  margin-bottom: 0px;
  border-top: 1px solid ${(props) => props.theme.lighterGray};
`;

// const SearchImage = styled.img`
//   height: 12px;
//   margin-top: 10px;
//   margin-left: 12px;
// `;

const AgencyContainer = styled.div`
  padding-top: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 98.5px;
  grid-row-gap: 16px;

  ${tablet} {
    display: flex;
    flex-direction: column;
  }
`;

const Agency = styled.div`
  max-width: 511px;
  width: 100%;
`;

const LoadingDiv = styled.div`
  width: 100%;
  margin-top: 16px;
  text-align: center;
`;

export default connect(mapStateToProps)(AgenciesTable);
