import React from "react";
import styled from "styled-components";
import Checkmark from "images/checkmark_shield_orange.svg";

const PendingAffiliateBanner = () => {
  const text = "affiliation pending approval";
  return (
    <Banner data-cy="pending-affiliation-banner">
      <LogoImg src={Checkmark} alt="checkmark" />
      <P>{text}</P>
    </Banner>
  );
};

const Banner = styled.p`
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.banner};
  height: 3em;
  margin-bottom: 0;
`;

const P = styled.p`
  font-weight: 600;
  font-family: ${(props) => props.theme.primaryFont};
  text-transform: uppercase;
  color: ${(props) => props.theme.charcoalGrey};
  letter-spacing: 1px;
  line-height: 3em;
`;

const LogoImg = styled.img`
  margin-right: 8px;
`;

export default PendingAffiliateBanner;
