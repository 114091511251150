import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { History } from "history";
import { RadioButton, GreenButton, TextLink, laptop } from "styles";
import { Container, CTAButtonContainer } from "../styles";
import { useSelector } from "react-redux";
import { selectUnderwritingProfileIndustrySuggestions } from "redux/selectors/underwritingProfile";

interface PropTypes {
  savedIndustry: IndustrySuggestion | {};
  callback: (payload: IndustrySuggestion) => void;
  history: History;
}

const IndustryConfirmation = ({ savedIndustry, callback, history }: PropTypes) => {
  const defaultSelectedIndustry = { naic: 0, description: "", simpleCoterieHashId: "", coterieClassificationId: "" };
  const [selectedIndustry, setSelectedIndustry] = useState<IndustrySuggestion>(defaultSelectedIndustry);
  const industrySuggestions = useSelector((state: ReduxState) => selectUnderwritingProfileIndustrySuggestions(state));

  useEffect(() => {
    if ("simpleCoterieHashId" in savedIndustry) {
      setSelectedIndustry(savedIndustry);
    }
  }, [savedIndustry]);

  const handleSelectIndustry = (e: React.FormEvent<HTMLInputElement>) => {
    const selectedCoterieHashId = e.currentTarget.value;
    const industry = industrySuggestions?.find(
      (e: IndustrySuggestion) => String(e.simpleCoterieHashId) === selectedCoterieHashId
    );

    setSelectedIndustry(industry ?? defaultSelectedIndustry);
  };

  const handleNextClick = () => callback(selectedIndustry);

  const disabledNextButton = Boolean(!selectedIndustry.simpleCoterieHashId);

  return (
    <Container>
      <RadioButtonGroup>
        <>
          {industrySuggestions?.map((industry) => (
            <RadioButton
              key={industry.simpleCoterieHashId}
              id={`industry-radio-button-${industry.naic}`}
              groupName="industry"
              label={`${industry.description}\n(NAICS code: ${industry.naic})`}
              value={industry.simpleCoterieHashId}
              checked={selectedIndustry.simpleCoterieHashId === industry.simpleCoterieHashId}
              required={true}
              handleClick={handleSelectIndustry}
              inputStyles={RadioInputStyles}
              labelStyles={RadioLabelStyles}
            />
          ))}
          <ManualEntryHeaderText>Are these not accurate?</ManualEntryHeaderText>
          <StyledTextLink onClick={() => history.push("/simplybind/new-industry")} data-cy="edit-text-link">
            Enter a different primary industry.
          </StyledTextLink>
        </>
      </RadioButtonGroup>
      <CTAButtonContainer>
        <GreenButton onClick={handleNextClick} disabled={disabledNextButton}>
          Next
        </GreenButton>
      </CTAButtonContainer>
    </Container>
  );
};

const RadioButtonGroup = styled.div`
  margin-bottom: 48px;
  max-width: 70%;
  input {
    margin-right: 16px;
  }
`;

const RadioInputStyles = css`
  width: 16px;
  height: 16px;
  border: 1px solid ${(props) => props.theme.blue};
  &:checked {
    width: 16px;
    height: 16px;
  }
  ${laptop} {
    margin-bottom: 20px;
  }
`;

const RadioLabelStyles = css`
  white-space: pre-line;
  max-width: 90%;
`;

const ManualEntryHeaderText = styled.div`
  margin-top: 80px;
  margin-bottom: 0;
`;

const StyledTextLink = styled(TextLink)`
  padding: 0;
`;

export default IndustryConfirmation;
