import React from "react";
import { Text, View, Rect, StyleSheet } from "@react-pdf/renderer";
import { Svg } from "utils/proposal-pdf/SvgPatch";
import { theme } from "styles";

interface HolderDetailsRowPropTypes {
  title: string;
  info?: string;
  secondInfo?: string;
  infoSecondLine?: string;
  showBottomLine?: boolean;
}

const HolderDetailsRow = ({
  title,
  info,
  secondInfo,
  infoSecondLine,
  showBottomLine = true,
}: HolderDetailsRowPropTypes) => (
  <View>
    <View style={styles.infoContainer}>
      <View style={styles.labelWidth}>
        <Text style={styles.detailsText}>{title}</Text>
      </View>
      {!secondInfo ? (
        <View style={styles.valueWidth}>
          <Text style={styles.detailsText}>{info}</Text>
          {infoSecondLine && <Text style={styles.detailsText}>{infoSecondLine}</Text>}
        </View>
      ) : (
        <View style={[styles.valueWidth, { flexDirection: "row", position: "relative" }]}>
          <Text style={styles.detailsText}>{"From: " + info}</Text>
          <Text style={[styles.detailsText, { marginLeft: 46 }]}>{"To: " + secondInfo}</Text>
        </View>
      )}
    </View>
    {showBottomLine && (
      <Svg style={styles.line}>
        <Rect x="0" y="0" width="1000px" height="1px" fill={theme.colors.neutral.light} />
      </Svg>
    )}
  </View>
);

const styles = StyleSheet.create({
  infoContainer: {
    flexDirection: "row",
    marginBottom: "8px",
    marginTop: "4px",
  },
  detailsText: {
    fontSize: "11px",
    fontFamily: theme.font.typeface.secondary,
  },
  labelWidth: {
    width: "25%",
  },
  valueWidth: {
    width: "75%",
  },
  line: {
    height: "1px",
  },
});

export default HolderDetailsRow;
