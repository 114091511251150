import React from "react";
import styled from "styled-components";

interface IDictionary<TValue> {
  [id: string]: TValue;
}

export const Text = styled.p<{ weight?: string }>`
  color: ${(props) => props.theme.charcoalGrey};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 12px;
  margin: 10px 10px;
  line-height: 1.36;
  font-weight: ${(props) => (props.weight === "bold" ? "bold" : "normal")};
`;

const ExceptionsContainer = styled.div`
  margin-top: 15px;
`;

const toolTipContent: IDictionary<JSX.Element> = {
  LIQUOR_LIABILITY: (
    <Text>
      Liquor Liability covers businesses that manufacture, sell, and/or serve alcohol. This coverage provides protection
      for lawsuits or settlements associated with the bodily injury or property damage of others.
    </Text>
  ),
  COMPUTER_FRAUD: (
    <Text>
      Computer Fraud coverage protects against the direct loss of money, securities and other property that is
      fraudulently transferred from the use of a computer system.
    </Text>
  ),
  EPLI: (
    <Text>
      EPLI provides coverage against claims made by employees for discrimination, wrongful termination, harassment, and
      other employment-related issues.
    </Text>
  ),
  IDENTITY_FRAUD: <Text>Identity Fraud coverage provides reimbursement compensation related to identity theft.</Text>,
  DISCRETIONARY_PAYROLL: (
    <Text>Discretionary Payroll Coverage provides money to pay for payroll for select employees for 120 days.</Text>
  ),
  ELECTRONIC_DATA_LIABILITY: (
    <Text>
      Electronic Data Liability provides coverage for the loss of, damage to, or inability to access or use
      electronically stored data.
    </Text>
  ),
  FINE_ARTS: (
    <Text>
      Fine Arts coverage provides protection for paintings, statues, rugs, tapestries, and other artwork used to
      decorate your business.
    </Text>
  ),
  SNOW_PLOW: (
    <Text>
      This coverage expands general liability coverage to include businesses that perform snow plow operations. Your
      business will be covered for bodily injury or property damage to others as a result of snow plow operations.
    </Text>
  ),
  ORDINANCE_LAW: (
    <Text>
      Ordinance or Law Coverage provides coverage to your business for extra rebuilding expenses incurred due to
      building codes.
    </Text>
  ),
  DEBRIS_REMOVAL: (
    <Text>Debris Removal coverage provides extra coverage for clean-up costs if your property is damaged.</Text>
  ),
  DISASTER_INCREASE: (
    <Text>Disaster increase coverage pays for extra rebuilding costs as a result of a natural disaster.</Text>
  ),
  EMPLOYEE_BENEFITS_LIABILITY: (
    <Text>
      Employee benefits liability pays for errors or omissions committed by an employer when administering the employee
      benefits program.
    </Text>
  ),
  GREEN_UPGRADES: (
    <Text>
      Green Upgrades Coverage provides additional coverage for replacing or rebuilding with more "energy efficient"
      materials.
    </Text>
  ),
  WATER_BACKUP: (
    <Text>
      Water Backup coverage provides protection against losses resulting from a backup of drains, sewers, or sump pump
      failure.
    </Text>
  ),
  GL_DEDUCTIBLE: <Text>This deductible applies to claims for property damage only.</Text>,
  ACCOUNTS_RECEIVABLE: (
    <Text>
      This coverage insures against the loss of sums owed by the business customers that are unable to be collected
      because of loss of data or damage to accounts receivable records. This limit is split for records that are kept on
      premises and records that are kept off premises.
    </Text>
  ),
  PROPERTY_IN_STORAGE: (
    <Text>
      This coverage applies to your business equipment that is temporarily stored in portable storage units. The
      coverage insures property that has been stored for less than 90 days (not more than), and the storage unit itself
      cannot have been installed and in use at the described premises for more than 90 days.
    </Text>
  ),
  FD_SERVICE_CHARGE: (
    <Text>
      This coverage applies to charges incurred from requiring fire department services to save or protect Covered
      Property from a Covered Cause of Loss at each premises.
    </Text>
  ),
  CONSTRUCTION_COST: (
    <Text>
      This coverage applies to enforcement of ordinances and laws regulating construction or repair of damaged
      buildings. Older buildings can sometimes require updating during reconstruction or repair after a covered loss.
      Increased costs incurred from ordinances or laws are covered up to the described limit.
    </Text>
  ),
  OUTDOOR_PROPERTY: (
    <Text>
      This coverage applies to outdoor fences, antennas, signs, trees, shrubs, and plants that are damaged by fire,
      lightning, explosion, riot or civil commotion, or aircraft. Coverage is limited to $2,500 total but no more than
      $1,000 for any one shrub or plant.
    </Text>
  ),
  PRESERVATION_OF_PROPERTY: (
    <Text>
      This limit applies to Covered Property being moved from the insured location and being temporarily stored at
      another location as a result of a covered loss in order to preserve it. Coverage only applies if loss or damage
      occurs within 30 days after the property is first moved.
    </Text>
  ),
  VALUABLE_PAPERS: (
    <Text>
      This coverage applies to Valuable Papers and Records that are stored at the insured premises and the cost to
      research, replace, or restore lost information after direct physical loss or damage from a covered cause of loss.
      For Valuable Papers and Records stored off premises a separate limit of $5,000 applies.
    </Text>
  ),
  BUSINESS_INCOME_EXTRA_EXPENSE: (
    <Text>
      This coverage applies to loss of income due to the necessary suspension of operations as a result of direct
      physical damage to property at the described premises that is caused by a Covered Cause of Loss. Ordinary payroll
      expenses are covered for only 60 days from the date of loss. Extra Expense are those expenses which help avoid or
      minimize the suspension of business and to continue operations.
    </Text>
  ),
  BUSINESS_INCOME_DEPENDENT_PROPERTIES: (
    <Text>
      This coverage applies to secondary dependent properties that sustain physical loss due to a Covered Cause of Loss.
      Business income from the dependent property is covered up to the described limit.
    </Text>
  ),
  CIVIL_AUTHORITY: (
    <Text>
      This coverage applies to a loss due to the action of a civil authority that prohibits access to the described
      premises, including reimbursement for loss of Business Income and Extra Expense.
    </Text>
  ),
  ELECTRONIC_DATA: (
    <Text>
      This coverage pays for the cost to replace or restore “electronic data” which has been destroyed or corrupted by a
      Covered Cause of Loss such as computer virus, harmful code, or similar instruction entered into your computer
      network.
    </Text>
  ),
  INTERRUPTION_OF_COMPUTER_OPERATIONS: (
    <Text>
      This coverage pays for Business Income and Extra Expenses incurred as a result of a computer virus, harmful code,
      or similar instruction entered into your computer network.
    </Text>
  ),
  FIRE_EXTINGUISHER_RECHARGE: (
    <Text>
      This coverage pays for the replacing or recharging of your fire extinguishers and fire extinguishing systems if
      they are discharged on or within 100 feet of the described premises.
    </Text>
  ),
  FORGERY: (
    <Text>
      This coverage pays for a loss resulting from the forgery or alteration of any check, draft, promissory note, bill
      of exchange, or similar written promise of money.
    </Text>
  ),
  MONEY_AND_SECURITIES: (
    <Text>
      This coverage pays for the loss of money and securities used in your business as a result of theft, disappearance,
      or destruction. This coverage does not pay for mathematical or accounting errors.
    </Text>
  ),
  MONEY_ORDERS_COUNTERFEIT: (
    <Text>
      This coverage pays for a loss due to counterfeit currency or the acceptance in good faith of a money order that is
      not paid upon presentation in exchange for merchandise, money or services.
    </Text>
  ),
  NEW_PROPERTY: (
    <Text>
      This coverage protects against disasters that happen to buildings under construction, or newly acquired property
      that hasn't been added to the insured’s policy yet. Coverage is provided for 30 days from the date they are
      acquired, or construction has begun.
    </Text>
  ),
  PERSONAL_EFFECTS: (
    <Text>
      This coverage pays for loss to personal effects owned by the insured, their officers, their partners or members,
      their managers, and their employees, including temporary and leased employees. This coverage does not pay for
      tools or equipment used in the business or for loss due to theft.
    </Text>
  ),
  PERSONAL_PROPERTY_OFF_PREMISES: (
    <Text>
      This coverage pays for loss to covered property off premises while it is in transit, or at a premise that is not
      owned, leased, or operated by the insured. Money, securities, valuable paper and records and accounts receivable
      are not covered under this coverage.
    </Text>
  ),
  AGENCY_LINK: (
    <Text>
      You can copy the link provided and add it to your website’s call to actions, email signature or PDF. The user will
      be directed to a quote that will be attributed to your agency.
    </Text>
  ),
  SELECT_INDUSTRY: <Text>What best describes the business' primary operations?</Text>,
  INDUSTRY_READONLY: <Text>If you would like to change the industry, please start a new quote.</Text>,
  ANNUAL_PAYROLL: (
    <Text>
      Payroll means remuneration, in money for services rendered by employees, excluding payroll of clerical employees,
      draftsmen, outside salespersons, collectors or messengers, and drivers or their helpers. All W2 Employees should
      be included. Independent contractors and subcontractors should be excluded.
    </Text>
  ),
  ANNUAL_SALES: (
    <Text>
      The gross amount charged by the insured for all goods sold or distributed, or for any operations performed.
    </Text>
  ),
  YEARS_OF_PRIOR_ACTS: (
    <Text>
      Policy will cover claims during the policy period arising out of events occurring number of years selected that
      precede the policy period.
    </Text>
  ),
  EXTENDED_REPORTING_PERIOD: (
    <Text>
      A designated time period after a claims-made policy has expired during which a claim may be made and coverage
      triggered as if the claim had been made during the policy period.
    </Text>
  ),
  YEARS_OF_PROFESSIONAL_EXPERIENCE: <Text>Number of years the business owner has been in the current profession.</Text>,
  BUSINESS_ADDRESS: (
    <Text>
      Location address of the business to be covered. At this time, Coterie only supports one location per policy.
    </Text>
  ),
  BUSINESS_ADDRESS_READ_ONLY: <Text>If you would like to change the business address, please start a new quote.</Text>,
  BUSINESS_PERSONAL_PROPERTY_LIMIT: (
    <Text>Property you own or is leased and is in your care, custody and control that is used in your business.</Text>
  ),
  ADDITIONAL_INSUREDS_PRIMARY_AND_NON_CONTRIBUTORY: (
    <Text>
      BP1488: Amends the "Other Insurance" provision of the policy to render the Additional Insured coverage provided
      under the policy as primary, and non-contributory to other collectible insurance.
    </Text>
  ),
  ADDITIONAL_INSUREDS_WAIVER_OF_TRANSFER: (
    <Text>
      BP0497: Customers who do business with other businesses may enter contracts which request that their insurance
      carrier waive its right to recover covered losses from that company, even if they are responsible. Adding this
      form means Coterie and its carriers waive the right to pursue recovery from those with whom they have agreed to do
      so in a written contract.
    </Text>
  ),
  ADDITIONAL_INSUREDS_MANAGERS_OR_LESSORS_OF_PREMISES_ENDORSEMENT: (
    <Text>
      BP0402: If your customer is a tenant, this form adds the premise owner, landlord, or manager as an insured on your
      policy for liability caused in whole or in part by your actions.
    </Text>
  ),
  ADDITIONAL_INSUREDS_LESSOR_OF_LEASED_EQUIPMENT_ENDORSEMENT: (
    <Text>
      BP0416: If your customer rents equipment on a short or long-term basis, this form additionally insures that
      equipment leasing company on the Liability policy, where required by written contract.
    </Text>
  ),
  ADDITIONAL_INSUREDS_CONSTRUCTION_CONTRACT_REQUIREMENT_ENDORSEMENT: (
    <Text>
      BP1487: This endorsement is designed for businesses who perform work for, or services to others, and are required
      to protect an entity for losses. Coverage is afforded when required in a written contract for losses which are
      caused in whole or in part by their actions for their ongoing operations.
    </Text>
  ),
  INDIVIDUAL_ADDITIONAL_INSURED: (
    <Text>
      We recommend the Blanket Additional Insured Bundle. But, if your customer has a known entity requiring a
      designated Additional Insured endorsement, BP0448 is available for scheduled organizations or persons. This AI
      coverage applies to ongoing operations and premises liability only.
    </Text>
  ),
  HIRED_AUTO_AND_NON_OWNED_AUTO: (
    <Text>Add liability coverage for any auto that is not owned, leased, hired or borrowed by the insured.</Text>
  ),
  CONTRACTORS_TOOLS_ENDORSEMENT: (
    <Text>
      Add coverage for contractors' tools and equipment owned by, or in the care, custody or control of, the insured as
      business personal property. A sublimit of $2,000 per tool applies.
    </Text>
  ),
  EMAIL_ADDRESS: (
    <Text>
      Please be sure to input the correct email address of the insured as this is how they will access the Billing
      Center to update payment methods.
    </Text>
  ),
  DAMAGE_TO_PREMISES_RENTED_TO_YOU: (
    <Text>
      Coverage for premises rented on a short-term basis up to seven days. When adding your higher limit amount, include
      your $50,000 in the total coverage.
    </Text>
  ),
  // TODO remove once logo upload for edit channel partner design tab is using portal API
  TEMP_LOGO_UPLOAD: (
    <Text>
      This feature is currently using the Imgur API for proof of concept and testing purposes and must NOT be used in
      production. Additional work is required to use the Portal API once that service is ready. For any questions,
      please ask Jon Prell or Ben Schaefer.
    </Text>
  ),
  BUSINESS_NAME_SIMPLYBIND: (
    <Text>
      The legal entity is who the policy covers for its operations and property. Examples include a corporation, LLC,
      LLP, sole proprietorship, etc.
      <br />
      <br />
      This will print as the Named Insured on the policy.
    </Text>
  ),
  BUSINESS_ADDRESS_SIMPLYBIND: (
    <Text>
      Enter the location where business operations occur, especially if Property coverage is needed. You can specify a
      separate mailing address later. <br />
      <br /> If your client has multiple locations with property or operations, you will need to create another quote
      for a separate policy per location. Allocate the sales, payroll, and property values emanating from each location,
      for each policy needed.
    </Text>
  ),
  BIND_AS_DIFF_USER: <Text>Select the designated responsible producer</Text>,
  DOING_BUSINESS_AS_NAME_AND_ADDRESS_SIMPLYBIND: (
    <Text>
      Use the “Doing Business As” field for an operating or trade name. Entering a DBA name can assist in our data
      gathering for this business to build your quote.
    </Text>
  ),
  DOING_BUSINESS_AS_SIMPLYBIND: (
    <Text>
      Use the “Doing Business As” field for an operating or trade name. Entering the DBA name is optional. This will
      print on the policy as the DBA if filled out.
    </Text>
  ),
  SIMPLYBIND_GET_STARTED_SUBHEADER: (
    <Text>
      The SimplyBind experience lets you quote in minutes! Enter the business name and address and we’ll use public data
      sources to pull risk and exposure information. All you need to do is validate the finished quote and bind.
    </Text>
  ),
  POLICY_EFFECTIVE_DATE_DISCLAIMER: (
    <Text>
      Policy effective period from 12:01AM of start date (or time of purchase if purchased on the same day) to 12:01AM*
      of end date (standard time at the business address). Notwithstanding the foregoing, no insurance applies prior to
      the time of Application on the first day of the Policy Period.
      <ExceptionsContainer>*Exceptions: 12:00 PM in Michigan and North Carolina</ExceptionsContainer>
    </Text>
  ),
  BUILDING_LIMIT: (
    <Text>
      Consider the full replacement cost of the building at this location. 80% coinsurance applies, up to a maximum $1MM
      limit of insurance. Dwellings are not eligible for this coverage.
    </Text>
  ),
  NPN: (
    <Text>
      The National Producer Number is a unique NAIC identifier assigned through the licensing application process. This
      is different from an individual’s state license number.
    </Text>
  ),
};

export default toolTipContent;
