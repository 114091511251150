import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GreenButton } from "styles";
import { GhostButton } from "shared/SimplyBind/styles";
import { ModalContentContainer, ActionRow, ActionContainer, ModalText } from "shared/ModalComponents";
import ModalBasic from "shared/ModalBasic";
import { Spinner } from "reactstrap";

interface ReadyToPayModalProps {
  onClose: () => void;
  applicationId: string;
  onGoToQuoteEdit: () => void;
  editButtonIsLoading?: boolean;
}

const ReadyToPayModal = ({ onClose, onGoToQuoteEdit, applicationId, editButtonIsLoading }: ReadyToPayModalProps) => {
  const history = useHistory();

  return (
    <ModalBasic
      isOpen={true}
      closeModal={onClose}
      label="Ready to pay for this policy?"
      title="Ready to pay for this policy?"
    >
      <ModalContentContainer>
        <ModalText>
          Proceed to pay for this policy or, if you need to edit this quote, you must provide some additional
          information for our new underwriting engine.
          <PremiumDisclaimer>
            There is a potential that editing the quote may result in a premium difference or declination from what was
            previously quoted.
          </PremiumDisclaimer>
        </ModalText>
      </ModalContentContainer>
      <ActionContainer>
        <ActionRow>
          <StyledGhostButton onClick={onGoToQuoteEdit} disabled={editButtonIsLoading}>
            {editButtonIsLoading ? <Spinner /> : "Edit Quote"}
          </StyledGhostButton>
          <StyledGreenButton onClick={() => history.push(`/simplybind/confirm/${applicationId}?legacy=true`)}>
            Purchase Policy
          </StyledGreenButton>
        </ActionRow>
      </ActionContainer>
    </ModalBasic>
  );
};

const StyledGreenButton = styled(GreenButton)`
  min-width: 156px;
`;

const StyledGhostButton = styled(GhostButton)`
  min-width: 156px;
`;

const PremiumDisclaimer = styled.span`
  display: block;
  margin-top: 14px;
`;

export default ReadyToPayModal;
