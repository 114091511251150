import { Label, Modal, ModalBody } from "coterie-ui-library";
import React from "react";
import { Column, Row } from "shared/ModalComponents";
import styled from "styled-components";
import { formatCurrency } from "utils/formatNumber";
import { format } from "date-fns";

type PropTypes = {
  commission: Commission;
  isOpen: boolean;
  closeModal: () => void;
};

export default function CommissionDetailsModal({ commission, isOpen, closeModal }: PropTypes) {
  const { month, year } = commission.accountingPeriod;
  return (
    <Modal label="commission-details-modal" isOpen={isOpen} closeModal={closeModal} title="Commission Details">
      <ModalBody style={{ minWidth: 600 }}>
        <DetailRow>
          <Column>
            <Label size="small">Insured Business Name</Label>
            <Content>{commission.insuredBusinessName}</Content>
          </Column>
          <Column>
            <Label size="small">Insurance Carrier</Label>
            <Content>{commission.insuranceCarrier}</Content>
          </Column>
        </DetailRow>
        <DetailRow>
          <Column>
            <Label size="small">Written Premium</Label>
            <Content>{formatCurrency(commission.writtenPremiums)}</Content>
          </Column>
          <Column>
            <Label size="small">Commission Amount</Label>
            <Content hasError={commission.writtenCommissions < 0}>
              {formatCurrency(commission.writtenCommissions)}
            </Content>
          </Column>
        </DetailRow>
        <DetailRow>
          <Column>
            <Label size="small">Accounting Period</Label>
            <Content>{format(new Date(year, month - 1), "MMMM yyyy")}</Content>
          </Column>
          <Column>
            <Label size="small">Policy Number</Label>
            <Content>{commission.policyNumber}</Content>
          </Column>
        </DetailRow>
        <DetailRow>
          <Column>
            <Label size="small">Product</Label>
            <Content>{commission.policyProduct}</Content>
          </Column>
          <Column>
            <Label size="small">Policy State</Label>
            <Content>{commission.policyState}</Content>
          </Column>
        </DetailRow>
        <DetailRow>
          <Column>
            <Label size="small">Policy Effective Date</Label>
            <Content>{new Date(commission.policyEffectiveDate).toLocaleDateString()}</Content>
          </Column>
          <Column>
            <Label size="small">Policy Expiration Date</Label>
            <Content>{new Date(commission.policyExpirationDate).toLocaleDateString()}</Content>
          </Column>
        </DetailRow>
        <DetailRow>
          <Column>
            <Label size="small">Producer First Name</Label>
            <Content>{commission.producerFirstName}</Content>
          </Column>
          <Column>
            <Label size="small">Producer Last Name</Label>
            <Content>{commission.producerLastName}</Content>
          </Column>
        </DetailRow>
        <DetailRow>
          <Column>
            <Label size="small">Producer Agency</Label>
            <Content>{commission.producerAgency}</Content>
          </Column>
        </DetailRow>
      </ModalBody>
    </Modal>
  );
}

const Content = styled.p<{ hasError?: boolean }>`
  color: ${({ theme, hasError }) => hasError && theme.colors.alert.zero};
  font-weight: ${({ hasError }) => hasError && "bold"};
  margin-bottom: 16px;
`;

const DetailRow = styled(Row)`
  margin: 8px 0;
  width: 100%;
`;
