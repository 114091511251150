import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { formatCurrency } from "utils/formatNumber";
import CoverageHeader from "./CoverageHeader";
import CoverageInfoRow from "./CoverageInfoRow";
import { POLICY_ARIA_LABELS, POLICY_OPTIONS } from "utils/constants";
import { theme } from "styles";

interface CoverageOverviewProps {
  application: Application;
  increaseFlPropertyMin?: boolean;
}

interface CoverageRowProps {
  label: string;
  value: string;
  description?: string;
}

const CoverageOverview = ({ application, increaseFlPropertyMin }: CoverageOverviewProps) => {
  const hasBOP: boolean = application.applicationTypes?.includes("BOP") ?? false;
  const hasGL: boolean = application.applicationTypes?.includes("GL") ?? false;
  const hasPL: boolean = application.applicationTypes?.includes("PL") ?? false;

  const coverageDetailsBOP = (application: Application) => {
    const location = application?.locations && application.locations[0];
    const locationOwned =
      location?.locationType === "BuildingOwned" || location?.locationType === "CommercialCondoOwned";

    return [
      {
        label: "Property Deductible - All Other Perils",
        value: formatCurrency(application.bppDeductible || 0),
        description: "applies per occurrence",
      },
      {
        label: "Property Deductible - Windstorm and Hail",
        value: "5%",
        description: "applies per coverage item (percentage deductible is subject to a $2,500 minimum amount)",
      },
      {
        label: POLICY_ARIA_LABELS[POLICY_OPTIONS.BUSINESS_PERSONAL_PROPERTY_LIMIT],
        value: formatCurrency(location?.bppLimit || 0),
      },
      {
        label: POLICY_ARIA_LABELS[POLICY_OPTIONS.BUILDING_LIMIT],
        value: formatCurrency(location?.buildingLimit),
      },
    ]
      .filter(
        (item: CoverageRowProps) => locationOwned || item.label !== POLICY_ARIA_LABELS[POLICY_OPTIONS.BUILDING_LIMIT]
      )
      .filter((item: CoverageRowProps) => {
        if (!increaseFlPropertyMin || location?.state !== "FL") {
          return item.label !== "Property Deductible - Windstorm and Hail";
        } else {
          return true;
        }
      });
  };

  const coverageDetailsGL = (application: Application) => [
    {
      label: "Property Damage Deductible",
      value: formatCurrency(application.propertyDamageLiabilityDeductible || 0),
    },
    {
      label: "General Liability (Each Occurrence)",
      value: formatCurrency(application.glLimit || 0),
      description: "(Includes Bodily Injury, Property Damage and Personal & Advertising Injury)",
    },
    {
      label: "General Liability (Annual Aggregate)",
      value: formatCurrency(application.glAggregateLimit || 0),
      description: "(Includes Bodily Injury, Property Damage and Personal & Advertising Injury)",
    },
    {
      label: "Products/Completed Operations Annual Aggregate",
      value: formatCurrency(application.glAggregateLimit || 0),
    },
    {
      label: "Damage to Premises Rented to You",
      value: application.limitDamagePremRented ? formatCurrency(application.limitDamagePremRented + 50000) : "$50,000",
    },
    {
      label: "Medical Expense Limit",
      value: "$5,000",
    },
  ];

  const coverageDetailsPL = (application: Application) => [
    {
      label: "Professional Liability Limit (Each Claim)",
      value: formatCurrency(application.professionalLiability?.occurrenceLimit || 0),
    },
    {
      label: "Professional Liability Limit (Aggregate)",
      value: formatCurrency(application.professionalLiability?.aggregateLimit || 0),
    },
    {
      label: "Deductible",
      value: formatCurrency(application.professionalLiability?.deductibleAmount || 0),
    },
    {
      label: "Years of prior acts",
      value: String(application.professionalLiability?.yearsOfPriorActs || 0),
    },
  ];

  return (
    <View wrap={false}>
      {hasBOP && (
        <View style={styles.coverageContainer}>
          <CoverageHeader title="Property Coverage Overview" subtitle="Coverages" />
          {coverageDetailsBOP(application)
            .filter((coverage) => coverage.value)
            .map((row: CoverageRowProps) => {
              return (
                <CoverageInfoRow
                  key={row.label}
                  title={row.label}
                  subtitle={row.description && row.description}
                  info={row.value}
                  showBottomLine
                />
              );
            })}
        </View>
      )}
      {(hasGL || hasBOP) && (
        <View style={styles.coverageContainer}>
          <CoverageHeader title="Liability Coverage Overview" subtitle="Coverages" />
          {coverageDetailsGL(application).map((row: CoverageRowProps) => {
            return (
              <CoverageInfoRow
                key={row.label}
                title={row.label}
                subtitle={row.description && row.description}
                info={row.value}
                showBottomLine
              />
            );
          })}
        </View>
      )}
      {hasPL && (
        <View style={styles.coverageContainer}>
          <CoverageHeader title="Professional Liability Policy Coverage" subtitle="Coverages" />
          {coverageDetailsPL(application).map((row: CoverageRowProps) => {
            return (
              <CoverageInfoRow
                key={row.label}
                title={row.label}
                subtitle={row.description && row.description}
                info={row.value}
                showBottomLine
              />
            );
          })}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  coverageContainer: {
    marginBottom: 16,
  },
  pageHeaderTitle: {
    marginBottom: "16px",
    fontSize: "18px",
    color: theme.colors.tertiary.zero,
  },
  subHeaderContainer: {
    backgroundColor: theme.colors.tertiary.zero,
    paddingTop: 3,
    paddingBottom: 3,
    marginBottom: 5,
    borderRadius: 3,
  },
  subHeaderText: {
    fontSize: 11,
    color: theme.white,
    marginLeft: 6,
  },
});

export default CoverageOverview;
