import React from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { YesNoQuestion } from "coterie-ui-library";
import { selectUnderwritingProfileDetailsData } from "redux/selectors/underwritingProfile";
import { updateUnderwritingProfileDetailsData } from "redux/reducers/_underwritingProfileReducer";
import { laptop } from "styles";

interface DetailsDataType {
  string: {
    dataKey: string;
    value: any;
    friendlyName: string;
    dataType?: string;
  };
}

interface PropTypes {
  riskData: DetailsDataType;
  updateUnderwritingProfileDetailsData: (arg0: any) => void;
}

const RiskAttributes = ({ riskData, updateUnderwritingProfileDetailsData }: PropTypes) => {
  const hasRenderedAttributes = Object.keys(riskData).some(
    (key: string) => riskData[key as keyof DetailsDataType].dataType === "toggle"
  );

  const handleRiskAttributesChange = (riskAttribute: any) => {
    updateUnderwritingProfileDetailsData({ ...riskData, ...riskAttribute });
  };

  if (!hasRenderedAttributes) return null;

  return (
    <Container>
      <RiskAttributesTitle>Risk Attributes Affecting Underwriting</RiskAttributesTitle>
      <RiskAttributesHelpText>
        The following characteristics have been attributed to this business. These are used to determine the policy
        types, terms, and conditions for which the business qualifies.
      </RiskAttributesHelpText>
      <RiskAttributesContainer>
        {Object.keys(riskData).map((key: string) => {
          if (riskData[key as keyof DetailsDataType].dataType === "toggle") {
            return (
              <RiskAttributeContainer
                key={key}
                count={Object.values(riskData).filter((e) => e.dataType === "toggle").length}
              >
                <YesNoQuestion
                  key={key}
                  question={riskData[key as keyof DetailsDataType].friendlyName}
                  value={riskData[key as keyof DetailsDataType].value}
                  selectYes={() =>
                    handleRiskAttributesChange({ [key]: { ...riskData[key as keyof DetailsDataType], value: true } })
                  }
                  selectNo={() =>
                    handleRiskAttributesChange({ [key]: { ...riskData[key as keyof DetailsDataType], value: false } })
                  }
                  name={riskData[key as keyof DetailsDataType].friendlyName}
                />
              </RiskAttributeContainer>
            );
          } else {
            return null;
          }
        })}
      </RiskAttributesContainer>
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  riskData: selectUnderwritingProfileDetailsData(state),
});

const mapDispatchToProps = {
  updateUnderwritingProfileDetailsData,
};

const RiskAttributesTitle = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
`;

const RiskAttributesHelpText = styled.p`
  margin: 0 0 24px 0;
  font-size: 13px;
`;

const RiskAttributesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 24px;
`;

const RiskAttributeContainer = styled.div<{ count: number }>`
  min-width: ${(props) => (props.count > 3 ? "48%" : "50%")};
  ${laptop} {
    min-width: 100%;
  }
  :not(:last-child) {
    border-bottom: ${(props) => `2px solid ${props.theme.offWhite}`};
    padding-bottom: 16px;
  }
  :nth-last-child(2) {
    ${(props) =>
      props.count > 3 &&
      props.count % 2 === 0 &&
      css`
        border-bottom: none;
        padding-bottom: 0px;
      `}
    ${laptop} {
      border-bottom: ${(props) => `2px solid ${props.theme.offWhite}`};
      padding-bottom: 16px;
    }
  }
`;

const Container = styled.div`
  margin-top: 30px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(RiskAttributes);
