import React from "react";
import styled from "styled-components";
import { Header4, Paragraph } from "styles";
import DragDropImageUpload from "kit/imageUpload/DragDropImageUpload";

const AgencyLogo = ({ agencyId }: { agencyId: string }) => (
  <>
    <Header4>Agency Logo</Header4>
    <StyledParagraph>Upload your logo to co-brand your Quote Proposals</StyledParagraph>
    <DragDropImageUpload agencyId={agencyId} />
  </>
);

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 8px;
`;

export default AgencyLogo;
