import { applyMiddleware, compose, createStore } from "redux";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "redux/reducers";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { Role } from "types/enums";
import updateApplicationFromServer from "./middleware/updateApplicationFromServer";
import fetchAvailableEndorsements from "./middleware/fetchAvailableEndorsements";

const reduxLoggerEnabled = false;

const migrations = {
  0: (state) => {
    // migration clear out device state
    return {
      ...state,
      auth: {
        user: undefined,
        token: undefined,
        isAuthenticated: false,
        role: Role.User, //remove later
        roles: [],
        is_dashboard: false,
      },
    };
  },
  1: (state) => {
    return {
      ...state,
      user: {
        loading: false,
        error: undefined,
        email: "",
        givenName: "",
        id: "",
        role: Role.User,
        roles: [Role.User],
        userName: "",
        account: undefined,
        channelPartner: undefined,
        agency: undefined,
      },
    };
  },
};

const persistConfig = {
  key: "coterie-kit",
  version: 1,
  storage,
  blacklist: [
    "application",
    "affiliation",
    "availableEndorsements",
    "snapshot",
    "underwritingProfile",
    "underwritingQuestions",
    "global",
  ],
  migrate: createMigrate(migrations, { debug: false }),
};

const loggerConfig = {
  predicate: () => reduxLoggerEnabled,
};

export default function configureStore() {
  const composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const persistedReducer = persistReducer(persistConfig, reducer);

  const store = createStore(
    persistedReducer,
    {},
    composedEnhancers(
      applyMiddleware(createLogger(loggerConfig), updateApplicationFromServer, fetchAvailableEndorsements, thunk)
    )
  );

  let persistor = persistStore(store);
  // persistor.purge();

  return { store, persistor };
}
