import React from "react";
import styled, { CSSObject } from "styled-components";
import Select, { InputActionMeta, components } from "react-select";
import Creatable from "react-select/creatable";
import { theme, Label } from "styles";
import { ErrorText } from "./Input";
import ToolTipIcon from "shared/ToolTipIcon";
import { Dictionary } from "lodash";

interface PropTypes {
  label?: string;
  labelStyles?: Record<string, string>;
  onChange: (selectedOption: any) => void;
  onBlur?: (selectedOption: any) => void;
  onInputChange?: (value: string, changeEvent?: InputActionMeta) => void;
  options: OptionType[];
  value?: string | number | undefined;
  full?: boolean;
  name?: string;
  menuHeight?: number;
  error?: boolean;
  errorText?: string;
  isMulti?: boolean;
  toolTipContent?: string;
  isCreatable?: boolean;
  placeholder?: string;
  multiValues?: OptionType[];
  isClearable?: boolean;
  labelIsHidden?: boolean;
  isDisabled?: boolean;
  customStyles?: Dictionary<any>;
  isSearchable?: boolean;
  defaultValue?: OptionType;
  isValidNewOption?: (value: string) => boolean;
  id?: string;
}

export interface StyleTypes {
  isFocused: boolean;
  isSelected: boolean;
  isDisabled?: boolean;
  data: OptionType;
}

export interface OptionType {
  value: string | number;
  label: string;
  helperText?: string;
  disabled?: boolean;
}

const dropdownOptionColor = (isSelected: boolean, isDisabled?: boolean) => {
  if (isDisabled) {
    return theme.regularGray;
  } else if (isSelected) {
    return theme.textNavyBlue;
  } else {
    return theme.textNavyBlue;
  }
};

export const dropdownStyles = {
  control: (styles: CSSObject, { isFocused }: StyleTypes) => {
    return {
      ...styles,
      boxShadow: isFocused ? `0 0 0 1px ${theme.blue}` : null,
      border: isFocused ? `solid 1px ${theme.inputBorder}` : `solid 1px ${theme.inputBorder}`,
      minHeight: "48px",

      ":hover": {
        ...styles[":hover"],
        border: isFocused ? `solid 1px ${theme.inputBorder}` : `solid 1px ${theme.inputBorder}`,
      },
    };
  },
  option: (styles: CSSObject, { isFocused, isSelected, isDisabled }: StyleTypes) => {
    return {
      ...styles,
      backgroundColor: isFocused ? theme.green : null,
      color: dropdownOptionColor(isSelected, isDisabled),
      fontFamily: theme.secondaryFont,
      cursor: isDisabled ? "not-allowed" : "pointer",
      minHeight: "40px",
      zIndex: 3,
      overflowWrap: "break-word",
      display: "flex",
      alignItems: "center",

      ":active": {
        ...styles[":active"],
        backgroundColor: isDisabled ? null : theme.green,
      },
    };
  },
  dropdownIndicator: (styles: CSSObject) => ({
    ...styles,
    cursor: "pointer",
    color: theme.blue,
    ":hover": {
      color: theme.blue,
    },
  }),
  clearIndicator: (styles: CSSObject) => ({
    ...styles,
    cursor: "pointer",
  }),
  singleValue: (styles: CSSObject) => ({
    ...styles,
    color: theme.textNavyBlue,
    fontFamily: theme.secondaryFont,
  }),
};

const Dropdown = ({
  label,
  labelStyles,
  onChange,
  onInputChange,
  options,
  value,
  full,
  name,
  menuHeight = 175,
  error,
  errorText,
  isMulti,
  toolTipContent,
  placeholder,
  multiValues,
  isClearable,
  onBlur,
  labelIsHidden,
  isDisabled,
  customStyles,
  isSearchable = true,
  defaultValue,
  isCreatable,
  isValidNewOption,
  id,
}: PropTypes) => {
  const selected = options?.find((option) => option.value === value);
  const SelectComponent = isCreatable ? StyledCreatableSelect : StyledSelect;

  return (
    <Column>
      <LabelContainer labelIsHidden={labelIsHidden}>
        <Label style={labelStyles} htmlFor={name}>
          {label}
        </Label>
        {toolTipContent && <ToolTipIcon contentKey={toolTipContent} />}
      </LabelContainer>
      <SelectComponent
        key={value}
        id={id}
        className={`${name}-dropdown`}
        classNamePrefix={`${name}-dropdown`}
        inputId={`${name}-dropdown`}
        onChange={(value: { value: number; label: string }) => {
          onChange(value);
        }}
        onInputChange={onInputChange}
        onBlur={onBlur}
        options={options}
        styles={customStyles ?? dropdownStyles}
        value={multiValues ? multiValues : selected}
        full={full}
        name={name}
        aria-label={label}
        maxMenuHeight={menuHeight}
        hasError={error}
        isMulti={isMulti}
        isClearable={isClearable}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        defaultValue={defaultValue}
        isOptionDisabled={(option: OptionType) => option.disabled}
        components={{ IndicatorSeparator: () => null, Option: customOption }}
        isValidNewOption={isValidNewOption}
      />
      {error && errorText && <ErrorText>{errorText}</ErrorText>}
    </Column>
  );
};

const customOption = (props: any) => {
  const { data, ...rest } = props;
  return (
    <components.Option {...rest}>
      <OptionContainer>
        <OptionLabel>{data.label}</OptionLabel>
        {data.helperText && <HelperText>{data.helperText}</HelperText>}
      </OptionContainer>
    </components.Option>
  );
};

export const StyledSelect = styled(Select)<{ hasError?: boolean }>`
  width: ${(props) => (props.full ? "100%" : "255px")};
  border-radius: 4px;
  margin-bottom: ${(props) => (props.hasError ? "8px" : "32px")};
  ${(props) => props.hasError && `border: 1px solid ${props.theme.errorText}; border-radius: 5px;`}
`;

export const StyledCreatableSelect = styled(Creatable)<{ hasError?: boolean }>`
  width: ${(props) => (props.full ? "100%" : "255px")};
  border-radius: 4px;
  margin-bottom: ${(props) => (props.hasError ? "8px" : "32px")};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelContainer = styled.span<{ labelIsHidden?: boolean }>`
  display: flex;
  ${(props) =>
    props.labelIsHidden && `height: 1px; width: 1px; overflow: hidden; position: absolute; margin-bottom: 0;`}
`;

const OptionLabel = styled.div`
  font-weight: normal;
`;

const HelperText = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5px;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Dropdown;
