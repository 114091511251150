import { useEffect, useState } from "react";
import { Label } from "reactstrap";
import styled from "styled-components";
import { Dropdown, Input, Switch } from "styles";

interface ContactDetailsProps {
  updateFormData: (arg0: string, arg1: any) => void;
  formData?: ClaimFormData;
  policy: Policy;
}

const ContactDetails = ({ updateFormData, formData, policy }: ContactDetailsProps) => {
  const [contactData, setContactData] = useState<any>({
    contactFirstName: formData?.contactData?.contactFirstName || policy.contactFirstName,
    contactLastName: formData?.contactData?.contactLastName || policy.contactLastName,
    countryCode: 1,
    phone: formData?.contactData?.phone || policy.contactPhone,
    mailingAddress: formData?.contactData?.mailingAddress || policy.mailingAddress.street || "",
    mailingCity: formData?.contactData?.mailingCity || policy.mailingAddress.city || "",
    mailingState: formData?.contactData?.mailingState || policy.mailingAddress.state || "",
    mailingZip: formData?.contactData?.mailingZip || policy.mailingAddress.zip || "",
    isPrimaryAddress: true,
    incidentAddress: formData?.contactData?.incidentAddress || policy.mailingAddress.street || "",
    incidentCity: formData?.contactData?.incidentCity || policy.mailingAddress.city || "",
    incidentState: formData?.contactData?.incidentState || policy.mailingAddress.state || "",
    incidentZip: formData?.contactData?.incidentZip || policy.mailingAddress.zip || "",
    phoneType: formData?.contactData?.phoneType || "Business",
    email: formData?.contactData?.email || policy.contactEmail,
  });

  useEffect(() => {
    updateFormData("contact", contactData);
  }, [contactData]);

  const handleFormInputs = (field: string, value: any) => {
    const updatedFormData = { ...contactData };
    updatedFormData[field] = value;
    setContactData(updatedFormData);
  };

  const phoneTypeOptions = [
    { value: "Business", label: "Business" },
    { value: "Personal", label: "Personal" },
  ];

  return (
    <FormContainer>
      <h2>Contact Details</h2>
      <FormGroup>
        <Input
          label="First Name"
          name="contactFirstName"
          type="text"
          value={contactData.contactFirstName}
          handleChange={(event) => handleFormInputs(event.target.name, event.target.value)}
          placeholder="First"
          required
          inputStyles={{ marginBottom: "16px" }}
        />
        <Input
          label="Last Name"
          name="contactLastName"
          type="text"
          value={contactData.contactLastName}
          handleChange={(event) => handleFormInputs(event.target.name, event.target.value)}
          placeholder="Last"
          required
          inputStyles={{ marginBottom: "16px" }}
        />
      </FormGroup>
      <Dropdown
        onChange={(selected) => handleFormInputs("phoneType", selected.value)}
        options={phoneTypeOptions}
        value={contactData.phoneType}
        full={true}
        name="phoneType"
        label="Phone Type"
      />
      <FormGroup>
        <Input
          label="Country Code"
          name="countryCode"
          type="text"
          value={contactData.countryCode}
          handleChange={(event) => handleFormInputs(event.target.name, event.target.value)}
          placeholder="+1"
          required
          inputStyles={{ width: "50px", marginBottom: "16px", marginRight: "96px" }}
        />
        <Input
          label="Phone Number"
          name="phone"
          type="tel"
          value={contactData.phone}
          handleChange={(event) => handleFormInputs(event.target.name, event.target.value)}
          placeholder="Insured Contact Phone Number"
          required
          inputStyles={{ marginBottom: "16px" }}
        />
      </FormGroup>
      <Input
        label="Insured Contact Email"
        name="email"
        type="text"
        value={contactData.email}
        handleChange={(event) => handleFormInputs(event.target.name, event.target.value)}
        placeholder="Insured Contact Email"
        required
        inputStyles={{ marginBottom: "16px" }}
      />
      <Input
        label="Mailing Address"
        name="mailingAddress"
        type="text"
        value={contactData.mailingAddress}
        handleChange={(event) => handleFormInputs(event.target.name, event.target.value)}
        placeholder="Mailing Address"
        required
      />
      <FormGroup>
        <Input
          label="City"
          name="mailingCity"
          type="text"
          value={contactData.mailingCity}
          handleChange={(event) => handleFormInputs(event.target.name, event.target.value)}
          placeholder="City"
          required
        />
        <Input
          label="State"
          name="mailingState"
          type="text"
          value={contactData.mailingState}
          handleChange={(event) => handleFormInputs(event.target.name, event.target.value)}
          placeholder="State"
          required
        />
        <Input
          label="Zip Code"
          name="mailingZip"
          type="text"
          value={contactData.mailingZip}
          handleChange={(event) => handleFormInputs(event.target.name, event.target.value)}
          placeholder="Zip Code"
          required
        />
      </FormGroup>
      <InlineSwitchContainer>
        <Label>Did the incident occur at the mailing address?</Label>
        <p>
          <b>{contactData.isPrimaryAddress ? "Yes" : "No"}</b>
        </p>
        <Switch
          onChange={() => handleFormInputs("isPrimaryAddress", !contactData.isPrimaryAddress)}
          checked={contactData.isPrimaryAddress}
          ariaLabel={"incident-address-same-as-insured"}
          dataTestId={"insured-address-switch"}
        />{" "}
      </InlineSwitchContainer>
      {!contactData.isPrimaryAddress && (
        <>
          <Input
            label="Location of Loss"
            name="incidentAddress"
            type="text"
            value={contactData.incidentAddress}
            handleChange={(event) => handleFormInputs(event.target.name, event.target.value)}
            placeholder="Incident Address"
            required
            inputStyles={{ marginBottom: "16px" }}
          />
          <FormGroup>
            <Input
              label="City"
              name="incidentCity"
              type="text"
              value={contactData.incidentCity}
              handleChange={(event) => handleFormInputs(event.target.name, event.target.value)}
              placeholder="City (incident)"
              required
            />
            <Input
              label="State"
              name="incidentState"
              type="text"
              value={contactData.incidentState}
              handleChange={(event) => handleFormInputs(event.target.name, event.target.value)}
              placeholder="State (incident)"
              required
            />
            <Input
              label="Zip Code"
              name="incidentZip"
              type="text"
              value={contactData.incidentZip}
              handleChange={(event) => handleFormInputs(event.target.name, event.target.value)}
              placeholder="Zip Code (incident)"
              required
            />
          </FormGroup>
        </>
      )}
    </FormContainer>
  );
};

const FormContainer = styled.div`
  min-width: 550px;
  padding: 0 25px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
`;

const InlineSwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export default ContactDetails;
