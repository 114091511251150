import { AlertButton, CTAButton, SecondaryButton } from "coterie-ui-library";
import styled from "styled-components";

interface ModalActionBarProps {
  secondaryText?: string;
  closeModal: () => void;
  disabled?: boolean;
  onSubmit?: () => void;
  backFormPage: () => void;
  page: number;
}

const ModalActionBar = ({ closeModal, onSubmit, disabled, page, backFormPage }: ModalActionBarProps) => {
  if (!onSubmit) return null;
  return (
    <>
      <ActionContainer>
        <ActionButton>
          {page === 0 ? (
            <AlertButton data-testid="modal-cancel-button" onClick={closeModal}>
              Close
            </AlertButton>
          ) : (
            <SecondaryButton data-testid="modal-back-button" onClick={backFormPage}>
              Back
            </SecondaryButton>
          )}
        </ActionButton>
        {onSubmit && (
          <ActionButton>
            <CTAButton onSubmit={onSubmit} onClick={onSubmit} disabled={disabled} data-testid="modal-submit-button">
              <span>{disabled ? "Loading..." : page === 4 ? "Create Claim" : "Next"}</span>
            </CTAButton>
          </ActionButton>
        )}
      </ActionContainer>
    </>
  );
};

const ActionContainer = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 24px;
  border-top: ${(props) => `2px solid ${props.theme.modals.buttonBar.borderTop}`};
`;

const ActionButton = styled.div`
  display: inline-block;
  margin-right: 16px;
`;

export default ModalActionBar;
