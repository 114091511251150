const formatValue = (value: number, money?: boolean) => {
  if (value < 1000) {
    if (money === true) {
      return `$${value}`;
    }
    return value;
  }
  if (value >= 1000000) {
    return `${money === true ? "$" : ""}${(value / 1000000).toString()}M`;
  }

  const formatted = (value / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (money === true) {
    return `$${formatted}k`;
  }
  return `${formatted}k`;
};

export const formatNumberLong = (value?: number) => value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "";

export const formatNumber = (amount: any) => {
  try {
    return parseInt(amount);
  } catch {
    return amount;
  }
};

export const formatCurrency = (value: number | undefined) => {
  if (value === undefined) return "";
  if (value % 1 === 0) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  } else
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
};

export const formatPhoneNumber = (value: string) => {
  if (value.length === 10) {
    return `${value.substring(0, 3)}-${value.substring(3, 6)}-${value.substring(6)}`;
  } else return value;
};

export default formatValue;
