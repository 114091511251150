import React from "react";
import { TableContainer } from "styles/containerStyledComponents";
import { Link } from "react-router-dom";
import AjaxLoader from "shared/AjaxLoader";
import ClipboardInput from "shared/KITClipboardInput";
import styled from "styled-components";

const StyledChannelPartnerTable = styled(TableContainer)`
  margin-top: 25px;
  table {
    tbody {
      tr {
        td {
          padding-right: 10px;

          &:first-child {
            width: 150px;
          }
        }
      }
    }
  }
`;

interface PropTypes {
  channelPartners: ChannelPartner[];
  isLoading: boolean;
}

export default class ChannelPartnersTable extends React.Component<PropTypes> {
  static defaultProps = {
    channelPartners: [],
  };

  render(): React.ReactElement {
    const { channelPartners, isLoading } = this.props;

    const rows = Array.isArray(channelPartners)
      ? channelPartners.map((cp: ChannelPartner, i) => (
          <tr key={i} data-channel-partner-id={cp.id}>
            <td>{cp.name}</td>
            <td>
              <Link to={`/accounts/${cp.accountId}`}>{cp.accountId}</Link>
            </td>
            <td>
              {cp.webhookUrl ? (
                <ClipboardInput name={`Webhook Url for ${cp.id}-${cp.name}`} text={cp.webhookUrl} />
              ) : (
                ""
              )}
            </td>
            <td>{cp.policyPaymentType}</td>
            <td>
              <ClipboardInput name={`${cp.id}-${cp.name} Publishable Key`} text={cp.publishableKey} />
            </td>
            <td>{cp.allowPolicyBackdating ? "Yes" : "No"}</td>
            <td>{cp.premierPlatform ? "Yes" : "No"}</td>
            <td>
              <Link data-testid="edit_channel_partner" to={`/channel-partners/${cp.id}`}>
                Edit
              </Link>
            </td>
          </tr>
        ))
      : [];

    return (
      <StyledChannelPartnerTable>
        <table data-testid="channel_partners_table">
          <thead>
            <tr>
              <td>Name</td>
              <td>Id</td>
              <td>Webhook URL</td>
              <td>Policy Payment Type</td>
              <td>Publishable Key</td>
              <td>Backdating</td>
              <td>Premier</td>
              <td>Edit</td>
            </tr>
          </thead>

          {isLoading ? (
            <AjaxLoader />
          ) : (
            <tbody data-testid="channel_partners_data">
              {rows.length > 0 ? (
                rows
              ) : (
                <tr>
                  <td colSpan={99}>There are no channel partners!</td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </StyledChannelPartnerTable>
    );
  }
}
