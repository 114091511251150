import { useSelector } from "react-redux";
import {
  selectMonth1Fees,
  selectMonth1Owed,
  selectTotalYearlyFees,
  selectTotalYearlyOwed,
  selectMonthlyFees,
  selectMonthlyOwed,
  selectPolicyFees,
  selectInstallmentFees,
  selectLossControlFees,
  selectHasFees,
  selectHasPolicyFee,
  selectFeesSavings,
  selectHasFirstMonthWaived,
} from "redux/selectors/application";
import {
  selectMonth1Fees as selectMonth1FeesSnapshot,
  selectMonth1Owed as selectMonth1OwedSnapshot,
  selectTotalYearlyFees as selectTotalYearlyFeesSnapshot,
  selectTotalYearlyOwed as selectTotalYearlyOwedSnapshot,
  selectMonthlyFees as selectMonthlyFeesSnapshot,
  selectMonthlyOwed as selectMonthlyOwedSnapshot,
  selectPolicyFees as selectPolicyFeesSnapshot,
  selectInstallmentFees as selectInstallmentFeesSnapshot,
  selectLossControlFees as selectLossControlFeesSnapshot,
  selectHasFees as selectHasFeesSnapshot,
  selectHasPolicyFee as selectHasPolicyFeeSnapshot,
  selectFeesSavings as selectFeesSavingsSnapshot,
  selectHasFirstMonthWaived as selectHasFirstMonthWaivedSnapshot,
} from "redux/reducers/_snapshotReducer";

const useFees = (isSimplyBind?: boolean) => {
  return {
    month1Fees: useSelector(isSimplyBind ? selectMonth1Fees : selectMonth1FeesSnapshot),
    month1Owed: useSelector(isSimplyBind ? selectMonth1Owed : selectMonth1OwedSnapshot),
    totalYearlyFees: useSelector(isSimplyBind ? selectTotalYearlyFees : selectTotalYearlyFeesSnapshot),
    totalYearlyOwed: useSelector(isSimplyBind ? selectTotalYearlyOwed : selectTotalYearlyOwedSnapshot),
    monthlyFees: useSelector(isSimplyBind ? selectMonthlyFees : selectMonthlyFeesSnapshot),
    monthlyOwed: useSelector(isSimplyBind ? selectMonthlyOwed : selectMonthlyOwedSnapshot),
    policyFees: useSelector(isSimplyBind ? selectPolicyFees : selectPolicyFeesSnapshot),
    installmentFees: useSelector(isSimplyBind ? selectInstallmentFees : selectInstallmentFeesSnapshot),
    lossControlFees: useSelector(isSimplyBind ? selectLossControlFees : selectLossControlFeesSnapshot),
    hasFees: useSelector(isSimplyBind ? selectHasFees : selectHasFeesSnapshot),
    hasPolicyFee: useSelector(isSimplyBind ? selectHasPolicyFee : selectHasPolicyFeeSnapshot),
    feesSavings: useSelector(isSimplyBind ? selectFeesSavings : selectFeesSavingsSnapshot),
    hasFirstMonthWaived: useSelector(isSimplyBind ? selectHasFirstMonthWaived : selectHasFirstMonthWaivedSnapshot),
  };
};

export default useFees;
