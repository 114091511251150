import styled from "styled-components";

const Label = styled.label<{ withoutMargin?: boolean }>`
  font-family: ${(props) => props.theme.primaryFont};
  color: ${(props) => props.theme.fontColors?.primary};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;

  margin-bottom: ${(props) => (props.withoutMargin ? "0px" : "8px")};
  letter-spacing: 0px;
`;

export default Label;
