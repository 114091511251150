import React from "react";
import styled from "styled-components";

interface PropTypes {
  selectedTab: number;
  tabOptions: { id?: number; value: string; content: JSX.Element }[];
  panelStyles?: any;
}

const TabPanel = ({ selectedTab, tabOptions, panelStyles }: PropTypes) => (
  <>
    {tabOptions.map((tab) => {
      const isSelected = Boolean(selectedTab === tab.id);
      return (
        <Panel
          key={tab.value}
          role="tabpanel"
          id={`tabpanel-${tab.value}`}
          aria-labelledby={`tab-${tab.value}`}
          aria-expanded={isSelected}
          hidden={!isSelected}
          style={panelStyles}
        >
          {tab.content}
        </Panel>
      );
    })}
  </>
);

const Panel = styled.div`
  padding: 40px 0;
  background-color: #e8e8ef;
  border-radius: 2px;
`;

export default TabPanel;
