import styled from "styled-components";
import { mobile } from "styles";

export const NavListItem = styled.li<{ isResourceLink?: boolean }>`
  list-style: none;
  margin-left: 30px;
  padding: 5px 0px;
  ${(props) => props.isResourceLink && "width: fit-content; margin: 0 auto;"}

  ${mobile} {
    ${(props) => !props.isResourceLink && "margin-left: 0px;"}
  }
`;
