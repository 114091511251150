export const POLICY_DEFAULTS = {
  PL: {
    professionalLiability: {
      deductibleAmount: 1500,
      occurrenceLimit: 500000,
    },
  },
  GL: {
    glLimit: 1000000,
    propertyDamageLiabilityDeductible: 0,
    lossControl: "OptIn",
  },
  BOP: {
    bppDeductible: 500,
    locations: [
      {
        buildingLimit: 100000,
        bppLimit: 25000,
      },
    ],
  },
};

export const POLICY_OPTIONS = {
  BUSINESS_PERSONAL_PROPERTY_LIMIT: "BUSINESS_PERSONAL_PROPERTY_LIMIT",
  BUILDING_LIMIT: "BUILDING_LIMIT",
  PROPERTY_CLAIMS_DEDUCTIBLE: "PROPERTY_CLAIMS_DEDUCTIBLE",
  PROPERTY_CLAIMS_DEDUCTIBLE_FL: "PROPERTY_CLAIMS_DEDUCTIBLE_FL",
  GENERAL_LIABILITY_LIMIT: "GENERAL_LIABILITY_LIMIT",
  PROPERTY_DAMAGE_DEDUCTIBLE: "PROPERTY_DAMAGE_DEDUCTIBLE",
  PROFESSIONAL_LIABILITY_LIMIT: "PROFESSIONAL_LIABILITY_LIMIT",
  DEDUCTIBLE: "DEDUCTIBLE",
  YEARS_OF_PROFESSIONAL_EXPERIENCE: "YEARS_OF_PROFESSIONAL_EXPERIENCE",
  YEARS_OF_PRIOR_ACTS: "YEARS_OF_PRIOR_ACTS",
  PERIOD_LOADING: "PERIOD_LOADING",
};

export const VALUE_OPTIONS = {
  [POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE]: [500, 1000, 2500, 5000, 10000],
  [POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE_FL]: [1000, 2500],
  [POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT]: [300_000, 500_000, 1_000_000, 2_000_000],
  [POLICY_OPTIONS.PROPERTY_DAMAGE_DEDUCTIBLE]: [0, 1000, 2500],
  [POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT]: [25000, 50000, 250000, 500000, 1000000],
  [POLICY_OPTIONS.DEDUCTIBLE]: [0, 250, 750, 1500, 2500, 5000, 7500, 20000, 50000],
};

export const POLICY_ARIA_LABELS = {
  [POLICY_OPTIONS.BUSINESS_PERSONAL_PROPERTY_LIMIT]: "Business Personal Property Limit",
  [POLICY_OPTIONS.BUILDING_LIMIT]: "Building Limit",
  [POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE]: "Property Claims Deductible",
  [POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT]: "General liability claim amount",
  [POLICY_OPTIONS.PROPERTY_DAMAGE_DEDUCTIBLE]: "Property damage claim deductible amount",
  [POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT]: "Professional liability claims coverage amount",
  [POLICY_OPTIONS.DEDUCTIBLE]: "Professional liability claims deductible amount",
  [POLICY_OPTIONS.YEARS_OF_PROFESSIONAL_EXPERIENCE]: "Years of Professional Experience",
  [POLICY_OPTIONS.YEARS_OF_PRIOR_ACTS]: "Years of Prior Acts Coverage",
  [POLICY_OPTIONS.PERIOD_LOADING]: "Extended Reporting Period",
};

export const businessAgeOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10+" },
];

export const stateAbbrevOptions = [
  { value: "AL", label: "AL", name: "Alabama" },
  { value: "AK", label: "AK", name: "Alaska" },
  { value: "AZ", label: "AZ", name: "Arizona" },
  { value: "AR", label: "AR", name: "Arkansas" },
  { value: "CA", label: "CA", name: "California" },
  { value: "CO", label: "CO", name: "Colorado" },
  { value: "CT", label: "CT", name: "Connecticut" },
  { value: "DE", label: "DE", name: "Delaware" },
  { value: "FL", label: "FL", name: "Florida" },
  { value: "GA", label: "GA", name: "Georgia" },
  { value: "HI", label: "HI", name: "Hawaii" },
  { value: "ID", label: "ID", name: "Idaho" },
  { value: "IL", label: "IL", name: "Illinois" },
  { value: "IN", label: "IN", name: "Indiana" },
  { value: "IA", label: "IA", name: "Iowa" },
  { value: "KS", label: "KS", name: "Kansas" },
  { value: "KY", label: "KY", name: "Kentucky" },
  { value: "LA", label: "LA", name: "Louisiana" },
  { value: "ME", label: "ME", name: "Maine" },
  { value: "MD", label: "MD", name: "Maryland" },
  { value: "MA", label: "MA", name: "Massachusetts" },
  { value: "MI", label: "MI", name: "Michigan" },
  { value: "MN", label: "MN", name: "Minnesota" },
  { value: "MS", label: "MS", name: "Mississippi" },
  { value: "MO", label: "MO", name: "Missouri" },
  { value: "MT", label: "MT", name: "Montana" },
  { value: "NE", label: "NE", name: "Nebraska" },
  { value: "NV", label: "NV", name: "Nevada" },
  { value: "NH", label: "NH", name: "New Hampshire" },
  { value: "NJ", label: "NJ", name: "New Jersey" },
  { value: "NM", label: "NM", name: "New Mexico" },
  { value: "NY", label: "NY", name: "New York" },
  { value: "NC", label: "NC", name: "North Carolina" },
  { value: "ND", label: "ND", name: "North Dakota" },
  { value: "OH", label: "OH", name: "Ohio" },
  { value: "OK", label: "OK", name: "Oklahoma" },
  { value: "OR", label: "OR", name: "Oregon" },
  { value: "PA", label: "PA", name: "Pennsylvania" },
  { value: "RI", label: "RI", name: "Rhode Island" },
  { value: "SC", label: "SC", name: "South Carolina" },
  { value: "SD", label: "SD", name: "South Dakota" },
  { value: "TN", label: "TN", name: "Tennessee" },
  { value: "TX", label: "TX", name: "Texas" },
  { value: "UT", label: "UT", name: "Montana" },
  { value: "VT", label: "VT", name: "Vermont" },
  { value: "VA", label: "VA", name: "Virginia" },
  { value: "WA", label: "WA", name: "Washington" },
  { value: "WV", label: "WV", name: "West Virginia" },
  { value: "WI", label: "WI", name: "Wisconsin" },
  { value: "WY", label: "WY", name: "Wyoming" },
  { value: "DC", label: "DC", name: "Washington, D.C." },
].sort((a, b) => (a.label > b.label ? 1 : -1));

export const dropdownAccountTypeOptions = [
  //{ TODO: comment back in after data patch}
  // { value: 0, label: "Channel Partner" },
  { value: 1, label: "Agency" },
  { value: 2, label: "Producer" },
];

export const PAGES = {
  VERIFY_NPN: "VERIFY_NPN",
  ACCOUNT_TYPE: "ACCOUNT_TYPE",
  BASIC_INFO: "BASIC_INFO",
};

export const passwordInput = {
  passwordPattern: "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}",
  passwordTitle:
    "Must contain at least one number, one uppercase letter, one lowercase letter, and at least 8 or more characters.",
};

type StringDict = {
  [key: string]: string;
};

export const namedColorsToHex: StringDict = {
  aliceblue: "#f0f8ff",
  antiquewhite: "#faebd7",
  aqua: "#00ffff",
  aquamarine: "#7fffd4",
  azure: "#f0ffff",
  beige: "#f5f5dc",
  bisque: "#ffe4c4",
  black: "#000000",
  blanchedalmond: "#ffebcd",
  blue: "#0000ff",
  blueviolet: "#8a2be2",
  brown: "#a52a2a",
  burlywood: "#deb887",
  cadetblue: "#5f9ea0",
  chartreuse: "#7fff00",
  chocolate: "#d2691e",
  coral: "#ff7f50",
  cornflowerblue: "#6495ed",
  cornsilk: "#fff8dc",
  crimson: "#dc143c",
  cyan: "#00ffff",
  darkblue: "#00008b",
  darkcyan: "#008b8b",
  darkgoldenrod: "#b8860b",
  darkgray: "#a9a9a9",
  darkgreen: "#006400",
  darkkhaki: "#bdb76b",
  darkmagenta: "#8b008b",
  darkolivegreen: "#556b2f",
  darkorange: "#ff8c00",
  darkorchid: "#9932cc",
  darkred: "#8b0000",
  darksalmon: "#e9967a",
  darkseagreen: "#8fbc8f",
  darkslateblue: "#483d8b",
  darkslategray: "#2f4f4f",
  darkturquoise: "#00ced1",
  darkviolet: "#9400d3",
  deeppink: "#ff1493",
  deepskyblue: "#00bfff",
  dimgray: "#696969",
  dodgerblue: "#1e90ff",
  firebrick: "#b22222",
  floralwhite: "#fffaf0",
  forestgreen: "#228b22",
  fuchsia: "#ff00ff",
  gainsboro: "#dcdcdc",
  ghostwhite: "#f8f8ff",
  gold: "#ffd700",
  goldenrod: "#daa520",
  gray: "#808080",
  green: "#008000",
  greenyellow: "#adff2f",
  honeydew: "#f0fff0",
  hotpink: "#ff69b4",
  "indianred ": "#cd5c5c",
  indigo: "#4b0082",
  ivory: "#fffff0",
  khaki: "#f0e68c",
  lavender: "#e6e6fa",
  lavenderblush: "#fff0f5",
  lawngreen: "#7cfc00",
  lemonchiffon: "#fffacd",
  lightblue: "#add8e6",
  lightcoral: "#f08080",
  lightcyan: "#e0ffff",
  lightgoldenrodyellow: "#fafad2",
  lightgrey: "#d3d3d3",
  lightgreen: "#90ee90",
  lightpink: "#ffb6c1",
  lightsalmon: "#ffa07a",
  lightseagreen: "#20b2aa",
  lightskyblue: "#87cefa",
  lightslategray: "#778899",
  lightsteelblue: "#b0c4de",
  lightyellow: "#ffffe0",
  lime: "#00ff00",
  limegreen: "#32cd32",
  linen: "#faf0e6",
  magenta: "#ff00ff",
  maroon: "#800000",
  mediumaquamarine: "#66cdaa",
  mediumblue: "#0000cd",
  mediumorchid: "#ba55d3",
  mediumpurple: "#9370d8",
  mediumseagreen: "#3cb371",
  mediumslateblue: "#7b68ee",
  mediumspringgreen: "#00fa9a",
  mediumturquoise: "#48d1cc",
  mediumvioletred: "#c71585",
  midnightblue: "#191970",
  mintcream: "#f5fffa",
  mistyrose: "#ffe4e1",
  moccasin: "#ffe4b5",
  navajowhite: "#ffdead",
  navy: "#000080",
  oldlace: "#fdf5e6",
  olive: "#808000",
  olivedrab: "#6b8e23",
  orange: "#ffa500",
  orangered: "#ff4500",
  orchid: "#da70d6",
  palegoldenrod: "#eee8aa",
  palegreen: "#98fb98",
  paleturquoise: "#afeeee",
  palevioletred: "#d87093",
  papayawhip: "#ffefd5",
  peachpuff: "#ffdab9",
  peru: "#cd853f",
  pink: "#ffc0cb",
  plum: "#dda0dd",
  powderblue: "#b0e0e6",
  purple: "#800080",
  rebeccapurple: "#663399",
  red: "#ff0000",
  rosybrown: "#bc8f8f",
  royalblue: "#4169e1",
  saddlebrown: "#8b4513",
  salmon: "#fa8072",
  sandybrown: "#f4a460",
  seagreen: "#2e8b57",
  seashell: "#fff5ee",
  sienna: "#a0522d",
  silver: "#c0c0c0",
  skyblue: "#87ceeb",
  slateblue: "#6a5acd",
  slategray: "#708090",
  snow: "#fffafa",
  springgreen: "#00ff7f",
  steelblue: "#4682b4",
  tan: "#d2b48c",
  teal: "#008080",
  thistle: "#d8bfd8",
  tomato: "#ff6347",
  turquoise: "#40e0d0",
  violet: "#ee82ee",
  wheat: "#f5deb3",
  white: "#ffffff",
  whitesmoke: "#f5f5f5",
  yellow: "#ffff00",
  yellowgreen: "#9acd32",
};

export const applicationFormContactFields = [
  "contactEmail",
  "contactLastName",
  "contactFirstName",
  "contactPhone",
  "mailing-address",
  "mailingAddressCity",
  "mailingAddressState",
  "mailingAddressStreet",
  "mailingAddressZip",
];

export const statesExcludedFromProfessionalLiability = [
  "CO",
  "DC",
  "FL",
  "IL",
  "LA",
  "MA",
  "MN",
  "MT",
  "NH",
  "NY",
  "ND",
  "VA",
  "VT",
];
