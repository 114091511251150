import React from "react";
import styled from "styled-components";

const LocationsList = styled.ul`
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 15px;
  }
`;

interface PropTypes {
  locations: PolicyLocation[];
}

export default class Locations extends React.Component<PropTypes> {
  render(): React.ReactNode {
    const locations = this.props.locations.map((l, i) => {
      return (
        <li key={i}>
          <div>
            {l.street}, {l.city}, {l.state} {l.zip}
          </div>

          <div>
            <b>Type:</b> {l.locationType || "Unspecified"}
          </div>
          <div>
            <b>Building limit:</b> {l.buildingLimit ? l.buildingLimit : "n/a"}
          </div>
          <div>
            <b>BPP Limit:</b> {l.bppLimit ? l.bppLimit : "n/a"}
          </div>
        </li>
      );
    });

    return (
      <React.Fragment>
        {locations.length > 0 ? <LocationsList>{locations}</LocationsList> : <div>None</div>}
      </React.Fragment>
    );
  }
}
