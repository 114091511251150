import React from "react";
import styled from "styled-components";
import ToolTipIcon from "shared/ToolTipIcon";
import BlueCheckmark from "images/blue_checkbox.svg";
import GreenCheckmark from "images/green_checkbox.svg";
import { toastError } from "utils/toast";
import { isCanadaEnv } from "utils/environmentChecker";

interface PropTypes {
  checked: boolean;
  label?: string | React.ReactNode;
  name: string;
  handleClick: (argO: any) => void;
  required?: boolean;
  readOnly?: boolean;
  contentKey?: any;
  ariaLabel?: string;
  marginOverride?: string;
  rightAlignOverride?: boolean;
  hasError?: boolean;
  labelStyleOverride?: any;
  readOnlyAlertText?: string;
  id?: string;
  checkBoxStyleOverride?: any;
  containerStyleOverride?: any;
}

const Checkbox = ({
  handleClick,
  checked,
  label,
  name,
  required,
  readOnly,
  contentKey,
  ariaLabel,
  marginOverride,
  rightAlignOverride = false,
  hasError = false,
  labelStyleOverride,
  readOnlyAlertText,
  id,
  checkBoxStyleOverride,
  containerStyleOverride,
}: PropTypes) => (
  <Row rightAlignOverride={rightAlignOverride} style={{ ...containerStyleOverride }}>
    <InputCheckbox
      type="checkbox"
      checked={Boolean(checked)}
      aria-checked={Boolean(checked)}
      onChange={(arg0: any) => {
        if (readOnly && readOnlyAlertText) {
          toastError(readOnlyAlertText);
        } else {
          handleClick(arg0);
        }
      }}
      id={id ? id : `${name}-checkbox`}
      data-testid={`${name}-checkbox`}
      data-cy={`${name}-checkbox`}
      name={name}
      required={required}
      aria-disabled={readOnly}
      aria-label={ariaLabel}
      marginOverride={marginOverride}
      hasError={hasError}
      isReadOnly={readOnly}
      style={{ ...checkBoxStyleOverride }}
    />
    {label && (
      <Label htmlFor={id ? id : `${name}-checkbox`} hasError={hasError} labelStyleOverride={labelStyleOverride}>
        {label}
        {contentKey && <ToolTipIcon contentKey={contentKey} />}
      </Label>
    )}
  </Row>
);

export const Row = styled.div<{ rightAlignOverride?: boolean }>`
  display: flex;
  align-items: center;
  ${(props) => props.rightAlignOverride && "margin-left: auto;"}
`;

export const InputCheckbox = styled.input<{ marginOverride?: string; hasError: boolean; isReadOnly?: boolean }>`
  cursor: pointer;
  appearance: none;
  min-width: 24px;
  height: 24px;
  border: 1px solid ${(props) => (props.hasError ? props.theme.red : props.theme.checkboxBorder)};
  border-radius: 4px;
  background: ${(props) => props.theme.white};
  margin: ${(props) => props.marginOverride || " 16px 12px "};
  transition: 0.2s all ease;

  &:focus {
    outline-color: ${(props) => props.theme.blue};
    outline-style: dotted;
    outline-width: thin;
  }

  &:checked {
    border: 1px solid ${(props) => props.theme.blue};
    background-image: url(${isCanadaEnv() ? GreenCheckmark : BlueCheckmark});
    background-position: center;
  }

  ${(props) => props.isReadOnly && `{cursor: not-allowed;}`}
`;

const Label = styled.label<{ hasError: boolean; labelStyleOverride: any }>`
  color: ${(props) => (props.hasError ? props.theme.red : props.theme.gray)};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
  ${(props) => props.labelStyleOverride}
`;

export default Checkbox;
