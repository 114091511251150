import React from "react";
import { Text, View, Rect } from "@react-pdf/renderer";
import { Svg } from "utils/proposal-pdf/SvgPatch";
import { theme } from "styles";

interface CoverageInfoRowProps {
  title: string;
  subtitle?: string;
  info: string;
  showBottomLine?: boolean;
}

const CoverageInfoRow = ({ title, subtitle, info, showBottomLine = true }: CoverageInfoRowProps) => {
  return (
    <View>
      <View style={{ flexDirection: "row", marginVertical: 5 }}>
        <View style={styles.detailsTitle}>
          <Text style={styles.detailsText}>{title}</Text>
          {subtitle ? <Text style={styles.subDetailsText}>{subtitle}</Text> : null}
        </View>
        <View style={styles.limit}>
          <Text style={styles.detailsText}>{info}</Text>
        </View>
      </View>
      {showBottomLine && (
        <Svg style={styles.line}>
          <Rect x="" y="" width="1000px" height="1px" fill={styles.lightGray.color} />
        </Svg>
      )}
    </View>
  );
};

const styles = {
  detailsText: {
    fontSize: 11,
    fontFamily: theme.font.typeface.secondary,
    marginLeft: 6,
  },
  subDetailsText: {
    marginTop: 4,
    fontFamily: theme.font.typeface.secondary,
    fontSize: 9,
    marginLeft: 6,
    color: theme.colors.tertiary.eighty,
  },
  detailsTitle: {
    width: "72%",
  },
  limit: {
    width: "28%",
  },
  line: {
    height: "1px",
    marginBottom: "5px",
    marginTop: "5px",
    marginLeft: 6,
  },
  lightGray: {
    color: theme.colors.neutral.light,
  },
};

export default CoverageInfoRow;
