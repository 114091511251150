import React from "react";
import ReactTable, { RowInfo } from "react-table";
import "react-table/react-table.css";
import styled from "styled-components";
import { faDownload, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { formatCurrency } from "utils/formatNumber";
import formatDateTime from "utils/formatDateTime";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface PropTypes {
  charges: PolicyChargeReportItem[];
  onRefresh?: () => void;
  defaultPageSize?: number;
  isLoading?: boolean;
}

const columns = [
  {
    Header: "Policy Number",
    accessor: "policyNumber",
    style: { textAlign: "center" },
    width: 170,
    Cell: (props: any) => <Link to={`/policies/${props.value}`}>{props.value}</Link>,
  },
  {
    Header: "Date",
    accessor: "chargeDate",
    width: 220,
    style: { textAlign: "center" },
    Cell: (props: any) => <span>{formatDateTime(new Date(props.value))}</span>,
  },
  {
    Header: "Updated On",
    accessor: "chargeStatusDate",
    width: 220,
    style: { textAlign: "center" },
    Cell: (props: any) => <span>{formatDateTime(new Date(props.value))}</span>,
  },
  {
    Header: "Status",
    accessor: "chargeStatus",
    width: 220,
    style: { textAlign: "center" },
    Cell: (props: any) => (
      <div>
        <Status>{props.value}</Status>
        {props.original.chargeErrorMessage && (
          <ChargeErrorMessage>{props.original.chargeErrorMessage}</ChargeErrorMessage>
        )}
      </div>
    ),
  },
  {
    Header: "Premium",
    accessor: "chargeAmount",
    style: { textAlign: "center" },
    Cell: (props: any) => {
      return <span data-cy="charge-amount">{formatCurrency(props.value)}</span>;
    },
  },
  {
    Header: "Fee",
    accessor: "transactionFeeAmount",
    style: { textAlign: "center" },
    Cell: (props: any) => <span data-cy="fee-amount">{formatCurrency(props.value || 0)}</span>,
  },
  {
    Header: "Total",
    id: "total",
    style: { textAlign: "center" },
    Cell: ({ original }: any) => {
      const { transactionFeeAmount, chargeAmount } = original;
      return <span data-cy="total-amount">{formatCurrency(chargeAmount + (transactionFeeAmount || 0))}</span>;
    },
  },
  {
    Header: "Type",
    id: "type",
    style: { textAlign: "center" },
    Cell: ({ original }: any) => {
      const { isOneTimePayment } = original;
      return <span data-cy="type">{isOneTimePayment ? "OTP" : "Installment"}</span>;
    },
  },
  { Header: "Transaction ID", accessor: "chargeTransactionId", width: 250, style: { textAlign: "center" } },
  { Header: "Fee ID", accessor: "transactionFeeId", width: 250, style: { textAlign: "center" } },
  {
    Header: "Account",
    accessor: "accountId",
    width: 120,
    style: { textAlign: "center" },
    Cell: (props: any) => (
      <Link to={`/accounts/${props.value}`} data-testid="account">
        {props.value}
      </Link>
    ),
  },
  {
    Header: "Payer Account",
    accessor: "payerAccountId",
    style: { textAlign: "center" },
    width: 120,
    Cell: (props: any) => (
      <Link to={`/accounts/${props.value}`} data-testid="payer-account">
        {props.value}
      </Link>
    ),
  },
  { Header: "Policy Status", accessor: "status", style: { textAlign: "center" } },
];

const ChargeReportTable = ({ charges = [], onRefresh, defaultPageSize, isLoading }: PropTypes) => {
  return (
    <Container data-cy="charges-table">
      <ReactTable
        data={charges}
        columns={columns}
        getTrProps={(state: unknown, rowInfo: unknown) => {
          const rowItem = rowInfo as RowInfo;
          const charge = rowItem?.original as PolicyChargeReportItem;
          return {
            "data-testid": charge?.chargeTransactionId,
          };
        }}
        defaultPageSize={defaultPageSize || 10}
        defaultSorted={[{ id: "chargeDate", desc: true }]}
        noDataText={isLoading ? "Loading..." : "No charges found"}
      />
      <Actions>
        {onRefresh && (
          <button onClick={onRefresh}>
            <FontAwesomeIcon icon={faSync as IconProp} style={{ marginRight: "0.5em" }} />
            Refresh
          </button>
        )}
        {(charges || []).length > 0 && (
          <CSVLink data={charges}>
            <FontAwesomeIcon icon={faDownload as IconProp} style={{ marginRight: "0.3em" }} /> Download CSV
          </CSVLink>
        )}
      </Actions>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 50px;
`;

const Actions = styled.div`
  margin: 1em 0;
  text-align: right;
  a,
  .btn-link,
  svg {
    margin-left: 0.5em;
  }
`;

const Status = styled.div`
  display: inline;
`;

const ChargeErrorMessage = styled.div`
  color: #900;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default ChargeReportTable;
