import React from "react";
import Loader from "react-loader-spinner";
import { theme } from "styles";
import styled from "styled-components";

interface PropsTypes {
  color?: string;
  height?: number;
  width?: number;
  styles?: string;
}

const AjaxLoader = ({ color = theme.blue, height = 32, width = 65, styles = "" }: PropsTypes) => (
  <Container styles={styles}>
    <Loader type="ThreeDots" color={color} height={height} width={width} />
  </Container>
);

const Container = styled.div<{ styles?: string }>`
  ${({ styles }) => styles}
`;

export default AjaxLoader;
