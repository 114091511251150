import React, { useState } from "react";
import styled from "styled-components";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { tablet } from "./responsive";

interface PropTypes {
  actions: ActionsType[];
  policyView?: boolean;
}

const ActionsDropdown = ({ actions, policyView = false }: PropTypes) => {
  const [toggle, updateToggle] = useState(false);

  const handleToggle = () => {
    updateToggle(!toggle);
  };

  return (
    <Container policyView={Boolean(policyView)}>
      <Dropdown isOpen={toggle} toggle={handleToggle}>
        <DropdownToggle data-cy="title-bar-dropdown-toggle" caret>
          Actions
        </DropdownToggle>
        <DropdownMenu right>
          {actions
            .filter((item: ActionsType) => !item.hide)
            .map((item: ActionsType, i: number) => (
              <Item key={i} data-cy={`dropdown-item-${i}`} onClick={item.action}>
                {item.name}
              </Item>
            ))}
        </DropdownMenu>
      </Dropdown>
    </Container>
  );
};

const Container = styled.div<{ policyView: boolean }>`
  margin-left: ${({ policyView }) => (policyView ? "16px" : "auto")};
  .dropdown {
    margin-top: 0.5em;
    margin-left: auto;
    ${tablet} {
      margin: ${({ policyView }) => (policyView ? "8px 0px" : "16px 0px")};
    }
    ${({ policyView }) => policyView && `margin: 8px 0;`}
  }

  .dropdown .btn {
    background-color: ${(props) => props.theme.green};
    border-radius: 23px;
    padding: 10px 20px;
    border: none;
    color: ${(props) => props.theme.bgDarkBlue};
    font-family: ${(props) => props.theme.primaryFont};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;

    &:hover,
    &:active {
      background-color: ${(props) => props.theme.buttonHover};
      color: ${(props) => props.theme.buttonTextHover};
      text-decoration: none;
    }
  }
`;

const Item = styled(DropdownItem)`
  color: ${(props) => props.theme.dropdownItem};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
`;

export default ActionsDropdown;
