import React, { useState } from "react";
import ReactTable, { CellInfo } from "react-table";
import "react-table/react-table.css";
import styled from "styled-components";
import { faDownload, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
import { Link, useLocation } from "react-router-dom";
import formatDateTime from "utils/formatDateTime";
import formatDate from "utils/formatDate";
import DetachPolicyModal from "./account/DetachPolicyModal";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import { formatBackendPolicyDate } from "utils/formatBackendPolicyDate";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface PropTypes {
  policies: Policy[];
  onRefresh?: () => void;
  defaultPageSize?: number;
  isLoading?: boolean;
  highlightMultiDayRows?: boolean;
}

const csvHeaders = [
  { label: "Policy Number", key: "number" },
  { label: "Type", key: "type" },
  { label: "Status", key: "status" },
  { label: "Start Date", key: "startDate" },
  { label: "End Date", key: "endDate" },
  { label: "Days Open", key: "daysOpen" },
  { label: "Auto Extend", key: "autoExtend" },
  { label: "Premium", key: "premium" },
  { label: "Premium Paid", key: "premiumPaid" },
  { label: "Next Payment Date", key: "nextPaymentDate" },
  { label: "Business Name", key: "businessName" },
  { label: "Account Id", key: "accountId" },
  { label: "Payer Account Id", key: "payerAccountId" },
  { label: "Metadata", key: "metadata" },
  { label: "Project Id", key: "projectId" },
  { label: "Project Address", key: "projectAddress" },
  { label: "Project City", key: "projectCity" },
  { label: "Project State", key: "projectState" },
  { label: "Project Zip", key: "projectZip" },
  { label: "Detach", key: "detach" },
];

const PolicyReportTable = ({
  policies = [],
  onRefresh,
  defaultPageSize,
  isLoading,
  highlightMultiDayRows,
}: PropTypes) => {
  const { showDetachAccount } = useFeatureFlags();
  const [detachPolicy, setDetachPolicy] = useState<Policy | null>(null);
  const { pathname } = useLocation();
  const isAccountsView = new RegExp("/accounts/\\d+$").test(pathname);

  const columns = [
    {
      Header: "Policy Number",
      accessor: "policyNumber",
      width: 170,
      Cell: (props: any) => (
        <Link data-cy="link-to-policy" to={`/policies/${props.value}`}>
          {props.value}
        </Link>
      ),
    },
    { Header: "Policy Type", accessor: "policyType" },
    { Header: "Policy Status", accessor: "status" },
    {
      Header: "Policy Start Date",
      accessor: "startDate",
      width: 180,
      style: { textAlign: "center" },
      Cell: (props: any) => <span>{formatDate(formatBackendPolicyDate(new Date(props.value)))}</span>,
    },
    {
      Header: "Policy End Date",
      accessor: "endDate",
      width: 180,
      style: { textAlign: "center" },
      Cell: (props: any) => <span>{formatDate(formatBackendPolicyDate(new Date(props.value)))}</span>,
    },
    {
      Header: "Days Open",
      accessor: "daysOpen",
      width: 100,
      style: { textAlign: "right" },
    },
    {
      Header: "Auto Extend",
      id: "autoExtend",
      style: { textAlign: "center" },
      accessor: (x: Policy) => (x.autoExtend ? "Yes" : "No"),
    },
    {
      Header: "Premium",
      id: "premium",
      style: { textAlign: "right" },
      accessor: (x: Policy) => `$ ${x.premium.toFixed(2)}`,
    },
    {
      Header: "Premium Paid",
      id: "premiumPaid",
      style: { textAlign: "right" },
      accessor: (x: Policy) => `$ ${x.premiumPaid.toFixed(2)}`,
    },
    {
      Header: "Next Payment Date",
      accessor: "nextPaymentDate",
      width: 180,
      style: { textAlign: "center" },
      Cell: (props: any) => <span>{props.value ? formatDateTime(new Date(props.value)) : ""}</span>,
    },
    { Header: "Business Name", accessor: "businessName", width: 200 },
    {
      Header: "Account ID",
      accessor: "accountId",
      width: 120,
      Cell: (props: any) => <Link to={`/accounts/${props.value}`}>{props.value}</Link>,
    },
    {
      Header: "Payer Account ID",
      accessor: "payerAccountId",
      width: 170,
      Cell: (props: any) => <Link to={`/accounts/${props.value}`}>{props.value}</Link>,
    },
    {
      Header: "Project Address",
      id: "projectStreet",
      accessor: (p: Policy) =>
        !p.endorsements || !p.endorsements.projectBasedCoverage ? null : p.endorsements.projectBasedCoverage.street,
      width: 250,
    },
    {
      Header: "Project City",
      id: "projectCity",
      accessor: (p: Policy) =>
        !p.endorsements || !p.endorsements.projectBasedCoverage ? null : p.endorsements.projectBasedCoverage.city,
      width: 200,
    },
    {
      Header: "Project State",
      id: "projectState",
      accessor: (p: Policy) =>
        !p.endorsements || !p.endorsements.projectBasedCoverage ? null : p.endorsements.projectBasedCoverage.state,
      width: 120,
    },
    {
      Header: "Project Zip",
      id: "projectZip",
      accessor: (p: Policy) =>
        !p.endorsements || !p.endorsements.projectBasedCoverage ? null : p.endorsements.projectBasedCoverage.zip,
      width: 120,
    },
    {
      Header: "Project ID",
      id: "projectId",
      accessor: (p: Policy) =>
        !p.endorsements || !p.endorsements.projectBasedCoverage ? null : p.endorsements.projectBasedCoverage.projectId,
      width: 300,
    },
    {
      Header: "Metadata",
      id: "metadata",
      accessor: (p: Policy) => p.metadata,
      width: 300,
    },
    {
      Header: "Detach",
      accessor: "policyNumber",
      width: 170,
      show: showDetachAccount && isAccountsView,
      Cell: (props: CellInfo) => {
        const { value, row, original: policy } = props;
        const isPolicyHolderPay = row.accountId === row.payerAccountId;

        if (isPolicyHolderPay) {
          return (
            <button data-cy={`detach-${value}`} data-testid={`detach-${value}`} onClick={() => setDetachPolicy(policy)}>
              Detach
            </button>
          );
        } else {
          return null;
        }
      },
    },
  ];

  const getFilename = (): string => {
    return `Coterie_OpenPolicyReport_${new Date().toLocaleDateString()}`;
  };

  const getHighlightedTrProps = (_state: any, rowInfo: any) => {
    if (typeof rowInfo !== "undefined") {
      if (rowInfo.row.daysOpen > 3) return { style: { background: "#f99" } };
      if (rowInfo.row.daysOpen > 2) return { style: { background: "#fc9" } };
      if (rowInfo.row.daysOpen > 1) return { style: { background: "#feb" } };
    }
    return {};
  };

  const mapPolicies = policies.map((p) => {
    return {
      number: p.policyNumber,
      type: p.policyType,
      status: p.status,
      startDate: formatBackendPolicyDate(new Date(p.startDate)),
      endDate: formatBackendPolicyDate(new Date(p.endDate)),
      daysOpen: p.daysOpen,
      autoExtend: p.autoExtend ? "Yes" : "No",
      premium: p.premium,
      premiumPaid: p.premiumPaid,
      nextPaymentDate: p.nextPaymentDate ? formatDateTime(new Date(p.nextPaymentDate)) : null,
      businessName: p.businessName,
      accountId: p.accountId,
      payerAccountId: p.payerAccountId,
      metadata: p.metadata,
      projectId:
        !p.endorsements || !p.endorsements.projectBasedCoverage ? null : p.endorsements.projectBasedCoverage.projectId,
      projectAddress:
        !p.endorsements || !p.endorsements.projectBasedCoverage ? null : p.endorsements.projectBasedCoverage.street,
      projectCity:
        !p.endorsements || !p.endorsements.projectBasedCoverage ? null : p.endorsements.projectBasedCoverage.city,
      projectState:
        !p.endorsements || !p.endorsements.projectBasedCoverage ? null : p.endorsements.projectBasedCoverage.state,
      projectZip:
        !p.endorsements || !p.endorsements.projectBasedCoverage ? null : p.endorsements.projectBasedCoverage.zip,
    };
  });

  return (
    <>
      <Container>
        <ReactTable
          data={policies}
          columns={columns}
          defaultPageSize={defaultPageSize || 15}
          defaultSorted={[{ id: "policyNumber", desc: false }]}
          noDataText={isLoading ? "Loading..." : "No policies found"}
          getTrProps={highlightMultiDayRows ? getHighlightedTrProps : undefined}
        />
        <Actions>
          {onRefresh && (
            <button onClick={onRefresh}>
              <FontAwesomeIcon icon={faSync as IconProp} style={{ marginRight: "0.5em" }} />
              Refresh
            </button>
          )}
          {policies.length > 0 && (
            <CSVLink data={mapPolicies} headers={csvHeaders} filename={getFilename()}>
              <FontAwesomeIcon icon={faDownload as IconProp} style={{ marginRight: "0.3em" }} /> Download CSV
            </CSVLink>
          )}
        </Actions>
      </Container>
      {detachPolicy && isAccountsView && (
        <DetachPolicyModal isOpen={Boolean(detachPolicy)} policy={detachPolicy} onClose={() => setDetachPolicy(null)} />
      )}
    </>
  );
};

const Container = styled.div`
  margin-top: 50px;
`;

const Actions = styled.div`
  margin: 1em 0;
  text-align: right;
  a,
  .btn-link,
  svg {
    margin-left: 0.5em;
  }
`;

export default PolicyReportTable;
