import { useQuery } from "@tanstack/react-query";
import useAuth from "./useAuth";
import Api from "utils/api";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const useGetPolicyDocuments = (policyNumber: string) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const getPolicyDocuments = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    // TODO look up other similiar methods
    return api.getPolicyDocumentList(policyNumber, apiVersion);
  };

  return useQuery({
    queryKey: ["commissions", policyNumber],
    queryFn: getPolicyDocuments,
    select: (res) => res,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export default useGetPolicyDocuments;
