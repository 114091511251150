import React, { useState, useEffect, useCallback } from "react";
import styled, { createGlobalStyle } from "styled-components";
import debounce from "lodash.debounce";
import Api from "utils/api";
import { SuggestionsContainer, SuggestionsList, NoMatch } from "./StyledComponents";
import { Row } from "shared/ModalComponents";
import Checkbox from "styles/Checkbox";
import { Dropdown, TextLink, Input } from "styles";
import { StyledSelect } from "styles/Dropdown";
import { ParagraphSmall } from "styles/paragraph";
import { stateAbbrevOptions } from "utils/constants";
import useAuth from "hooks/useAuth";
import { ReactComponent as LocationPinIcon } from "images/location_pin.svg";
import { ReactComponent as CheckmarkIcon } from "images/checkmark_securus.svg";
import useVerifyAddressStateAndZip from "hooks/useVerifyAddressStateAndZip";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  changeAddress: (address: Address, isVerified: boolean, isManualAddressEntry: boolean) => void;
  street?: string;
  streetLineTwo?: string;
  city?: string;
  state?: string;
  zip?: string;
  name: string;
  invalid?: boolean;
  hideManualAddressButton?: boolean;
  alreadyEnteredAddress?: any;
  isSimplybind?: boolean;
  readOnly?: boolean;
  shouldUpdateAddressOnChange?: boolean;
  id?: string;
}

const errorMessages = {
  invalidAddress: "Invalid address.",
  missingSuite:
    "Looks like you need to add a suite number or enter the address manually. Try something like “3214 N Jefferson Ave, Ste 100, New York NY 10001”",
  noVerify: "Please double check. We can’t verify this state and zipcode combination.",
};

const AddressSelector = (props: PropTypes) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const [suggestions, setSuggestions] = useState<any>([]);
  const [verified, setVerified] = useState(false);
  const [addressString, setAddressString] = useState("");
  const [errorName, setErrorName] = useState("");

  const [displayManualAddress, setDisplayManualAddress] = useState(false);

  const [address, setAddress] = useState({
    street: props.street || "",
    streetLineTwo: props.streetLineTwo || "",
    city: props.city || "",
    state: props.state || "",
    zip: props.zip || "",
  });
  const [useExistingAddress, updateUseExistingAddress] = useState(Boolean(props.alreadyEnteredAddress));
  const [useExistingAddressCheckbox, updateUseExistingAddressCheckbox] = useState(false);
  const allAddressValuesPresent = Object.values(address).every(Boolean);

  const streetAddressDisplayName = `${props.street}${props?.streetLineTwo ? ` ${props?.streetLineTwo}` : ""} ${
    props.city
  }, ${props.state} ${props.zip}`;

  useEffect(() => {
    if (props.street && props.city && props.state && props.zip) {
      setAddressString(streetAddressDisplayName);
    }
  }, [props.street, props.city, props.state, props.zip]);

  const debounceAddressUpdate = useCallback(
    debounce((address, isVerified) => {
      props.changeAddress(address, isVerified, displayManualAddress);
    }, 300),
    []
  );

  const debounceVerifyStateAndZip = useCallback(
    debounce(() => {
      verifyAddressStateAndZip();
    }, 400),
    []
  );

  useEffect(() => {
    if (displayManualAddress) {
      debounceVerifyStateAndZip();
    }
    if (props.shouldUpdateAddressOnChange)
      debounceAddressUpdate(address, props.isSimplybind ? allAddressValuesPresent : true);
  }, [address]);

  const showAddressWithEntries = (address: any) => {
    const hasEntries = Number(address.entries) > 1;
    const hasOneEntry = Number(address.entries) === 1;

    if (hasEntries) {
      return `${address.street} ${address.secondary} (${address.entries} more entries), ${address.city} ${address.state} ${address.zipCode}`;
    } else if (hasOneEntry) {
      return `${address.street} ${address.secondary}, ${address.city} ${address.state} ${address.zipCode}`;
    } else return `${address.street}, ${address.city} ${address.state} ${address.zipCode}`;
  };

  const handleSelectAddress = async (value: any) => {
    if (Number(value?.entries) > 1) {
      const token = await getToken();
      const api = new Api(apiVersion, token);
      const addressEntries = await api.address.fetchEntries(value);
      setSuggestions(addressEntries);
    } else {
      const address = `${value.street} ${value.secondary}, ${value.city} ${value.state} ${value.zipCode}`;
      const token = await getToken();
      const api = new Api(apiVersion, token);
      const fetchVerifiedAddress = await api.address.verify(address);
      const verifiedAddress = JSON.stringify(fetchVerifiedAddress);
      if (verifiedAddress === "{}" || verifiedAddress === '""') {
        setSuggestions([]);
        setVerified(false);
        setAddressString(address);
        setErrorName("invalidAddress");
        props.changeAddress({}, false, displayManualAddress);
      } else {
        const addressObject = JSON.parse(verifiedAddress);
        const { result } = addressObject;
        if (result.analysis.dpv_match_code === "D") {
          setErrorName("missingSuite");
          setVerified(false);
          setAddress(addressObject);
          setSuggestions([]);
        } else {
          setAddress(addressObject);
          setAddressString(
            `${addressObject.street} ${addressObject.city}, ${addressObject.state} ${addressObject.zip}`
          );
          setSuggestions([]);
          setVerified(true);
          setErrorName("");
          await props.changeAddress(addressObject, true, displayManualAddress);
        }
      }
    }
  };

  const debounceFetchAddressSuggestions = useCallback(
    debounce(async (address: string) => {
      setAddressString(address);
      setErrorName("");
      if (address) {
        const token = await getToken();
        const api = new Api(apiVersion, token);
        return api.address
          .typeAhead(address)
          .then((addressSuggestions) => {
            setSuggestions(addressSuggestions.slice(0, 5) || []);
          })
          .catch((err) => {
            console.log(err);
          });
      } else setSuggestions([]);
    }, 300),
    []
  );

  const handleChange = (e: any) => {
    let value = e.target.value;
    setAddressString(value);
    setVerified(false);
    props.changeAddress({}, false, displayManualAddress);
    debounceFetchAddressSuggestions(value);

    if (value === "") {
      props.changeAddress(
        { street: "", city: "", state: props.state ?? "", zip: props.zip ?? "" },
        !props.isSimplybind,
        displayManualAddress
      );
    }
  };

  const checkIsAnyEmptyString = (args: string[]) => {
    // removing the second value that is "streetLineTwo" which can be empty
    args.splice(1, 1);
    return args.filter((e) => typeof e === "string").some((a) => !a.trim());
  };

  const changeAddressWithEmptyStringCheckForManualAddress = (address: Address) => {
    if (checkIsAnyEmptyString(Object.values(address))) {
      props.changeAddress(address, false, displayManualAddress);
    } else {
      props.changeAddress(address, true, displayManualAddress);
    }
  };

  const toggleManualAddress = () => {
    if (displayManualAddress) {
      if (!verified) {
        props.changeAddress({}, false, displayManualAddress);
        setAddressString("");
        setErrorName("");
      }
    } else {
      setVerified(false);
      verifyAddressStateAndZip();
      changeAddressWithEmptyStringCheckForManualAddress(address);
    }
    setDisplayManualAddress(!displayManualAddress);
  };

  const handleStateChange = (state: any) => {
    let newState = Object.assign({}, address, { state: state.value });
    setAddress(newState);
    if (props.isSimplybind) {
      changeAddressWithEmptyStringCheckForManualAddress(newState);
    } else {
      props.changeAddress(newState, true, displayManualAddress);
    }
  };

  const handleManualChange = (event: React.FormEvent<HTMLInputElement>) => {
    let { name, value } = event.currentTarget;
    let newState = Object.assign({}, address, { [name]: value });
    setAddress(newState);
  };

  const handleManualAddressBlur = () => {
    if (props.isSimplybind) {
      changeAddressWithEmptyStringCheckForManualAddress(address);
    } else {
      props.changeAddress(address, true, displayManualAddress);
    }
  };

  const { refetch: verifyAddressStateAndZip, isError: stateZipAreInvalid, error: verifyAddressError, isSuccess: verifyAddressSuccess } = useVerifyAddressStateAndZip({
    state: address.state,
    zip: address.zip,
  });

  useEffect(() => {
    if (verifyAddressError) {
      setErrorName("noVerify");
    }
  }, [verifyAddressError]);

  useEffect(() => {
    if (verifyAddressSuccess) {
      setErrorName("");
    }
  }, [verifyAddressSuccess]);

  const alreadyEnteredAddressLabel = props.name === "mailing" ? "business" : "mailing";
  const showAlreadyEnteredAddressCheckbox = !addressString && props.alreadyEnteredAddress?.street && useExistingAddress;
  if (showAlreadyEnteredAddressCheckbox) {
    const { street, city, state, zip } = props.alreadyEnteredAddress;
    return (
      <>
        <p>
          Select {alreadyEnteredAddressLabel} address already entered or
          <TextLink
            onClick={() => updateUseExistingAddress(!useExistingAddress)}
            data-cy="enter-different-business-address"
          >
            enter a different {props.name} address
          </TextLink>
        </p>
        <Checkbox
          label={`${street} ${city}, ${state} ${zip}`}
          name="useExistingAddress"
          handleClick={() => {
            props.changeAddress(props.alreadyEnteredAddress, true, displayManualAddress);
            updateUseExistingAddress(!useExistingAddress);
            updateUseExistingAddressCheckbox(true);
          }}
          checked={useExistingAddressCheckbox}
        />
      </>
    );
  }

  return (
    <div className="addressSelector">
      {displayManualAddress !== true && (
        <>
          <InputWrapper readOnly={props.readOnly}>
            <Input
              datacy={`${props.name}-address-input`}
              data-testid={`${props.name}-address`}
              value={addressString}
              handleChange={handleChange}
              type="text"
              placeholder="e.g. “3214 N Jefferson Ave Suite 2, New York NY 10001”"
              id={props.id}
              name={`${props.name}-address`}
              error={props.invalid}
              errorText="Address is required"
              inputStyles={{ marginBottom: "10px" }}
              readOnly={props.readOnly}
            />
            {verified && (
              <Verified>
                <CheckmarkIcon title="verified" />
              </Verified>
            )}
          </InputWrapper>
          {Boolean(errorName) && (
            <StyledNoMatch data-pendotag={`${errorName}-error`}>
              {errorMessages[errorName as keyof typeof errorMessages]}
            </StyledNoMatch>
          )}

          {suggestions.length > 0 && (
            <SuggestionsContainer className="suggestions">
              {suggestions.map((suggestion: any) => (
                <SuggestionsList
                  data-cy={"address-suggestion"}
                  key={`${suggestion.street} ${suggestion.secondary} ${suggestion.city} ${suggestion.state} ${suggestion.zipCode}`}
                  onClick={() => handleSelectAddress(suggestion)}
                >
                  <StyledIcon>
                    <LocationPinIcon title="Location-Pin-Icon" transform="translate(-6,-2)" />
                  </StyledIcon>
                  {showAddressWithEntries(suggestion)}
                </SuggestionsList>
              ))}

              {!props.hideManualAddressButton && (
                <SuggestionsManualAddressButtonContainer>
                  <SuggestionsManualAddressButton
                    type="button"
                    data-testid="manual-address"
                    data-cy="manual-address"
                    onClick={toggleManualAddress}
                  >
                    Can’t find the address? Enter it manually
                  </SuggestionsManualAddressButton>
                </SuggestionsManualAddressButtonContainer>
              )}
            </SuggestionsContainer>
          )}
        </>
      )}

      {!displayManualAddress && !verified && !props.readOnly && !props.alreadyEnteredAddress && (
        <StyledParagraphSmall>Begin typing for a verified USPS suggested address</StyledParagraphSmall>
      )}

      {!props.hideManualAddressButton && !displayManualAddress && (
        <ManualAddressButton
          type="button"
          data-testid="manual-address"
          data-cy="manual-address"
          onClick={toggleManualAddress}
        >
          Enter address manually
        </ManualAddressButton>
      )}

      {props.alreadyEnteredAddress?.street && !addressString && (
        <TextLink onClick={() => props.changeAddress(props.alreadyEnteredAddress, true, displayManualAddress)}>
          Select {alreadyEnteredAddressLabel} address already entered
        </TextLink>
      )}

      {displayManualAddress && (
        <>
          <Row>
            <ManualAddressInputContainer widthPercentage="60%">
              <Input
                label="Street Address"
                name="street"
                type="text"
                value={address.street}
                placeholder="e.g. “3214 N Jefferson Ave”"
                handleChange={handleManualChange}
                handleBlur={handleManualAddressBlur}
                labelStyles={{ fontSize: 12 }}
                inputStyles={{ marginBottom: 16 }}
              />
            </ManualAddressInputContainer>
            <ManualAddressInputContainer widthPercentage="40%">
              <Input
                label="Address 2"
                name="streetLineTwo"
                type="text"
                value={address.streetLineTwo}
                placeholder="e.g. “Ste 100”"
                handleChange={handleManualChange}
                handleBlur={handleManualAddressBlur}
                labelStyles={{ fontSize: 12 }}
                inputStyles={{ marginBottom: 16 }}
              />
            </ManualAddressInputContainer>
          </Row>
          <ManualAddressInputContainer>
            <Input
              label="City"
              name="city"
              type="text"
              value={address.city}
              handleChange={handleManualChange}
              handleBlur={handleManualAddressBlur}
              labelStyles={{ fontSize: 12 }}
              inputStyles={{ marginBottom: 16 }}
            />
          </ManualAddressInputContainer>

          <Row id="manual-address-state-zip">
            <OverrideStyle />
            <StateDropdownContainer>
              <Dropdown
                onChange={handleStateChange}
                options={stateAbbrevOptions}
                value={address.state || ""}
                label="State"
                name="state"
                labelStyles={{ fontSize: "12px" }}
                full
                error={stateZipAreInvalid}
              />
            </StateDropdownContainer>
            <ManualAddressInputContainer widthPercentage="40%">
              <Input
                label="Zip Code"
                name="zip"
                type="text"
                value={address.zip}
                handleChange={handleManualChange}
                handleBlur={handleManualAddressBlur}
                labelStyles={{ fontSize: 12 }}
                inputStyles={{ marginBottom: 16 }}
                error={stateZipAreInvalid}
              />
            </ManualAddressInputContainer>
          </Row>
          {Boolean(errorName) && (
            <StyledNoMatch data-pendotag={`${errorName}-error`}>
              {errorMessages[errorName as keyof typeof errorMessages]}
            </StyledNoMatch>
          )}
          <ManualAddressButton
            type="button"
            data-testid="manual-address"
            data-cy="manual-address"
            onClick={toggleManualAddress}
          >
            Go back to address autofill
          </ManualAddressButton>
        </>
      )}
    </div>
  );
};

const InputWrapper = styled.div<{ readOnly?: boolean }>`
  position: relative;
  max-width: 600px;
  ${(props) => props.readOnly && `cursor: not-allowed;`}
`;

const Verified = styled.p`
  svg {
    fill: ${(props) => props.theme.blue};
  }
  font-weight: bold;
  position: absolute;
  top: 12px;
  right: 16px;
`;

const StyledNoMatch = styled(NoMatch)`
  letter-spacing: 0.75;
  padding-left: 0;
  margin-bottom: 5px;
`;

const ManualAddressButton = styled(TextLink)`
  padding-left: 0px;
  margin-bottom: 22px;
`;

const SuggestionsManualAddressButtonContainer = styled.div`
  padding: 0px 8px;
  background-color: ${(props) => props.theme.offWhite};
  border-radius: 4px;
  position: sticky;
  bottom: 0;
`;

const SuggestionsManualAddressButton = styled(TextLink)`
  margin: 8px 0;
`;

const StyledParagraphSmall = styled(ParagraphSmall)`
  color: ${(props) => props.theme.textNavyBlue};
  font-family: ${(props) => props.theme.primaryFont};
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 8px;
`;

const StyledIcon = styled.span`
  path {
    fill: ${(props) => props.theme.regularGray};
  }
`;

const OverrideStyle = createGlobalStyle`
#manual-address-state-zip {
  ${StyledSelect} {
    margin-bottom: 0;
  }
}
`;

const ManualAddressInputContainer = styled.div<{ widthPercentage?: string }>`
  width: ${(props) => props.widthPercentage ?? "100%"};
`;

const StateDropdownContainer = styled.div`
  width: 60%;
  z-index: 2;
`;

export default AddressSelector;
