import React, { useState } from "react";
import Api from "utils/api";
import { Input } from "reactstrap";
import Label from "reactstrap/lib/Label";
import Button from "reactstrap/lib/Button";
import Alert from "reactstrap/lib/Alert";
import styled from "styled-components";
import AjaxLoader from "shared/AjaxLoader";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface RequestType {
  apiKey: string;
  policyNumber: string;
}

const fixeeKey = "35DC3D60-0559-4698-BA13-5AD7EEA34019";

const PolicyEnder = () => {
  const { getToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [request, setRequest] = useState<RequestType>({ apiKey: fixeeKey, policyNumber: "" });
  const [result, setResult] = useState(null);
  const { apiVersion } = useFeatureFlags();

  const onEndPolicyClick = () => {
    setLoading(true);
    endPolicy();
  };

  const endPolicy = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);
    api
      .endPolicy(request.policyNumber, request.apiKey)
      .then((result: any) => {
        setLoading(false);
        setResult(result);
        setError(null);
      })
      .catch((error: any) => {
        setLoading(false);
        setResult(null);
        setError(error);
      });
  };

  const onRequestFieldChange = (e: any) => {
    setRequest({ ...request, [e.target.name]: e.target.value });
  };

  const renderInputField = (name: keyof RequestType) => (
    <div className={"input"}>
      <Label>{name}</Label>
      <Input name={name} value={request[name]} onChange={onRequestFieldChange}></Input>
    </div>
  );

  return (
    <div>
      <RequestContainer>
        <RequestFieldGroup>
          {renderInputField("apiKey")}
          {renderInputField("policyNumber")}
        </RequestFieldGroup>
      </RequestContainer>
      <div style={{ paddingTop: "1em" }}>
        <Button onClick={onEndPolicyClick} color={"danger"}>
          End Policy
        </Button>
        {loading && <AjaxLoader />}
      </div>
      {result && (
        <div style={{ paddingTop: "1em" }}>
          <Alert color={"success"} style={{ whiteSpace: "pre", fontFamily: "courier" }}>
            {JSON.stringify(result, null, 2)}
          </Alert>
        </div>
      )}
      {error && (
        <div style={{ paddingTop: "1em" }}>
          <Alert color={"danger"} style={{ whiteSpace: "pre", fontFamily: "courier" }}>
            {JSON.stringify(error, null, 2)}
          </Alert>
        </div>
      )}
    </div>
  );
};

const RequestContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RequestFieldGroup = styled.div`
  flex: 1 1 33%;
  max-width: 33%;
  padding: 5px;
  .input {
    padding-bottom: 0.5em;
  }
  label {
    font-weight: bold;
    margin: 0;
  }
`;

export default PolicyEnder;
