import { toastError } from "./toast";
import { ErrorToReport, reportToSentry, SentryContext, SentryTag } from "./sentry";
import axios, { AxiosError } from "axios";

export enum PREFIX {
  GENERIC = "ERR",
  INDUSTRY_SEARCH = "IND",
  STRIPE = "STR",
  AIRKIT = "AKT",
}

export const TAGS = {
  INDUSTRY_SEARCH: { key: "component", value: "industry-select" },
  STRIPE: { key: "component", value: "credit card stripe" },
  AIRKIT: { key: "component", value: "airkit" },
};

export const getReferenceCodeTag = (refCode: string) => ({ key: "reference code", value: refCode });

export const getReferenceCode = (prefix?: string) => {
  const blockOne = Math.random().toString().slice(2, 5);
  const blockTwo = Math.random().toString().slice(2, 5);

  return `${prefix || ""} ${blockOne} ${blockTwo}`;
};

const getErrorMessage = (error: ErrorToReport, fallbackErrorMsg: string) => {
  const isNetworkError = axios.isAxiosError(error);

  if (isNetworkError) {
    const errorResponse = (error as AxiosError)?.response?.data as any;
    const networkErrorMsg = errorResponse?.messages?.[0]?.message || errorResponse?.message;

    return networkErrorMsg || fallbackErrorMsg;
  } else {
    return fallbackErrorMsg;
  }
};

type ReportOptions = {
  forceMsgOverride?: boolean;
  skipToast?: boolean;
};

/**
 * Creates a reportError func with custom error message
 * @arg fallbackErrorMsg @type {string} - error message that will be displayed to the user; by default, if the error is an AxiosError from a failed network response, the message from the error response will be rendered instead
 * @arg referenceCodePrefix @type {PREFIX} - Prefix tag to be used in the error reference code, e.g. ERR
 * @arg tag @type {StripeTag|StripeTag[]} - Adds a indexed and searchable tag to events https://docs.sentry.io/platforms/javascript/enriching-events/tags/
 */
export const reportErrorFactory =
  (fallbackErrorMsg: string, refCodePrefix?: PREFIX, factoryTag?: SentryTag | SentryTag[]) =>
  (error: ErrorToReport, context?: SentryContext, tag?: SentryTag | SentryTag[], options?: ReportOptions) => {
    const refCode = getReferenceCode(refCodePrefix || PREFIX.GENERIC);
    const referenceCodeTag = getReferenceCodeTag(refCode);
    const sentryTags = [referenceCodeTag];
    const errorMsg = getErrorMessage(error, fallbackErrorMsg);
    const message = options?.forceMsgOverride ? fallbackErrorMsg : errorMsg;

    if (factoryTag) {
      if (Array.isArray(factoryTag)) {
        factoryTag.forEach((tag) => sentryTags.push(tag));
      } else {
        sentryTags.push(factoryTag);
      }
    }

    if (tag) {
      if (Array.isArray(tag)) {
        tag.forEach((tag) => sentryTags.push(tag));
      } else {
        sentryTags.push(tag);
      }
    }

    if (!options?.skipToast) {
      toastError(`${message} [ref: ${refCode}]`);
    }

    reportToSentry(error, context, sentryTags);
  };

export const industrySelectErrorMsg = "An error occurred while looking up your industry";
export const reportIndustrySearchError = reportErrorFactory(
  industrySelectErrorMsg,
  PREFIX.INDUSTRY_SEARCH,
  TAGS.INDUSTRY_SEARCH
);

export const airkitErrorMsg = "An error occurred while loading Airkit";
export const reportAirkitError = reportErrorFactory(airkitErrorMsg, PREFIX.AIRKIT, TAGS.AIRKIT);

export const stripeErrorMsg = "An issue occurred with your payment";
export const reportStripeError = (error: ErrorToReport, errorMsgOverride?: string) =>
  reportErrorFactory(errorMsgOverride || stripeErrorMsg, PREFIX.STRIPE, TAGS.STRIPE)(error);

export const genericErrorMsg = "An issue has occurred";
export const reportError = reportErrorFactory(genericErrorMsg);

const agencyErrorMsg = "Error retrieving agencies";
export const reportGetAgencyError = reportErrorFactory(agencyErrorMsg);

const getProducerMsg = "Error retrieving producers";
export const reportGetProducerError = reportErrorFactory(getProducerMsg);

const addProducerMsg = "Error adding producer";
export const reportAddProducerError = reportErrorFactory(addProducerMsg);

const getPolicyMsg = "Error retrieving policy";
export const reportGetPolicyError = reportErrorFactory(getPolicyMsg);

const getPolicyDocsMsg = "Error retrieving policy docs";
export const reportGetPolicyDocsError = reportErrorFactory(getPolicyDocsMsg);

const getPolicyChargesMsg = "Error retrieving policy charges";
export const reportGetPolicyChargesError = reportErrorFactory(getPolicyChargesMsg);

const getAuditTrailMsg = "Error retrieving audit log";
export const reportGetAuditTrailMsg = reportErrorFactory(getAuditTrailMsg);

const updatePolicySettingsMsg = "Error saving policy changes";
export const reportUpdatePolicySettingsError = reportErrorFactory(updatePolicySettingsMsg);

const updateQuotePolicyMsg = "Error quoting policy changes.";
export const reportUpdateQuotePolicyError = reportErrorFactory(updateQuotePolicyMsg);

const cancelPolicyMsg = "An error has occurred. Failed to cancel policy";
export const reportCancelPolicyError = reportErrorFactory(cancelPolicyMsg);

const sendPolicyDocsMsg = "An error has occurred. Failed to send policy docs";
export const reportSendPolicyDocsError = reportErrorFactory(sendPolicyDocsMsg);

const getOpenProjectPoliciesMsg = "Error retrieving Open Project Policies";
export const reportGetOpenProjectPolicyError = reportErrorFactory(getOpenProjectPoliciesMsg);

const paymentMsg = "An issue occurred while processing your payment";
export const reportPaymentError = reportErrorFactory(paymentMsg);

const failedTransactionMsg = "Error retrieving transactions";
export const reportFailedTransactionError = reportErrorFactory(failedTransactionMsg);

const failedOneTimePaymentMsg = "Payment Failed";
export const reportFailedOneTimePayment = reportErrorFactory(failedOneTimePaymentMsg);

const failedUpdatePassword = "Password failed to update";
export const reportFailedUpdatePassword = reportErrorFactory(failedUpdatePassword);

const failedFetchRules = "Error fetching underwriting rules";
export const reportFailedFetchRules = reportErrorFactory(failedFetchRules);

const failedFetchVariables = "Error fetching underwriting variables";
export const reportFailedFetchVariables = reportErrorFactory(failedFetchVariables);

const failedFetchRuleMetadata = "Error fetching rule metadata";
export const reportFailFetchRuleMetadata = reportErrorFactory(failedFetchRuleMetadata);

const failedSubmitRule = "Error submitting rule";
export const reportFailedSubmitRule = reportErrorFactory(failedSubmitRule);

const failedToQuote = "Failed to quote";
export const reportFailedToQuote = reportErrorFactory(failedToQuote);

const formotivFailed = "Failed to download formotiv scripts";
export const reportFormotivFailed = reportErrorFactory(formotivFailed);

const getFNOLFailed = "Failed to receive FNOL records";
export const reportFailedGetFNOL = reportErrorFactory(getFNOLFailed);

const failedDeleteClaim = "Failed to delete claim from FNOL Table";
export const reportFailedDeleteClaim = reportErrorFactory(failedDeleteClaim);
