import React from "react";
import { Checkbox, Label, Textarea } from "styles";
import { ChannelPartnerState } from "./EditChannelPartner";
import { policies } from "shared/application/edit/Coverages/Coverages";
import { PolicyType } from "types/enums";
import styled from "styled-components";
import { POLICY_OPTIONS, VALUE_OPTIONS } from "utils/constants";
import MultiRow from "shared/application/MultiRow";
import { formatNumber } from "utils/formatNumber";

interface PropTypes {
  values: ChannelPartnerState;
  updateValue: React.Dispatch<React.SetStateAction<ChannelPartnerState>>;
  onPolicyTypeBtnClick: (selected: PolicyType) => void;
}

const InsuranceTab = ({ values, updateValue, onPolicyTypeBtnClick }: PropTypes) => {
  const kitPropertyDeductibleOptions = VALUE_OPTIONS[POLICY_OPTIONS.PROPERTY_DAMAGE_DEDUCTIBLE];

  const updatePropertyDamageDeductible = (option: number) => {
    updateValue({ ...values, defaultPropertyDamageDeductible: option });
  };
  return (
    <>
      <SectionWrapper>
        <Label>Allowable Types of Insurance</Label>
        {policies.map((policy) => (
          <Checkbox
            handleClick={() => onPolicyTypeBtnClick(policy.policy)}
            label={`${policy.title} (${policy.policy})`}
            name={policy.policy}
            checked={values.allowablePolicyTypes.includes(policy.policy)}
            data-cy={`policy-type-${policy.policy.toLowerCase()}`}
            marginOverride="8px 8px 8px 0px"
          />
        ))}
      </SectionWrapper>
      <SectionWrapper>
        <Label>Property Damage Deductible</Label>
        <MultiRow
          money={true}
          options={kitPropertyDeductibleOptions}
          value={formatNumber(values.defaultPropertyDamageDeductible)}
          updateValue={updatePropertyDamageDeductible}
          label="Property Damage Deductible"
          propertyName="defaultPropertyDamageDeductible"
        />
      </SectionWrapper>
      <SectionWrapper>
        <Label>Product Configuration</Label>
        <Checkbox
          handleClick={() => updateValue({ ...values, allowBackdating: !values.allowBackdating })}
          label="Allow Policy Backdating"
          name="allowBackdating"
          checked={values.allowBackdating}
          marginOverride="8px 8px 8px 0px"
        />
        <Checkbox
          handleClick={() => updateValue({ ...values, premierPlatform: !values.premierPlatform })}
          label="Premier Platform Discount"
          name="premierPlatform"
          checked={values.premierPlatform}
          marginOverride="8px 8px 8px 0px"
        />
        <Checkbox
          handleClick={() => updateValue({ ...values, requestBrokerEmail: !values.requestBrokerEmail })}
          label="Prompt for Broker Email"
          name="requestBrokerEmail"
          checked={values.requestBrokerEmail}
          marginOverride="8px 8px 8px 0px"
        />
      </SectionWrapper>
      <SectionWrapper>
        <Label htmlFor="options">Config Options</Label>
        <Textarea
          id="options"
          rows={6}
          name="options"
          value={values.options}
          placeholder="Config Options"
          onChange={(e) => updateValue({ ...values, [e.target.name]: e.target.value })}
        />
      </SectionWrapper>
    </>
  );
};

const SectionWrapper = styled.div`
  margin-bottom: 40px;
`;

export default InsuranceTab;
