import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { portalApi } from "hooks/useAPI/instances";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const getLookinSources = async (token: Token, apiVersion: string) => {
  const api = portalApi(apiVersion, token);
  const { data } = await api.get(`/UnderwritingRule/metadata/values/lookinsource`);
  return data;
};

type Options = { enabled?: boolean };

const useGetLookinSources = (options?: Options) => {
  const { apiVersion } = useFeatureFlags();
  const token = useSelector((state: ReduxState) => state.token.token);

  return useQuery({
    queryKey: ["lookinSource"],
    queryFn: () => getLookinSources(token, apiVersion),
    retry: 1,
    ...options,
  });
};

export default useGetLookinSources;
