import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import ArchiveModal from "ia/Archive/ArchiveModal";
import { Checkbox } from "styles";
import { InputCheckbox } from "styles/Checkbox";
import QuoteDropdown from "ia/Archive/QuoteDropdown";
import Highlighter from "shared/Highlighter";
import ApplicationStatusDisplay from "shared/ApplicationStatusDisplay";
import useAuth from "hooks/useAuth";
import { ApplicationStatus, ApplicationSource } from "types/enums";
import MissingInfoModal from "shared/SimplyBind/MissingInfoModal";
import ReadyToPayModal from "./ReadyToPayModal";
import RequoteModal from "./RequoteModal";
import useGetLegacyApplication from "hooks/useGetLegacyApplication";
import ApiSourceEditQuoteModal from "./ApiSourceEditQuoteModal";

interface PropTypes {
  app: any;
  index: number;
  archiveQuote: () => void;
  selectedQuoteIds: string[];
  handleCheckbox: (applicationId: string) => void;
  search?: string;
  archiveStatus: boolean;
}

const ApplicationRow = ({
  app,
  index,
  archiveQuote,
  selectedQuoteIds,
  handleCheckbox,
  search,
  archiveStatus,
}: PropTypes) => {
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showReadyToPayModal, setShowReadyToPayModal] = useState(false);
  const [showMissingInfoModal, setShowMissingInfoModal] = useState(false);
  const [showRequoteModal, setShowRequoteModal] = useState(false);
  const [legacyApplicationId, setLegacyApplicationId] = useState<string | undefined>();
  const [showApiSourceEditQuoteModal, setShowApiSourceEditQuoteModal] = useState(false);
  const history = useHistory();
  const { isIAUser } = useAuth();

  const displayArchiveModal = () => {
    setShowArchiveModal(!showArchiveModal);
  };

  const isReady = (app: any) => Boolean(app?.status === ApplicationStatus.ReadyToQuote);
  const isSimplybind = (app: any) =>
    Boolean(app.sourceVersion === ApplicationSource.underwriting || app.source === ApplicationSource.dashboard);
  const isAPIQuote = (app: Application) => Boolean(app.source === ApplicationSource.API);
  const isLegacyAndPending = !isSimplybind(app) && !isReady(app);

  const { data: legacyApplication, isLoading: isLoadingLegacyApplication } = useGetLegacyApplication(
    legacyApplicationId ?? "",
    {
      enabled: Boolean(legacyApplicationId),
    }
  );

  const viewApplication = (
    applicationId: string | undefined,
    isReady: boolean,
    isSimplybind: boolean,
    isAPIQuote: boolean
  ) => {
    if (applicationId && isSimplybind) {
      history.push(`/simplybind/quote/${applicationId}`);
    } else if (!isReady && !isAPIQuote) {
      setShowRequoteModal(true);
    } else {
      setShowApiSourceEditQuoteModal(true);
    }
  };

  const handleArchiveQuote = () => {
    archiveQuote();
    setShowArchiveModal(false);
  };

  const dropdownActions = (app: Application) => {
    return [
      {
        name: `Archive Quote${selectedQuoteIds?.length > 1 ? "s" : ""}`,
        action: displayArchiveModal,
        hide:
          selectedQuoteIds?.length === 0 ||
          Boolean(app.applicationId && selectedQuoteIds && !selectedQuoteIds.includes(app.applicationId)),
      },
      {
        name: `Archive Quote`,
        action: () => {
          if (app?.applicationId) {
            handleCheckbox(app?.applicationId);
            displayArchiveModal();
          }
        },
        hide: selectedQuoteIds?.length !== 0,
      },
      {
        name: "Edit Quote",
        action: () => viewApplication(app?.applicationId, isReady(app), isSimplybind(app), isAPIQuote(app)),
        hide:
          isLegacyAndPending ||
          (selectedQuoteIds?.length > 1 &&
            Boolean(app.applicationId && selectedQuoteIds && selectedQuoteIds.includes(app.applicationId))),
      },
    ];
  };

  return (
    <Tr data-cy={`application-row-${index}`} data-testid={`application-row-${index}`} tabIndex={0}>
      {showArchiveModal && (
        <ArchiveModal
          closeModal={displayArchiveModal}
          modalIsOpen={showArchiveModal}
          handleArchiveQuote={handleArchiveQuote}
        />
      )}
      {!archiveStatus && isIAUser && (
        <td>
          <OverrideStyle />
          <Checkbox
            name="archive"
            handleClick={() => {
              handleCheckbox(app.applicationId);
            }}
            checked={selectedQuoteIds?.includes(app?.applicationId)}
          />
        </td>
      )}

      {showReadyToPayModal && (
        <ReadyToPayModal
          onClose={() => setShowReadyToPayModal(false)}
          onGoToQuoteEdit={() => {
            setShowReadyToPayModal(false);
            setShowMissingInfoModal(true);
          }}
          applicationId={app?.applicationId}
          editButtonIsLoading={isLoadingLegacyApplication}
        />
      )}
      {showApiSourceEditQuoteModal && (
        <ApiSourceEditQuoteModal
          onClose={() => setShowApiSourceEditQuoteModal(false)}
          applicationId={app?.applicationId}
        />
      )}
      {showRequoteModal && <RequoteModal onClose={() => setShowRequoteModal(false)} />}
      {showMissingInfoModal && (
        <MissingInfoModal
          onClose={() => setShowMissingInfoModal(false)}
          applicationId={app?.applicationId}
          onError={() => {
            setShowMissingInfoModal(false);
            setShowRequoteModal(true);
          }}
          application={legacyApplication?.application}
        />
      )}
      <td>
        <ApplicationStatusDisplay
          isReady={isReady(app)}
          isLegacyQuote={!isSimplybind(app) && !isAPIQuote(app)}
          shouldResponsiveShrink
        />
      </td>
      <td>
        <Highlighter searchTerm={search}>{app?.businessName || ""}</Highlighter>
      </td>
      <td>
        <Highlighter searchTerm={search}>
          {(app?.contactFirstName || "") + " " + (app?.contactLastName || "")}
        </Highlighter>
      </td>
      <td>{app?.applicationTypes ? app?.applicationTypes.join(", ") : ""}</td>
      <td data-date={app?.createdOn}>{app?.createdOn ? new Date(app?.createdOn).toLocaleDateString() : ""}</td>
      {!archiveStatus && isIAUser && (
        <td>
          <QuoteDropdown actions={dropdownActions(app)} />
        </td>
      )}
      <ClickArea
        data-testid={`application-row-${index}-click-area`}
        onClick={() => {
          viewApplication(app?.applicationId, isReady(app), isSimplybind(app), isAPIQuote(app));
        }}
      />
    </Tr>
  );
};

const ClickArea = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 0;
  left: 0;
`;

const Tr = styled.tr`
  cursor: pointer;
  position: relative;
  :hover,
  :focus {
    outline: ${(props) => props.theme.blue} solid 1px;
  }
`;

const OverrideStyle = createGlobalStyle`
  ${InputCheckbox} {
    z-index: 1;
    height: 16px;
    width: 16px;
    background-size: 16px 16px;
    margin: 0;
  }
`;

export default ApplicationRow;
