import { useQuery } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { reportFailedTransactionError } from "utils/reportError";
import Api from "utils/api";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface GetAgencyProducersOptions {
  externalId?: string;
}

const useGetFailedTransactions = ({ externalId }: GetAgencyProducersOptions) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const getFailedTransactions = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api.getFailedTransactions(externalId).catch((error) => {
      reportFailedTransactionError(error);

      return Promise.reject(error);
    });
  };

  return useQuery({
    queryKey: ["failed_transactions", externalId],
    queryFn: getFailedTransactions,
    select: (res) => res,
    retry: false,
  });
};

export default useGetFailedTransactions;
