import React, { useEffect } from "react";
import { Route, Router, Switch } from "react-router-dom";
import history from "../history";
import App from "App";
import useAuth from "hooks/useAuth";

const Logout = () => {
  const { logout } = useAuth();
  useEffect(() => {
    logout({ returnTo: window.location.origin });
  }, []);
  return null;
};

const AuthRoutes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/logout" component={Logout} />
        <Route path="/" render={(renderProps) => <App {...renderProps} />} />
      </Switch>
    </Router>
  );
};

export default AuthRoutes;
