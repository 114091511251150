import React, { useState, useEffect } from "react";
import Api from "utils/api";
import PolicyView from "kit/Policy/PolicyView";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import AjaxLoader from "shared/AjaxLoader";
import { BreadcrumbContainer } from "styles/containerStyledComponents";
import { reportGetPolicyError } from "utils/reportError";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface RouteParams {
  policyNumber: string;
}

interface PropTypes extends RouteComponentProps<RouteParams> {}

const PolicyPage = ({ match }: PropTypes) => {
  const [policy, setPolicy] = useState<Policy | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const { params } = match;
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  useEffect(() => {
    refreshPolicy();
  }, []);

  const refreshPolicy = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    api
      .getPolicy(params.policyNumber)
      .then((policy) => {
        if (policy !== "") {
          //NOTE: this is an example of how TypeScript can fail, the api can return an empty string for the Policy and then it gets set here.
          setPolicy(policy);
        }
      })
      .catch((error) => reportGetPolicyError(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <AjaxLoader />;
  } else {
    if (policy !== undefined) {
      return (
        <BreadcrumbContainer>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={`/policies`}>Policy Search</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/accounts/${policy.accountId}`}>Account ID {policy.accountId}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{policy.businessName}</BreadcrumbItem>
          </Breadcrumb>
          <PolicyView policy={policy} onPolicyChange={refreshPolicy} />
        </BreadcrumbContainer>
      );
    } else {
      return (
        <div style={{ width: "100%" }}>
          <h3 data-cy="policy-not-found">Policy Not Found</h3>
          <Link className="btn btn-primary" to={`/policies`}>
            Return to Search
          </Link>
        </div>
      );
    }
  }
};

export default PolicyPage;
