import React, { useContext } from "react";
import { RuleSearchContext } from "../RuleSearchContext";
import { theme } from "styles";
import styled, { CSSObject } from "styled-components";
import { Dropdown } from "styles";
import { OptionType, dropdownStyles, StyleTypes } from "styles/Dropdown";

export const controlOptions = [
  { value: "applyScore", label: "Apply Score" },
  { value: "decline", label: "Decline" },
  { value: "excludeEndorsements", label: "Exclude Endorsements" },
  { value: "requireEndorsements", label: "Require Endorsements" },
  { value: "recommendEndorsements", label: "Recommend Endorsements" },
];

const policyOptions = [
  { value: "PL", label: "PL" },
  { value: "BOP", label: "BOP" },
  { value: "GL", label: "GL" },
];

const UnderwritingActionFilter = () => {
  const { filters, setControlFilter } = useContext(RuleSearchContext);

  const formatDeclineDSL = (selectedOptions: OptionType[]) => {
    return selectedOptions
      ? `decline (${selectedOptions?.map((option: OptionType) => option.value).join(", ")})`
      : "decline";
  };

  return (
    <Container>
      <DropdownContainer>
        <Dropdown
          onChange={(option) => setControlFilter(option.value)}
          options={controlOptions}
          label="Underwriting Action"
          labelStyles={{ fontSize: "12px", color: theme.textNavyBlue }}
          placeholder="Action"
          full
          isSearchable={false}
          value={filters.control.split(" ")[0]}
        />
      </DropdownContainer>
      {filters.control.includes("decline") && (
        <DropdownContainer>
          <Dropdown
            onChange={(selection) => setControlFilter(formatDeclineDSL(selection))}
            options={policyOptions}
            label="Underwriting Result"
            labelStyles={{ fontSize: "12px", color: theme.mediumGray }}
            placeholder="Result"
            full
            isMulti
            labelIsHidden
            isSearchable={false}
            customStyles={PolicyDropdownStyles}
          />
        </DropdownContainer>
      )}
    </Container>
  );
};

const policyChipBackground: Record<string, string> = {
  PL: theme.orange25,
  GL: theme.green10,
  BOP: theme.yellow30,
};

const policyChipColor: Record<string, string> = {
  PL: "#BC7E2B",
  GL: "#16A66A",
  BOP: "#A68E2B",
};

export const PolicyDropdownStyles = {
  ...dropdownStyles,
  multiValue: (styles: CSSObject, { data }: StyleTypes) => ({
    ...styles,
    marginRight: "10px",
    minWidth: "50px",
    justifyContent: "space-between",
    background: policyChipBackground[data.value],
  }),
  multiValueLabel: (styles: CSSObject, { data }: StyleTypes) => ({
    ...styles,
    background: policyChipBackground[data.value],
    color: policyChipColor[data.value],
    fontSize: "11px",
    fontWeight: 600,
    letterSpacing: "1px",
  }),
  multiValueRemove: (styles: CSSObject, { data }: StyleTypes) => ({
    ...styles,
    background: policyChipBackground[data.value],
    color: policyChipColor[data.value],
    fontSize: "12px",
    fontWeight: 600,

    ":hover": {
      ...styles[":hover"],
      background: policyChipBackground[data.value],
      color: policyChipColor[data.value],
      cursor: "pointer",
    },
  }),
};

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: end;
  column-gap: 56px;
`;

const DropdownContainer = styled.div`
  max-width: 326px;
  width: 100%;
`;

export default UnderwritingActionFilter;
