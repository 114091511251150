export const ruleOperatorOptions = [
  { value: "=", label: "equals", inputType: "text", negated: "!=" },
  { value: "!=", label: "does not equal", inputType: "text", negated: "=" },
  { value: ">", label: "is greater than", inputType: "number", negated: "<=" },
  { value: ">=", label: "is greater than or equal to", inputType: "number", negated: "<" },
  { value: "<", label: "is less than", inputType: "number", negated: ">=" },
  { value: "<=", label: "is less than or equal to", inputType: "number", negated: ">" },
  { value: "in", label: "is in", inputType: "text", negated: "not in" },
  { value: "not in", label: "is not in", inputType: "text" },
  { value: "not in states", label: "is not in states", inputType: "dropdown" },
  { value: "notnull", label: "is not null", inputType: "text" },
  { value: "contains any", label: "contains any", inputType: "text" },
  { value: "contains all", label: "contains all", inputType: "text" },
  { value: "lookin", label: "look in", inputType: "text" },
  { value: "does not contain any", label: "does not contain any", inputType: "text" },
  { value: "does not contain all", label: "does not contain all", inputType: "text" },
  { value: "not lookin", label: "not look in", inputType: "text" },
];
