import React from "react";
import { Text, StyleSheet } from "@react-pdf/renderer";
import { theme } from "styles";
import CoverageTableHeader from "./CoverageTableHeader";
import CoverageTableInfoRow from "./CoverageTableInfoRow";
import { extraCoverages } from "utils/proposal-pdf/constants";

interface CoverageTablePagesProps {
  application: Application;
}

const CoverageTablePages = ({ application }: CoverageTablePagesProps) => (
  <>
    <Text style={styles.pageHeaderTitle}>Coverage Comparison and Details</Text>
    <CoverageTableHeader application={application} />
    {extraCoverages(application.bppDeductible || 0).map((coverageInfo) => {
      return (
        <CoverageTableInfoRow
          endorsementPackage={application?.endorsements?.endorsementPackage}
          key={coverageInfo.coverage}
          coverageObject={coverageInfo}
        />
      );
    })}
  </>
);

const styles = StyleSheet.create({
  pageHeaderTitle: {
    marginBottom: 16,
    fontSize: "18px",
    color: theme.colors.tertiary.zero,
    fontFamily: theme.font.typeface.primary,
    fontWeight: 300,
  },
});

export default CoverageTablePages;
