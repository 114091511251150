export const formatErrorStringWithoutCode = (message: string) =>
  message[5] === ":" ? message.substring(7) : message[6] === ":" ? message.substring(8) : message;

export const findAndFormatErrorMessage = (fieldName: string, invalidFields: InvalidField[]) => {
  const errorMessage = invalidFields.find((f) => f.field === fieldName)?.message;
  if (errorMessage) {
    if (fieldName === "BusinessAgeInMonths") {
      return formatErrorStringWithoutCode(errorMessage.replace("BusinessAgeInMonths", "Business Age"));
    } else return formatErrorStringWithoutCode(errorMessage);
  }
  return undefined;
};
