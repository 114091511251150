import { useAuth0 } from "@auth0/auth0-react";
import { useStore } from "react-redux";
import { setToken } from "redux/reducers/_tokenReducer";
import { Role } from "types/enums";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useOktaAuth } from "@okta/okta-react";

export const ROLE_URL = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

const useAuth = () => {
  const { showOktaAuth, apiVersion } = useFlags();
  const auth0 = useAuth0();
  const store = useStore();
  const okta = useOktaAuth();

  const postLogoutRedirectUri =
    apiVersion === "v2"
      ? `${process.env.REACT_APP_NEW_DASHBOARD_URL}/logout/callback`
      : `${window.location.origin}/logout/callback`;

  if (showOktaAuth) {
    const { oktaAuth, authState } = okta;
    const logout = async () =>
      await oktaAuth.signOut({
        postLogoutRedirectUri,
      });
    const isLoading = !authState;
    const loginWithRedirect = async (options: any) => {
      return oktaAuth.signInWithRedirect(options);
    };
    const user = authState?.idToken?.claims;

    const claimsRoles = authState?.accessToken?.claims.roles;

    const roles = authState?.isAuthenticated ? (Array.isArray(claimsRoles) ? claimsRoles : Array(claimsRoles)) : [];
    const isIAUser =
      authState?.isAuthenticated && roles ? roles.includes(Role.IAProducer) || roles.includes(Role.NonLicensed) : false;
    const isDeveloper = authState?.isAuthenticated ? roles.includes(Role.Developer) : false;
    const isChannelPartner = authState?.isAuthenticated ? roles.includes(Role.ChannelPartner) : false;
    const isNonlicensedUser = authState?.isAuthenticated ? roles.includes(Role.NonLicensed) : false;
    const isAgency = authState?.isAuthenticated ? roles.includes(Role.Agency) : false;

    const getToken = async () => {
      try {
        const token = await oktaAuth.getAccessToken();
        store.dispatch(setToken(token));
        return token;
      } catch (e) {
        console.error("Failed to get token");
      }
    };

    return {
      ...authState,
      getToken,
      loginWithRedirect,
      logout,
      user,
      isLoading,
      isDeveloper,
      isIAUser,
      isChannelPartner,
      isAgency,
      isNonlicensedUser,
      roles,
    };
  } else {
    const roles = auth0.isAuthenticated && auth0.user ? auth0.user[ROLE_URL] : [];
    const isIAUser = auth0.isAuthenticated
      ? roles.includes(Role.IAProducer) || roles.includes(Role.NonLicensed)
      : false;
    const isDeveloper = auth0.isAuthenticated ? roles.includes(Role.Developer) : false;
    const isChannelPartner = auth0.isAuthenticated ? roles.includes(Role.ChannelPartner) : false;
    const isNonlicensedUser = auth0.isAuthenticated ? roles.includes(Role.NonLicensed) : false;
    const isAgency = auth0.isAuthenticated ? roles.includes(Role.Agency) : false;
    const scope = "openid profile read:policies";
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
    const getToken = async () => {
      try {
        const token = await auth0.getAccessTokenSilently({ audience, scope });
        store.dispatch(setToken(token));
        return token;
      } catch (e) {
        console.error("Failed to get token");
      }
    };

    return { ...auth0, getToken, isDeveloper, isIAUser, isChannelPartner, isAgency, isNonlicensedUser, roles };
  }
};

export default useAuth;
