import useAuth from "hooks/useAuth";
import { useMutation } from "@tanstack/react-query";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import Api from "utils/api";
import { carrierParse } from "utils/policyIDParser";

interface CreateClaimProps {
  formData?: ClaimFormData;
  description: string;
  claimID: string;
}

type Options = {
  onSuccess: (res: any) => void;
  onSettled: (res: any) => void;
  onError: (res: any) => void;
};

const usePostCreateClaim = (options: Options) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const mutationFn = async ({ formData, description, claimID }: CreateClaimProps) => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    const { contactData, policyData, claimData } = formData as ClaimFormData;

    const claimPostData = {
      businessName: policyData?.businessName,
      carrier: carrierParse(policyData?.carrier as string),
      contactName: `${contactData?.contactFirstName} ${contactData?.contactLastName}`,
      email: contactData?.email,
      endDate: policyData?.policyExpirationDate,
      id: claimID,
      incidentAddress: contactData?.incidentAddress,
      incidentCity: contactData?.incidentCity,
      incidentDate: claimData?.lossOccurrenceDate,
      incidentDescription: description,
      incidentState: contactData?.incidentState,
      incidentTime: claimData?.lossOccurrenceTime,
      incidentZip: contactData?.incidentZip,
      isPrimaryAddress: contactData?.isPrimaryAddress,
      lineOfBusiness: policyData?.lineOfBusiness === "PL" ? "PF" : policyData?.lineOfBusiness,
      mailingAddress: contactData?.mailingAddress,
      mailingCity: contactData?.mailingCity,
      mailingState: contactData?.mailingState,
      mailingZip: contactData?.mailingZip,
      phoneNumber: contactData?.phone,
      policyNumber: policyData?.policyNumber,
      primaryState: contactData?.isPrimaryAddress ? contactData?.mailingState : contactData?.incidentState,
      startDate: policyData?.policyEffectiveDate,
    };

    return api.claims.createClaim(claimPostData as unknown as ClaimPostData, token as unknown as string);
  };

  return useMutation({ mutationFn, ...options });
};

export default usePostCreateClaim;
