import { ProductIcon, SecondaryButton } from "coterie-ui-library";
import React from "react";
import styled from "styled-components";
import { PRODUCT_LABELS } from "utils/constants/product";

type OverduePolicyToastContentProps = {
  policy: Policy;
  balance: PolicyBalance;
  openPaymentDrawer: (policy: Policy, balance: PolicyBalance) => void;
};

export default function OverduePolicyToastContent({
  policy,
  balance,
  openPaymentDrawer,
}: OverduePolicyToastContentProps) {
  return (
    <OverduePolicyToastContentContainer data-cy="overdue-policy-notification">
      <OverduePolicyToastContentWrap>
        <OverduePolicyToastContentTitle>ACCOUNT OVERDUE</OverduePolicyToastContentTitle>
      </OverduePolicyToastContentWrap>
      <OverduePolicyToastContentSubtitle>
        <div>
          <ProductIcon type={policy.policyType.toLowerCase()} height={18} width={27} />{" "}
          {PRODUCT_LABELS[policy.policyType]}
        </div>
        <MakeAPaymentButton>
          <SecondaryButton isSmall onClick={() => openPaymentDrawer(policy, balance)}>
            Make a Payment
          </SecondaryButton>
        </MakeAPaymentButton>
      </OverduePolicyToastContentSubtitle>
    </OverduePolicyToastContentContainer>
  );
}

const OverduePolicyToastContentContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 8px 8px 8px 24px;
`;

const OverduePolicyToastContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const OverduePolicyToastContentTitle = styled.h4`
  color: ${(props) => props.theme.pageComponents.ia.policyView.overduePolicyToast.title.color};
  font-family: ${(props) => props.theme.pageComponents.ia.policyView.overduePolicyToast.title.fontFamily};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 0;
`;
const OverduePolicyToastContentSubtitle = styled.div`
  align-items: center;
  display: flex;
  color: ${(props) => props.theme.pageComponents.ia.policyView.overduePolicyToast.subtitle.color};
  flex-direction: row;
  gap: 8px;
  font-family: ${(props) => props.theme.pageComponents.ia.policyView.overduePolicyToast.subtitle.fontFamily};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  width: 100%;
`;

const MakeAPaymentButton = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
