import useOneTimePaymentCharge from "hooks/useOneTimePaymentsCharge";
import React from "react";
import AjaxLoader from "shared/AjaxLoader";
import Modal from "shared/Modal";
import { ActionContainer, ModalContentContainer } from "shared/ModalComponents";
import { GhostButton } from "shared/SimplyBind/styles";
import styled from "styled-components";
import { GreenButton, Paragraph } from "styles";
import { toastError } from "utils/toast";
import { formatCurrency } from "utils/formatNumber";

export interface PaymentResponse {
  amountCharged: number;
  amountOwed: number;
  amountRequested: number;
  chargeStatus: string;
  errors?: { message: string }[];
  paymentProfile: {
    creditCard: string;
    cardType: string;
    expiration: string;
  };
  success: boolean;
  transactionId: string;
}

interface ConfirmationModalProps {
  isConfirmationModalOpen: boolean;
  closeConfirmationModal: () => void;
  openPaymentConfirmationModal: () => void;
  policyNumber: string;
  chargeRequestData: {
    apiPost: OneTimePaymentPayload;
    lastFourDigits: string;
  };
  transactionFeeAmount?: number;
  setChargeResponse: (data: any) => void;
}

const ConfirmationModal = ({
  isConfirmationModalOpen,
  closeConfirmationModal,
  chargeRequestData,
  policyNumber,
  openPaymentConfirmationModal,
  transactionFeeAmount,
  setChargeResponse,
}: ConfirmationModalProps) => {
  const mutation = useOneTimePaymentCharge({
    onError: (res: any) => {
      const errorMsg = res.message || "Payment Failed";
      toastError(errorMsg);
      closeConfirmationModal();
    },
    onSuccess: (data: PaymentResponse) => {
      if (data.errors && data.errors.length > 0) {
        const errorMsg = data.errors[0]?.message || "Payment Failed";
        toastError(errorMsg);
      } else {
        setChargeResponse(data);
        openPaymentConfirmationModal();
      }
      closeConfirmationModal();
    },
  });

  const submitBindRequest = (apiPost: any) => {
    mutation.mutate({
      policyNumber: policyNumber,
      apiData: { ...apiPost },
    });
  };

  const postPayment = (e: any) => {
    e.preventDefault();
    submitBindRequest(chargeRequestData.apiPost);
  };

  const chargeAmount = chargeRequestData.apiPost.amount;
  const confirmationMsg = (
    <ConfirmationParagraph>
      {chargeRequestData.apiPost.updatePayment
        ? `Are you sure you want to charge this account for $${chargeAmount} and make the card ending in ` +
          `${chargeRequestData.lastFourDigits} the default method for future payments?`
        : `Are you sure you want to charge this account for $${chargeAmount}?`}
    </ConfirmationParagraph>
  );

  const confirmMessageWithFees = (
    <>
      <ConfirmationParagraph>Are you sure you want to complete this charge?</ConfirmationParagraph>
      {(chargeRequestData.apiPost.updatePayment || transactionFeeAmount) && (
        <ConfirmationList>
          {transactionFeeAmount && (
            <li>{`Charge this account for $${chargeAmount} + $${transactionFeeAmount} fee = ${formatCurrency(
              chargeAmount + transactionFeeAmount
            )} total`}</li>
          )}
          {chargeRequestData.apiPost.updatePayment && (
            <li>{`Make the card ending in ${chargeRequestData.lastFourDigits} the default method for future payments`}</li>
          )}
        </ConfirmationList>
      )}
    </>
  );

  const confirmationMessage = transactionFeeAmount ? confirmMessageWithFees : confirmationMsg;

  return (
    <Modal
      isOpen={isConfirmationModalOpen}
      closeModal={closeConfirmationModal}
      label="confirm-charge"
      title="Confirm Charge"
    >
      <ModalContentContainer>
        {mutation.isPending ? <AjaxLoader /> : confirmationMessage}
        <ActionContainer>
          <CancelGhostButton onClick={closeConfirmationModal}>Cancel</CancelGhostButton>
          <ConfirmationGreenButton
            disabled={mutation.isPending}
            data-cy="cta-button"
            onClick={postPayment}
            data-testid={"cta-button"}
          >
            Yes
          </ConfirmationGreenButton>
        </ActionContainer>
      </ModalContentContainer>
    </Modal>
  );
};

const ConfirmationParagraph = styled(Paragraph)`
  width: 451px;
  color: ${(props) => props.theme.textNavyBlue};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: auto;
`;
const CancelGhostButton = styled(GhostButton)`
  width: 156px;
  height: 49px;
  margin-right: 8px;
`;
const ConfirmationGreenButton = styled(GreenButton)`
  width: 156px;
  height: 49px;
  margin-left: 8px;
`;

const ConfirmationList = styled.ul`
  width: 80%;
  margin: 16px auto 16px;
  color: ${(props) => props.theme.textNavyBlue};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;

  li {
    margin-bottom: 16px;
  }
`;

export default ConfirmationModal;
