import { createSlice } from "@reduxjs/toolkit";

const initialState: ClaimsAndPolicyState = {
  hasAPolicy: null,
  insurancePolicyCompany: "",
  insurancePolicyRate: "",
  claimsCount: 1,
  hasClaims: null,
  currentClaimEdited: 0,
  savedClaims: [],
  savedCurrentClaimsCount: 1,
  removeModalIsOpen: false,
  amountClaimedHasError: false,
  claimType: "",
  lossType: "",
  amountClaimed: null,
  claimDate: null,
  claimStatus: "",
  additionalInformation: "",
  claimNumber: 0,
  updatingQuestions: false,
};

const underwritingQuestionsSlice = createSlice({
  name: "underwritingQuestions",
  initialState,
  reducers: {
    setHasApolicy: (state, { payload }) => {
      state.hasAPolicy = payload;
    },
    setInsurancePolicyCompany: (state, { payload }) => {
      state.insurancePolicyCompany = payload;
    },
    setInsurancePolicyRate: (state, { payload }) => {
      state.insurancePolicyRate = payload;
    },
    setClaimsCount: (state, { payload }) => {
      state.claimsCount = payload;
    },
    setHasClaims: (state, { payload }) => {
      state.hasClaims = payload;
    },
    setCurrentClaimEdited: (state, { payload }) => {
      state.currentClaimEdited = payload;
    },
    setSavedClaims: (state, { payload }) => {
      state.savedClaims = payload;
    },
    setSavedCurrentClaimsCount: (state, { payload }) => {
      state.savedCurrentClaimsCount = payload;
    },
    setRemoveModalIsOpen: (state, { payload }) => {
      state.removeModalIsOpen = payload;
    },
    setClaimNumber: (state, { payload }) => {
      state.claimNumber = payload;
    },
    setAmountClaimedHasError: (state, { payload }) => {
      state.amountClaimedHasError = payload;
    },
    setClaimType: (state, { payload }) => {
      state.claimType = payload;
    },
    setLossType: (state, { payload }) => {
      state.lossType = payload;
    },
    setAmountClaimed: (state, { payload }) => {
      state.amountClaimed = payload;
    },
    setClaimStatus: (state, { payload }) => {
      state.claimStatus = payload;
    },
    setClaimDate: (state, { payload }) => {
      state.claimDate = payload;
    },
    setAdditionalInformation: (state, { payload }) => {
      state.additionalInformation = payload;
    },
    setUpdatingQuestions: (state, { payload }) => {
      state.updatingQuestions = payload;
    },
    clearPolicyInputs: (state, { payload }) => {
      return { ...state, ...payload };
    },
    clearAdditionalClaims: (state, { payload }) => {
      return { ...state, ...payload };
    },
    clearClaimInputs: (state, { payload }) => {
      return { ...state, ...payload };
    },
    editClaim: (state, { payload }) => {
      const { claimObj, claimNumber } = payload;
      return { ...state, ...claimObj, currentClaimEdited: claimNumber };
    },
  },
});

export const {
  setHasApolicy,
  setInsurancePolicyCompany,
  setInsurancePolicyRate,
  setClaimsCount,
  setHasClaims,
  setCurrentClaimEdited,
  setSavedClaims,
  setSavedCurrentClaimsCount,
  setRemoveModalIsOpen,
  setClaimNumber,
  setAmountClaimedHasError,
  setClaimType,
  setLossType,
  setAmountClaimed,
  setClaimStatus,
  setClaimDate,
  setAdditionalInformation,
  setUpdatingQuestions,
  clearPolicyInputs,
  clearAdditionalClaims,
  clearClaimInputs,
  editClaim,
} = underwritingQuestionsSlice.actions;

export default underwritingQuestionsSlice.reducer;
