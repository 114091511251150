import React from "react";
import { Breadcrumb, Header3 } from "styles";
import { selectUserName } from "redux/selectors/user";
import { connect } from "react-redux";
import styled from "styled-components";
import Account from "./Account";
import Partner from "./Partner";
import Agency from "./Agency";
import { AccountPage } from "types/enums";
import { NavLink, useRouteMatch, Route } from "react-router-dom";
import useAuth from "hooks/useAuth";

const TABS = [
  { id: 1, title: "Account", slide: AccountPage.AccountSlide, component: Account },
  { id: 2, title: "Agency", slide: AccountPage.AgencySlide, component: Agency },
  { id: 3, title: "Partner", slide: AccountPage.PartnerSlide, component: Partner, role: "ChannelPartner" },
  // { id: 4, title: "Affiliation", slide: AccountPage.AffiliationSlide, component: Affiliation },
];

interface PropTypes {
  userName: string;
}

const MyAccount = ({ userName }: PropTypes) => {
  let { url } = useRouteMatch();
  const { isChannelPartner } = useAuth();

  return (
    <div data-cy="my-account-view">
      <Breadcrumb>My Account</Breadcrumb>
      <Header3>{userName}</Header3>
      <Tabs role="tablist" aria-label="My Account Sections">
        {TABS.filter((tab) => (isChannelPartner ? tab : tab.role !== "ChannelPartner")).map((tab) => (
          <TabHeader
            key={tab.id}
            to={`/my-account/${tab.title.toLowerCase()}`}
            data-cy={`${tab.title.toLowerCase()}-tab`}
          >
            {tab.title}
          </TabHeader>
        ))}
      </Tabs>
      <Route path={`${url}/account`} component={Account} />
      <Route path={`${url}/agency`} component={Agency} />
      <Route path={`${url}/partner`} component={Partner} />

      {/* <Route path={`${url}/affiliation`} component={Affiliation} /> */}
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  userName: selectUserName(state),
});

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.lighterGray};
  max-width: 400px;
  width: fit-content;
  justify-content: space-between;
`;

const TabHeader = styled(NavLink)`
  padding: 8px 24px;
  margin-bottom: 0px;
  cursor: pointer;
  color: ${(props) => props.theme.fontColors?.primary};
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  &.active {
    border-bottom: 2px solid ${(props) => props.theme.blue};
  }
`;

export default connect(mapStateToProps)(MyAccount);
