import React from "react";
import { Card, CardTitle, CardBody, FormGroup, Input, InputGroup, InputGroupAddon } from "reactstrap";

interface PropTypes {
  primaryInsured: PolicyPrimaryInsured;
  isEditable: boolean;
  onFieldChanged?: (name: string, value: any) => void;
}

const PrimaryInsured = ({ primaryInsured, isEditable, onFieldChanged }: PropTypes) => {
  const onChangeContactInfo = (event: React.FormEvent<HTMLInputElement>) => {
    if (onFieldChanged) {
      onFieldChanged(event.currentTarget.name, event.currentTarget.value);
    }
  };

  const disabled = !isEditable;
  const { businessName, dba, contactEmail, contactFirstName, contactLastName, contactPhone } = primaryInsured;
  const showDba = Boolean(dba);

  return (
    <Card>
      <CardBody>
        <CardTitle id="primaryInsuredTitle">Primary Insured</CardTitle>
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend" id="businessNameLabel">
              Legal Business Name
            </InputGroupAddon>
            <Input
              data-cy="businessName"
              name="businessName"
              value={businessName}
              disabled={disabled}
              onChange={onChangeContactInfo}
              aria-labelledby="businessNameLabel"
            />
          </InputGroup>
        </FormGroup>
        {showDba && (
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend" id="dbaLabel">
                DBA
              </InputGroupAddon>
              <Input
                data-cy="dba"
                name="dba"
                value={dba}
                disabled={disabled}
                onChange={onChangeContactInfo}
                aria-labelledby="dbaLabel"
              />
            </InputGroup>
          </FormGroup>
        )}
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">Contact Name</InputGroupAddon>
            <Input
              data-cy="contactFirstName"
              name="contactFirstName"
              value={contactFirstName}
              disabled={disabled}
              onChange={onChangeContactInfo}
              aria-label="primary insured first name"
            />
            <Input
              data-cy="contactLastName"
              name="contactLastName"
              value={contactLastName}
              disabled={disabled}
              onChange={onChangeContactInfo}
              aria-label="primary insured last name"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend" id="phoneLabel">
              Phone
            </InputGroupAddon>
            <Input
              data-cy="contactPhone"
              name="contactPhone"
              value={contactPhone}
              disabled={disabled}
              onChange={onChangeContactInfo}
              aria-labelledby="phoneLabel"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend" id="emailLabel">
              Email
            </InputGroupAddon>
            <Input
              data-cy="contactEmail"
              name="contactEmail"
              value={contactEmail}
              disabled={disabled}
              onChange={onChangeContactInfo}
              aria-labelledby="emailLabel"
            />
          </InputGroup>
        </FormGroup>
      </CardBody>
    </Card>
  );
};

export default PrimaryInsured;
