import React, { useReducer } from "react";
import styled, { css } from "styled-components";
import { Label, RadioButton } from "styles";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { ErrorText } from "styles/Input";

interface ChargeAmountSectionProps {
  amountOwed: number;
  enteredAmount: number | undefined;
  setEnteredAmount: React.Dispatch<React.SetStateAction<number | undefined>>;
}

type StateType = {
  selectedRadioButton: string;
  error: boolean;
};

type ActionType = { type: "select_radio_button"; payload: string } | { type: "display_error" } | { type: "set_amount" };

const initialState = {
  selectedRadioButton: "enter_amount",
  error: false,
};

function reducer(state: StateType, action: ActionType) {
  switch (action.type) {
    case "select_radio_button":
      return { ...state, selectedRadioButton: action.payload };
    case "display_error":
      return { ...state, error: true };
    case "set_amount":
      return { ...state, error: false };
    default:
      return state;
  }
}

const ChargeAmountSection = ({ amountOwed, enteredAmount, setEnteredAmount }: ChargeAmountSectionProps) => {
  const [{ selectedRadioButton, error }, dispatch] = useReducer(reducer, initialState);

  const handleAmountChange = (values: NumberFormatValues) => {
    const { floatValue } = values;
    setEnteredAmount(floatValue);

    if (floatValue && floatValue > amountOwed) {
      dispatch({ type: "display_error" });
    } else {
      dispatch({ type: "set_amount" });
    }
  };

  const handleRadioButtonChange = (e: any, selectedRadioBtn?: string) => {
    dispatch({ type: "select_radio_button", payload: e?.target?.value || selectedRadioBtn });
  };

  return (
    <>
      {error && <ErrorText>The amount entered must be less than the remaining balance.</ErrorText>}
      <ContentDiv tabIndex={0} onClick={(e) => handleRadioButtonChange(e, "enter_amount")}>
        <RadioButton
          value="enter_amount"
          label=""
          checked={selectedRadioButton === "enter_amount"}
          groupName="charge_amount_section"
          handleClick={handleRadioButtonChange}
          inputStyles={RadioInputStyles}
        />
        <Label withoutMargin htmlFor="chargeAmount">
          Enter amount
        </Label>
        <AmountInput
          id="chargeAmount"
          prefix="$ "
          value={enteredAmount}
          displayType="input"
          thousandSeparator={true}
          name="chargeAmount"
          placeholder="$ 000.00"
          onValueChange={handleAmountChange}
        />
      </ContentDiv>
    </>
  );
};
const ContentDiv = styled.div`
  height: 80px;
  width: 440px;
  padding: 16px;
  background-color: ${(props) => props.theme.offWhite};
  border: 1px solid ${(props) => props.theme.regularGray};
  border-radius: 6px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  margin: 8px 0px;
  :focus {
    border: 1px solid ${(props) => props.theme.blue};
  }
  &:hover {
    cursor: pointer;
  }
`;

const AmountInput = styled(NumberFormat)<{ error?: boolean; loading?: boolean }>`
  height: 48px;
  background-color: ${(props) => props.theme.white};
  font-family: ${(props) => props.theme.secondaryFont};
  border: 1px solid ${(props) => props.theme.lighterGray};
  box-shadow: ${(props) => props.error && `0 0 0 1px ${props.theme.red}`};
  width: 102px;
  font-size: 15px;
  letter-spacing: 0;
  padding: 11px 15px;
  border-radius: 4px;
  position: absolute;
  right: 0;
  margin-right: 16px;
  -moz-appearance: textfield;

  :focus {
    outline: none;
    box-shadow: 0 0 0 1px ${(props) => (props.error ? props.theme.red : props.theme.blue)};
  }
  :read-only {
    pointer-events: none;
  }
  ::-webkit-calendar-picker-indicator {
    fill: purple;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    props.loading &&
    css`
      color: ${props.theme.connector};
    `}
`;

const RadioInputStyles = css`
  margin: -8px 16px -16px;
`;

export default ChargeAmountSection;
