import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import AjaxLoader from "shared/AjaxLoader";
import QuotePremium from "./QuotePremium";
import { CoverageOverview, BusinessDetails, CoverageComparison, EndorsementsAndAddOns, Services } from "./TabSections";
import TabList from "shared/TabList";
import TabPanel from "shared/TabPanel";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import Confirmation from "./Confirmation";
import { Header1 } from "styles/headers";
import {
  getSnapshotPolicies,
  setSnapshotAgent,
  setSnapshotPartner,
  setSnapshotApplication,
  setSnapshotApplicationLossControl,
  getSnapshotApplication,
  setSnapshotQuote,
  setSnapshotAlternateQuote,
} from "redux/reducers/_snapshotReducer";
import { fetchAvailableEndorsements, selectHasNoAvailableEndorsements } from "redux/reducers/_availableEndorsements";
import { setIsValidPolicyStartDate } from "redux/reducers/_global";
import useGetPublicApplication from "hooks/useGetPublicApplication";
import usePostPublicGetQuote from "hooks/usePostPublicGetQuote";
import useGetPublicIndustry from "hooks/useGetPublicIndustry";
import { checkValidDate } from "utils/checkValidDate";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import { PolicyType } from "types/enums";

interface ApplicationAndAPIVersion {
  application: Application;
  apiVersion: string;
}

interface PropTypes extends RouteComponentProps<RouteParams> {
  hasNoAvailableEndorsements: boolean;
  policies?: Policy[];
  application: Application;
  setSnapshotAgent: (agent: AgentInformation) => void;
  setSnapshotPartner: (partner: PartnerInformation) => void;
  setSnapshotApplication: (application: Application) => void;
  setSnapshotApplicationLossControl: (lossControl: string) => void;
  fetchAvailableEndorsements: (payload: ApplicationAndAPIVersion) => void;
  setSnapshotQuote: (quote: Quote) => void;
  setSnapshotAlternateQuote: (alternateQuote: AlternateQuote) => void;
  setIsValidPolicyStartDate: (isValid: boolean) => void;
}

interface RouteParams {
  applicationId: string;
}

const QuoteSnapshot = ({
  match,
  hasNoAvailableEndorsements,
  policies = [],
  application,
  setSnapshotAgent,
  setSnapshotPartner,
  setSnapshotApplication,
  setSnapshotApplicationLossControl,
  fetchAvailableEndorsements,
  setSnapshotQuote,
  setSnapshotAlternateQuote,
  setIsValidPolicyStartDate,
}: PropTypes) => {
  const { apiVersion, showLossControl } = useFeatureFlags();

  const [selectedTab, setSelectedTab] = useState(1);

  const shouldOptInLossControlForBridging = useCallback(
    (application: Application) => {
      return (
        showLossControl &&
        (application?.applicationTypes?.includes("BOP") || application?.applicationTypes?.includes("GL")) &&
        (!application?.lossControl || application?.lossControl === "NotSet")
      );
    },
    [showLossControl]
  );

  const shouldNotSetLossControlForBridgingPLOnly = useCallback(
    (application: Application) => {
      return (
        showLossControl &&
        !(application?.applicationTypes?.includes("BOP") || application?.applicationTypes?.includes("GL")) &&
        application?.lossControl === "OptIn"
      );
    },
    [showLossControl]
  );

  const {
    data: resultsFromGetApplication,
    isSuccess: getPublicApplicationIsSuccess,
    isLoading: loadingGetApplication,
  } = useGetPublicApplication(match.params.applicationId);

  const {
    mutate: postPublicGetQuote,
    data: quoteResults,
    isPending: loadingQuote,
  } = usePostPublicGetQuote({
    onSuccess: (data: { quote: Quote; alternateQuote: AlternateQuote }) => {
      setSnapshotQuote(data.quote);
      data?.alternateQuote && setSnapshotAlternateQuote(data.alternateQuote);
      setSnapshotApplicationLossControl(data.quote.lossControlFees ? "OptIn" : "OptOut");
    },
  });

  useEffect(() => {
    if (getPublicApplicationIsSuccess) {
      const { partner, agent, application } = resultsFromGetApplication;
      const token = partner?.id;
      const producerExternalID = agent?.producerExternalID;

      setSnapshotAgent(agent);
      setSnapshotPartner(partner);

      if (shouldOptInLossControlForBridging(application)) {
        const applicationWithLossControl = { ...application, producerExternalID, lossControl: "OptIn" };
        setSnapshotApplication(applicationWithLossControl);
        postPublicGetQuote({ application: applicationWithLossControl, token });
      } else if (shouldNotSetLossControlForBridgingPLOnly(application)) {
        const applicationWithLossControl = { ...application, producerExternalID, lossControl: "NotSet" };
        setSnapshotApplication(applicationWithLossControl);
        postPublicGetQuote({ application: applicationWithLossControl, token });
      } else {
        setSnapshotApplication(application);
        postPublicGetQuote({ application: { ...application, producerExternalID }, token });
      }
      fetchAvailableEndorsements({ application: { ...application }, apiVersion });
      if (application?.policyStartDate) {
        if (checkValidDate(application?.policyStartDate)) {
          setIsValidPolicyStartDate(true);
        } else {
          setIsValidPolicyStartDate(false);
        }
      }
    }
  }, [
    getPublicApplicationIsSuccess,
    resultsFromGetApplication,
    setSnapshotApplication,
    postPublicGetQuote,
    fetchAvailableEndorsements,
    setIsValidPolicyStartDate,
    apiVersion,
    setSnapshotAgent,
    setSnapshotPartner,
    shouldNotSetLossControlForBridgingPLOnly,
    shouldOptInLossControlForBridging,
  ]);

  const { data: resultsFromGetIndustryName } = useGetPublicIndustry(application.akHash!, {
    enabled: Boolean(application.akHash),
  });

  const channelPartnerId = resultsFromGetApplication?.partner?.id ?? "";
  const producerExternalID = resultsFromGetApplication?.agent?.producerExternalID ?? "";
  const quote = quoteResults?.quote ?? {};
  const industryName = resultsFromGetIndustryName ?? "";
  const applicationTypes = application?.applicationTypes ?? [];
  const showPolicyConfirmation = Boolean(policies && policies[0] && policies[0].policyNumber);

  const lossControlIsOptIn = application?.lossControl === "OptIn";
  const lossControlIsNotSet = application?.lossControl === "NotSet";
  const hasBOPorGL = Boolean(applicationTypes?.includes(PolicyType.BOP) || applicationTypes?.includes(PolicyType.GL));
  const renderLossControl = showLossControl && hasBOPorGL;

  const tabOptions = [
    {
      id: 1,
      label: "Coverage Overview",
      value: "coverage",
      content: <CoverageOverview application={application} quote={quote} />,
      show: true,
    },
    {
      id: 2,
      label: "Endorsements & Add Ons",
      value: "endorsements",
      content: (
        <EndorsementsAndAddOns
          application={application}
          channelPartnerId={channelPartnerId}
          setSelectedTab={setSelectedTab}
        />
      ),
      show: !hasNoAvailableEndorsements,
    },
    {
      id: 3,
      label: "Business Details",
      value: "business",
      content: <BusinessDetails application={application} industryName={String(industryName)} />,
      show: true,
    },
    {
      id: 4,
      label: "Coverage Comparison",
      value: "policy",
      content: <CoverageComparison application={application} />,
      show: applicationTypes?.includes("BOP"),
    },
    {
      id: 5,
      label: "Added Services",
      value: "services",
      content: (
        <Services
          application={application}
          loadingQuote={loadingQuote}
          lossControlIsOptIn={lossControlIsOptIn}
          lossControlIsNotSet={lossControlIsNotSet}
          postPublicGetQuote={postPublicGetQuote}
          token={channelPartnerId}
          producerExternalID={producerExternalID}
          hideSwitchButton={showPolicyConfirmation}
        />
      ),
      show: renderLossControl,
    },
  ];

  if (loadingGetApplication) {
    return (
      <AjaxLoaderContainer>
        <StyedHeader1>Quote Snapshot</StyedHeader1>
        <AjaxLoader />
      </AjaxLoaderContainer>
    );
  }

  return (
    <Container data-cy="quote-snapshot">
      {showPolicyConfirmation ? (
        <Confirmation policies={policies} />
      ) : (
        <QuotePremium
          loadingQuote={loadingQuote}
          applicationTypes={applicationTypes}
          quote={quote}
          application={application}
          lossControlIsOptIn={lossControlIsOptIn}
        />
      )}
      <TabList
        title="Quote Snapshot Sections"
        selectedTab={Number(selectedTab)}
        setSelectedTab={setSelectedTab}
        tabOptions={tabOptions.filter((t) => t.show)}
      />
      <TabPanel selectedTab={Number(selectedTab)} tabOptions={tabOptions.filter((t) => t.show)} />
      <ContactUs />
      <Footer />
    </Container>
  );
};

const mapDispatchToProps = {
  setSnapshotAgent,
  setSnapshotPartner,
  setSnapshotApplication,
  setSnapshotApplicationLossControl,
  fetchAvailableEndorsements,
  setSnapshotQuote,
  setSnapshotAlternateQuote,
  setIsValidPolicyStartDate,
};

const mapStateToProps = (state: ReduxState) => ({
  hasNoAvailableEndorsements: selectHasNoAvailableEndorsements(state),
  policies: getSnapshotPolicies(state),
  application: getSnapshotApplication(state),
});

const AjaxLoaderContainer = styled.div`
  text-align: center;
`;

const Container = styled.div`
  width: 100%;
`;

const StyedHeader1 = styled(Header1)`
  font-size: 60px;
  margin-bottom: 64px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(QuoteSnapshot);
