import React, { useState, useEffect, useCallback, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import LogsTable from "./LogsTable";
import AjaxLoader from "shared/AjaxLoader";
import debounce from "lodash.debounce";
import { LoadingDiv, SearchInput, Title, TitleBarAccessory, TitleContainer } from "styles/containerStyledComponents";
import { reportError } from "utils/reportError";
import Api from "utils/api";
import useAuth from "hooks/useAuth";
import get from "lodash/get";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  title: string;
  headers: string[];
  pathName: string;
  enableSearch: boolean;
  apiRequest: string;
}

const LogsContainer = ({ apiRequest, title, headers, pathName, enableSearch = false }: PropTypes) => {
  const params = new URLSearchParams(window.location.search);
  const q = params.get("q") || "";
  const history = useHistory();
  const { getToken } = useAuth();
  const [logs, setLogs] = useState<LogItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(q);
  const { apiVersion } = useFeatureFlags();

  const getLogsBySearch = async (searchTerm: string) => {
    setIsLoading(true);
    const token = await getToken();
    const api = new Api(apiVersion, token);
    const call = get(api, apiRequest);
    call(searchTerm)
      .then((result: LogsResult) => result?.logs && setLogs(result.logs))
      .catch((error: any) => reportError(error))
      .finally(() => setIsLoading(false));
  };

  const debounceGetLogs = useCallback(
    debounce((searchTerm) => getLogsBySearch(searchTerm), 500),
    []
  );

  useEffect(() => {
    debounceGetLogs(search);
  }, [search]);

  const searchForLog = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target) {
      const target = e.target as HTMLInputElement;
      setSearch(target.value);
      history.replace({
        pathname: pathName,
        search: "?q=" + target.value,
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <TitleContainer>
        <Title>{title}</Title>
        <TitleBarAccessory>
          <LoadingDiv>{isLoading ? <AjaxLoader /> : <span />}</LoadingDiv>

          {enableSearch ? (
            <SearchInput autoFocus type="text" value={search} placeholder="Search..." onChange={searchForLog} />
          ) : (
            <React.Fragment />
          )}
        </TitleBarAccessory>
      </TitleContainer>
      <LogsTable headers={headers} logItems={logs} searchTerm={search} />
    </div>
  );
};

export default LogsContainer;
