import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import { RouteComponentProps } from "react-router";
import Breadcrumb from "reactstrap/lib/Breadcrumb";
import BreadcrumbItem from "reactstrap/lib/BreadcrumbItem";
import { PolicyPaymentType, PolicyType } from "types/enums";
import { theme } from "styles";
import PartnerTab from "./PartnerTab";
import InsuranceTab from "./InsuranceTab";
import DesignTab from "./DesignTab";
import { SaveButton as DefaultButton } from "shared/ModalComponents";
import TabPanel from "shared/TabPanel";
import TabList from "shared/TabList";
import { ReactComponent as Arrow } from "images/arrow-right-blue.svg";
import { PageContainer, BreadcrumbContainer } from "styles/containerStyledComponents";
import { toastError, toastSuccess } from "utils/toast";
import useGetChannelPartners from "hooks/useGetChannelPartners";
import usePutChannelPartner from "hooks/usePutChannelPartner";
import { reportError } from "utils/reportError";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface RouteParams {
  channelPartnerId: string;
}

export interface ChannelPartnerState {
  name: string;
  email?: string;
  webhookUrl?: string;
  allowBackdating: boolean;
  premierPlatform: boolean;
  policyPaymentType: PolicyPaymentType;
  requestBrokerEmail: boolean;
  allowablePolicyTypes: PolicyType[];
  defaultPropertyDamageDeductible: number;
  options: string;
  allowPolicyBackdating?: boolean;
  emailPolicyDocs: boolean;
  emailPolicyDocsAddress: string | null;
}

interface ChannelPartnerData extends ChannelPartnerState {
  id: number;
  publishableKey: string;
  webhookOptions?: { rawOptions: string };
}

const EditChannelPartner = ({ match }: RouteComponentProps<RouteParams>) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [channelPartner, setChannelPartner] = useState<ChannelPartnerData>();
  const [values, updateValue] = useState<ChannelPartnerState>({
    name: channelPartner?.name || "",
    webhookUrl: channelPartner?.webhookUrl || "",
    allowBackdating: channelPartner?.allowPolicyBackdating || false,
    premierPlatform: channelPartner?.premierPlatform || false,
    policyPaymentType: channelPartner?.policyPaymentType || PolicyPaymentType.PolicyHolder,
    requestBrokerEmail: channelPartner?.requestBrokerEmail || false,
    allowablePolicyTypes: channelPartner?.allowablePolicyTypes || [PolicyType.GL, PolicyType.PL, PolicyType.BOP],
    defaultPropertyDamageDeductible: channelPartner?.defaultPropertyDamageDeductible || 0,
    options: channelPartner?.webhookOptions?.rawOptions || "",
    emailPolicyDocs: channelPartner?.emailPolicyDocs || false,
    emailPolicyDocsAddress: channelPartner?.emailPolicyDocsAddress || null,
  });

  const {
    data: channelPartners,
    isSuccess: getChannelPartnerIsSuccess,
    error: getChannelPartnerError,
    refetch: refetchChannelPartners,
  } = useGetChannelPartners();
  const channelPartnerIdFromUrl = Number(match.params.channelPartnerId);

  useEffect(() => {
    if (getChannelPartnerIsSuccess) {
      const channelPartnerMatch = channelPartners?.find((c: ChannelPartnerData) => c.id === channelPartnerIdFromUrl);
      setChannelPartner(channelPartnerMatch);
      updateValue({
        name: channelPartnerMatch?.name || "",
        webhookUrl: channelPartnerMatch?.webhookUrl || "",
        allowBackdating: channelPartnerMatch?.allowPolicyBackdating || false,
        premierPlatform: channelPartnerMatch?.premierPlatform || false,
        policyPaymentType: channelPartnerMatch?.policyPaymentType || PolicyPaymentType.PolicyHolder,
        requestBrokerEmail: channelPartnerMatch?.requestBrokerEmail || false,
        allowablePolicyTypes: channelPartnerMatch?.allowablePolicyTypes || [
          PolicyType.GL,
          PolicyType.PL,
          PolicyType.BOP,
        ],
        defaultPropertyDamageDeductible: channelPartnerMatch?.defaultPropertyDamageDeductible || 0,
        options: channelPartnerMatch?.webhookOptions?.rawOptions || "",
        emailPolicyDocs: channelPartnerMatch?.emailPolicyDocs || false,
        emailPolicyDocsAddress: channelPartnerMatch?.emailPolicyDocsAddress || null,
      });
    }
  }, [getChannelPartnerIsSuccess, channelPartners, channelPartnerIdFromUrl]);

  useEffect(() => {
    if (getChannelPartnerError) reportError(getChannelPartnerError);
  }, [getChannelPartnerError]);

  const saveChannelPartnerEdit = usePutChannelPartner(channelPartner?.id || channelPartnerIdFromUrl);
  const [showEmailError, setShowEmailError] = useState(false);
  const { showChannelPartnerDesignTab } = useFeatureFlags();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (values.emailPolicyDocs && (!values.emailPolicyDocsAddress || showEmailError)) {
      setShowEmailError(true);
      return toastError("There are unsaved changes on this page");
    }
    const onSuccess = () => {
      toastSuccess("Partner Updated!");
      refetchChannelPartners();
    };
    const onError = (error: any) => reportError(error);
    saveChannelPartnerEdit.mutate(
      {
        name: values.name,
        webhookUrl: values.webhookUrl,
        emailPolicyDocs: values.emailPolicyDocs,
        emailPolicyDocsAddress: values.emailPolicyDocsAddress,
        allowPolicyBackdating: values.allowBackdating,
        premierPlatform: values.premierPlatform,
        policyPaymentType: values.policyPaymentType,
        requestBrokerEmail: values.requestBrokerEmail,
        allowablePolicyTypes: values.allowablePolicyTypes,
        defaultPropertyDamageDeductible: values.defaultPropertyDamageDeductible,
        rawWebhookOptions: values.options,
      },
      { onSuccess, onError }
    );
  };

  const onPolicyTypeBtnClick = (selected: PolicyType) => {
    const { allowablePolicyTypes } = values;

    const hasPolicy = allowablePolicyTypes.includes(selected);

    const newPolicyTypes = hasPolicy
      ? allowablePolicyTypes.filter((p: any) => p !== selected)
      : [...allowablePolicyTypes, selected];

    updateValue({ ...values, allowablePolicyTypes: newPolicyTypes });
  };

  const tabOptions = [
    {
      id: 1,
      label: "Partners",
      content: (
        <PartnerTab
          values={values}
          updateValue={updateValue}
          showEmailError={showEmailError}
          setShowEmailError={setShowEmailError}
        />
      ),
      value: "partnersTab",
      isVisible: true,
    },
    {
      id: 2,
      label: "Insurance",
      content: <InsuranceTab values={values} updateValue={updateValue} onPolicyTypeBtnClick={onPolicyTypeBtnClick} />,
      value: "insuranceTab",
      isVisible: true,
    },
    {
      id: 3,
      label: "Design",
      value: "designTab",
      content: <DesignTab channelPartnerGuid={channelPartner?.publishableKey || ""} />,
      isVisible: showChannelPartnerDesignTab,
    },
  ].filter((t) => t.isVisible);

  const tabListStyles = {
    tabContainerStyles: {
      background: "inherit",
      height: 52,
      maxWidth: 760,
      margin: "0 0 22px",
    },
    buttonStyles: {
      color: theme.textNavyBlue,
      fontSize: 16,
      background: "inherit",
      borderBottom: "2px solid lightgrey",
    },
    selectedIndicator: `2px solid ${theme.blue}`,
  };

  const tabPanelStyles = { backgroundColor: "transparent", padding: 0 };

  return getChannelPartnerIsSuccess && channelPartner != null ? (
    <PageContainer>
      <EditChannelPartnerContainer>
        <BreadcrumbContainer>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={`/channel-partners`} data-testid="channel_partner_breadcrumb">
                Channel Partners
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>Edit</BreadcrumbItem>
          </Breadcrumb>
        </BreadcrumbContainer>
        <form onSubmit={handleSubmit} data-testid="edit_channel_partner_view">
          <HeaderCont>
            <TitleCont>
              <Link to="/channel-partners" data-testid="channel_partner_back_caret">
                <IconContainer>
                  <Arrow />
                </IconContainer>
              </Link>
              <EditHeader>Edit Channel Partner</EditHeader>
            </TitleCont>
            <SaveButton
              data-cy="submit-changes"
              color="primary"
              disabled={saveChannelPartnerEdit.isPending}
              type="submit"
              data-testid="submit-form"
            >
              {saveChannelPartnerEdit.isPending ? <Spinner color="light" /> : <span>Save</span>}
            </SaveButton>
          </HeaderCont>

          <TabList
            title="Edit Channel Partner Sections"
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabOptions={tabOptions}
            styles={tabListStyles}
          />
          <TabPanel selectedTab={selectedTab} tabOptions={tabOptions} panelStyles={tabPanelStyles} />
        </form>
      </EditChannelPartnerContainer>
    </PageContainer>
  ) : (
    <Spinner color="light" />
  );
};

export default EditChannelPartner;

const EditChannelPartnerContainer = styled.div`
  width: 500px;
`;

const SaveButton = styled(DefaultButton)`
  width: 120px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
`;

const EditHeader = styled.h1`
  color: ${({ theme }) => theme.textNavyBlue};
  font-family: ${({ theme }) => theme.primaryFont};
  height: 28px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
`;
const HeaderCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const TitleCont = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  h1 {
    margin: 0px;
  }

  a > svg {
    transform: rotate(180deg);
    margin-right: 20px;
  }
`;

const IconContainer = styled.div`
  svg path {
    fill: ${(props) => props.theme.blue};
  }
`;
