import React from "react";
import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import silver from "images/endorsement_silver.png";
import gold from "images/endorsement_gold.png";
import platinum from "images/endorsement_platinum.png";
import { theme } from "styles";
import CoverageTableLine from "./CoverageTableLine";
import { EndorsementPackages } from "types/enums";

interface CoverageTableHeaderProps {
  application: Application;
}

interface PackageObj {
  icon?: any;
  title: string;
  type: EndorsementPackages;
  bgCheck: string;
}

const endorsement_packages: PackageObj[] = [
  {
    title: "Base Coverage",
    bgCheck: "None",
    type: EndorsementPackages.None,
  },
  {
    icon: silver,
    title: "Silver Coverage",
    bgCheck: "Silver",
    type: EndorsementPackages.Silver,
  },
  {
    icon: gold,
    title: "Gold Coverage",
    bgCheck: "Gold",
    type: EndorsementPackages.Gold,
  },
  {
    icon: platinum,
    title: "Platinum Coverage",
    bgCheck: "Platinum",
    type: EndorsementPackages.Platinum,
  },
];

const CoverageTableHeader = ({ application }: CoverageTableHeaderProps) => (
  <View fixed>
    <View style={styles.headerBar}>
      <View style={styles.coverageBox}>
        <Text style={styles.headerBarText}>Coverages</Text>
      </View>
      <View style={styles.limitBox}>
        <Text style={styles.headerBarText}>Limit</Text>
      </View>
      <View style={styles.deductibleBox}>
        <Text style={styles.headerBarText}>Deductible</Text>
      </View>
    </View>
    <View style={styles.coverageOptions}>
      {endorsement_packages.map((endorsement) => (
        <View
          key={endorsement.title}
          style={{
            ...styles.coverageLimitBox,
            backgroundColor:
              application?.endorsements?.endorsementPackage === endorsement.bgCheck ? "rgba(66,36,255,0.1)" : "",
          }}
        >
          {endorsement.icon ? (
            <Image src={endorsement.icon} style={styles.coverageBadge} />
          ) : (
            <View style={styles.coverageBadge}></View>
          )}
          <Text
            style={{
              ...styles.coverageLimitText,
              paddingBottom: "6px",
              paddingTop: "4px",
            }}
          >
            {endorsement.title}
          </Text>
        </View>
      ))}
    </View>
    <CoverageTableLine />
  </View>
);

const styles = StyleSheet.create({
  pageHeaderTitle: {
    marginBottom: 16,
    fontSize: "18px",
    color: theme.colors.tertiary.zero,
  },
  headerBar: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 2,
  },
  headerBarText: {
    fontSize: 11,
    color: theme.white,
    marginLeft: 6,
    fontFamily: theme.font.typeface.primary,
    fontWeight: 400,
  },
  coverageBadge: {
    width: 17,
    height: 18,
    marginBottom: 0,
  },
  coverageBox: {
    backgroundColor: theme.colors.tertiary.zero,
    width: 143,
    marginRight: 3,
    paddingTop: 3,
    paddingBottom: 3,
    marginBottom: 5,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
  },
  limitBox: {
    backgroundColor: theme.colors.tertiary.zero,
    width: 282,
    marginRight: 3,
    paddingTop: 3,
    paddingBottom: 3,
    marginBottom: 5,
  },
  deductibleBox: {
    backgroundColor: theme.colors.tertiary.zero,
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    width: 93,
    paddingTop: 3,
    paddingBottom: 3,
    marginBottom: 5,
  },
  coverageOptions: {
    display: "flex",
    flexDirection: "row",
    width: 282,
    marginLeft: 146,
  },
  coverageLimitBox: {
    width: 70,
    height: 64,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingTop: 8,
  },
  coverageLimitText: {
    fontFamily: theme.font.typeface.secondary,
    fontSize: "9px",
    paddingHorizontal: 11,
  },
});

export default CoverageTableHeader;
