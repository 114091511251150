import React from "react";
import styled from "styled-components";
import { TextLink, Checkbox } from "styles";
import Modal from "shared/ModalBasic";
import formotivInstance from "utils/formotiv";
import { ModalContentContainer, Title as ModalTitle } from "shared/ModalComponents";
import { isCanadaEnv } from "utils/environmentChecker";

interface PropTypes {
  expanded: boolean;
  iconSrc: string;
  title: string;
  subTitle?: string;
  value: string;
  description: string;
  selected: boolean;
  toggleDetails: (title: string) => void;
  toggleCheckbox: (title: string) => void;
  children: React.ReactNode;
  datacy: string;
  id?: string;
  isExentionPackage?: boolean;
}

const SelectDetails = ({
  expanded,
  children,
  toggleDetails,
  toggleCheckbox,
  title,
  subTitle,
  value,
  iconSrc,
  description,
  selected,
  datacy,
  id,
  isExentionPackage = false,
}: PropTypes) => {
  return (
    <Container selected={selected}>
      <CardColumn
        onClick={() => {
          if (id) {
            formotivInstance.trackCustom(id, "checkbox");
          }
          toggleCheckbox(value);
        }}
        data-cy={datacy}
      >
        <Checkbox
          id={id}
          ariaLabel={`${title} checkbox`}
          name={title}
          handleClick={(e: React.FormEvent<HTMLInputElement>) => {
            e.stopPropagation();
            toggleCheckbox(value);
          }}
          checked={selected}
          rightAlignOverride={true}
        />
        <Icon src={iconSrc} alt="" />
        <Title isExentionPackage={isExentionPackage}>{title}</Title>
        <Description isExentionPackage={isExentionPackage}>{description}</Description>
      </CardColumn>

    {!expanded && (
      <ToggleDetailsContainer>
        <TextLink onClick={() => toggleDetails(title)} aria-label={`See ${title} details`}>
          See Coverages
        </TextLink>
      </ToggleDetailsContainer>
    )}

      <Modal isOpen={expanded} closeModal={() => toggleDetails("")} label="Coverages" title="Coverages">
        <StyledModalContentContainer maxHeight="614px" centered>
          <ModalRow>
            <Icon src={iconSrc} alt="" />
            <div>
              <StyledModalTitle>{isCanadaEnv() ? title : `${title} Coverage Package`}</StyledModalTitle>
              {subTitle && <SubTitle>{subTitle}</SubTitle>}
            </div>
          </ModalRow>
          {children}
        </StyledModalContentContainer>
      </Modal>
    </Container>
  );
};

const Container = styled.div<{ selected: boolean }>`
  border-radius: 4px;
  border: ${(props) => (props.selected ? `1px solid ${props.theme.blue}` : "none")};
  background-color: ${(props) => (props.selected ? props.theme.selectedAddonBackground : props.theme.offWhite)};
  margin-bottom: 24px;
  width: 200px;
  height: fit-content;

  &:hover {
    box-shadow: 0px 2px 10px ${(props) => props.theme.lighterGray};
  }
`;

const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 4px 4px 0 0;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

const SubTitle = styled.div`
  font-family: ${(props) => props.theme.secondaryFont};
  font-weight: 200;
`;

const Description = styled.div<{ isExentionPackage: boolean }>`
  padding: ${(props) => (props.isExentionPackage ? "0 14px 16px" : "0 16px 16px")};
  text-align: center;
  font-family: questrial;
  font-size: 13px;
  color: ${(props) => props.theme.gray};
  height: 72px;
  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  width: 47px;
  margin: -16px 16px 0;
`;

const Title = styled.h3<{ isExentionPackage: boolean }>`
  flex: 3;
  color: ${(props) => props.theme.link};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: ${(props) => (props.isExentionPackage ? "17px" : "20px")};
  font-weight: 600;
  letter-spacing: 0;
  margin: ${(props) => (props.isExentionPackage ? "16px 10px" : "16px")};
  text-align: center;
`;

export const ToggleDetailsContainer = styled.div`
  flex-basis: 100%;
  width: 100%;
  text-align: center;
  padding: 16px 0;
  border-radius: 0 0 4px 4px;
`;

const StyledModalContentContainer = styled(ModalContentContainer)`
  padding: 24px 0 0;
`;

const ModalRow = styled.div`
  display: flex;
  margin-bottom: 8px;
  margin-right: auto;
  margin-left: 0;
`;

const StyledModalTitle = styled(ModalTitle)`
  font-size: 20px;
  line-height: 18px;
`;

export default SelectDetails;
