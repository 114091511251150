import React from "react";
import { PolicyType } from "types/enums";
import { formatCurrency, formatPhoneNumber } from "utils/formatNumber";
import { Card, CardSectionHalfWidth, CardSectionTitle, Row, Label, Value } from "./tabContentStyles";

const BusinessDetails = ({ application, industryName }: { application: Application; industryName: string }) => {
  const businessName = application?.businessName ?? application?.legalBusinessName;
  const hasDBA = application?.dba ? [{ label: "DBA", value: application.dba }] : [];

  const location = application?.locations?.[0] ?? {};
  const businessAddress = `${location?.street ?? ""} ${location?.city ?? ""} ${location?.state ?? ""} ${
    location?.zip ?? ""
  }`;
  const locationType = location?.locationType ?? "No selection";
  const applicationTypes = application?.applicationTypes ?? [];
  const businessDetailsBOP = applicationTypes.includes(PolicyType.BOP)
    ? [
        { label: "Business Address", value: businessAddress },
        { label: "Business Location Type", value: locationType },
      ]
    : [];

  const businessAgeInMonths = application?.businessAgeInMonths;
  const businessAge = () => {
    if (businessAgeInMonths === undefined) {
      return "0";
    } else if (businessAgeInMonths < 12) {
      return `${businessAgeInMonths} month${businessAgeInMonths > 1 ? "s" : ""}`;
    } else {
      const ageInYears = Math.round(businessAgeInMonths / 12);
      return `${ageInYears} year${ageInYears > 1 ? "s" : ""}`;
    }
  };
  const numEmployees = application?.numEmployees ?? "";
  const hasPreviousLosses = Boolean(application?.previousLosses?.length);
  const annualPayroll = application?.annualPayroll ?? 0;
  const grossAnnualSales = application?.grossAnnualSales ?? 0;

  const contactName = `${application?.contactFirstName ?? ""} ${application?.contactLastName ?? ""}`;
  const contactEmail = application?.contactEmail ?? "";
  const contactPhone = application?.contactPhone ?? "";
  const mailingAddress = `${application?.mailingAddressStreet ?? ""} ${application?.mailingAddressCity ?? ""} ${
    application?.mailingAddressState ?? ""
  } ${application?.mailingAddressZip ?? ""}`;

  const additionalInsureds = application?.endorsements?.additionalInsureds || [];
  const additionalInsuredsContent = additionalInsureds
    .map((insured: any) => [
      { label: "Name", value: insured.name },
      { label: "Email", value: insured.email },
      { label: "Address", value: insured.fullAddress, className: "addressRow" },
    ])
    .flat();
  const additionalInsuredsDetails = additionalInsureds.length
    ? { title: "Additional Insured", content: additionalInsuredsContent }
    : undefined;

  const businessDetails = [
    {
      title: "Business Details",
      content: [
        { label: "Legal Business Name", value: businessName },
        ...hasDBA,
        { label: "Industry", value: industryName, className: "industryRow" },
        ...businessDetailsBOP,
        { label: "Business Age", value: businessAge() },
        { label: "Number of Employees", value: numEmployees },
        { label: "Past claims?", value: hasPreviousLosses ? "Yes" : "No" },
        { label: "Annual Payroll", value: formatCurrency(annualPayroll) },
        { label: "Annual Sales", value: formatCurrency(grossAnnualSales) },
      ],
    },
    {
      title: "Insured Details",
      content: [
        { label: "Contact Name", value: contactName },
        { label: "Email", value: contactEmail },
        { label: "Phone", value: formatPhoneNumber(contactPhone) },
        { label: "Mailing Address", value: mailingAddress },
      ],
    },
    additionalInsuredsDetails,
  ].filter((e) => e);

  return (
    <Card data-cy="business-details">
      {businessDetails.map((detail) => (
        <CardSectionHalfWidth key={detail?.title}>
          <CardSectionTitle>{detail?.title}</CardSectionTitle>
          {detail?.content.map((content: any, index: number) => (
            <Row className={content?.className} key={`${content.label}-${index}`}>
              <Label>{content.label}</Label>
              <Value className={content?.className}>{content.value}</Value>
            </Row>
          ))}
        </CardSectionHalfWidth>
      ))}
    </Card>
  );
};

export default BusinessDetails;
