export const accurateInfoLabelText =
  "I certify that to the best of my knowledge and belief, after reasonable inquiry, the information previously provided is true and correct. I agree that such information shall be the basis of the insurance policy to be issued with the requested coverage and that we will have relied on such information. I further agree that the requested coverage limit(s) will reflect the maximum potential payout in the event of a covered claim under any such insurance, and that the information provided is incorporated into and forms the basis of any such insurance.";

export const bindAsAgreementText =
  "By selecting “Bind As”, I certify that the selected licensed producer has authorized the binding of this policy.";

export const nluAcknowledgementText =
  "I attest that I am a non-licensed representative of my agency, and I will not sell or bind policies on behalf of my agency. I agree to abide by all state and federal regulations as it pertains to my non-licensed status.";

export const clientCommunicationAcknowledgementText =
  "I have communicated to my client that they must complete payment within 7 days to bind their policy.";

export const stateRequirementsTextSimplyBind =
  "I have disclosed all policy premiums, taxes surcharges and applicable fees to my client prior to policy bind per state requirements.";

export const stateRequirementsTextSnapshot =
  "All policy premiums, taxes, surcharges, and applicable fees have been reviewed prior to purchase in accordance with state requirements.";
