import { useMutation } from "@tanstack/react-query";
import Api from "utils/api";
import useAuth from "./useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const usePostPublicGetAvailableEndorsements = (options?: any) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const postAvailableEndorsements = async (application: Application) => {
    const token = await getToken();
    const api = new Api(apiVersion, token);
    return api.availableEndorsements(application);
  };

  return useMutation({
    mutationFn: postAvailableEndorsements,
    ...options,
  });
};

export default usePostPublicGetAvailableEndorsements;
