import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Modal from "shared/Modal";
import { LabelLarge } from "./FormFields";
import BOPicon from "images/bop_policy.svg";
import MoreDetailsTable from "./MoreDetailsTable";
import { ModalDivContainer } from "shared/ModalComponents";
import { hideForPrint, TextLink } from "styles";
import { extraCoverages } from "utils/proposal-pdf/constants";
import { EndorsementPackages } from "types/enums";
import {
  selectApplicationFormBPPDeductible,
  selectApplicationFormEndorsementPackage,
} from "redux/selectors/application";

interface PropTypes {
  bppDeductible: number;
  endorsementPackage: EndorsementPackages;
}

const BopMoreDetails = ({ bppDeductible, endorsementPackage }: PropTypes) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Div>
      <MoreDetailsButton onClick={openModal} data-cy="bop-more-details-button">
        All coverage details
      </MoreDetailsButton>
      <Modal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        label="BOP-more-coverage-details"
        title="Coverage Details"
        onSubmit={closeModal}
        buttonText="Close"
      >
        <ModalDivContainer>
          <ModalHeader>
            <LabelLarge>
              <PolicyIcon src={BOPicon} alt="" aria-hidden="true" />
              Business Owner's Policy Details
            </LabelLarge>
          </ModalHeader>
          <MoreDetailsTable rows={extraCoverages(bppDeductible, endorsementPackage)} />
        </ModalDivContainer>
      </Modal>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MoreDetailsButton = styled(TextLink)`
  padding: 4px 0px;

  ${hideForPrint}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: ${(props) => props.theme.colors.neutral.white};
`;

const ModalHeader = styled(Header)`
  padding: 5px 20px;
`;

const PolicyIcon = styled.img`
  height: 35px;
  width: 50px;
  margin-right: 20px;
  vertical-align: middle;
`;

const mapStateToProps = (state: ReduxState) => ({
  bppDeductible: selectApplicationFormBPPDeductible(state),
  endorsementPackage: selectApplicationFormEndorsementPackage(state),
});

export default connect(mapStateToProps)(BopMoreDetails);
