import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { PolicyType } from "types/enums";
import { Row } from "../StyledComponents";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  selectApplicationFormEmployees,
  selectApplicationFormPayroll,
  selectApplicationFormSales,
  selectApplicationFormApplicationTypes,
  selectApplicationFormBusinessAge,
  selectApplicationFormLosses,
  selectApplicationFormLocation,
  selectIndustryName,
} from "redux/selectors/application";
import Field from "shared/Field";
import { laptop } from "styles";

interface PropTypes {
  annualPayroll: number;
  applicationTypes: PolicyType[];
  businessAgeInMonths?: number;
  grossAnnualSales: number;
  industryName: string;
  locations: ApplicationLocation;
  numEmployees?: number;
  previousLosses: PreviousLoss[];
  isReadOnly?: boolean;
}

const BusinessBasics = ({
  annualPayroll,
  applicationTypes,
  businessAgeInMonths,
  grossAnnualSales,
  industryName,
  locations,
  numEmployees,
  previousLosses,
}: PropTypes) => {
  const businessAgeInYears = businessAgeInMonths ? Math.round(businessAgeInMonths / 12) : 0;
  const displayClaims = previousLosses !== undefined && previousLosses.length > 0 ? "Yes" : "No";
  const bopOrGlSelected =
    applicationTypes && (applicationTypes.includes(PolicyType.BOP) || applicationTypes.includes(PolicyType.GL));
  const bopSelected = applicationTypes && applicationTypes.includes(PolicyType.BOP);

  return (
    <Card data-cy="business-basics-view">
      <CardBody>
        <Row>
          <CardTitle>Business Basics</CardTitle>
        </Row>
        <Field value={industryName} label="Industry" slug="Industry" />
        <TwoColumnRow>
          <FieldContainer>
            <Field value={businessAgeInYears} label="Business Age" slug="BusinessAgeInYears" />
            <Field value={locations.state} label="Business State" slug="PrimaryLocationState" />
          </FieldContainer>
          <FieldContainer>
            <Field value={numEmployees} label="Employees" slug="NumberOfEmployees" />
            <Field value={locations.zip} label="Business Zipcode" slug="PrimaryLocationZip" />
          </FieldContainer>
        </TwoColumnRow>
        <Field value={displayClaims} label="Past Claims?" slug="DisplayClaims" />
        <Field value={annualPayroll} label="Annual Payroll" slug="AnnualPayroll" isCurrency />
        <Field value={grossAnnualSales} label="Annual Sales" slug="GrossAnnualSales" isCurrency />
        {bopSelected && <Field value={locations.locationType} label="Location Type" slug="LocationType" />}
        {bopOrGlSelected && (
          <Field
            value={`${locations.street} \n${locations.city}, ${locations.state} ${locations.zip}`}
            label="Business Address"
            slug="MailingAddress"
          />
        )}
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  annualPayroll: selectApplicationFormPayroll(state),
  applicationTypes: selectApplicationFormApplicationTypes(state),
  businessAgeInMonths: selectApplicationFormBusinessAge(state),
  grossAnnualSales: selectApplicationFormSales(state),
  industryName: selectIndustryName(state),
  locations: selectApplicationFormLocation(state),
  numEmployees: selectApplicationFormEmployees(state),
  previousLosses: selectApplicationFormLosses(state),
});

const TwoColumnRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: -2px;

  ${laptop} {
    flex-direction: column;
  }
`;

const FieldContainer = styled.div`
  margin: -2px 0;
  :last-child {
    margin-bottom: 0;
    ${laptop} {
      margin: 0;
    }
  }
`;

export default connect(mapStateToProps)(BusinessBasics);
