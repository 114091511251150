import React from "react";
import styled from "styled-components";

interface PropTypes {
  value: string;
  checked: boolean;
  label: string;
  groupName: string;
  handleClick: (arg0: any) => void;
  required?: boolean;
  readOnly?: boolean;
  inputStyles?: any;
  labelStyles?: any;
  ariaLabel?: string;
  id?: string;
}

const RadioButton = ({
  value,
  checked,
  label,
  groupName,
  handleClick,
  required,
  readOnly,
  inputStyles,
  labelStyles,
  ariaLabel,
  id,
}: PropTypes) => (
  <Container>
    <Radio
      type="radio"
      onChange={handleClick}
      id={id ? id : `${groupName}-${value}-radio-button`}
      value={value}
      checked={checked}
      name={groupName}
      required={required}
      disabled={readOnly}
      inputStyles={inputStyles}
      aria-label={ariaLabel}
    />
    <Label htmlFor={id ? id : `${groupName}-${value}-radio-button`} labelStyles={labelStyles}>
      {label}
    </Label>
  </Container>
);

const Container = styled.div`
  display: flex;
  margin: 8px 0 16px;
  align-items: center;
`;

export const Radio = styled.input<{
  inputStyles?: any;
}>`
  cursor: pointer;
  appearance: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 1px solid ${(props) => props.theme.lightGray};
  background: ${(props) => props.theme.white};
  margin-right: 8px;
  transition: 0.2s all ease;

  &:focus {
    outline-color: ${(props) => props.theme.radioBtn};
    outline-style: dotted;
    outline-width: thin;
  }

  &:checked {
    border: 5px solid ${(props) => props.theme.radioBtn};
    background: ${(props) => props.theme.radioBtn};
    box-shadow: inset 0 0 0 2px white;
    width: 24px;
    height: 24px;
  }

  &:checked + label {
    color: ${(props) => props.theme.radioBtn};
  }
  ${(props) => props.inputStyles}
`;

const Label = styled.label<{ labelStyles?: any }>`
  cursor: pointer;
  color: ${({ theme }) => theme.gray};
  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
  white-space: pre;
  ${(props) => props.labelStyles}
`;

export default RadioButton;
