import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { portalApi } from "hooks/useAPI/instances";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const getVariables = async (token: Token, apiVersion: string, variableQuery: string) => {
  const api = portalApi(apiVersion, token);
  const { data } = await api.get(`/UnderwritingRule/search?q=${variableQuery}`);

  return data;
};

type Options = { enabled: boolean };

const useGetUnderwritingRuleVariables = (variableQuery: string, options?: Options) => {
  const { apiVersion } = useFeatureFlags();
  const token = useSelector((state: ReduxState) => state.token.token);

  return useQuery({
    queryKey: [variableQuery],
    queryFn: () => getVariables(token, apiVersion, variableQuery),
    retry: 1,
    ...options,
  });
};

export default useGetUnderwritingRuleVariables;
