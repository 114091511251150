import React from "react";
import styled, { css } from "styled-components";
import formatValue from "utils/formatNumber";

import { ErrorText } from "styles/Input";

interface PropTypes {
  value: number;
  options: number[];
  updateValue: (value: any, name?: string) => void;
  money: boolean;
  label?: string;
  propertyName: string;
  isReadOnly?: boolean;
  hasError?: boolean;
  errorText?: string;
}

const MultiRow = ({
  updateValue,
  value,
  options,
  money,
  label,
  propertyName,
  isReadOnly = false,
  hasError = false,
  errorText,
}: PropTypes) => (
  <Container
    hasActive={options.some((option) => option === value)}
    activeFirstOption={options[0] === value}
    activeLastOption={options[options.length - 1] === value}
  >
    {options.map((option) => (
      <Option
        onClick={() => {
          updateValue(option, propertyName);
        }}
        items={options.length}
        key={option}
        aria-label={`${label}: ${option}`}
        aria-pressed={option === value}
        active={option === value}
        hasActive={options.some((option) => option === value)}
        type="button"
        data-cy={`multi-button-${option}`}
        disabled={isReadOnly}
        id={propertyName}
      >
        {formatValue(option, money)}
      </Option>
    ))}
    {hasError && Boolean(errorText) && <StyledErrorText data-cy="error-text">{errorText}</StyledErrorText>}
  </Container>
);

const Container = styled.div<{ hasActive: boolean; activeFirstOption: boolean; activeLastOption: boolean }>`
  text-align: center;
  min-height: 64px;
  width: 590px;
  max-width: 100%;
  margin-top: 16px;
  padding: ${(props) =>
    props.activeFirstOption
      ? "0 0 0 3px"
      : props.activeLastOption
      ? "0 10px 0 0"
      : props.hasActive
      ? `0`
      : `0 10px 0 10px`};
`;

interface OptionsType {
  items: number;
  active: boolean;
  hasActive: boolean;
}

const Option = styled.button`
  text-align: center;
  border: ${(props) => `1px solid ${props.theme.lighterGray}`};
  color: ${(props) => props.theme.gray};
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  width: ${(props) =>
    props.hasActive
      ? props.items < 8
        ? `${78 / (props.items - 1)}%`
        : `${84 / (props.items - 1)}%`
      : `${100 / props.items}%`};
  align-items: center;
  padding: 16px;
  height: 20px;
  z-index: -1;
  background: none;
  background-color: white;

  :first-of-type {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }
  :last-of-type {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  :hover {
    cursor: pointer;
  }
  :disabled {
    cursor: default;
  }

  ${(props: OptionsType) =>
    props.active &&
    css`
      color: ${(props) => props.theme.white};
      font-weight: 600;
      border: ${(props) => `1px solid ${props.theme.blue}`};
      background-color: ${(props) => props.theme.blue};
      width: ${(props: OptionsType) => (props.items < 8 ? "22%" : "16%")};
      height: 48px;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      margin: 0 -10px;
      font-size: 16px;
      z-index: 0;
      position: relative;
      :hover {
        color: ${(props) => props.theme.white};
        cursor: default;
      }

      @media print {
        -webkit-print-color-adjust: exact !important;
      }
    `}
`;

const StyledErrorText = styled(ErrorText)`
  margin-top: 8px;
  text-align: left;
`;

export default MultiRow;
