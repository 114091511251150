import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { theme } from "styles";
import CoverageTableLine from "./CoverageTableLine";
import { EndorsementPackages } from "types/enums";

interface CoverageObject {
  coverage: string;
  None: string;
  Silver: string;
  Gold: string;
  Platinum: string;
  deductible: string;
}
interface CoverageTableHeaderProps {
  coverageObject: CoverageObject;
  endorsementPackage: EndorsementPackages;
}

const CoverageTableInfoRow = ({ coverageObject, endorsementPackage }: CoverageTableHeaderProps) => {
  return (
    <View wrap={false}>
      <View style={styles.rowWrapper}>
        <View style={styles.titleWrapper}>
          <Text style={styles.coverageTitle}>{coverageObject.coverage}</Text>
        </View>
        <View style={styles.limitWrapper}>
          <View
            style={{
              ...styles.coverageLimitBox,
              backgroundColor: endorsementPackage === "None" ? "rgba(66,36,255,0.1)" : "",
            }}
          >
            <Text style={styles.coverageText}>{coverageObject.None}</Text>
          </View>
          <View
            style={{
              ...styles.coverageLimitBox,
              backgroundColor: endorsementPackage === "Silver" ? "rgba(66,36,255,0.1)" : "",
            }}
          >
            <Text style={styles.coverageText}>{coverageObject.Silver}</Text>
          </View>
          <View
            style={{
              ...styles.coverageLimitBox,
              backgroundColor: endorsementPackage === "Gold" ? "rgba(66,36,255,0.1)" : "",
            }}
          >
            <Text style={styles.coverageText}>{coverageObject.Gold}</Text>
          </View>
          <View
            style={{
              ...styles.coverageLimitBox,
              backgroundColor: endorsementPackage === "Platinum" ? "rgba(66,36,255,0.1)" : "",
            }}
          >
            <Text style={styles.coverageText}>{coverageObject.Platinum}</Text>
          </View>
        </View>
        <View style={styles.deductibleWrapper}>
          <Text style={styles.coverageText}>{coverageObject.deductible}</Text>
        </View>
      </View>
      <CoverageTableLine />
    </View>
  );
};

const styles = StyleSheet.create({
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    height: 33,
  },
  titleWrapper: {
    width: 143,
    marginRight: 3,
  },
  limitWrapper: { width: 282, display: "flex", flexDirection: "row" },
  deductibleWrapper: { width: 93 },
  coverageTitle: {
    fontFamily: theme.font.typeface.secondary,
    fontSize: "9px",
    paddingBottom: 5,
    paddingTop: 5,
  },
  coverageText: {
    fontFamily: theme.font.typeface.secondary,
    fontSize: "7px",
    textAlign: "center",
    paddingHorizontal: 3,
    paddingBottom: 5,
    paddingTop: 5,
  },
  coverageLimitBox: {
    width: 70,
  },
});

export default CoverageTableInfoRow;
