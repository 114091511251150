import React from "react";
import { Text, View, Image, Rect, StyleSheet, Link } from "@react-pdf/renderer";
import { Svg } from "utils/proposal-pdf/SvgPatch";
import { format } from "date-fns";
import { theme } from "styles";
import coterie_logo from "images/coterie-pdf-proposal-logo.png";
import { coterieAddress, carrierTypes, policyFinePrint } from "utils/proposal-pdf/constants";
import { startCase, replace } from "lodash";

interface FinePrintPropTypes {
  application: Application;
  quote: Quote;
}

const FinePrint = ({ application, quote }: FinePrintPropTypes) => {
  const {
    policyStartDate,
    policyEndDate,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhone,
    mailingAddressStreet,
    mailingAddressState,
    mailingAddressCity,
    mailingAddressZip,
  } = application;

  const insuranceCarrier = quote?.quotes?.[0]?.insuranceCarrier ?? "";

  const policyAndCarrierInfo = [
    {
      label: "Proposed Policy Effective Date",
      value: policyStartDate && format(new Date(policyStartDate), "MMM dd, yyyy"),
    },
    {
      label: "Proposed Policy Expiration Date",
      value: policyEndDate && format(new Date(policyEndDate), "MMM dd, yyyy"),
    },
    { label: "Carrier", value: carrierTypes[insuranceCarrier].displayName },
    { label: "Carrier NAIC No.", value: carrierTypes[insuranceCarrier].naic },
    { label: "AM Best Rating", value: carrierTypes[insuranceCarrier].rating },
    { label: "Financial Size Category", value: carrierTypes[insuranceCarrier].financialSizeCategory },
  ];

  const contactInfo = [
    {
      label: "Contact Name",
      value: startCase(`${contactFirstName} ${contactLastName}`),
    },
    {
      label: "Email",
      value: contactEmail,
    },
    {
      label: "Phone Number",
      value: contactPhone && replace(contactPhone, ")", ") "),
    },
    {
      label: "Mailing Address",
      value: `${mailingAddressStreet}, ${mailingAddressCity}, ${mailingAddressState} ${mailingAddressZip}`,
    },
  ];

  return (
    <View>
      <View style={styles.header}>
        <Image style={styles.image} src={coterie_logo} />
        <Text style={styles.addressText}>{coterieAddress}</Text>
      </View>
      <Svg style={styles.line}>
        <Rect x="" y="" width="1000px" height="1px" fill={theme.textNavyBlue} />
      </Svg>
      <View style={styles.container}>
        <>
          {policyAndCarrierInfo.map((info) => (
            <View key={info.label} style={styles.flex}>
              <Text style={styles.labelText}>{info.label}</Text>
              <Text style={styles.valueText}>{info.value}</Text>
            </View>
          ))}

          <View style={styles.contactInfo}>
            {contactInfo.map((info) => (
              <View key={info.label} style={styles.flex}>
                <Text style={styles.labelText}>{info.label}</Text>
                <Text style={styles.valueText}>{info.value}</Text>
              </View>
            ))}
          </View>
        </>
      </View>
      <View>
        <Text style={styles.finePrintText}>
          {policyFinePrint}
          <Link src="https://coterieinsurance.com/terms-conditions/">
            https://coterieinsurance.com/terms-conditions/
          </Link>
          {`.`}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    marginLeft: "auto",
    marginTop: "16px",
    paddingRight: "8px",
  },
  image: {
    width: "141px",
    height: "26px",
    marginBottom: "12px",
  },
  addressText: {
    fontSize: "11px",
    fontFamily: theme.font.typeface.secondary,
    color: theme.gray,
    marginBottom: "24px",
    lineHeight: "1.5px",
  },
  container: {
    margin: "10px 0 0 8px",
    height: "484px",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginBottom: "8px",
  },
  labelText: {
    fontSize: "11px",
    fontFamily: theme.font.typeface.secondary,
    width: "296px",
  },
  valueText: {
    fontSize: "11px",
    fontFamily: theme.font.typeface.primary,
    fontWeight: 600,
  },
  finePrintText: {
    fontSize: "10px",
    fontFamily: theme.font.typeface.secondary,
    color: theme.midGray,
    lineHeight: "1.5px",
    paddingRight: "16px",
  },
  line: {
    height: "1px",
    marginBottom: "4px",
    marginTop: "4px",
  },
  contactInfo: {
    marginTop: "24px",
  },
});

export default FinePrint;
