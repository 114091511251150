import React from "react";
import { Card, CardTitle, CardBody, FormGroup, Input, InputGroup, InputGroupAddon } from "reactstrap";
import styled from "styled-components";

interface PropTypes {
  address: Address;
  isEditable: boolean;
  onFieldChanged?: (name: string, value: any) => void;
}

const MailingAddressCard = ({ address, isEditable, onFieldChanged }: PropTypes) => {
  const onChangeMailingAddress = (event: React.FormEvent<HTMLInputElement>) => {
    if (onFieldChanged) {
      onFieldChanged(event.currentTarget.name, event.currentTarget.value);
    }
  };

  const disabled = !isEditable;
  const { street, city, state, zip } = address;
  return (
    <Card>
      <CardBody>
        <CardTitle id="mailingAddressTitle">Mailing Address</CardTitle>
        {disabled ? (
          <React.Fragment>
            <FormGroup>
              <StyledInputGroup>
                <InputGroupAddon addonType="prepend" id="mailingAddressLabel">
                  Mailing Address
                </InputGroupAddon>
                <textarea
                  name="mailingAddress"
                  disabled
                  value={`${street} \n${city}, ${state} ${zip}`}
                  data-testid="mailing-address-textarea"
                  aria-labelledby="mailingAddressLabel"
                />
              </StyledInputGroup>
            </FormGroup>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend" id="streetLabel">
                  Street
                </InputGroupAddon>
                <Input
                  data-cy="mailingAddressStreet"
                  name="street"
                  value={street}
                  disabled={disabled}
                  onChange={onChangeMailingAddress}
                  aria-labelledby="mailingAddressTitle streetLabel"
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend" id="cityLabel">
                  City
                </InputGroupAddon>
                <Input
                  data-cy="mailingAddressCity"
                  name="city"
                  value={city}
                  disabled={disabled}
                  onChange={onChangeMailingAddress}
                  aria-labelledby="mailingAddressTitle cityLabel"
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend" id="stateLabel">
                  State
                </InputGroupAddon>
                <Input
                  data-cy="mailingAddressState"
                  name="state"
                  value={state}
                  disabled={disabled}
                  onChange={onChangeMailingAddress}
                  aria-labelledby="mailingAddressTitle stateLabel"
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend" id="zipLabel">
                  ZIP
                </InputGroupAddon>
                <Input
                  data-cy="mailingAddressZip"
                  name="zip"
                  value={zip}
                  disabled={disabled}
                  onChange={onChangeMailingAddress}
                  aria-labelledby="mailingAddressTitle zipLabel"
                />
              </InputGroup>
            </FormGroup>
          </React.Fragment>
        )}
      </CardBody>
    </Card>
  );
};

const StyledInputGroup = styled(InputGroup)`
  height: 68px;
`;

export default MailingAddressCard;
