import React from "react";
import styled from "styled-components";
import ReactModal from "react-modal";
import { ModalDivContainer } from "shared/ModalComponents";
import Close from "images/close.svg";
import { theme } from "styles";

if (process.env.NODE_ENV !== "test") ReactModal.setAppElement("#root");

interface ModalPropTypes {
  isOpen: boolean;
  closeModal: () => void;
  label: string;
  title: string;
  loading?: boolean;
  children?: React.ReactNode;
  modalStyles?: ReactModal.Styles;
}

const Modal = ({ isOpen, closeModal, label, title, children, loading, modalStyles }: ModalPropTypes) => {
  const childrenWithCloseProp = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement<React.ReactNode>(child as any, { onRequestClose: closeModal } as any);
    }
    return child;
  });

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyles || ModalStyles}
      onRequestClose={closeModal}
      contentLabel={label}
      shouldCloseOnOverlayClick={true}
    >
      <ModalDivContainer data-cy={`${label}-modal`} data-testid={`${label}-modal`}>
        <ModalTopBar>
          <ModalTitle data-cy={`${label}-header`} className="modal-title">
            {title}
          </ModalTitle>
          <CloseModal disabled={loading} data-cy="close-modal" data-testid="close-modal" onClick={closeModal}>
            <img src={Close} alt="Close Modal" />
          </CloseModal>
        </ModalTopBar>
        {childrenWithCloseProp}
      </ModalDivContainer>
    </ReactModal>
  );
};

const ModalTopBar = styled.div`
  background: ${(props) => props.theme.modalTitleBg};
  padding: 20px;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
`;

const CloseModal = styled.button`
  display: inline-block;
  float: right;
  background: ${(props) => props.theme.modalTitleBg};
  border: ${(props) => props.theme.modalTitleBg};
  margin-top: -7px;
  margin-right: -7px;
`;

const ModalTitle = styled.h2`
  color: white;
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 18px;
  display: inline-block;
  margin: 0px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
`;

export const ModalStyles: ReactModal.Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    position: "fixed",
    border: "none",
    borderRadius: "4px",
  },
  overlay: {
    zIndex: 10,
    backgroundColor: theme.modalBg,
  },
};

export default Modal;
