import { isPast, isDate } from "date-fns";

const validateApplicationStartDate = (application: Application) => {
  const { policyStartDate } = application;

  const rawStartDate = policyStartDate ? new Date(policyStartDate) : new Date();

  const newDate = new Date();
  const currentDate = newDate.toISOString();

  const validatedStartDate =
    !policyStartDate || isPast(rawStartDate) || !isDate(rawStartDate) ? currentDate : policyStartDate;

  return {
    ...application,
    policyStartDate: validatedStartDate,
  } as Application;
};

export default validateApplicationStartDate;
