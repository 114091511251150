import { getToken } from "@okta/okta-auth-js";
import { useMutation } from "@tanstack/react-query";
import useAuth from "./useAuth";
import Api from "utils/api";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface SubmitFormQuestionsArgs {
  policyNumber: string;
  payload: any;
}

type Options = {
  onSuccess?: (res: any) => void;
};

const usePostFormQuestions = (options?: Options) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();
  const getQuote = async ({ policyNumber, payload }: SubmitFormQuestionsArgs) => {
    const token = await getToken();
    const api = new Api(apiVersion, token);
    return api.selfService.submitFormQuestions(policyNumber, payload);
  };

  return useMutation({
    mutationFn: getQuote,
    ...options,
  });
};

export default usePostFormQuestions;
