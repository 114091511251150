import React, { Component, Fragment } from "react";
import styled, { css } from "styled-components";
import { LabelSmall, TextInput } from "./FormFields";
import formatValue from "utils/formatNumber";
import { mobile } from "styles";

interface MultiButtonOptionType {
  title: string;
  values: number[];
  customInput?: string | null;
  placeholder?: string | null;
}

interface PropTypes {
  value: number;
  updateValue: (value: string | number | null) => void;
  available: boolean;
  directLabel: string;
  money: boolean;
  options: MultiButtonOptionType[];
}

interface StateTypes {
  activeButtonTitle: string;
  showDirectInput: boolean;
  selectedIndex: number;
  triggerDirectInputFocus: boolean;
  directInputText: string | number;
}

class MultiButton extends Component<PropTypes, StateTypes> {
  private directInput: any;

  constructor(props: PropTypes) {
    super(props);
    this.state = {
      activeButtonTitle: "",
      showDirectInput: false,
      selectedIndex: 0,
      triggerDirectInputFocus: false,
      directInputText: this.props.value || "",
    };

    this.directInput = React.createRef();
  }

  componentDidMount() {
    // If value is set find the right detail button to show
    if (this.props.value && (!this.state.activeButtonTitle || this.state.showDirectInput)) {
      const activeButton = this.props.options.find((option) => option.values.includes(this.props.value));
      if (activeButton) {
        this.setState({ activeButtonTitle: activeButton.title });
      } else {
        this.setState({ showDirectInput: true });
      }
    }
  }

  changeRange = () => {
    this.setState({ activeButtonTitle: "", showDirectInput: false }, () => {
      this.props.updateValue(null);
    });
  };

  incrementSelection = () => {
    const activeOption = this.props.options.find((option) => option.title === this.state.activeButtonTitle);
    if (activeOption === undefined) {
      return;
    }
    const currentIndex = activeOption.values.findIndex((value) => value === this.props.value);

    if (currentIndex === activeOption.values.length - 1) {
      return;
    }
    this.props.updateValue(activeOption.values[currentIndex + 1]);
  };

  decrementSelection = () => {
    const activeOption = this.props.options.find((option) => option.title === this.state.activeButtonTitle);
    if (activeOption === undefined) {
      return;
    }
    const currentIndex = activeOption.values.findIndex((value) => value === this.props.value);
    this.props.updateValue(activeOption.values[currentIndex - 1]);
  };

  showDirect = () => {
    this.setState({ showDirectInput: true, triggerDirectInputFocus: true });
  };

  componentDidUpdate(prevProps: PropTypes, prevState: StateTypes) {
    if (this.state.showDirectInput && !prevState.showDirectInput && this.state.triggerDirectInputFocus) {
      if (this.props.options.find((item) => item.values.includes(this.props.value))) {
        this.directInput.value = "";
      } else this.directInput.value = this.props.value;
      this.directInput.focus();
    }
    if (typeof prevProps.value === "number" && this.props.value === null) {
      this.setState({
        directInputText: "",
        activeButtonTitle: "",
        showDirectInput: false,
      });
    }

    if (typeof this.props.value === "number" && prevProps.value === null && !this.state.showDirectInput) {
      const { options, value } = this.props;
      // looks to see if a pre selected option matches with the inputed value
      const selectedOption = options.find((item) => item.values.includes(value));

      // get the highest option title for the direct input
      const directInputTitle = options[options.length - 1]?.title;

      this.setState({
        // if no pre selected options match the given value we know we should show the direct value input
        activeButtonTitle: selectedOption?.title || directInputTitle,
        showDirectInput: !selectedOption,
        directInputText: !selectedOption ? value : "",
      });
    }
  }

  handleDirectInputChange = (value: string | number) => {
    this.setState({
      directInputText: value,
    });
  };

  renderTopLevelButtons = () => (
    <Container>
      {this.props.options.map((option) => (
        <Button
          aria-label={`${this.props.directLabel}: ${option.title}`}
          type="button"
          key={option.title}
          onClick={() => this.setState({ activeButtonTitle: option.title })}
          disabled={!this.props.available}
          data-cy={`multi-button-${option.title.replace(/\s/g, "-").replace("$", "").replace(",", "")}`}
        >
          {option.title}
        </Button>
      ))}
    </Container>
  );

  renderDrillDownRow = () => {
    const activeOption = this.props.options.find((option) => option.title === this.state.activeButtonTitle);
    const buttonList = activeOption?.values.map((value) => (
      <Option
        type="button"
        key={value.toString()}
        options={activeOption.values}
        onClick={() => this.props.updateValue(value)}
        active={value === this.props.value}
        data-cy={`multi-button-${value}`}
        data-testid={`multi-button-${value}`}
        aria-label={`${this.props.directLabel}: ${value}`}
        aria-pressed={value === this.props.value}
      >
        {formatValue(value, this.props.money)}
      </Option>
    ));

    if (window.innerWidth < 767) {
      // TODO: Use ReactMedia for this
      if (!this.props.value && activeOption) {
        this.props.updateValue(activeOption.values[0]);
      }

      const currentIndex = activeOption?.values.findIndex((value) => value === this.props.value);

      return (
        <Fragment>
          <ClearText onClick={this.changeRange}>&#8592;{this.state.activeButtonTitle}</ClearText>
          <ButtonRow>
            <IncrementButton onClick={this.decrementSelection} type="button">
              -
            </IncrementButton>
            <Value>{formatValue(this.props.value, this.props.money)}</Value>
            {activeOption && currentIndex === activeOption.values.length - 1 ? (
              <IncrementButton onClick={this.showDirect} type="button">
                +
              </IncrementButton>
            ) : (
              <IncrementButton onClick={this.incrementSelection} type="button">
                +
              </IncrementButton>
            )}
          </ButtonRow>
        </Fragment>
      );
    }

    if (activeOption?.customInput) {
      buttonList?.push(
        <Option
          key={activeOption.customInput}
          onClick={this.showDirect}
          options={activeOption.values}
          data-cy="multi-button-custom"
          aria-label={`${this.props.directLabel}: ${activeOption.customInput}`}
        >
          {activeOption.customInput}
        </Option>
      );
    }

    return (
      <Fragment>
        <ClearText onClick={this.changeRange}>&#8592;{this.state.activeButtonTitle}</ClearText>
        <OptionRow>{buttonList}</OptionRow>
      </Fragment>
    );
  };

  render() {
    const { value, updateValue, directLabel } = this.props;
    const { showDirectInput, activeButtonTitle, directInputText } = this.state;

    if (showDirectInput) {
      return (
        <DirectContainer>
          <ClearText onClick={this.changeRange} data-testid="direct-back-button">
            &#8592;{this.state.activeButtonTitle}
          </ClearText>
          <LabelSmall>{directLabel}</LabelSmall>
          <TextInput
            type="text"
            value={this.state.directInputText || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleDirectInputChange(e.target.value)}
            onBlur={(e: React.FormEvent<HTMLInputElement>) => updateValue(Number(directInputText))}
            width="quarter"
            ref={(el: any) => (this.directInput = el)}
            aria-label={`${this.props.directLabel} input`}
            data-cy="multi-button-direct-input"
          />
        </DirectContainer>
      );
    }
    if (!value && !activeButtonTitle) {
      return this.renderTopLevelButtons();
    }
    if (activeButtonTitle) {
      return this.renderDrillDownRow();
    }

    return this.renderTopLevelButtons();
  }
}

export default MultiButton;

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

interface ButtonProps {
  theme: ThemeInterface;
}

const Button = styled.button`
  height: 48px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  border: 1px solid black;
  border: ${(props: ButtonProps) => `solid 1px ${props.theme.offWhite}`};
  background: ${(props) => props.theme.offWhite};
  border-radius: 23px;
  text-align: center;
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  color: ${(props) => props.theme.buttonTextSecondary};
  width: 187px;
  :hover {
    cursor: pointer;
    border: 1px solid;
    border-color: ${(props) => props.theme.blue};
    background: ${(props) => props.theme.blue};
    color: ${(props) => props.theme.white};
  }
  :disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const OptionRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 60px;
  width: 100%;
  flex-direction: row;
  ${mobile} {
    flex-direction: row;
  }
`;

const Option = styled.button<{ options?: any; active?: boolean }>`
  text-align: center;
  border: ${(props) => `1px solid ${props.theme.lighterGray}`};
  color: ${(props) => props.theme.gray};
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  flex: 1;
  align-items: center;
  padding: 16px;
  height: 20px;
  background-color: white;
  :last-child {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  :first-child {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.2px;
  :hover {
    cursor: pointer;
  }

  ${(props) =>
    props.options?.length > 8 &&
    css`
      justify-content: space-between;
      padding: 16px 8px;
      :first-child {
        padding-left: 16px;
      }
    `}

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.white};
      border: ${(props) => `1px solid ${props.theme.blue}`};
      background-color: ${(props) => props.theme.blue};
      flex: 3;
      height: 48px;
      border-radius: 23px;
      font-size: 16px;
      z-index: 2;
      margin: 0 -10px;
      :first-child {
        margin-left: 0px;
      }
      :last-child {
        margin-right: 0px;
      }
    `}
  
  ${(props) =>
    props.active &&
    props.options?.length > 8 &&
    css`
      padding-left: 28px;
      :first-child {
        padding-left: 28px;
      }
    `}
`;

const ClearText = styled.a`
  display: block;
  font-family: ${(props) => props.theme.secondaryFont};
  color: ${(props) => props.theme.blue};
  margin: 5px 0 5px 0;
  justify-self: flex-start;
  align-self: flex-start;
  :hover {
    cursor: pointer;
  }
`;
const DirectContainer = styled(Container)`
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 10px;
`;
export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  max-width: 300px;
  margin: auto;
  align-items: center;
`;
export const IncrementButton = styled(Button)`
  font-weight: bold;
  font-size: 20px;
  height: 50px;
`;

export const Value = styled.h3`
  color: ${(props) => props.theme.charcoalGrey};
  font-family: ${(props) => props.theme.primaryFont};
  width: 70px;
  text-align: center;
`;
