import useAuth from "./useAuth";
import { useMutation } from "@tanstack/react-query";
import { portalApi } from "./useAPI/instances";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

export type OTPDashboardPayloadType = {
  policyNumber: string;
  amount: number;
  options?: {
    onSuccess?: (data: any) => void;
    onError?: (error: any) => void;
    onSettled?: (data: any, error: any) => void;
  };
};

export const usePostOneTimePaymentDashboard = ({ policyNumber, amount, options }: OTPDashboardPayloadType) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const postOneTimePaymentDashboard = async () => {
    const token = await getToken();
    const api = portalApi(apiVersion, token);
    return api.post(`/policies/${policyNumber}/billing/charge`, {
      amount,
    });
  };

  return useMutation({
    mutationFn: postOneTimePaymentDashboard,
    ...options,
  });
};

export default usePostOneTimePaymentDashboard;
