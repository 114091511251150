import React from "react";
import styled, { css } from "styled-components";
import { PAGES } from "utils/constants";

const pageOrder = [
  { id: PAGES.VERIFY_NPN, label: "Verify NPN" },
  { id: PAGES.ACCOUNT_TYPE, label: "Account Type" },
  { id: PAGES.BASIC_INFO, label: "Basic Info" },
];

interface PropTypes {
  currentPage: string;
  availablePages: string[];
}

const SignUpProgress = ({ currentPage, availablePages }: PropTypes) => {
  return (
    <Container>
      {pageOrder.map((page, index) => {
        const isCurrent = availablePages.includes(page.id) && currentPage === page.id;
        const isCompleted = availablePages.includes(page.id) && currentPage !== page.id;
        const isUnavailable = !availablePages.includes(page.id);
        return (
          <TitleCont key={page.id}>
            <BarDotCont>
              <Circle active={isCurrent} completed={isCompleted} />
              {index !== pageOrder.length - 1 && <Connector active={isCurrent} completed={isCompleted} />}
            </BarDotCont>
            <TitleRow>
              {isCurrent && (
                <ActiveTitle data-testid="circle-active" className="activeLink">
                  {page.label}
                </ActiveTitle>
              )}
              {isCompleted && (
                <Title id={page.id} data-testid="circle-complete" className="availableLink">
                  {page.label}
                </Title>
              )}
              {isUnavailable && <UnavailTitle data-testid="circle-none">{page.label}</UnavailTitle>}
            </TitleRow>
          </TitleCont>
        );
      })}
    </Container>
  );
};

const Title = styled.p<{ active?: boolean }>`
  background-color: ${(props) => (props.active ? "green" : "transparent")};
  color: ${(props) => props.theme.fontColors?.primary};
  text-decoration: none;
  font-family: ${(props) => props.theme.primaryFont};
  margin: 0;
  padding-top: 7.5px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 15px;
  text-transform: capitalize;
  max-width: 52px;
`;

const UnavailTitle = styled.p`
  font-family: ${(props) => props.theme.primaryFont};
  margin: 0;
  color: ${(props) => props.theme.borderGray};
  opacity: 0.2;
  display: inline;
  font-weight: 600;
  font-size: 13px;
  margin: 0;
  letter-spacing: 1px;
  line-height: 15px;
  text-transform: capitalize;
  max-width: 52px;
  padding-top: 7.5px;
`;

const ActiveTitle = styled(UnavailTitle)`
  font-family: ${(props) => props.theme.primaryFont};
  color: ${(props) => props.theme.blue};
  opacity: 1;
  text-transform: capitalize;
  align-self: flex-start;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const TitleCont = styled.div`
  display: flex;
  min-height: 40px;
  flex-direction: column;
`;

const BarDotCont = styled.div`
  display: flex;
  flex-direction: column;
`;

const Connector = styled.div<{ active?: boolean; completed?: boolean }>`
  margin: 5px 0 0 31px;
  border-top: 1px dashed ${(props) => props.theme.connector};
  padding-right: 132px;
  position: absolute;

  ${(props) =>
    props.completed &&
    css`
      border-top: 1px solid ${(props) => props.theme.blue};
    `}

  ${(props) =>
    props.active &&
    css`
      border-top: 1px dashed ${(props) => props.theme.connector};
    `}
`;

const Circle = styled.div<{ active?: boolean; completed?: boolean }>`
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.connector};
  background-color: ${(props) => props.theme.white};
  width: 10.6px;
  height: 10.6px;
  background-clip: content-box;
  position: relative;
  margin: auto;

  ${(props) =>
    props.active &&
    css`
      border: 1px solid ${props.theme.blue};
      background-color: ${(props) => props.theme.green};
    `}

  ${(props) =>
    props.completed &&
    css`
      border: 1px solid ${props.theme.blue};
      background-color: ${(props) => props.theme.green};
    `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 336px;
  justify-content: space-between;
  position: relative;
`;

export default SignUpProgress;
