import { useQuery } from "@tanstack/react-query";
import { UnderwritingActions } from "types/enums";
import { reportError } from "utils/reportError";
import { portalApi } from "./useAPI/instances";
import useAuth from "./useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

type Options = { enabled?: boolean };

const useGetUnderwritingProfile = (applicationId: string, options?: Options) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const getUnderwritingProfile = async (applicationId: string) => {
    const token = await getToken();
    const portalAPI = portalApi(apiVersion, token);
    const { data } = await portalAPI.get(`/underwriting/${applicationId}/application`).then(async (res: any) => {
      /* Manual UWE risk assessment + rate trigger for users who create their quote via API and bridge to Simplybind.  */
      const appliedActions = res?.data?.appliedActions;
      const underwriteActionIndex = appliedActions?.findLastIndex((action: any) => action === UnderwritingActions.Underwrite);
      const actionsAfterUnderwrite = appliedActions?.slice(underwriteActionIndex + 1);
      if (!actionsAfterUnderwrite?.includes(UnderwritingActions.AssessRisk)) {
        return await portalAPI
          .get(`/underwriting/${res.data.underwritingProfileId}?afterAction=AssessRisk`)
          .then(() => portalAPI.get(`/underwriting/${res.data.underwritingProfileId}?afterAction=Rate`))
          .then(async (rateResponse: any) => {
            const readyToBind = !rateResponse?.data?.validationErrors;
            if (readyToBind && !actionsAfterUnderwrite?.includes(UnderwritingActions.PreBind)) {
              return await portalAPI.get(`/underwriting/${res.data.underwritingProfileId}?afterAction=PreBind`);
            } else {
              return rateResponse;
            }
          })
          .catch((error) => {
            reportError(error);

            return Promise.reject(error);
          });
      } else {
        return res;
      }
    });
    return data;
  };

  return useQuery({
    queryKey: ["underwriting_profile", applicationId],
    queryFn: () => getUnderwritingProfile(applicationId),
    refetchOnWindowFocus: false,
    retry: 0,
    ...options,
  });
};

export default useGetUnderwritingProfile;
