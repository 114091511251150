import React from "react";
import { EndorsementPackages } from "types/enums";
import styled from "styled-components";

interface PropTypes {
  endorsementPackage: EndorsementPackages;
}

const PackageDetails = ({ endorsementPackage }: PropTypes) => {
  return (
    <Details>
      <Table>
        <TableHeader>
          <th>Coverage</th>
          <th>Limit</th>
        </TableHeader>

        {Object.keys(endorsement_coverages)
          .filter((key) => endorsement_coverages[key][endorsementPackage])
          .map((key) => {
            const limit = endorsement_coverages[key][endorsementPackage];
            return (
              <TableRow key={key}>
                <td>{key}</td>
                <td>{limit}</td>
              </TableRow>
            );
          })}
      </Table>
    </Details>
  );
};

const TableHeader = styled.tr`
  background-color: ${(props) => props.theme.labelGray};
  border-radius: 4px;
  top: 0;
  position: sticky;
  transform: translateY(-1px);

  th {
    text-transform: uppercase;
    color: ${(props) => props.theme.link};
    font-family: ${(props) => props.theme.primaryFont};
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0;
    padding: 8px;
    padding-left: 16px;
  }
`;

const Table = styled.table`
  margin: 0px;
  width: 100%;
`;

const TableRow = styled.tr`
  &:nth-child(odd) {
    background: ${(props) => props.theme.offWhite};
  }

  padding: 8px;
  line-height: 40px;

  td {
    color: ${(props) => props.theme.link};
    font-family: ${(props) => props.theme.primaryFont};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    padding-left: 16px;
  }
`;

const Details = styled.div`
  background: ${(props) => props.theme.white};
  width: 100%;
  height: 400px;
  overflow: auto;
`;

const endorsement_coverages: any = {
  "Blanket Limit": {
    Silver: "$150,000",
    Gold: "$350,000",
    Platinum: "$500,000",
  },
  "Accounts Receivable": {
    Silver: "Included",
    Gold: "Included",
    Platinum: "Included",
  },
  "Computers and Media": {
    Silver: "Included",
    Gold: "Included",
    Platinum: "Included",
  },
  "Debris Removal": {
    Silver: "Included",
    Gold: "Included",
    Platinum: "Included",
  },
  "Personal Property of Others": {
    Silver: "Included",
    Gold: "Included",
    Platinum: "Included",
  },
  "Valuable Papers and Records": {
    Silver: "Included",
    Gold: "Included",
    Platinum: "Included",
  },
  "Brands and Labels": {
    Silver: "No sublimit",
    Gold: "No sublimit",
    Platinum: "No sublimit",
  },
  "Claim Expenses": {
    Silver: "$10,000",
    Gold: "$10,000",
    Platinum: "$10,000",
  },
  "Computer Fraud": {
    Silver: "$5,000",
    Gold: "$5,000",
    Platinum: "$5,000",
  },
  "Contract Penalty": {
    Gold: "$1,000",
    Platinum: "$1,000",
  },
  "Employee Dishonesty (including ERISA)": {
    Silver: "$25,000",
    Gold: "$25,000",
    Platinum: "$25,000",
  },
  "Fine Arts": {
    Silver: "$25,000",
    Gold: "$50,000",
    Platinum: "$75,000",
  },
  Forgery: {
    Silver: "$25,000",
    Gold: "$25,000",
    Platinum: "$35,000",
  },
  "Laptop Computers - Worldwide Coverage": {
    Silver: "$10,000",
    Gold: "$10,000",
    Platinum: "$15,000",
  },
  "Off-Premises Utility Services - Direct Damage": {
    Silver: "$25,000",
    Gold: "$25,000",
    Platinum: "$25,000",
  },
  "Ordinance or Law": {
    Gold: "$25,000",
    Platinum: "$50,000/$25,000",
  },
  "Outdoor Signs": {
    Silver: "No sublimit",
    Gold: "No sublimit",
    Platinum: "No sublimit",
  },
  "Pairs or Sets": {
    Silver: "No sublimit",
    Gold: "No sublimit",
    Platinum: "No sublimit",
  },
  "Property at Other Premises": {
    Silver: "$25,000",
    Gold: "$50,000",
    Platinum: "$50,000",
  },
  "Salespersons Samples": {
    Silver: "$5,000",
    Gold: "$25,000",
    Platinum: "$25,000",
  },
  "Sewer and Drain Back Up": {
    Silver: "No sublimit",
    Gold: "No sublimit",
    Platinum: "No sublimit",
  },
  "Sump Overflow or Sump Pump Failure": {
    Silver: "$50,000",
    Gold: "$50,000",
    Platinum: "$50,000",
  },
  "Tenant Building and Business Personal Property Coverage - Required by Lease": {
    Silver: "$20,000",
    Gold: "$20,000",
    Platinum: "$20,000",
  },
  "Tenant Glass": {
    Gold: "No sublimit",
    Platinum: "No sublimit",
  },
  "Transit Property in the Care of Carriers for Hire": {
    Silver: "$10,000",
    Gold: "$25,000",
    Platinum: "$25,000",
  },
  "Unauthorized Business Card Use": {
    Silver: "$5,000",
    Gold: "$5,000",
    Platinum: "$5,000",
  },
  "Newly Acquired or Constructed Property (Building)": {
    Silver: "Up to $1,000,000",
    Gold: "Up to $1,000,000",
    Platinum: "Up to $1,000,000",
  },
  "Newly Acquired or Constructed Property (BPP)": {
    Silver: "Up to $500,000",
    Gold: "Up to $500,000",
    Platinum: "Up to $500,000",
  },
  "Newly Acquired or Constructed Property (BI)": {
    Silver: "60 days",
    Gold: "120 days",
    Platinum: "180 days",
  },
  "Outdoor Property": {
    Silver: "$25,000",
    Gold: "$25,000",
    Platinum: "$25,000",
  },
  "Personal Effects": {
    Silver: "$25,000",
    Gold: "$60,000",
    Platinum: "$60,000",
  },
  "Property Off-Premises": {
    Silver: "$25,000",
    Gold: "$25,000",
    Platinum: "$25,000",
  },
  "Business Income Daily Limit Options": {
    Silver: "$500 Daily limit or Avg Daily Limit",
    Gold: "$500 Daily limit or Avg Daily Limit",
    Platinum: "$500 Daily limit or Avg Daily Limit",
  },
  "Business Income Extension for Off-Premises Utility Services": {
    Silver: "$50,000",
    Gold: "$50,000",
    Platinum: "$50,000",
  },
  "Business Income Extension for Web Sites": {
    Silver: "$50,000",
    Gold: "$50,000",
    Platinum: "$50,000",
  },
  "Business Income from Dependent Properties": {
    Silver: "$50,000",
    Gold: "$50,000",
    Platinum: "$50,000",
  },
  "Extended Business Income": {
    Silver: "90 days",
    Gold: "12 months",
    Platinum: "12 months",
  },
  "Stock Valuation Changes": {
    Silver: "Yes",
    Gold: "Yes",
    Platinum: "Yes",
  },
};

export default PackageDetails;
