import { Label, Dropdown } from "coterie-ui-library";
import useGetCancelReasons from "hooks/useGetCancelReasons";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import { Textarea } from "styles";
import { ReactComponent as AlertIcon } from "images/alert-red.svg";
import { CancelError } from "./CancelStyles";
import { CancellationForm } from "./types";

type Reason = {
  label: string;
  value: string;
};

export default function CancellationReasons() {
  const { data: reasons, isLoading } = useGetCancelReasons();
  const { setValue, formState } = useFormContext<CancellationForm>();
  const { reason } = useWatch<CancellationForm>();

  const { errors } = formState;

  const handleReasonChange = (value: Reason) => {
    if (!value || value?.value === "CR") setValue("additionalInformation", undefined);
    setValue("reason", value, { shouldValidate: true });
  };

  if (!reasons) return null;

  return (
    <div>
      <CancellationReasonsDropdown>
        <CancelReasonsDropdown>
          <Dropdown
            id="reason"
            label="What is the reason for the cancellation?"
            multiple={false}
            placeholder="Select a reason"
            options={reasons.map((reason) => ({ label: reason.description, value: reason.code }))}
            canSearch
            size="large"
            loading={isLoading}
            onChange={(reason) => handleReasonChange(reason as Reason)}
            value={reason as Reason}
          />
        </CancelReasonsDropdown>
        {errors.reason && (
          <CancelError>
            <AlertIcon />
            {errors.reason.message}
          </CancelError>
        )}
      </CancellationReasonsDropdown>
      {reason?.value === "CR" && (
        <AdditionalInfoContainer>
          <Label size="large" htmlFor="additionalInformation">
            Please specify reason for cancellation
          </Label>
          <AdditionalInfo
            id="additionalInformation"
            rows={6}
            onChange={({ target: { value } }) => setValue("additionalInformation", value, { shouldValidate: true })}
          />
          {errors.additionalInformation && (
            <CancelError>
              <AlertIcon />
              {errors.additionalInformation.message}
            </CancelError>
          )}
        </AdditionalInfoContainer>
      )}
    </div>
  );
}

const CancellationReasonsDropdown = styled.div`
  margin-top: 8px;
  max-width: 322px;
`;

const AdditionalInfoContainer = styled.div`
  margin-top: 16px;
  max-width: 322px;

  label {
    margin-bottom: 8px;
  }
`;

const AdditionalInfo = styled(Textarea)`
  resize: none;
  margin: 0;
`;

const CancelReasonsDropdown = styled.div`
  label {
    margin-bottom: 8px;
  }
`;
