import React, { useState, useEffect } from "react";
import Api from "utils/api";
import PolicyReportTable from "kit/PolicyReportTable";
import styled from "styled-components";
import { ContentContainer, Title } from "styles/containerStyledComponents";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const OpenPolicyReport = () => {
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = async () => {
    setIsLoading(true);
    setPolicies([]);

    const token = await getToken();
    const api = new Api(apiVersion, token);

    api
      .getOpenPolicies()
      .then((policies) => {
        setPolicies(policies);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ContentContainer>
      <Title>Open Policy Report</Title>
      <PolicyReportTable policies={policies} isLoading={isLoading} onRefresh={onRefresh} />

      <Note>
        NOTE: An "Open" policy is a policy that is pending or active, or a policy that has a balance on the books
        (premium paid != premium)
      </Note>
    </ContentContainer>
  );
};

const Note = styled.div`
  width: 100%;
  text-align: center;
`;

export default OpenPolicyReport;
