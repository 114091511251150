import { QueryFunction, useQuery } from "@tanstack/react-query";
import useAuth from "./useAuth";
import { portalApi } from "./useAPI/instances";
import { reportError } from "utils/reportError";
import { useSelector } from "react-redux";
import { selectUserAgencyId } from "redux/selectors/user";
import { AxiosError } from "axios";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface GetAgencyProducersOptions {
  enabled?: boolean;
  options?: any;
  shouldReturnNonLicensed?: boolean;
}

export interface GetAgencyProducersResponse {
  accountExternalId: string;
  firstName: string;
  lastName: string;
  value: string;
  label: string;
  email: string;
}

const useGetAgencyProducers = ({
  enabled = true,
  shouldReturnNonLicensed = false,
  options = {},
}: GetAgencyProducersOptions) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();
  const agencyId = useSelector(selectUserAgencyId);
  const queryOptions = { onError: reportError, enabled: Boolean(agencyId) && enabled, ...options };

  const getAgencyProducers: QueryFunction<GetAgencyProducersResponse[]> = async () => {
    const token = await getToken();
    const api = portalApi(apiVersion, token);
    const licenseQueryParam = shouldReturnNonLicensed ? "?license=false" : "";

    return api.get(`/agencies/${agencyId}/producers${licenseQueryParam}`);
  };

  return useQuery<GetAgencyProducersResponse[]>({
    queryKey: ["agency_producers"],
    queryFn: getAgencyProducers,
    ...queryOptions,
    retry: (failureCount, error: AxiosError) => (error.response?.status === 404 ? false : failureCount < 2 && true),
    select: ({ data }: { data: GetAgencyProducersResponse[] }) =>
      data.map((producer: GetAgencyProducersResponse) => ({
        ...producer,
        value: producer.accountExternalId,
        label: producer.firstName ? `${producer.firstName} ${producer.lastName}` : producer.email,
      })),
  });
};

export default useGetAgencyProducers;
