import styled, { css } from "styled-components";
import React from "react";
import { isCanadaEnv } from "utils/environmentChecker";

export const GreenButtonStyles = css`
  font-family: ${(props) => props.theme.primaryFont};
  text-decoration: none;

  border: 0;
  border-radius: 100px;
  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.buttonText};
  transition: all 0.15s;

  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  padding: 14px 22px;
  display: inline-block;
  min-width: 120px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${(props) => props.theme.buttonHover};
    color: ${(props) => props.theme.buttonTextHover};
    text-decoration: none;
  }

  &:disabled,
  &[aria-disabled="true"] {
    background-color: ${(props) => props.theme.disabledBackground};
    color: ${(props) => props.theme.disabledText};
    cursor: not-allowed;
    box-shadow: none;
  }

  span {
    white-space: nowrap;
    text-decoration: none;
  }
`;

const GreenButtonButton = styled.button`
  ${GreenButtonStyles}
  ${(props) =>
    props.noHover &&
    css`
      &:hover {
        background-color: ${(props) => props.theme.green};
        color: ${(props) => props.theme.bgDarkBlue};
      }
    `}
`;

const ButtonLink = styled.a`
  ${GreenButtonStyles}
  ${(props) =>
    props.noHover &&
    css`
      &:hover {
        background-color: ${(props) => props.theme.green};
        color: ${(props) => props.theme.bgDarkBlue};
      }
    `}
`;

export const GreenButton = (props) => {
  const dataCy = props?.dataCy ?? "cta-button";
  if (props.onClick || props.type === "submit") {
    return (
      <GreenButtonButton data-cy={dataCy} data-testid={dataCy} {...props} onClick={props.onClick}>
        <span>{props.children}</span>
      </GreenButtonButton>
    );
  }
  return (
    <ButtonLink {...props} href={props.href}>
      <span>{props.children}</span>
    </ButtonLink>
  );
};

export const NarrowButtonStyles = css`
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  padding: 7px 16px;

  font-family: ${(props) => props.theme.primaryFont};
  text-decoration: none;

  border-radius: 23px;
  background-color: ${(props) => (isCanadaEnv() ? props.theme.button : props.theme.offWhite)};
  color: ${(props) => (isCanadaEnv() ? props.theme.fontColors.primary : props.theme.bgDarkBlue)};
  transition: all 0.15s;

  cursor: pointer;
  display: inline-block;

  border: 1px solid;
  border-color: ${(props) => props.theme.regularGray};

  &:active {
    border-color: ${(props) => props.theme.blue};
  }

  &:hover {
    background-color: ${(props) => (isCanadaEnv() ? props.theme.buttonHover : props.theme.blue)};
    color: ${(props) => props.theme.buttonTextHover};
    border-color: ${(props) => (isCanadaEnv() ? props.theme.buttonTextHover : props.theme.blue)};
  }

  span {
    white-space: nowrap;
    text-decoration: none;
  }

  &:disabled {
    background-color: ${(props) => props.theme.disabledBackground};
    color: ${(props) => props.theme.disabledText};
    cursor: not-allowed;
  }

  ${(props) =>
    props.selected &&
    css`
      border-color: ${(props) => props.theme.blue};
    `};
`;

const NarrowButtonButton = styled.button`
  ${NarrowButtonStyles}
`;

const NarrowLink = styled.a`
  ${NarrowButtonStyles}
`;

export const NarrowButton = (props) => {
  if (props.onClick || props.type === "submit") {
    return (
      <NarrowButtonButton {...props} onClick={props.onClick}>
        <span>{props.children}</span>
      </NarrowButtonButton>
    );
  }
  return (
    <NarrowLink {...props} href={props.href}>
      <span>{props.children}</span>
    </NarrowLink>
  );
};

export const NavLinkStyles = css`
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 15px;
  color: ${(props) => props.theme.fontColors.primary};
  text-decoration: none;
  font-weight: 600;
  line-height: 18px;
  padding: 12px 30px;
  display: inline-block;
  width: 100%;

  :hover,
  :focus {
    color: ${(props) => props.theme.blue};
    text-decoration: none;
  }
`;

const NavLinkA = styled.a`
  ${NavLinkStyles}

  display: inline;
`;

const NavLinkButton = styled.button`
  border: 0;
  background: none;

  ${GreenButtonStyles}
`;

export const NavLink = (props) => {
  if (props.onClick) {
    return <NavLinkButton {...props}>{props.children}</NavLinkButton>;
  }
  //link
  return <NavLinkA {...props}>{props.children}</NavLinkA>;
};

export const TextLink = styled.button`
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 13px;
  font-weight: 600;
  background: transparent;
  padding-bottom: 1px;

  :hover {
    cursor: pointer;
    border-bottom: 1px dotted ${({ theme }) => theme.blue};
    padding-bottom: 0px;
  }

  :disabled {
    color: ${({ theme }) => theme.lightGray};
    pointer-events: none;
  }

  // support disabled class name for Link component from react-router-dom
  &.disabled {
    color: ${({ theme }) => theme.lightGray};
    pointer-events: none;
  }

  :focus,
  :active {
    outline-offset: -0.5px;
  }
`;
