import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import phone from "images/proposal_phone_icon.png";
import mail from "images/proposal_mail_icon.png";
import { theme } from "styles";

const Footer = () => (
  <View style={styles.footer} fixed>
    <Text>Need Assistance?</Text>
    <View style={styles.footerItem}>
      <Image src={mail} style={{ width: "17px", height: "10px", marginRight: "5px" }} />
      <Text>support@coterieinsurance.com</Text>
    </View>
    <View style={styles.footerItem}>
      <Image src={phone} style={{ width: "11px", height: "16px", marginRight: "5px" }} />
      <Text>(855) 566-1011</Text>
    </View>
  </View>
);

const styles = StyleSheet.create({
  footer: {
    position: "absolute",
    bottom: 0,
    fontSize: "11px",
    fontFamily: theme.font.typeface.secondary,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: 612,
    padding: "0 16px 16px",
    color: theme.colors.tertiary.zero,
  },
  footerItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

export default Footer;
