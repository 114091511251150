import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { RuleOptions } from "../Shared/types";
import RuleCard from "./RuleCard";
import { OptionType } from "styles/Dropdown";
import { laptop, tablet, TextLink } from "styles";
import { debounce } from "lodash";
import { useEffect } from "react";
import VariableSearchBox from "./VariableSearchBox";
import useGetUnderwritingRuleVariables from "hooks/underwritingRules/useGetUnderwritingVariables";
import useFilterUnderwritingRules from "hooks/underwritingRules/useFilterUnderwritingRules";
import SortByDropdown from "./SortByDropdown";
import { reportFailedFetchRules, reportFailedFetchVariables } from "utils/reportError";
import RuleFilters from "./RuleFilters";
import { RuleSearchContext } from "./RuleSearchContext";
import { FilterButton } from "./FilterComponents";
import { Filters, RuleStatus } from "./types";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface RuleSearchProps {
  goToRuleBuilder: (ruleToEdit: Rule) => void;
  rules: [];
  loadingRules: boolean;
}

export interface Rule {
  created: string;
  id: string;
  rule: Rule;
  updated: string;
  version: number;
  variable: string;
  disabled: boolean;
  options: RuleOptions[];
  label: string;
  tags?: string[];
}

const RuleSearch = ({ goToRuleBuilder, rules = [], loadingRules }: RuleSearchProps) => {
  const [ruleFilterIsOpen, setRuleFilterIsOpen] = useState(false);
  const [firstMountRulesHaveLoaded, setFirstMountRulesHaveLoaded] = useState(false);
  const {
    filteredRules,
    variableOptions,
    variableQuery,
    filters,
    setFilteredRules,
    setVariableOptions,
    setVariableQuery,
    setAvailableVariablesFromRules,
    setVariableFilter,
    clearVariableFilter,
    clearFilters,
    sortRules,
  } = useContext(RuleSearchContext);

  const buildFilterQuery = (filters: Filters) => {
    let params = new URLSearchParams();
    if (filters.variable) {
      params.append("Variable", filters.variable);
    }
    if (filters.tags.length) {
      params.append("Tag", filters.tags.join(","));
    }
    if (filters.control) {
      params.append("Control", filters.control);
    }
    if (filters.statuses.length === 1) {
      params.append("DisabledFlag", `${filters.statuses.includes(RuleStatus.InActive)}`);
    }
    return params.toString();
  };

  const {
    data: filterUnderWritingRulesDate,
    isLoading: isLoadingRuleList,
    isSuccess: filterUnderWritingRulesSuccess,
    error: underwritingRulesError,
    isError: hasUnderwritingRulesError,
    refetch: getFilteredRules,
  } = useFilterUnderwritingRules(buildFilterQuery(filters));

  useEffect(() => {
    if (filterUnderWritingRulesSuccess) {
      setFilteredRules(filterUnderWritingRulesDate);
      setFirstMountRulesHaveLoaded(true);
    }
    // disabling this warning because the setFilteredRules can't be memoized
    // a real solution would require rethinking this entire pattern
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterUnderWritingRulesSuccess, filterUnderWritingRulesDate]);

  useEffect(() => {
    if (hasUnderwritingRulesError) {
      reportFailedFetchRules(underwritingRulesError);
    }
  }, [hasUnderwritingRulesError, underwritingRulesError]);

  const {
    isLoading: isLoadingVariables,
    isSuccess: underwritingRulesVariablesSuccess,
    data: underWritingRulesVariables,
    error: underwritingRulesVariablesError,
  } = useGetUnderwritingRuleVariables(variableQuery, {
    enabled: Boolean(variableQuery),
  });

  useEffect(() => {
    if (underwritingRulesVariablesSuccess) {
      setVariableOptions(underWritingRulesVariables);
    }
    // disabling this warning because the setFilteredRules can't be memoized
    // a real solution would require rethinking this entire pattern
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [underwritingRulesVariablesSuccess, underWritingRulesVariables]);

  useEffect(() => {
    if (underwritingRulesVariablesError) {
      reportFailedFetchVariables(underwritingRulesVariablesError);
    }
  }, [underwritingRulesVariablesError]);

  const debounceVariableQuery = useCallback(
    debounce((search) => {
      setVariableQuery(search);
    }, 300),
    []
  );

  useEffect(() => {
    getFilteredRules().then(({ data }: { data?: Rule[] }) => {
      if (data) setAvailableVariablesFromRules(data);
    });
  }, []);

  return (
    <RuleSearchContainer>
      <TopRow>
        <VariableSearchBox
          options={variableOptions}
          onChange={(value: string) => setVariableFilter(value)}
          onInputChange={debounceVariableQuery}
          loading={isLoadingVariables || isLoadingRuleList || loadingRules}
          onClear={clearVariableFilter}
        />
        <TopRowRightButtons>
          <FilterButton onClick={() => setRuleFilterIsOpen(!ruleFilterIsOpen)} isSelected={ruleFilterIsOpen} />
          <ResetButton onClick={clearFilters}>Reset</ResetButton>
          <CSVLink data={rules}>
            <FontAwesomeIcon icon={faDownload as IconProp} style={{ marginRight: "0.3em", marginLeft: "3em" }} />{" "}
            Download CSV - All Rules
          </CSVLink>
        </TopRowRightButtons>
        {ruleFilterIsOpen && <RuleFilters onClose={() => setRuleFilterIsOpen(false)} />}
      </TopRow>

      {Boolean(filteredRules.length) && (
        <SortByDropdown
          onChange={(option: OptionType) => {
            sortRules(option.value as string);
          }}
        />
      )}

      {filteredRules?.map((rule: Rule) => (
        <RuleCard rule={rule} goToRuleBuilder={goToRuleBuilder} key={rule.id} />
      ))}

      {!filteredRules.length && firstMountRulesHaveLoaded && (
        <NoRulesFoundContainer>
          <p>No rules found for this criteria</p>
          <p>You may want to remove some filters to broaden your search</p>
        </NoRulesFoundContainer>
      )}
    </RuleSearchContainer>
  );
};

const TopRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  margin: 40px 0 55px 0;
  padding-bottom: 55px;
  border-bottom: 1px solid ${(props) => props.theme.regularGray};
`;

const TopRowRightButtons = styled.div`
  ${tablet} {
    width: 100%;
  }
`;

const RuleSearchContainer = styled.div`
  width: 80%;
  ${laptop} {
    width: 100%;
  }
`;

const ResetButton = styled(TextLink)`
  font-size: 16px;
  margin-left: 45px;
`;

const NoRulesFoundContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  p {
    color: ${(props) => props.theme.mediumGray};
    width: fit-content;
    :first-child {
      font-weight: 600;
      margin-bottom: 5px;
      font-size: 24px;
    }
    :second-child {
      font-size: 15px;
    }
  }
`;

export default RuleSearch;
