import useAuth from "./useAuth";
import { useMutation } from "@tanstack/react-query";
import { portalApi } from "./useAPI/instances";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

export type UpdateDefaultPaymentMethodArgs = {
  accountId: string | number;
  options?: {
    onSuccess?: (data: any) => void;
    onError?: (error: any) => void;
    onSettled?: (data: any, error: any) => void;
  };
};

export const usePutUpdateDefaultPaymentMethod = ({ accountId, options }: UpdateDefaultPaymentMethodArgs) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const postUpdateAgentPassword = async (tokenizedPaymentId: string) => {
    const token = await getToken();
    const api = portalApi(apiVersion, token);

    return api.put(`/accounts/policyholders/${accountId}/paymentMethod`, {
      tokenizedPaymentId,
    });
  };

  return useMutation({
    mutationFn: postUpdateAgentPassword,
    ...options,
  });
};

export default usePutUpdateDefaultPaymentMethod;
