import React from "react";
import styled, { css } from "styled-components";
import AddOns from "./AddOns/AddOns";
import Packages from "./Packages";
import { Header4, Paragraph, laptop, tablet } from "styles";
import { connect } from "react-redux";
import { selectHasAddOns, selectShowEndorsementPackages } from "redux/reducers/_availableEndorsements";

interface PropTypes {
  shouldDisplayEndorsementPackages: boolean;
  hasAddOns: boolean;
}

const Endorsements = ({ hasAddOns, shouldDisplayEndorsementPackages }: PropTypes) => {
  return (
    <>
      {shouldDisplayEndorsementPackages && (
        <>
          <StyledParagraph>These are recommend coverages based on your client’s industry selection.</StyledParagraph>
          <StyledHeader4>Endorsements</StyledHeader4>
          <Wrapper isEndorsements={true}>
            <Packages />
          </Wrapper>
        </>
      )}

      {hasAddOns && (
        <>
          <Header4>Add-Ons</Header4>
          <Wrapper isAddOns={true}>
            <AddOns />
          </Wrapper>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  shouldDisplayEndorsementPackages: selectShowEndorsementPackages(state),
  hasAddOns: selectHasAddOns(state),
});

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 24px;
`;

const StyledHeader4 = styled(Header4)`
  font-size: 20px;
  margin-bottom: 24px;
`;

const Wrapper = styled.div<{ isEndorsements?: boolean; isAddOns?: boolean }>`
  background: ${(props) => props.theme.white};
  padding: 8px;
  width: 100%;
  ${(props) =>
    props.isEndorsements &&
    css`
      display: flex;
      column-gap: 56px;
      padding: 0;

      ${laptop} {
        column-gap: 24px;
      }
      ${tablet} {
        flex-direction: column;
      }
    `}
  ${(props) =>
    props.isAddOns &&
    css`
      div.AddOnQuestionCont:first-of-type {
        padding-top: 0;
      }
      div.AddOnQuestionCont:last-of-type {
        border-bottom: none;
      }
    `}
`;

export default connect(mapStateToProps)(Endorsements);
