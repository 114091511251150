import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Tabs, Tab, Header } from "coterie-ui-library";
import AjaxLoader from "shared/AjaxLoader";
import { BreadcrumbContainer } from "styles/containerStyledComponents";
import useGetPolicy from "hooks/useGetPolicy";
import getQueryParam from "utils/getQueryParam";
import styled from "styled-components";
import ActivePolicyTitle from "./ActivePolicyTitle";
import PolicySelect from "./PolicySelect";
import ActivePolicyCancelModal from "./Menus/ActivePolicyCancelModal";
import LossRunRequest from "./LossRunRequest";
import CreateCOIModal from "./Menus/CreateCOIModal";
import useAuth from "hooks/useAuth";
import PolicyDocuments from "./Tabs/Documents";
import PolicyInformation from "./Tabs/Information";
import PolicyPaymentHistory from "./Tabs/PaymentHistory";
import useGetBillingBalance from "hooks/useGetBillingBalance";
import PolicyPaymentMethod from "./Tabs/PaymentMethod";

import { desktop } from "styles";
import PaymentDrawer from "./Menus/PaymentDrawer";
import { PaymentContext, PaymentProvider } from "context/payment";
import OverduePolicyToast from "./Menus/OverduePolicyToast";
import useGetAllPolicyBalances from "hooks/useGetAllPolicyBalances";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

enum ContentTabs {
  POLICY_INFORMATION = "information",
  POLICY_DOCUMENTS = "documents",
  POLICY_PAYMENT_HISTORY = "history",
  PAYMENT_METHOD = "payment-method",
}

interface RouteParams {
  policyNumber: string;
}

const ActivePolicyPage = ({ location }: RouteComponentProps<RouteParams>) => {
  const { user } = useAuth();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showLossRunModal, setShowLossRunModal] = useState(false);
  const [showCOIModal, setShowCOIModal] = useState(false);

  const policyNumberQueryParam = getQueryParam(location, "policyIds");
  const activeTab = getQueryParam(location, "tab") || ContentTabs.POLICY_INFORMATION;

  const { state } = useContext(PaymentContext);
  const { policyToCharge } = state;

  const handleTabChange = (tab: string) => {
    history.push({
      search: `?policyIds=${policyNumberQueryParam}&tab=${tab}`,
    });
  };

  const policyNumbers = policyNumberQueryParam?.split(",");
  const hasAPolicy = policyNumbers && policyNumbers?.length > 0;

  const { data: policies, isLoading: isLoadingPolicies, isSuccess: isPoliciesSuccess } = useGetPolicy(policyNumbers, {
    enabled: Boolean(hasAPolicy),
  });

  const { data: balances } = useGetAllPolicyBalances(policyNumbers, { enabled: Boolean(policies && policies.length > 0) });

  const [selectedPolicy, setSelectedPolicy] = useState<Policy>(policies?.[0] || {});

  useEffect(() => {
    if (isPoliciesSuccess) {   
      setSelectedPolicy(policies?.[0] || {});
    }
  }, [isPoliciesSuccess, policies]);

  const { data: paymentHistoryData } = useGetBillingBalance(selectedPolicy?.policyNumber);

  const isPolicyFound = policies && policies.length > 0 && policies?.[0].policyNumber;
  const hasOverdueCharge = (paymentHistoryData as any)?.isOverdue;

  const onPolicySelect = (policy: Policy) => {
    setSelectedPolicy(policy);
  };

  if (isLoadingPolicies) {
    return <AjaxLoader />;
  }

  if (isPolicyFound) {
    return (
      <ActivePolicyPageContainer>
        <PaymentDrawer
          onUpdatePaymentSuccess={() => {
            // Refetch payment method tab
            queryClient.refetchQueries({
              queryKey: ["payment_summary"],
              type: "active"
            });
          }}
          onOneTimePaymentSuccess={() => {
            queryClient.refetchQueries({
              queryKey: ["policy_balance"],
              type: "active",
            });
            toast.dismiss(policyToCharge?.policyNumber);
          }}
        />
        <ActivePolicyTitleContainer>
          <BreadcrumbContainer useUpdatedBackground={true}>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/accounts">Policy Search</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active data-cy="policy-view-header-breadcrumb">
                {selectedPolicy.businessName}
              </BreadcrumbItem>
            </Breadcrumb>
          </BreadcrumbContainer>

          <ActivePolicyTitle
            selectedPolicy={selectedPolicy}
            openCOIModal={() => setShowCOIModal(true)}
            openCancelModal={() => setShowCancelModal(true)}
            openLossRunModal={() => setShowLossRunModal(true)}
          />

          <StyledHeader as="h2">Policies</StyledHeader>
          <PolicySelect policies={policies} selectedPolicy={selectedPolicy} onPolicySelect={onPolicySelect} />
          <Tabs activeTab={activeTab} onTabChange={handleTabChange}>
            <Tab
              tabKey={ContentTabs.POLICY_INFORMATION}
              content="Policy Information"
              aria-label="select policy information"
            />
            <Tab
              tabKey={ContentTabs.POLICY_DOCUMENTS}
              content="Policy Documents"
              aria-label="select policy documents"
            />

            <Tab
              tabKey={ContentTabs.POLICY_PAYMENT_HISTORY}
              content={<span>Payment History {hasOverdueCharge && <RedDot />}</span>}
              aria-label="select policy payment history"
            />

            <Tab tabKey={ContentTabs.PAYMENT_METHOD} content="Payment Method" aria-label="update payment method" />
          </Tabs>
        </ActivePolicyTitleContainer>
        {activeTab === ContentTabs.POLICY_INFORMATION && <PolicyInformation policy={selectedPolicy} />}
        {activeTab === ContentTabs.POLICY_DOCUMENTS && <PolicyDocuments policy={selectedPolicy} />}

        {activeTab === ContentTabs.POLICY_PAYMENT_HISTORY && (
          <PolicyPaymentHistory policy={selectedPolicy} paymentHistory={paymentHistoryData} />
        )}

        {activeTab === ContentTabs.PAYMENT_METHOD && <PolicyPaymentMethod policy={selectedPolicy} />}
        <ActivePolicyCancelModal
          email={user?.email}
          policy={selectedPolicy}
          modalIsOpen={showCancelModal}
          closeModal={() => setShowCancelModal(false)}
        />
        <LossRunRequest
          email={user?.email}
          policy={selectedPolicy}
          modalIsOpen={showLossRunModal}
          closeModal={() => setShowLossRunModal(false)}
        />
        <CreateCOIModal
          email={user?.email}
          policy={selectedPolicy}
          modalIsOpen={showCOIModal}
          closeModal={() => setShowCOIModal(false)}
        />
        {policies && balances && <OverduePolicyToast policies={policies} balances={balances as PolicyBalance[]} />}
      </ActivePolicyPageContainer>
    );
  }

  return (
    <PolicyNotFoundContainer>
      <h3 data-cy="policy-not-found">Policy Not Found</h3>
      <Link className="btn btn-primary" to={`/policies`}>
        Return to Search
      </Link>
    </PolicyNotFoundContainer>
  );
};

const WrappedPolicyPage = (props: RouteComponentProps<RouteParams>) => {
  return (
    <PaymentProvider>
      <ActivePolicyPage {...props} />
    </PaymentProvider>
  );
};

const ActivePolicyTitleContainer = styled.div`
  width: 100%;
  padding: 30px 50px 0 50px;
  background-color: ${(props) => props.theme.pageComponents.ia.policyTitle.background};
  ${desktop} {
    padding: 30px 3vw 0 3vw;
  }
`;

const RedDot = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.pageComponents.ia.paymentHistory.dot};
  display: inline-block;
  margin-left: 0px;
  margin-bottom: 10px;
`;

const PolicyNotFoundContainer = styled.div`
  width: 100%;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 12px;
`;

const ActivePolicyPageContainer = styled.div`
  .overdue-policy-toast {
    margin-bottom: 12px;
  }
`;

export default WrappedPolicyPage;
