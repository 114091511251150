import { useQuery } from "@tanstack/react-query";
import { reportError } from "utils/reportError";
import { coterieAPI_v1_4 } from "./useAPI/instances";

const getCommercialIndustry = async (akHash: string) => {
  const api = coterieAPI_v1_4();
  const { data } = await api.get(`/commercial/industries/akHash/${akHash}`).catch((error) => {
    reportError(error);

    return Promise.reject(error);
  });

  return data;
};

type Options = {
  enabled?: boolean;
  select?: (data: any) => any;
};

const useGetPublicIndustry = (akHash: string, options?: Options) => {
  return useQuery({
    queryKey: ["commercial_industry", akHash],
    queryFn: () => getCommercialIndustry(akHash),
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...options,
  });
};

export default useGetPublicIndustry;
