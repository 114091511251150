import styled from "styled-components";
import { ProductIcon } from "coterie-ui-library";
import { PRODUCT_LABELS } from "utils/constants/product";

interface PolicySelectProps {
  policies: Policy[];
  selectedPolicy: Policy;
  onPolicySelect: (policy: Policy) => void;
}

const PolicySelect = ({ policies, selectedPolicy, onPolicySelect }: PolicySelectProps) => {
  return (
    <PolicySelectCont>
      {policies.map((policy, index) => {
        const { policyNumber, policyType } = policy;
        const isSelected = policyNumber === selectedPolicy.policyNumber;
        return (
          <PolicyButton
            key={index}
            isSelected={isSelected}
            policyType={policyType}
            role="tab"
            aria-selected={isSelected}
            aria-label={`select ${policyType} policy`}
            onClick={() => onPolicySelect(policy)}
          >
            <PolicyContainer>
              <StyledProductIcon type={policyType.toLowerCase()} height="42px" width="42px" />
              <TypeAndNumberContainer>
                {PRODUCT_LABELS[policyType]}
                <PolicyNumber>{`#${policy.policyNumber}`}</PolicyNumber>
              </TypeAndNumberContainer>
            </PolicyContainer>
          </PolicyButton>
        );
      })}
    </PolicySelectCont>
  );
};

export default PolicySelect;

const PolicySelectCont = styled.div`
  width: calc(100% + 16px);
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  margin-left: -16px;
`;

const PolicyButton = styled.button<{ isSelected: boolean; policyType: string }>`
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin: 16px;
  min-width: 308px;
  width: 308px;
  height: 80px;
  border-radius: 8px;
  border: ${({ theme, isSelected, policyType }) =>
    isSelected
      ? `2px solid ${theme.pageComponents.kit.policyView.policySelect.productType[policyType.toLowerCase()]}`
      : "none"};
  background: ${({ theme, isSelected }) =>
    isSelected
      ? theme.pageComponents.kit.policyView.policySelect.background.selected
      : theme.pageComponents.kit.policyView.policySelect.background.unselected};
  font-weight: 600;
  color: black;
  box-shadow: ${({ theme, isSelected }) =>
    isSelected ? theme.pageComponents.kit.policyView.policySelect.boxShadow : "none"};
`;

const StyledProductIcon = styled(ProductIcon)`
  margin-right: 8px;
  position: relative;
  top: -3px;
`;

const PolicyContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TypeAndNumberContainer = styled.div`
  text-align: left;
`;

const PolicyNumber = styled.div`
  color: ${({ theme }) => theme.pageComponents.kit.policyView.policySelect.policyNumber};
  font-size: 12px;
`;
