import React from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import { isFieldInvalid, selectInvalidField } from "redux/selectors/application";
import { formatCurrency, formatPhoneNumber } from "utils/formatNumber";

interface PropTypes {
  value: string;
  label: string;
  isInvalidField: boolean;
  invalidFieldDetails: { field: string; message: string };
  isCurrency?: boolean;
  isPhoneNumber?: boolean;
}

const Field = ({ value, label, isInvalidField, invalidFieldDetails, isCurrency, isPhoneNumber }: PropTypes) => {
  const formattedValue = (value: string) => {
    if (isCurrency) {
      return formatCurrency(Number(value));
    } else if (isPhoneNumber) {
      return formatPhoneNumber(value);
    } else {
      return value;
    }
  };

  const errorMessageWithoutCode =
    invalidFieldDetails?.message[5] === ":" ? invalidFieldDetails?.message.substring(7) : invalidFieldDetails?.message;

  return (
    <FieldContainer>
      <Label>
        <Property>{label}</Property>
      </Label>
      <Value
        invalid={isInvalidField}
        data-testid={`${isInvalidField ? "is" : "not"}-invalid-field`}
        title={errorMessageWithoutCode}
      >
        <Property data-cy={`${label.replace(/\s+/g, "-")}-field-value`}>{formattedValue(value)}</Property>
      </Value>
    </FieldContainer>
  );
};

const mapStateToProps = (state: ReduxState, ownProps: any) => ({
  isInvalidField: isFieldInvalid(ownProps.slug)(state),
  invalidFieldDetails: selectInvalidField(ownProps.slug)(state),
});

const Property = styled.span`
  align-self: center;
`;

const FieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  min-height: 40px;
  width: 100%;
  margin: 2px 0;
`;

const Label = styled.div`
  background: ${(props) => props.theme.labelGray};
  width: 150px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 1px;
  color: ${(props) => props.theme.charcoalGrey};
  display: flex;
  padding: 8px;
`;

const Value = styled.div<{ invalid?: boolean }>`
  width: 150px;
  display: flex;
  flex: 1;
  padding: 8px;
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.gray};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;

  ${(props) =>
    props.invalid &&
    css`
      border: 2px solid ${(props) => props.theme.red};
    `}
`;

export default connect(mapStateToProps)(Field);
