import React, { useState } from "react";
import { connect } from "react-redux";
import { EndorsementPackages } from "types/enums";
import { updateApplicationFormEndorsementsPackage } from "redux/reducers/_applicationReducer";
import { selectAvailableEndorsements } from "redux/reducers/_availableEndorsements";
import { selectApplicationFormEndorsementPackage } from "redux/selectors/application";
import { selectUnderwritingProfileId } from "redux/selectors/underwritingProfile";
import endorsement_silver_icon from "images/endorsement_silver.svg";
import endorsement_gold_icon from "images/endorsement_gold.svg";
import endorsement_platinum_icon from "images/endorsement_platinum.svg";
import EndorsementDetails from "./PackageDetails";
import SelectDetails from "styles/SelectDetails";

const endorsement_packages = [
  {
    type: EndorsementPackages.Silver,
    icon: endorsement_silver_icon,
    title: "Silver",
    description: "Designed for small businesses leasing their space.",
  },
  {
    type: EndorsementPackages.Gold,
    icon: endorsement_gold_icon,
    title: "Gold",
    description: "Ideal for small businesses who also own their building.",
  },
  {
    type: EndorsementPackages.Platinum,
    icon: endorsement_platinum_icon,
    title: "Platinum",
    description: "Ideal for small businesses who also own their building.",
  },
];

interface PropTypes {
  updateApplicationFormEndorsementsPackage: (ar0: string) => void;
  endorsementPackage: EndorsementPackages;
  underwritingProfileId: string;
  availableEndorsements: any;
  isReadOnly?: boolean;
}

const Packages = ({
  updateApplicationFormEndorsementsPackage,
  endorsementPackage,
  underwritingProfileId,
  availableEndorsements,
  isReadOnly = false,
}: PropTypes) => {
  const [details, setDetails] = useState("");

  const handleDetailsToggle = (endorsementPackage: string) => {
    setDetails(endorsementPackage);
  };

  const handleCheckboxToggle = (selectedEndorsementPackage: string) => {
    const endorsement =
      selectedEndorsementPackage !== endorsementPackage ? selectedEndorsementPackage : EndorsementPackages.None;
    updateApplicationFormEndorsementsPackage(endorsement);
  };

  const endorsementPackages = isReadOnly
    ? endorsement_packages.filter((e) => e.type === endorsementPackage)
    : underwritingProfileId
    ? endorsement_packages.filter((e) => availableEndorsements.endorsementPackage.includes(e.type))
    : endorsement_packages;

  return (
    <>
      {endorsementPackages.map((endorsement) => (
        <SelectDetails
          id={`quote-endorsement-${endorsement.title.toLowerCase()}-checkbox`}
          key={endorsement.type}
          expanded={details === endorsement.title}
          toggleDetails={handleDetailsToggle}
          toggleCheckbox={handleCheckboxToggle}
          title={endorsement.title}
          value={endorsement.type}
          iconSrc={endorsement.icon}
          selected={endorsementPackage === endorsement.type}
          datacy={`endorsement-type-${endorsement.type}`}
          description={endorsement.description}
        >
          <EndorsementDetails endorsementPackage={endorsement.type} />
        </SelectDetails>
      ))}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  endorsementPackage: selectApplicationFormEndorsementPackage(state),
  underwritingProfileId: selectUnderwritingProfileId(state),
  availableEndorsements: selectAvailableEndorsements(state),
});

const mapDispatchToProps = {
  updateApplicationFormEndorsementsPackage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
