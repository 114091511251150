import Modal, { ModalStyles } from "shared/ModalBasic";
import { Header, PremiumOptions, Page } from "utils/proposal-pdf/components";
import { Document, PDFViewer } from "@react-pdf/renderer";
import { connect } from "react-redux";
import { selectApplicationFormUserEmail, selectUserAgencyName, selectUserAgencyId } from "redux/selectors/user";

const mockApplication = {
  applicationTypes: ["BOP", "PL"],
};

const mockQuote = {
  channelPartnerId: 0,
  premium: 1140,
  monthlyPremium: 95,
  policyType: "",
  createdOn: "",
  isEstimate: false,
  applicationUrl: "",
  stateNoticeText: "",
  insuranceCarrier: "",
  ratingEngine: "",
  policyFee: 7,
  fees: [{ frequency: 12, amount: 7, description: "Installment Fee", feeType: "InstallmentFee", offset: 0 }],
  totalYearlyFees: 32,
  totalYearlyOwed: 152,
  month1Owed: 42,
  month1Fees: 32,
  monthlyFees: 7,
  monthlyOwed: 17,
  policyFees: 25,
  installmentFees: 7,
};

const mockAlternateQuote = {
  isOptInLowerCost: true,
  lossControlOptOutPremium: 1200,
  lossControlOptOutMonthlyPremium: 100,
  optInCostDifferenceYearly: 108,
};

interface Props {
  isOpen: boolean;
  agentEmail: string;
  agencyName?: string;
  agencyId?: number;
  closeModal: () => void;
  agencyLogo: string;
}

const PdfHeaderPreview = ({ isOpen, agentEmail, agencyName, closeModal, agencyLogo }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      label="quote-proposal-preview"
      title="Quote Proposal Preview"
      modalStyles={modalStyle}
    >
      <PDFViewer width="100%" height="100%">
        <Document>
          <Page>
            <Header agentEmail={agentEmail} agencyName={agencyName} agencyLogo={agencyLogo} />
            <PremiumOptions application={mockApplication} quote={mockQuote} alternateQuote={mockAlternateQuote} />
          </Page>
        </Document>
      </PDFViewer>
    </Modal>
  );
};

const modalStyle = {
  content: {
    ...ModalStyles.content,
    width: "848px",
    height: "60%",
  },
  overlay: ModalStyles.overlay,
};

const mapStateToProps = (state: ReduxState) => ({
  agentEmail: selectApplicationFormUserEmail(state),
  agencyName: selectUserAgencyName(state),
  agencyId: selectUserAgencyId(state),
});

export default connect(mapStateToProps)(PdfHeaderPreview);
