import React, { useState } from "react";
import Api from "utils/api";
import Modal from "shared/Modal";
import styled from "styled-components";
import { Alert, Button, FormGroup, Input, InputGroup, InputGroupAddon, Spinner } from "reactstrap";
import { CheckboxLabel } from "styles/containerStyledComponents";
import { ModalContentContainer, ActionContainer } from "shared/ModalComponents";
import { PolicyPaymentType } from "types/enums";
import { toastSuccess } from "utils/toast";
import { reportError } from "utils/reportError";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  reloadData: () => void;
  closeModal: () => void;
  modalIsOpen: boolean;
}

const CreateChannelPartnerModal = ({ reloadData, closeModal, modalIsOpen }: PropTypes) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isIA, setIsIA] = useState(false);
  const [allowBackdating, setAllowBackdating] = useState(false);
  const [premierPlatform, setPremierPlatform] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [policyPaymentType, setPolicyPaymentType] = useState<PolicyPaymentType>(PolicyPaymentType.PolicyHolder);
  const [nationalProducerNumber, setNationalProducerNumber] = useState("");
  const [error, setError] = useState("");
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const cancel = () => {
    setName("");
    setEmail("");
    setIsIA(false);
    setAllowBackdating(false);
    setPremierPlatform(false);
    setIsCreating(false);
    setPolicyPaymentType(PolicyPaymentType.PolicyHolder);
    setNationalProducerNumber("");
    setError("");
    closeModal();
  };

  const validatePartner = (): boolean => {
    if (name === "") {
      setError("Name is required");
      return false;
    }
    if (email === "") {
      setError("Email is required");
      return false;
    }
    return true;
  };

  const createPartner = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    if (!validatePartner()) return;

    const channelPartnerIA = {
      name: name,
      email: email,
      accountType: 0,
      nationalProducerNumber: nationalProducerNumber,
    };

    const channelPartner = {
      name: name,
      email: email,
      allowPolicyBackdating: allowBackdating,
      premierPlatform: premierPlatform,
      policyPaymentType: PolicyPaymentType[policyPaymentType],
    };

    const createChannelPartnerNetworkCall = isIA
      ? api.createAccountDashboard(channelPartnerIA)
      : api.channelPartners.create(channelPartner);

    setIsCreating(true);

    createChannelPartnerNetworkCall
      .then((channelPartner) => {
        if (channelPartner) {
          setName("");
          setEmail("");
          setIsIA(false);
          setAllowBackdating(false);
          setPremierPlatform(false);
          setIsCreating(false);
          setPolicyPaymentType(PolicyPaymentType.PolicyHolder);
          setNationalProducerNumber("");
          setError("");
          closeModal();
          reloadData();
          toastSuccess("Partner Created!");
        }
      })
      .catch((error) => reportError(error))
      .finally(() => setIsCreating(false));
  };

  return (
    <Modal isOpen={modalIsOpen} closeModal={cancel} label="create-channel-partner" title="Create Channel Partner">
      <ModalContentContainer>
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">Name</InputGroupAddon>
            <Input
              data-cy="name"
              placeholder="Name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">Email</InputGroupAddon>
            <Input
              data-cy="email"
              placeholder="Email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <CheckboxLabel>
            <Input data-cy={"isIA"} name={"isIA"} type={"checkbox"} checked={isIA} onChange={() => setIsIA(!isIA)} />{" "}
            Independent Agent Partner?
          </CheckboxLabel>
        </FormGroup>
        {isIA ? (
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">NPN</InputGroupAddon>
              <Input
                data-cy="national-producer-number"
                placeholder="National Producer Number"
                name="nationalProducerNumber"
                data-testid="national-producer-number"
                type="number"
                value={nationalProducerNumber}
                onChange={(e) => setNationalProducerNumber(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
        ) : (
          <React.Fragment>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">Policy Payment Type</InputGroupAddon>
                <Input
                  aria-label="Policy Payment Type"
                  data-cy="policy-payment-type"
                  name="policyPaymentType"
                  data-testid="policy-payment-type"
                  type="select"
                  value={policyPaymentType}
                  onChange={(e) => setPolicyPaymentType(PolicyPaymentType[e.target.value as PolicyPaymentType])}
                >
                  <option value={PolicyPaymentType.PolicyHolder}>Policy Holder</option>
                  <option value={PolicyPaymentType.ChannelPartner}>Channel Partner</option>
                </Input>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <CheckboxLabel>
                <Input
                  data-cy="allow-backdating"
                  name="allowBackdating"
                  type="checkbox"
                  checked={allowBackdating}
                  onChange={() => setAllowBackdating(!allowBackdating)}
                />{" "}
                Allow Policy Backdating
              </CheckboxLabel>
            </FormGroup>
            <FormGroup>
              <CheckboxLabel>
                <Input
                  data-cy="premier-platform"
                  name="premierPlatform"
                  type="checkbox"
                  checked={premierPlatform}
                  onChange={() => setPremierPlatform(!premierPlatform)}
                />{" "}
                Premier Platform Discount
              </CheckboxLabel>
            </FormGroup>
          </React.Fragment>
        )}

        <div>{error !== "" ? <Alert color="danger">{error}</Alert> : <React.Fragment />}</div>
      </ModalContentContainer>
      <ActionContainer>
        <ButtonContainer>
          <Button data-cy="cancel" color="cancel" onClick={cancel} disabled={isCreating}>
            Cancel
          </Button>
          <Button data-cy="submit" color="primary" onClick={createPartner} disabled={isCreating}>
            {isCreating ? <Spinner color="light" /> : <span>Create Partner</span>}
          </Button>
        </ButtonContainer>
      </ActionContainer>
    </Modal>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  width: 90%;
  height: 90%;

  button {
    flex: 1;
  }
`;

export default CreateChannelPartnerModal;
