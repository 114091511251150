import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { theme } from "styles";
import HolderDetailsRow from "./holderDetailsRow";
import { carrierTypes } from "utils/proposal-pdf/constants";
import { formatInTimeZone } from "date-fns-tz";

interface PolicyHolderDetailsProps {
  application: Application;
  industryName: string;
  naicCode?: number;
  quote?: Quote;
}

const PolicyHolderDetails = ({ application, industryName, naicCode, quote }: PolicyHolderDetailsProps) => {
  const { locations, dba, policyStartDate, policyEndDate } = application;

  const legalBusinessName = application?.businessName ?? application?.legalBusinessName;

  const businessAddress =
    locations &&
    locations[0] &&
    `${locations[0].street}, ${locations[0].city}, ${locations[0].state} ${locations[0].zip}`;

  const dates = proposedPolicyDates(policyStartDate, policyEndDate);
  const insuranceCarrier = quote?.quotes?.[0]?.insuranceCarrier ?? "";
  const insuredDetailsStyle = [styles.headerTitle, { marginTop: "12px" }];

  return (
    <View>
      <>
        <Text style={styles.headerTitle}>Policy Details</Text>
        {Boolean(dates) && <HolderDetailsRow title="Proposed Policy Dates" info={dates} />}
        <HolderDetailsRow title="Carrier" info={carrierTypes[insuranceCarrier].displayName} />
      </>

      <Text style={insuredDetailsStyle}>Insured Details</Text>
      <HolderDetailsRow title="Business Name" info={legalBusinessName} />
      {dba && <HolderDetailsRow title="DBA" info={dba} />}
      <HolderDetailsRow title="Business Address" info={businessAddress} />
      <HolderDetailsRow
        title="Industry"
        info={industryName}
        infoSecondLine={naicCode ? `(NAICS Code: ${naicCode})` : ""}
      />
    </View>
  );
};

const proposedPolicyDates = (policyStartDate?: string | Date, policyEndDate?: string | Date) => {
  if (Boolean(policyStartDate) && Boolean(policyEndDate)) {
    return `${formatInTimeZone(new Date(policyStartDate as string), "GMT", "M/d/yyyy")} - ${formatInTimeZone(
      new Date(policyEndDate as string),
      "GMT",
      "M/d/yyyy"
    )}`;
  }
  if (Boolean(policyStartDate)) {
    return formatInTimeZone(new Date(policyStartDate as string), "GMT", "M/d/yyyy");
  }
  return "";
};

const styles = {
  headerTitle: {
    fontFamily: theme.font.typeface.secondary,
    fontSize: "16px",
    marginBottom: "12px",
    color: theme.colors.tertiary.zero,
  },
  detailsText: {
    fontSize: "11px",
    fontFamily: theme.font.typeface.secondary,
  },
};

export default PolicyHolderDetails;
