import React, { useState } from "react";
import { Modal, ModalActionBar, ModalBody } from "coterie-ui-library";
import { Row } from "shared/ModalComponents";
import { Input } from "styles";
import { reportError } from "utils/reportError";

interface PropTypes {
  account: AccountType;
  editAgent: (agentId: number, updateRequest: UpdateAgentRequest) => Promise<void>;
  closeModal: () => void;
  modalIsOpen: boolean;
}

const EditAgentAccountModal = ({ account, editAgent, closeModal, modalIsOpen }: PropTypes) => {
  const [isEditing, setIsEditing] = useState(false);
  const [nationalProducerNumber, setNationalProducerNumber] = useState(account?.nationalProducerNumber);
  const [firstName, setFirstName] = useState(account?.firstName);
  const [lastName, setLastName] = useState(account?.lastName);
  const [email, setEmail] = useState(account?.email);

  const handleSubmit = (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    setIsEditing(true);

    const agencyId = account?.agencyDetails?.id ?? 0;
    const channelPartnerId = account?.channelPartnerDetails?.id ?? 0;
    const nationalProducerNumberUpdate =
      account?.nationalProducerNumber === nationalProducerNumber ? undefined : nationalProducerNumber;
    const agencyUpdate = account?.agencyDetails?.id === agencyId ? undefined : agencyId;
    const channelPartnerUpdate = account?.channelPartnerDetails?.id === channelPartnerId ? undefined : channelPartnerId;
    const firstNameUpdate = account?.firstName === firstName ? undefined : firstName;
    const lastNameUpdate = account?.lastName === lastName ? undefined : lastName;
    const emailUpdate = account?.email === email ? undefined : email;

    editAgent(account.id, {
      nationalProducerNumber: nationalProducerNumberUpdate,
      agencyId: agencyUpdate,
      channelPartnerId: channelPartnerUpdate,
      firstName: firstNameUpdate,
      lastName: lastNameUpdate,
      email: emailUpdate,
    })
      .catch((error) => reportError(error))
      .finally(() => setIsEditing(false));
  };

  return (
    <Modal title="Edit Agent Account" label="Edit Agent Account Screen" isOpen={modalIsOpen} closeModal={closeModal}>
      <ModalBody>
        <Row>
          <Input
            label="First Name"
            name="firstName"
            value={firstName}
            type="text"
            handleChange={(e) => setFirstName(e.target.value)}
            halfwidth
          />
          <Input
            label="Last Name"
            name="lastName"
            value={lastName}
            type="text"
            handleChange={(e) => setLastName(e.target.value)}
            halfwidth
          />
        </Row>
        <Row>
          <Input
            label="National Producer Number"
            name="nationalProducerNumber"
            value={nationalProducerNumber}
            type="number"
            maxLength={10}
            handleChange={({ value }) => setNationalProducerNumber(value)}
            halfwidth
          />
          <Input
            label="Agent Email Address"
            name="email"
            value={email}
            type="text"
            handleChange={(e) => setEmail(e.target.value)}
            halfwidth
          />
        </Row>
      </ModalBody>
      <ModalActionBar
        handleCTAOnClick={handleSubmit}
        ctaText={isEditing ? "..." : "Edit Account"}
        ctaDisabled={isEditing}
        handleSecondaryOnClick={closeModal}
        secondaryText={"Cancel"}
      />
    </Modal>
  );
};

export default EditAgentAccountModal;
