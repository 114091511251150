import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import { YesNoQuestion } from "coterie-ui-library";
import { Dropdown, Input, Label, tablet } from "styles";
import AddressSelector from "shared/application/AddressSelector";
import { CoverageType } from "shared/application/StyledComponents";
import { VALUE_OPTIONS, POLICY_ARIA_LABELS, POLICY_OPTIONS, POLICY_DEFAULTS } from "utils/constants";
import { findAndFormatErrorMessage } from "utils/formatErrorString";
import {
  selectApplicationFormBPPDeductible,
  selectApplicationFormBPPLimit,
  selectApplicationFormBuildingLimit,
  selectApplicationFormLocationType,
  selectApplicationFormRentVehicles,
  selectApplicationFormLocation,
  selectApplicationFormNonOwnedCoverage,
  selectApplicationFormHasSprinklers,
  selectApplicationQuoteErrors,
} from "redux/selectors/application";
import { LocationTypes } from "types/enums";
import { updateApplicationForm, updateApplicationFormLocation } from "redux/reducers/_applicationReducer";
import ToolTipIcon from "shared/ToolTipIcon";
import { formatCurrency } from "utils/formatNumber";
import { StyledSelect } from "styles/Dropdown";
import { StyledInput, MoneyInput, ErrorText } from "styles/Input";
import formotivInstance from "utils/formotiv";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  updateApplicationFormLocation: (arg0: object) => void;
  updateApplicationForm: (arg0: Application) => void;
  bppDeductible: number;
  employeeVehicles: string;
  bppLimit: number | undefined;
  locationType: string;
  buildingLimit: number | undefined;
  location: any;
  hasSprinklers: boolean | undefined;
  invalidFields: InvalidField[];
}

const BOPCoverages = ({
  updateApplicationFormLocation,
  updateApplicationForm,
  bppLimit,
  bppDeductible,
  locationType,
  buildingLimit,
  location,
  hasSprinklers,
  invalidFields = [],
}: PropTypes) => {
  const [bppLimitInputValue, setBppLimitInputValue] = useState(bppLimit);
  const [buildingLimitInputValue, setBuildingLimitInputValue] = useState(buildingLimit);

  const { increaseFlPropertyMin } = useFeatureFlags();

  const isFlorida = Boolean(location.state === "FL");

  const updateFireSuppression = (value: boolean) => {
    formotivInstance.trackCustom(
      `quote-has-sprinkler-system-${value === true ? "yes" : "no"}`,
      "yes/no button",
      `${value === true ? "yes" : "no"}`
    );
    updateApplicationFormLocation({ hasSprinklers: value });
  };
  const updateLocationType = (selectedOption: any) => {
    if (window.formotiv) {
      formotivInstance.trackCustom("quote-location-type");
    }
    updateApplicationFormLocation({ locationType: selectedOption.value });
  };

  const changeBusinessAddress = (address: Address, isVerified: boolean) => {
    if (isVerified) {
      updateApplicationFormLocation({
        street: address.street,
        state: address.state,
        city: address.city,
        zip: address.zip,
      });
    }
  };

  const handleChangeBppLimit = (values: { floatValue: number }) => {
    const { floatValue } = values;
    setBppLimitInputValue(floatValue);
  };

  const handleChangeBuildingLimit = (values: { floatValue: number }) => {
    const { floatValue } = values;
    setBuildingLimitInputValue(floatValue);
  };

  const handleBlur = (event: React.FormEvent<HTMLInputElement>) => {
    let { name, value } = event.currentTarget;
    const finalValue = Number(value.replace(/[^0-9.]+/g, ""));
    updateBopCoverage(finalValue, name);
  };

  const updateDropdown = (amount: number, name?: any) => {
    if (window.formotiv) {
      formotivInstance.trackCustom("quote-property-claims-deductible");
    }
    updateApplicationForm({ [name]: amount });
  };

  const updateBopCoverage = (amount: number, name?: any) => {
    updateApplicationFormLocation({ [name]: amount });
  };

  const propertyClaimsDeductibleOptions = VALUE_OPTIONS[
    increaseFlPropertyMin && isFlorida
      ? POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE_FL
      : POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE
  ].map((amount) => {
    return { value: amount, label: formatCurrency(amount) };
  });

  const showBuildingLimit = locationType && locationType === LocationTypes.BuildingOwned;

  useEffect(() => {
    setBuildingLimitInputValue(buildingLimit);
  }, [buildingLimit]);

  useEffect(() => {
    setBppLimitInputValue(bppLimit);
  }, [bppLimit]);

  return (
    <>
      <OverrideStyle />
      <PropertyDetailsHeader>Property Details</PropertyDetailsHeader>
      <FlexContainer>
        <InputContainer width={312}>
          <Label>
            Business Address
            <ToolTipIcon contentKey={"BUSINESS_ADDRESS_READ_ONLY"} />
          </Label>
          <AddressSelector
            id="quote-business-address"
            street={location.street}
            city={location.city}
            state={location.state}
            zip={location.zip}
            changeAddress={changeBusinessAddress}
            name="bop-business"
            readOnly
            hideManualAddressButton
          />
        </InputContainer>
        <InputContainer width={248}>
          <Dropdown
            id="quote-location-type"
            label="Location Type"
            name="locationType"
            onChange={updateLocationType}
            options={locationOptions}
            value={locationType}
            full
          />
        </InputContainer>
        {showBuildingLimit && !isFlorida && (
          <InputContainer width={312}>
            <Input
              id="quote-building-limit"
              name="buildingLimit"
              value={buildingLimitInputValue}
              handleChange={handleChangeBuildingLimit}
              handleBlur={handleBlur}
              prefix="$"
              defaultValue={POLICY_DEFAULTS.BOP.locations[0].buildingLimit}
              label={POLICY_ARIA_LABELS[POLICY_OPTIONS.BUILDING_LIMIT]}
              error={Boolean(invalidFields.find((f) => f.field === "BuildingLimit"))}
              errorText={findAndFormatErrorMessage("BuildingLimit", invalidFields)}
              placeholder="Enter Limit"
              toolTipContent="BUILDING_LIMIT"
            />
          </InputContainer>
        )}
        {showBuildingLimit && isFlorida && (
          <InputContainer width={312}>
            <Label>{POLICY_ARIA_LABELS[POLICY_OPTIONS.BUILDING_LIMIT]}</Label>
            <UnavailableText>Building coverage is unavailable in Florida</UnavailableText>
          </InputContainer>
        )}
      </FlexContainer>
      <FlexContainer>
        <InputContainer width={312}>
          <Input
            id="quote-business-personal-property-limit"
            name="bppLimit"
            value={bppLimitInputValue}
            handleChange={handleChangeBppLimit}
            handleBlur={handleBlur}
            prefix="$"
            defaultValue={POLICY_DEFAULTS.BOP.locations[0].bppLimit}
            label={POLICY_ARIA_LABELS[POLICY_OPTIONS.BUSINESS_PERSONAL_PROPERTY_LIMIT]}
            error={Boolean(invalidFields.find((f) => f.field === "BppLimit"))}
            errorText={findAndFormatErrorMessage("BppLimit", invalidFields)}
            toolTipContent={"BUSINESS_PERSONAL_PROPERTY_LIMIT"}
            data-testid="bppLimit"
          />
        </InputContainer>
        <InputContainer width={248}>
          <Dropdown
            id="quote-property-claims-deductible"
            options={propertyClaimsDeductibleOptions}
            onChange={(option) => updateDropdown(option.value, "bppDeductible")}
            label="Property Claims Deductible"
            value={bppDeductible}
            name="bppDeductible"
            placeholder="Select Deductible"
            error={Boolean(invalidFields.find((f) => f.field === "bppDeductible"))}
            errorText={findAndFormatErrorMessage("bppDeductible", invalidFields)}
            defaultValue={propertyClaimsDeductibleOptions[0]}
            full
          />
        </InputContainer>
        <SprinklerQuestionContainer>
          <YesNoQuestion
            question="Does this location have an automatic sprinkler system?"
            value={hasSprinklers}
            selectYes={() => updateFireSuppression(true)}
            selectNo={() => updateFireSuppression(false)}
            name="sprinkler-system"
            questionMaxWidth="300px"
          />
        </SprinklerQuestionContainer>
      </FlexContainer>
      {increaseFlPropertyMin && isFlorida && (
        <FlexContainer>
          <InputContainer width={312}>
            <Input
              name="windHailDeductible"
              value="5% subject to $2,500 minimum"
              label="Wind and Hail Deductible"
              readOnly
            />
          </InputContainer>
        </FlexContainer>
      )}
    </>
  );
};

export const locationOptions = [
  { value: LocationTypes.BuildingLeased, label: "Leased Premises" },
  { value: LocationTypes.Home, label: "Home-based" },
  { value: LocationTypes.BuildingOwned, label: "Building Owned & Occupied" },
];

const mapStateToProps = (state: ReduxState) => ({
  bppDeductible: selectApplicationFormBPPDeductible(state),
  bppLimit: selectApplicationFormBPPLimit(state),
  rentVehicles: selectApplicationFormRentVehicles(state),
  employeeVehicles: selectApplicationFormNonOwnedCoverage(state),
  buildingLimit: selectApplicationFormBuildingLimit(state),
  locationType: selectApplicationFormLocationType(state),
  location: selectApplicationFormLocation(state),
  hasSprinklers: selectApplicationFormHasSprinklers(state),
  invalidFields: selectApplicationQuoteErrors(state),
});

const mapDispatchToProps = { updateApplicationForm, updateApplicationFormLocation };

const FlexContainer = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
  flex-wrap: wrap;
`;

const InputContainer = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  margin-bottom: 24px;
  ${tablet} {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

const SprinklerQuestionContainer = styled.span`
  display: flex;
  justify-content: center;
  height: fit-content;
  margin-top: 24px;
  button {
    width: 42px;
    height: 32px;
    font-size: 13px;
  }
  label {
    font-weight: 400;
    font-size: 13px;
    max-width: 180px;
  }
  div {
    height: inherit;
  }
  ${tablet} {
    margin-top: 0;
  }
`;

const PropertyDetailsHeader = styled(CoverageType)`
  margin-bottom: 24px;
`;

const UnavailableText = styled.p`
  font-size: 15px;
  font-family: ${(props) => props.theme.secondaryFont};
  color: #626266;
`;

const OverrideStyle = createGlobalStyle`
  ${FlexContainer} {
    ${Label} {
      font-size: 13px;
    }
    ${StyledSelect}, ${StyledInput}, ${MoneyInput} {
     margin-bottom: 0 !important;
    }
    ${ErrorText} {
      margin-bottom: -15px;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(BOPCoverages);
