import React, { useContext } from "react";
import { RuleOptions } from "../../Shared/types";
import RuleTextInput from "./RuleTextInput";
import { RuleLogicBlock, LogicBlockHeader, FlexDiv, DeleteLogicBlockButtonContainer } from "../styles";
import { RuleBuilderContext } from "../RuleBuilderContext";
import DeleteButton from "../DeleteButton";

interface MessageProps {
  ruleOption: RuleOptions;
}
const MessageBlock = ({ ruleOption }: MessageProps) => {
  const { updateLogicBlock, updateMessage, deleteBlock, activeRulePath, activeLogicBlock } = useContext(
    RuleBuilderContext
  );
  const isFirstRuleOption = ruleOption.path === "0.options.0";
  const isSelected = ruleOption.path === activeRulePath && activeLogicBlock === "message";

  return (
    <>
      {isFirstRuleOption && <LogicBlockHeader>Include Message...</LogicBlockHeader>}
      <RuleLogicBlock
        isSelected={isSelected}
        onFocus={() => updateLogicBlock("message")}
        onClick={() => updateLogicBlock("message")}
      >
        <FlexDiv style={{ marginBottom: "10px", marginTop: "5px" }}>
          {!isFirstRuleOption && <LogicBlockHeader>Include Message...</LogicBlockHeader>}{" "}
        </FlexDiv>
        <RuleTextInput
          defaultValue={ruleOption.message}
          updateRuleProperty={(message: string) => updateMessage(message)}
          updateKey="message"
          inputStyles={{ width: 320, marginBottom: 0 }}
          name="ruleMessageInput"
          type="textArea"
          label="include message"
        />
        <DeleteLogicBlockButtonContainer>
          <DeleteButton
            ariaLabel="delete message"
            isDisabled={!isSelected}
            onClick={() => deleteBlock("message")}
          ></DeleteButton>
        </DeleteLogicBlockButtonContainer>
      </RuleLogicBlock>
    </>
  );
};

export default MessageBlock;
