import React from "react";
import { View } from "@react-pdf/renderer";
import AddOnsHeader from "./AddOnsHeader";
import AddOnsItem from "./AddOnsItem";
import { AddOnObj } from "../../constants";

type PropTypes = {
  addOnsArray: AddOnObj[];
};

const AddOns = ({ addOnsArray }: PropTypes) => {
  return (
    <View style={styles.addOnsContainer}>
      <AddOnsHeader title="Policy Add Ons" />
      {addOnsArray.map((addOn) => {
        const { title, description, limit, sublimit } = addOn;
        return <AddOnsItem key={title} title={title} description={description} limit={limit} sublimit={sublimit} />;
      })}
    </View>
  );
};

const styles = {
  addOnsContainer: {
    marginBottom: 32,
  },
};

export default AddOns;
