import { HttpMethods } from ".";

interface Request {
  url: string;
  transformResponse?: (arg0: string) => any;
  defaultState?: any;
  public?: boolean;
  method?: HttpMethods;
  fetchOnMount?: boolean;
  version?: string;
}

type RequestDictionary = {
  [key: string]: (...args: any[]) => Request;
};

const requests: RequestDictionary = {
  getPolicyDocumentList: (policyNumber: string, apiVersion: string) => ({
    url: `/policies/${policyNumber}/docs`,
    transformResponse: (res: any) => {
      return res.data.map((doc: PolicyDocumentLink) => {
        return { link: `${process.env.REACT_APP_PORTAL_API_BASE_URL}/${apiVersion}${doc.link}`, name: doc.name };
      });
    },
    defaultState: [],
  }),
  publicGetIndustry: (akHash: string) => ({
    public: true,
    url: `/commercial/industries/akHash/${akHash}`,
  }),
  requestPasswordReset: (accountId: string) => ({
    url: `/accounts/${accountId}/resetPassword`,
    method: HttpMethods.POST,
    fetchOnMount: false,
  }),
  updateAgency: (agencyId: number) => ({
    url: `/agencies/${agencyId}`,
    method: HttpMethods.PUT,
    fetchOnMount: false,
  }),
  updateChannelPartner: (partnerId: number) => ({
    url: `/channelpartners/${partnerId}`,
    method: HttpMethods.PUT,
    fetchOnMount: false,
  }),
  getChannelPartner: (partnerId: number) => ({
    url: `/channelpartners/${partnerId}`,
  }),
  getAccount: (accountId: string) => ({
    url: `/accounts/${accountId}`,
  }),
  updatePayment: (id: number) => ({
    url: `/accounts/${id}/updatePayment`,
    method: HttpMethods.POST,
    fetchOnMount: false,
  }),
  createRule: (rule: string[]) => ({
    url: `/UnderwritingRule`,
    method: HttpMethods.POST,
  }),
  updateRule: (rule: string[]) => ({
    url: `/UnderwritingRule`,
    method: HttpMethods.PUT,
  }),
  createUnderwritingProfile: (businessProfile: any) => ({
    url: "/underwriting",
    method: HttpMethods.POST,
    fetchOnMount: false,
  }),
  getUnderwritingProfile: (applicationId: string) => ({
    url: `/underwriting/${applicationId}/application`,
    method: HttpMethods.GET,
  }),
  getUnderwritingProfileConfidenceData: (underwritingProfileId: string) => ({
    url: `/underwriting/${underwritingProfileId}/?afterAction=FetchConfidenceData`,
    method: HttpMethods.GET,
    fetchOnMount: false,
  }),
  confirmUnderwritingProfile: (underwritingProfileId: string) => ({
    url: `/underwriting/${underwritingProfileId}/confirm`,
    method: HttpMethods.PUT,
    fetchOnMount: false,
  }),
  updateUnderwritingProfile: (underwritingProfile: any) => ({
    url: "/underwriting",
    method: HttpMethods.PUT,
    fetchOnMount: false,
  }),
  getUnderwritingAppetite: () => ({
    url: "/underwritingappetite/all",
  }),
  enableUnderwritingAppetite: (sleuthId) => ({
    url: `/underwritingappetite/${sleuthId}/enable`,
    method: HttpMethods.PUT,
  }),
  disableUnderwritingAppetite: (sleuthId) => ({
    url: `/underwritingappetite/${sleuthId}/disable`,
    method: HttpMethods.PUT,
  }),
  getUnderwritingCompetitors: () => ({
    url: "underwriting/competitors",
    method: HttpMethods.GET,
  }),
};

export default requests;
