import React, { useState } from "react";
import FailedTransactionsReportTable from "kit/FailedTransactionsReportTable";
import { ContentContainer, Title, TitleBarAccessory, TitleContainer } from "styles/containerStyledComponents";
import useAuth from "hooks/useAuth";
import { useSelector } from "react-redux";
import { getUserExternalId } from "../../redux/selectors/user";
import useGetFailedTransactions from "hooks/useGetFailedTransactions";
import styled from "styled-components";
import useGetAgencyProducers, { GetAgencyProducersResponse } from "hooks/useGetAgencyProducers";
import { Dropdown } from "styles";

const FailedTransactionsReport = () => {
  const externalId = useSelector(getUserExternalId);
  const { data: producers } = useGetAgencyProducers({});
  const { isIAUser } = useAuth();
  const [currentExternalId, setCurrentExternalId] = useState(isIAUser ? externalId : undefined);
  const {
    data: failedTransactionsData,
    refetch,
    isLoading,
    isFetching,
  } = useGetFailedTransactions({ externalId: currentExternalId });

  const handleFilterOnChange = (producerOption: GetAgencyProducersResponse) => {
    setCurrentExternalId(!producerOption ? externalId : producerOption.value);
  };

  return (
    <ContentContainer>
      <TitleContainer>
        <Title>Failed Transactions Report</Title>
        <TitleBarAccessory>
          {isIAUser && (
            <DropdownWrapper>
              {producers && producers.length > 0 && (
                <>
                  <DropdownLabel htmlFor="failedTransactionsFilter-dropdown">Filter by Users</DropdownLabel>
                  <Dropdown
                    onChange={handleFilterOnChange}
                    options={producers}
                    value={currentExternalId}
                    name="failedTransactionsFilter"
                    labelIsHidden
                    isClearable={externalId !== currentExternalId}
                  />
                </>
              )}
            </DropdownWrapper>
          )}
        </TitleBarAccessory>
      </TitleContainer>
      <FailedTransactionsReportTable
        failedTransactions={failedTransactionsData ?? []}
        isLoading={isLoading || isFetching}
        onRefresh={() => refetch()}
      />
    </ContentContainer>
  );
};

export const DropdownWrapper = styled.div`
  align-items: flex-end;
  flex-direction: column;
  width: 255px;
  margin-left: auto;
  margin-top: 16px;
  z-index: 2;
  position: relative;
`;

export const DropdownLabel = styled.label`
  color: ${(props) => props.theme.textNavyBlue};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.75px;
  line-height: 16px;
`;

export default FailedTransactionsReport;
