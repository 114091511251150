import React, { useState } from "react";
import ChannelPartnersTable from "kit/channelPartners/ChannelPartnersTable";
import { PageContainer, Title, TitleContainer } from "styles/containerStyledComponents";
import CreateChannelPartnerModal from "kit/channelPartners/CreateChannelPartnerModal";
import ActionsDropdown from "styles/ActionsDropdown";
import useGetChannelPartners from "hooks/useGetChannelPartners";

const ChannelPartners = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { data, refetch, isSuccess, isLoading } = useGetChannelPartners();
  const channelPartners = !isLoading && isSuccess ? data : [];

  const dropdownActions = [
    {
      name: "Create Partner",
      action: () => {
        setShowCreateModal(true);
      },
    },
  ];

  return (
    <PageContainer>
      <TitleContainer>
        <Title>Channel Partners</Title>
        <ActionsDropdown actions={dropdownActions} />
      </TitleContainer>

      <ChannelPartnersTable channelPartners={channelPartners} isLoading={isLoading} />

      <CreateChannelPartnerModal
        reloadData={refetch}
        closeModal={() => setShowCreateModal(false)}
        modalIsOpen={showCreateModal}
      />
    </PageContainer>
  );
};

export default ChannelPartners;
