const removeEmpty = (obj: any) => {
  Object.entries(obj).forEach(
    ([key, val]) =>
      (val && typeof val === "object" && removeEmpty(val)) || ((val === null || val === "") && delete obj[key])
  );
  return obj;
};

export const prettyPrintJSON = (json: string) => {
  if (json === "" || json === "{}") return "";

  try {
    return JSON.stringify(removeEmpty(JSON.parse(json)), null, 2);
  } catch (err) {
    return json;
  }
};
