import { reportFormotivFailed } from "./reportError";

class Formotiv {
  config: any; // reference to the config set up during class construction
  createInstance() {
    try {
      var formotivConfig = window.formotiv.FormotivDefaultConfig.Instance(process.env.REACT_APP_FORMOTIV_ID);
      formotivConfig.api = {
        consolehost: "https://app-test.formotiv.com",
        apihost: process.env.REACT_APP_FORMOTIV_ENDPOINT,
      };

      // uncomment for console logs / debugging
      // formotivConfig.loglevel = window.formotiv.LogLevel.Debug;

      // not sure what the data types should be here
      formotivConfig.discovery.customElementNamesHandler = function (input: any, defaultVal: any) {
        return input.id || defaultVal;
      };
      this.config = formotivConfig;
    } catch (e) {
      reportFormotivFailed("Failed to download Formotiv Scripts!", {
        name: "formotiv error",
        data: {
          e,
        },
      });
    }
  }
  init(
    agentId: string,
    pageLabel: string,
    applicationId: string | undefined,
    underwritingProfileId: string | undefined
  ) {
    this.config.features.agentsettings = {
      enabled: true,
    };
    if (underwritingProfileId && pageLabel !== "get-started") {
      this.config.state.ptv1 = underwritingProfileId;
    } else {
      this.config.state.ptv1 = null;
    }
    if (applicationId && (pageLabel === "quote" || pageLabel === "confirm")) {
      this.config.state.ptv4 = applicationId;
    }
    if (agentId) {
      this.config.state.ptv2 = agentId; //user.account.id
      this.config.discovery.altURIHandler = {
        enabled: true,
        altFormURI: pageLabel,
      };
      window.formotiv.init(this.config);
    }
  }
  submit() {
    window.formotiv.submit(this.config);
  }
  submitFinal() {
    if (window && window.formotiv && this.config) {
      window.formotiv.submitFinal(this.config);
    }
  }
  trackCustom(id: string, type?: string, value?: any) {
    if (window && window.formotiv && this.config && id) {
      window.formotiv.inputActivity(this.config, id, type ? type : "select", "selection created", value ? value : id);
    }
  }
}

const formotiv = new Formotiv();

export default formotiv;
