import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { getCoverageTitle } from "utils/proposal-pdf/helpers";
import { AlternateQuoteType } from "utils/proposal-pdf/constants";
import { theme } from "styles";
import { PolicyType } from "types/enums";
import CheckmarkIcon from "images/checkmark-light-blue.png";

interface PremiumOptionsPropTypes {
  application: Application;
  quote: Quote;
  alternateQuote: AlternateQuoteType;
}

const PremiumOptions = ({ application, quote, alternateQuote }: PremiumOptionsPropTypes) => {
  const {
    premium,
    monthlyPremium,
    totalYearlyOwed,
    month1Owed,
    installmentFees,
    policyFees,
    lossControlFees = 0,
    monthlyFees,
    monthlyOwed,
  } = quote;

  const {
    isOptInLowerCost,
    lossControlOptOutPremium: optOutPremium,
    lossControlOptOutMonthlyPremium: optOutMonthlyPremium,
  } = alternateQuote;

  const fees = quote?.quotes?.[0]?.fees ?? [];

  const hasFirstMonthWaived = fees?.find((fee) => fee?.description === "Installment Fee")?.offset === 1;
  const month1InstallmentFees = hasFirstMonthWaived ? 0 : installmentFees;

  const totalMonthlyPayments = (monthlyOwed as number) * 11 + (month1Owed as number);

  const applicationTypes = application.applicationTypes as PolicyType[];
  const coverageTitle = getCoverageTitle(applicationTypes);

  const hasLossControl = application.lossControl === "OptIn";
  const showPremiumStrikethrough = hasLossControl && isOptInLowerCost;

  const formatCurrency = (value?: number, fallback: number = 0) => {
    const num = value ?? fallback;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(num);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.policyTypesTitle}>{coverageTitle}</Text>
      <View style={styles.tableContainer}>
        <View style={styles.headerContainer}>
          <View style={[styles.row, styles.centerText, styles.textLarge]}>
            <View style={[styles.cell, styles.borderRight, styles.headerRowCell, styles.leftCell]}>
              <Text> </Text>
            </View>
            <View style={[styles.cell, styles.cellBlueBackground, styles.headerRowCell, styles.borderRight]}>
              <Text>Yearly Option</Text>
            </View>
            <View style={[styles.cell, styles.borderTopRight, styles.headerRowCell]}>
              <Text>Monthly Option</Text>
            </View>
          </View>
          <View style={styles.valueBox}>
            <Text style={styles.valueText}>Best Value!</Text>
          </View>

          <View style={[styles.row, styles.centerText, styles.textPrimary]}>
            <View style={[styles.cell, styles.borderRight, styles.leftCell]}>
              <Text
                style={[styles.rightText, styles.textMedium, styles.textLetterSpacingWide, styles.textLargePadding]}
              >
                Total due to bind:
              </Text>
            </View>
            <View style={[styles.cell, styles.cellBlueBackground, styles.borderRight]}>
              <Text style={styles.textLarge}>{formatCurrency(totalYearlyOwed)}</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={styles.textLarge}>{formatCurrency(month1Owed)}</Text>
            </View>
          </View>
        </View>

        <View style={[styles.row, styles.centerText]}>
          <View style={[styles.cell, styles.borderRight, styles.leftCell, styles.topPadding]}>
            <Text style={[styles.rightText, styles.textGray]}>Premium:</Text>
            {showPremiumStrikethrough && (
              <View style={[styles.row, styles.bottomRight]}>
                <Image style={styles.checkmark} src={CheckmarkIcon} />
                <Text style={[styles.rightText, styles.discountText]}> Manage My Risk Discount</Text>
              </View>
            )}
          </View>
          <View
            style={[
              styles.cell,
              styles.cellBlueBackground,
              styles.borderRight,
              styles.textLetterSpacing,
              styles.textLeft,
              styles.topPadding,
            ]}
          >
            <Text>
              <Text style={[styles.rightPadding, styles.width50]}>{formatCurrency(premium)}</Text>
              {showPremiumStrikethrough && (
                <>
                  <Text> </Text>
                  <Text style={styles.strikethrough}>{formatCurrency(optOutPremium)}</Text>
                </>
              )}
            </Text>
          </View>
          <View style={[styles.cell, styles.textLetterSpacing, styles.textLeft, styles.topPadding]}>
            <Text>
              <Text style={styles.rightPadding}>{formatCurrency(monthlyPremium)}</Text>
              {showPremiumStrikethrough && (
                <>
                  <Text> </Text>
                  <Text style={styles.strikethrough}>{formatCurrency(optOutMonthlyPremium)}</Text>
                </>
              )}
            </Text>
          </View>
        </View>

        {Boolean(policyFees) && (
          <View style={[styles.row, styles.centerText]}>
            <View style={[styles.cell, styles.borderRight, styles.leftCell]}>
              <Text style={[styles.rightText, styles.textGray]}>Annual Policy Fee:</Text>
            </View>
            <View
              style={[
                styles.cell,
                styles.cellBlueBackground,
                styles.borderRight,
                styles.textLetterSpacingWider,
                styles.textLeft,
              ]}
            >
              <Text>{formatCurrency(policyFees)}</Text>
            </View>
            <View style={[styles.cell, styles.textLetterSpacingWider, styles.textLeft]}>
              <Text>{formatCurrency(policyFees)}</Text>
            </View>
          </View>
        )}

        {Boolean(month1InstallmentFees) && (
          <View style={[styles.row, styles.centerText]}>
            <View style={[styles.cell, styles.borderRight, styles.leftCell]}>
              <Text style={[styles.rightText, styles.textGray]}>Installment Fee:</Text>
            </View>
            <View
              style={[
                styles.cell,
                styles.cellBlueBackground,
                styles.borderRight,
                styles.textLetterSpacingWider,
                styles.textLeft,
              ]}
            >
              <Text>{formatCurrency(month1InstallmentFees)}</Text>
            </View>
            <View style={[styles.cell, styles.textLetterSpacingWider, styles.textLeft]}>
              <Text>{formatCurrency(month1InstallmentFees)}</Text>
            </View>
          </View>
        )}

        {hasLossControl && (
          <View style={[styles.row, styles.centerText]}>
            <View style={[styles.cell, styles.borderRight, styles.leftCell]}>
              <Text style={[styles.rightText, styles.textGray]}>Manage My Risk Program:</Text>
            </View>
            <View
              style={[
                styles.cell,
                styles.cellBlueBackground,
                styles.borderRight,
                styles.textLetterSpacingWider,
                styles.textLeft,
              ]}
            >
              <Text>{formatCurrency(lossControlFees * 12)}</Text>
            </View>
            <View style={[styles.cell, styles.textLetterSpacingWider, styles.textLeft]}>
              <Text>{formatCurrency(lossControlFees)}</Text>
            </View>
          </View>
        )}

        <View style={[styles.row, styles.centerText]}>
          <View style={[styles.cell, styles.borderRight, styles.leftCell]}>
            <View style={[styles.hr]} />
          </View>
          <View style={[styles.cell, styles.cellBlueBackground, styles.borderRight]}>
            <View style={[styles.hr]} />
          </View>
          <View style={styles.cell}>
            <View style={[styles.hr]} />
          </View>
        </View>

        <View style={[styles.row, styles.centerText]}>
          <View style={[styles.cell, styles.borderRight, styles.leftCell]}>
            <Text style={[styles.rightText, styles.textGray]}>Future Monthly Payment:</Text>
          </View>
          <View
            style={[
              styles.cell,
              styles.cellBlueBackground,
              styles.borderRight,
              styles.textLetterSpacingWider,
              styles.textLeft,
            ]}
          >
            <Text style={styles.textNa}>N/A</Text>
          </View>
          <View style={[styles.cell, styles.textLetterSpacingWider, styles.textLeft]}>
            <Text style={[styles.monthlyPayment, styles.textGray]}>{formatCurrency(monthlyPremium)}</Text>
            {Boolean(monthlyFees) && (
              <Text style={styles.feeText}>{`plus ${formatCurrency(monthlyFees)}/mo in fees`}</Text>
            )}
          </View>
        </View>
      </View>

      <View style={[styles.totalsContainer, styles.row, styles.lastRow]}>
        <View style={[styles.cell, styles.leftCell]}>
          <Text
            style={[
              styles.rightText,
              styles.textPrimary,
              styles.textMedium,
              styles.textLetterSpacingWide,
              styles.textNavy,
            ]}
          >
            What you'll pay over the year:
          </Text>
        </View>
        <View style={styles.cell}>
          <Text
            style={[
              styles.textPrimary,
              styles.textPadding,
              styles.textNavy,
              styles.textLetterSpacingWider,
              styles.textLeft,
            ]}
          >
            {formatCurrency(totalYearlyOwed)}
          </Text>
        </View>
        <View style={styles.cell}>
          <Text
            style={[
              styles.textPrimary,
              styles.textPadding,
              styles.textNavy,
              styles.textLetterSpacingWider,
              styles.textLeft,
            ]}
          >
            {formatCurrency(totalMonthlyPayments)}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: "12px 24px 16px 0",
  },
  policyTypesTitle: {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "12px",
    fontFamily: theme.font.typeface.primary,
    color: theme.blue,
  },
  valueBox: {
    height: "16px",
    width: "70px",
    backgroundColor: theme.green10,
    border: `1px solid ${theme.colors.secondary.dark}`,
    borderRadius: "4px",
    position: "absolute",
    left: "204px",
    top: "-11px",
  },
  valueText: {
    textAlign: "center",
    color: theme.colors.secondary.dark,
    fontFamily: theme.font.typeface.primary,
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: "8px",
    letterSpacing: "0.73px",
    textTransform: "uppercase",
    paddingTop: "2px",
  },
  tableContainer: {
    width: "480px",
    borderColor: "black",
    borderWidth: 1,
    borderRadius: "10px",
    marginRight: "auto",
    marginLeft: "auto",
  },
  totalsContainer: {
    width: "480px",
    marginRight: "auto",
    marginLeft: "auto",
  },
  borderTopRight: {
    borderTopRightRadius: "8px",
  },
  borderBottomRight: {
    borderBottomRightRadius: "8px",
  },
  headerContainer: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
  },
  headerRowCell: {
    paddingTop: "11px",
  },
  row: {
    flexDirection: "row",
    fontFamily: theme.font.typeface.secondary,
    display: "flex",
    alignItems: "stretch",
    alignContent: "stretch",
    flexGrow: 1,
  },
  cell: {
    width: "122px",
    display: "flex",
    alignSelf: "stretch",
    fontSize: "11px",
    paddingTop: "4px",
    paddingBottom: "6px",
    color: theme.textNavyBlue,
    flexGrow: 1,
  },
  cellBlueBackground: {
    backgroundColor: "rgba(66,36,255,0.1)",
  },
  borderRight: {
    borderRightColor: "black",
    borderRightWidth: 1,
  },
  borderLeft: {
    borderLeftColor: "black",
    borderLeftWidth: 1,
  },
  centerText: {
    textAlign: "center",
  },
  rightText: {
    textAlign: "right",
    paddingRight: "8px",
  },
  textPrimary: {
    fontFamily: theme.font.typeface.primary,
  },
  textSmall: {
    fontSize: "9px",
  },
  textMedium: {
    fontSize: "10px",
  },
  textLarge: {
    fontSize: "12px",
  },
  textGray: {
    color: theme.gray,
  },
  textNavy: {
    color: theme.colors.tertiary.zero,
  },
  textLetterSpacing: {
    letterSpacing: "0.5px",
  },
  textLetterSpacingWide: {
    letterSpacing: "0.91px",
  },
  textLetterSpacingWider: {
    letterSpacing: "1px",
  },
  cellPaddingTop: {
    paddingTop: "10px",
  },
  cellPaddingBottom: {
    paddingBottom: "10px",
  },
  cellPaddingLeft: {
    paddingLeft: "16px",
  },
  textPadding: {
    padding: "2px 0 2px 0",
  },
  textLargePadding: {
    paddingTop: "1px",
    paddingBottom: "1px",
  },
  leftCell: {
    width: "176px",
  },
  textLeft: {
    textAlign: "left",
    paddingLeft: "14px",
  },
  textNa: {
    color: theme.gray,
    fontSize: "10px",
  },
  hr: {
    width: "90%",
    backgroundColor: theme.colors.tertiary.eighty,
    marginRight: "auto",
    marginLeft: "auto",
    height: "1px",
  },
  lastRow: {
    paddingBottom: "6px",
    alignItems: "center",
  },
  topPadding: {
    paddingTop: "10px",
  },
  rightPadding: {
    paddingRight: "8px",
  },
  width50: {
    width: "50%",
  },
  feeText: {
    fontSize: "8px",
    color: theme.gray,
    letterSpacing: "0.2px",
  },
  monthlyPayment: {
    fontWeight: 600,
    fontFamily: theme.font.typeface.primary,
  },
  strikethrough: {
    textDecoration: "line-through",
    color: theme.gray,
  },
  bottomRight: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  discountText: {
    display: "flex",
    paddingTop: "4px",
    fontSize: "7px",
    fontWeight: 600,
    letterSpacing: "1px",
    textTransform: "uppercase",
    color: "#3391CC",
  },
  checkmark: {
    height: "8px",
    width: "8px",
  },
});

export default PremiumOptions;
