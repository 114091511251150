import React, { useState, useRef } from "react";
import { Dictionary } from "lodash";
import { HexColorPicker } from "react-colorful";
import styled from "styled-components";
import { theme, Input } from "styles";
import useOnClickOutside from "utils/useOnOutsideClick";
import { namedColorsToHex } from "utils/constants";

export const colorInputErrorMsg = "Must be a valid CSS value";

const isValidCssColorValue = (value: string) => {
  const style = new Option().style;
  // If the value is not a valid css color value, it will not update this property
  style.color = value;

  return style.color !== "";
};

export const getPrimaryOne = (input: string) => {
  if (!isValidCssColorValue(input)) return "";
  const color = namedColorsToHex[input.toLowerCase()] || input;

  const alpha = 0.25; // this is the "opacity" value we'll be applying
  const whiteBackgroundRGB = {
    r: 255,
    g: 255,
    b: 255,
  };

  const rgbColor = new Option().style;
  rgbColor.color = color;
  const rgbColorValues = rgbColor.color.replace(/[^\d,]/g, "").split(",");
  const inputRGB = {
    r: Number(rgbColorValues[0]),
    g: Number(rgbColorValues[1]),
    b: Number(rgbColorValues[2]),
  };

  const newColorValues = {
    r: Math.floor((1 - alpha) * whiteBackgroundRGB.r + alpha * inputRGB.r),
    g: Math.floor((1 - alpha) * whiteBackgroundRGB.g + alpha * inputRGB.g),
    b: Math.floor((1 - alpha) * whiteBackgroundRGB.b + alpha * inputRGB.b),
  };

  return `rgb(${newColorValues.r},${newColorValues.g},${newColorValues.b})`;
};

interface ColorSelectProps {
  color: ColorAttrTypes;
  setColor: React.Dispatch<React.SetStateAction<ColorAttrTypes>>;
}

const ColorSelect = ({ color, setColor }: ColorSelectProps) => {
  const [isPrimaryColorOpen, setIsPrimaryColorOpen] = useState(false);
  const [isSecondaryColorOpen, setIsSecondaryColorOpen] = useState(false);
  const [hasError, setHasError] = useState<Dictionary<boolean>>({ primary: false, secondary: false });
  const primaryColorPickerRef = useRef<HTMLDivElement>(null);
  const secondaryColorPickerRef = useRef<HTMLDivElement>(null);

  const onColorChange = (colorType: string) => (input: React.ChangeEvent<HTMLInputElement> | string) => {
    const stringValue = typeof input === "string" ? input : input.target.value;
    // If a user changes the bottom slider on the color picker first, the color picker returns a bad value of #NaNNaNNaN
    const value = stringValue === "#NaNNaNNaN" ? "#ffffff" : stringValue;
    if (colorType === "primary") {
      setColor({ ...color, primary: value, primaryOne: getPrimaryOne(value) });
      setHasError({ ...hasError, primary: false });
    } else {
      setColor({ ...color, secondary: value });
      setHasError({ ...hasError, secondary: false });
    }
  };

  const onBlurChange = (colorType: string) => (input: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = input.target;
    if (value === "" || isValidCssColorValue(value)) {
      setHasError({ ...hasError, [colorType]: false });
    } else {
      setHasError({ ...hasError, [colorType]: true });
    }
  };

  const onPrimaryColorChange = onColorChange("primary");
  const onSecondaryColorChange = onColorChange("secondary");
  const onPrimaryBlurChange = onBlurChange("primary");
  const onSecondaryBlurChange = onBlurChange("secondary");

  useOnClickOutside(primaryColorPickerRef, () => setIsPrimaryColorOpen(false));
  useOnClickOutside(secondaryColorPickerRef, () => setIsSecondaryColorOpen(false));

  return (
    <ColorSelectCont>
      <InputWithColorPrev hasError={hasError.primary}>
        <Input
          label="Primary Color"
          type="text"
          name="primaryColor"
          placeholder="Enter color"
          handleChange={onPrimaryColorChange}
          handleBlur={onPrimaryBlurChange}
          value={color.primary}
          error={hasError.primary}
          errorText={colorInputErrorMsg}
        />
        <ColorPreviewCont>
          <ColorPreview
            data-testid="primary_color_prev"
            style={{ backgroundColor: color.primary || theme.blue }}
            onClick={() => setIsPrimaryColorOpen(!isPrimaryColorOpen)}
            hasError={hasError.primary}
          />
          {isPrimaryColorOpen && (
            <ColorPickerCont data-testid="primary_color_picker" ref={primaryColorPickerRef}>
              <HexColorPicker color={color.primary} onChange={onPrimaryColorChange} />
            </ColorPickerCont>
          )}
        </ColorPreviewCont>
      </InputWithColorPrev>
      {!hasError.primary && <HelperLabel id="helper-label">Valid css units are (string, hex, rgb, hsl)</HelperLabel>}
      <InputWithColorPrev hasError={hasError.secondary}>
        <Input
          label="Secondary Color"
          type="text"
          name="secondaryColor"
          placeholder="Enter color"
          handleChange={onSecondaryColorChange}
          handleBlur={onSecondaryBlurChange}
          value={color.secondary}
          error={hasError.secondary}
          errorText={colorInputErrorMsg}
        />
        <ColorPreviewCont>
          <ColorPreview
            data-testid="secondary_color_prev"
            style={{ backgroundColor: color.secondary || theme.green }}
            onClick={() => setIsSecondaryColorOpen(!isSecondaryColorOpen)}
            hasError={hasError.secondary}
          />
          {isSecondaryColorOpen && (
            <ColorPickerCont data-testid="secondary_color_picker" ref={secondaryColorPickerRef}>
              <HexColorPicker color={color.secondary} onChange={onSecondaryColorChange} />
            </ColorPickerCont>
          )}
        </ColorPreviewCont>
      </InputWithColorPrev>
      {!hasError.secondary && <HelperLabel id="helper-label">Valid css units are (string, hex, rgb, hsl)</HelperLabel>}
    </ColorSelectCont>
  );
};

export default ColorSelect;

const HelperLabel = styled.label`
  color: ${({ theme }) => theme.gray};
  font-family: Questrial;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 20px;
  width: 170px;
`;

const ColorSelectCont = styled.div`
  margin: 19px 0 0;
`;

const ColorPreviewCont = styled.div`
  position: relative;
  cursor: pointer;
`;

const ColorPreview = styled.div<{ hasError: boolean }>`
  height: 24px;
  width: 24px;
  border-radius: 4px;
  position: absolute;
  top: ${({ hasError }) => (hasError ? "-12px" : "4px")};
  right: 10px;
`;

const ColorPickerCont = styled.div`
  background-color: ${({ theme }) => theme.white};
  padding: 16px;
  position: absolute;
  top: 32px;
  left: -34px;
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);

  button {
    padding: 6px;
    margin-bottom: 6px;
  }
`;

const InputWithColorPrev = styled.div<{ hasError: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ hasError }) => (hasError ? "24px" : "0")};
  width: 170px;
  input {
    margin-bottom: 0;
    padding-right: 45px;
  }
`;
