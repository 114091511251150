import { useQuery } from "@tanstack/react-query";
import Api from "utils/api";
import { reportToSentry } from "utils/sentry";
import useAuth from "./useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

type Options = {
  enabled?: boolean;
  retry?: boolean;
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
};

const useGetPublicAgencyLogo = (applicationId: string, options?: Options) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const getAgencyLogo = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api.agencyLogo.publicGet(applicationId).catch((err) => {
      if (err.response.status === 404) {
        return Promise.resolve("no logo found");
      } else {
        reportToSentry(err);
        return Promise.reject(err);
      }
    });
  };

  return useQuery({
    queryKey: ["agency_logo", applicationId],
    queryFn: getAgencyLogo,
    select: (data) => {
      return data !== "no logo found";
    },
    ...options,
  });
};

export default useGetPublicAgencyLogo;
