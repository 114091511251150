import { useMutation } from "@tanstack/react-query";
import Api from "utils/api";
import useAuth from "./useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import { CancelRequest } from "ia/Policy/Cancel/types";

export const usePostCreateCancelRequest = (policyNumber: string) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const cancelRequest = async (request: CancelRequest) => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api.cancel.createCancelRequest(policyNumber, request);
  };

  return useMutation({
    mutationFn: (request: CancelRequest) => cancelRequest(request),
    mutationKey: ["createCancelRequest", policyNumber],
  });
};
