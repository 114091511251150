import React, { useState, useEffect } from "react";
import { Checkbox, TextLink } from "styles";
import { InputCheckbox } from "styles/Checkbox";
import styled, { createGlobalStyle } from "styled-components";
import useGetAcknowledgements from "hooks/useGetAcknowledgements";
import {
  accurateInfoLabelText,
  bindAsAgreementText,
  nluAcknowledgementText,
  stateRequirementsTextSimplyBind,
} from "../constants";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PaymentConfirmationInputProps {
  setDocs: (sendDocs: boolean) => void;
  sendDocs: boolean;
  PLConsentShouldShow: boolean;
  setAllTermsChecked: (isChecked: boolean) => void;
  industryId: number;
}

const PaymentConfirmationInputs = ({
  setDocs,
  sendDocs,
  PLConsentShouldShow,
  setAllTermsChecked,
  industryId,
}: PaymentConfirmationInputProps) => {
  const [nluAcknowledgementIsChecked, setNluAcknowledgementIsChecked] = useState(false);
  const [accuracyIsChecked, setAccuracyIsChecked] = useState(false);
  const [PLTermsIsChecked, setPLTermsIsChecked] = useState(false);
  const [termsIsChecked, setTermsIsChecked] = useState(false);
  const [stateRequirementsIsChecked, setStateRequirementsIsChecked] = useState(false);

  const { isNonlicensedUser } = useAuth();
  const { showNonLicensedUserLink } = useFeatureFlags();
  const { data: resultsAcknowledgements } = useGetAcknowledgements(industryId, {
    enabled: PLConsentShouldShow,
  });

  const accurateInfoWithBindAsText = `${accurateInfoLabelText} ${bindAsAgreementText}`;
  const defaultAccurateInfoText = showNonLicensedUserLink ? accurateInfoWithBindAsText : accurateInfoLabelText;
  const accurateInfoLabel = isNonlicensedUser ? accurateInfoWithBindAsText : defaultAccurateInfoText;

  const confirmationsChecksAreInvalid =
    !accuracyIsChecked ||
    !termsIsChecked ||
    !stateRequirementsIsChecked ||
    Boolean(PLConsentShouldShow && !PLTermsIsChecked) ||
    Boolean(isNonlicensedUser && !nluAcknowledgementIsChecked);

  useEffect(() => {
    setAllTermsChecked(!confirmationsChecksAreInvalid);
  }, [accuracyIsChecked, termsIsChecked, PLTermsIsChecked, nluAcknowledgementIsChecked, stateRequirementsIsChecked]);

  return (
    <div>
      <OverrideStyle />
      <div>
        <CheckboxContainer>
          <Checkbox
            label={accurateInfoLabel}
            name="accurate-info"
            id="confirm-accurate-info-checkbox"
            handleClick={() => {
              setAccuracyIsChecked(!accuracyIsChecked);
            }}
            checked={accuracyIsChecked}
          />
        </CheckboxContainer>
        {isNonlicensedUser && (
          <CheckboxContainer>
            <Checkbox
              label={nluAcknowledgementText}
              name="nlu-acknowledgement"
              id="confirm-nlu-acknowledgement"
              handleClick={() => {
                setNluAcknowledgementIsChecked(!nluAcknowledgementIsChecked);
              }}
              checked={nluAcknowledgementIsChecked}
            />
          </CheckboxContainer>
        )}
        {PLConsentShouldShow && (
          <CheckboxContainer>
            <Checkbox
              label={
                <LabelText>
                  Yes, I have reviewed and understand the{" "}
                  <StyledTextLink
                    as="a"
                    href={resultsAcknowledgements?.pl_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Professional Liability Terms & Exclusions.
                  </StyledTextLink>
                </LabelText>
              }
              name="reviewPlTerms"
              handleClick={() => {
                setPLTermsIsChecked(!PLTermsIsChecked);
              }}
              checked={PLTermsIsChecked}
            />
          </CheckboxContainer>
        )}
        <CheckboxContainer>
          <Checkbox
            label={stateRequirementsTextSimplyBind}
            name="stateRequirements"
            handleClick={() => {
              setStateRequirementsIsChecked(!stateRequirementsIsChecked);
            }}
            checked={stateRequirementsIsChecked}
          />
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox
            label={
              <LabelText>
                Yes, I have reviewed Coterie’s{" "}
                <StyledTextLink
                  as="a"
                  href={`https://${process.env.REACT_APP_DOMAIN}/terms-conditions/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </StyledTextLink>{" "}
                and{" "}
                <StyledTextLink
                  as="a"
                  href={`https://${process.env.REACT_APP_DOMAIN}/privacy-policy/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </StyledTextLink>
                .
              </LabelText>
            }
            name="review-terms"
            id="confirm-review-terms"
            handleClick={() => {
              setTermsIsChecked(!termsIsChecked);
            }}
            checked={termsIsChecked}
          />
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox
            label="Send policy docs directly to policy holder"
            name="sendDocs"
            id="confirm-send-docs"
            handleClick={() => {
              setDocs(!sendDocs);
            }}
            checked={sendDocs}
          />
        </CheckboxContainer>
      </div>
    </div>
  );
};

const LabelText = styled.span`
  max-width: 80%;
  display: block;
`;

const CheckboxContainer = styled.div`
  margin: 25px 0;
`;

const StyledTextLink = styled(TextLink)`
  font-size: 15px;
`;

const OverrideStyle = createGlobalStyle`
  ${InputCheckbox} {
    margin-top: 0;
    margin-bottom: 0;
    align-self: flex-start;
    min-width: 24px;
  }
`;

export default PaymentConfirmationInputs;
