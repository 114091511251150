import React from "react";
import { Card, CardBody } from "reactstrap";
import AuditTrailTable from "kit/AuditTrailTable";

interface PropTypes {
  auditTrail: PolicyAuditItem[];
  isLoading: boolean;
}

const ChangeLog: React.FC<PropTypes> = (props: PropTypes) => {
  const auditTrail = props.auditTrail || [];

  return (
    <Card>
      <CardBody>
        <div data-cy={"audit-trail"} className={"audit-trail-body"}>
          {props.isLoading ? <div>Loading...</div> : <AuditTrailTable auditTrail={auditTrail} />}
        </div>
      </CardBody>
    </Card>
  );
};

export default ChangeLog;
