import React from "react";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import useFees from "hooks/useFees";
import { formatCurrency } from "utils/formatNumber";
import CloseIcon from "images/close-gray.svg";
import CheckmarkIcon from "images/checkmark-light-blue.svg";
import WarningIcon from "images/warning-orange.svg";
import ToolTipIcon from "shared/ToolTipIcon";
import { Text } from "utils/toolTipContent";
import {
  selectIsOptInLowerCost,
  selectLossControlOptOutPremium,
  selectLossControlOptOutMonthlyPremium,
  selectOptInCostDifferenceMonthly,
  selectOptInCostDifferenceYearly,
} from "redux/selectors/global";
import { selectApplicationFormApplicationTypes } from "redux/selectors/application";
import { PolicyType } from "types/enums";

interface PropTypes {
  onClose: () => void;
  isMonthly: boolean;
  monthlyPremium: number;
  premium: number;
  showPriceDrawer: boolean;
}

const PriceDrawer = ({ onClose, isMonthly, monthlyPremium, premium, showPriceDrawer }: PropTypes) => {
  const {
    month1Owed,
    totalYearlyOwed,
    monthlyFees,
    monthlyOwed,
    policyFees,
    installmentFees,
    lossControlFees,
    hasPolicyFee,
    feesSavings,
    hasFirstMonthWaived,
  } = useFees(true);

  const applicationTypes = useSelector(selectApplicationFormApplicationTypes);
  const renderLossControl = applicationTypes.includes(PolicyType.BOP) || applicationTypes.includes(PolicyType.GL);

  const lossControlIsOptIn = Boolean(lossControlFees);
  const isOptInLowerCost = useSelector(selectIsOptInLowerCost);
  const lossControlOptOutPremium = useSelector(selectLossControlOptOutPremium);
  const lossControlOptOutMonthlyPremium = useSelector(selectLossControlOptOutMonthlyPremium);
  const optInCostDifferenceMonthly = useSelector(selectOptInCostDifferenceMonthly);
  const optInCostDifferenceYearly = useSelector(selectOptInCostDifferenceYearly);

  const alternatePremium = isMonthly ? lossControlOptOutMonthlyPremium : lossControlOptOutPremium;

  const showPremiumStrikethrough = (label: string) => {
    return label === "Premium" && lossControlIsOptIn && isOptInLowerCost;
  };

  const getLossControlSavingsMessage = () => {
    if (isOptInLowerCost) {
      return `${lossControlIsOptIn ? "Saving" : "Save"} ${
        isMonthly ? formatCurrency(optInCostDifferenceMonthly) : formatCurrency(optInCostDifferenceYearly)
      }${isMonthly ? "/mo" : "/year"} with Manage My Risk discount`;
    } else if (lossControlIsOptIn) {
      return "Maximizing business protection with Manage My Risk";
    } else {
      return "Increase protection with Manage My Risk";
    }
  };

  const PriceDetails = [
    { label: "Premium", amount: isMonthly ? monthlyPremium : premium, isVisible: true },
    { label: "Annual Policy Fee", amount: policyFees, isVisible: hasPolicyFee },
    { label: "Installment Fee", amount: installmentFees, isVisible: !hasFirstMonthWaived && Boolean(installmentFees) },
    {
      label: "Manage My Risk Program",
      amount: isMonthly ? lossControlFees : lossControlFees * 12,
      isVisible: renderLossControl && lossControlIsOptIn,
    },
  ];

  const showRecommendedLabel = () => {
    if (renderLossControl) {
      return lossControlIsOptIn && !isMonthly;
    } else {
      return !isMonthly;
    }
  };

  return (
    <>
      <Overlay showPriceDrawer={showPriceDrawer} onClick={onClose} />
      <Drawer showPriceDrawer={showPriceDrawer}>
        <Close onClick={onClose}>
          <img src={CloseIcon} alt="Close Cost Breakdown" />
        </Close>
        <DrawerContent>
          <TotalContainer>
            <Header>Total Breakdown</Header>
            <TotalDue>
              <div>Total Due Today:</div>
              <div>{isMonthly ? formatCurrency(month1Owed) : formatCurrency(totalYearlyOwed)}</div>
            </TotalDue>
            {showRecommendedLabel() && (
              <FlexStartDiv>
                <StyledRecommendedLabel>Recommended</StyledRecommendedLabel>
              </FlexStartDiv>
            )}
          </TotalContainer>
          <PriceDetailsContainer>
            {PriceDetails.filter((e) => e.isVisible).map((detail) => (
              <SpaceBetweenDiv>
                <PriceLabel>{`${detail.label}:`}</PriceLabel>
                {showPremiumStrikethrough(detail.label) ? (
                  <PremiumAmountSpan>
                    <PremiumLabel>{formatCurrency(detail.amount)}</PremiumLabel>
                    <StrikethroughPremiumLabel>{formatCurrency(alternatePremium)}</StrikethroughPremiumLabel>
                  </PremiumAmountSpan>
                ) : (
                  <PriceLabel>{formatCurrency(detail.amount)}</PriceLabel>
                )}
              </SpaceBetweenDiv>
            ))}
            {isMonthly && (
              <FuturePaymentContainer>
                <FuturePaymentLabel>
                  Future Monthly Payments:
                  {Boolean(monthlyFees) && (
                    <ToolTipIcon
                      content={
                        <Text>
                          Future monthly payments include the monthly premium and the following fee
                          {lossControlIsOptIn && "s"}:
                          <ul>
                            <li>{formatCurrency(installmentFees as number)}/mo installment fee</li>
                            {lossControlIsOptIn && (
                              <li>{formatCurrency(lossControlFees as number)}/mo Manage My Risk</li>
                            )}
                          </ul>
                        </Text>
                      }
                    />
                  )}
                </FuturePaymentLabel>
                <PriceLabel>{formatCurrency(monthlyOwed)}</PriceLabel>
              </FuturePaymentContainer>
            )}
          </PriceDetailsContainer>
          {(Boolean(feesSavings) || renderLossControl) && (
            <SavingsContainer>
              {Boolean(feesSavings) && (
                <SavingsText isWarningStyle={isMonthly}>
                  <Icon>
                    <img src={isMonthly ? WarningIcon : CheckmarkIcon} alt="Icon" />
                  </Icon>
                  {isMonthly ? "Save" : "Saving"} {formatCurrency(feesSavings)} by selecting yearly plan
                </SavingsText>
              )}
              {renderLossControl && (
                <SavingsText isWarningStyle={!lossControlIsOptIn}>
                  <Icon>
                    <img src={lossControlIsOptIn ? CheckmarkIcon : WarningIcon} alt="Icon" />
                  </Icon>
                  {getLossControlSavingsMessage()}
                </SavingsText>
              )}
            </SavingsContainer>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};

const slideIn = keyframes`
from {
  transform: translateX(100%);
}
to {
  transform: translateX(0);
}
`;

const slideOut = keyframes`
from {
  transform: translateX(0);
}
to {
  transform: translateX(100%);
}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Overlay = styled.div<{ showPriceDrawer: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  animation: ${({ showPriceDrawer }) => (showPriceDrawer ? fadeIn : fadeOut)} 0.4s forwards;
`;

const Drawer = styled.div<{ showPriceDrawer: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background: white;
  padding-left: 32px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 24px;
  z-index: 100;
  animation: ${({ showPriceDrawer }) => (showPriceDrawer ? slideIn : slideOut)} 0.4s forwards;
`;

const DrawerContent = styled.div`
  padding-right: 16px;
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexStartDiv = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const PriceLabel = styled.span`
  color: ${(props) => props.theme.mediumGray};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const FuturePaymentContainer = styled.span`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 4px;
`;

const FuturePaymentLabel = styled.span`
  color: ${(props) => props.theme.textNavyBlue};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
`;

const StrikethroughPremiumLabel = styled.span`
  color: ${(props) => props.theme.blueStrikethroughColor};
  text-align: right;
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: line-through;
`;

const PremiumLabel = styled.span`
  color: ${(props) => props.theme.blue};
  text-align: right;
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const PremiumAmountSpan = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TotalDue = styled(SpaceBetweenDiv)`
  color: ${(props) => props.theme.textNavyBlue};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

const StyledRecommendedLabel = styled.span`
  background-color: ${(props) => props.theme.colors.secondary.twentyFive};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.secondary.dark};
  color: ${(props) => props.theme.colors.secondary.dark};
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  padding: 4px 8px;
  width: fit-content;
  padding-left: 13.5px;
  padding-right: 13.5px;
`;

const PriceDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0px;
`;

const Close = styled.span`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

const Icon = styled.span`
  display: flex;
  justify-content: flex-start;
`;

const Header = styled.p`
  color: ${(props) => props.theme.mediumGray};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-top: 24px;
`;

const SavingsContainer = styled.div`
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid ${(props) => props.theme.borderGrayLight};
`;

const SavingsText = styled.div<{ isWarningStyle: boolean }>`
  display: flex;
  width: 100%;
  padding: 4px 8px;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  border-radius: 4px;
  background: ${(props) => (props.isWarningStyle ? props.theme.orange25 : "transparent")};
  color: ${(props) => (props.isWarningStyle ? props.theme.darkOrange : props.theme.blueStrikethroughColor)};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export default PriceDrawer;
