import { Alert, Icon } from "coterie-ui-library";
import useGetPolicyDocuments from "hooks/useGetPolicyDocuments";
import React from "react";
import AjaxLoader from "shared/AjaxLoader";
import styled from "styled-components";
import { PolicyType } from "types/enums";
import { PolicyTabContainer } from "../PolicyTabContainer";

type PolicyDocumentsProps = {
  policy: Policy;
};

export default function PolicyDocuments({ policy }: PolicyDocumentsProps) {
  const { data, isLoading, isSuccess, isFetching, isError, refetch } = useGetPolicyDocuments(policy.policyNumber);

  return (
    <PolicyTabContainer heading="Download your Policy Documents" data-testid="content-policy-documents">
      {isSuccess && data && !isFetching && (
        <PolicyDocumentsTable>
          <PolicyDocumentsCard>
            <PolicyDocumentsHeader policyType={policy.policyType.toLowerCase()}>
              <span>Policy Documents</span>
              <ReloadDocumentsButton
                aria-label="Reload policy documents"
                data-testid="reload-documents"
                data-cy="reload-documents"
                onClick={() => refetch()}
              >
                <Icon name="refresh" label="reload policy documents" />
              </ReloadDocumentsButton>
            </PolicyDocumentsHeader>
            <PolicyDocumentsList>
              {!data.length ? (
                <PolicyDocumentListItem>No documents found.</PolicyDocumentListItem>
              ) : (
                <>
                  {data.map((document: PolicyDocumentLink) => {
                    return (
                      <PolicyDocumentListItem key={document.link}>
                        <a target="_blank" rel="noopener noreferrer" href={document.link}>
                          {document.name}
                        </a>
                      </PolicyDocumentListItem>
                    );
                  })}
                </>
              )}
            </PolicyDocumentsList>
          </PolicyDocumentsCard>
        </PolicyDocumentsTable>
      )}
      {(isLoading || isFetching) && (
        <StatusContainer data-testid="loading-docs" data-cy="loading-docs">
          <AjaxLoader />
        </StatusContainer>
      )}
      {isError && !isLoading && !isFetching && (
        <StatusContainer>
          <Alert color="red" title="Server Error" message="There was an error loading your policy documents." />
        </StatusContainer>
      )}
    </PolicyTabContainer>
  );
}

const PolicyDocumentsTable = styled.div`
  max-width: 640px;
`;

const PolicyDocumentsHeader = styled.header<{ policyType: PolicyType }>`
  background-color: ${(props) => props.theme.pageComponents.ia.policyDocuments.productType[props.policyType]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  min-height: 37px;
  padding: 12px 20px;
  color: ${(props) => props.theme.pageComponents.ia.policyDocuments.header.color};
  font-family: ${(props) => props.theme.pageComponents.ia.policyDocuments.header.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const PolicyDocumentsCard = styled.div`
  width: 100%;
`;

const PolicyDocumentsList = styled.ul`
  padding: 0;
  margin: 0;
  li:nth-child(odd) {
    background-color: ${(props) => props.theme.pageComponents.ia.policyDocuments.list.odd};
  }
  li:nth-child(even) {
    background-color: ${(props) => props.theme.pageComponents.ia.policyDocuments.list.even};
  }
`;

const PolicyDocumentListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  min-height: 37px;
  padding: 12px 20px;
  color: ${(props) => props.theme.pageComponents.ia.policyDocuments.listItem.color};
  font-family: ${(props) => props.theme.pageComponents.ia.policyDocuments.listItem.fontFamily};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  a {
    color: ${(props) => props.theme.pageComponents.ia.policyDocuments.listItem.color};
  }
`;

const ReloadDocumentsButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
`;

const StatusContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;
