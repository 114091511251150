import { useState, useCallback, useEffect, useMemo } from "react";
import QuestionsCreator from "shared/QuestionsCreator";
import styled from "styled-components";
import { GreenButton, Label, theme } from "styles";
import { scrollToById } from "utils/scroll";
import { Icon } from "coterie-ui-library";
import { useHistory, useRouteMatch } from "react-router-dom";
import useGetFormQuestions from "hooks/useGetFormQuestions";
import AjaxLoader from "shared/AjaxLoader";
import usePostFormQuestions from "hooks/usePostFormQuestions";
import { useSelector } from "react-redux";
import { selectProducerExternalId } from "redux/selectors/user";
import { toast } from "react-toastify";
import { FormCategory } from "types/enums";

interface ParamsType {
  policyIds: string;
  questionsType: keyof typeof FormCategory;
}

const headerTexts = {
  [FormCategory.Endorsement]: "Add Endorsements",
  [FormCategory.AdditionalInsured]: "Additional Insured",
  [FormCategory.PolicyInformation]: "Update Policy Information",
};

const UpdatePolicyForm = () => {
  const history = useMemo(useHistory, []);

  const match = useRouteMatch<ParamsType>();
  const params = match.params; // Access params
  const policyId = params.policyIds.split(",")[0];
  const formCategory = FormCategory[params.questionsType as keyof typeof FormCategory];

  const [questionsToSubmit, setQuestionsToSubmit] = useState<valueQuestionsType[]>([]);

  const [gobackHover, setGobackHover] = useState<boolean>(false);

  const [completed, setCompleted] = useState<boolean>(false);
  const [oneQuestionCompleted, setOneQuestionCompleted] = useState<boolean>(false);
  const [incompleteId, setIncompleteId] = useState<string>("");
  const [showQuestionErrors, setShowQuestionErrors] = useState<boolean>(false);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState<boolean>(false);
  const [changeRequestId, setChangeRequestId] = useState<string>();
  const [validationErrors, setValidationErrors] = useState<string[]>();
  

  const {
    data: loadedFormQuestions,
    isLoading: isLoadingLegacyApplication,
    isSuccess: isSuccessLegacyApplication,
    error: errorLegacyApplication,
    isError: isErrorLegacyApplication,
  } = useGetFormQuestions(formCategory, {
    enabled: Boolean(formCategory),
  });

  const goBack = useCallback( () => {
    history.push(`/policy?policyIds=${params.policyIds}`)
  }, [history , params.policyIds]);

  const toastError = useCallback((formCategory: FormCategory, isSuccess: boolean) => {
    if (isSuccess) {
      toast.error(`Something Went Wrong Loading ${formCategory} Form Questions - No Questions Were Found`);
    } else {
      toast.error(
        (errorLegacyApplication as any)?.response?.data?.errors[0]?.message ??
          `Error: Something Went Wrong Loading ${formCategory} Form Questions`
      );
    }
    history.push(`/policy?policyIds=${params.policyIds}`);
  }, [history, params.policyIds, errorLegacyApplication]);
  
  useEffect(() => {
    if (isSuccessLegacyApplication && loadedFormQuestions?.length === 0) {
      toastError(formCategory, true);
    }
  }, [isSuccessLegacyApplication, loadedFormQuestions?.length, formCategory, toastError]);

  useEffect(() => {
    if (isErrorLegacyApplication) {
      toastError(formCategory, false);
    }
  }, [isErrorLegacyApplication, formCategory, toastError]);

  const submittedQuestionsFormat = (questions: valueQuestionsType[]) => {
    const questionsToSubmit = questions.map((question) => {
      const { questionValue, id } = question;
      return {
        questionValue,
        id,
      };
    });
    return questionsToSubmit;
  };

  const { mutate: postFormQuestions, isPending: isSubmittingQuestions } = usePostFormQuestions();

  const producerGuid = useSelector((state: any) => selectProducerExternalId(state));

  const submitQuestions = () => {
    if (completed) {
      const payload = {
        questions: submittedQuestionsFormat(questionsToSubmit),
        producerGuid,
        formCategory,
      };
      postFormQuestions(
        { policyNumber: policyId, payload },
        {
          onSuccess: (data) => {
            if (data.validationErrors) {
              const mappedData = data.validationErrors.map((error: any) => error.message);
              setValidationErrors(mappedData);
              return;
            } else {
              setSubmittedSuccessfully(true);
              setChangeRequestId(data.changeRequestId);
            }
          },
          onError: (error: any) => {
            toast.error(error?.response?.data?.errors[0]?.message ?? "Something went wrong");
          },
        }
      );
    } else {
      setShowQuestionErrors(true);
      scrollToById(incompleteId);
    }
  };

  const questionsCompleteChecker = (allCompleted: boolean, oneCompleted: boolean, incompleteId: string) => {
    setCompleted(allCompleted);
    setOneQuestionCompleted(oneCompleted);
    setIncompleteId(incompleteId);
  };

  const questionsAnswered = (questionsReadyToSubmit: valueQuestionsType[]) => {
    setQuestionsToSubmit(questionsReadyToSubmit);
  };

  return (
    <div>
      <PageWrapper>
        <GoBackContainer
          onClick={goBack}
          onMouseOver={() => setGobackHover(true)}
          onMouseOut={() => setGobackHover(false)}
          data-testId="go-back-button"
        >
          <IconContainer>
            <Icon
              name="arrow-down"
              colorOverride={
                gobackHover
                  ? theme.pageComponents.ia.policyEdits.goBackTextHover
                  : theme.pageComponents.ia.policyEdits.goBackText
              }
            />
          </IconContainer>
          <span>Return to Policy Information</span>
        </GoBackContainer>

        {validationErrors && (
          <>
            <HeaderErrorText>Sorry, we are unable to process your request.</HeaderErrorText>
            {validationErrors.map((error) => (
              <IconTextContainer>
                <ErrorIconContainer>
                  <Icon name="close" colorOverride={theme.pageComponents.ia.policyEdits.errorIcon} />
                </ErrorIconContainer>
                <ErrorText>{error}</ErrorText>
              </IconTextContainer>
            ))}
          </>
        )}

        {!validationErrors && (
          <>
            <HeaderText>{!submittedSuccessfully ? `${headerTexts[formCategory]}` : "Form Submitted"}</HeaderText>
            <SubHeaderText>
              {!submittedSuccessfully
                ? "Complete and submit the form"
                : "Thank you for reaching out to Coterie Insurance. We have received your request and are working to resolve your case. You should hear back within 3-5 business days."}
            </SubHeaderText>
            <PolicyNumberContainer>
              <PolicyNumberText>{!changeRequestId ? "Policy Number: " : "Change RequestId: "}</PolicyNumberText>
              <PolicyNumberLabel>{!changeRequestId ? `#${policyId}` : `#${changeRequestId}`}</PolicyNumberLabel>
            </PolicyNumberContainer>
          </>
        )}

        {formCategory === FormCategory.Endorsement && !submittedSuccessfully && !validationErrors && (
          <HelperTextContainer>
            <Label>Resources:</Label>
            <ul>
              <li>
                <LinkTag
                  href="https://image.discover.coterieinsurance.com/lib/fe30117371640478701479/m/1/b2a4be3b-d515-40d2-b470-2b2b7451d6f0.pdf"
                  target="_blank"
                >
                  Business Personal Property Limit Coverage Info
                </LinkTag>
              </li>
            </ul>
          </HelperTextContainer>
        )}

        {loadedFormQuestions ? (
          !submittedSuccessfully &&
          !validationErrors && (
            <QuestionsCreator
              questionsCompleteChecker={questionsCompleteChecker}
              questionsObj={loadedFormQuestions}
              questionGroupId={params.questionsType}
              questionsAnswered={questionsAnswered}
              showQuestionErrors={showQuestionErrors}
            />
          )
        ) : (
          <AjaxLoaderContainer>
            <Label>Loading Questions...</Label>
            <AjaxLoader />
          </AjaxLoaderContainer>
        )}
      </PageWrapper>
      <SubmitButtonContainer>
        {!submittedSuccessfully && (
          <GreenButton
            dataCy="next-confirm-button"
            disabled={!oneQuestionCompleted || isSubmittingQuestions || isLoadingLegacyApplication}
            onClick={submitQuestions}
          >
            Submit
          </GreenButton>
        )}
      </SubmitButtonContainer>
    </div>
  );
};

const HelperTextContainer = styled.div`
  margin-bottom: 20px;
`;

const IconTextContainer = styled.div`
  display: flex;
  margin-bottom: 6px;
`;

const ErrorIconContainer = styled.div`
  padding: 2px 5px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.pageComponents.ia.policyEdits.errorIcon};
  width: 29px;
  height: 29px;
`;

const LinkTag = styled.a`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: 600;
  font-size: 15px;
  &:hover {
    text-decoration: underline;
  }
`;

const AjaxLoaderContainer = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubmitButtonContainer = styled.div`
  z-index: 12;
  height: 80px;
  bottom: 0;
  position: fixed;
  background: ${(props) => props.theme.offWhite};
  justify-content: center;
  align-items: center;
  display: flex;
  width: calc(100% - 317px);
  max-width: 1440px;
  column-gap: 32px;
  button {
    height: fit-content;
  }
`;

const PageWrapper = styled.div`
  padding: 26px;
`;

const GoBackContainer = styled.div`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  margin-bottom: 40px;
  width: fit-content;
  color: ${({ theme }) => theme.pageComponents.ia.policyEdits.goBackText};
  &:hover {
    color: ${({ theme }) => theme.pageComponents.ia.policyEdits.goBackTextHover};
    border-bottom: 1px solid ${({ theme }) => theme.pageComponents.ia.policyEdits.goBackTextHover};
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  font-size: 16px;
  font-weight: 600;
  transform: rotate(90deg);
  margin-right: 8px;
`;

const HeaderText = styled.div`
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.pageComponents.ia.policyEdits.headerText};
`;

const HeaderErrorText = styled.div`
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 18px;
  color: ${({ theme }) => theme.pageComponents.ia.policyEdits.headerText};
  font-size: 18px;
`;

const PolicyNumberContainer = styled.div`
  margin-bottom: 32px;
  font-size: 16px;
`;

const PolicyNumberLabel = styled.span`=
  font-family: ${({ theme }) => theme.secondaryFont};
  color: ${({ theme }) => theme.pageComponents.ia.policyEdits.subHeaderText};
  text-transform: uppercase;
`;

const PolicyNumberText = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.pageComponents.ia.policyEdits.headerText};
`;

const SubHeaderText = styled.div`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 6px;
  font-family: ${({ theme }) => theme.secondaryFont};
  color: ${({ theme }) => theme.pageComponents.ia.policyEdits.subHeaderText};
`;

const ErrorText = styled.div`
  font-size: 18px;
  line-height: 30px;
  margin-left: 10px;
  font-family: ${({ theme }) => theme.secondaryFont};
  color: ${({ theme }) => theme.pageComponents.ia.policyEdits.subHeaderText};
`;

export default UpdatePolicyForm;
