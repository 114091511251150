import { setUnderwritingModalIsOpen } from "redux/reducers/_global";
import isEqual from "lodash/isEqual";
import updateApplicationFromServer from "redux/actions/updateApplicationFromServer";
import { selectApplicationForm } from "redux/selectors/application";
import { selectIsIAUser, selectToken } from "redux/selectors/user";
import { selectUnderwritingProfile } from "redux/selectors/underwritingProfile";

const updateApplicationFromServerMiddleWare = (store: any) => (next: any) => (action: any) => {
  if (
    action.type &&
    (action.type.includes("ApplicationForm") ||
      action.type.includes("updateLiquorLiabilityCoverage") ||
      action.type.includes("updateRestaurantCoverage") ||
      action.type.includes("updateEquipmentBreakdownCoverage") ||
      action.type.includes("updateContractorsInstallationAndTools") ||
      action.type.includes("updateLimitDamagePremRented") ||
      action.type.includes("updateUnderwritingProfileDetailsData"))
  ) {
    if (!action.type.includes("updateApplicationFormContactDetails")) {
      store.dispatch(setUnderwritingModalIsOpen(true));
    }

    const old_application = selectApplicationForm(store.getState());
    const old_underwritingProfile = selectUnderwritingProfile(store.getState());

    const result = next(action);

    const new_application = selectApplicationForm(store.getState());
    const new_underwritingProfile = selectUnderwritingProfile(store.getState());

    const token = selectToken(store.getState());

    const shouldUpdateApplication =
      old_application.applicationId && new_application.applicationId && !isEqual(old_application, new_application);
    const shouldUpdateUnderwritingProfile =
      old_underwritingProfile.underwritingProfileId &&
      new_underwritingProfile.underwritingProfileId &&
      !isEqual(old_underwritingProfile, new_underwritingProfile);

    const isIAUser = selectIsIAUser(store.getState());

    const updateApplicationFromServerDeferred = () => {
      const underwritingProfile = selectUnderwritingProfile(store.getState());
      if (underwritingProfile.loadingUnderwritingProfile) {
        setTimeout(updateApplicationFromServerDeferred, 500);
      } else {
        store.dispatch(updateApplicationFromServer({ token, isIAUser, apiVersion: "v1" }));
      }
    };

    if (shouldUpdateApplication || shouldUpdateUnderwritingProfile) updateApplicationFromServerDeferred();

    return result;
  } else {
    return next(action);
  }
};

export default updateApplicationFromServerMiddleWare;
