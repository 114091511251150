import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { theme } from "styles";

type PropTypes = {
  title: string;
};

const AddOnsHeader = ({ title }: PropTypes) => (
  <View>
    <Text style={styles.pageHeaderTitle}>{title}</Text>
  </View>
);

const styles = StyleSheet.create({
  pageHeaderTitle: {
    fontSize: "20px",
    fontWeight: 600,
    color: theme.textNavyBlue,
    fontFamily: theme.font.typeface.primary,
  },
});

export default AddOnsHeader;
