import React, { useState } from "react";
import Modal from "shared/Modal";
import { ModalContentContainer } from "shared/ModalComponents";
import { Input } from "styles";

interface PropTypes {
  isLoading: boolean;
  closeModal: () => void;
  modalIsOpen: boolean;
  save: (name: string, email: string) => void;
  name: string;
  email: string;
}

const EditAgenciesModal = ({ isLoading, closeModal, modalIsOpen, save, name, email }: PropTypes) => {
  const [values, setValues] = useState({
    name: name,
    email: email,
  });

  const editAgency = () => {
    save(values.name, values.email);
  };

  const change = (event: React.FormEvent<HTMLInputElement>) => {
    let { name, value } = event.currentTarget;

    setValues((values) => ({ ...values, [name]: value }));
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      closeModal={closeModal}
      label="edit-agency"
      title="Edit Agency"
      onSubmit={editAgency}
      loading={isLoading}
    >
      <ModalContentContainer>
        <Input
          label="Agency Name"
          name="name"
          value={name}
          handleChange={change}
          aria-label="Agency Name"
          placeholder="Enter agency's name"
          required
        />
        <Input
          label="Email Address"
          name="email"
          value={email}
          handleChange={change}
          type="email"
          aria-label="Email Address"
          placeholder="Enter agency's email"
        />
      </ModalContentContainer>
    </Modal>
  );
};

export default EditAgenciesModal;
