import React from "react";
import { Card, CardSectionFullWidth, CardSectionTitle } from "./tabContentStyles";
import LossControl from "shared/application/edit/LossControl";
import ShieldLossControl from "images/shield_loss_control.svg";
import AjaxLoader from "shared/AjaxLoader";

interface PropTypes {
  application: Application;
  loadingQuote: boolean;
  lossControlIsOptIn: boolean;
  lossControlIsNotSet: boolean;
  postPublicGetQuote: (arg0: any) => void;
  token: string;
  producerExternalID: string;
  hideSwitchButton: boolean;
}

const Services = ({
  application,
  loadingQuote,
  lossControlIsOptIn,
  lossControlIsNotSet,
  postPublicGetQuote,
  token,
  producerExternalID,
  hideSwitchButton,
}: PropTypes) => {
  const handleUpdateLossControl = (lossControl: any) => {
    const applicationWithLossControl = { ...application, ...lossControl, producerExternalID: producerExternalID };
    postPublicGetQuote({ application: applicationWithLossControl, token });
  };

  return (
    <Card>
      <CardSectionFullWidth>
        <CardSectionTitle>
          <img src={ShieldLossControl} alt="" /> Manage My Risk Program
        </CardSectionTitle>
        {loadingQuote ? (
          <AjaxLoader />
        ) : (
          <LossControl
            lossControlIsOptIn={lossControlIsOptIn}
            lossControlIsNotSet={lossControlIsNotSet}
            updateLossControlSnapshot={handleUpdateLossControl}
            isSnapshot={true}
            hideSwitchButton={hideSwitchButton}
          />
        )}
      </CardSectionFullWidth>
    </Card>
  );
};

export default Services;
