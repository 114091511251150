import { Label, RadioGroup } from "coterie-ui-library";
import React from "react";
import DatePicker from "react-date-picker";
import styled from "styled-components";
import { ReactComponent as CalendarIcon } from "images/calendar.svg";
import { format, subDays, isBefore } from "date-fns";
import { useFormContext, useWatch } from "react-hook-form";
import { ReactComponent as LightBulbIcon } from "images/light_bulb.svg";
import { CancelError } from "./CancelStyles";
import { ReactComponent as AlertIcon } from "images/alert-red.svg";
import { CancellationForm } from "./types";

type CancellationDateProps = {
  policy?: Policy;
};

export default function CancellationDate({ policy }: CancellationDateProps) {
  const {
    setValue,
    formState: { errors },
  } = useFormContext<CancellationForm>();
  const { cancelDate, isNonRenew } = useWatch<CancellationForm>();

  const handleRenewalRadioChange = (value: CancellationForm["isNonRenew"]) => {
    const policyEndDate = new Date(policy?.endDate as string);

    if (value === "true") {
      setValue("cancelDate", policyEndDate, { shouldValidate: true });
    } else {
      setValue("cancelDate", null, { shouldValidate: true });
    }

    setValue("isNonRenew", value, { shouldValidate: true });
  };

  if (!policy) return null;
  return (
    <CancellationDateContainer>
      <div>
        <Label size="large" htmlFor="isNonRenew">
          When do you want the policy coverage to end?
        </Label>
        <RadioGroup
          name="isNonRenew"
          options={[
            {
              value: "true",
              label: `At Renewal on ${format(new Date(policy.endDate), "MM/dd/yyyy")}`,
            },
            {
              value: "false",
              label: "Earlier",
            },
          ]}
          handleChange={(value) => handleRenewalRadioChange(value)}
          selectedValue={isNonRenew}
          orientation="column"
        />
        {errors.isNonRenew && (
          <CancelError>
            <AlertIcon />
            {errors.isNonRenew.message}
          </CancelError>
        )}
        {isNonRenew === "false" && (
          <DatePickerContainer>
            <Label size="large">Requested Cancellation Date</Label>
            <p className="cancellation-date-lead">
              Cancellation requests that are more than 14 days before today's date require uploading additional
              documentation to support this request.
            </p>
            <StyledDatePicker
              name="cancelDate"
              value={cancelDate}
              onChange={(date: Date) => setValue("cancelDate", date, { shouldValidate: true })}
              maxDate={subDays(new Date(policy.endDate), 1)}
              minDate={new Date(policy.startDate)}
              clearIcon={null}
              calendarIcon={
                <CalendarIconWrapper>
                  <CalendarIcon aria-label="Calendar Icon" width={16} height={16} />
                </CalendarIconWrapper>
              }
              monthPlaceholder="MM"
              dayPlaceholder="DD"
              yearPlaceholder="YYYY"
              calendarType="US"
              error={false}
              disabled={false}
            />
            {errors.cancelDate && (
              <CancelError>
                <AlertIcon />
                {errors.cancelDate.message}
              </CancelError>
            )}
          </DatePickerContainer>
        )}
      </div>
      {isNonRenew === "false" && isBefore(new Date(cancelDate || new Date()), subDays(new Date(), 15)) && (
        <CancellationDateNotice>
          <div className="container">
            <div>
              <LightBulbIcon width={24} height={24} />
            </div>
            <div>
              <p>
                Requested cancellation date is more than 14 days. Additional documentation is required. This includes,
                but is not limited to the following:
              </p>
              <ul>
                <li>Proof of existing coverage</li>
                <li>Bill of sale</li>
                <li>Proof of dissolution</li>
                <li>Proof of previous cancellation request</li>
              </ul>
            </div>
          </div>
        </CancellationDateNotice>
      )}
    </CancellationDateContainer>
  );
}

const CancellationDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 40px;

  .cancellation-date-lead {
    color: ${({ theme }) => theme.colors.tertiary.zero};
    font-family: ${({ theme }) => theme.font.typeface.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

const CancellationDateNotice = styled.div`
  display: flex;
  justify-content: start;
  align-items: end;
  padding: 0 16px;

  .container {
    border-left: 2px solid ${(props) => props.theme.colors.primary.zero};
    display: flex;
    align-items: start;
    gap: 8px;
    color: ${({ theme }) => theme.colors.tertiary.zero};
    font-family: ${({ theme }) => theme.font.typeface.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    ul {
      padding-left: 24px;
    }
  }
`;

const StyledDatePicker = styled(DatePicker)<{ error: boolean }>`
  background: ${(props) => props.theme.white};
  height: 48px;
  width: 100%;
  max-width: 322px;
  z-index: 10;
`;

const CalendarIconWrapper = styled.div`
  svg {
    fill: ${(props) => props.theme.blue};
  }
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 36px;
`;
