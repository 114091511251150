import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import AjaxLoader from "shared/AjaxLoader";
import Account from "kit/account/Account";
import Login from "auth/login/index";
import Home from "kit/Home";
import Logs from "kit/logs/Logs";
import ApiKey from "kit/ApiKey";
import PolicySearch from "kit/policySearch";
import AccountSearch from "ia/AccountSearch";
import PayAccountSearch from "kit/accountSearch/AccountSearch";
import Policy from "kit/PolicyPage";
import ActivePolicy from "ia/Policy/ActivePolicyPage";
import DeveloperTools from "kit/developerTools/DeveloperTools";
import ChargeReport from "kit/reports/ChargeReport";
import OpenPolicyReport from "kit/reports/OpenPolicyReport";
import OpenProjectPolicyReport from "kit/reports/OpenProjectPolicyReport";
import FailedTransactionsReport from "kit/reports/FailedTransactionsReport";
import ThirdPartyLogs from "kit/ThirdPartyLogs";
import ChannelPartners from "kit/channelPartners/ChannelPartners";
import ApplicationSearch from "shared/ApplicationSearch/ApplicationSearch";
import MyAccount from "ia/MyAccount/MyAccount";
import AgentSearch from "kit/agentSearch/AgentSearch";
import QuoteSnapshot from "ia/QuoteSnapshot/QuoteSnapshot";
import QualificationChecker from "shared/QualificationChecker/QualificationChecker";
import useAuth from "hooks/useAuth";
import SimplyBindContainer from "shared/SimplyBind/SimplyBindContainer";
import DirectLink from "utils/proposal-pdf/DirectLink";
import Confirmation from "ia/Confirmation/Confirmation";
import EditChannelPartner from "kit/channelPartners/EditChannelPartner";
import Renewals from "ia/Renewals";
import ClaimsPage from "kit/Claims";
import CommissionStatements from "ia/Commissions/CommissionStatements";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import { useSelector } from "react-redux";
import { selectIsChannelPartnerOwner } from "redux/selectors/user";
import ActivePolicyUpdates from "ia/Policy/ActivePolicyUpdates";
import Logout, { LoadingContainer } from "./Logout";
import CancelPolicy from "ia/Policy/Cancel/CancelPolicy";

const DirectLogin = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth();
  const location = useLocation();
  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({ appState: { targetUrl: location.pathname } });
  }

  return (
    <LoadingContainer>
      <AjaxLoader />
    </LoadingContainer>
  );
};

const AppRoutes = () => {
  const { isDeveloper, isIAUser, isChannelPartner, isAuthenticated } = useAuth();
  const { showCommissions, showClaims, showMikesAppetiteChecker, showCancels } = useFeatureFlags();
  const isChannelPartnerOwner = useSelector((state: ReduxState) => selectIsChannelPartnerOwner(state));

  const iaRoutes = [
    <Route exact path="/" component={ApplicationSearch} />,
    <Route exact path={"/quotes"} component={ApplicationSearch} />,
    <Route exact path={"/quotes/:applicationId"} component={ApplicationSearch} />,
    <Route exact path={"/quotes/:applicationId/:tabId/edit"} component={ApplicationSearch} />,
    <Route exact path="/accounts" component={AccountSearch} />,
    <Route exact path="/policy" component={ActivePolicy} />,
    showCancels && <Route exact path="/policy/cancel/:policyNumber" component={CancelPolicy} />,
    <Route exact path="/policyEdits/:questionsType/:policyIds" component={ActivePolicyUpdates} />,
    <Route path="/my-account" component={MyAccount} />,
    showMikesAppetiteChecker && <Route path="/appetite-checker" component={QualificationChecker} />,
    <Route path="/policies/confirmation" component={Confirmation} />,
    <Route exact path="/simplybind" component={SimplyBindContainer} />,
    <Route exact path="/simplybind/:pageLabel/" component={SimplyBindContainer} />,
    <Route exact path="/simplybind/:pageLabel/:applicationId" component={SimplyBindContainer} />,
    <Route path="/failed-transactions" component={FailedTransactionsReport} />,
    <Route path="/renewals" component={Renewals} />,
    showCommissions && isChannelPartnerOwner && <Route path="/commissions" component={CommissionStatements} />,
    <Route path="/logout/callback" component={Logout} />,
  ];

  const kitRoutes = [
    <Route exact path="/" component={Home} />,
    <Route exact path={"/policies"} component={PolicySearch} />,
    <Route path={"/policies/:policyNumber"} component={Policy} />,
    <Route exact path="/accounts/:accountId" component={Account} />,
    isDeveloper && <Route exact path={"/channel-partners"} component={ChannelPartners} />,
    isDeveloper && <Route exact path={"/channel-partners/:channelPartnerId"} component={EditChannelPartner} />,
    (isChannelPartner || isDeveloper) && <Route path={"/logs"} component={Logs} />,
    isDeveloper && <Route path={"/third-party-logs"} component={ThirdPartyLogs} />,
    isChannelPartner && <Route path={"/api-key"} component={ApiKey} />,
    <Route path={"/reports/charge-report"} component={ChargeReport} />,
    <Route path={"/reports/open-policy-report"} component={OpenPolicyReport} />,
    <Route path={"/reports/open-project-policy-report"} component={OpenProjectPolicyReport} />,
    isDeveloper && <Route path={"/reports/failed-transaction-report"} component={FailedTransactionsReport} />,
    isDeveloper && <Route exact path={"/quotes"} component={ApplicationSearch} />,
    isDeveloper && <Route exact path={"/quotes/:applicationId"} component={ApplicationSearch} />,
    isDeveloper && <Route exact path={"/quotes/:applicationId/:tabId/edit"} component={ApplicationSearch} />,
    isDeveloper && <Route path={"/developer-tools"} component={DeveloperTools} />,
    isDeveloper && <Route exact path={"/agents"} component={AgentSearch} />,
    isDeveloper && <Route path={"/agents/:accountId"} component={Account} />,
    showMikesAppetiteChecker && <Route path="/appetite-checker" component={QualificationChecker} />,
    <Route exact path="/simplybind" component={SimplyBindContainer} />,
    <Route exact path="/simplybind/:pageLabel/" component={SimplyBindContainer} />,
    <Route exact path="/simplybind/:pageLabel/:applicationId" component={SimplyBindContainer} />,
    showClaims && <Route path={"/first-notice-of-loss"} component={ClaimsPage} />,

    isDeveloper && <Route path={"/agent-sign-up"} component={() => <Login />} />,
    <Route path="/policies/:policyNumber/confirmation" component={Confirmation} />,
    isDeveloper && <Route exact path={"/account-search"} component={PayAccountSearch} />,
  ];

  const authenticatedRoutes = () => {
    switch (true) {
      case isAuthenticated && isIAUser:
        return iaRoutes;
      case isAuthenticated && !isIAUser:
        return kitRoutes;
    }
  };

  return (
    <Switch>
      {authenticatedRoutes()}
      <Route exact path="/snapshot/:applicationId" component={QuoteSnapshot} />
      <Route exact path="/snapshot/:applicationId/policy" component={QuoteSnapshot} />
      <Route path="/quoteproposal/:applicationId" component={DirectLink} />
      {!isAuthenticated && <Route component={() => <DirectLogin />} />}
      {isAuthenticated && isIAUser && <Route exact path="" component={ApplicationSearch} />}
      {isAuthenticated && !isIAUser && <Route exact path="" component={Home} />}
    </Switch>
  );
};

export default AppRoutes;
