import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import debounce from "lodash.debounce";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Api from "utils/api";
import InfiniteScroll from "react-infinite-scroll-component";
import { SortDirection, AgentSortBy } from "types/enums";
import AjaxLoader from "shared/AjaxLoader";
import { reportError } from "utils/reportError";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const InfiniteSearch = ({ query, children }) => {
  const { apiVersion } = useFeatureFlags();
  const token = useSelector((state) => state.token.token);
  const api = new Api(apiVersion, token);
  const history = useHistory();
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [sortDirection, setSortDirection] = useState(SortDirection.Descending);
  const [sortBy, setSortBy] = useState(AgentSortBy.Name);

  const isAfterMountPageQuery = useRef(false);
  const isAfterMountSortQuery = useRef(false);
  const isAfterMountSearchQuery = useRef(false);

  const handleChangeSort = (sortBy, sortDirection) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  const debounceSearchForAgents = useCallback(
    debounce((query) => refetch(query), 750),
    []
  );

  const refetch = (query, page, clear = false) => {
    setLoading(true);
    api
      .agentSearch(query || "", sortBy, sortDirection, page)
      .then((serverResponse) => {
        setTotalCount(serverResponse.totalCount);
        setResults(clear ? serverResponse.accounts : [...results, ...serverResponse.accounts]);
      })
      .catch((err) => reportError(err))
      .finally(() => setLoading(false));
  };

  // // On search query change
  useEffect(() => {
    if (isAfterMountSearchQuery.current) {
      setResults([]);
      history.replace({
        pathname: "/agents",
        search: query.length > 0 ? "?q=" + query : "",
      });

      debounceSearchForAgents(query);

      setPage(1);
    } else {
      isAfterMountSearchQuery.current = true;
    }
  }, [query]);

  // On sort change
  useEffect(() => {
    if (isAfterMountSortQuery.current) {
      refetch(query, 1, true);
    } else {
      isAfterMountSortQuery.current = true;
    }
  }, [sortBy, sortDirection]);

  // On page change
  useEffect(() => {
    if (isAfterMountPageQuery.current) {
      refetch(query, page + 1);
    } else {
      const params = new URLSearchParams(location.search);
      let q = params.get("q");
      if (q === null) {
        refetch(query, page);
      }
      isAfterMountPageQuery.current = true;
    }
  }, [page]);

  // TODO:
  // having the infinite scroll be the whole page (like to the bottom of the screen) makes me a little anxious. maybe in the future we can make that in a container, like a container with margin bottom, does that make sense?

  return (
    <InfiniteScroll
      dataLength={results.length}
      next={() => setPage(page + 1)}
      hasMore={results.length >= 25 && results.length <= totalCount}
      loader={<Loader />}
    >
      {children({
        loading,
        results,
        handleChangeSort,
        sortBy,
        sortDirection,
      })}
    </InfiniteScroll>
  );
};

const StyledAjaxLoader = styled.div`
  text-align: center;
`;

const Loader = () => {
  return (
    <StyledAjaxLoader>
      <AjaxLoader />
    </StyledAjaxLoader>
  );
};

export default InfiniteSearch;
