import CoterieLogo from "images/Coterie_Text_Logo.svg";
import BrokerlinkLogo from "images/Brokerlink_Logo.svg";
import { Role } from "types/enums";
import { isCanadaEnv } from "./environmentChecker";
import { theme } from "styles";

export interface Brand {
  title: string;
  logo: any;
  customFavicon?: string;
  navStyle: any;
}

interface Brands {
  [key: string]: Brand;
}

const brandLogo: string = isCanadaEnv() ? BrokerlinkLogo : CoterieLogo;

export const brands: Brands = {
  ChannelPartner: {
    title: "",
    logo: brandLogo,
    navStyle: {
      backgroundColor: theme.navBg,
      color: theme.white,
    },
  },
  IA: {
    title: "",
    logo: brandLogo,
    navStyle: {
      backgroundColor: theme.navBg,
      color: theme.white,
    },
  },
  Developer: {
    title: "",
    logo: brandLogo,
    navStyle: {
      backgroundColor: theme.navBg,
      color: theme.white,
    },
  },
  CustomerAdvocate: {
    title: "K.I.T.",
    logo: brandLogo,
    navStyle: {
      backgroundColor: theme.black,
      color: theme.bgRed,
    },
  },
  ClaimsAdministrator: {
    title: "",
    logo: brandLogo,
    navStyle: {
      backgroundColor: theme.navBg,
      color: theme.white,
    },
  },
};

const isKitDomain = () => /http.?:\/\/kit.*/.test(window.location.href);

export const getBrand = (roles: Role[]) => {
  if (roles.includes(Role.IAProducer)) {
    return brands.IA;
  }

  if (roles.includes(Role.ChannelPartner)) {
    return brands.ChannelPartner;
  }

  if (roles.includes(Role.Developer)) {
    return brands.Developer;
  }

  if (isKitDomain()) return brands.Developer;
  return brands.ChannelPartner;
};
