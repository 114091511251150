import React, { useEffect } from "react";
import styled from "styled-components";
import { RouteComponentProps } from "react-router";
import { BlobProvider } from "@react-pdf/renderer";
import { Header4 } from "styles";
import AjaxLoader from "shared/AjaxLoader";
import useGetPublicApplication from "hooks/useGetPublicApplication";
import useGetPublicForms from "hooks/useGetPublicForms";
import useGetPublicIndustry from "hooks/useGetPublicIndustry";
import usePostPublicGetQuote from "hooks/usePostPublicGetQuote";
import usePostPublicGetAvailableEndorsements from "hooks/usePostPublicGetAvailableEndorsements";
import useGetPublicAgencyLogo from "hooks/useGetPublicAgencyLogo";
import CorePDF from "./index";
import { downloadPDF } from "./downloadPDF";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface RouteParams {
  applicationId: string;
}

interface PropTypes extends RouteComponentProps<RouteParams> {}

const DirectLink = ({ match }: PropTypes) => {
  const { params } = match;
  const { increaseFlPropertyMin } = useFeatureFlags();

  const {
    mutate: postPublicGetAvailableEndorsements,
    data: availableEndorsementsResults,
    isSuccess: availableEndorsementsHasLoaded,
  } = usePostPublicGetAvailableEndorsements();

  const { data: applicationResults, isSuccess: applicationHasLoaded } = useGetPublicApplication(params.applicationId);

  const { mutate: postPublicGetQuote, data: quoteResults, isSuccess: quoteHasLoaded } = usePostPublicGetQuote();

  useEffect(() => {
    if (applicationHasLoaded) {
      const { partner, agent } = applicationResults;
      const token = partner?.id;
      const agencyExternalId = agent?.agencyExternalId;
      const producerExternalID = agent?.producerExternalID;
      postPublicGetAvailableEndorsements(applicationResults.application);
      const application = {
        ...applicationResults.application,
        agencyExternalId: agencyExternalId,
        producerExternalID: producerExternalID,
      };
      postPublicGetQuote({ application, token });
    }
  }, [applicationHasLoaded, applicationResults, postPublicGetAvailableEndorsements, postPublicGetQuote]);

  const akHash = applicationResults?.application?.akHash;
  const agencyName = applicationResults?.agent?.agencyName;
  const application = applicationResults?.application;

  const alternateQuote = quoteHasLoaded
    ? {
        isOptInLowerCost: quoteResults.alternateQuote.isOptInLowerCost,
        lossControlOptOutPremium: quoteResults.alternateQuote.lossControlOptOut.premium,
        lossControlOptOutMonthlyPremium: quoteResults.alternateQuote.lossControlOptOut.monthlyPremium,
        optInCostDifferenceYearly: quoteResults.alternateQuote.optInCostDifferenceYearly,
      }
    : {
        isOptInLowerCost: false,
        lossControlOptOutPremium: 0,
        lossControlOptOutMonthlyPremium: 0,
        optInCostDifferenceYearly: 0,
      };

  const { data: formsResponse, isFetched: formsHasLoaded } = useGetPublicForms(params.applicationId, {
    enabled: Boolean(applicationResults?.application?.locations?.[0]?.state),
  });

  const { data: urlLogoExists } = useGetPublicAgencyLogo(String(params.applicationId), {
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const logoURL = urlLogoExists
    ? `${process.env.REACT_APP_API_BASE_URL}/v1.4/commercial/logos/${params.applicationId}`
    : "";

  const { data: industryName } = useGetPublicIndustry(akHash, {
    enabled: Boolean(akHash),
  });

  return (
    <Container>
      {quoteResults && applicationResults && formsResponse && availableEndorsementsResults ? (
        <BlobProvider
          document={
            <CorePDF
              quote={quoteResults?.quote}
              agencyName={agencyName}
              application={application}
              forms={formsResponse}
              industryName={industryName}
              agencyLogo={logoURL}
              increaseFlPropertyMin={increaseFlPropertyMin}
              alternateQuote={alternateQuote}
            />
          }
        >
          {({ url, loading }) => {
            if (
              !loading &&
              url &&
              quoteHasLoaded &&
              applicationHasLoaded &&
              formsHasLoaded &&
              availableEndorsementsHasLoaded
            ) {
              downloadPDF(url);
            }

            return (
              <div data-cy="direct-link-info-text">
                {loading ? (
                  <Header4 data-testid="download-not-started-2">
                    Downloading quote proposal. Look below for your download to complete.
                  </Header4>
                ) : (
                  <Header4>Quote proposal download complete!</Header4>
                )}
                {loading && <AjaxLoader />}
              </div>
            );
          }}
        </BlobProvider>
      ) : (
        <>
          <Header4 data-testid="download-not-started-1">
            Downloading quote proposal. Look below for your download to complete.
          </Header4>
          <AjaxLoader />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 75px;
  text-align: center;
`;

export default DirectLink;
