import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, CardBody, CardTitle } from "reactstrap";
import Card from "reactstrap/lib/Card";
import Api from "utils/api";
import { toastSuccess } from "utils/toast";
import ChargeReportTable from "kit/ChargeReportTable";
import PolicyReportTable from "kit/PolicyReportTable";
import UpdatePaymentMethodModal from "kit/updatePaymentMethod/UpdatePaymentMethodModal";
import UpdateAgentPasswordModal from "kit/updateAgentPassword/UpdateAgentPasswordModal";
import { AffiliatedStatus } from "types/enums";
import EditAccountModal from "./EditAccountModal";
import { isEmpty } from "lodash";
import useAuth from "hooks/useAuth";
import { mobile } from "styles";
import { Subtitle, TitleContainer, Title } from "styles/containerStyledComponents";
import ActionsDropdown from "styles/ActionsDropdown";
import StatusSymbol from "shared/StatusSymbol";
import { reportError } from "utils/reportError";
import EditAgentAccountModal from "./EditAgentAccountModal";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import AffiliatedStatusBadge from "shared/AffiliatedStatusBadge";

interface RouteParams {
  accountId: string;
}

interface PropTypes extends RouteComponentProps<RouteParams> {}

const Account = ({ match }: PropTypes) => {
  const [account, setAccount] = useState<AccountType>({});
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [charges, setCharges] = useState<PolicyChargeReportItem[]>([]);
  const [isLoadingCharges, setIsLoadingCharges] = useState(true);
  const [isLoadingPolicies, setIsLoadingPolicies] = useState(true);
  const [showUpdatePaymentMethodModal, setShowUpdatePaymentMethodModal] = useState(false);
  const [showEditAccountModal, setShowEditAccountModal] = useState(false);
  const [showUpdateAgentPasswordModal, setShowUpdateAgentPasswordModal] = useState(false);
  const { params } = match;
  const accountId = params.accountId;
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  useEffect(() => {
    reloadData();
  }, [match.url]);

  const reloadAccount = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    api.getAccount(accountId).then((account) => {
      setAccount(account);
    });
  };

  const reloadData = async () => {
    setIsLoadingPolicies(true);
    setIsLoadingCharges(true);

    const token = await getToken();
    const api = new Api(apiVersion, token);

    api
      .getAccount(accountId)
      .then((account) => {
        setAccount(account);
      })
      .catch((error) => reportError(error));

    api
      .getAccountPolicies(accountId)
      .then((policies) => {
        setPolicies(policies);
        setIsLoadingPolicies(false);
      })
      .catch((error) => reportError(error));

    api
      .getAccountCharges(accountId, "1/1/2019")
      .then((charges) => {
        setCharges(charges);
        setIsLoadingCharges(false);
      })
      .catch((error) => reportError(error));
  };

  const saveAccountEdits = async (accountId: number, accountUpdate: UpdateAccountRequest): Promise<void> => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api
      .updateAccount(accountId, accountUpdate)
      .then(() => {
        reloadData();
        setShowEditAccountModal(false);
        toastSuccess("Account Updated!");
      })
      .catch((error) => reportError(error));
  };

  const saveAgentEdits = async (agentId: number, agentUpdate: UpdateAgentRequest): Promise<void> => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api
      .updateAgent(agentId, agentUpdate)
      .then(() => {
        reloadData();
        setShowEditAccountModal(false);
        toastSuccess("Agent Updated!");
      })
      .catch((error) => reportError(error));
  };

  const toggleAffiliated = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    const isAffiliated = account?.affiliatedStatus === AffiliatedStatus.Affiliated;
    api
      .updateAgentAffiliation(accountId, isAffiliated ? AffiliatedStatus.NonAffiliated : AffiliatedStatus.Affiliated)
      .then(() => {
        reloadAccount();
        toastSuccess("Account updated successfully");
      })
      .catch((error) => reportError(error));
  };

  const affiliatedStatus = account?.agencyDetails?.id
    ? account?.affiliatedStatus === AffiliatedStatus.Affiliated
      ? "Affiliated"
      : "NonAffiliated"
    : undefined;

  const breadcrumbPath = window.location.href.includes("agents") ? (
    <Link to={`/agents`}>Agent Search</Link>
  ) : (
    <Link to={`/policies`}>Policy Search</Link>
  );

  const accountType = account?.accountType || "";
  const nationalProducerNumber = account?.nationalProducerNumber || "";
  const channelPartnerName = account?.channelPartnerDetails?.name || "";
  const publishableKey = account?.channelPartnerDetails?.publishableKey || "";
  const agencyExternalId = account?.agencyDetails?.externalId || "";
  const agencyName = account?.agencyDetails?.name || "";
  const producerId = account?.producerId || "";
  const titleSecondarySubtitle = [
    `Account ID: ${account?.id}`,
    `Producer ID: ${producerId}`,
    `NPN: ${nationalProducerNumber}`,
    accountType,
    `Channel Partner: ${channelPartnerName} ${Boolean(account?.isChannelPartnerOwner) ? `(owner)` : ""}`,
    `Channel Partner Publishable Key: ${publishableKey}`,
    `Agency ID: ${agencyExternalId}`,
    `Agency: ${agencyName} ${Boolean(account?.isAgencyOwner) ? `(owner)` : ""}`,
  ];

  if (isEmpty(account) || !account) {
    return (
      <Breadcrumb>
        <BreadcrumbItem>{breadcrumbPath}</BreadcrumbItem>
      </Breadcrumb>
    );
  }

  const dropdownActions = [
    {
      name: "Update Payment Method",
      action: () => {
        setShowUpdatePaymentMethodModal(true);
      },
    },
    {
      name: "Edit Account",
      action: () => {
        setShowEditAccountModal(true);
      },
    },
    {
      name: "Toggle Affiliated",
      action: toggleAffiliated,
      hide: !account?.agencyDetails?.id,
    },
    {
      name: "Update Password",
      action: () => {
        setShowUpdateAgentPasswordModal(true);
      },
    },
  ];

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>{breadcrumbPath}</BreadcrumbItem>
        <BreadcrumbItem active>Account ID {account.id}</BreadcrumbItem>
      </Breadcrumb>
      <TitleContainer>
        <Title hasStatus>{`${account.firstName} ${account.lastName}`}</Title>
        {affiliatedStatus && <AffiliatedStatusBadge expanded status={affiliatedStatus} />}
        <ActionsDropdown actions={dropdownActions} />
      </TitleContainer>
      <Subtitle>
        <SubtitleEmail href={`mailto:${account.email}`} data-cy={"subtitle"}>
          {account.email}
        </SubtitleEmail>
        <SubtitleCont>
          {titleSecondarySubtitle.map((subtitle) => (
            <SubTitle>
              <SubtitleDivider>|</SubtitleDivider>
              <P>{subtitle}</P>
            </SubTitle>
          ))}
        </SubtitleCont>
      </Subtitle>
      <CardContainer>
        <Card>
          <CardBody>
            <CardTitle>Payment Info</CardTitle>
            <div>Card Type: {account?.paymentProfile?.cardType}</div>
            <div>CC: {account?.paymentProfile?.creditCard}</div>
            <div>EXP: {account?.paymentProfile?.expiration}</div>
          </CardBody>
        </Card>
      </CardContainer>
      <CardContainer>
        <Card>
          <CardBody>
            <CardTitle>Policies</CardTitle>
            <PolicyReportTable policies={policies} isLoading={isLoadingPolicies} />
          </CardBody>
        </Card>
      </CardContainer>

      <CardContainer>
        <Card>
          <CardBody>
            <CardTitle>Charges</CardTitle>
            <ChargeReportTable charges={charges} isLoading={isLoadingCharges} />
          </CardBody>
        </Card>
      </CardContainer>

      <UpdatePaymentMethodModal
        modalIsOpen={showUpdatePaymentMethodModal}
        onRequestClose={() => setShowUpdatePaymentMethodModal(!showUpdatePaymentMethodModal)}
        accountId={account.id}
      />
      {account.accountType === "Other" ? (
        <EditAccountModal
          account={account}
          editAccount={saveAccountEdits}
          closeModal={() => setShowEditAccountModal(!showEditAccountModal)}
          modalIsOpen={showEditAccountModal}
        />
      ) : (
        <EditAgentAccountModal
          account={account}
          editAgent={saveAgentEdits}
          closeModal={() => setShowEditAccountModal(!showEditAccountModal)}
          modalIsOpen={showEditAccountModal}
        />
      )}

      <UpdateAgentPasswordModal
        modalIsOpen={showUpdateAgentPasswordModal}
        onRequestClose={() => setShowUpdateAgentPasswordModal(!setShowUpdateAgentPasswordModal)}
        accountId={account.id}
      />
    </div>
  );
};

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  .card-title {
    font-weight: bold;
  }
  .card {
    margin: 5px;
    flex: 1 1 30%;
  }
  .form-control:disabled {
    background: #f7f7f7;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  .input-group-text {
    min-width: 10em;
  }
`;

const SubtitleEmail = styled.a`
  color: ${(props) => props.theme.gray};
`;

const SubtitleDivider = styled.p`
  margin: 0px 8px;

  ${mobile} {
    display: none;
  }
`;

const SubtitleCont = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SubTitle = styled.div`
  display: flex;
`;

const P = styled.p`
  margin: 0;
`;

export default Account;
