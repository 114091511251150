import React, { useReducer } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { GreenButton, Label, theme } from "styles";
import styled from "styled-components";
import { ActionContainer, Column, Row } from "shared/ModalComponents";
import { ErrorText } from "styles/Input";
import { toastSuccess } from "utils/toast";
import { reportStripeError } from "utils/reportError";
import { loadStripe } from "@stripe/stripe-js";
import { stripeOptionsObject } from "utils/paymentUtils";
import usePutUpdateDefaultPaymentMethod from "hooks/usePutUpdateDefaultPaymentMethod";

interface UpdatePaymentMethodFormProps {
  accountId: number;
  onRequestClose: () => void;
  afterPaymentUpdate?: () => void;
}

type StateType = {
  focusedInput: string | null;
  error: string | undefined;
};

type Action =
  | { type: "focus_cc_number" }
  | { type: "focus_expiration" }
  | { type: "focus_cvc" }
  | { type: "blur_input" }
  | { type: "reset-error" }
  | { type: "error"; payload: string | undefined };

const initialState = {
  focusedInput: null,
  error: undefined,
};

function reducer(state: StateType, action: Action) {
  switch (action.type) {
    case "focus_cc_number":
      return {
        ...state,
        focusedInput: action.type,
      };
    case "focus_expiration":
      return {
        ...state,
        focusedInput: action.type,
      };
    case "focus_cvc":
      return {
        ...state,
        focusedInput: action.type,
      };
    case "error":
      return {
        ...state,
        error: action.payload,
      };
    case "reset-error":
      return {
        ...state,
        error: "",
      };
    case "blur_input":
      return {
        ...state,
        focusedInput: null,
      };

    default:
      return state;
  }
}

const CreditCardForm = ({ accountId, onRequestClose, afterPaymentUpdate }: UpdatePaymentMethodFormProps) => {
  const [{ focusedInput, error }, dispatch] = useReducer(reducer, initialState);
  const stripe = useStripe();
  const elements = useElements();

  const inputStyle = {
    fontWeight: "400",
    fontFamily: theme.secondaryFont,
    fontSize: "16px",
    lineHeight: "25px",
  };
  const options = {
    placeholder: "",
    style: {
      base: inputStyle,
    },
  };

  const { mutateAsync: updatePaymentMethod, isPending } = usePutUpdateDefaultPaymentMethod({ accountId });

  const handleSubmit = async (e: any) => {
    if (!e.elementType) {
      e.preventDefault();
    }
    if (isPending) return;

    const cardElement = elements && elements.getElement("cardNumber");

    if (stripe && cardElement) {
      const { token, error } = await stripe.createToken(cardElement);
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          dispatch({ type: "error", payload: error.message });
        } else {
          reportStripeError(error);
        }
      }
      if (token) {
        updatePaymentMethod(token.id)
          .then(() => {
            dispatch({ type: "reset-error" });
            onRequestClose();
            toastSuccess("Payment method updated successfully");
            if (afterPaymentUpdate) afterPaymentUpdate();
          })
          .catch(() => {
            reportStripeError("Error updating payment method");
          });
      }
    } else {
      reportStripeError("Stripe Failed To initialize", "An issue has occurred");
    }
  };

  return (
    <form onSubmit={handleSubmit} data-testid="stripe_form">
      <Label htmlFor="card_number">Credit Card Number</Label>
      <CardInputWrapper hasFocus={focusedInput === "focus_cc_number"}>
        <CardNumberElement
          id="card_number"
          onFocus={() => dispatch({ type: "focus_cc_number" })}
          onBlur={() => dispatch({ type: "blur_input" })}
          options={options}
        />
      </CardInputWrapper>
      <Row>
        <Column>
          <Label htmlFor="card_expiration">Expiration</Label>
          <CardInputWrapper hasFocus={focusedInput === "focus_expiration"}>
            <CardExpiryElement
              id="card_expiration"
              onFocus={() => dispatch({ type: "focus_expiration" })}
              onBlur={() => dispatch({ type: "blur_input" })}
              options={options}
            />
          </CardInputWrapper>
        </Column>
        <Column>
          <Label htmlFor="card_cvc">CVC (Security Code)</Label>
          <CardInputWrapper hasFocus={focusedInput === "focus_cvc"}>
            <CardCvcElement
              id="card_cvc"
              onFocus={() => dispatch({ type: "focus_cvc" })}
              onBlur={() => dispatch({ type: "blur_input" })}
              options={options}
            />
          </CardInputWrapper>
        </Column>
      </Row>
      <ErrorText>{error}</ErrorText>
      <ActionContainer>
        <GreenButton
          data-cy="cta-button"
          type="submit"
          disabled={isPending}
          data-testid={`cta-button-${isPending ? "disabled" : "active"}`}
        >
          Update
        </GreenButton>
      </ActionContainer>
    </form>
  );
};

export const CardInputWrapper = styled.div<{ hasFocus: boolean }>`
  height: 48px;
  background-color: ${(props) => props.theme.white};
  border: 1px solid ${(props) => (props.hasFocus ? props.theme.blue : props.theme.lighterGray)};
  padding: 10px 14px;
  border-radius: 4px;
  margin-bottom: 32px;
`;

export { CreditCardForm };

const UpdatePaymentMethodForm = (props: UpdatePaymentMethodFormProps) => {
  if (!process.env.REACT_APP_STRIPE_KEY) {
    return null;
  }
  const publishableKey = process.env.REACT_APP_STRIPE_KEY;
  const stripePromise = loadStripe(publishableKey);
  return (
    <Elements options={stripeOptionsObject} stripe={stripePromise}>
      <CreditCardForm {...props} />
    </Elements>
  );
};

// used in UpdatePaymentMethodModal in KIT
export default UpdatePaymentMethodForm;
