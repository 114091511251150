import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { portalApi } from "hooks/useAPI/instances";
import { reportFailFetchRuleMetadata } from "utils/reportError";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const getTags = async (token: Token, apiVersion: string) => {
  const api = portalApi(apiVersion, token);
  const { data } = await api.get(`/UnderwritingRule/metadata/values/tags`).catch((error) => {
    reportFailFetchRuleMetadata(error);

    return Promise.reject(error);
  });

  return data;
};

type Options = { enabled?: boolean };

const useGetUnderwritingRuleTags = (options?: Options) => {
  const { apiVersion } = useFeatureFlags();
  const token = useSelector((state: ReduxState) => state.token.token);
  return useQuery({
    queryKey: ["tags"],
    queryFn: () => getTags(token, apiVersion),
    retry: 1,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export default useGetUnderwritingRuleTags;
