import { Header } from "coterie-ui-library";
import React, { HTMLAttributes, ReactNode, FC } from "react";
import styled from "styled-components";

interface PolicyTabContainerProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  heading: ReactNode;
  headerLabel?: string;
}

export const PolicyTabContainer: FC<PolicyTabContainerProps> = ({
  heading,
  headerLabel,
  children,
  ...props
}: PolicyTabContainerProps) => {
  return (
    <PolicyContainer {...props}>
      <Header as="h2" size="medium" data-cy={headerLabel} data-testid={headerLabel}>
        {heading}
      </Header>
      {children}
    </PolicyContainer>
  );
};

const PolicyContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 30px;
    min-height: 100vh;
    background-color:  ${({ theme }) => theme.pageComponents.ia.policyDetails.background}}};
`;
