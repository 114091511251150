import React, { useState } from "react";
import SmoothCollapse from "react-smooth-collapse";
import { ReactComponent as CaretDownIcon } from "images/caret_down.svg";
import { ReactComponent as CaretUpIcon } from "images/caret_up.svg";
import styled from "styled-components";
import theme from "styles/theme";
import { tablet } from "styles";

interface CoverageExclusionProps {
  isBOP: boolean;
  exclusions?: string[];
  initialExpanded?: boolean;
}

export const CoverageExclusionAccordion = ({
  isBOP,
  exclusions = [],
  initialExpanded = false,
}: CoverageExclusionProps) => {
  const [expanded, setExpanded] = useState<boolean>(initialExpanded);
  const cardBorder = theme.pageComponents.shared.CoverageExclusionAccordion[isBOP ? "bop" : "gl"].cardBorderColor;
  const cardBG = theme.pageComponents.shared.CoverageExclusionAccordion[isBOP ? "bop" : "gl"].cardBackgroundColor;
  const exclusionColOne: string[] = [...exclusions.slice(0, Math.ceil(exclusions.length / 2))];
  const exclusionColTwo: string[] = [...exclusions.slice(Math.ceil(exclusions.length / 2), exclusions.length)];

  return (
    <>
      {exclusions && exclusions.length > 0 ? (
        <CardContainer data-testid="exclusion-container-test-id" expanded={expanded} cardBorder={cardBorder}>
          <CardBar data-testid="exclusion-bar-test-id" bgColor={cardBG} onClick={() => setExpanded(!expanded)}>
            <CardTitle>Which operations are excluded?</CardTitle>
            <CaretIconWrapper>{expanded ? <CaretUpIcon /> : <CaretDownIcon />}</CaretIconWrapper>
          </CardBar>
          <SmoothCollapse data-testid="exclusion-container-collapse-test-id" expanded={expanded} eagerRender={true}>
            <CardTitle style={{ margin: "16px 24px" }}>
              We exclude the following operations as part of this industry:
            </CardTitle>
            <CardContent>
              <ExclusionList>
                {exclusionColOne.map((exclusion: string, i: number) => {
                  return <li key={exclusion}>{exclusion}</li>;
                })}
              </ExclusionList>
              <ExclusionList>
                {exclusionColTwo.map((exclusion: string, i: number) => {
                  return <li key={exclusion}>{exclusion}</li>;
                })}
              </ExclusionList>
            </CardContent>
          </SmoothCollapse>
        </CardContainer>
      ) : (
        <></>
      )}
    </>
  );
};

const CaretIconWrapper = styled.div`
  svg {
    fill: ${theme.pageComponents.shared.CoverageExclusionAccordion.caretColor};
  }
`;

const CardTitle = styled.div`
  flex-grow: 1;
  color: ${theme.pageComponents.shared.CoverageExclusionAccordion.cardTitleColor};
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
`;

const CardContainer = styled.div<{ expanded: boolean; cardBorder: string }>`
  border-radius: 4px;
  border-top: 5px solid ${(props) => props.cardBorder};
  border-left: 1px solid ${(props) => props.cardBorder};
  border-bottom: 1px solid ${(props) => props.cardBorder};
  border-right: 1px solid ${(props) => props.cardBorder};
  margin: 16px 0px;
  width: 100%;
  max-width: 1044px;
  font-color: ${theme.colors.tertiary.zero};
`;

const CardBar = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${(props) => props.bgColor};
  padding: 16px;
  height: 53px;
  border-radius: 0px 0px 4px 4px;
`;

export const CardContent = styled.div`
  border-radius: 4px;
  margin: 0 24px 24px;
  justify-content: space-between;
  background-color: ${theme.pageComponents.shared.CoverageExclusionAccordion.cardBackgroundColor};
  display: flex;
  flex-flow: row wrap;
`;

const ExclusionList = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: column;
  flex-basis: 40%;
  padding: 0;
  margin: 0 24px;
  align-items: space-between;

  li:before {
    content: "•";
    font-size: 15px;
    color: ${theme.pageComponents.shared.CoverageExclusionAccordion.cardBulletColor};
    font-weight: bold;
    display: inline-block;
    margin-right: 16px;
    margin-left: -1em;
    line-height: 23px;
  }
  li {
    font-family: ${theme.pageComponents.shared.CoverageExclusionAccordion.cardListFont};
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    width: 97%;
  }

  ${tablet} {
    flex-basis: 100%;
  }
`;
