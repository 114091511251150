import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { QuestionRow } from "../StyledComponents";
import Input from "styles/Input";
import { selectApplicationFormSales } from "redux/selectors/application";
import { selectUnderwritingProfile } from "redux/selectors/underwritingProfile";
import { updateEndorsementQuestionState } from "redux/reducers/_underwritingProfileReducer";

interface PropTypes {
  action: (value: { alcoholicBeverageSales: number }) => void;
  annualSales: number;
  underwritingProfile: UnderwritingProfile;
  updateEndorsementQuestionState: (endorsementDetails: { key: string; questionHasBeenAnswered: boolean }) => void;
}

const LiquorLiability = ({ action, annualSales, updateEndorsementQuestionState, underwritingProfile }: PropTypes) => {
  const alcoholicBeverageSales =
    underwritingProfile?.legacyApplication?.endorsements?.liquorLiabilityEndorsement?.alcoholicBeverageSales;
  const [amount, setAmount] = useState(alcoholicBeverageSales === 0 ? undefined : alcoholicBeverageSales);
  const hasError = (amount: number) => amount > annualSales / 2 || !annualSales;

  useEffect(() => {
    setAmount(alcoholicBeverageSales === 0 ? undefined : alcoholicBeverageSales);
  }, [alcoholicBeverageSales]);

  const errors = [
    {
      errorText: "An amount is required to add this coverage",
      error: (amount: number | undefined) => !amount,
    },
    {
      errorText: "Annual liquor sales cannot exceed half of gross annual sales",
      error: (amount: number) => Boolean(amount > annualSales / 2 || !annualSales),
    },
  ];

  const handleChange = (amount: number) => {
    setAmount(amount);
    verify(amount);
  };

  const verify = useCallback(
    debounce((newAmount) => {
      updateEndorsementQuestionState({
        key: "liquorLiabilityEndorsement",
        questionHasBeenAnswered: Boolean(newAmount && !hasError(newAmount)),
      });
      action({ alcoholicBeverageSales: newAmount });
    }, 2000),
    []
  );

  const amountError = errors?.find((e) => e.error(amount))?.errorText;
  const errorProps = amountError ? { error: true, errorText: amountError } : {};

  return (
    <QuestionRow id="Liquor-Liability-Endorsement-Question">
      <Input
        type="text"
        name="liquorLiability"
        id="quote-liquor-liability"
        value={amount}
        handleChange={(event: { floatValue: number }) => handleChange(event.floatValue)}
        prefix="$"
        label="What are your annual liquor sales?"
        placeholder="Enter sales"
        {...errorProps}
      />
    </QuestionRow>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  annualSales: selectApplicationFormSales(state),
  underwritingProfile: selectUnderwritingProfile(state),
});

const mapDispatchToProps = {
  updateEndorsementQuestionState,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiquorLiability);
