import React, { useContext } from "react";
import { Dropdown } from "styles";
import { RuleOptions } from "../../Shared/types";
import RuleTextInput from "./RuleTextInput";
import { RuleLogicBlock, LogicBlockHeader, FlexDiv, DeleteLogicBlockButtonContainer } from "../styles";
import { removeNonAlphaNumericChars } from "../utils";
import { RuleBuilderContext } from "../RuleBuilderContext";
import DeleteButton from "../DeleteButton";

export interface OptionType {
  value: string | number;
  label: string;
}

interface ControlBlockProps {
  ruleOption: RuleOptions;
}

const policyOptions = [
  { value: "PL", label: "PL" },
  { value: "BOP", label: "BOP" },
  { value: "GL", label: "GL" },
];

const controlOptions = [
  { value: "applyScore", label: "apply score", inputType: "number" },
  { value: "decline", label: "decline", inputType: "dropdownMulti" },
  { value: "excludeEndorsements", label: "exclude endorsements", inputType: "text" },
  { value: "requireEndorsements", label: "require endorsements", inputType: "text" },
  { value: "recommendEndorsements", label: "recommend endorsements", inputType: "text" },
  { value: "attachForms", label: "attach forms", inputType: "text", disabled: true },
  { value: "requireMinimumDeductible", label: "require minimum deductible", inputType: "number", disabled: true },
];

const ControlBlock = ({ ruleOption }: ControlBlockProps) => {
  const {
    updateLogicBlock,
    updateControlTarget,
    updateControlAction,
    deleteBlock,
    activeRulePath,
    activeLogicBlock,
  } = useContext(RuleBuilderContext);
  const isFirstRuleOption = ruleOption.path === "0.options.0";
  const isSelected = ruleOption.path === activeRulePath && activeLogicBlock === "control";

  return (
    <>
      {isFirstRuleOption && <LogicBlockHeader>Then...</LogicBlockHeader>}
      <RuleLogicBlock
        isSelected={isSelected}
        onFocus={() => updateLogicBlock("control")}
        onClick={() => updateLogicBlock("control")}
      >
        <FlexDiv>{!isFirstRuleOption && <LogicBlockHeader>Then...</LogicBlockHeader>}</FlexDiv>
        <FlexDiv>
          <Dropdown
            value={ruleOption.controlAction ?? ""}
            options={controlOptions}
            name="controlAction"
            label="control action"
            labelIsHidden
            onChange={(selection) => {
              updateControlAction(selection.value);
            }}
          />
          {controlOptions.find((option) => option.value === ruleOption.controlAction)?.inputType === "dropdownMulti" ? (
            <Dropdown
              isMulti
              label="policy types"
              labelIsHidden
              multiValues={policyOptions.filter((opt) =>
                ruleOption.controlTarget
                  ?.split(",")
                  .map((str) => removeNonAlphaNumericChars(str))
                  .includes(opt.value)
              )}
              options={policyOptions}
              name="policyOptions"
              onChange={(selection) => {
                updateControlTarget(selection?.map((option: OptionType) => option.value).join(", ") ?? "");
              }}
            />
          ) : (
            <RuleTextInput
              type={controlOptions.find((option) => option.value === ruleOption.controlAction)?.inputType}
              defaultValue={ruleOption.controlTarget}
              name="controlTarget"
              label="control target"
              updateRuleProperty={(controlTarget: string) => updateControlTarget(controlTarget)}
              inputStyles={{ marginBottom: 8 }}
              updateKey="controlTarget"
            />
          )}
        </FlexDiv>
        <DeleteLogicBlockButtonContainer>
          <DeleteButton
            ariaLabel="delete control"
            isDisabled={!isSelected}
            onClick={() => deleteBlock("control")}
          ></DeleteButton>
        </DeleteLogicBlockButtonContainer>
      </RuleLogicBlock>
    </>
  );
};

export default ControlBlock;
