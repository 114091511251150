import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { reportError } from "utils/reportError";
import { portalApi } from "./useAPI/instances";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const getLegacyApplication = async (token: Token, apiVersion: string, applicationId: string) => {
  const api = portalApi(apiVersion, token);
  const { data } = await api.get(`/applications/${applicationId}`).catch((error) => {
    reportError(error);

    return Promise.reject(error);
  });

  return data;
};

type Options = { enabled?: boolean };

const useGetLegacyApplication = (applicationId: string, options?: Options) => {
  const { apiVersion } = useFeatureFlags();
  const token = useSelector((state: ReduxState) => state.token.token);
  return useQuery({
    queryKey: ["legacy_application", applicationId],
    queryFn: () => getLegacyApplication(token, apiVersion, applicationId),
    retry: 1,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export default useGetLegacyApplication;
