import React from "react";
import styled from "styled-components";
import { toastSuccess } from "utils/toast";
import Clipboard from "react-clipboard.js";
import { ReactComponent as ClipboardIcon } from "images/clipboard.svg";
import ToolTipIcon from "shared/ToolTipIcon";

interface PropTypes {
  value: string;
  label: string;
  labelHeight?: string;
  successOverride?: () => void;
  tooltipContentKey?: string;
}

const ClipboardInput = ({ value, label, labelHeight, successOverride, tooltipContentKey }: PropTypes) => {
  const onSuccess = () => toastSuccess(`${value} has been copied to your clipboard`);

  return (
    <FieldContainer>
      <Label>
        <Property style={labelHeight ? { maxHeight: labelHeight } : {}}>{label}</Property>
        {tooltipContentKey && <ToolTipIcon contentKey={tooltipContentKey} />}
      </Label>
      <Value>
        <Property data-cy="field-value">{value}</Property>
      </Value>
      <ClipboardContainer>
        <Clipboard
          style={{ backgroundColor: "white" }}
          onSuccess={successOverride ?? onSuccess}
          data-clipboard-text={value}
        >
          <ClipboardIcon className="social-icon" />
        </Clipboard>
      </ClipboardContainer>
    </FieldContainer>
  );
};

const Property = styled.span`
  align-self: center;
  max-height: 20px;
  overflow: hidden;
`;

const FieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  min-height: 40px;
  width: 100%;
  margin: 2px 0;
`;

const Label = styled.div`
  background: ${(props) => props.theme.labelGray};
  width: 147px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 1px;
  color: ${(props) => props.theme.charcoalGrey};
  display: flex;
  padding: 8px;
`;

const Value = styled.div<{ invalid?: boolean }>`
  width: 150px;
  display: flex;
  flex: 1;
  padding: 8px;
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.gray};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
  word-break: break-all;
`;

const ClipboardContainer = styled.div`
  background: ${(props) => props.theme.white};
  margin-left: 2px;
  width: 50px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ClipboardInput;
