import React, { useReducer, useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Label, theme } from "styles";
import styled from "styled-components";
import { Column, Row } from "shared/ModalComponents";
import { ErrorText, StyledInput } from "styles/Input";
import { toastSuccess } from "utils/toast";
import { reportError, reportStripeError } from "utils/reportError";
import { loadStripe } from "@stripe/stripe-js";
import { stripeOptionsObject } from "utils/paymentUtils";
import { usePutDetachPolicy } from "hooks/usePutDetachPolicy";
import { usePostDetachPolicyPreview } from "hooks/usePostDetachPolicyPreview";
import AjaxLoader from "shared/AjaxLoader";
import DetachPreview from "./DetachPreview";
import reducer, { initialState } from "./reducers/creditCardFormReducer";
import { AxiosError } from "axios";
import { StyledModalBody } from "./styles";
import { ModalActionBar } from "coterie-ui-library";

interface CreditCardProps {
  onSubmit: (error?: AxiosError<any>) => void;
  policy: Policy;
}

const StripeCreditCardComponent = ({ onSubmit, policy }: CreditCardProps) => {
  const [firstName, setFirstName] = useState(policy.contactFirstName || "");
  const [lastName, setLastName] = useState(policy.contactLastName || "");
  const [detachPolicyPayload, setDetachPolicyPayload] = useState<DetachRequestData | null>(null);
  const [email, setEmail] = useState("");
  const [{ focusedInput, error }, dispatch] = useReducer(reducer, initialState);
  const stripe = useStripe();
  const elements = useElements();

  const { policyNumber } = policy;

  const inputStyle = {
    fontWeight: "400",
    fontFamily: theme.secondaryFont,
    fontSize: "16px",
    lineHeight: "25px",
  };
  const options = {
    placeholder: "",
    style: {
      base: inputStyle,
    },
  };

  const {
    data: previewData,
    reset: resetDetachPreview,
    isPending: isPreviewLoading,
    isSuccess: isPreviewSuccess,
    mutate: postDetachPolicyPreview,
  } = usePostDetachPolicyPreview({
    onError: (error: AxiosError<any>) => {
      onSubmit(error);
      const skipToast = error?.response?.status === 409;
      reportError(error, undefined, undefined, { skipToast });
    },
  });

  const { isPending: isDetachLoading, mutate: putDetachPolicy } = usePutDetachPolicy({
    onSuccess: () => {
      dispatch({ type: "reset-error" });
      onSubmit();
      toastSuccess("Account detached successfully!");
    },
    onError: (error: AxiosError<any>) => {
      onSubmit(error);
      const skipToast = error?.response?.data?.message === "Policy must be active or pending in order to update.";
      reportError(error, undefined, undefined, { skipToast });
    },
  });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isDetachLoading) return;

    const cardElement = elements && elements.getElement("cardNumber");
    if (stripe && cardElement) {
      const { token, error } = await stripe.createToken(cardElement);
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          dispatch({ type: "error", payload: error.message });
        } else {
          reportStripeError(error);
        }
      }
      if (token && !error) {
        setDetachPolicyPayload({
          policyNumber,
          payload: {
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            TokenizedPaymentId: token.id,
          },
        });
        // TODO - asserting to any to fix issue with typescript inference
        postDetachPolicyPreview({
          policyNumber,
          payload: {
            FirstName: firstName,
            LastName: lastName,
            Email: email,
          },
        } as any);
      }
    } else {
      reportStripeError("Stripe Failed To initialize", "An issue has occurred");
    }
  };

  const handlePreviewConfirm = () => {
    resetDetachPreview();
    putDetachPolicy(detachPolicyPayload as any ); //DetachRequestData); TODO - temp fix for typescript inference issue
  };

  const isDisabled = isDetachLoading || !firstName || !lastName || !email;

  if (isPreviewLoading || isDetachLoading) {
    return (
      <LoadingContainer>
        <span data-testid="loading-spinner" />
        <AjaxLoader />
      </LoadingContainer>
    );
  }

  return previewData && isPreviewSuccess ? (
    <DetachPreview preview={previewData as any} onConfirm={handlePreviewConfirm} />
  ) : (
    <>
      <StyledModalBody>
        <form id="detach-form" onSubmit={handleSubmit} data-testid="stripe_form">
          <Label htmlFor="first_name">First Name</Label>
          <TextInput
            id="first_name"
            disabled={false}
            placeholder="Jon"
            type="text"
            value={firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
          />
          <Label htmlFor="last_name">Last Name</Label>
          <TextInput
            id="last_name"
            disabled={false}
            placeholder="Doe"
            type="text"
            value={lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
          />
          <Label htmlFor="email">New Email</Label>
          <TextInput
            id="email"
            disabled={false}
            placeholder="jondoe@email.com"
            type="text"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          />
          <Label htmlFor="card_number">Credit Card Number</Label>
          <CardInputWrapper hasFocus={focusedInput === "focus_cc_number"}>
            <CardNumberElement
              id="card_number"
              onFocus={() => dispatch({ type: "focus_cc_number" })}
              onBlur={() => dispatch({ type: "blur_input" })}
              options={options}
            />
          </CardInputWrapper>
          <Row>
            <Column>
              <Label htmlFor="card_expiration">Expiration</Label>
              <CardInputWrapper hasFocus={focusedInput === "focus_expiration"}>
                <CardExpiryElement
                  id="card_expiration"
                  onFocus={() => dispatch({ type: "focus_expiration" })}
                  onBlur={() => dispatch({ type: "blur_input" })}
                  options={options}
                />
              </CardInputWrapper>
            </Column>
            <Column>
              <Label htmlFor="card_cvc">CVC</Label>
              <CardInputWrapper hasFocus={focusedInput === "focus_cvc"}>
                <CardCvcElement
                  id="card_cvc"
                  onFocus={() => dispatch({ type: "focus_cvc" })}
                  onBlur={() => dispatch({ type: "blur_input" })}
                  options={options}
                />
              </CardInputWrapper>
            </Column>
          </Row>
          <ErrorText>{error}</ErrorText>
        </form>
      </StyledModalBody>
      <ModalActionBar ctaText="Detach" ctaDisabled={isDisabled} type="submit" formId="detach-form" />
    </>
  );
};

export const CardInputWrapper = styled.div<{ hasFocus: boolean }>`
  height: 48px;
  background-color: ${(props) => props.theme.white};
  border: 1px solid ${(props) => (props.hasFocus ? props.theme.blue : props.theme.lighterGray)};
  padding: 10px 14px;
  border-radius: 4px;
  margin-bottom: 32px;
`;

export { StripeCreditCardComponent };

const CreditCardStripe = (props: CreditCardProps) => {
  if (!process.env.REACT_APP_STRIPE_KEY) {
    return null;
  }
  const publishableKey = process.env.REACT_APP_STRIPE_KEY;
  const stripePromise = loadStripe(publishableKey);
  return (
    <Elements options={stripeOptionsObject} stripe={stripePromise}>
      <StripeCreditCardComponent {...props} />
    </Elements>
  );
};

export default CreditCardStripe;

const TextInput = styled(StyledInput)`
  margin-bottom: 16px;
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 250px;
  width: 600px;
  align-items: center;
  justify-content: center;
`;
