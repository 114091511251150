import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle } from "reactstrap";
import styled from "styled-components";
import { selectApplicationFormPreviousLosses } from "redux/selectors/application";
import Field from "shared/Field";

interface PropTypes {
  previousLosses: PreviousLoss[];
}

const PreviousLosses = ({ previousLosses }: PropTypes) => {
  return (
    <>
      {previousLosses && previousLosses.length > 0 && (
        <Card>
          <StyledCardTitle>Past Claims</StyledCardTitle>
          <CardBody>
            {previousLosses.map((loss) => (
              <>
                <Field value={loss.amount} label="Amount" slug="LossAmount" />
                <Field value={loss.description} label="Description" slug="LossDescription" />
              </>
            ))}
          </CardBody>
        </Card>
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  previousLosses: selectApplicationFormPreviousLosses(state),
});

const StyledCardTitle = styled(CardTitle)`
  padding-top: 25px;
`;

export default connect(mapStateToProps)(PreviousLosses);
