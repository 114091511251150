import { useQuery } from "@tanstack/react-query";
import { AccountSearchQueryType, AccountSearchOrderByType, SortDirection } from "types/enums";
import { portalApi } from "./useAPI/instances";
import useAuth from "./useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface GetAccountPolicies {
  queryType?: AccountSearchQueryType;
  searchQuery?: string | null;
  orderBy: AccountSearchOrderByType;
  sortDirection: SortDirection;
  pageNumber: number;
  onError?: (response: any) => void;
}

const useGetAccountSearch = ({ queryType, searchQuery, orderBy, sortDirection, pageNumber }: GetAccountPolicies) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const getAccountSearch = async () => {
    const token = await getToken();
    const portalAPI = portalApi(apiVersion, token);
    const orderByAscending = sortDirection === SortDirection.Ascending;

    return portalAPI.get(
      `accounts/policyHolders?PageSize=20&PageNumber=${pageNumber}&orderBy=${orderBy}&orderByAscending=${orderByAscending}&${queryType}=${searchQuery}`
    );
  };

  return useQuery({
    queryKey: ["get_account_search", searchQuery, queryType, orderBy, pageNumber, sortDirection],
    queryFn: getAccountSearch,
    enabled: Boolean(searchQuery && queryType),
    select: (data) => data.data,
    retry: false,
  });
};

export default useGetAccountSearch;
