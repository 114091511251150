import React, { useState } from "react";
import Modal from "shared/Modal";
import { ModalContentContainer } from "shared/ModalComponents";
import { Input } from "styles";

interface PropTypes {
  isLoading: boolean;
  closeModal: () => void;
  modalIsOpen: boolean;
  save: (name: string, email: string, npn: string) => void;
}

const AddAgencyModal = ({ isLoading, closeModal, modalIsOpen, save }: PropTypes) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [npn, setNpn] = useState("");

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    let { name, value } = event.currentTarget;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      default:
        return;
    }
  };

  const handleNumberInputChange = ({ value }: ValuesType) => {
    setNpn(value);
  };

  const closeAndClear = () => {
    setName("");
    setEmail("");
    setNpn("");
    closeModal();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    save(name, email, npn);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      closeModal={closeAndClear}
      label="add-agency"
      title="Add Agency"
      onSubmit={handleSubmit}
      loading={isLoading}
      disabled={!Boolean(name && email) || isLoading}
    >
      <ModalContentContainer>
        <Input
          label="Agency Name"
          name="name"
          type="text"
          value={name}
          handleChange={handleInputChange}
          placeholder="Enter agency's name"
          required
          inputStyles={{ marginBottom: "16px" }}
        />
        <Input
          label="Email Address"
          name="email"
          type="email"
          value={email}
          handleChange={handleInputChange}
          placeholder="Enter agency's email"
          required
          inputStyles={{ marginBottom: "16px" }}
        />
        <Input
          label="National Producer Number"
          name="npn"
          type="number"
          maxLength={10}
          value={npn}
          handleChange={handleNumberInputChange}
          placeholder="Enter agency's NPN"
          required
          inputStyles={{ marginBottom: "16px" }}
        />
      </ModalContentContainer>
    </Modal>
  );
};

export default AddAgencyModal;
