import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { PanelContainer } from "../StyledComponents";
import { Switch, Label, TextLink } from "styles";
import { updateApplicationForm } from "redux/reducers/_applicationReducer";
import Build from "images/build.svg";
import School from "images/school.svg";
import LiveTV from "images/live-tv.svg";

interface PropTypes {
  lossControlIsOptIn: boolean;
  lossControlIsNotSet: boolean;
  isSnapshot?: boolean;
  hideSwitchButton?: boolean;
  updateLossControlSnapshot?: (arg0: any) => void;
  updateApplicationForm: (arg0: Application) => void;
}

const LossControl = ({
  lossControlIsOptIn,
  isSnapshot = false,
  hideSwitchButton = false,
  updateLossControlSnapshot = () => {},
  updateApplicationForm,
}: PropTypes) => {
  const handleCheckboxOnChange = () => {
    const payload = lossControlIsOptIn ? "OptOut" : "OptIn";
    isSnapshot ? updateLossControlSnapshot({ lossControl: payload }) : updateApplicationForm({ lossControl: payload });
  };

  return (
    <PanelContainer>
      {!hideSwitchButton && (
        <SwitchWrapper
          data-pendotag={`${isSnapshot ? "snapshot" : "quote-edit"}-loss-control-${
            lossControlIsOptIn ? "remove" : "add"
          }-button`}
        >
          <Switch
            ariaLabel={`${lossControlIsOptIn ? "remove" : "add"} manage my risk program`}
            dataTestId="manage-my-risk-program-checkbox"
            checked={lossControlIsOptIn}
            onChange={handleCheckboxOnChange}
          />
          <StyledLabel htmlFor="manage-my-risk-program-checkbox">
            {lossControlIsOptIn ? "Enrolled" : "Enroll"} in Coterie’s Manage My Risk Program
            {!lossControlIsOptIn && " now!"}
          </StyledLabel>
        </SwitchWrapper>
      )}
      <Body>
        <TaglineContainer>
          <Tagline>
            Sign {!isSnapshot && "your client "}up for our self-guided and self-directed loss-control services. While
            there are no program requirements at this time, we recommend{" "}
            {isSnapshot ? "using" : "encouraging your clients to use"} the program to secure{" "}
            {isSnapshot ? "your" : "their"} business. {!isSnapshot && "Learn more about our "}
            {!isSnapshot && (
              <AgentTextLink
                as="a"
                href="https://explore.coterieinsurance.com/manage-my-risk-agentsbrokers"
                target="_blank"
                rel="noopener noreferrer"
              >
                Manage My Risk Program.
              </AgentTextLink>
            )}
          </Tagline>
        </TaglineContainer>
        <ListItem>
          <Img src={Build} alt="build" />
          <ListItemText>
            <ListItemTextBold>Safety Equipment Marketplace: </ListItemTextBold>Access to industry-specific safety
            equipment to secure your {!isSnapshot && "client’s "}business and prevent losses.
          </ListItemText>
        </ListItem>
        <ListItem>
          <Img src={School} alt="school" />
          <ListItemText>
            <ListItemTextBold>Industry-Specific Training Resources: </ListItemTextBold>Specific training for your{" "}
            {!isSnapshot && "client’s "}industry to {!isSnapshot && "help them "}understand{" "}
            {isSnapshot ? "your" : "their"} risk and secure {isSnapshot ? "your business" : "their businesses"}.
          </ListItemText>
        </ListItem>
        <ListItem>
          <Img src={LiveTV} alt="live TV" />
          <ListItemText>
            <ListItemTextBold>Curated Content: </ListItemTextBold>Targeted content around loss prevention and risk
            mitigation so {isSnapshot ? "you" : "your clients"} can learn how to protect {isSnapshot ? "your" : "their"}{" "}
            business.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            {!isSnapshot && <ListItemTextBold>What’s next: </ListItemTextBold>}Once enrolled, we’ll email{" "}
            {isSnapshot ? "you" : "your client"} login information about how to access our program within 24-48 hours{" "}
            {isSnapshot && " of purchasing your policy"}.{" "}
            <PolicyHolderTextLink
              as="a"
              href="https://explore.coterieinsurance.com/manage-my-risk-policyholders"
              target="_blank"
              rel="noopener noreferrer"
            >
              Program details for small business owners.
            </PolicyHolderTextLink>
          </ListItemText>
        </ListItem>
      </Body>
    </PanelContainer>
  );
};

const mapDispatchToProps = {
  updateApplicationForm,
};

const SwitchWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const StyledLabel = styled(Label)`
  margin: 2px 0px 0 16px;
  &:hover {
    cursor: pointer;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-top: 16px;
  color: ${(props) => props.theme.fontColors?.primary};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
  div:last-child {
    margin-top: 8px;
  }
`;

const TaglineContainer = styled.div`
  margin-bottom: 12px;
`;

const Tagline = styled.p`
  margin-bottom: 0;
`;

const AgentTextLink = styled(TextLink)`
  font-size: 16px;
`;

const PolicyHolderTextLink = styled(TextLink)`
  color: inherit;
  font-weight: 400;
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
  text-decoration-line: underline;
  :hover {
    color: ${({ theme }) => theme.blue};
    border-bottom: none;
    text-decoration-line: underline;
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
`;

const Img = styled.img`
  margin-top: 4px;
`;

const ListItemText = styled.p`
  margin-bottom: 0;
`;

const ListItemTextBold = styled.span`
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  font-weight: 600;
`;

export default connect(null, mapDispatchToProps)(LossControl);
