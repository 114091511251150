import React from "react";
import { SearchIconWrapper } from "styles/containerStyledComponents";
import AjaxLoader from "shared/AjaxLoader";
import styled, { CSSObject } from "styled-components";
import { OptionType, dropdownStyles } from "styles/Dropdown";
import { Dropdown } from "styles";
import { theme } from "styles";
import { InputActionMeta } from "react-select";
import { ReactComponent as SearchIcon } from "images/search_icon.svg";

interface VariableSearchProps {
  options: OptionType[];
  onChange: (value: string) => void;
  onInputChange: (value: string) => void;
  loading: boolean;
  onClear: () => void;
}

const VariableSearchBox = ({ options, onChange, onInputChange, loading, onClear }: VariableSearchProps) => {
  return (
    <SearchContainer>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <Dropdown
        options={options ?? []}
        onChange={(option) => {
          if (option) {
            onChange(option.value as string);
          } else {
            onClear();
          }
        }}
        onInputChange={(value: string, changeEvent?: InputActionMeta) => {
          if (changeEvent?.action === "input-change") {
            onInputChange(value);
          }
        }}
        placeholder="Search Variables"
        label="Search Variables"
        labelIsHidden
        customStyles={SearchDropdownStyles}
        isClearable
        full
      />
      {loading ? (
        <LoaderContainer>
          <AjaxLoader width={32} />
        </LoaderContainer>
      ) : null}
    </SearchContainer>
  );
};

const SearchDropdownStyles = {
  ...dropdownStyles,
  container: (styles: CSSObject) => {
    return {
      ...styles,
      margin: 0,
      width: "100%",
    };
  },
  control: (styles: CSSObject) => {
    return {
      ...styles,
      padding: "0 0 0 1.5em",
      border: "none",
      borderBottom: `solid 1px ${theme.blue}`,
      boxShadow: "none",
      background: "transparent",
      borderRadius: "none",

      ":hover": {
        ...styles[":hover"],
        border: "none",
        borderBottom: `solid 1px ${theme.blue}`,
      },
    };
  },
  indicatorsContainer: () => {
    return {
      display: "none",
    };
  },
  dropdownIndicator: () => {
    return {
      display: "none",
    };
  },
};

const LoaderContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 0;
`;

const SearchContainer = styled.div`
  width: 400px;
  margin-right: 50px;
  position: relative;
`;

export default VariableSearchBox;
