import { toastError } from "utils/toast";

export const IMAGE_ACTION = {
  RESET_STATE: "RESET_STATE",
  SET_PREVIEW: "SET_PREVIEW",
  SET_NAME: "SET_NAME",
  SET_SIZE: "SET_SIZE",
  SET_EXT_ERROR: "SET_EXT_ERROR",
  SET_SIZE_ERROR: "SET_SIZE_ERROR",
  SET_READ_ERROR: "SET_READ_ERROR",
  SET_UPLOAD_ERROR: "SET_UPLOAD_ERROR",
};

export type ImageStateType = {
  previewSrc: string | null;
  fileName: string | null;
  fileSize: number | null;
  errorMsg: string | null;
};

type ImageActionType = {
  type: string;
  payload?: any;
};

export const imageDefaultState: ImageStateType = {
  previewSrc: null,
  fileName: null,
  fileSize: null,
  errorMsg: null,
};

export const IMAGE_UPLOAD_SUCCESS_MESSAGE = "Success! Image upload complete.";

export const IMAGE_UPLOAD_ERROR_MESSAGES = {
  EXT_ERROR: "File type not supported",
  SIZE_ERROR: "File size must be less than 5MB",
  READ_ERROR: "There was an issue while reading the image",
  UPLOAD_ERROR: "There was an issue with uploading the image",
};

export const imageReducer = (state: ImageStateType, action: ImageActionType): ImageStateType => {
  switch (action.type) {
    case IMAGE_ACTION.RESET_STATE:
      return imageDefaultState;
    case IMAGE_ACTION.SET_PREVIEW:
      return {
        ...state,
        previewSrc: action.payload || "",
        errorMsg: null,
      };
    case IMAGE_ACTION.SET_NAME:
      return {
        ...state,
        fileName: action.payload || "",
        errorMsg: null,
      };
    case IMAGE_ACTION.SET_SIZE:
      return {
        ...state,
        fileSize: action.payload || null,
        errorMsg: null,
      };
    case IMAGE_ACTION.SET_EXT_ERROR:
      toastError(IMAGE_UPLOAD_ERROR_MESSAGES.EXT_ERROR);
      return {
        ...state,
        previewSrc: null,
        errorMsg: IMAGE_UPLOAD_ERROR_MESSAGES.EXT_ERROR,
      };
    case IMAGE_ACTION.SET_SIZE_ERROR:
      toastError(IMAGE_UPLOAD_ERROR_MESSAGES.SIZE_ERROR);
      return {
        ...state,
        previewSrc: null,
        errorMsg: IMAGE_UPLOAD_ERROR_MESSAGES.SIZE_ERROR,
      };
    case IMAGE_ACTION.SET_READ_ERROR:
      toastError(IMAGE_UPLOAD_ERROR_MESSAGES.READ_ERROR);
      return {
        ...state,
        previewSrc: null,
        errorMsg: IMAGE_UPLOAD_ERROR_MESSAGES.READ_ERROR,
      };
    case IMAGE_ACTION.SET_UPLOAD_ERROR:
      toastError(IMAGE_UPLOAD_ERROR_MESSAGES.UPLOAD_ERROR);
      return {
        ...state,
        previewSrc: null,
        errorMsg: IMAGE_UPLOAD_ERROR_MESSAGES.UPLOAD_ERROR,
      };
    default:
      return state;
  }
};
