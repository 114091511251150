import React, { useState } from "react";
import Modal from "shared/Modal";
import { ModalContentContainer } from "shared/ModalComponents";
import { Input } from "styles";

interface PropTypes {
  modalIsOpen: boolean;
  closeModal: () => void;
  renewPolicy: (annualPayroll: number, grossAnnualSales: number) => void;
  isRenewing: boolean;
  policy: Policy;
}

const RenewModal = ({ policy, modalIsOpen, closeModal, renewPolicy, isRenewing }: PropTypes) => {
  const [annualPayroll, setAnnualPayroll] = useState<number>(policy.annualPayroll);
  const [grossAnnualSales, setGrossAnnualSales] = useState<number>(policy.grossAnnualSales);

  const onPayrollChanged = (values: ValuesType) => {
    setAnnualPayroll(values.floatValue);
  };

  const onSalesChanged = (values: ValuesType) => {
    setGrossAnnualSales(values.floatValue);
  };

  const submitRenew = () => {
    renewPolicy(annualPayroll, grossAnnualSales);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      closeModal={closeModal}
      label="renew-policy"
      title="Renew Policy"
      buttonText="Renew Policy"
      onSubmit={submitRenew}
      loading={isRenewing}
    >
      <ModalContentContainer>
        <p>Renewal Requirements:</p>
        <ul>
          <li>must be a yearly policy (length equal or greater than 365 days)</li>
          <li>can only renew within 7 days of expiration</li>
        </ul>
        <Input
          label="Annual Payroll"
          value={annualPayroll}
          handleChange={onPayrollChanged}
          name="annualPayroll"
          datacy="annual-payroll"
          prefix="$"
          placeholder="Enter dollar amount"
        />
        <Input
          label="Gross Annual Sales"
          value={grossAnnualSales}
          handleChange={onSalesChanged}
          name="grossAnnualSales"
          datacy="gross-annual-sales"
          prefix="$"
          placeholder="Enter dollar amount"
        />
      </ModalContentContainer>
    </Modal>
  );
};

export default RenewModal;
