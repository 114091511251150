import React from "react";
import styled from "styled-components";
import ToolTipIcon from "shared/ToolTipIcon";

interface PropTypes {
  rows: DetailRow[];
}

const MoreDetailsTable = ({ rows }: PropTypes) => {
  return (
    <MoreDetails>
      <Table>
        <thead>
          <tr>
            <th>COVERAGE</th>
            {rows.some((r) => r.limit) && <th>LIMIT</th>}
            {rows.some((r) => r.deductible) && <th>DEDUCTIBLE</th>}
          </tr>
        </thead>
        <tbody>
          {rows.map(({ coverage, toolTipContentKey, limit, deductible }) => (
            <tr key={coverage}>
              <td className="coverage">
                {coverage}
                {toolTipContentKey && <ToolTipIcon contentKey={toolTipContentKey} />}
              </td>
              <td className="limit">{limit}</td>
              {deductible && <td>{deductible}</td>}
            </tr>
          ))}
        </tbody>
      </Table>
    </MoreDetails>
  );
};

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  position: relative;

  tbody tr td {
    border-bottom: 1px solid ${(props) => props.theme.lighterGray};
    font-size: 13px;
    color: ${(props) => props.theme.fontColors?.primary};
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
  }

  th {
    padding: 5px 15px;
    border: none;
    font-size: 11px;
    position: sticky;
    top: 0;
    background: ${(props) => props.theme.bgDarkBlue};
    border-style: ${(props) => props.theme.bgDarkBlue};
    color: ${(props) => props.theme.white};
    text-align: left;
    font: ${(props) => props.theme.primaryFont};
  }

  td {
    padding: 10px 15px;
    border: none;
    font-size: 13px;
    color: ${(props) => props.theme.fontColors?.primary};
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
  }

  td.limit {
    max-width: 157px;
  }

  td.coverage {
    max-width: 321px;
  }
`;

const MoreDetails = styled.div`
  overflow: scroll;
  max-height: 426px;
  height: 100%;
`;

export default MoreDetailsTable;
