import { Page as PDFPage, Image, StyleSheet } from "@react-pdf/renderer";
import { PageProps as PDFPageProps, ViewNode, TextNode, Style } from "@react-pdf/types";
import Footer from "./Footer";
import template from "images/template.jpg";
import { theme } from "styles";

interface PageProps extends PDFPageProps {
  children?: ViewNode | TextNode | Element | (ViewNode | TextNode | Element | boolean)[];
  style?: Style;
}

const QuoteProposalPDFPage: any = PDFPage;

const Page = (props: PageProps) => {
  return (
    <QuoteProposalPDFPage size="LETTER" {...props} style={{ ...styles.pageStyles, ...props?.style }}>
      <Image
        src={template}
        style={{
          position: "absolute",
          width: 612,
          height: 792,
          top: 0,
          left: 0,
        }}
        fixed
      />
      {props.children}
      <Footer />
    </QuoteProposalPDFPage>
  );
};

export default Page;

const styles = StyleSheet.create({
  pageStyles: {
    fontFamily: theme.font.typeface.primary,
    padding: "40px 34px 60px 34px",
  },
});
