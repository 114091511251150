import styled from "styled-components";
import { Header3, GreenButton, laptop } from "styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StyledHeader3 = styled(Header3)`
  color: ${(props) => props.theme.styledHeader3};
  text-align: center;
  margin-bottom: 8px;
  padding: 0 1em;
  font-size: 24px;
`;

export const HeaderTextContainer = styled.div`
  border-bottom: ${(props) => `1px solid ${props.theme.connector}`};
  padding-bottom: 2em;
  margin-top: 80px;
  width: 95%;
`;

export const PageHeaderSubTextContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PageHeaderSubText = styled.p`
  color: ${(props) => props.theme.gray};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 18px;
  text-align: center;
  margin: 0;
`;

export const SectionHeaderText = styled.p<{ leftAlign?: boolean }>`
  color: ${(props) => props.theme.textNavyBlue};
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  text-align: ${(props) => (props.leftAlign ? "left" : "center")};
  line-height: 30px;
  margin-bottom: 24px;
`;

export const PageHeaderContainer = styled.div`
  min-height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  ${laptop} {
    min-height: 200px;
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 10px;
`;

export const CardBar = styled.div`
  display: flex;
  cursor: pointer;
  background-color: ${(props) => props.theme.offWhite};
  padding: 16px 32px;
  border-radius: 4px;
  align-items: center;
`;

export const CardTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  flex-grow: 1;
`;

export const CardSubtitle = styled.div<{ warningStyle?: boolean }>`
  display: flex;
  gap: 4px;
  margin-right: 16px;
  padding: 5.5px 8px;
  align-items: center;
  background: ${(props) => (props.warningStyle ? props.theme.orange25 : props.theme.blue10)};
  color: ${(props) => (props.warningStyle ? props.theme.darkOrange : props.theme.blueSavingsTextColor)};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
`;

export const CardContent = styled.div`
  border-radius: 4px;
  padding: 20px 32px 32px 32px;
  background-color: ${(props) => props.theme.white};
`;

export const GhostButton = styled(GreenButton)`
  background: transparent;
  border: 1px solid ${(props) => props.theme.regularGray};
  color: ${(props) => props.theme.buttonTextSecondary};
  box-shadow: none;
`;

export const CTAButtonContainer = styled.div`
  z-index: 12;
  height: 80px;
  bottom: 0;
  position: fixed;
  background: ${(props) => props.theme.offWhite};
  justify-content: center;
  align-items: center;
  display: flex;
  width: calc(100% - 240px);
  max-width: 1440px;
  column-gap: 32px;
  button {
    height: fit-content;
  }
`;

export const RedDot = styled.span`
  position: relative;
  :after {
    content: "";
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.red};
    position: absolute;
    border-radius: 50%;
  }
`;

export const PremiumAmountText = styled(Header3)<{ isLoading?: boolean; minWidth?: number }>`
  color: ${(props) => props.theme.blue};
  background: ${(props) => props.theme.colors.neutral.white};
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  padding: ${(props) => (props.isLoading ? "8px 0" : "0 0 8px")};
  margin: 0 0 4px;
  flex-grow: 1;
  ${(props) => props.minWidth && `min-width: ${props.minWidth}px`};
`;
