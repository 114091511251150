import React, { useState } from "react";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRadiationAlt } from "@fortawesome/free-solid-svg-icons/faRadiationAlt";
import PolicyEnder from "./PolicyEnder";
import AccountCharger from "./AccountCharger";
import UnderwritingRules from "./UnderwritingRules/RuleBuilder/UnderwritingRules";
import AppetiteChangesUploader from "./AppetiteChangesUploader";
import AppetiteToggler from "./AppetiteToggler";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import RuleSearch from "./UnderwritingRules/RuleSearch/RuleSearch";
import RuleBuilderProvider from "./UnderwritingRules/RuleBuilder/RuleBuilderContext";
import RuleSearchProvider from "./UnderwritingRules/RuleSearch/RuleSearchContext";
import useGetUnderwritingRuleList from "hooks/underwritingRules/useGetUnderwritingRuleList";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const DeveloperTools = () => {
  const { showSleuth } = useFeatureFlags();

  const [activeTab, setActiveTab] = useState("1");
  const [ruleSearchRenderKey, setRuleSearchRenderKey] = useState(0);

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { data: rules, isLoading: isLoadingRuleList } = useGetUnderwritingRuleList();

  const renderNavItem = (tabId: string, title: string) => (
    <NavItem>
      <NavLink
        className={activeTab === tabId ? "active" : ""}
        onClick={() => {
          toggle(tabId);
          if (tabId === "4") {
            setRuleSearchRenderKey(ruleSearchRenderKey + 1);
          }
        }}
      >
        {title}
      </NavLink>
    </NavItem>
  );

  return (
    <TestUtilitiesContainer>
      <DeveloperToolsTitle>
        <div>
          <FontAwesomeIcon icon={faRadiationAlt as IconProp} /> DEVELOPER TOOLS
          <FontAwesomeIcon icon={faRadiationAlt as IconProp} />
        </div>
        Developer tools should be used with extreme caution !!!
      </DeveloperToolsTitle>
      <Nav tabs>
        {renderNavItem("1", "End Policy")}
        {renderNavItem("2", "Charge Account")}
        {showSleuth && renderNavItem("3", "Rule Builder")}
        {showSleuth && renderNavItem("4", "Rule Search")}
        {showSleuth && renderNavItem("5", "Appetite Controller")}
        {renderNavItem("6", "Appetite Changes Upload")}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <PolicyEnder />
        </TabPane>
        <TabPane tabId="2">
          <AccountCharger />
        </TabPane>
        {showSleuth && (
          <>
            <RuleBuilderProvider>
              <TabPane tabId="3">
                <UnderwritingRules />
              </TabPane>
              <TabPane tabId="4" key={ruleSearchRenderKey}>
                <RuleSearchProvider>
                  <RuleSearch
                    goToRuleBuilder={(rule) => {
                      setActiveTab("3");
                    }}
                    rules={rules}
                    loadingRules={isLoadingRuleList}
                  />
                </RuleSearchProvider>
              </TabPane>
            </RuleBuilderProvider>
            <TabPane tabId="5">
              <AppetiteToggler />
            </TabPane>
          </>
        )}

        <TabPane tabId="6">
          <AppetiteChangesUploader />
        </TabPane>
      </TabContent>
    </TestUtilitiesContainer>
  );
};

const TestUtilitiesContainer = styled.div`
  width: 100%;
  a.nav-link {
    color: #999;
  }
  a.nav-link.active {
    color: #000;
    font-weight: bold;
  }
  .nav-link:hover {
    cursor: pointer;
  }
  .nav {
    margin-top: 1em;
    margin-bottom: 1em;
  }
`;

const DeveloperToolsTitle = styled.div`
  text-align: center;
  background: #ebb;
  border: 1px solid #dcc;
  color: #600;
  padding: 0.5em;
  div:first-child {
    font-size: 2em;
    font-weight: bold;
  }
`;

export default DeveloperTools;
