import styled from "styled-components";
import { Paragraph, GreenButton } from "styles";

export const FailFastContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 20px;
`;

export const StatusMessage = styled(Paragraph)`
  text-align: left;
  line-height: 21px;
  width: 100%;
  padding-top: 9px;
`;

export const Card = styled.div`
  max-width: 822px;
  width: 100%;
  background: ${(props) => props.theme.white};
  padding: 32px;
  margin: 20px 0;
`;

export const GetQualifiedButton = styled(GreenButton)`
  height: 48px;
  margin-top: 24px;
`;

export const Container = styled.div`
  max-width: 504px;
  width: 100%;
`;
