import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Header4, GreenButton, tablet } from "styles";
import { SecondaryButton } from "coterie-ui-library";
import styled from "styled-components";
import Api from "utils/api";
// import SearchIcon from "images/search_icon.svg";
import { toastSuccess } from "utils/toast";
import AjaxLoader from "shared/AjaxLoader";
import Field from "shared/Field";
import { CardBody } from "reactstrap";
import { reportGetProducerError } from "utils/reportError";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import AddUserModal from "./AddUserModal";

interface PropTypes {
  agencyId?: number;
  userChannelPartnerId?: number;
  agencyChannelPartnerId?: number;
}

const ProducersTable = ({ agencyId, userChannelPartnerId, agencyChannelPartnerId }: PropTypes) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [producers, setProducers] = useState<AccountType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getToken } = useAuth();

  const { showAddAgencyUser, apiVersion } = useFeatureFlags();

  useEffect(() => {
    loadProducers();
  }, []);

  const loadProducers = async () => {
    setIsLoading(true);

    const token = await getToken();
    const api = new Api(apiVersion, token);

    if (agencyId) {
      api.producers
        .get(agencyId, true)
        .then((producers) => setProducers(producers))
        .catch((error) => reportGetProducerError(error))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const addProducer = () => {
    toastSuccess(
      "User successfully added! Allow a few minutes for their name to appear. They will receive an email with login details."
    );
    loadProducers();
  };

  const producerList = producers.map((producer) => (
    <Producer key={producer.id} data-cy="producer">
      <StyledCardBody>
        <Field value={`${producer.firstName} ${producer.lastName}`} label="Name" />
        <Field value={producer.email} label="Email Address" />
      </StyledCardBody>
    </Producer>
  ));

  return (
    <Container>
      <Title>Producers</Title>
      <Actions>
        {showAddAgencyUser ? (
          <SecondaryButton as="a" onClick={() => setShowAddModal(true)}>
            +&nbsp;&nbsp;Add user
          </SecondaryButton>
        ) : (
          <GreenButton
            as="a"
            href={`https://${process.env.REACT_APP_DOMAIN}/add-a-producer`}
            target="_blank"
            data-cy="add-producer-button"
          >
            + Add producer
          </GreenButton>
        )}
        {/* TODO for use later when search is implemented */}
        {/* <SearchContainer>
          <SearchImage src={SearchIcon} alt="magnifying glass search icon" />
          <Action>Search</Action>
        </SearchContainer> */}
      </Actions>
      <LoadingDiv>{isLoading ? <AjaxLoader /> : <ProducerContainer>{producerList}</ProducerContainer>}</LoadingDiv>
      <AddUserModal closeModal={() => setShowAddModal(false)} isOpen={showAddModal} onAddUser={addProducer} />
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  agencyId: state.user.agency?.id,
  userChannelPartnerId: state.user.channelPartner?.id,
  agencyChannelPartnerId: state.user?.agency?.channelPartnerId,
});

const Container = styled.div`
  max-width: 1121px;
  width: 100%;
`;

// TODO for use later when search is implemented
// const SearchContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   border-left: 1px solid ${(props) => props.theme.lighterGray};
// `;

// const SearchImage = styled.img`
//   height: 12px;
//   margin-top: 10px;
//   margin-left: 12px;
// `;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled(Header4)`
  padding: 16px 0;
  margin-bottom: 0px;
  border-top: 1px solid ${(props) => props.theme.lighterGray};
`;

const ProducerContainer = styled.div`
  padding-top: 7px;
  max-width: 511px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 98.5px;
  grid-row-gap: 16px;
  max-width: 1121px;
  width: 100%;

  ${tablet} {
    display: flex;
    flex-direction: column;
  }
`;

const Producer = styled.div`
  max-width: 511px;
  width: 100%;
`;

const LoadingDiv = styled.div`
  width: 100%;
  margin-top: 16px;
  text-align: center;
`;

const StyledCardBody = styled(CardBody)`
  padding: 0;
`;

export default connect(mapStateToProps)(ProducersTable);
