import React from "react";
import { Card, CardTitle, CardBody, FormGroup, Input, InputGroup, InputGroupAddon } from "reactstrap";
import formatDate from "utils/formatDate";
import { formatBackendPolicyDate } from "utils/formatBackendPolicyDate";
import { PolicyStatus } from "types/enums";

interface PropTypes {
  policy: Policy;
}

export default class PolicyInfo extends React.Component<PropTypes> {
  render(): React.ReactNode {
    const policy = this.props.policy || {};

    const policyStatuses = {
      [PolicyStatus.Active]: "Active",
      [PolicyStatus.Cancelled]: "Cancelled",
      [PolicyStatus.Expired]: "Expired",
      [PolicyStatus.Pending]: "Pending",
      [PolicyStatus.PendingCancellation]: "Pending Cancellation",
    };

    const status = policyStatuses[policy.status as PolicyStatus] || "";

    return (
      <Card>
        <CardBody>
          <CardTitle>Policy Information</CardTitle>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon id="policyNumberLabel" addonType="prepend">
                Policy Number
              </InputGroupAddon>
              <Input aria-labelledby="policyNumberLabel" value={policy.policyNumber} disabled={true} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon id="statusLabel" addonType="prepend">
                Status
              </InputGroupAddon>
              <Input
                aria-labelledby="statusLabel"
                value={`${status} ${policy.cancelDate ? formatDate(new Date(policy.cancelDate)) : ""}`}
                disabled={true}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon id="autoExtendLabel" addonType="prepend">
                Auto Extend
              </InputGroupAddon>
              <Input aria-labelledby="autoExtendLabel" value={policy.autoExtend ? "On" : "Off"} disabled={true} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon id="startDateLabel" addonType="prepend">
                Start Date
              </InputGroupAddon>
              <Input
                aria-labelledby="startDateLabel"
                value={formatDate(formatBackendPolicyDate(new Date(policy.startDate)))}
                disabled={true}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon id="endDateLabel" addonType="prepend">
                End Date
              </InputGroupAddon>
              <Input
                aria-labelledby="endDateLabel"
                value={formatDate(formatBackendPolicyDate(new Date(policy.endDate)))}
                disabled={true}
              />
            </InputGroup>
          </FormGroup>
        </CardBody>
      </Card>
    );
  }
}
