import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  Card,
  CardSectionFullWidth,
  CardSectionSubtitle,
  CardSectionTitle,
  CardSectionText,
  Row,
  Column,
  CardSectionContainer,
  NoDetails,
} from "./tabContentStyles";
import endorsement_silver_icon from "images/endorsement_silver.svg";
import endorsement_gold_icon from "images/endorsement_gold.svg";
import endorsement_platinum_icon from "images/endorsement_platinum.svg";
import { EndorsementPackages } from "types/enums";
import {
  endorsementsMap,
  EndorsementObj,
  EndorsementsMap,
} from "shared/application/edit/Endorsements/AddOns/AddOnQuestionsMap";
import get from "lodash/get";
import { selectShowEndorsementPackages } from "redux/reducers/_availableEndorsements";
import { endorsementLimitValue } from "utils/endorsementLimitValue";

interface PropTypes {
  application: Application;
  channelPartnerId: string;
  setSelectedTab: any;
  showEndorsementPackages: boolean;
}

interface PackageObj {
  type: string;
  icon: any;
  title: string;
  copy: string;
  amount?: number;
}

const EndorsementsAndAddOns = ({ application, setSelectedTab, showEndorsementPackages }: PropTypes) => {
  const endorsement_packages: PackageObj[] = [
    {
      type: EndorsementPackages.Silver,
      icon: endorsement_silver_icon,
      title: "Silver Coverage",
      copy: "Provides the lowest limits and the least coverage for your business.",
    },
    {
      type: EndorsementPackages.Gold,
      icon: endorsement_gold_icon,
      title: "Gold Coverage",
      copy: "Provides moderate limits and moderate coverage for your business.",
    },
    {
      type: EndorsementPackages.Platinum,
      icon: endorsement_platinum_icon,
      title: "Platinum Coverage",
      copy: "Provides the highest limits and the most coverage for your business.",
    },
  ];

  const mappedEndorsements: EndorsementsMap = endorsementsMap();

  const endorsementPackageSelected = endorsement_packages.filter(
    (e) => e.type === application?.endorsements?.endorsementPackage
  );

  const hasAddon = Object.keys(mappedEndorsements).some((addOnKey) =>
    get(application, mappedEndorsements[addOnKey].selector)
  );
  const addOnsAddedInApplication = (addOnKey: string) => {
    if (get(application, mappedEndorsements[addOnKey].selector)) {
      return true;
    } else {
      return false;
    }
  };

  const displayAddOn = (addOnKey: string) => {
    const addOn: EndorsementObj = mappedEndorsements[addOnKey];

    const value = get(application, addOn.selector);
    return (
      <CardSectionContainer>
        <Inline>
          <CardSectionSubtitle>{addOn.LABEL}</CardSectionSubtitle>
          <Limit>{endorsementLimitValue(value, addOn.selector)}</Limit>
        </Inline>
        <CardSectionText>{addOn.COVERAGE_COPY}</CardSectionText>
      </CardSectionContainer>
    );
  };

  const addOnSection = Object.keys(mappedEndorsements).filter(addOnsAddedInApplication).map(displayAddOn);

  return (
    <Card>
      <CardSectionFullWidth>
        {showEndorsementPackages && (
          <>
            <CardSectionTitle>Endorsements Package</CardSectionTitle>
            {endorsementPackageSelected.length > 0 ? (
              endorsementPackageSelected.map((selectedPackage) => {
                return (
                  <Row>
                    <Icon src={selectedPackage.icon} alt="coverage icon" />
                    <Column>
                      <CardSectionSubtitle>{selectedPackage.title}</CardSectionSubtitle>
                      <CoverageText>{selectedPackage.copy}</CoverageText>
                      <PolicyDetailsLink onClick={() => setSelectedTab(4)}>
                        View and compare coverages
                      </PolicyDetailsLink>
                    </Column>
                  </Row>
                );
              })
            ) : (
              <NoDetails>No endorsements package selected.</NoDetails>
            )}
          </>
        )}
        <StyledCardSectionTitle>Policy Add Ons</StyledCardSectionTitle>
        {!hasAddon && <NoDetails>No policy add ons selected.</NoDetails>}
        {addOnSection}
      </CardSectionFullWidth>
    </Card>
  );
};

const StyledCardSectionTitle = styled(CardSectionTitle)`
  padding-top: 38px;
`;

const CoverageText = styled.p`
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 0;
`;

const Icon = styled.img`
  padding-right: 16px;
  margin-top: -40px;
`;

const PolicyDetailsLink = styled.button`
  background: none;
  text-align: left;
  font-weight: 600;
  padding: 0 0 18px 0;
  margin-top: 8px;
`;

const Inline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Limit = styled(CardSectionText)`
  margin: 5px 0 0 0;
`;

const mapStateToProps = (state: ReduxState) => ({
  showEndorsementPackages: selectShowEndorsementPackages(state),
});

export default connect(mapStateToProps)(EndorsementsAndAddOns);
