import { createGlobalStyle } from "styled-components";
import { theme } from "styles";

const GlobalStyle = createGlobalStyle`
#root,
.App {
  height: 100%;
}

body {
  font-family: Gilroy;
}

a {
  color: ${theme.blue};
}

a:hover {
  color: ${theme.textDarkBlue};
  text-decoration: none;
}

svg.social-icon:hover {
  fill: ${theme.blue};
}

input:focus {
  outline: none;
}

button {
  color: ${theme.blue};
  border: none;
  background-color: ${theme.offWhite};
}

button:hover {
  text-decoration: none;
}

button:focus,
a:focus,
li:focus,
div:focus,
h4:focus,
p:focus,
tr:focus {
  outline-color: ${theme.blue};
  outline-style: dotted;
  outline-width: thin;
  z-index: 3000;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 100 !important;
}

.MoreDetails {
  background: ${theme.white};
  border: 1px solid rgb(204, 204, 204);
  outline: currentColor none medium;
  overflow: scroll;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  width: 70%;
  max-width: 768px;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 768px) {
  .MoreDetails {
    width: 100%;
    height: 100%;
  }
}

.react-date-picker__wrapper {
  border: solid 1px ${theme.inputBorder} !important;
}

.react-date-picker__inputGroup {
  padding: 0 8px !important;
}

ol.breadcrumb .active.breadcrumb-item {
  color: #0D0D46 !important;
}
`;

export default GlobalStyle;
