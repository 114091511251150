import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { theme } from "styles";

interface AdditionalInsuredsPropTypes {
  application: Application;
  hasBlanketAIs: boolean;
  hasIndividualAIs?: number;
  blanketTitles: string[];
}

const AdditionalInsureds = ({
  application,
  hasBlanketAIs,
  hasIndividualAIs,
  blanketTitles,
}: AdditionalInsuredsPropTypes) => {
  if (!hasBlanketAIs && !hasIndividualAIs) return null;
  return (
    <View>
      <Text style={styles.pageHeaderTitle}>Additional Insureds</Text>

      {hasBlanketAIs && (
        <View style={styles.section}>
          <Text style={styles.subHeaderText}>Blanket Additional Insured Bundle</Text>
          {blanketTitles.map((title) => (
            <Text key={title} style={styles.text}>
              {title}
            </Text>
          ))}
        </View>
      )}

      {hasIndividualAIs && (
        <View style={styles.section}>
          <Text style={styles.subHeaderText}>Individual Additional Insured</Text>
          <Text style={styles.infoText}>
            {`Provides additional insured status for ongoing operations and premises liability for the following
            scheduled person or organization:`}
          </Text>
          {application.endorsements?.additionalInsureds?.map((insured) => (
            <Text style={styles.text}>{insured.name}</Text>
          ))}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  pageHeaderTitle: {
    marginBottom: 16,
    fontSize: "18px",
    color: theme.colors.tertiary.zero,
  },
  section: {
    marginBottom: 12,
  },
  subHeaderText: {
    fontSize: "10px",
    color: theme.black,
    borderBottom: `1px solid ${theme.colors.neutral.light}`,
    paddingBottom: 6,
    marginBottom: 6,
  },
  text: {
    fontSize: "9px",
    color: theme.black,
    fontFamily: theme.font.typeface.secondary,
    marginBottom: 4,
  },
  infoText: {
    fontSize: "9px",
    color: theme.black,
    fontFamily: theme.font.typeface.secondary,
    marginBottom: 10,
  },
});

export default AdditionalInsureds;
