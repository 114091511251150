import React, { useState } from "react";
import { Form } from "reactstrap";
import { Input } from "styles";
import Api from "utils/api";
import { NextButton, Description, Row } from "./StyledComponents";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  newAccount: AccountValues;
  setAccountCreated: (value: boolean) => void;
  setError: (error: any) => void;
}

const BasicInfo = ({ newAccount, setAccountCreated, setError }: PropTypes) => {
  const { getToken } = useAuth();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [doesUserAlreadyExists, setDoesUserAlreadyExists] = useState(false);
  const [confirmPasswordHasError, setConfirmPasswordHasError] = useState(false);

  const { apiVersion } = useFeatureFlags();

  const passwordPattern = "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}";
  const passwordTitle =
    "Must contain at least one number, one uppercase letter, one lowercase letter, and at least 8 or more characters.";

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    let { name, value } = event.currentTarget;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      case "companyName":
        setCompanyName(value);
        break;
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      default:
        return;
    }
  };

  const onSignupClick = async (accountValues: AccountValues) => {
    setCreatingAccount(true);

    const token = await getToken();
    const api = new Api(apiVersion, token);

    api
      .createAccountDashboard({
        accountType: accountValues.accountType!,
        agencyId: accountValues.agencyId,
        channelPartnerId: accountValues.channelPartnerId!,
        email,
        password,
        firstName: accountValues.firstName,
        lastName: accountValues.lastName,
        name: companyName,
        nationalProducerNumber: accountValues.nationalProducerNumber!,
      })
      .then(() => {
        setAccountCreated(true);
      })
      .catch((error) => {
        if (error.message === "The user already exists.") {
          setDoesUserAlreadyExists(true);
        } else {
          setError(error);
        }
      })
      .finally(() => {
        setCreatingAccount(false);
      });
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSignupClick({ email, password, companyName, firstName, lastName, ...newAccount });
      }}
    >
      <Description>Please enter some basic information and set your password.</Description>
      <Row>
        {!newAccount?.firstName && (
          <Input
            type="text"
            value={firstName}
            name="firstName"
            label="First Name"
            required
            aria-required
            handleChange={handleInputChange}
            inputStyles={{ marginBottom: "16px" }}
          />
        )}

        {!newAccount?.lastName && (
          <Input
            type="text"
            value={lastName}
            name="lastName"
            label="Last Name"
            required
            aria-required
            handleChange={handleInputChange}
            inputStyles={{ marginBottom: "16px" }}
          />
        )}
      </Row>
      {(newAccount?.accountType === 0 || newAccount?.accountType === 1) && (
        <Input
          type="text"
          value={companyName}
          name="companyName"
          label="Agency Name"
          required
          aria-required
          handleChange={handleInputChange}
          inputStyles={{ marginBottom: "16px" }}
        />
      )}
      <Input
        type="email"
        value={email}
        name="email"
        label="Email address"
        required
        aria-required
        handleChange={handleInputChange}
        inputStyles={{ marginBottom: "16px" }}
        error={doesUserAlreadyExists}
        errorText="Error: This user already exists."
      />
      <Row>
        <Input
          type="password"
          value={password}
          name="password"
          label="Set password"
          required
          aria-required
          pattern={passwordPattern}
          title={passwordTitle}
          handleChange={handleInputChange}
          inputStyles={{ marginBottom: "16px" }}
        />
        <Input
          type="password"
          value={confirmPassword}
          name="confirmPassword"
          label="Confirm password"
          required
          aria-required
          pattern={passwordPattern}
          title={passwordTitle}
          handleChange={handleInputChange}
          handleBlur={() => setConfirmPasswordHasError(password !== confirmPassword)}
          error={confirmPasswordHasError}
          errorText="Error: passwords do not match"
        />
      </Row>
      <NextButton
        type="submit"
        disabled={
          email === "" || password === "" || confirmPassword === "" || password !== confirmPassword || creatingAccount
        }
      >
        Next
      </NextButton>
    </Form>
  );
};

export default BasicInfo;
