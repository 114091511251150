import { useMutation } from "@tanstack/react-query";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import Api from "utils/api";
import { reportError } from "utils/reportError";
import { useSelector } from "react-redux";

interface GetQuoteArgs {
  application: Application;
  token: string;
}

type Options = {
  onSuccess?: (res: { quote: Quote; alternateQuote: AlternateQuote }) => void;
};

const usePostPublicGetQuote = (options?: Options) => {
  const { apiVersion } = useFeatureFlags();

  const isOptIn = useSelector((state: ReduxState) => state.snapshot?.application?.lossControl === "OptIn");
  const currentQuote = useSelector((state: ReduxState) => state.snapshot?.quote) ?? {};
  const alternateQuoteOptIn =
    useSelector((state: ReduxState) => state.snapshot?.alternateQuote?.lossControlOptIn) ?? {};
  const alternateQuoteOptOut =
    useSelector((state: ReduxState) => state.snapshot?.alternateQuote?.lossControlOptOut) ?? {};

  const skipGettingAlternateQuoteData =
    currentQuote.premium &&
    currentQuote.premium === (isOptIn ? alternateQuoteOptIn.premium : alternateQuoteOptOut.premium) &&
    currentQuote.month1Owed === (isOptIn ? alternateQuoteOptIn.month1Owed : alternateQuoteOptOut.month1Owed);

  const getQuote = async ({
    application,
    token,
  }: GetQuoteArgs): Promise<{ quote: Quote; alternateQuote: AlternateQuote }> => {
    const api = new Api(apiVersion, token);

    return await api.application.publicGetQuote(application, token).then(async (res: any) => {
      if (skipGettingAlternateQuoteData) {
        return res;
      } else {
        const lossControlIsOptIn = res.quote.lossControlFees && res.quote.lossControlFees > 0;
        const payload = lossControlIsOptIn ? "OptOut" : "OptIn";
        const applicationWithLossControl = { ...application, ...{ lossControl: payload } };
        return await api.application
          .publicGetQuote(applicationWithLossControl, token)
          .then(async (alternateRes: any) => {
            const payload = lossControlIsOptIn ? "OptIn" : "OptOut";
            const applicationWithLossControl = { ...application, ...{ lossControl: payload } };
            return await api.application
              .publicGetQuote(applicationWithLossControl, token)
              .then((latestRes: any) => {
                const lossControlOptInAlternateQuote = {
                  month1Owed: lossControlIsOptIn ? res.quote.month1Owed : alternateRes.quote.month1Owed,
                  totalYearlyOwed: lossControlIsOptIn ? res.quote.totalYearlyOwed : alternateRes.quote.totalYearlyOwed,
                  premium: lossControlIsOptIn ? res.quote.premium : alternateRes.quote.premium,
                  monthlyPremium: lossControlIsOptIn ? res.quote.monthlyPremium : alternateRes.quote.monthlyPremium,
                  lossControlFees: lossControlIsOptIn ? res.quote.lossControlFees : alternateRes.quote.lossControlFees,
                };
                const lossControlOptOutAlternateQuote = {
                  month1Owed: lossControlIsOptIn ? alternateRes.quote.month1Owed : res.quote.month1Owed,
                  totalYearlyOwed: lossControlIsOptIn ? alternateRes.quote.totalYearlyOwed : res.quote.totalYearlyOwed,
                  premium: lossControlIsOptIn ? alternateRes.quote.premium : res.quote.premium,
                  monthlyPremium: lossControlIsOptIn ? alternateRes.quote.monthlyPremium : res.quote.monthlyPremium,
                  lossControlFees: lossControlIsOptIn ? alternateRes.quote.lossControlFees : res.quote.lossControlFees,
                };
                const isOptInLowerCost =
                  lossControlOptInAlternateQuote.month1Owed < lossControlOptOutAlternateQuote.month1Owed;
                const optInCostDifferenceMonthly = isNaN(
                  Math.abs(lossControlOptOutAlternateQuote.month1Owed - lossControlOptInAlternateQuote.month1Owed)
                )
                  ? undefined
                  : Math.abs(lossControlOptOutAlternateQuote.month1Owed - lossControlOptInAlternateQuote.month1Owed);
                const optInCostDifferenceYearly = isNaN(
                  Math.abs(
                    lossControlOptOutAlternateQuote.totalYearlyOwed - lossControlOptInAlternateQuote.totalYearlyOwed
                  )
                )
                  ? undefined
                  : Math.abs(
                      lossControlOptOutAlternateQuote.totalYearlyOwed - lossControlOptInAlternateQuote.totalYearlyOwed
                    );
                return {
                  ...latestRes,
                  alternateQuote: {
                    lossControlOptIn: lossControlOptInAlternateQuote,
                    lossControlOptOut: lossControlOptOutAlternateQuote,
                    isOptInLowerCost: isOptInLowerCost,
                    optInCostDifferenceMonthly: optInCostDifferenceMonthly,
                    optInCostDifferenceYearly: optInCostDifferenceYearly,
                  },
                };
              })
              .catch((error) => {
                reportError(error);
                return Promise.reject(error);
              });
          });
      }
    });
  };

  return useMutation({
    mutationFn: getQuote,
    ...options,
  });
};

export default usePostPublicGetQuote;
