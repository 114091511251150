import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import Api from "utils/api";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const getChannelPartners = async (apiVersion: string, token: Token) => {
  const api = new Api(apiVersion, token);
  return await api.channelPartners.getAll();
};

export default function useGetChannelPartners() {
  const { apiVersion } = useFeatureFlags();
  const token = useSelector((state: ReduxState) => state.token.token);
  return useQuery({
    queryKey: ["channel_partners"],
    queryFn: () => getChannelPartners(apiVersion, token),
  });
}
