import styled from "styled-components";
import { formatCurrency } from "utils/formatNumber";
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { laptop } from "styles";
import { BLANKET_TEXT } from "shared/application/edit/Endorsements/AdditionalInsured/constants";
import { BOPCoverage } from "./Coverages/BOPCoverage";
import { GLCoverage } from "./Coverages/GLCoverage";
import { PLCoverage } from "./Coverages/PLCoverage";
import { PolicyTabContainer } from "../PolicyTabContainer";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import ShieldLossControl from "images/shield_loss_control.svg";

interface PolicyInformationProps {
  policy: Policy;
}

const PolicyInformation = ({ policy }: PolicyInformationProps) => {
  const { showLossControl } = useFeatureFlags();
  const lossControlFee = policy?.fees?.find((fee) => fee?.feeType === "LossControlFee") ?? { amount: 0 };
  const hasLossControl = showLossControl && lossControlFee?.amount > 0;

  const endorsements = policy.endorsements;

  const renderCoverage = (policy: Policy) => {
    switch (policy.policyType) {
      case "BOP":
        return <BOPCoverage policy={policy} />;
      case "GL":
        return <GLCoverage policy={policy} />;
      case "PL":
        return <PLCoverage policy={policy} />;
      default:
        return null;
    }
  };

  const hasDBA = Boolean(policy?.dba);
  const hasEndorsements = Boolean(
    Object.entries(endorsements || {}).length > 0 && endorsements?.endorsementPackage !== "None"
  );

  return (
    <PolicyTabContainer heading="Dive deeper into the coverage details" data-testid="content-policy-information">
      <PolicyInfoContent>
        <div>
          <Table>
            <TableHeadSecondary>
              <tr>
                <THead colSpan={2}>Primary Insureds</THead>
              </tr>
            </TableHeadSecondary>
            <tbody>
              <tr data-cy="business-name">
                <TLabel>Legal Business Name</TLabel>
                <TData>{policy.businessName}</TData>
              </tr>
              {hasDBA && (
                <tr data-cy="dba">
                  <TLabel>DBA</TLabel>
                  <TData>{policy.dba}</TData>
                </tr>
              )}
              <tr data-cy="contact-name">
                <TLabel>Contact Name</TLabel>
                <TData>
                  {policy.contactFirstName} {policy.contactLastName}
                </TData>
              </tr>
              <tr data-cy="phone">
                <TLabel>Phone</TLabel>
                <TData>{policy.contactPhone}</TData>
              </tr>
              <tr data-cy="email">
                <TLabel>Email</TLabel>
                <TData>{policy.contactEmail}</TData>
              </tr>
            </tbody>
          </Table>

          {hasEndorsements && (
            <Table>
              <TableHeadSecondary>
                <tr>
                  <THead colSpan={2}>Additional Insureds</THead>
                </tr>
              </TableHeadSecondary>
              <tbody>
                {endorsements?.additionalInsuredsPrimaryAndNonContributory === true && (
                  <tr>
                    <TLabel>BLANKET</TLabel>
                    <TData>{BLANKET_TEXT.PRIMARY}</TData>
                  </tr>
                )}
                {endorsements?.additionalInsuredsWaiverOfTransfer === true && (
                  <tr>
                    <TLabel>BLANKET</TLabel>
                    <TData>{BLANKET_TEXT.SUBROGATION}</TData>
                  </tr>
                )}
                {"additionalInsuredsConstructionContractRequirementEndorsement" in
                  (endorsements as EndorsementsType) && (
                  <tr>
                    <TLabel>BLANKET</TLabel>
                    <TData>{BLANKET_TEXT.CONSTRUCTION}</TData>
                  </tr>
                )}
                {"additionalInsuredsManagersOrLessorsPremisesEndorsement" in (endorsements as EndorsementsType) && (
                  <tr>
                    <TLabel>BLANKET</TLabel>
                    <TData>{BLANKET_TEXT.PREMISES}</TData>
                  </tr>
                )}
                {"additionalInsuredsLessorOfLeasedEquipmentEndorsement" in (endorsements as EndorsementsType) && (
                  <tr>
                    <TLabel>BLANKET</TLabel>
                    <TData>{BLANKET_TEXT.EQUIPMENT}</TData>
                  </tr>
                )}
              </tbody>
            </Table>
          )}

          {hasLossControl && (
            <Table>
              <TableHeadSecondary>
                <tr>
                  <THead colSpan={2}>Added Services</THead>
                </tr>
              </TableHeadSecondary>
              <tbody>
                <tr data-cy="loss-control">
                  <TLabel>
                    <img src={ShieldLossControl} alt="" /> Manage My Risk Program
                  </TLabel>
                  <TData>Enrolled</TData>
                </tr>
              </tbody>
            </Table>
          )}
        </div>

        <div>
          <Table>
            <TableHeadContainer policyType={policy.policyType}>
              <tr>
                <THead colSpan={2}>Payment Information</THead>
              </tr>
            </TableHeadContainer>
            <tbody>
              <tr data-cy="premium">
                <TLabel>Total Premium</TLabel>
                <TData>{formatCurrency(policy.premium)}</TData>
              </tr>
              <tr data-cy="premium-paid">
                <TLabel>Premium Paid</TLabel>
                <TData>{formatCurrency(policy.premiumPaid)}</TData>
              </tr>
              <tr data-cy="next-payment-date">
                <TLabel>Next Payment Date</TLabel>
                <TData>
                  {Boolean(policy.nextPaymentDate) ? format(new Date(policy.nextPaymentDate), "MM/dd/yyyy") : ""}
                </TData>
              </tr>
              <tr data-cy="payment-interval">
                <TLabel>Payment Interval</TLabel>
                <TData>{policy.paymentInterval}</TData>
              </tr>
            </tbody>
          </Table>

          <Table>
            <TableHeadContainer policyType={policy.policyType}>
              <tr>
                <THead colSpan={2}>Policy Details</THead>
              </tr>
            </TableHeadContainer>
            <tbody>
              <Subheader policyType={policy.policyType} data-cy="policy-number">
                <TLabel>Policy Number</TLabel>
                <TData>{policy.policyNumber}</TData>
              </Subheader>
              <tr>
                <TLabel>Start Date</TLabel>
                <TLabel>End Date</TLabel>
              </tr>
              <tr>
                <TData style={{ paddingTop: 0 }} data-cy="start-date">
                  {policy.startDate ? formatInTimeZone(new Date(policy.startDate), "GMT", "MM/dd/yyyy") : "..."}
                </TData>
                <TData style={{ paddingTop: 0 }} data-cy="end-date">
                  {policy.endDate ? formatInTimeZone(new Date(policy.endDate), "GMT", "MM/dd/yyyy") : "..."}
                </TData>
              </tr>
            </tbody>
          </Table>

          <Table>
            <TableHeadContainer policyType={policy.policyType}>
              <tr>
                <THead colSpan={3}>Coverage Overview</THead>
              </tr>
              <Subheader policyType={policy.policyType}>
                <TLabel>Coverage</TLabel>
                <TLabel>Limit</TLabel>
                <TLabel style={{ paddingLeft: 0 }}>Deductible</TLabel>
              </Subheader>
            </TableHeadContainer>
            {renderCoverage(policy)}
          </Table>
        </div>
      </PolicyInfoContent>
    </PolicyTabContainer>
  );
};

export default PolicyInformation;

const PolicyInfoContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  grid-gap: 20px;
  font-size: 16px;
  ${laptop} {
    grid-template-columns: 1fr;
    height: auto;
  }
`;

const Table = styled.table`
  width: 100%;
  margin: auto;
  table-layout: fixed;
  background-color: ${({ theme }) => theme.pageComponents.ia.policyInformation.tableBackground};
  &:not(:first-child) {
    margin-top: 20px;
  }
  td {
    word-wrap: break-word;
  }
`;

const TableHeadContainer = styled.thead<{ policyType: string }>`
  background-color: ${({ theme, policyType }) =>
    theme.pageComponents.ia.policyInformation.productType[policyType?.toLowerCase()]};
`;

const TableHeadSecondary = styled.thead`
  background-color: ${({ theme }) => theme.pageComponents.ia.policyInformation.secondaryHeader};
`;

const Subheader = styled.tr<{ policyType: string }>`
  background-color: ${({ theme, policyType }) =>
    theme.pageComponents.ia.policyInformation.productTypeSubheader[policyType?.toLowerCase()]};
`;

const THead = styled.th`
  padding: 10px 0px 10px 20px;
  font-size: 12px;
`;

const TLabel = styled.td`
  text-transform: uppercase;
  padding: 10px 0px 10px 20px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.gray};
  font-size: 12px;
`;

const TData = styled.td`
  font-weight: 400;
  padding: 10px 0px 10px 20px;
  font-family: ${({ theme }) => theme.secondaryFont};
  color: ${({ theme }) => theme.gray};
  font-size: 15px;
`;
