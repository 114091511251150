import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import AddOnQuestion from "./AddOnQuestions";
import { EndorsementsMap, endorsementsMap, PackageInclusion } from "./AddOnQuestionsMap";
import { isEmpty } from "lodash";
import { selectAvailableEndorsements, selectLoadingAvailableEndorsements } from "redux/reducers/_availableEndorsements";
import { AddOnQuestionDisabled } from "./AddOnQuestionDisabled";
import { selectApplicationFormGLLimit, selectNaic } from "redux/selectors/application";
import { liquorClasses100 } from "utils/constants/liquorClasses";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  dispatch: Dispatch;
  availableEndorsementsResponse: any;
  loadingAvailableEndorsements: boolean;
  coverageBullets?: Array<string>;
  packageInclusion?: PackageInclusion;
  glLimit?: number;
  naic: number;
}

const AddOns = ({
  dispatch,
  availableEndorsementsResponse,
  loadingAvailableEndorsements,
  coverageBullets,
  packageInclusion,
  glLimit,
  naic,
}: PropTypes) => {
  const mappedEndorsements: EndorsementsMap = endorsementsMap();
  const { useNewLiquorLiabilityRules } = useFeatureFlags();

  if (loadingAvailableEndorsements) {
    return null;
  }

  if (isEmpty(availableEndorsementsResponse)) {
    return <div>No add-on selections available.</div>;
  }

  const availableEndorsements = Object.assign({}, availableEndorsementsResponse, { limitDamagePremRented: 50000 });

  const isLiquorLiabilityDisabled = (endorsement: string) => {
    return endorsement === "liquorLiabilityEndorsement" && glLimit === 2_000_000;
  };

  return (
    <>
      {Object.keys(availableEndorsements)
        .filter((result: string) => mappedEndorsements[result])
        .map((endorsement) => {
          const key =
            useNewLiquorLiabilityRules &&
            endorsement === "liquorLiabilityEndorsement" &&
            liquorClasses100.includes(naic)
              ? "liquorLiabilityEndorsement100"
              : endorsement;
          const {
            LABEL,
            QUESTION_COPY,
            DISABLED_DESCRIPTION,
            action,
            question,
            selector,
            toolTipKey,
            coverageBullets,
            packageInclusion,
          } = mappedEndorsements[key];

          if (isLiquorLiabilityDisabled(endorsement) && DISABLED_DESCRIPTION) {
            return <AddOnQuestionDisabled label={LABEL} description={DISABLED_DESCRIPTION} />;
          } else {
            return (
              <AddOnQuestion
                endorsement={endorsement}
                selector={selector}
                label={LABEL}
                description={QUESTION_COPY}
                question={question}
                action={(value: any) => dispatch(action(value))}
                key={endorsement}
                toolTipKey={toolTipKey}
                coverageBullets={coverageBullets}
                packageInclusion={packageInclusion}
              />
            );
          }
        })}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  availableEndorsementsResponse: selectAvailableEndorsements(state),
  loadingAvailableEndorsements: selectLoadingAvailableEndorsements(state),
  glLimit: selectApplicationFormGLLimit(state),
  naic: selectNaic(state),
});

export default connect(mapStateToProps)(AddOns);
