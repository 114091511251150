import Api from "utils/api";
import { useSelector } from "react-redux";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

export type EmailValidationResponse = {
  isValid: boolean;
  isWarning: boolean;
  confidenceValue: number;
  issueType: string;
  suggestedAddress?: string;
};

export const getEmailValidationMessage = (error: EmailValidationResponse, email: string) => {
  switch (error.issueType) {
    case "PossibleTypo":
      return `Did you mean ${error.suggestedAddress}?`;
    case "TemporaryAddress":
      return "This looks like a temporary email. We need a valid email to send information to the policyholder.";
    case "ConfidenceTooLow":
      return "We can't verify this email. We require a valid policyholder email to access secure policy information and Billing Center.";
    default:
      return `"${email}" is not a valid email. Please correct and double check for spaces at the beginning or end.`;
  }
};

const warningTypes = ["PossibleTypo", "ConfidenceTooLow"];

export const checkForWarnings = (error: EmailValidationResponse) => {
  return { ...error, isWarning: warningTypes.includes(error.issueType) };
};

export default function useValidateEmail() {
  const token = useSelector((state: ReduxState) => state.token.token);
  const { apiVersion } = useFeatureFlags();
  const api = new Api(apiVersion, token);

  return async (email: string) => {
    const response = await api.validateEmail(email);
    return checkForWarnings(response);
  };
}
