import React from "react";
import { TableContainer } from "styles/containerStyledComponents";
import PolicySearchRow from "./PolicySearchRow";
import styled from "styled-components";
import UpImg from "images/up.svg";
import DownImg from "images/down.svg";
import { PolicySortBy, SortDirection } from "types/enums";

const TABLE_HEADERS = [
  { title: "Status", sort: PolicySortBy.Status, id: 1 },
  { title: "Name", sort: PolicySortBy.Name, id: 2 },
  { title: "Email", sort: PolicySortBy.Email, id: 3 },
  { title: "Policy Number", sort: PolicySortBy.PolicyNumber, id: 4 },
  { title: "Expiration", sort: PolicySortBy.EndDate, id: 5 },
];

const getSortBy = (header: string): PolicySortBy =>
  TABLE_HEADERS.find((row) => row.title === header)?.sort || PolicySortBy.Name;

interface PropTypes {
  policies: Policy[];
  search?: string;
  showAccountLink?: boolean;
  changeSort: (sortBy: PolicySortBy, sortDirection: SortDirection) => void;
  sortBy: PolicySortBy;
  sortDirection: SortDirection;
}

const PolicySearchTable = ({ policies, search, sortBy, sortDirection, changeSort }: PropTypes) => {
  const arrow = {
    [SortDirection.Descending]: DownImg,
    [SortDirection.Ascending]: UpImg,
  };

  const selectSortColumn = (column: PolicySortBy) => {
    changeSort(column, sortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending);
  };

  const resultRows = policies.map((policy: Policy, i: number) => {
    return <PolicySearchRow key={policy.policyId} policy={policy} searchTerm={search} />;
  });

  return (
    <TableContainer className="results" data-cy="policy-table">
      <table>
        <thead>
          <tr>
            {TABLE_HEADERS.map((header, index) => (
              <th key={`header-${header.id}`}>
                <HeaderRow>
                  <button
                    data-cy={`header-${header.title}`}
                    type="button"
                    onClick={() => selectSortColumn(getSortBy(header.title))}
                  >
                    {header.title}
                  </button>
                  {sortBy === getSortBy(header.title) && <img src={arrow[sortDirection]} alt="arrow" />}
                </HeaderRow>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {resultRows.length > 0 ? (
            resultRows
          ) : (
            <tr>
              <td colSpan={10}>No policies matching your criteria, try typing in the search bar above</td>
            </tr>
          )}
        </tbody>
      </table>
    </TableContainer>
  );
};

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export default PolicySearchTable;
