import React from "react";
import { Header4 } from "styles";
import formatDateTime from "utils/formatDateTime";
import Field from "./Field";

interface PropTypes {
  policy: Policy;
}

const PaymentInfoCard = ({ policy }: PropTypes) => {
  const policyInfo = policy || {};
  return (
    <div>
      <Header4>Payment Information</Header4>
      <Field value={`$${policyInfo.premium || 0}`} label="Total Premium" aria-label="Total Premium" />
      <Field value={`$${policyInfo.premiumPaid || 0}`} label="Premium Paid" aria-label="Premium Paid" />
      <Field
        value={policyInfo.nextPaymentDate ? formatDateTime(new Date(policyInfo.nextPaymentDate)) : ""}
        label="Next Payment Date"
        aria-label="Next Payment Date"
      />
      <Field value={policyInfo.paymentInterval} label="Payment Interval" aria-label="Payment Interval" />
    </div>
  );
};

export default PaymentInfoCard;
