import styled from "styled-components";
import { GreenButton, Paragraph, TextLink } from "styles";

export const Center = styled.div`
  text-align: center;
`;

export const Description = styled(Paragraph)`
  line-height: 23px;
  margin: 32px 0 17px;
`;

export const NextButton = styled(GreenButton)`
  width: 120px;
  margin: 24px auto 32px auto;
  display: block;

  :disabled {
    background-color: ${(props) => props.theme.disabledBackground};
    color: ${(props) => props.theme.disabledText};
    opacity: 1;
  }
`;

export const BoldTextLink = styled(TextLink)`
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
  font-weight: normal;
`;

export const Row = styled.div`
  display: grid;
  grid-column-gap: 26px;
  grid-template-columns: repeat(2, 1fr);
`;
