import { createSlice } from "@reduxjs/toolkit";

const initialState: AffiliationState = {
  form: {},
  files: [],
  loading: false,
  successModal: { display: false, errorMessage: "" },
};

const applicationsSlice = createSlice({
  name: "affiliation",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setForm: (state, { payload }) => {
      state.form = payload ? { ...state.form, ...payload } : {};
    },
    setAffiliationModal: (state, { payload }) => {
      state.successModal = { display: !state.successModal.display, errorMessage: payload?.errorMessage };
    },
    setFiles: (state, { payload }) => {
      state.files = payload;
    },
  },
});

export const getAffiliationForm = (state: ReduxState) => state.affiliation.form;
export const getAffiliationLoading = (state: ReduxState) => state.affiliation.loading;
export const getAffiliationId = (state: ReduxState) => getAffiliationForm(state).id;
export const getAffiliationFirstName = (state: ReduxState) => getAffiliationForm(state).firstName;
export const getAffiliationLastName = (state: ReduxState) => getAffiliationForm(state).lastName;
export const getAffiliationEmail = (state: ReduxState) => getAffiliationForm(state).contactEmail;
export const getAffiliationBirthday = (state: ReduxState) => getAffiliationForm(state).dateOfBirth;
export const getAffiliationPhone = (state: ReduxState) => getAffiliationForm(state).phoneNumber;
export const getAffiliationSocial = (state: ReduxState) => getAffiliationForm(state).ssn;

export const getAffiliationLicenseNumber = (state: ReduxState) => getAffiliationForm(state).licenseNumber;
export const getAffiliationLicenseState = (state: ReduxState) => getAffiliationForm(state).licenseState;
export const getAffiliationState = (state: ReduxState) => getAffiliationForm(state).affiliatedState;
export const getAffiliationPropertyConfirmation = (state: ReduxState) =>
  getAffiliationForm(state).propertyBrokerConfirmation;
export const getAffiliationCasualtyConfirmation = (state: ReduxState) =>
  getAffiliationForm(state).casualtyBrokerConfirmation;
export const getAffiliationNPN = (state: ReduxState) => getAffiliationForm(state).npn;
export const getAffiliationLanguage = (state: ReduxState) => getAffiliationForm(state).language;

export const getAffiliationBGProfessionalLicense = (state: ReduxState) =>
  getAffiliationForm(state).backgroundProfessionalLicense;
export const getAffiliationBGPreviousContract = (state: ReduxState) =>
  getAffiliationForm(state).backgroundPreviousContract;
export const getAffiliationBGCivilJudgments = (state: ReduxState) => getAffiliationForm(state).backgroundCivilJudgments;
export const getAffiliationBGBankruptcy = (state: ReduxState) => getAffiliationForm(state).backgroundBankruptcy;
export const getAffiliationBGConvictedFelon = (state: ReduxState) => getAffiliationForm(state).backgroundConvictedFelon;
export const getAffiliationBGPrincipalOwner = (state: ReduxState) => getAffiliationForm(state).backgroundPrincipalOwner;
export const getAffiliationBGAccurateInformation = (state: ReduxState) =>
  getAffiliationForm(state).backgroundAccurateInformation;
export const getAffiliationBGAdditionalDetails = (state: ReduxState) =>
  getAffiliationForm(state).backgroundAdditionalDetails;

export const getAffiliationConsentAccurateInformation = (state: ReduxState) =>
  getAffiliationForm(state).consentAccurateInformation;
export const getAffiliationConsentConsumerReports = (state: ReduxState) =>
  getAffiliationForm(state).consentConsumerReports;

export const getAffiliatedStatus = (state: ReduxState) => state.user.affiliatedStatus;
export const getAffiliationModal = (state: ReduxState) => state.affiliation.successModal;
export const getAffiliationFiles = (state: ReduxState) => state.affiliation.files;

export const { setLoading, setForm, setAffiliationModal, setFiles } = applicationsSlice.actions;
export default applicationsSlice.reducer;
