import React from "react";
import styled, { keyframes } from "styled-components";
import { PolicyTypeChip, PolicyTypeChipTypes } from "coterie-ui-library";
import { formatCurrency } from "utils/formatNumber";
import { Text } from "utils/toolTipContent";
import { PolicyType } from "types/enums";
import ToolTipIcon from "shared/ToolTipIcon";
import { GhostButton } from "shared/SimplyBind/styles";
import CloseIcon from "images/close-gray.svg";
import ShieldLossControl from "images/shield_loss_control_border.svg";

interface PropTypes {
  onClose: () => void;
  selectedRenewalDetails: Renewal;
  showPriceDrawer: boolean;
}

const PriceDrawer = ({ onClose, selectedRenewalDetails, showPriceDrawer }: PropTypes) => {
  const {
    businessName,
    expiringPolicyNumber,
    renewalPremium = 0,
    installmentFees,
    lineOfBusiness,
    lossControl,
    lossControlFees,
    month1Owed,
    monthlyFees,
    monthlyOwed,
    totalYearlyOwed,
    paymentPlan,
    policyFees,
  } = selectedRenewalDetails;

  const isMonthly = paymentPlan === "Monthly";
  const premium = renewalPremium;
  const monthlyPremium = renewalPremium / 12;
  const lossControlIsOptIn = lossControl === "OptIn";
  const hasPolicyFee = Boolean(policyFees);
  const hasInstallmentFee = Boolean(installmentFees);
  const renderLossControl = lineOfBusiness === PolicyType.BOP || lineOfBusiness === PolicyType.GL;

  const PriceDetails = [
    {
      label: isMonthly ? "Renewal Monthly Premium" : "Renewal Yearly Premium",
      amount: isMonthly ? monthlyPremium : premium,
      isVisible: true,
    },
    { label: "Annual Policy Fee", amount: policyFees, isVisible: hasPolicyFee },
    { label: "Installment Fee", amount: installmentFees, isVisible: hasInstallmentFee },
    {
      label: "Manage My Risk Program",
      amount: isMonthly ? lossControlFees : lossControlFees * 12,
      isVisible: renderLossControl && lossControlIsOptIn,
    },
    {
      label: "Amount to be billed at renewal",
      amount: isMonthly ? month1Owed : totalYearlyOwed,
      isVisible: true,
      isBold: true,
    },
  ];

  return (
    <>
      <Overlay showPriceDrawer={showPriceDrawer} onClick={onClose} />
      <Drawer showPriceDrawer={showPriceDrawer}>
        <Close onClick={onClose}>
          <img src={CloseIcon} alt="Close Cost Breakdown" />
        </Close>
        <DrawerContent>
          <Header>Renewal Billing</Header>
          <BusinessDetails>
            <PolicyTypeChip name={PolicyTypeChipTypes[lineOfBusiness as "BOP" | "GL" | "PL"]} />
            <BusinessNameText>{businessName}</BusinessNameText>
            <BillingPlanChip>{isMonthly ? "Monthly Billing" : "Yearly Billing"}</BillingPlanChip>
          </BusinessDetails>
          <PriceDetailsContainer>
            {PriceDetails.filter((e) => e.isVisible).map((detail) => (
              <SpaceBetweenDiv>
                <PriceLabel isBold={detail?.isBold}>{`${detail.label}:`}</PriceLabel>
                <PriceLabel isBold={detail?.isBold}>{formatCurrency(detail.amount)}</PriceLabel>
              </SpaceBetweenDiv>
            ))}
          </PriceDetailsContainer>
          {isMonthly && (
            <Section>
              <Header>Future Monthly Payments</Header>
              <SpaceBetweenDiv>
                <PriceLabel>
                  Monthly Payment Amount:{" "}
                  {Boolean(monthlyFees) && (
                    <ToolTipIcon
                      content={
                        <Text>
                          Future monthly payments include the monthly premium and the following fee
                          {lossControlIsOptIn && "s"}:
                          <ul>
                            <li>{formatCurrency(installmentFees as number)}/mo installment fee</li>
                            {lossControlIsOptIn && (
                              <li>{formatCurrency(lossControlFees as number)}/mo Manage My Risk</li>
                            )}
                          </ul>
                        </Text>
                      }
                    />
                  )}
                </PriceLabel>
                <PriceLabel>{formatCurrency(monthlyOwed)}</PriceLabel>
              </SpaceBetweenDiv>
            </Section>
          )}
          {renderLossControl && (
            <Section>
              <Header>Manage My Risk Program</Header>
              <LossControlContainer>
                <img src={ShieldLossControl} alt="" />
                <LossControlValue isOptIn={lossControlIsOptIn}>
                  {lossControlIsOptIn ? "" : "Not "}Enrolled in Manage My Risk
                </LossControlValue>
              </LossControlContainer>
            </Section>
          )}
          <Section>
            <Header>Expiring Policy Number: {expiringPolicyNumber}</Header>
            <StyledGhostButton href={`/policy?policyIds=${expiringPolicyNumber}`} data-testid="view-policy-button">
              View Expiring Policy
            </StyledGhostButton>
          </Section>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const slideIn = keyframes`
from {
  transform: translateX(100%);
}
to {
  transform: translateX(0);
}
`;

const slideOut = keyframes`
from {
  transform: translateX(0);
}
to {
  transform: translateX(100%);
}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Overlay = styled.div<{ showPriceDrawer: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  animation: ${({ showPriceDrawer }) => (showPriceDrawer ? fadeIn : fadeOut)} 0.4s forwards;
`;

const Drawer = styled.div<{ showPriceDrawer: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background-color: ${(props) => props.theme.colors.neutral.white};
  padding-left: 32px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 24px;
  z-index: 100;
  animation: ${({ showPriceDrawer }) => (showPriceDrawer ? slideIn : slideOut)} 0.4s forwards;
`;

const DrawerContent = styled.div`
  padding: 24px 16px 0 0;
`;

const Header = styled.p`
  color: ${(props) => props.theme.mediumGray};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const BusinessNameText = styled.div`
  width: 124px;
  font-size: 16px;
  max-height: 2lh;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const BillingPlanChip = styled.div`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.tertiary.zero};
  background-color: ${(props) => props.theme.colors.neutral.special};
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 4px;
  padding: 4px 8px;
  min-width: fit-content;
`;

const PriceDetailsContainer = styled.div`
  margin: 20px 0;
  display: flex;
  row-gap: 4px;
  flex-direction: column;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const PriceLabel = styled.span<{ isBold?: boolean }>`
  color: ${(props) => (props.isBold ? props.theme.colors.tertiary.zero : props.theme.mediumGray)};
  font-family: ${(props) => (props.isBold ? props.theme.primaryFont : props.theme.secondaryFont)};
  font-size: ${(props) => (props.isBold ? "16px" : "15px")};
  font-style: normal;
  font-weight: ${(props) => (props.isBold ? 600 : 400)};
  line-height: 24px;
`;

const LossControlContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const LossControlValue = styled(PriceLabel)<{ isOptIn?: boolean }>`
  color: ${(props) => (props.isOptIn ? props.theme.colors.secondary.dark : props.theme.colors.alert.dark)};
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-top: 1px solid ${(props) => props.theme.colors.neutral.light};
`;

const BusinessDetails = styled(SpaceBetweenDiv)`
  color: ${(props) => props.theme.textNavyBlue};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-top: 24px;
  align-items: center;
`;

const Close = styled.span`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

const StyledGhostButton = styled(GhostButton)`
  width: 172px;
`;

export default PriceDrawer;
