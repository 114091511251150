import React, { useState } from "react";
import { ContentTable, Pagination, TextLink } from "coterie-ui-library";
import CommissionDetailsModal from "./CommissionDetailsModal";
import styled from "styled-components";
import { formatCurrency } from "utils/formatNumber";
import { format } from "date-fns";

interface PropTypes {
  commissions: CommissionResponse;
  setPageNumber: (pageNumber: number) => void;
  setOrderBy: (sort: SortValue[]) => void;
  orderby: SortValue[];
}

const CommissionsTable = ({ commissions, setPageNumber, setOrderBy, orderby }: PropTypes) => {
  const [selectedCommission, setSelectedCommission] = useState<Commission>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { pageCount, pageNumber, items } = commissions;

  const handleDetailsClick = (row: any) => {
    setShowModal(true);
    setSelectedCommission(row.original);
  };

  const handlePageChange = (page: number) => setPageNumber(page);
  const handleSortChange = (sort: SortValue[]) => setOrderBy(sort);

  const columns = [
    {
      Header: "Policy",
      accessor: "policyNumber",
      id: "PolicyNumber",
      Cell: ({ value, row }: any) => {
        return value;
      },
      minWidth: 180,
      maxWidth: 190,
    },
    {
      Header: "Insured",
      accessor: "insuredBusinessName",
      id: "InsuredBusinessName",
      Cell: ({ value }: any) => {
        return value || "------";
      },
      minWidth: 220,
    },
    {
      Header: "Agency",
      accessor: "producerAgency",
      id: "ProducerAgency",
      Cell: ({ value }: any) => {
        return value || "------";
      },
      minWidth: 230,
    },
    {
      Header: "Agent",
      id: "name",
      Cell: ({ row }: any) => {
        return row.original.producerFirstName && row.original.producerLastName
          ? `${row.original.producerFirstName} ${row.original.producerLastName}`
          : "-----";
      },
    },
    {
      Header: "Commission",
      accessor: "writtenCommissions",
      id: "WrittenCommissions",
      Cell: ({ value }: any) => {
        return value < 0 ? <ErrorAmountLabel>{formatCurrency(value)}</ErrorAmountLabel> : formatCurrency(value);
      },
      maxWidth: 75,
    },
    {
      Header: "Accounting Period",
      accessor: "AccountingPeriod",
      Cell: ({ row }: any) => {
        const month = row.original.accountingPeriod.month;
        const year = row.original.accountingPeriod.year;
        const accountPeriod = format(new Date(year, month - 1), "MMMM yyyy");

        return accountPeriod;
      },
      maxWidth: 100,
    },
    {
      Header: "",
      id: "commision-details",
      Cell: ({ row }: any) => {
        return <TextLink onClick={() => handleDetailsClick(row)}>View Details</TextLink>;
      },
      maxWidth: 100,
    },
  ];

  return (
    <>
      <ContentTable
        data={items}
        columns={columns}
        label="commissions-table"
        onSortChange={handleSortChange}
        manualSortBy
        sortBy={orderby}
      />
      <PaginationWrapper>
        <Pagination totalPages={pageCount} currentPage={pageNumber} onPageChange={handlePageChange} />
      </PaginationWrapper>
      {selectedCommission && (
        <CommissionDetailsModal
          commission={selectedCommission}
          isOpen={showModal}
          closeModal={() => setShowModal(false)}
        />
      )}
    </>
  );
};

const ErrorAmountLabel = styled.div`
  color: ${(props) => props.theme.colors.alert.zero};
`;

const PaginationWrapper = styled.div`
  align-items: flex-end;
  position: relative;
  margin-left: auto;
  margin-top: 16px;
  button {
    min-width: 30px;
    width: auto;
  }
`;

export default CommissionsTable;
