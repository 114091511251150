import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle } from "reactstrap";
import styled from "styled-components";
import { PolicyType, LocationTypes } from "types/enums";
import { POLICY_OPTIONS, VALUE_OPTIONS, POLICY_ARIA_LABELS } from "utils/constants";
import { formatNumber } from "utils/formatNumber";
import { updateApplicationForm, updateApplicationFormPL } from "redux/reducers/_applicationReducer";
import updateApplicationFromServer from "redux/actions/updateApplicationFromServer";
import {
  selectApplicationFormApplicationTypes,
  selectApplicationFormBPPDeductible,
  selectApplicationFormBPPLimit,
  selectApplicationFormBuildingLimit,
  selectApplicationFormEmployeeVehicles,
  selectApplicationFormGLLimit,
  selectApplicationFormLocationType,
  selectApplicationFormPLDeductible,
  selectApplicationFormYearsOfProfessionalExperience,
  selectApplicationFormYearsOfPriorActs,
  selectApplicationFormPLLimit,
  selectApplicationFormPropertyDamageLiabilityDeductible,
  selectApplicationFormRentVehicles,
  selectApplicationFormHasSprinklers,
  selectApplicationFormPeriodLoading,
  selectApplicationAvailablePolicyTypes,
  selectApplicationFormGLAggregatePcoLimit,
} from "redux/selectors/application";
import BopMoreDetails from "../BopMoreDetails";
import GlMoreDetails from "../GlMoreDetails";
import { Row, CoverageType, CoverageCard, PolicyTile, PolicyName } from "../StyledComponents";
import MultiRow from "../MultiRow";
import Field from "shared/Field";
import BopSmall from "images/bop_policy_small.svg";
import GlSmall from "images/gl_policy_small.svg";
import PlSmall from "images/pl_policy_small.svg";
import PolicyGL from "images/gl_policy.svg";
import PolicyPL from "images/pl_policy.svg";
import PolicyBOP from "images/bop_policy.svg";
import { extendedReportingPeriodOptions } from "../edit/Coverages/PLCoverages";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  applicationTypes: any;
  availablePolicyTypes: PolicyType[];
  bppLimit: number | undefined;
  bppDeductible: number;
  buildingLimit: number | undefined;
  employeeVehicles: string;
  glLimit: number;
  glAggregatePcoLimit: number;
  locationType: string;
  pdDeductible: number;
  plDeductible: number;
  plLimit: number;
  yearsOfProfessionalExperience: number;
  yearsOfPriorActs: number;
  rentVehicles: string;
  hasSprinklers: any;
  periodLoading: number;
  updateApplicationFromServer: (arg0: APIVersionAuth) => void;
  updateApplicationForm: (arg0: Application) => void;
  updateApplicationFormPL: (arg0: Application) => void;
  isReadOnly?: boolean;
}

const icon: any = {
  "Business Owner's Policy": BopSmall,
  "General Liability": GlSmall,
  "Professional Liability": PlSmall,
};

const PolicySelection = ({ policy }: any) => (
  <PolicySection>
    <PolicyIcon src={icon[policy]} alt={`${policy} icon`} />
    <PolicyTitle>{policy}</PolicyTitle>
  </PolicySection>
);

const Coverages = ({
  applicationTypes,
  availablePolicyTypes,
  bppDeductible,
  bppLimit,
  buildingLimit,
  employeeVehicles,
  glLimit,
  glAggregatePcoLimit,
  locationType,
  pdDeductible,
  plDeductible,
  plLimit,
  yearsOfProfessionalExperience,
  yearsOfPriorActs,
  rentVehicles,
  updateApplicationFromServer,
  updateApplicationForm,
  updateApplicationFormPL,
  hasSprinklers,
  isReadOnly = false,
  periodLoading,
}: PropTypes) => {
  const { getToken, isIAUser } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const updateValue = async (amount: number, name?: any) => {
    const token = await getToken();

    updateApplicationForm({ [name]: amount });
    updateApplicationFromServer({ token, isIAUser, apiVersion });
  };

  const updatePlCoverage = async (amount: number, name?: any) => {
    const token = await getToken();

    updateApplicationFormPL({ [name]: amount });
    updateApplicationFromServer({ token, isIAUser, apiVersion });
  };

  const noPolicies = !applicationTypes || applicationTypes.length === 0;
  const isBOP = applicationTypes && applicationTypes.includes(PolicyType.BOP);
  const isGL = applicationTypes && applicationTypes.includes(PolicyType.GL) && !isBOP;
  const isPL = applicationTypes && applicationTypes.includes(PolicyType.PL);
  const showBuildingLimit = isBOP && locationType && locationType === LocationTypes.BuildingOwned;

  const policies = [
    { policy: PolicyType.PL, title: "Professional Liability Policy", image: PolicyPL },
    { policy: PolicyType.GL, title: "General Liability Policy", image: PolicyGL },
    { policy: PolicyType.BOP, title: "Business Owner’s Policy", image: PolicyBOP },
  ];

  return (
    <Card data-cy="coverages-view">
      <CardBody>
        <Row>
          <CardTitle>Coverages</CardTitle>
        </Row>

        {noPolicies && availablePolicyTypes && availablePolicyTypes.length > 0 && (
          <div>
            {policies
              .filter((item) => availablePolicyTypes.includes(item.policy))
              .map((tile: any) => {
                return (
                  <PolicyTile white invalid key={tile.policy}>
                    <img src={tile.image} alt={`${tile.title} icon`} />
                    <PolicyName>{tile.title}</PolicyName>
                  </PolicyTile>
                );
              })}
          </div>
        )}

        {isBOP && (
          <CardWithFields>
            <StyledCoverageCardWithFields>
              <PolicySelection policy="Business Owner's Policy" />
              <CoverageType>Property Claims Deductible</CoverageType>
              <MultiRow
                money={true}
                isReadOnly={isReadOnly ?? false}
                options={VALUE_OPTIONS[POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE]}
                value={formatNumber(bppDeductible)}
                updateValue={updateValue}
                label={POLICY_ARIA_LABELS[POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE]}
                propertyName={"bppDeductible"}
              />
              <CoverageType>General Liability Limit</CoverageType>
              <MultiRow
                money={true}
                isReadOnly={isReadOnly ?? false}
                options={VALUE_OPTIONS[POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT]}
                value={formatNumber(glLimit)}
                updateValue={updateValue}
                label={POLICY_ARIA_LABELS[POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT]}
                propertyName={"glLimit"}
              />
              <CoverageType>Property Damage Liability Deductible</CoverageType>
              <MultiRow
                money={true}
                isReadOnly={isReadOnly ?? false}
                options={VALUE_OPTIONS[POLICY_OPTIONS.PROPERTY_DAMAGE_DEDUCTIBLE]}
                value={formatNumber(pdDeductible)}
                updateValue={updateValue}
                label={POLICY_ARIA_LABELS[POLICY_OPTIONS.PROPERTY_DAMAGE_DEDUCTIBLE]}
                propertyName={"propertyDamageLiabilityDeductible"}
              />
              <BopMoreDetails />
            </StyledCoverageCardWithFields>
            <Field
              value={formatNumber(bppLimit)}
              label={POLICY_ARIA_LABELS[POLICY_OPTIONS.BUSINESS_PERSONAL_PROPERTY_LIMIT]}
              slug="BppLimit"
              isCurrency
            />
            {showBuildingLimit && (
              <Field
                value={formatNumber(buildingLimit)}
                label={POLICY_ARIA_LABELS[POLICY_OPTIONS.BUILDING_LIMIT]}
                slug="BuildingLimit"
                isCurrency
              />
            )}
            <Field value={hasSprinklers ? "Yes" : "No"} label="Sprinkler System?" slug="HasSprinklers" />
            {/*endorsements below temporarily hidden for BOP 2.0 rollout
            <Field value={rentVehicles} label="Rent Vehicles" slug="HiredCoverage" />
            <Field value={employeeVehicles} label="Empl. Vehicles?" slug="NonOwnedCoverage" /> */}
          </CardWithFields>
        )}

        {isGL && (
          <StyledCoverageCard>
            <PolicySelection policy="General Liability" />
            <CoverageType>General Liability Limit</CoverageType>
            <MultiRow
              money={true}
              isReadOnly={isReadOnly ?? false}
              options={VALUE_OPTIONS[POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT]}
              value={formatNumber(glLimit)}
              updateValue={updateValue}
              label={POLICY_ARIA_LABELS[POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT]}
              propertyName={"glLimit"}
            />
            <CoverageType>Property Damage Liability Deductible</CoverageType>
            <MultiRow
              money={true}
              isReadOnly={isReadOnly ?? false}
              options={VALUE_OPTIONS[POLICY_OPTIONS.PROPERTY_DAMAGE_DEDUCTIBLE]}
              value={formatNumber(pdDeductible)}
              updateValue={updateValue}
              label={POLICY_ARIA_LABELS[POLICY_OPTIONS.PROPERTY_DAMAGE_DEDUCTIBLE]}
              propertyName={"propertyDamageLiabilityDeductible"}
            />
            <GlMoreDetails glLimit={glLimit} deductible={pdDeductible} glAggregatePcoLimit={glAggregatePcoLimit} />
          </StyledCoverageCard>
        )}

        {isPL && (
          <CardWithFields>
            <StyledCoverageCardWithFields>
              <PolicySelection policy="Professional Liability" />
              <CoverageType>Professional Liability Limit</CoverageType>
              <MultiRow
                money={true}
                isReadOnly={isReadOnly ?? false}
                options={VALUE_OPTIONS[POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT]}
                value={formatNumber(plLimit)}
                updateValue={updatePlCoverage}
                label={POLICY_ARIA_LABELS[POLICY_OPTIONS.PROFESSIONAL_LIABILITY_LIMIT]}
                propertyName="occurrenceLimit"
              />
              <CoverageType>Deductible</CoverageType>
              <MultiRow
                money={true}
                isReadOnly={isReadOnly ?? false}
                options={VALUE_OPTIONS[POLICY_OPTIONS.DEDUCTIBLE]}
                value={formatNumber(plDeductible)}
                updateValue={updatePlCoverage}
                label={POLICY_ARIA_LABELS[POLICY_OPTIONS.DEDUCTIBLE]}
                propertyName={"deductibleAmount"}
              />
            </StyledCoverageCardWithFields>
            <Field
              value={formatNumber(yearsOfProfessionalExperience)}
              label={POLICY_ARIA_LABELS[POLICY_OPTIONS.YEARS_OF_PROFESSIONAL_EXPERIENCE]}
              slug="yearsOfProfessionalExperience"
            />
            <Field
              value={formatNumber(yearsOfPriorActs)}
              label={POLICY_ARIA_LABELS[POLICY_OPTIONS.YEARS_OF_PRIOR_ACTS]}
              slug="yearsOfPriorActs"
            />
            <Field
              value={
                periodLoading === 0 ? extendedReportingPeriodOptions.find((p) => p.value === 0)?.label : periodLoading
              }
              label={POLICY_ARIA_LABELS[POLICY_OPTIONS.PERIOD_LOADING]}
            />
          </CardWithFields>
        )}
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  applicationTypes: selectApplicationFormApplicationTypes(state),
  availablePolicyTypes: selectApplicationAvailablePolicyTypes(state),
  bppDeductible: selectApplicationFormBPPDeductible(state),
  bppLimit: selectApplicationFormBPPLimit(state),
  buildingLimit: selectApplicationFormBuildingLimit(state),
  employeeVehicles: selectApplicationFormEmployeeVehicles(state),
  glLimit: selectApplicationFormGLLimit(state),
  glAggregatePcoLimit: selectApplicationFormGLAggregatePcoLimit(state),
  locationType: selectApplicationFormLocationType(state),
  pdDeductible: selectApplicationFormPropertyDamageLiabilityDeductible(state),
  plLimit: selectApplicationFormPLLimit(state),
  plDeductible: selectApplicationFormPLDeductible(state),
  yearsOfProfessionalExperience: selectApplicationFormYearsOfProfessionalExperience(state),
  yearsOfPriorActs: selectApplicationFormYearsOfPriorActs(state),
  rentVehicles: selectApplicationFormRentVehicles(state),
  hasSprinklers: selectApplicationFormHasSprinklers(state),
  periodLoading: selectApplicationFormPeriodLoading(state),
});

const mapDispatchToProps = {
  updateApplicationFromServer,
  updateApplicationForm,
  updateApplicationFormPL,
};

const StyledCoverageCard = styled(CoverageCard)`
  padding: 0px 16px 16px;
  margin-top: 2px;
`;

const StyledCoverageCardWithFields = styled(CoverageCard)`
  padding: 0px 16px 16px;
  margin-bottom: 0px;
`;

const CardWithFields = styled.div`
  margin-bottom: 20px;
  margin-top: 2px;
`;

const PolicyTitle = styled.h4`
  color: ${(props) => props.theme.fontColors?.primary};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  font-weight: 600;
  margin: 20px 10px;
`;

const PolicySection = styled.div`
  display: flex;
  flex-direction: row;
`;

const PolicyIcon = styled.img`
  margin-top: -4px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Coverages);
