import { StyleSheet, Rect } from "@react-pdf/renderer";
import { Svg } from "utils/proposal-pdf/SvgPatch";
import { theme } from "styles";

const CoverageTableLine = () => (
  <Svg style={styles.line}>
    <Rect x="" y="" width="524px" height="1px" fill={theme.colors.tertiary.sixty} />
  </Svg>
);

const styles = StyleSheet.create({
  line: {
    height: "1px",
  },
});
export default CoverageTableLine;
