import React from "react";
import "react-table/react-table.css";
import styled from "styled-components";
import { TableContainer } from "styles/containerStyledComponents";

interface PropTypes {
  auditTrail: PolicyAuditItem[];
}

const AuditTrailTable = (props: PropTypes) => {
  const { auditTrail } = props;

  const rows = auditTrail.map((item, index) => (
    <tr key={`auditItem-${index}`} data-cy={"audit-trail-row"}>
      <td>{item.changeDescription}</td>
      <td>{new Date(item.changedOn).toLocaleString()}</td>
    </tr>
  ));

  return (
    <StyledAuditTrailTable className={"audit-trail-table"}>
      <table>
        <thead>
          <tr>
            <th>Description</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              <td colSpan={2}>No changes</td>
            </tr>
          )}
        </tbody>
      </table>
    </StyledAuditTrailTable>
  );
};

const StyledAuditTrailTable = styled(TableContainer)`
  max-height: 500px;
  overflow: auto;

  thead {
    tr {
      th {
        background: none;
      }
    }
  }
  tbody {
    max-height: 500px;
    overflow: auto;

    tr {
      td {
        vertical-align: top;

        &:first-child {
          white-space: pre-line;
        }
      }
    }
  }
`;

export default AuditTrailTable;
