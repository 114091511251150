import React from "react";
import UpdatePolicyForm from "./PolicyUpdatesForm/UpdatePolicyForm";
import styled from "styled-components";

const ActivePolicyUpdates = () => {
  return (
    <PageWapper>
      <UpdatePolicyForm />
    </PageWapper>
  );
};

const PageWapper = styled.div`
  padding: 0 2rem 5rem 0;
  background-color: #ffffff;
  width: 100%;
  max-width: 1440px;
  height: 100%;
`;

export default ActivePolicyUpdates;
