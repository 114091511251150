import React from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, InputGroup, InputGroupAddon } from "reactstrap";
import { PolicyType } from "types/enums";

interface PropTypes {
  policy: Policy;
}

const CoverageInfoCard = ({ policy }: PropTypes) => {
  const policyInfo = policy || {};

  let coverageInfo = <React.Fragment />;
  switch (policyInfo.policyType) {
    case PolicyType.GL:
      coverageInfo = (
        <React.Fragment>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon id="limitLabel" addonType="prepend">
                Limit
              </InputGroupAddon>
              <Input
                value={`${policyInfo.glLimit} / ${policyInfo.glAggregateLimit} / ${policy.glAggregatePcoLimit}`}
                disabled={true}
                aria-labelledby="limitLabel"
              />
            </InputGroup>
          </FormGroup>
        </React.Fragment>
      );
      break;
    case PolicyType.BOP:
      coverageInfo = (
        <React.Fragment>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon id="buildingDeductLabel" addonType="prepend">
                Building Deductible
              </InputGroupAddon>
              <Input value={policyInfo.buildingDeductible} disabled={true} aria-labelledby="buildingDeductLabel" />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon id="bppDeductLabel" addonType="prepend">
                BPP Deductible
              </InputGroupAddon>
              <Input value={policyInfo.bppDeductible} disabled={true} aria-labelledby="bppDeductLabel" />
            </InputGroup>
          </FormGroup>
        </React.Fragment>
      );
      break;

    case PolicyType.PL:
      let { professionalLiability } = policyInfo;
      if (!professionalLiability) {
        professionalLiability = {
          deductibleAmount: undefined,
          occurrenceLimit: undefined,
          aggregateLimit: undefined,
        };
      }

      const { deductibleAmount, occurrenceLimit, aggregateLimit } = professionalLiability;

      coverageInfo = (
        <React.Fragment>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon id="deductibleLabel" addonType="prepend">
                Deductible
              </InputGroupAddon>
              <Input aria-labelledby="deductibleLabel" value={deductibleAmount} disabled={true} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon id="occurrenceLimitLabel" addonType="prepend">
                Occurrence Limit
              </InputGroupAddon>
              <Input aria-labelledby="occurrenceLimitLabel" value={occurrenceLimit} disabled={true} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon id="aggregateLimitLabel" addonType="prepend">
                Aggregate Limit
              </InputGroupAddon>
              <Input aria-labelledby="aggregateLimitLabel" value={aggregateLimit} disabled={true} />
            </InputGroup>
          </FormGroup>
        </React.Fragment>
      );
      break;
  }

  return (
    <Card data-cy={"coverage-info"}>
      <CardBody>
        <CardTitle>Coverage Info</CardTitle>
        {coverageInfo}
        <FormGroup>
          <InputGroup>
            <InputGroupAddon id="pdLiabDeductLabel" addonType="prepend">
              PD Liab. Deductible
            </InputGroupAddon>
            <Input
              aria-labelledby="pdLiabDeductLabel"
              value={`${policyInfo.propertyDamageLiabilityDeductible || 0}`}
              disabled={true}
            />
          </InputGroup>
        </FormGroup>
      </CardBody>
    </Card>
  );
};

export default CoverageInfoCard;
