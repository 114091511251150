import SmoothCollapse from "react-smooth-collapse";
import styled from "styled-components";

export const CenteredLoadingCont = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
`;

export const AddOnQuestionCont = styled.div`
  min-height: 72px;
  margin: 0;
  border-radius: 0px;
  border: none;
  background-color: ${(props) => props.theme.white};
  padding: 24px;
  border-bottom: 1px solid ${(props) => props.theme.disabledBackground};
  :last-of-type {
    padding-bottom: 0;
  }
`;

export const AddOnQuestionDesc = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
`;

export const QuestionRow = styled.div<{ isAddOn?: boolean }>`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  ${(props) =>
    props.isAddOn &&
    `
    align-items: center;
    margin-left: -48px;
    width: fit-content;
  `}
`;

export const StyledSmoothCollapse = styled(SmoothCollapse)`
  margin-top: 16px;
  padding: 18px 16px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.blue};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
`;
