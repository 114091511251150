import React, { useState } from "react";
import Modal from "shared/Modal";
import DatePicker from "react-date-picker";
import styled, { createGlobalStyle } from "styled-components";
import { ModalContentContainer, ModalField, WarningText, WarningContainer } from "shared/ModalComponents";
import { ReactComponent as CalendarIcon } from "images/calendar.svg";
import { StyledSelect } from "styles/Dropdown";
import { Dropdown } from "styles";
import { formatBackendPolicyDate } from "utils/formatBackendPolicyDate";

interface PropTypes {
  modalIsOpen: boolean;
  closeModal: () => void;
  cancelPolicy: (cancelDate: string) => void;
  policy: Policy;
}

const CancelModal = ({ policy, modalIsOpen, closeModal, cancelPolicy }: PropTypes) => {
  const [cancelDate, setCancelDate] = useState<any>(new Date());
  const [cancelReason, updateCancelReason] = useState<any>("");

  const cancelReasonOptions = [
    { value: "noBackdating", label: "Nonpayment of premium" },
    { value: "noBackdating", label: "Price" },
    { value: "noBackdating", label: "Customer product needs" },
    { value: "noBackdating", label: "Change in customer needs" },
    { value: "noBackdating", label: "Other" },
    { value: "yesBackdating", label: "Duplicate coverage exists" },
    { value: "yesBackdating", label: "Customer no longer in business" },
    { value: "yesBackdating", label: "Location or property sold" },
    { value: "yesBackdating", label: "Customer replaced coverage" },
    { value: "yesBackdating", label: "Coverage placed in error" },
  ];

  const minDate =
    cancelReason.value === "yesBackdating" ? formatBackendPolicyDate(new Date(policy.startDate)) : new Date();

  return (
    <Modal
      isOpen={modalIsOpen}
      closeModal={closeModal}
      label="cancel-policy"
      title="Cancel Policy"
      buttonText="Cancel policy"
      onSubmit={() => cancelPolicy(cancelDate)}
      disabled={!Boolean(cancelReason && cancelDate)}
    >
      <ModalContentContainer>
        <form data-testid="cancel-form">
          <Column>
            <OverrideStyle />
            <Dropdown
              name="cancel-reason"
              data-testid="cancel-reason"
              label="Cancellation Reason"
              onChange={updateCancelReason}
              options={cancelReasonOptions}
              value={cancelReason}
            />
          </Column>
          <Column>
            <ModalField>Effective Cancel Date</ModalField>
            <StyledDatePicker
              name="Date"
              className="cancel-date"
              value={cancelDate}
              onChange={setCancelDate}
              clearIcon={null}
              calendarIcon={
                <CalendarIconWrapper>
                  <CalendarIcon title="Calendar Icon" />
                </CalendarIconWrapper>
              }
              monthPlaceholder="MM"
              dayPlaceholder="DD"
              yearPlaceholder="YYYY"
              calendarType="US"
              disabled={cancelReason.value === "" || cancelReason === ""}
              minDate={new Date(minDate)}
            />
          </Column>
        </form>
        <WarningContainer>
          <WarningText>
            WARNING: Cancellation of a policy is final and cannot be undone. Once you click "Cancel Policy" this action
            will be taken immediately.
          </WarningText>
        </WarningContainer>
      </ModalContentContainer>
    </Modal>
  );
};

const CalendarIconWrapper = styled.div`
  svg {
    fill: ${(props) => props.theme.blue};
  }
`;

const StyledDatePicker = styled(DatePicker)`
  background: ${(props) => props.theme.white};
  height: 40px;
  margin-bottom: 25px;
`;

const OverrideStyle = createGlobalStyle`
  div${StyledSelect} {
    width: 100%;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default CancelModal;
