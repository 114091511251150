import { useQuery } from "@tanstack/react-query";
import useAuth from "../useAuth";
import Api from "utils/api";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const useGetClaims = () => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const getClaims = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api.claims.getClaims();
  };

  return useQuery({
    queryKey: ["claims"],
    queryFn: getClaims,
    select: (res) => res,
    retry: false,
  });
};

export default useGetClaims;
