export const managerOrLessorDefaultFormText =
  "Any person or organization with whom you agree in a written agreement for lease or rental of premises or building, prior to loss, to name as an additional insured in this Businessowners Coverage.";
export const lessorOfLeasedDefaultFormText =
  "Any person or organization with whom you agree in a written agreement for lease or rental of equipment, prior to loss, to name as an additional insured in this Businessowners Coverage.";

export const defaultBlanketDetail: EndorsementsType = {
  additionalInsuredsPrimaryAndNonContributory: true,
  additionalInsuredsWaiverOfTransfer: true,
  additionalInsuredsConstructionContractRequirementEndorsement: {
    // TODO this should become boolean when BE is updated
    formText: "<PLACEHOLDER_FOR_CONTRACT_REQUIREMENT_ENDORSEMENT>",
    numAddInsOwnerWithReq: 1,
  },
  additionalInsuredsManagersOrLessorsPremisesEndorsement: {
    formText: managerOrLessorDefaultFormText,
    numAddInsManagerPerApartment: 1,
    numAddInsManagerOther: 1,
  },
  additionalInsuredsLessorOfLeasedEquipmentEndorsement: {
    formText: lessorOfLeasedDefaultFormText,
    numAddInsLeasedEquipPerApartment: 1,
    numAddInsLeasedEquipOther: 1,
  },
};

export const defaultIndividual: AdditionalInsured = {
  name: "",
  email: "",
  street: "",
  city: "",
  state: "",
  zip: "",
};

export const BLANKET_TEXT = {
  PRIMARY: "Primary and Non-Contributory",
  SUBROGATION: "Waiver of Subrogation",
  CONSTRUCTION: "Construction Contract Requirement AI",
  PREMISES: "Managers or Lessors Premises AI",
  EQUIPMENT: "Lessor of Leased Equipment AI",
};

export const blanketOptions: { question: string; label: keyof EndorsementsType }[] = [
  {
    question: BLANKET_TEXT.PREMISES,
    label: "additionalInsuredsManagersOrLessorsPremisesEndorsement",
  },
  { question: BLANKET_TEXT.EQUIPMENT, label: "additionalInsuredsLessorOfLeasedEquipmentEndorsement" },
  {
    question: BLANKET_TEXT.CONSTRUCTION,
    label: "additionalInsuredsConstructionContractRequirementEndorsement",
  },
  { question: BLANKET_TEXT.PRIMARY, label: "additionalInsuredsPrimaryAndNonContributory" },
  { question: BLANKET_TEXT.SUBROGATION, label: "additionalInsuredsWaiverOfTransfer" },
];
