import React from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import ConfirmationDetails from "./ConfirmationDetails";
import AjaxLoader from "shared/AjaxLoader";
import { BreadcrumbContainer } from "styles/containerStyledComponents";
import getQueryParam from "utils/getQueryParam";
import useGetPolicy from "hooks/useGetPolicy";

interface RouteParams {
  policyNumber: string;
  accountId: string;
}

const Confirmation = ({ location }: RouteComponentProps<RouteParams>) => {
  const policyNumberQueryParam = getQueryParam(location, "policyIds");
  const policyNumbers = policyNumberQueryParam?.split(",");
  const hasAPolicy = policyNumbers && policyNumbers?.length > 0;
  const { data: policies, isLoading: isLoadingPolicies, isFetched } = useGetPolicy(policyNumbers, {
    enabled: hasAPolicy,
  });

  if (isLoadingPolicies && !isFetched) {
    return <AjaxLoader />;
  }

  const initialPolicy = Array.isArray(policies) ? policies[0] : [];
  if (!initialPolicy.policyNumber) {
    return <p>Not found.</p>;
  }

  return (
    <>
      <BreadcrumbContainer>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/accounts">Policy Search</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{initialPolicy.businessName}</BreadcrumbItem>
        </Breadcrumb>
      </BreadcrumbContainer>
      {initialPolicy.policyNumber && <ConfirmationDetails policies={policies as any} initialPolicy={initialPolicy} />}
    </>
  );
};

export default Confirmation;
