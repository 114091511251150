import React, { useContext } from "react";
import { LogicBlockHeader, DeleteLogicBlockButtonContainer, AttributeBlock } from "../styles";
import { RuleBuilderContext } from "../RuleBuilderContext";
import DeleteButton from "../DeleteButton";
import Dropdown, { OptionType, dropdownStyles } from "styles/Dropdown";
import useGetUnderwritingRuleTags from "hooks/underwritingRules/useGetUnderwritingRuleTags";
import { arrayToDropdownOptionsList } from "../../Shared/utils";
import { theme } from "styles";
import styled from "styled-components";

const TagsBlock = () => {
  const { updateLogicBlock, deleteTagBlock, activeLogicBlock, rule, setTags } = useContext(RuleBuilderContext);
  const isSelected = activeLogicBlock === "tags";

  const { data: existingTags } = useGetUnderwritingRuleTags();

  return (
    <>
      <LogicBlockHeader>Add Tags...</LogicBlockHeader>
      <AttributeBlock
        onFocus={() => updateLogicBlock("tags")}
        onClick={() => updateLogicBlock("tags")}
        isSelected={isSelected}
      >
        <InnerWrapper>
          <Dropdown
            options={arrayToDropdownOptionsList(existingTags)}
            label="Tags Search"
            onChange={(selection) => setTags(selection?.map((option: OptionType) => option.value) ?? [])}
            multiValues={arrayToDropdownOptionsList(rule.tags ?? [])}
            isCreatable
            isMulti
            full
            customStyles={DropdownStyles}
            labelStyles={{ fontSize: "12px" }}
          />
          <DeleteLogicBlockButtonContainer>
            <DeleteButton
              ariaLabel="delete tags"
              isDisabled={!isSelected}
              onClick={() => deleteTagBlock()}
            ></DeleteButton>
          </DeleteLogicBlockButtonContainer>
        </InnerWrapper>
      </AttributeBlock>
    </>
  );
};

export const DropdownStyles = {
  ...dropdownStyles,
  multiValue: (styles: any) => ({
    ...styles,
    marginRight: "10px",
    border: `1px solid ${theme.blue}`,
    borderRadius: "4px",
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    background: theme.blue10,
    color: theme.blue,
    fontSize: "11px",
    fontWeight: 600,
    letterSpacing: "1px",
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    background: theme.blue10,
    color: theme.blue,
    fontSize: "12px",
    fontWeight: 600,

    ":hover": {
      ...styles[":hover"],
      background: theme.blue10,
      color: theme.blue,
      cursor: "pointer",
    },
  }),
};

const InnerWrapper = styled.div`
  background: ${(props) => props.theme.white};
  padding: 1em;
  border-radius: 4px;
`;

export default TagsBlock;
