import { useFlags } from "launchdarkly-react-client-sdk";
import { cloneDeep, isEqual, isObject, debounce } from "lodash";
import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import ReactModal from "react-modal";
import { toastSuccess, toastError } from "utils/toast";
import {
  TitleBar,
  ItemCont,
  ToolsBtn,
  Row,
  ControlsCont,
  Block,
  RawEdit,
  TextInput,
  CloseButton,
  ContentCont,
  PrimaryButton,
  TextButton,
  GhostButton,
  ToolsIcon,
  RawEditToggle,
  ModalContents,
} from "./styles";
import lightCloseButton from "./icons/lightCloseButton.svg";
import feature_flag_tools from "./icons/feature_flag_tools.png";
import { Switch } from "styles";
import { ErrorText } from "styles/Input";
import CoterieTool from "../coterie_tool/coterieTool";

export const FeatureFlagContext = createContext({});

const UpdateFlag = ({ flags, flag, handleUpdate }: any) => {
  const [value, setValue] = useState(flags[flag]);

  const handleCheckboxOnChange = () => {
    setValue((value: boolean) => !value);
    handleUpdate(flag, !value);
    toastSuccess(`The ${flag} flag has been turned ${value ? "off" : "on"}`);
  };

  const handleTextUpdate = (flag: string, value: string) => {
    handleUpdate(flag, value);
    toastSuccess(`The ${flag} flag has been changed to ${value}`);
  };

  const handleTextOnChange = useCallback(
    debounce((flag, value) => {
      handleTextUpdate(flag, value);
    }, 500),
    []
  );

  switch (typeof flags[flag]) {
    case "boolean":
      return (
        <div style={{ display: "flex" }}>
          <p style={{ margin: "auto", marginRight: "10px" }}>Off</p>
          <Switch
            ariaLabel={`change ${flag} to ${!value}`}
            dataTestId={`${flag}-checkbox`}
            checked={value}
            onChange={handleCheckboxOnChange}
          />
          <p style={{ margin: "auto", marginLeft: "10px" }}>On</p>
        </div>
      );
    case "string":
      return (
        <TextInput
          aria-label={`change ${flag} value`}
          type="text"
          placeholder={value}
          onChange={(e: any) => handleTextOnChange(flag, e.target.value)}
        />
      );
    default:
      return <span>Use raw edit</span>;
  }
};

const FeatureFlagTools = ({ flags, flagOverrides, setFlagOverrides }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [flagsEdit, setFlagsEdit] = useState({ ...flags, ...flagOverrides });
  const [rawFlags, setRawFlags] = useState(JSON.stringify({ ...flags, ...flagOverrides }, undefined, 2));
  const [rawEditError, setRawEditError] = useState("");
  const [showRawEdit, setShowRawEdit] = useState(false);
  const [, setStoredOverrides] = useLocalStorage("overrides", {});
  const flagList = Object.keys(flags).filter((flag) => flag !== "enableFeatureFlagTools");

  useEffect(() => {
    setFlagsEdit({ ...flags, ...flagOverrides });
    setRawFlags(JSON.stringify({ ...flags, ...flagOverrides }, undefined, 2));
  }, [flagOverrides, flags]);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleUpdate = (flag: string, value: any) => {
    const newState = {
      ...flagsEdit,
      ...flagOverrides,
      [flag]: value,
    };
    setFlagsEdit(newState);
    setFlagOverrides(newState);
    setStoredOverrides(newState);
  };

  const handleRawEditOnChange = (e: any) => {
    setRawFlags(e.target.value);
  };

  const handleSaveRawEdit = () => {
    try {
      const newValue = JSON.parse(rawFlags);
      if (Object.keys(newValue).length === Object.keys(flags).length) {
        setFlagsEdit(newValue);
        setRawEditError("");
        toastSuccess("Raw edit is saved.");
      } else {
        setRawEditError("Cannot add or remove flags in dev tools");
        toastError("Cannot add or remove flags in dev tools");
      }
    } catch (e) {
      setRawEditError("Must be valid JSON");
      toastError("Must be valid JSON");
    }
  };

  const handleReset = (flag: string) => {
    const resetFn = (flagOverrides: any) => {
      const flagOverridesCopy = cloneDeep(flagOverrides);
      delete flagOverridesCopy[flag];

      return flagOverridesCopy;
    };
    setFlagOverrides(resetFn);
    setStoredOverrides(resetFn);
    toastSuccess(`The ${flag} flag has been reset to its original status`);
  };

  const handleResetAll = () => {
    setFlagOverrides({});
    setStoredOverrides({});
    setRawEditError("");
    toastSuccess("All Feature Flags have been reset to their original status");
  };

  const isProdOrSandbox =
    process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "sandbox";

  const isEnabledOnSandbox = process.env.REACT_APP_ENVIRONMENT === "sandbox" && flags["enableFeatureFlagTools"];

  return (
    <>
      {!isProdOrSandbox || isEnabledOnSandbox ? (
        <ToolsBtn data-testid="open_feature_flag_dev_tools" onClick={handleOpen}>
          <ToolsIcon src={feature_flag_tools} alt="feature flag dev tools" />
        </ToolsBtn>
      ) : null}
      <ReactModal
        isOpen={isOpen}
        onRequestClose={handleClose}
        style={{
          content: {
            position: "absolute",
            top: "5%",
            left: "20%",
            right: "20%",
            bottom: "15%",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "0",
          },
        }}
      >
        <ModalContents>
          <div>
            <CloseButton aria-label="close dev tools" onClick={handleClose}>
              <img alt="close icon" src={lightCloseButton} />
            </CloseButton>
            <TitleBar>Feature Flag Dev Tools</TitleBar>
          </div>
          <ContentCont>
            <Row>
              <Block>
                <h3>Flags</h3>
              </Block>
              <Block>
                <h3>Values</h3>
              </Block>
              <Block>
                <h3>Overrides</h3>
              </Block>
              <Block>
                <h3>Update</h3>
              </Block>
              <Block halfWidth={true}>
                <h3>Reset</h3>
              </Block>
            </Row>
            <ItemCont>
              {flagList.map((flag) => {
                const isFlagValueObj = isObject(flags[flag]) && !Array.isArray(flags[flag]);
                const areObjectsEqual = isEqual(flags[flag], flagOverrides[flag]);
                const flagValueStringified = flags[flag]?.toString();
                const objectOverrideMsg =
                  areObjectsEqual || !Boolean(flagOverrides[flag]) ? "no override" : "Obj is overridden";
                const overrideValueStringified = isFlagValueObj ? objectOverrideMsg : flagOverrides[flag]?.toString();
                const flagValue = isFlagValueObj ? "Object" : flagValueStringified;
                const hasOverride =
                  (flagOverrides[flag] != null && flags[flag].toString() !== flagOverrides[flag].toString()) ||
                  (isFlagValueObj && !areObjectsEqual);
                const overrideValue = hasOverride ? overrideValueStringified : "no override";

                return (
                  <Row key={`${flag}_${flagValue}_${overrideValue}`}>
                    <Block>{flag}</Block>
                    <Block>{flagValue}</Block>
                    <Block>{overrideValue}</Block>
                    <Block style={{ display: "flex", alignContent: "center", flexWrap: "wrap" }}>
                      <UpdateFlag flags={{ ...flags, ...flagOverrides }} flag={flag} handleUpdate={handleUpdate} />
                    </Block>
                    <Block halfWidth={true}>
                      <TextButton aria-label={`reset ${flag} flag`} onClick={() => handleReset(flag)}>
                        Reset
                      </TextButton>
                    </Block>
                  </Row>
                );
              })}
            </ItemCont>
            <RawEditToggle
              data-testid="open_feature_flag_dev_tools_raw_edit"
              onClick={() => setShowRawEdit(!showRawEdit)}
            >
              Toggle Raw Edit
            </RawEditToggle>
            {showRawEdit && (
              <>
                <h2>Raw Edit</h2>
                <RawEdit
                  aria-label={"edit raw data"}
                  value={rawFlags}
                  onChange={handleRawEditOnChange}
                  hasError={Boolean(rawEditError)}
                />
                {rawEditError && <ErrorText style={{ color: "red" }}>{rawEditError}</ErrorText>}
                <PrimaryButton onClick={handleSaveRawEdit}>Save Raw Edits</PrimaryButton>
              </>
            )}
          </ContentCont>
          <ControlsCont>
            <GhostButton onClick={handleResetAll}>Reset All</GhostButton>
          </ControlsCont>
        </ModalContents>
      </ReactModal>
    </>
  );
};

/**
 * useFeatureFlags is a custom hook which returns all feature flags with local overrides (using Feature Flag Dev Tools in lower environments).
 * It uses the useContext primitive to access the LaunchDarkly context set up by withLDProvider.
 * As such you will still need to use the withLDProvider HOC at the root of your app to initialize the React SDK and populate the context with ldClient and your flags.
 * @return — All the feature flags configured in your LaunchDarkly project with flag overrides
 */
export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error("useFeatureFlag hook must be rendered within the FeatureFlagProvider");
  }

  return context as any;
};

const useLocalStorage = (storageKey: string, fallbackState: any) => {
  const prevStoredVal = localStorage.getItem(storageKey);
  const [value, setValue] = useState(prevStoredVal ? JSON.parse(prevStoredVal) : fallbackState);

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};

interface FeatureFlagProviderProps {
  children?: React.ReactNode;
}

export const FeatureFlagProvider = (props: FeatureFlagProviderProps) => {
  const flags = useFlags();
  const [storedOverrides] = useLocalStorage("overrides", {});
  const [flagOverrides, setFlagOverrides] = useState(storedOverrides);

  const value = {
    ...flags,
    ...flagOverrides,
  };

  return (
    <FeatureFlagContext.Provider value={value}>
      {props.children}
      <FeatureFlagTools flags={flags} flagOverrides={flagOverrides} setFlagOverrides={setFlagOverrides} />
      <CoterieTool />
    </FeatureFlagContext.Provider>
  );
};
