import React, { useState } from "react";
import OneTimePayment from "./OneTimePayment";
import ConfirmationModal, { PaymentResponse } from "./ConfirmationModal";
import PaymentConfirmationModal from "./PaymentConfirmationModal";

interface PropTypes {
  modalIsOpen: boolean;
  onRequestClose: () => void;
  modalTitle: string;
  policy: Policy;
  transactionFeeAmount?: number;
}

const OneTimePaymentModal = ({ onRequestClose, modalIsOpen, modalTitle, policy, transactionFeeAmount }: PropTypes) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isPaymentConfirmationModalOpen, setIsPaymentConfirmationModalOpen] = useState(false);
  const [chargeRequestData, setChargeRequestData] = useState({
    apiPost: { amount: 0, sourceId: "", updatePayment: false },
    lastFourDigits: "",
  });
  const [chargeResponse, setChargeResponse] = useState<PaymentResponse | undefined>(undefined);

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  return (
    <>
      <OneTimePayment
        onRequestClose={onRequestClose}
        setChargeRequestData={setChargeRequestData}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        modalTitle={modalTitle}
        modalIsOpen={modalIsOpen}
        policyNumber={policy.policyNumber}
        transactionFeeAmount={transactionFeeAmount}
      />

      <ConfirmationModal
        isConfirmationModalOpen={isConfirmationModalOpen}
        closeConfirmationModal={closeConfirmationModal}
        chargeRequestData={chargeRequestData}
        policyNumber={policy.policyNumber}
        openPaymentConfirmationModal={() => setIsPaymentConfirmationModalOpen(true)}
        transactionFeeAmount={transactionFeeAmount}
        setChargeResponse={(data: any) => setChargeResponse(data)}
      />

      <PaymentConfirmationModal
        paymentResponse={chargeResponse}
        isPaymentConfirmationModalOpen={isPaymentConfirmationModalOpen}
        closePaymentConfirmationModal={() => setIsPaymentConfirmationModalOpen(false)}
        paymentAmount={chargeRequestData.apiPost.amount}
        transactionFeeAmount={transactionFeeAmount}
      />
    </>
  );
};

export default OneTimePaymentModal;
