import React from "react";
import ClipboardInput from "shared/KITClipboardInput";
import useAuth from "hooks/useAuth";

const ApiKey = () => {
  const { user } = useAuth();

  const renderKey = (name: string, apiKey: string) => {
    return (
      <div>
        <label>{name}</label>
        <div>
          <ClipboardInput style={{ width: "30em" }} name={name} text={apiKey || "Loading..."} />
        </div>
      </div>
    );
  };

  let publishableKey = "";
  let secretKey = "";
  if (user && user.channelPartner) {
    publishableKey = user.channelPartner.publishableKey;
    secretKey = user.channelPartner.secretKey;
  }
  return (
    <div style={{ width: "100%" }}>
      {renderKey("Publishable Key", publishableKey)}
      <br />
      {renderKey("Secret Key", secretKey)}
    </div>
  );
};

export default ApiKey;
