import React from "react";
import { ActionContainer, Title } from "shared/ModalComponents";
import { FailFastContainer, StatusMessage } from "./styles";
import { GreenButton } from "styles";

interface PropTypes {
  resetIQC: () => void;
}

const Denied = ({ resetIQC }: PropTypes) => (
  <>
    <FailFastContainer data-cy="fail-fast-container">
      <Title>Not Qualified</Title>
      <StatusMessage>
        Based on the information you have provided to us, we believe that this business does not fit within our
        appetite. Please try again or give a call to our customer service team for assistance at (513)440-4044.
      </StatusMessage>
    </FailFastContainer>
    <ActionContainer>
      <GreenButton data-cy="denied-close-button" onClick={() => resetIQC()} active>
        <span>Close</span>
      </GreenButton>
    </ActionContainer>
  </>
);

export default Denied;
