import axios, { AxiosInstance } from "axios";
import { CancelRequest } from "ia/Policy/Cancel/types";
import { PolicySortBy, SortDirection, AgentSortBy, AffiliatedStatus, CoterieAPIVersion } from "types/enums";

declare global {
  interface Window {
    purgeState: () => Promise<string>;
  }
}

export const coterieAPIBaseURL = (apiVersion: CoterieAPIVersion) => {
  return `${process.env.REACT_APP_API_BASE_URL}/${apiVersion}`;
};

export default class Api {
  private portalApi: AxiosInstance;
  private api: AxiosInstance;
  private api_v1_4: AxiosInstance;
  private api_underwriting: AxiosInstance;

  constructor(apiVersion?: string, token?: any) {
    this.portalApi = axios.create({
      baseURL: `${process.env.REACT_APP_PORTAL_API_BASE_URL}/${apiVersion}`,
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });

    // TODO: replace this token with channel partner token
    this.api = axios.create({
      baseURL: coterieAPIBaseURL(CoterieAPIVersion.v1),
      headers: {
        Authorization: "token f6f8a0bc-ae66-4e92-87a8-ffcdd6e0afa5",
      },
    });

    this.api_v1_4 = axios.create({
      baseURL: coterieAPIBaseURL(CoterieAPIVersion.v1_4),
      headers: {
        Authorization: "token f6f8a0bc-ae66-4e92-87a8-ffcdd6e0afa5",
      },
    });

    this.api_underwriting = axios.create({
      baseURL: process.env.REACT_APP_UW_RULES_ENGINE_API_BASE_URL,
      headers: {
        Authorization: "token a3e88b39-b569-44ef-aa80-d136f58dcb6b",
      },
    });

    // Handle errors
    this.portalApi.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          //LOGOUT
          window.location.replace("/logout");
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  getPolicyDocumentList = (policyNumber: string, apiVersion: string): Promise<PolicyDocumentLink[]> => {
    return this.portalApi
      .get(`/policies/${policyNumber}/docs`)
      .then((res) => {
        return res.data.map((doc: PolicyDocumentLink) => {
          return { link: `${process.env.REACT_APP_PORTAL_API_BASE_URL}/${apiVersion}${doc.link}`, name: doc.name };
        });
      })
      .catch((err) => Promise.reject(err));
  };

  addCharge = (policyNumber: string, charge: Charge) => {
    return this.portalApi
      .post(`/policies/${policyNumber}/charges`, charge)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  getCharges = () => {
    return this.portalApi
      .get(`/charges`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  getCommissions = (channelPartnerId: number, query?: CommissionsQueryParams): Promise<CommissionResponse> => {
    return this.portalApi
      .get(`/channelPartners/${channelPartnerId}/commissions`, { params: query })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  policySearch = (
    searchText: string,
    sortBy: PolicySortBy = PolicySortBy.PolicyNumber,
    sortDirection: SortDirection = SortDirection.Descending
  ) => {
    return this.portalApi
      .get(`/policies/search?query=${searchText}&sort_by=${sortBy}&sort_direction=${sortDirection}&limit=250`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  agentSearch = (
    searchText: string,
    sortBy: AgentSortBy = AgentSortBy.Name,
    sortDirection: SortDirection = SortDirection.Descending,
    page: number = 1,
    limit: number = 25
  ) => {
    return this.portalApi
      .get(
        `/accounts/search/agents?query=${searchText}&sort_by=${sortBy}&sort_direction=${sortDirection}&page=${page}&limit=${limit}`
      )
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  getPolicy = (policyNumber: string) => {
    return this.portalApi
      .get(`/policies/${policyNumber}`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  getOpenPolicies = () => {
    return this.portalApi
      .get(`/policies/open`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  getOpenProjectPolicies = () => {
    return this.portalApi
      .get(`/policies/open?project=true`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  getFailedTransactions = (id?: string) => {
    return this.portalApi
      .get(`/charges/failed`, { params: id ? { producerExternalId: id } : {} })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  getPolicyCharges = (policyNumber: string) => {
    return this.portalApi
      .get(`/policies/${policyNumber}/charges`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  getPaymentSummary = (policyNumber: string) => {
    return this.portalApi
      .get(`/policies/${policyNumber}/paymentSummary`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err.response));
  };

  updatePaymentMethod = (accountId: number, paymentRequest: UpdatePaymentMethodRequest) => {
    return this.portalApi
      .put(`/accounts/policyholders/${accountId}/paymentMethod`, paymentRequest)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err.response));
  };

  addOneTimePaymentCharge = (data: { policyNumber: string; apiData: OneTimePaymentPayload }) => {
    return this.portalApi
      .post(`/policies/${data.policyNumber}/charge`, data.apiData)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  getAuditTrail = (policyNumber: string) => {
    return this.portalApi
      .get(`/policies/${policyNumber}/audit`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  getAccount = (accountId: string) => {
    return this.portalApi
      .get(`/accounts/${accountId}`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  getAccountPolicies = (accountId: string) => {
    return this.portalApi
      .get(`/accounts/${accountId}/policies`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  getInsuranceProducts = () => {
    return this.api
      .get(`/commercial/products`)
      .then((res) =>
        res.data.reduce((obj: any, policy: any) => {
          obj[policy.name] = policy.statesCovered;
          return obj;
        }, {})
      )
      .catch((err) => Promise.reject(err));
  };

  getAccountCharges = (accountId: string, createdAfter: string) => {
    return this.portalApi
      .get(`/accounts/${accountId}/charges?createdAfter=${createdAfter}`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  createAccountKit = (email: string, password: string, name: string) =>
    this.portalApi
      .post("/accounts", { email, password, name })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));

  // accountType: ChannelPartner = 0, Agency = 1, Producer = 2
  createAccountDashboard = (request: CreateAccountRequest) =>
    this.portalApi
      .post("/accounts/ia", request)
      .then((res) => Promise.resolve(res.data))
      .catch((err) => Promise.reject(err));

  createAccountSelfService = (request: CreateAccountRequest) =>
    this.portalApi
      .post("/accounts/ia/selfservice", request)
      .then((res) => Promise.resolve(res.data))
      .catch((err) => Promise.reject(err));

  getCurrentUser = () =>
    this.portalApi
      .get("/users/current")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          //LOGOUT
          window.location.replace("/logout");
        } else {
          return Promise.reject(err);
        }
      });

  updateCurrentUserInfo = async (accountId: string, currentUserRequest: UpdateCurrentUserRequest) => {
    await this.portalApi
      .put(`/accounts/${accountId}/update`, currentUserRequest)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => Promise.reject(err));
  };

  updateAgent = (accountId: number, agentRequest: UpdateAgentRequest) =>
    this.portalApi
      .put(`/accounts/${accountId}/update`, agentRequest)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => Promise.reject(err));

  updateAccount = (accountId: number, accountRequest: UpdateAccountRequest) =>
    this.portalApi
      .patch(`/accounts/policyholders/${accountId}`, accountRequest)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => Promise.reject(err));

  updateAgentAffiliation = (accountId: string, affiliatedStatus: AffiliatedStatus) =>
    this.portalApi
      .put(`/accounts/${accountId}/setAffiliated`, { affiliatedStatus })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => Promise.reject(err));

  requestPasswordReset = (accountId: string) =>
    this.portalApi
      .post(`/accounts/${accountId}/resetPassword`, {})
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => Promise.reject(err));

  chargePolicyHolder = (asOfDate: string, payerAccountId: string) =>
    this.portalApi
      .post("/eventQueue/chargePolicyHolder", { asOfDate, payerAccountId })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));

  chargeChannelPartner = (asOfDate: string, payerAccountId: string) =>
    this.portalApi
      .post("/eventQueue/chargeChannelPartner", { asOfDate, payerAccountId })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));

  requeueError = (name: string) =>
    this.portalApi
      .post(`/eventQueue/requeue/${name}`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));

  requeueAllErrors = () =>
    this.portalApi
      .post(`/eventQueue/errors`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));

  updatePolicy = (policyNumber: string, data: PolicyChangeRequest) =>
    this.portalApi
      .request({
        method: "PUT",
        url: `/policies/${policyNumber}/update`,
        data,
      })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));

  quotePolicyUpdate = (policyNumber: string, data: PolicyChangeRequest) =>
    this.portalApi
      .request({
        method: "POST",
        url: `/policies/${policyNumber}/quote`,
        data,
      })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));

  sendPolicyDocs = (policyNumber: string) =>
    this.portalApi
      .request({
        method: "POST",
        url: `/policies/${policyNumber}/send-docs`,
        data: {},
      })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));

  cancelPolicy = (policyNumber: string, data: any) => {
    return this.portalApi
      .request({
        method: "POST",
        url: `/policies/${policyNumber}/cancel`,
        data,
      })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  industry = {
    get: (akHash?: string) => {
      return this.api
        .get(`/commercial/industries/akHash/${akHash}`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };

  application = {
    post: (application: Application) => {
      return this.portalApi
        .post("/applications", application)
        .then((res) => res.data as ApplicationResponse)
        .catch((err) => Promise.reject(err));
    },

    get: (applicationId: string) => {
      return this.portalApi
        .get(`/applications/${applicationId}`)
        .then((res) => res.data as ApplicationResponse)
        .catch((err) => Promise.reject(err));
    },

    archive: (applicationIds: any) => {
      const data = {
        ApplicationIds: applicationIds,
      };
      return this.portalApi
        .put(`/applications/archive`, data)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },

    update: (application: Application) => {
      return this.portalApi
        .post("/applications", application)
        .then((res) => res.data as ApplicationResponse)
        .catch((err) => Promise.reject(err));
    },

    bind: (data: BindRequest) => {
      return this.portalApi
        .post(`/quotes/${data.quoteId}/bind?sendDocs=${data.sendDocs}&producerId=${data.producerId}`, data.paymentInfo)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },

    bindQuoteWithStripe: (data: BindRequestWithStripe) => {
      const payloadProducerOrAgencyId = data.producerExternalId
        ? { producerExternalId: `producer_${data.producerExternalId}` }
        : { agencyId: process.env.REACT_APP_COTERIE_AGENCY_EXTERNAL_ID };

      const requestPayload = {
        TokenizedPaymentId: data.tokenizedPaymentId,
        PaymentInterval: data.paymentInterval || "None",
        ...payloadProducerOrAgencyId,
      };
      const requestPayloadSnapshot = {
        ...requestPayload,
        source: "Snapshot",
        producerExternalId: data.producerExternalId,
      };

      if (data.isSnapshot) {
        return this.api_v1_4
          .post(`/commercial/quotes/${data.quoteId}/bind`, requestPayloadSnapshot, {
            headers: {
              Authorization: `token ${data.channelPartnerPublishableKey}`,
            },
          })
          .then((res) => res.data)
          .catch((err) => Promise.reject(err));
      } else {
        return this.portalApi
          .post(
            `/quotes/${data.quoteId}/bind?sendDocs=${data.sendDocs}&producerId=${data.producerId}&source=${data.source}`,
            requestPayload
          )
          .then((res) => res.data)
          .catch((err) => Promise.reject(err));
      }
    },

    getQuote: (application: Application) => {
      return this.portalApi
        .post("/quotes", application)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },

    publicGetQuote: (data: Application, token?: string) => {
      return this.api_v1_4
        .post("/commercial/quotes/bindable", data, {
          headers: {
            Authorization: `token ${token}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },

    getEstimateQuote: (application: Application) => {
      return this.portalApi
        .post("/quotes/estimate", application)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },

    publicPostApplication: async (application: Application, token?: string) => {
      return this.api_v1_4
        .post("/commercial/applications", application, {
          headers: {
            Authorization: `token ${token}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };

  address = {
    verify: (address: string) => {
      return this.api
        .get(`/commercial/address/verify/${encodeURIComponent(address)}`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    typeAhead: (address: string) => {
      return this.api_v1_4
        .get(`/commercial/address/typeahead/${encodeURIComponent(address)}`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    fetchEntries: (address: any) => {
      return this.api_v1_4
        .get(
          `/commercial/address/entries?street=${encodeURIComponent(address.street)}&secondary=${encodeURIComponent(
            address.secondary
          )}&entries=${encodeURIComponent(address.entries)}&city=${encodeURIComponent(
            address.city
          )}&state=${encodeURIComponent(address.state)}&zipCode=${encodeURIComponent(address.zipCode)}`
        )
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };

  // Coterie API methods for Developer tools

  endPolicy = (policyNumber: string, token: string) =>
    this.api
      .request({
        method: "POST",
        url: `/commercial/policies/${policyNumber}/end`,
        headers: {
          Authorization: `token ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));

  policies = {
    updateSettings: (policyNumber: string, data: PolicySettings) => {
      return this.portalApi
        .request({
          method: "PUT",
          url: `/policies/${policyNumber}/settings`,
          data,
        })
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    renew: (policyNumber: string, data: RenewData) => {
      return this.portalApi
        .request({
          method: "POST",
          url: `/policies/${policyNumber}/renew`,
          data,
        })
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    detach: (policyNumber: string, data: DetachData) => {
      return this.portalApi
        .put(`/policies/${policyNumber}/detach`, data)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    detachPreview: (policyNumber: string, data: DetachPreviewData) => {
      return this.portalApi
        .post(`/policies/${policyNumber}/detach/preview`, data)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };

  logs = {
    get: (search: string | null) => {
      return this.portalApi
        .get(`logs?q=${search}`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    getThirdParty: (service: string | null) => {
      return this.portalApi
        .get(`logs/thirdParty?q=${service}`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };

  agencies = {
    get: (agencyId: number) => {
      return this.portalApi
        .get(`/agencies/${agencyId}`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },

    getByChannelPartner: (channelPartnerId: number) => {
      return this.portalApi
        .get(`/agencies?channelPartnerId=${channelPartnerId}`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },

    update: (agency: AgencyType) => {
      return this.portalApi
        .put(`/agencies/${agency.id}`, agency)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };

  producers = {
    get: (agencyId: number, withUnlicensed: boolean = false) => {
      return this.portalApi
        .get(`/agencies/${agencyId}/producers${withUnlicensed ? "?license=false" : ""}`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };

  channelPartners = {
    getAll: () => {
      return this.portalApi
        .get(`/channelpartners`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    getAllIA: () => {
      return this.portalApi
        .get(`/channelpartners/ia`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    create: (channelPartnerRequest: CreateChannelPartnerRequest) => {
      return this.portalApi
        .post(`/channelpartners`, channelPartnerRequest)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    update: (partnerId: number, channelPartnerRequest: UpdateChannelPartnerRequest) => {
      return this.portalApi
        .put(`/channelpartners/${partnerId}`, channelPartnerRequest)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };

  affiliation = {
    post: (affiliateFormData: any) => {
      return this.portalApi
        .post("/affiliation", affiliateFormData, { headers: { "Content-Type": "multipart/form-data" } })
        .then((res) => res)
        .catch((err) => Promise.reject(err));
    },
  };

  verifyNPN = (nationalProducerNumber: string) => {
    return this.portalApi
      .get(`/validation/nipr?npn=${nationalProducerNumber}`)
      .then((res) => res.data)
      .catch((err) => {
        return Promise.reject(err);
      });
  };

  coterieHash = {
    post: (appetiteFormData: any) => {
      return this.portalApi
        .post("/underwritingAppetite/file", appetiteFormData)
        .then((res) => res)
        .catch((err) => Promise.reject(err));
    },
  };

  availableEndorsements = (application: Application) => {
    return this.api_v1_4
      .post("/commercial/applications/availableEndorsements", application)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  agencyLogo = {
    get: (agencyId: string) => {
      return this.portalApi
        .get(`/logo/${agencyId}/metadata`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    put: (agencyId: string, logo: FormData) => {
      return this.portalApi
        .put(`/logo/${agencyId}`, logo)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    delete: (agencyId: string) => {
      return this.portalApi
        .delete(`/logo/${agencyId}`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    publicGet: (applicationId: string) => {
      return this.api_v1_4
        .get(`/commercial/logos/${applicationId}`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };

  underwriting = {
    put: (underwritingProfile: any) => {
      return this.portalApi
        .put("/underwriting", underwritingProfile)
        .then((res) => res?.data)
        .catch((err) => Promise.reject(err));
    },
    prebind: (underwritingProfileId: string) => {
      return this.portalApi
        .get(`/underwriting/${underwritingProfileId}/?afterAction=PreBind`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };

  renewals = {
    getRenewals: async (renewalParams: RenewalParams) => {
      const { orderBy, PageSize, producerExternalId, startDate, endDate, orderByAscending, PageNumber } = renewalParams;
      return await this.portalApi
        .get(
          `/renewals?orderBy=${orderBy}
          &producerExternalId=${producerExternalId}
          &startDate=${startDate}&endDate=${endDate}
          &orderByAscending=${orderByAscending}
          &PageSize=${PageSize}
          &PageNumber=${PageNumber}`
        )
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };

  cancel = {
    getCancelReasons: async (): Promise<{ code: string; description: string }[]> => {
      return await this.portalApi
        .get(`/policyAdmin/cancellationReasons`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    getCancelPreview: async (policyNumber: string, cancelDate: string): Promise<CancelPreviewResponse> => {
      return await this.portalApi
        .get(`/policies/${policyNumber}/cancel/preview?cancelDate=${cancelDate}`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    createCancelRequest: async (policyNumber: string, request: CancelRequest): Promise<{ cancelRequestId: string }> => {
      return await this.portalApi
        .post(`/policyAdmin/${policyNumber}/cancel/request`, request)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };

  claims = {
    getClaims: async () => {
      return this.portalApi
        .get(`/Claims/list`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    createClaim: async (claimData: ClaimPostData, token: string) => {
      return this.portalApi
        .post(`/claims/createClaim`, claimData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    deleteClaim: async (id: string) => {
      return this.portalApi
        .delete(`/claims/${id}`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };

  validateEmail = async (email: string) => {
    return this.portalApi
      .get(`/validation/${email}/validate`)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  getRules = async () => {
    return await this.api_underwriting
      .get("/rule/export")
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  };

  dashboardBilling = {
    getBillingBalance: async (policyNumber: string) => {
      return this.portalApi
        .get(`/policies/${policyNumber}/billing/balance`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    updateDefaultPaymentMethod: async (accountId: string, requestPayload: any) => {
      return this.portalApi
        .post(`/accounts/policyholders/${accountId}/paymentMethod`, requestPayload)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };

  selfService = {
    getAllQuestions: async (catagoryId: string) => {
      return this.api_v1_4
        .get(`/commercial/selfservice/formquestions/${catagoryId}`)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
    submitFormQuestions: async (policyNumber: string, requestPayload: any) => {
      return this.portalApi
        .post(`/policyadmin/${policyNumber}/formChange`, requestPayload)
        .then((res) => res.data)
        .catch((err) => Promise.reject(err));
    },
  };
}
