import React, { useContext, useEffect } from "react";
import { Dropdown } from "styles";
import { RuleBuilderContext } from "../RuleBuilderContext";
import useGetLookinSources from "hooks/underwritingRules/useGetLookinSources";
import styled from "styled-components";
import { reportFailFetchRuleMetadata } from "utils/reportError";
import { arrayToDropdownOptionsList } from "../../Shared/utils";
import { Condition } from "../../Shared/types";

interface LookinSourceDropdownProps {
  conditionIndex: number;
  conditionObj: Condition;
}

const LookinSourceDropdown = ({ conditionObj, conditionIndex }: LookinSourceDropdownProps) => {
  const { updateConditionTarget } = useContext(RuleBuilderContext);

  const { data: lookinSources, error } = useGetLookinSources();

  useEffect(() => {
    if (error) {
      reportFailFetchRuleMetadata(error);
    }
  }, [error])

  return (
    <DropdownContainer>
      <Dropdown
        labelIsHidden
        label="lookin source"
        options={arrayToDropdownOptionsList(lookinSources)}
        onChange={(option) => updateConditionTarget(option.value, conditionIndex)}
        value={conditionObj.conditionTarget}
      />
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  margin-left: 8px;
`;

export default LookinSourceDropdown;
