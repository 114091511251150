import { useQuery } from "@tanstack/react-query";
import { AccountSortBy, SortDirection } from "types/enums";
import { portalApi } from "./useAPI/instances";
import useAuth from "./useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

type AccountResponse = { data: AccountType[] };

const selectAccountPolicies = (res: AccountResponse) => {
  return res.data.map((account: any) => {
    if (!account.contactFirstName) {
      const { contactFirstName, contactLastName, contactEmail } = account.policies[0];
      return {
        ...account,
        contactFirstName,
        contactLastName,
        contactEmail,
      };
    } else {
      return account;
    }
  });
};

interface GetAccountPolicies {
  searchText: string;
  sortBy: AccountSortBy;
  sortDirection: SortDirection;
  producerId: string;
}

const useGetAccountPolicies = ({
  searchText,
  sortBy = AccountSortBy.Name,
  sortDirection = SortDirection.Descending,
  producerId,
}: GetAccountPolicies) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const getAccountPolicies = async () => {
    const token = await getToken();
    const portalAPI = portalApi(apiVersion, token);

    return portalAPI.get(
      `/accounts/search?query=${searchText}&sort_by=${sortBy}&sort_direction=${sortDirection}&limit=250&producerId=${producerId}`
    );
  };

  return useQuery({
    queryKey: ["get_account_policies", searchText, sortBy, sortDirection, producerId],
    queryFn: getAccountPolicies,
    select: selectAccountPolicies,
  });
};

export default useGetAccountPolicies;
