import React from "react";
import { Input, InputGroup, Label } from "reactstrap";
import Clipboard from "react-clipboard.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { toastSuccess } from "utils/toast";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface PropTypes {
  name: string;
  text: string;
  style?: object;
}

const ClipboardInput = ({ name, text, style }: PropTypes) => {
  const onSuccess = () => toastSuccess(`${name} has been copied to your clipboard`);

  return (
    <InputGroup style={style}>
      <Label for={`${name}-input`} hidden>
        {name}
      </Label>
      <Input id={`${name}-input`} value={text} readOnly={true} style={{ fontFamily: "courier" }} />
      <div className={"input-group-append"}>
        <Clipboard onSuccess={onSuccess} data-clipboard-text={text} className={"btn btn-secondary"}>
          <FontAwesomeIcon icon={faCopy as IconProp} />
        </Clipboard>
      </div>
    </InputGroup>
  );
};

export default ClipboardInput;
