import { useEffect, useState } from "react";
import { genericErrorMsg, reportErrorFactory } from "utils/reportError";
import { toastSuccess } from "utils/toast";
import { portalApi, coterieAPI, coterieAPI_v1_2, coterieAPI_v1_4 } from "./instances";
import urls from "./requests";
import useAuth from "hooks/useAuth";
import { CoterieAPIVersion } from "types/enums";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

export enum HttpMethods {
  GET = "get",
  POST = "post",
  PUT = "put",
}

interface optionsType {
  waitFor?: boolean;
  errorMessage?: string;
  headers?: object;
  body?: object;
  dependencyArray?: [any?];
  onSuccess?: (arg0?: any) => void;
  onError?: (arg0?: any) => void;
  successMessage?: string;
  skipErrorMessageStatusCodes?: number[];
  skipApiCall?: boolean;
}

const useAPI = (key: string, args: any[] = [], optionsAr: optionsType = {}) => {
  const optionsDefaults = { waitFor: true };
  const options = { ...optionsDefaults, ...optionsAr };
  const call = urls[key](...args);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>(call.defaultState || {});

  const { apiVersion } = useFeatureFlags();

  const apiVersionSwitcher = (version: string | undefined) => {
    switch (version) {
      case CoterieAPIVersion.v1_2:
        return coterieAPI_v1_2;
      case CoterieAPIVersion.v1_4:
        return coterieAPI_v1_4;
      default:
        return coterieAPI;
    }
  };

  const coterieAPIVersion = apiVersionSwitcher(call.version);
  const api = call.public ? coterieAPIVersion : portalApi;

  const shouldNotFetchOnMount = "fetchOnMount" in call && !call.fetchOnMount;
  const { getToken, isAuthenticated } = useAuth();

  const networkMethod = async (args?: any) => {
    const token = await getToken();

    api(apiVersion, token).interceptors.response.use(
      (response) => response,
      (error) => {
        const hasErrorDataObject = error?.response?.data;
        return Promise.reject(hasErrorDataObject ? { ...error.response, ...error.response.data } : error);
      }
    );

    const argumentArray = () => {
      if (Array.isArray(args)) {
        return args;
      } else if (!args) {
        return [];
      } else {
        return [args];
      }
    };

    switch (call.method) {
      case HttpMethods.PUT:
        return api(apiVersion, token).put(call.url, ...argumentArray());
      case HttpMethods.POST:
        if (options.headers) {
          const setHeaders = () => (options.headers ? { headers: options.headers } : {});
          return api(apiVersion, token).post(call.url, ...argumentArray(), setHeaders());
        } else {
          return api(apiVersion, token).post(call.url, ...argumentArray());
        }

      default:
        return api(apiVersion, token).get(call.url);
    }
  };

  const fetch = (args?: any) => {
    setLoading(true);
    return networkMethod(args)
      .then((res: any) => {
        const defaultResponse = (res: any) => {
          return res.data;
        };
        const transformedResponse = call.transformResponse ? call.transformResponse(res) : defaultResponse(res);
        setResults(transformedResponse);

        return transformedResponse;
      })
      .then((res: any) => {
        if (options.onSuccess) {
          options.onSuccess(res);
        }
        if (options.successMessage) {
          toastSuccess(options.successMessage);
        }
        return res;
      })
      .catch((err: any) => {
        if (options.onError) {
          options.onError(err);
        }

        if (![401, ...(options.skipErrorMessageStatusCodes ?? [])].includes(err?.response?.status)) {
          const shouldForceMsgOverride = Boolean(options.errorMessage);
          const customReportError = reportErrorFactory(options.errorMessage || genericErrorMsg);

          customReportError(err, undefined, undefined, { forceMsgOverride: shouldForceMsgOverride });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const shouldRequireAuthentication = call.public || (!call.public && isAuthenticated);
    if (options.waitFor && !shouldNotFetchOnMount && shouldRequireAuthentication && !options.skipApiCall) {
      fetch(args);
    }
  }, options.dependencyArray || args);

  return { loading, results, callback: options.skipApiCall ? async () => {} : fetch };
};

export default useAPI;
