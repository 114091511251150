import React, { useState, useEffect } from "react";
import { Checkbox } from "styles";
import styled from "styled-components";
import { connect } from "react-redux";
import { selectUnderwritingProfile } from "redux/selectors/underwritingProfile";
import { updateEndorsementQuestionState } from "redux/reducers/_underwritingProfileReducer";

interface PropTypes {
  action: (value: boolean | null) => void;
  underwritingProfile: UnderwritingProfile;
  updateEndorsementQuestionState: (endorsementDetails: { key: string; questionHasBeenAnswered: boolean }) => void;
}

const HiredNonOwnedAuto = ({ action, underwritingProfile, updateEndorsementQuestionState }: PropTypes) => {
  const questionHasBeenAnswered =
    underwritingProfile?.endorsementDetails?.hiredAutoAndNonOwnedAuto?.questionHasBeenAnswered;

  const [checked, setChecked] = useState(questionHasBeenAnswered ?? false);

  const handleCheckboxClick = () => {
    const updatedCheckState = !checked;
    if (underwritingProfile.underwritingProfileId) {
      updateEndorsementQuestionState({ key: "hiredAutoAndNonOwnedAuto", questionHasBeenAnswered: updatedCheckState });
      action(updatedCheckState || null);
    }
    setChecked(updatedCheckState);
  };

  useEffect(() => {
    setChecked(questionHasBeenAnswered ?? false);
  }, [underwritingProfile]);

  return (
    <>
      <p>
        Coterie's intent to offer this coverage is truly incidental HNOA exposures. HNOA is available as an endorsement
        for risks that meet the following criteria:
      </p>
      <Ul>
        <li>No owned autos</li>
        <li>Does not transport passengers</li>
        <li>No logistics or transportation operators</li>
        <li>No prior HNOA claims</li>
      </Ul>
      <Checkbox
        id="quote-hnoa-consent-checkbox"
        label="As the agent, I confirm this risk meets the above criteria"
        name="hnoaConsent"
        handleClick={handleCheckboxClick}
        checked={checked}
        required
        hasError={!checked}
      />
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  underwritingProfile: selectUnderwritingProfile(state),
});

const mapDispatchToProps = {
  updateEndorsementQuestionState,
};

const Ul = styled.ul`
  list-style: none;
  li {
    padding-bottom: 8px;
  }
  li:before {
    content: "";
    display: inline-block;
    margin-left: 1em;
    background-color: ${(props) => props.theme.blue};
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 10px;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(HiredNonOwnedAuto);
