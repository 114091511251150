import { useQuery } from "@tanstack/react-query";
import { underwritingAPI } from "hooks/useAPI/instances";

const getRuleList = async () => {
  const api = underwritingAPI();
  const { data } = await api.get("v1/rule/export");

  return data;
};

type Options = { enabled?: boolean };

const useGetUnderwritingRuleList = (options?: Options) => {
  return useQuery({
    queryKey: ["export"],
    queryFn: () => getRuleList(),
    retry: 1,
    ...options,
  });
};

export default useGetUnderwritingRuleList;
