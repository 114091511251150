import React, { useState } from "react";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import styled, { css } from "styled-components";
import { format, isBefore, addDays, compareDesc, set } from "date-fns";
import { ReactComponent as CalendarIcon } from "images/calendar.svg";
import { ErrorText } from "styles/Input";
import { Paragraph } from "styles/paragraph";
import ToolTipIcon from "shared/ToolTipIcon";
import { isCanadaEnv } from "utils/environmentChecker";
import { setIsValidPolicyStartDate } from "redux/reducers/_global";

interface PolicyStartDatePickerProps {
  startDate: Date;
  onChange: (startDate: Date) => void;
  isDisabled: boolean;
  headerText?: string;
  setIsValidPolicyStartDate: (isValid: boolean) => void;
}

const PolicyStartDatePicker = ({
  startDate,
  onChange,
  isDisabled,
  headerText,
  setIsValidPolicyStartDate,
}: PolicyStartDatePickerProps) => {
  const [selectedDate, setSelectedDate] = useState(startDate);
  const isPastFutureDatingPeriod = Boolean(isBefore(addDays(new Date(), 90), new Date(selectedDate)));
  const isInPast = Boolean(
    compareDesc(set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }), new Date(selectedDate)) === -1
  );

  return (
    <>
      <DatePickerHeader>{headerText ?? "Policy Start Date"}</DatePickerHeader>
      <StyledDatePicker
        calendarAriaLabel="policy start date picker"
        name="Date"
        className="start-date"
        value={selectedDate}
        onChange={(date: Date) => {
          setSelectedDate(() => {
            const isFutureCheck = Boolean(isBefore(addDays(new Date(), 90), new Date(date)));
            const isPastCheck = Boolean(
              compareDesc(set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }), new Date(date)) === -1
            );
            if (!isFutureCheck && !isPastCheck) {
              onChange(date);
              setIsValidPolicyStartDate(true);
            } else {
              setIsValidPolicyStartDate(false);
            }
            return date;
          });
        }}
        clearIcon={null}
        calendarIcon={
          <CalendarIconWrapper>
            <CalendarIcon title="Calendar Icon" />
          </CalendarIconWrapper>
        }
        monthPlaceholder="MM"
        dayPlaceholder="DD"
        yearPlaceholder="YYYY"
        calendarType="US"
        error={isPastFutureDatingPeriod || isInPast}
        disabled={isDisabled}
      />
      <StyledParagraph>
        at 12:01AM Standard Time (or time of purchase if purchased on the same day)
        <ToolTipIcon contentKey="POLICY_EFFECTIVE_DATE_DISCLAIMER" />
      </StyledParagraph>
      {isPastFutureDatingPeriod && (
        <ErrorText data-testid="past-maximum-future-error">
          {format(new Date(selectedDate), "MM/dd/yyyy")} is past the maximum future date period of 90 days.
        </ErrorText>
      )}
      {isInPast && (
        <ErrorText data-testid="date-in-past-error">
          {format(new Date(selectedDate), "MM/dd/yyyy")} is before {format(new Date(), "MM/dd/yyyy")}. Please select a
          Policy Start Date on or after {format(new Date(), "MM/dd/yyyy")}.
        </ErrorText>
      )}
    </>
  );
};

const CalendarIconWrapper = styled.div`
  svg {
    fill: ${(props) => props.theme.blue};
  }
`;

const StyledDatePicker = styled(DatePicker)<{ error: boolean }>`
  background: ${(props) => props.theme.white};
  height: 48px;
  width: 100%;
  z-index: 10;

  ${(props) =>
    props.error &&
    css`
      margin-bottom: 8px;

      > div {
        border: 1px solid ${props.theme.red}!important;
      }

      div > div > input,
      div > div > span {
        color: ${props.theme.red};
        font-weight: bold;
      }
    `}
`;

const DatePickerHeader = styled.p`
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  color: ${(props) => props.theme.fontColors?.primary};
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 13px;
  line-height: 19px;
  color: ${(props) => (isCanadaEnv() ? props.theme.black : props.theme.textNavyBlue)};
  margin-top: 8px;
`;

const mapDispatchToProps = {
  setIsValidPolicyStartDate,
};

export default connect(null, mapDispatchToProps)(PolicyStartDatePicker);
