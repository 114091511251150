import { addSource } from "utils/addSource";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  updateIndustryName,
  updateApplicationForm,
  setAvailablePolicyTypes,
  setApplicationError,
  setLoadingQuote,
} from "redux/reducers/_applicationReducer";
import Api from "utils/api";
import { reportError } from "utils/reportError";

const createApplicationFromQualificationChecker: any = createAsyncThunk(
  "application/createApplication",
  (
    { akHash, industryId, state, zip, numEmployees, previousLosses, token, isIAUser, apiVersion }: any,
    thunkAPI: any
  ) => {
    const api = new Api(apiVersion, token);
    const sourceData = addSource({ isDashboard: isIAUser });

    const application = {
      akHash,
      industryId,
      locations: [{ state, zip }],
      numEmployees,
      previousLosses,
      ...sourceData,
    };

    thunkAPI.dispatch(setLoadingQuote(true));

    api.application
      .post(application)
      .then((response) => {
        const { akHash } = response.application;

        if (akHash) {
          api.industry.get(akHash).then((res) => {
            thunkAPI.dispatch(updateIndustryName(res));
            thunkAPI.dispatch(updateApplicationForm(response.application));
            thunkAPI.dispatch(setAvailablePolicyTypes(response.availablePolicyTypes));
          });
        } else {
          thunkAPI.dispatch(updateApplicationForm(response.application));
          thunkAPI.dispatch(setAvailablePolicyTypes(response.availablePolicyTypes));
        }
      })

      .catch((err) => {
        thunkAPI.dispatch(setApplicationError(err));
        reportError(err);
      })
      .finally(() => {
        thunkAPI.dispatch(setLoadingQuote(false));
      });
  }
);

export default createApplicationFromQualificationChecker;
