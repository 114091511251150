import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import { YesNoQuestion } from "coterie-ui-library";
import styled from "styled-components";
import { selectEndorsements } from "redux/selectors/application";
import { updateApplicationFormBlanketAdditionalInsured } from "redux/reducers/_applicationReducer";
import {
  defaultBlanketDetail,
  blanketOptions,
  managerOrLessorDefaultFormText,
  lessorOfLeasedDefaultFormText,
} from "./constants";
import { Input, Paragraph } from "styles";
import useAuth from "hooks/useAuth";
import formotivInstance from "utils/formotiv";

interface PropTypes {
  endorsements: EndorsementsType;
  updateApplicationFormBlanketAdditionalInsured: (arg0: EndorsementsType) => void;
  availableEndorsements: any;
}

const blanketIsBoolean = (label: keyof EndorsementsType) =>
  Boolean(label === "additionalInsuredsPrimaryAndNonContributory" || label === "additionalInsuredsWaiverOfTransfer");

const BlanketInsured = ({
  endorsements,
  updateApplicationFormBlanketAdditionalInsured,
  availableEndorsements,
}: PropTypes) => {
  const { isIAUser } = useAuth();

  const additionalInsuredsPrimaryAndNonContributory = endorsements?.additionalInsuredsPrimaryAndNonContributory;
  const additionalInsuredsWaiverOfTransfer = endorsements?.additionalInsuredsWaiverOfTransfer;
  const additionalInsuredsConstructionContractRequirementEndorsement = Boolean(
    endorsements?.additionalInsuredsConstructionContractRequirementEndorsement
  );
  const additionalInsuredsManagersOrLessorsPremisesEndorsement = Boolean(
    endorsements?.additionalInsuredsManagersOrLessorsPremisesEndorsement
  );
  const additionalInsuredsLessorOfLeasedEquipmentEndorsement = Boolean(
    endorsements?.additionalInsuredsLessorOfLeasedEquipmentEndorsement
  );

  const answersInitialState = {
    additionalInsuredsPrimaryAndNonContributory,
    additionalInsuredsWaiverOfTransfer,
    additionalInsuredsConstructionContractRequirementEndorsement,
    additionalInsuredsManagersOrLessorsPremisesEndorsement,
    additionalInsuredsLessorOfLeasedEquipmentEndorsement,
  };

  const inputsInitialState = {
    additionalInsuredsManagersOrLessorsPremisesEndorsement:
      endorsements.additionalInsuredsManagersOrLessorsPremisesEndorsement?.formText || managerOrLessorDefaultFormText,
    additionalInsuredsLessorOfLeasedEquipmentEndorsement:
      endorsements.additionalInsuredsLessorOfLeasedEquipmentEndorsement?.formText || lessorOfLeasedDefaultFormText,
  };

  const [answers, updateAnswers]: any = useState(answersInitialState);
  const [formTextInputs, setFormTextInputs]: any = useState(inputsInitialState);

  useEffect(() => updateAnswers(answersInitialState), [endorsements]);

  // after BE refactor, Construction Contract Requirement will not have formText and will become boolean value
  const showFormInputStaticValue = (label: string) =>
    label !== "additionalInsuredsConstructionContractRequirementEndorsement";

  const showFormInput = (label: keyof EndorsementsType) =>
    !isIAUser &&
    !blanketIsBoolean(label) &&
    endorsements[label] &&
    "formText" in endorsements[label] &&
    showFormInputStaticValue(label);

  const showFormText = (label: keyof EndorsementsType) =>
    isIAUser &&
    !blanketIsBoolean(label) &&
    endorsements[label] &&
    "formText" in endorsements[label] &&
    showFormInputStaticValue(label);

  const handleSelectYes = (label: keyof EndorsementsType, item: any) => {
    formotivInstance.trackCustom(`quote-${item.question.toLowerCase().replace(" ", "-")}-yes`, "yes/no button", "yes");
    updateAnswers({ ...answers, [label]: true });

    const blanketAdditionalInsuredUpdate: EndorsementsType = blanketIsBoolean(label)
      ? { [label]: true }
      : { [label]: defaultBlanketDetail[label] };

    updateApplicationFormBlanketAdditionalInsured(blanketAdditionalInsuredUpdate);
  };

  const handleSelectNo = (label: keyof EndorsementsType, item: any) => {
    formotivInstance.trackCustom(`quote-${item.question.toLowerCase().replace(" ", "-")}-no`, "yes/no button", "no");
    updateAnswers({ ...answers, [label]: false });

    const blanketAdditionalInsuredUpdate: EndorsementsType = blanketIsBoolean(label)
      ? { [label]: false }
      : { [label]: null };

    updateApplicationFormBlanketAdditionalInsured(blanketAdditionalInsuredUpdate);
  };

  const debounceUpdateApplicationForm = useCallback(
    debounce(
      (blanketAdditionalInsuredUpdate) => updateApplicationFormBlanketAdditionalInsured(blanketAdditionalInsuredUpdate),
      500
    ),
    []
  );

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>, label: keyof EndorsementsType) => {
    const { name, value } = event.currentTarget;
    setFormTextInputs({ ...formTextInputs, [name]: value });
    const blanketAdditionalInsuredUpdate = { [name]: { ...endorsements[label], formText: value } };
    debounceUpdateApplicationForm(blanketAdditionalInsuredUpdate);
  };

  return (
    <div>
      {blanketOptions
        .filter(
          (option) =>
            !(
              option.label === "additionalInsuredsConstructionContractRequirementEndorsement" &&
              !availableEndorsements.additionalInsuredsConstructionContractRequirementEndorsement
            )
        )
        .map((item) => (
          <Container key={item.question}>
            <YesNoQuestion
              question={item.question}
              value={answers[item.label]}
              selectYes={() => handleSelectYes(item.label, item)}
              selectNo={() => handleSelectNo(item.label, item)}
              name={item.label}
              isSmall={true}
            />
            {showFormInput(item.label) && (
              <Input
                type="text"
                value={formTextInputs[item.label]}
                name={item.label}
                label="Blanket Wording:"
                placeholder="Enter names of additional insureds"
                handleChange={(e) => handleInputChange(e, item.label)}
                inputStyles={{ marginBottom: 8 }}
              />
            )}
            {showFormText(item.label) && (
              <FormTextContainer>
                <StyledParagraph>Blanket Wording:</StyledParagraph>
                <StyledParagraph>{formTextInputs[item.label]}</StyledParagraph>
              </FormTextContainer>
            )}
          </Container>
        ))}
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  endorsements: selectEndorsements(state),
});

const mapDispatchToProps = {
  updateApplicationFormBlanketAdditionalInsured,
};

const Container = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid lightgrey;
  :first-of-type {
    padding-top: 8px;
  }
  :last-of-type {
    border: none;
  }
`;

const FormTextContainer = styled.div`
  width: 80%;
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 13px;
  line-height: 18px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(BlanketInsured);
