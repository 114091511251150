import { isCanadaEnv } from "utils/environmentChecker";
import { defaultBlanketDetail } from "../application/edit/Endorsements/AdditionalInsured/constants";

export const pages = [
  { step: 1, label: ["get-started"] },
  { step: 2, label: ["industry", "new-industry", "industry-exposures"] },
  { step: 3, label: ["business-details"] },
  ...(isCanadaEnv() ? [{ step: 4, label: ["underwriting-questions"] }] : []),
];

export const pageHeaderTextLoading = [
  {
    route: "get-started",
    headerText: "We're searching for your client's business details",
    steps: ["Verifying business name and address", "Identifying primary industry", "Loading results"],
  },
  {
    route: "industry",
    headerText: "Great! This should only take a moment",
    subHeaderText: "We're reviewing our sources for rating info on this business.",
    steps: ["Checking industry eligibility", "Finding business details", "Loading results"],
  },
  {
    route: "new-industry",
    headerText: "Great! This should only take a moment",
    subHeaderText: "We're reviewing our sources for rating info on this business.",
    steps: ["Checking industry eligibility", "Finding business details", "Loading results"],
  },
  {
    route: "industry-exposures",
    headerText: "What operations are involved in your client's business?",
    subHeaderText: "We use this information to ensure that your client is within appetite.",
    steps: ["Checking industry eligibility", "Finding business details", "Loading results"],
  },
  {
    route: "business-details",
    headerText: "We’re calculating our recommendation!",
    steps: ["Underwriting application", "Fetching coverage suggestions", "Loading results"],
  },
  {
    route: "underwriting-questions",
    headerText: "Underwriting Questions",
    steps: ["Underwriting application", "Fetching coverage suggestions", "Loading results"],
  },
  {
    route: "quote",
    headerText: "We’re calculating our recommendation!",
    steps: ["Underwriting application", "Fetching coverage suggestions", "Loading results"],
  },
];

export const simplyBindEndorsementsMap: { [key: string]: Partial<EndorsementsType> } = {
  HNOAL: {
    hiredAutoAndNonOwnedAuto: {
      hiredCoverage: "hiredAutoLiability",
      nonOwnedCoverage: "nonOwnedAutoLiabilityWithoutDeliveryService",
      shouldInclude: true,
    },
  },
  LIQ: {
    liquorLiabilityEndorsement: { alcoholicBeverageSales: 0.0, shouldInclude: true },
  },
  RESTEE: {
    restaurantCoverage: true,
  },
  EQBRK: {
    equipmentBreakdownEndorsement: true,
  },
  TOOLS: {
    contractorsToolsEndorsement: {
      contractorsToolsEndorsement: true,
      selectedContractorsToolsLimit: 5000,
      toolsAndEquipmentCoverageType: "blanket",
    },
  },
  "BLKTAI-EQ": {
    additionalInsuredsLessorOfLeasedEquipmentEndorsement: {
      ...defaultBlanketDetail.additionalInsuredsLessorOfLeasedEquipmentEndorsement!,
    },
  },
  "BLKTAI-MGR": {
    additionalInsuredsManagersOrLessorsPremisesEndorsement: {
      ...defaultBlanketDetail.additionalInsuredsManagersOrLessorsPremisesEndorsement!,
    },
  },
  "BLKTAI-CC": {
    additionalInsuredsConstructionContractRequirementEndorsement: {
      ...defaultBlanketDetail.additionalInsuredsConstructionContractRequirementEndorsement!,
    },
  },
  BLKTPNC: {
    additionalInsuredsPrimaryAndNonContributory: defaultBlanketDetail.additionalInsuredsPrimaryAndNonContributory,
  },
  BLKTWOS: {
    additionalInsuredsWaiverOfTransfer: defaultBlanketDetail.additionalInsuredsWaiverOfTransfer,
  },
  // canada endorsements
  EqBrkDwn: {
    canadaEquipmentBreakdownEndorsement: { shouldInclude: true },
  },
  Flood: {
    floodEndorsement: { shouldInclude: true },
  },
  EQShock: {
    earthquakeShockEndorsement: { shouldInclude: true },
  },
  Crime: {
    crimeEndorsement: { shouldInclude: true },
  },
  PrivBreach: {
    privacyBreachEndorsement: { shouldInclude: true },
  },
  NOA: {
    nonOwnedAutomobileCoveragesEndorsement: { shouldInclude: true },
  },
  ProfitsALS: {
    profitsActualLossSustainedEndorsement: { shouldInclude: true },
  },
  SBU: {
    sewerBackUpEndorsement: { shouldInclude: true },
  },
  LegGrd: {
    legalGuardInformationServiceEndorsement: { shouldInclude: true },
  },
};

export const headerTextObject = (
  isSadPath: boolean
): Record<string, { headerText: string; subHeaderText: string | null; toolTipContent?: string }> => {
  return {
    "get-started": {
      headerText: "Start your quote now!",
      subHeaderText: null,
      toolTipContent: "SIMPLYBIND_GET_STARTED_SUBHEADER",
    },
    industry: {
      headerText: "Which industry best describes the majority of your client’s revenue?",
      subHeaderText: null,
    },
    "new-industry": isSadPath
      ? {
          headerText: "We couldn’t quite identify this business from our data sources.",
          subHeaderText: "We’ll need a bit more information from you to complete this quote.",
        }
      : {
          headerText: "Enter the primary industry",
          subHeaderText: null,
        },
    "industry-exposures": {
      headerText: "What operations are involved in your client's business?",
      subHeaderText: "We use this information to ensure that your client is within appetite.",
    },
    "business-details": isSadPath
      ? {
          headerText: "Provide business details so we can get your quote!",
          subHeaderText: "Enter the following details to complete this application.",
        }
      : {
          headerText: "Review and confirm business details so we can get your quote!",
          subHeaderText:
            "We have prefilled this application with business details below for you to review and correct if needed.",
        },
    "underwriting-questions": {
      headerText: "Tell us more about your business",
      subHeaderText: null,
    },
  };
};
