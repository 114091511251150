import styled from "styled-components";
import { AddOnQuestionCont, AddOnQuestionDesc, QuestionRow } from "./StyledComponents";
import XMarkIcon from "images/octagon-xmark.svg";
import { Label } from "styles";
import ToolTipIcon from "shared/ToolTipIcon";

interface AddOnQuestionDisabledProps {
  label: string;
  description: string;
}

export const AddOnQuestionDisabled = ({ label, description }: AddOnQuestionDisabledProps) => {
  return (
    <div style={{ display: "flex" }}>
      <AddOnQuestionCont data-testid="AddOn-question" className="AddOnQuestionCont">
        <QuestionRow isAddOn={true}>
          <XMark src={XMarkIcon} />
          <StyledLabel>{label}</StyledLabel>
        </QuestionRow>
        <AddOnQuestionDesc>{description}</AddOnQuestionDesc>
      </AddOnQuestionCont>
    </div>
  );
};

const XMark = styled.img`
  margin: 16px 12px;
  width: 24px;
  height: 24px;
`;

const StyledLabel = styled(Label)`
  margin-bottom: 0;
  color: ${(props) => props.theme.mediumGray};
`;
