import React from "react";
import styled from "styled-components";
import { ContentTable, TextLink, Pagination, TextButton, Label, Tooltip } from "coterie-ui-library";
import ArrowRight from "images/arrow-right-primary-blue.svg";

interface PropTypes {
  renewalsData?: Renewal[];
  currentPage: number;
  pageCount?: number;
  handleSelectRenewalDetails: (arg: string) => void;
  updateCurrentPage: (page: number) => void;
}

const RenewalsTable = ({
  renewalsData,
  currentPage,
  pageCount,
  handleSelectRenewalDetails,
  updateCurrentPage,
}: PropTypes) => {
  const columns = [
    {
      Header: "Renewal Status",
      accessor: "renewalStatus",
      Cell: ({ value, row }: any) => {
        return value === "Active" ? (
          <ActiveLabel size="large">Auto renew</ActiveLabel>
        ) : (
          <TooltipContainer>
            <InactiveLabel size="large">Non renew</InactiveLabel>
            <Tooltip
              id={`${row.original.expiringPolicyNumber}-nonrenewal-reason`}
              text={value}
              size="small"
              positionToLeft={1}
            />
          </TooltipContainer>
        );
      },
    },
    {
      Header: "Named Insured",
      accessor: "namedInsured",
    },
    {
      Header: "Expiring Policy Number",
      accessor: "expiringPolicyNumber",
      Cell: ({ value, row }: any) => {
        const isDisabled = row.original.isDisabled;

        return isDisabled ? (
          <TextButton disabled={true}>{value}</TextButton>
        ) : (
          <TextLink as="a" href={`/policy?policyIds=${value}`}>
            {value}
          </TextLink>
        );
      },
    },
    {
      Header: "Expiring Premium",
      accessor: "expiringPremium",
      Cell: ({ value }: any) => {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        return formatter.format(value);
      },
    },
    {
      Header: "Renewal Premium",
      accessor: "renewalPremium",
      Cell: ({ value }: any) => {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        return value ? formatter.format(value) : "$--";
      },
    },
    {
      Header: "Line of Business",
      accessor: "lineOfBusiness",
    },
    {
      Header: "Renewal Date",
      accessor: "renewalDate",
      Cell: ({ value }: any) => {
        return new Date(value).toLocaleDateString();
      },
    },
    {
      Header: "",
      accessor: "lossControl",
      Cell: ({ row }: any) => {
        const isActive = row.original.renewalStatus === "Active";
        return isActive ? (
          <StyledTextButton onClick={() => handleSelectRenewalDetails(row.original.expiringPolicyNumber)}>
            View details <ArrowRightImg src={ArrowRight}/>
          </StyledTextButton>
        ) : null;
      },
    },
  ];

  return (
    <>
      <ContentTable data={renewalsData ?? []} columns={columns} label="renewals" data-testid="renewals-table" />
      {renewalsData?.length === 0 && <NoRecordsText>No matching renewals found.</NoRecordsText>}
      <PaginationWrapper>
        <Pagination totalPages={pageCount || 1} currentPage={currentPage} onPageChange={updateCurrentPage} />
      </PaginationWrapper>
    </>
  );
};

const TooltipContainer = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`;

const ActiveLabel = styled(Label)`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.secondary.dark};
  background-color: ${(props) => props.theme.colors.secondary.twentyFive};
  border-radius: 4px;
  padding: 4px 8px;
  line-height: 15px;
  letter-spacing: 1px;
  margin-bottom: 0;
  min-width: 104px;
`;

const InactiveLabel = styled(ActiveLabel)`
  color: ${(props) => props.theme.colors.alert.dark};
  background-color: ${(props) => props.theme.colors.alert.ten};
`;

const NoRecordsText = styled.p`
  margin-top: 16px;
`;

const PaginationWrapper = styled.div`
  align-items: flex-end;
  position: relative;
  margin-left: auto;
  margin-top: 16px;
`;

const ArrowRightImg = styled.img`
  margin-bottom: 2px;
`;

const StyledTextButton = styled(TextButton)`
  font-size: 13px;
`;

export default RenewalsTable;
