import React, { useState, useEffect } from "react";
import ConfirmationCheckModal from "shared/ConfirmationCheckModal";
import ClaimsModal from "shared/Claims/ClaimsModal";
import { AlertButton, CTAButton, TabulatedTable, TextLink } from "coterie-ui-library";
import { ContentContainer, Title, TitleContainer } from "styles/containerStyledComponents";
import { Breadcrumb, ParagraphSmall } from "styles";
import styled from "styled-components";
import useGetClaims from "hooks/claims/useGetClaims";
import useDeleteClaim from "hooks/claims/useDeleteClaim";
import { toastError, toastSuccess } from "utils/toast";
import { lightFormat, parse } from "date-fns";
import { theme } from "coterie-ui-library";

const ClaimsPage = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [policyID, setPolicyID] = useState<string>("");
  const [claimID, setClaimID] = useState<string>("");
  const [dateOfLoss, setDateOfLoss] = useState<string>("");
  const [incidentTime, setIncidentTime] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const {
    data: ClaimsData,
    isLoading,
    refetch: getClaimsData,
    error: claimsError,
  } = useGetClaims();

  useEffect(() => {
    if (claimsError) {
      toastError("There was an error retrieving the claims.");
    }
  }, [claimsError]);

  const { mutate: deleteClaim } = useDeleteClaim(claimID, {
    onSuccess: () => toastSuccess("Claim removed successfully."),
    onSettled: () => getClaimsData(),
    onError: () => toastError("There was an error removing the claim."),
  });

  const columns = [
    {
      Header: "Policy ID",
      accessor: "policyNumber",
      Cell: ({ value }: any) => (
        <TextLink as={"a"} href={`/policies/${value}`} isSmall>
          {value}
        </TextLink>
      ),
      width: 120,
    },
    {
      Header: "Insured Email",
      accessor: "email",
      width: 180,
    },
    {
      Header: "Date of Loss",
      accessor: "incidentDate",
      width: 80,
      Cell: ({ value }: any) => <p>{lightFormat(parse(value, "dd/MM/yyyy", new Date()), "MM/dd/yyyy")}</p>,
    },
    {
      Header: "Description",
      accessor: "incidentDescription",
      disableSortBy: true,
      width: 220,
      Cell: ({ value }: any) => <DescriptionText>{value}</DescriptionText>,
    },
    {
      Header: "Create Claim",
      id: "createClaim",
      Cell: (props: any) => (
        <CTAButton
          isSmall
          onClick={() => openCreateClaimModal(props.row)}
          data-testid={`create-claim-row-${props.row.id}`}
        >
          Create Claim
        </CTAButton>
      ),
      width: 100,
    },
    {
      Header: "Delete Record",
      id: "ClaimsActions",
      Cell: (props: any) => (
        <AlertButton
          isSmall
          onClick={() => openDeleteModal(props.row)}
          data-testid={`delete-claim-row-${props.row.id}`}
        >
          Delete
        </AlertButton>
      ),
      width: 100,
    },
  ];

  const openCreateClaimModal = (row: any) => {
    setPolicyID(row.original.policyNumber);
    setClaimID(row.original.id);
    setDateOfLoss(reformatDate(row.original.incidentDate));
    setIncidentTime(row.original.incidentTime);
    setDescription(row.original.incidentDescription);
    setIsOpen(true);
  };

  const openDeleteModal = (row: any) => {
    setClaimID(row.original.id);
    setPolicyID(row.original.policyNumber);
    setClaimID(row.original.id);
    setDateOfLoss(reformatDate(row.original.incidentDate));
    setIncidentTime(row.original.incidentTime);
    setDescription(row.original.incidentDescription);
    setIsDeleteOpen(true);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteModal = (entryDeleted: boolean) => {
    entryDeleted && deleteClaim();
    setIsDeleteOpen(!isDeleteOpen);
  };

  const reformatDate = (date: string) => {
    return lightFormat(parse(date, "dd/MM/yyyy", new Date()), "yyyy-MM-dd");
  };

  const hasClaimsData = !isLoading && ClaimsData !== undefined;

  return (
    <ContentContainer>
      <StyledBreadcrumb>First Notice of Loss</StyledBreadcrumb>
      <TitleContainer>
        <Title>First Notice of Loss</Title>
      </TitleContainer>

      {isLoading && <p>Loading...</p>}
      {!isLoading && ClaimsData === undefined && <p>No First Notice of Loss entries found.</p>}
      {hasClaimsData && (
        <TabulatedTable data={ClaimsData} columns={columns} label="claims-table" data-testid="claims-table" />
      )}

      {isOpen && (
        <ClaimsModal
          isOpen={isOpen}
          toggleModal={toggleModal}
          policyID={policyID}
          claimID={claimID}
          description={description}
          dateOfLoss={dateOfLoss}
          incidentTime={incidentTime}
        />
      )}

      {isDeleteOpen && (
        <ConfirmationCheckModal
          policyID={policyID}
          description={description}
          isOpen={isDeleteOpen}
          confirmationHeader="Delete Claim"
          confirmationText={"Are you sure you want to delete this claim?"}
          confirmationButtonText="Delete"
          confirmationAction={handleDeleteModal}
        />
      )}
    </ContentContainer>
  );
};

const DescriptionText = styled(ParagraphSmall)`
  color: ${theme.colors.neutral.dark};
  text-wrap: {
    white-space: normal;
  }
  text-overflow: ellipsis;
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 20px;
`;

export default ClaimsPage;
