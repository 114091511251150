import React from "react";
import ReactSwitch from "react-switch";
import { theme } from "styles";

type OnChange = (
  checked: boolean,
  event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
  id: string
) => void;

interface SwitchProps {
  onChange: OnChange;
  checked: boolean;
  dataCy?: string;
  ariaLabel: string;
  dataTestId: string;
  dataPendoTag?: string;
}

const Switch = ({ onChange, checked, dataCy, ariaLabel, dataTestId }: SwitchProps) => (
  <ReactSwitch
    onChange={(checked, event, id) => {
      onChange(checked, event, id);
    }}
    checked={checked}
    uncheckedIcon={false}
    checkedIcon={true}
    onColor="#ECE9FF"
    offColor="#F2F5FA"
    offHandleColor={theme.connector}
    onHandleColor={theme.blue}
    activeBoxShadow={`0 0 2px 3px ${theme.blue}`}
    data-cy={dataCy}
    data-testid={dataTestId}
    aria-label={ariaLabel}
    id={dataTestId}
  />
);

export default Switch;
