import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useLocation } from "react-router-dom";
import AjaxLoader from "shared/AjaxLoader";
import {
  LoadingDiv,
  SearchInput,
  TitleBarAccessory,
  TitleContainer,
  ContentContainer,
  StyledTitle,
  StyledBreadcrumb,
} from "styles/containerStyledComponents";
import { AccountSearchOrderByType, AccountSearchQueryType, SortDirection } from "types/enums";
import useGetAccountSearch from "hooks/useGetAccountSearch";
import { ContentTable, Dropdown, Pagination } from "coterie-ui-library";
import { debounce } from "lodash";
import styled from "styled-components";
import { formatCurrency } from "utils/formatNumber";
import { CellInfo } from "react-table";

const columns = [
  {
    Header: "Account Id",
    accessor: "accountId",
  },
  {
    Header: "Email",
    accessor: "accountEmail",
  },
  {
    Header: "Policy Number",
    accessor: "policyNumber",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Premium",
    accessor: "premium",
    Cell: ({ value }: CellInfo) => formatCurrency(value),
  },
  {
    Header: "Premium Paid",
    accessor: "premiumPaid",
    Cell: ({ value }: CellInfo) => formatCurrency(value),
  },
];

export const dropdownOptions = [
  {
    value: AccountSearchQueryType.AccountId,
    label: "Account Id",
  },
  {
    value: AccountSearchQueryType.Email,
    label: "Email",
  },
  {
    value: AccountSearchQueryType.PolicyNumber,
    label: "Policy Number",
  },
];

interface RouteParams {
  searchTerm: string;
}

function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const AccountSearch = ({ history }: RouteComponentProps<RouteParams>) => {
  const urlSearchParams = useQueryParams();
  const searchQuery = urlSearchParams.get("q");

  const [searchValue, setSearchValue] = useState<string>(searchQuery || "");
  const [queryTypeOption, setQueryTypeOption] = useState(dropdownOptions[0]);
  const [orderBy, setOrderBy] = useState<AccountSearchOrderByType>(AccountSearchOrderByType.AccountId);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Descending);
  const [pageNumber, setPageNumber] = useState(1);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      history.replace({
        pathname: "/account-search",
        search: query ? `?q=${query}` : "",
      });
    }, 1000),
    [history]
  );

  const searchForPolicy = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setSearchValue(target.value);
    debouncedSearch(target.value);
  };

  const handleSortChange = (sortBy: { id: AccountSearchOrderByType; desc: boolean }[]) => {
    if (!sortBy.length) return;
    setOrderBy(sortBy[0].id);
    setSortDirection(sortBy[0].desc ? SortDirection.Descending : SortDirection.Ascending);
  };

  const { isLoading, data } = useGetAccountSearch({
    queryType: queryTypeOption?.value,
    searchQuery,
    orderBy,
    sortDirection,
    pageNumber,
  });

  const { items, pageCount } = (data as unknown as AccountSearchResponse) ?? {};

  return (
    <ContentContainer data-cy="policy-search">
      <StyledBreadcrumb>Accounts</StyledBreadcrumb>
      <TitleContainer>
        <StyledTitle>Accounts</StyledTitle>
        <TitleBarAccessory>
          <LoadingDiv>{isLoading ? <AjaxLoader /> : <span />}</LoadingDiv>
          <SearchInput
            autoFocus
            data-cy="policy-search-input"
            type="text"
            value={searchValue}
            placeholder="Search Query"
            onChange={searchForPolicy}
          />
        </TitleBarAccessory>
      </TitleContainer>
      <DropdownCont>
        <StyledDropdown
          options={dropdownOptions}
          createable={false}
          multiple={false}
          placeholder={"Search By..."}
          disabled={false}
          id={"Search By"}
          canSearch={false}
          size={"large"}
          loading={isLoading}
          //@ts-ignore
          onChange={(option: any) => setQueryTypeOption(option)} //TODO fix once Dropdown is patched
          value={queryTypeOption}
        />
      </DropdownCont>
      {items?.length ? (
        <>
          <StyledContentTable
            data={items}
            columns={columns}
            manualSortBy
            label="Account Search Table"
            onRowClick={(row) => history.replace(`/accounts/${row.accountId}`)}
            onSortChange={handleSortChange}
            rowKey={"accountId"}
          />
          <Pagination totalPages={pageCount} currentPage={pageNumber} onPageChange={(page) => setPageNumber(page)} />
        </>
      ) : (
        <p>No Results</p>
      )}
    </ContentContainer>
  );
};

export default AccountSearch;

const StyledContentTable = styled(ContentTable)`
  margin-bottom: 32px;
`;

const StyledDropdown = styled(Dropdown)`
  width: 200px;
`;

const DropdownCont = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px 0;
`;
