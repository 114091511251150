import React, { useEffect } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  selectApplicationForm,
  selectApplicationQuote,
  selectIsApplicationReadyToView,
  selectIndustryName,
  selectNaic,
} from "redux/selectors/application";
import { selectApplicationFormUserEmail, selectUserAgencyName, selectUserAgencyId } from "redux/selectors/user";
import {
  selectIsOptInLowerCost,
  selectLossControlOptOutPremium,
  selectLossControlOptOutMonthlyPremium,
  selectOptInCostDifferenceYearly,
} from "redux/selectors/global";
import { ReactComponent as FileIcon } from "images/file.svg";
import { GhostButton } from "shared/SimplyBind/styles";
import useGetPublicForms from "hooks/useGetPublicForms";
import useGetPublicAgencyLogo from "hooks/useGetPublicAgencyLogo";
import CorePDF from "utils/proposal-pdf";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface StyledFileIconPropTypes {
  isDisabled: boolean;
}

const MyDownloadLink = () => {
  const quote = useSelector(selectApplicationQuote);
  const agencyId = useSelector(selectUserAgencyId);
  const agencyName = useSelector(selectUserAgencyName);
  const agentEmail = useSelector(selectApplicationFormUserEmail);
  const application = useSelector(selectApplicationForm);
  const readyToViewApplication = useSelector(selectIsApplicationReadyToView);
  const industryName = useSelector(selectIndustryName);
  const naicCode = useSelector(selectNaic);
  const isGetFormsEnabled = Boolean(application?.applicationId && readyToViewApplication);

  const alternateQuote = {
    isOptInLowerCost: useSelector(selectIsOptInLowerCost),
    lossControlOptOutPremium: useSelector(selectLossControlOptOutPremium),
    lossControlOptOutMonthlyPremium: useSelector(selectLossControlOptOutMonthlyPremium),
    optInCostDifferenceYearly: useSelector(selectOptInCostDifferenceYearly),
  };

  const {
    data: formsResponse,
    isFetched: formsSuccess,
    refetch,
  } = useGetPublicForms(application?.applicationId ? application?.applicationId : "", {
    enabled: isGetFormsEnabled,
  });

  useEffect(() => {
    if (isGetFormsEnabled) {
      refetch();
    }
  }, [application, quote, refetch, isGetFormsEnabled]);

  const { data: urlLogoExists, isFetched: urlLogoSettled } = useGetPublicAgencyLogo(
    String(application?.applicationId),
    {
      enabled: Boolean(application?.applicationId),
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const { increaseFlPropertyMin } = useFeatureFlags();

  const logoURL = urlLogoExists
    ? `${process.env.REACT_APP_API_BASE_URL}/v1.4/commercial/logos/${
        application?.applicationId
      }?${new Date().getTime()}`
    : "";

  return (
    <>
      {!readyToViewApplication || !formsSuccess || (agencyId && !urlLogoSettled) ? (
        <StyledGhostButton disabled={true}>
          <StyledFileIcon isDisabled={true}>
            <FileIcon title="File-Icon" transform="translate(-6,-2)" />
          </StyledFileIcon>
          Download Quote
        </StyledGhostButton>
      ) : (
        <PDFDownloadLink
          document={
            <CorePDF
              forms={formsResponse}
              quote={quote}
              agentEmail={agentEmail}
              agencyName={agencyName}
              application={application}
              industryName={industryName}
              naicCode={naicCode}
              agencyLogo={logoURL}
              increaseFlPropertyMin={increaseFlPropertyMin}
              alternateQuote={alternateQuote}
            />
          }
          fileName="quoteproposal.pdf"
        >
          {({ loading }) => (
            <StyledGhostButton disabled={!readyToViewApplication || loading}>
              <StyledFileIcon isDisabled={!readyToViewApplication || loading}>
                <FileIcon title="File-Icon" transform="translate(-6,-2)" />
              </StyledFileIcon>
              Download Quote
            </StyledGhostButton>
          )}
        </PDFDownloadLink>
      )}
    </>
  );
};

const StyledFileIcon = styled.span<StyledFileIconPropTypes>`
  path {
    fill: ${(props) => (props.isDisabled ? props.theme.regularGray : props.theme.colors.secondary.darkTen)};
  }
`;

const StyledGhostButton = styled(GhostButton)<{ disabled: boolean }>`
  color: ${(props) => props.theme.colors.secondary.darkTen};
  border: 1px solid ${(props) => props.theme.colors.secondary.darkTen};
  padding: 6px 16px 8px 24px;
  height: 32px;

  ${(props) =>
    props.disabled &&
    `
      color: ${props.theme.regularGray};
      border: 1px solid ${props.theme.regularGray};
      cursor: not-allowed;
      :hover {
        background: inherit;
        color: ${props.theme.regularGray};
      }
    `}

  :hover {
    ${(props) =>
      !props.disabled &&
      `
        border: 1px solid ${props.theme.white};
        path {
          fill: ${props.theme.white};
        }
      `}
  }
`;

export default MyDownloadLink;
