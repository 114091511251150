import AirKitEmbed from "ia/AirKitEmbed";
import React from "react";
import { Modal } from "coterie-ui-library";
import { ModalContentContainer } from "shared/ModalComponents";
import styled from "styled-components";

interface PropTypes {
  modalIsOpen: boolean;
  closeModal: () => void;
  policy: Policy;
  email?: string;
}

const ActivePolicyLossRunModal = ({ policy, modalIsOpen, closeModal, email }: PropTypes) => {
  return (
    <Modal isOpen={modalIsOpen} closeModal={closeModal} label="loss-run-request" title="Loss Run Request">
      <ModalContainer>
        <AirKitEmbed
          body={{ policyNumber: policy.policyNumber, email: email }}
          url={process.env.REACT_APP_AIRKIT_LOSS_RUN_URL as string}
          name="loss-run-iframe"
        />
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled(ModalContentContainer)`
  overflow: hidden;
  padding: 0;
  min-width: 500px;
`;

export default ActivePolicyLossRunModal;
