import styled from "styled-components";
import { mobile, tablet } from "styles/responsive";
import { theme } from "coterie-ui-library";

export const Card = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 56px;
  row-gap: 32px;
  padding: 16px 32px 32px;
  background: ${({ theme }) => theme.white};
  margin: auto;
  border-radius: 4px;
  max-width: 960px;
  min-height: 408px;
  ${tablet} {
    max-width: 88vw;
  }
  ${mobile} {
    padding: 16px 16px 32px;
  }
`;

export const CardSectionHalfWidth = styled.div`
  min-width: 46%;
  @media only screen and (max-width: 911px) {
    min-width: 100%;
  }
`;

export const CardSectionFullWidth = styled.div`
  width: 100%;
  position: relative;
`;

export const CardSectionTitle = styled.p`
  font-size: 24px;
  font-weight: 600;

  ${mobile} {
    line-height: 32px;
  }
`;

export const CardSectionSubtitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
`;

export const CardSectionText = styled.p`
  font-size: 15px;
  font-weight: 300;
`;

export const CardSectionContainer = styled.div`
  border-bottom: 2px dotted ${(props) => props.theme.lighterPurple};
  margin-bottom: 18px;
`;

export const Row = styled.div<{ isHeader?: boolean; hasTwoValues?: boolean }>`
  display: flex;
  column-gap: 4px;
  flex-wrap: wrap;
  margin-top: 16px;
  color: ${({ isHeader, theme }) => (isHeader ? theme.white : "inherit")};
  border-bottom: ${({ isHeader }) => (isHeader ? "none" : "2px dotted rgb(66, 35, 255, .6)")};
  padding-bottom: ${({ isHeader }) => (isHeader ? "0" : "6px")};

  &.addressRow {
    margin-bottom: 32px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }

  ${mobile} {
    flex-direction: ${({ hasTwoValues }) => (hasTwoValues ? "row" : "column")};
    display: ${({ isHeader }) => (isHeader ? "none" : "flex")};
    flex-wrap: ${({ hasTwoValues }) => (hasTwoValues ? "wrap" : "inherit")};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label<{ isHeader?: boolean; hasTwoValues?: boolean }>`
  font-size: 15px;
  font-weight: ${({ isHeader }) => (isHeader ? "600" : "400")};
  flex-grow: 1;
  line-height: ${({ isHeader }) => (isHeader ? "28px" : "24px")};
  padding-left: ${({ isHeader }) => (isHeader ? "8px" : "0")};
  margin-bottom: 0;
  background-color: ${({ isHeader, theme }) => (isHeader ? theme.blue : "")};
  font-family: ${theme.font.typeface.secondary};
  color: ${theme.colors.neutral.dark};
  ${mobile} {
    width: ${({ hasTwoValues }) => (hasTwoValues ? "100%" : "inherit")};
    line-height: 20px;
  }
`;

export const Value = styled.p<{
  isHeader?: boolean;
  hasTwoValues?: boolean;
  isNarrow?: boolean;
  isMobileOnly?: boolean;
}>`
  display: ${({ isMobileOnly }) => (isMobileOnly ? "none" : "block")};
  font-size: 15px;
  font-weight: 600;
  text-align: ${({ hasTwoValues }) => (hasTwoValues ? "left" : "end")};
  width: ${({ hasTwoValues, isNarrow }) => (hasTwoValues ? "160px" : isNarrow ? "128px" : "192px")};
  line-height: ${({ isHeader }) => (isHeader ? "28px" : "24px")};
  padding-left: ${({ hasTwoValues }) => (hasTwoValues ? "8px" : "0")};
  margin-bottom: 0;
  background-color: ${({ isHeader, theme }) => (isHeader ? theme.blue : "")};

  &.industryRow {
    width: 296px;
    @media only screen and (max-width: 911px) {
      width: inherit;
    }
  }

  @media only screen and (max-width: 911px) {
    width: ${({ hasTwoValues }) => (hasTwoValues ? "160px" : "inherit")};
  }

  ${mobile} {
    display: block;
    font-size: 16px;
    font-weight: ${({ isMobileOnly }) => (isMobileOnly ? "500" : "600")};
    text-align: left;
    width: ${({ hasTwoValues }) => (hasTwoValues ? "48%" : "inherit")};
    padding-left: 0;
    margin-top: ${({ isMobileOnly }) => (isMobileOnly ? "6px" : "0")};
  }
`;

export const NoDetails = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 16px;
`;

export const Description = styled.p`
  width: 100%;
  font-family: ${theme.font.typeface.secondary};
  font-size: 13px;
  font-weight: 400;
  color: ${theme.colors.tertiary.sixty};
  margin-bottom: 0;
`;
