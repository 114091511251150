import React from "react";
import Modal from "shared/Modal";
import styled from "styled-components";
import {
  ModalContentContainer,
  ModalDivContainer,
  ActionRow,
  SaveButton,
  CancelButton,
  ActionContainer,
  Title,
} from "shared/ModalComponents";
import { Paragraph } from "styles";

interface PropTypes {
  closeModal: () => void;
  modalIsOpen: boolean;
  handleArchiveQuote: (quoteIds: string[]) => void;
}

const ArchiveModal = ({ closeModal, modalIsOpen, handleArchiveQuote }: PropTypes) => {
  return (
    <Modal isOpen={modalIsOpen} closeModal={closeModal} label="archive-notice" title="Archive Notice">
      <ModalDivContainer>
        <ModalContentContainer>
          <Title>Are you sure you want to archive?</Title>
          <StyledParagraph>
            Archived quotes will be moved to the Archived tab where you can still view the quote and bind the policy but
            you are not able to move them back to the Active quotes tab.
            <RequoteArchiveNotice>
              Note: Accounts where a requote is required cannot be viewed or bound from the Archive tab.
            </RequoteArchiveNotice>
          </StyledParagraph>
        </ModalContentContainer>
        <ActionContainer>
          <ActionRow>
            <StyledCancelButton color="cancel" onClick={closeModal} data-testid="cancel-button">
              Cancel
            </StyledCancelButton>
            <StyledSaveButton
              data-cy="archive-button"
              color="primary"
              onClick={handleArchiveQuote}
              data-testid="archive-button"
            >
              Archive
            </StyledSaveButton>
          </ActionRow>
        </ActionContainer>
      </ModalDivContainer>
    </Modal>
  );
};

const StyledParagraph = styled(Paragraph)`
  padding: 24px 0;
`;

const StyledCancelButton = styled(CancelButton)`
  width: 144px;
`;

const StyledSaveButton = styled(SaveButton)`
  width: 144px;
`;

const RequoteArchiveNotice = styled.span`
  margin-top: 1em;
  display: block;
`;

export default ArchiveModal;
