import React from "react";
import Highlight from "react-highlighter";

interface PropTypes {
  searchTerm?: string | number;
  color?: string;
  loading?: boolean;
  children: any;
}

const Highlighter = ({ children, searchTerm = "", color = "yellow", loading }: PropTypes) => {
  if (String(searchTerm).length > 3 && !loading) {
    return (
      <Highlight matchElement={"span"} matchStyle={{ backgroundColor: color }} search={String(searchTerm)}>
        {typeof children === "number" ? String(children) : children}
      </Highlight>
    );
  } else {
    return <span>{children}</span>;
  }
};

export default Highlighter;
