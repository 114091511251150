import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isNil } from "lodash";
import get from "lodash/get";
import { Label, Checkbox } from "styles";
import { AddOnQuestionCont, AddOnQuestionDesc, QuestionRow } from "./StyledComponents";
import ToolTipIcon from "shared/ToolTipIcon";
import styled from "styled-components";
import { selectUnderwritingProfileEndorsementDetails } from "redux/selectors/underwritingProfile";
import { updateEndorsementQuestionState, deleteEndorsementDetail } from "redux/reducers/_underwritingProfileReducer";
import formotivInstance from "utils/formotiv";
import { isCanadaEnv } from "utils/environmentChecker";
import { PackageInclusion } from "./AddOnQuestionsMap";
import { selectApplicationFormSales } from "redux/selectors/application";

interface PropTypes {
  endorsement: string;
  selector: string;
  label: string;
  description: string;
  question: any;
  action: (value: any) => Action;
  toolTipKey?: string;
  value: any;
  underwritingProfileEndorsementDetails: EndorsementDetails;
  updateEndorsementQuestionState: (endorsementDetails: { key: string; questionHasBeenAnswered: boolean }) => void;
  deleteEndorsementDetail: (key: string) => void;
  coverageBullets?: Array<string>;
  packageInclusion?: PackageInclusion;
  grossAnnualSales: number;
}

type OnChange = (
  checked: boolean,
  event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
  id?: string
) => void;

export const AddOnQuestion = ({
  endorsement,
  label,
  description,
  value,
  action,
  question,
  toolTipKey,
  underwritingProfileEndorsementDetails,
  updateEndorsementQuestionState,
  deleteEndorsementDetail,
  coverageBullets,
  packageInclusion,
  grossAnnualSales,
}: PropTypes) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleOnChange: OnChange = (checked, event, id) => {
    event.preventDefault();

    if (id) {
      formotivInstance.trackCustom(id, "checkbox");
    }

    if (checked) {
      setExpanded(true);
      if (question) {
        updateEndorsementQuestionState({ key: endorsement, questionHasBeenAnswered: false });
        if (endorsement === "limitDamagePremRented") {
          action(50_000);
        }
        if (endorsement === "liquorLiabilityEndorsement") {
          action({ shouldInclude: true });
        }
      } else {
        if (endorsement === "liquorLiabilityEndorsement") {
          action({ shouldInclude: true, alcoholicBeverageSales: grossAnnualSales });
        } else {
          action(true);
        }
      }
    } else {
      if (question || endorsement === "liquorLiabilityEndorsement") {
        deleteEndorsementDetail(endorsement);
      }
      setExpanded(false);
      action(isCanadaEnv() ? false : null);
    }
  };

  useEffect(() => {
    setExpanded(
      underwritingProfileEndorsementDetails?.[endorsement as keyof EndorsementDetails]?.questionHasBeenAnswered ===
        false
    );
  }, [underwritingProfileEndorsementDetails]);

  const QuestionComponent = question;

  return (
    <AddOnQuestionCont data-testid="AddOn-question" className="AddOnQuestionCont">
      <QuestionRow isAddOn={true}>
        <Checkbox
          id={`quote-${label?.toLowerCase().replace(" ", "-")}-add-on`}
          name={`${endorsement}-addOn`}
          handleClick={(e) => handleOnChange(isCanadaEnv() ? !value : !(isExpanded || !isNil(value)), e)}
          checked={isCanadaEnv() ? value : isExpanded || !isNil(value)}
        />
        <StyledLabel>
          {label}
          {toolTipKey && <ToolTipIcon contentKey={toolTipKey} />}
        </StyledLabel>
      </QuestionRow>
      <AddOnQuestionDesc>{description}</AddOnQuestionDesc>
      {question && (isExpanded || !isNil(value)) && (
        <QuestionContainer>
          <QuestionComponent action={action} />
        </QuestionContainer>
      )}
      {coverageBullets && (
        <List>
          {coverageBullets.map((bullet: string) => (
            <ListElement>{bullet}</ListElement>
          ))}
        </List>
      )}

      {packageInclusion?.inclusionHeader && <AddOnQuestionDesc>{packageInclusion.inclusionHeader}</AddOnQuestionDesc>}

      {packageInclusion?.inclusionBullets && (
        <PackageInclusionList>
          {packageInclusion.inclusionBullets.map((bullet: string) => (
            <PackageInclusionContainer>
              <div>
                <Icon src={packageInclusion.inclusionIcon} alt="shield_icon" />
              </div>
              <PackageBullet>{bullet}</PackageBullet>
            </PackageInclusionContainer>
          ))}
        </PackageInclusionList>
      )}
    </AddOnQuestionCont>
  );
};

const mapDispatchToProps = {
  updateEndorsementQuestionState,
  deleteEndorsementDetail,
};

const mapStateToProps = (state: ReduxState, props: { selector: string }) => ({
  value: get(state.application.form, props.selector),
  underwritingProfileEndorsementDetails: selectUnderwritingProfileEndorsementDetails(state),
  grossAnnualSales: selectApplicationFormSales(state),
});

const StyledLabel = styled(Label)`
  margin-bottom: 0;
`;

const QuestionContainer = styled.div`
  margin-top: 8px;
`;

const ListElement = styled.li`
  font-size: 16px;
  line-height: 24px;
`;

const List = styled.ul`
  margin-top: 8px;
`;

const PackageInclusionList = styled.ul`
  margin-top: 10px;
  padding-left: 21px;
`;

const Icon = styled.img`
  width: 20px;
  margin-right: 10px;
`;

const PackageBullet = styled.span`
  font-size: 16px;
`;

const PackageInclusionContainer = styled.div`
  display: flex;
`;

export default connect(mapStateToProps, mapDispatchToProps)(AddOnQuestion);
