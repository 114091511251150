const isCanadaEnv = (): boolean => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "canada-main":
    case "canada-production":
      return true;
    default:
      return false;
  }
};

export { isCanadaEnv };
