import React from "react";
import { connect } from "react-redux";
import Modal from "shared/Modal";
import { ModalContentContainer } from "shared/ModalComponents";
import styled from "styled-components";
import Savings from "images/savings-orange.svg";
import ShieldLossControl from "images/shield_loss_control.svg";
import {
  selectIsOptInLowerCost,
  selectOptInCostDifferenceMonthly,
  selectOptInCostDifferenceYearly,
} from "redux/selectors/global";
import { formatCurrency } from "utils/formatNumber";
import { TextLink } from "coterie-ui-library";

type PropTypes = {
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  secondaryButtonAction: () => void;
  isMonthly: boolean;
  isOptInLowerCost: boolean;
  optInCostDifferenceMonthly: number | undefined;
  optInCostDifferenceYearly: number | undefined;
};

const onLearnMoreClick = () => {
  window.open("https://explore.coterieinsurance.com/manage-my-risk-agentsbrokers", "_blank");
};

const LossControlModal = ({
  isOpen,
  closeModal,
  onSubmit,
  secondaryButtonAction,
  isMonthly,
  isOptInLowerCost,
  optInCostDifferenceMonthly,
  optInCostDifferenceYearly,
}: PropTypes) => {
  const subtitleCopy = () => {
    if (isOptInLowerCost) {
      return `save ${
        isMonthly
          ? `${formatCurrency(optInCostDifferenceMonthly)}/mo`
          : `${formatCurrency(optInCostDifferenceYearly)}/year`
      } with Manage My Risk discount`;
    } else return "Don’t miss out! Increase protection with Manage My Risk";
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      label="Loss Control"
      title="Add extra protection for your client?"
      buttonText="Add and proceed to pay"
      onSubmit={onSubmit}
      secondaryButtonText="Continue without adding"
      secondaryButtonAction={secondaryButtonAction}
    >
      <Subtitle>
        <img src={Savings} alt="Savings" />
        {subtitleCopy()}
      </Subtitle>
      <ModalContentContainer>
        <Heading>
          <img src={ShieldLossControl} alt="Shield" />
          Manage My Risk Program
        </Heading>
        <Message>
          Boost your client's protection with our self-guided and self-directed Manage My Risk Program.{" "}
          <TextLink onClick={onLearnMoreClick}>Learn more</TextLink>
        </Message>
      </ModalContentContainer>
    </Modal>
  );
};

const Subtitle = styled.div`
  display: flex;
  padding: 8px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px 4px 0px 0px;
  background: ${(props) => props.theme.orange25};
  color: ${(props) => props.theme.darkOrange};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
`;

const Heading = styled.span`
  display: flex;
  color: ${(props) => props.theme.textNavyBlue};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 8px;
  align-items: center;
`;

const Message = styled.p`
  color: ${(props) => props.theme.textNavyBlue};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

const mapStateToProps = (state: ReduxState) => ({
  isOptInLowerCost: selectIsOptInLowerCost(state),
  optInCostDifferenceMonthly: selectOptInCostDifferenceMonthly(state),
  optInCostDifferenceYearly: selectOptInCostDifferenceYearly(state),
});

export default connect(mapStateToProps)(LossControlModal);
