import React from "react";
import LabelLarge from "shared/LabelLarge";
import Modal from "shared/Modal";
import { ActionContainer, ModalContentContainer } from "shared/ModalComponents";
import styled from "styled-components";
import { GreenButton } from "styles";
import { PaymentResponse } from "./ConfirmationModal";
import { formatCurrency } from "utils/formatNumber";

interface PaymentConfimationModalProps {
  isPaymentConfirmationModalOpen: boolean;
  closePaymentConfirmationModal: () => void;
  paymentResponse?: PaymentResponse;
  paymentAmount: number;
  transactionFeeAmount?: number;
}

const PaymentConfirmationModal = ({
  paymentAmount,
  transactionFeeAmount,
  paymentResponse,
  isPaymentConfirmationModalOpen,
  closePaymentConfirmationModal,
}: PaymentConfimationModalProps) => {
  let totalCharged = paymentResponse ? paymentResponse.amountCharged : 0;
  if (transactionFeeAmount) {
    totalCharged += transactionFeeAmount;
  }

  return (
    <Modal
      isOpen={isPaymentConfirmationModalOpen}
      closeModal={closePaymentConfirmationModal}
      label="payment-confirmation"
      title="Payment Confirmation"
    >
      <ModalContentContainer>
        <InfoContainer>
          <Title>Payment Successful</Title>
          <AmountDetails>
            <AmountRow>
              <TitleText>Premium paid</TitleText>
              <ValueText>{formatCurrency(paymentAmount)}</ValueText>
            </AmountRow>
            {transactionFeeAmount && (
              <>
                <AmountRow>
                  <TitleText>Transaction fee</TitleText>
                  <ValueText>{formatCurrency(transactionFeeAmount)}</ValueText>
                </AmountRow>
                <AmountTotal>
                  <TitleText>Total charged:</TitleText>
                  <ValueText>{paymentResponse && formatCurrency(totalCharged)}</ValueText>
                </AmountTotal>
              </>
            )}
          </AmountDetails>
          <InfoContainerSpacer>
            <TitleText>Remaining Balance</TitleText>
            <ValueText>{paymentResponse && formatCurrency(paymentResponse.amountOwed)}</ValueText>
          </InfoContainerSpacer>
          <InfoContainerSpacer>
            <TitleText>Payment method</TitleText>
            <ValueText>{`**** **** **** ${paymentResponse?.paymentProfile.creditCard}`}</ValueText>
          </InfoContainerSpacer>
          <InfoContainerSpacer>
            <TitleText>Transaction ID</TitleText>
            <ValueText>{paymentResponse?.transactionId}</ValueText>
          </InfoContainerSpacer>
        </InfoContainer>
        <ActionContainer>
          <ConfirmationGreenButton
            onClick={closePaymentConfirmationModal}
            data-cy="cta-button"
            type="submit"
            data-testid={"cta-button-active"}
          >
            Close
          </ConfirmationGreenButton>
        </ActionContainer>
      </ModalContentContainer>
    </Modal>
  );
};

const Title = styled(LabelLarge)`
  font-size: 24px;
`;

const InfoContainer = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const InfoContainerSpacer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 419px;
  margin: 5px 0px;
`;
const TitleText = styled.p`
  color: ${(props) => props.theme.textNavyBlue};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
  margin: 0;
`;

const ValueText = styled.p`
  color: ${(props) => props.theme.gray};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
  margin: 0;
`;
const ConfirmationGreenButton = styled(GreenButton)`
  width: 156px;
  height: 49px;
  margin-left: 8px;
`;

const AmountDetails = styled.div`
  width: 65%;
`;

const AmountRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 28px;

  p {
    flex-grow: 1;
    flex: 1;
    margin-bottom: 16px;
  }
`;

const AmountTotal = styled(AmountRow)`
  border-top: 1px solid;
  border-color: ${(props) => props.theme.disabledBackground};
  padding-top: 12px;

  p {
    font-size: 20px;
    font-family: ${(props) => props.theme.primaryFont};
    font-weight: bold;
  }
`;

export default PaymentConfirmationModal;
