import { OptionType } from "styles/Dropdown";

export const enumToOptions = (enumToParse: any) => {
  return Object.entries(enumToParse)
    .filter(([key]) => isNaN(Number(key))) // This will filter out the numeric keys
    .map(
      ([key, value]) =>
        ({
          value,
          label: key,
        } as OptionType)
    );
};
