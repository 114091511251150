import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Checkbox, Dropdown, Input } from "styles";
import { ChannelPartnerState } from "./EditChannelPartner";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import { theme } from "coterie-ui-library";
import useValidateEmail, { getEmailValidationMessage } from "hooks/useValidateEmail";

interface PropTypes {
  showEmailError: boolean;
  setShowEmailError: React.Dispatch<React.SetStateAction<boolean>>;
  values: ChannelPartnerState;
  updateValue: React.Dispatch<React.SetStateAction<ChannelPartnerState>>;
}

const PartnerTab = ({ showEmailError, setShowEmailError, values, updateValue }: PropTypes) => {
  const [emailValidationWarning, setEmailValidationWarning] = useState("");
  const [emailValidationError, setEmailValidationError] = useState("");

  const { showEmailPolicyDocs } = useFeatureFlags();
  const emailValidator = useValidateEmail();
  const validateEmail = useCallback(
    async (email: string) => {
      return await emailValidator(email);
    },
    [emailValidator]
  );
  const handleEmailBlur = async (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setEmailValidationError("");
    setEmailValidationWarning("");

    const result = await validateEmail(value);

    if (result.isWarning) {
      setEmailValidationWarning(getEmailValidationMessage(result, value));
    } else if (!result.isValid) {
      setShowEmailError(true);
      setEmailValidationError(getEmailValidationMessage(result, value));
    }
  };

  return (
    <PartnerTabWrapper>
      <Input
        label="Channel Partner Name"
        type="text"
        name="name"
        value={values.name}
        placeholder="Name of Channel Partner"
        handleChange={(e) => updateValue({ ...values, [e.target.name]: e.target.value })}
      />
      <Input
        label="Webhook URL"
        type="text"
        name="webhookUrl"
        value={values.webhookUrl}
        placeholder="Webhook URL"
        handleChange={(e) => updateValue({ ...values, [e.target.name]: e.target.value })}
      />
      <Dropdown
        label="Policy Payment Type"
        name="policyPaymentType"
        value={values.policyPaymentType}
        onChange={(e) => updateValue({ ...values, policyPaymentType: e.value })}
        full
        options={[
          { value: "PolicyHolder", label: "Policy Holder" },
          { value: "ChannelPartner", label: "Channel Partner" },
        ]}
      />
      {showEmailPolicyDocs && (
        <>
          <Checkbox
            labelStyleOverride={values.emailPolicyDocs && { color: theme.colors.primary.zero }}
            checkBoxStyleOverride={{ marginLeft: "0px" }}
            checked={values.emailPolicyDocs}
            name="emailPolicyDocs"
            label="Send Policy Docs via Email?"
            handleClick={(e) => {
              updateValue({ ...values, emailPolicyDocs: !values.emailPolicyDocs });
            }}
          />
          {values.emailPolicyDocs && (
            <Input
              label="Policy Docs Recipient Email"
              type="text"
              name="emailPolicyDocsAddress"
              value={values.emailPolicyDocsAddress}
              placeholder="Enter Email"
              warning={Boolean(emailValidationWarning)}
              warningText={emailValidationWarning}
              errorText={!values.emailPolicyDocsAddress ? "Email is a required field" : emailValidationError}
              handleBlur={handleEmailBlur}
              error={showEmailError && values.emailPolicyDocs && (!values.emailPolicyDocsAddress || showEmailError)}
              handleChange={(e) => {
                if (showEmailError) setShowEmailError(false);
                updateValue({ ...values, [e.target.name]: e.target.value });
              }}
            />
          )}
        </>
      )}
    </PartnerTabWrapper>
  );
};

const PartnerTabWrapper = styled.div`
  max-width: 370px;
`;
export default PartnerTab;
