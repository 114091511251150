import React, { ChangeEvent, useState, useCallback, useEffect } from "react";
import Api from "utils/api";
import PolicySearchTable from "./PolicySearchTable";
import { RouteComponentProps } from "react-router";
import { History } from "history";
import { PolicySortBy, SortDirection } from "types/enums";
import AjaxLoader from "shared/AjaxLoader";
import {
  LoadingDiv,
  SearchInput,
  TitleBarAccessory,
  TitleContainer,
  ContentContainer,
  StyledTitle,
  StyledBreadcrumb,
} from "styles/containerStyledComponents";
import debounce from "lodash.debounce";
import { reportError } from "utils/reportError";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface RouteParams {
  searchTerm: string;
}

interface PropTypes extends RouteComponentProps<RouteParams> {
  history: History;
}

const PolicySearch = ({ history }: PropTypes) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const params = new URLSearchParams(window.location.search);
  const q = params.get("q") ?? "";

  const [searchInput, setSearchInput] = useState(q);
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState(PolicySortBy.PolicyNumber);
  const [sortDirection, setSortDirection] = useState(SortDirection.Descending);

  const searchForPolicy = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target) {
      const target = e.target as HTMLInputElement;
      setSearchInput(target.value);
      setIsLoading(true);

      history.replace({
        pathname: "/policies",
        search: target.value.length > 0 ? "?q=" + target.value : "",
      });
    }
  };

  const searchForPolicies = async (searchInput: string, sortBy: PolicySortBy, sortDirection: SortDirection) => {
    const token = await getToken();
    const api = new Api(apiVersion, token);
    api
      .policySearch(searchInput || "", sortBy, sortDirection)
      .then((result) => {
        if (result?.isSuccess) {
          setPolicies(result.policies);
        } else {
          reportError("An error has occurred while searching for policies");
        }
      })
      .catch((error) => reportError(error))
      .finally(() => setIsLoading(false));
  };

  const debounceSearch = useCallback(
    debounce((searchInput, sortBy, sortDirection) => searchForPolicies(searchInput, sortBy, sortDirection), 500),
    []
  );

  const sortPolicies = (sortBy: PolicySortBy, sortDirection: SortDirection) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
    searchForPolicies(searchInput, sortBy, sortDirection);
  };

  useEffect(() => {
    if (searchInput === "") {
      searchForPolicies(searchInput, sortBy, sortDirection);
    } else {
      debounceSearch(searchInput, sortBy, sortDirection);
    }
  }, [searchInput]);

  return (
    <ContentContainer data-cy="policy-search">
      <StyledBreadcrumb>Policies</StyledBreadcrumb>
      <TitleContainer>
        <StyledTitle>Policies</StyledTitle>
        <TitleBarAccessory>
          <LoadingDiv>{isLoading ? <AjaxLoader /> : <span />}</LoadingDiv>
          <SearchInput
            autoFocus
            data-cy="policy-search-input"
            type="text"
            value={searchInput}
            placeholder="Search Policies"
            onChange={searchForPolicy}
          />
        </TitleBarAccessory>
      </TitleContainer>
      <PolicySearchTable
        policies={policies}
        search={searchInput}
        sortBy={sortBy}
        sortDirection={sortDirection}
        changeSort={sortPolicies}
      />
    </ContentContainer>
  );
};

export default PolicySearch;
