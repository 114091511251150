import React, { useState } from "react";
import { Card, CardBody, CardTitle, FormGroup, Input, InputGroup, InputGroupAddon, Spinner } from "reactstrap";
import styled from "styled-components";
import { ModalContentContainer, ActionContainer, SaveButton, CancelButton, ActionRow } from "shared/ModalComponents";
import Modal from "shared/Modal";
import MailingAddressCard from "./MailingAddressCard";
import PrimaryInsured from "shared/PrimaryInsured";
import AdditionalInsureds from "./AdditionalInsureds";
import update from "immutability-helper";
import PolicyChangeConfirmation from "./PolicyChangeConfirmation";
import { LocationTypes } from "types/enums";
import { POLICY_OPTIONS, VALUE_OPTIONS } from "utils/constants";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  saveChanges: (changes: PolicyChangeRequest) => void;
  quoteChanges: (changes: PolicyChangeRequest) => void;
  closeModal: () => void;
  modalIsOpen: boolean;
  isLoading: boolean;
  policy: Policy;
  quoteResult?: QuotePolicyUpdateResponse;
}

const EditPolicyModal = ({
  saveChanges,
  quoteChanges,
  closeModal,
  modalIsOpen,
  isLoading,
  policy,
  quoteResult,
}: PropTypes) => {
  const [primaryInsured, setPrimaryInsured] = useState<PolicyPrimaryInsured>({
    accountId: policy.accountId,
    contactFirstName: policy.contactFirstName,
    contactPhone: policy.contactPhone,
    contactLastName: policy.contactLastName,
    contactEmail: policy.contactEmail,
    businessName: policy.businessName,
  });
  const [mailingAddress, setMailingAddress] = useState<Address>(policy.mailingAddress);
  const [endorsements, setEndorsements] = useState<EndorsementsType | undefined>(policy?.endorsements);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [annualPayroll, setAnnualPayroll] = useState<number | undefined>(policy.annualPayroll);
  const [grossAnnualSales, setGrossAnnualSales] = useState<number | undefined>(policy.grossAnnualSales);
  const [glLimit, setGlLimit] = useState(policy.glLimit);
  const [bppDeductible, setBppDeductible] = useState(policy.bppDeductible);
  const [buildingDeductible, setBuildingDeductible] = useState(policy.buildingDeductible);
  const [bppLimit, setBppLimit] = useState<number | undefined>(policy.locations?.[0]?.bppLimit);
  const [buildingLimit, setBuildingLimit] = useState<number | undefined>(policy.locations?.[0]?.buildingLimit);

  const onPrimaryInsuredChanged = (name: string, value: any) => {
    setPrimaryInsured((prev) => {
      prev = update(prev, { [name]: { $set: value } });
      return prev;
    });
  };

  const onMailingAddressChanged = (name: string, value: any) => {
    setMailingAddress((prev) => {
      prev = update(prev, { [name]: { $set: value } });
      return prev;
    });
  };

  const onAnnualPayrollChanged = (e: React.FormEvent<HTMLInputElement>) => {
    const value = parseInt(e.currentTarget.value);
    setAnnualPayroll(!isNaN(value) ? value : undefined);
  };

  const onGrossAnnualSalesChanged = (e: React.FormEvent<HTMLInputElement>) => {
    const value = parseInt(e.currentTarget.value);
    setGrossAnnualSales(!isNaN(value) ? value : undefined);
  };

  const onCoverageChanged = (e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const targetValue = parseInt(e.currentTarget.value);
    const value = !isNaN(targetValue) ? targetValue : undefined;
    const { name } = e.currentTarget;
    switch (name) {
      case "glLimit":
        setGlLimit(value);
        break;
      case "bppDeductible":
        setBppDeductible(value);
        break;
      case "buildingDeductible":
        setBuildingDeductible(value);
        break;
      case "bppLimit":
        setBppLimit(value);
        break;
      case "buildingLimit":
        setBuildingLimit(value);
        break;
      default:
        return;
    }
  };

  const addAdditionalInsured = (insured: AdditionalInsured) => {
    let stateEndorsements = endorsements || { additionalInsureds: [] };
    if (!stateEndorsements.additionalInsureds) stateEndorsements.additionalInsureds = [];

    const updatedEndorsements = update(stateEndorsements, { additionalInsureds: { $push: [insured] } });
    setEndorsements(updatedEndorsements);

    return true;
  };

  const removeAdditionalInsured = (insured: AdditionalInsured) => {
    if (!endorsements || !endorsements.additionalInsureds) return false;

    const insuredIndex = endorsements.additionalInsureds.indexOf(insured);
    if (insuredIndex < 0) return false;

    const updatedEndorsements = update(endorsements, { additionalInsureds: { $splice: [[insuredIndex, 1]] } });
    setEndorsements(updatedEndorsements);

    return true;
  };

  const resetToPolicy = () => {
    setAnnualPayroll(policy.annualPayroll);
    setGrossAnnualSales(policy.grossAnnualSales);
    setGlLimit(policy.glLimit);
    setBppDeductible(policy.bppDeductible);
    setBuildingDeductible(policy.buildingDeductible);
    setBppLimit(policy.locations[0].bppLimit);
    setBuildingLimit(policy.locations[0].buildingLimit);
    setPrimaryInsured({
      accountId: policy.accountId,
      contactFirstName: policy.contactFirstName,
      contactPhone: policy.contactPhone,
      contactLastName: policy.contactLastName,
      contactEmail: policy.contactEmail,
      businessName: policy.businessName,
    });
    setMailingAddress(policy.mailingAddress);
    setEndorsements(policy.endorsements);
  };

  const cancelChanges = () => {
    resetToPolicy();
    setShowConfirmation(false);
    closeModal();
  };

  const buildChangeRequest = () => {
    const policyMailingAddress = policy.mailingAddress;

    let changes: PolicyChangeRequest = {};

    if (annualPayroll !== policy.annualPayroll) {
      changes.annualPayroll = annualPayroll;
    }

    if (grossAnnualSales !== policy.grossAnnualSales) {
      changes.grossAnnualSales = grossAnnualSales;
    }

    if (mailingAddress.street !== policyMailingAddress.street) {
      changes.mailingAddressStreet = mailingAddress.street;
    }

    if (mailingAddress.city !== policyMailingAddress.city) {
      changes.mailingAddressCity = mailingAddress.city;
    }

    if (mailingAddress.state !== policyMailingAddress.state) {
      changes.mailingAddressState = mailingAddress.state;
    }

    if (mailingAddress.zip !== policyMailingAddress.zip) {
      changes.mailingAddressZip = mailingAddress.zip;
    }

    if (primaryInsured.businessName !== policy.businessName) {
      changes.businessName = primaryInsured.businessName;
    }

    if (primaryInsured.contactFirstName !== policy.contactFirstName) {
      changes.contactFirstName = primaryInsured.contactFirstName;
    }

    if (primaryInsured.contactLastName !== policy.contactLastName) {
      changes.contactLastName = primaryInsured.contactLastName;
    }

    if (primaryInsured.contactEmail !== policy.contactEmail) {
      changes.contactEmail = primaryInsured.contactEmail;
    }

    if (primaryInsured.contactPhone !== policy.contactPhone) {
      changes.contactPhone = primaryInsured.contactPhone;
    }

    if (policy.policyType === "BOP" || policy.policyType === "GL") {
      if (glLimit !== policy.glLimit) {
        changes.glBopUpdateRequest = { ...changes.glBopUpdateRequest, glLimit };
      }

      if (policy.policyType === "BOP") {
        if (bppDeductible !== policy.bppDeductible) {
          changes.glBopUpdateRequest = { ...changes.glBopUpdateRequest, bppDeductible };
        }
        if (buildingDeductible !== policy.buildingDeductible) {
          changes.glBopUpdateRequest = { ...changes.glBopUpdateRequest, buildingDeductible };
        }
        if (bppLimit !== policy.locations[0].bppLimit) {
          changes.glBopUpdateRequest = { ...changes.glBopUpdateRequest, bppLimit };
        }
        if (buildingLimit !== policy.locations[0].buildingLimit) {
          changes.glBopUpdateRequest = { ...changes.glBopUpdateRequest, buildingLimit };
        }
      }
    }

    //Find Additional Insureds changes
    const additionalInsureds = endorsements?.additionalInsureds || [];

    if (policy.endorsements !== undefined && policy.endorsements.additionalInsureds !== undefined) {
      const policyAdditionalInsureds = policy.endorsements.additionalInsureds;
      const insuredsToAdd = additionalInsureds.filter((insured) => policyAdditionalInsureds?.indexOf(insured) < 0);
      const insuredsToRemove = policyAdditionalInsureds.filter((insured) => additionalInsureds.indexOf(insured) < 0);

      if (insuredsToAdd.length > 0 || insuredsToRemove.length > 0) {
        changes.additionalInsureds = additionalInsureds;
      } else {
        changes.additionalInsureds = undefined;
      }
    } else {
      changes.additionalInsureds = additionalInsureds;
    }

    return changes;
  };

  const handleSaveChanges = () => {
    const changes = buildChangeRequest();
    saveChanges(changes);
  };

  const showChangeConfirmation = () => {
    setShowConfirmation(true);
  };

  const hideChangeConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleQuoteChanges = () => {
    const changes = buildChangeRequest();
    quoteChanges(changes);
    showChangeConfirmation();
  };

  let additionalInsureds: AdditionalInsured[] = [];
  if (endorsements) additionalInsureds = endorsements.additionalInsureds || [];

  const header = showConfirmation ? "Confirm Policy Changes" : "Edit Policy";

  const { useMaxOneMillionGl } = useFeatureFlags();

  const glLimitOptions = VALUE_OPTIONS[POLICY_OPTIONS.GENERAL_LIABILITY_LIMIT];
  const disableGlLimitOption = (option: number) => useMaxOneMillionGl && option > 1_000_000;
  const bppDeductibleOptions = [250, 500, 1000, 2500];

  return (
    <Modal isOpen={modalIsOpen} closeModal={cancelChanges} label="edit-policy" title={header}>
      {showConfirmation ? (
        <PolicyChangeConfirmation isLoading={isLoading} quoteResult={quoteResult} policy={policy} />
      ) : (
        <ModalContentContainer>
          <PrimaryInsured primaryInsured={primaryInsured} isEditable={true} onFieldChanged={onPrimaryInsuredChanged} />
          <MailingAddressCard address={mailingAddress} isEditable={true} onFieldChanged={onMailingAddressChanged} />

          <Card>
            <CardBody>
              <CardTitle id="businessInfoTitle">Business Info</CardTitle>
              <div>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend" id="payrollLabel">
                      Annual Payroll
                    </InputGroupAddon>
                    <Input
                      type="number"
                      width="full"
                      name="annualPayroll"
                      value={annualPayroll || ""}
                      onChange={onAnnualPayrollChanged}
                      placeholder="Enter dollar amount"
                      aria-labelledby="payrollLabel"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend" id="grossSalesLabel">
                      Gross Annual Sales
                    </InputGroupAddon>
                    <Input
                      type="number"
                      width="full"
                      name="grossAnnualSales"
                      value={grossAnnualSales || ""}
                      onChange={onGrossAnnualSalesChanged}
                      placeholder="Enter dollar amount"
                      aria-labelledby="grossSalesLabel"
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            </CardBody>
          </Card>

          {policy.policyType === "GL" || policy.policyType === "BOP" ? (
            <Card>
              <CardBody>
                <CardTitle>Coverage Info</CardTitle>
                <div>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon id="glLimitLabel" addonType="prepend">
                        General Liability
                      </InputGroupAddon>
                      <select
                        name="glLimit"
                        aria-labelledby="glLimitLabel"
                        value={glLimit || ""}
                        onChange={onCoverageChanged}
                      >
                        {glLimitOptions.map((option) => (
                          <option value={option} key={option} disabled={disableGlLimitOption(option)}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </InputGroup>
                    {glLimit === 2_000_000 && (
                      <div>Reminder: you will not be able to change back to 2M if you edit this limit</div>
                    )}
                  </FormGroup>
                  {policy.policyType === "BOP" && (
                    <>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon id="bppDeductibleLabel" addonType="prepend">
                            BPP Deductible
                          </InputGroupAddon>
                          <select
                            name="bppDeductible"
                            aria-labelledby="bppDeductibleLabel"
                            value={bppDeductible || ""}
                            onChange={onCoverageChanged}
                          >
                            {bppDeductibleOptions.map((option) => (
                              <option value={option} key={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend" id="buildingDeductibleLabel">
                            Building Deductible
                          </InputGroupAddon>
                          <Input
                            type="number"
                            width="full"
                            name="buildingDeductible"
                            value={buildingDeductible || ""}
                            onChange={onCoverageChanged}
                            aria-labelledby="buildingDeductibleLabel"
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend" id="bppLimitLabel">
                            BPP Limit
                          </InputGroupAddon>
                          <Input
                            type="number"
                            width="full"
                            name="bppLimit"
                            value={bppLimit || ""}
                            onChange={onCoverageChanged}
                            aria-labelledby="bppLimitLabel"
                          />
                        </InputGroup>
                      </FormGroup>
                      {policy.locations[0].locationType === LocationTypes.BuildingOwned && (
                        <FormGroup>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">Building Limit</InputGroupAddon>
                            <Input
                              type="number"
                              width="full"
                              name="buildingLimit"
                              value={buildingLimit || ""}
                              onChange={onCoverageChanged}
                            />
                          </InputGroup>
                        </FormGroup>
                      )}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          ) : null}

          <StyledCard>
            <CardBody>
              <CardTitle>Additional Insured</CardTitle>
              <AdditionalInsureds
                additionalInsureds={additionalInsureds || []}
                addAdditionalInsured={addAdditionalInsured}
                removeAdditionalInsured={removeAdditionalInsured}
              />
            </CardBody>
          </StyledCard>
        </ModalContentContainer>
      )}

      <ActionContainer>
        {showConfirmation ? (
          <ActionRow>
            <CancelButton onClick={hideChangeConfirmation} disabled={isLoading}>
              Back
            </CancelButton>
            <SaveButton onClick={handleSaveChanges} disabled={isLoading}>
              {isLoading ? <Spinner color="light" /> : <span>Confirm Changes</span>}
            </SaveButton>
          </ActionRow>
        ) : (
          <ActionRow>
            <CancelButton onClick={cancelChanges} disabled={isLoading}>
              Cancel
            </CancelButton>
            <SaveButton onClick={handleQuoteChanges} disabled={isLoading}>
              {isLoading ? <Spinner color="light" /> : <span>Quote Changes</span>}
            </SaveButton>
          </ActionRow>
        )}
      </ActionContainer>
    </Modal>
  );
};

const StyledCard = styled(Card)`
  flex: 1 1 100%;
`;

export default EditPolicyModal;
