import React, { useState } from "react";
import styled from "styled-components";
import { GreenButton, Paragraph, TextLink } from "styles";
import CoterieLogo from "images/coterie_logo_dark.svg";
import SignUpProgress from "./SignUpProgress";
import NpnVerification from "./NpnVerification";
import AccountType from "./AccountType";
import BasicInfo from "./BasicInfo";
import { PAGES } from "utils/constants";
import { mobile } from "styles/responsive";
import { BoldTextLink } from "./StyledComponents";
import UserIcon from "images/icon-user.svg";
import { useHistory } from "react-router-dom";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const Login = () => {
  const [accountCreated, setAccountCreated] = useState(false);
  const [error, setError] = useState(undefined);
  const [activePage, setActivePage] = useState(PAGES.VERIFY_NPN);
  const [availablePages, setAvailablePages] = useState<string[]>([PAGES.VERIFY_NPN]);
  const [newAccount, setNewAccount] = useState<AccountValues>({});
  const [npnVerified, setVerifiedCalled] = useState(false);

  const { showNonLicensedUserLink } = useFeatureFlags();
  const history = useHistory();

  const goToNextPage = (page: string, accountValues: AccountValues) => {
    setActivePage(page);
    setAvailablePages([...availablePages, page]);
    setNewAccount({ ...newAccount, ...accountValues });
  };

  const goBackPage = (page: string) => {
    setError(undefined);
    setAvailablePages([PAGES.VERIFY_NPN]);
    setActivePage(page);
  };

  const onAgentClick = () => {
    history.push(`/agents/`);
  };

  const goNextWithNonLicensed = () => {
    setActivePage(PAGES.ACCOUNT_TYPE);
    setAvailablePages([...availablePages, PAGES.ACCOUNT_TYPE]);
    setNewAccount({ accountType: 3 });
  };

  const renderCardDetails = () => {
    if (accountCreated) {
      return (
        <Center>
          <SuccessHeader>User Added</SuccessHeader>
          <Description>
            Congratulations, user has been added to Coterie Insurance. To affiliate the user go to agent search and
            approve the affiliation.
          </Description>
          <NextButton aria-label="agents" onClick={onAgentClick}>
            View Agents
          </NextButton>
        </Center>
      );
    } else if (error !== undefined) {
      return (
        <Center>
          <ErrorHeader>It looks like something went wrong with your application.</ErrorHeader>
          <ErrorMessage>
            Please go back and submit your applications again. Or, if you need help call the number below.
          </ErrorMessage>
          <BackButton onClick={() => goBackPage(PAGES.VERIFY_NPN)}>Back</BackButton>
          <Number>(513) 440-4044</Number>
        </Center>
      );
    } else {
      return (
        <>
          <Header>User Add</Header>
          <SignUpProgress currentPage={activePage} availablePages={availablePages} />
          {activePage === PAGES.VERIFY_NPN && (
            <>
              <NpnVerification setVerifiedCalled={setVerifiedCalled} goToNextPage={goToNextPage} />
              {showNonLicensedUserLink && (
                <Center>
                  <Row>
                    <img src={UserIcon} alt="user-icon" />
                    <NonLicensedLink onClick={goNextWithNonLicensed}>Add Non-Licensed Accounts</NonLicensedLink>
                  </Row>
                  <FinePrint>Support accounts do not require licensing numbers.</FinePrint>
                </Center>
              )}
            </>
          )}
          {activePage === PAGES.ACCOUNT_TYPE && <AccountType goToNextPage={goToNextPage} newAccount={newAccount} />}
          {activePage === PAGES.BASIC_INFO && (
            <BasicInfo newAccount={newAccount} setAccountCreated={setAccountCreated} setError={setError} />
          )}
          {activePage === PAGES.VERIFY_NPN && npnVerified && (
            <Center>
              <Paragraph>Not you?</Paragraph>
              <BoldTextLink
                as="a"
                href="https://nipr.com/help/look-up-your-npn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit NIPR.com for help.
              </BoldTextLink>
            </Center>
          )}
        </>
      );
    }
  };

  return (
    <DashLogin>
      <Card>
        <Center>
          <img src={CoterieLogo} alt="Coterie logo" />
        </Center>
        {renderCardDetails()}
      </Card>
      <DashRow>
        <FinePrintLink href={`https://${process.env.REACT_APP_DOMAIN}/privacy-policy`} target="_blank" rel="noopener">
          Privacy Policy
        </FinePrintLink>
        <FinePrintLink href={`https://${process.env.REACT_APP_DOMAIN}/terms-conditions`} target="_blank" rel="noopener">
          Terms & Conditions
        </FinePrintLink>
      </DashRow>
      <FinePrint>©{new Date().getFullYear()} Coterie Applications Inc. All Rights Reserved.</FinePrint>
    </DashLogin>
  );
};

const Center = styled.div`
  text-align: center;
`;

const Card = styled.div`
  background: ${(props) => props.theme.white};
  padding: 40px 32px 24px;
  margin: auto;
  border: 1px solid #d4d4d9;
  border-radius: 4px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  width: 400px;

  ${mobile} {
    width: 380px;
  }
`;

const DashLogin = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding-top: 50px;
`;

const Header = styled.h1`
  color: ${(props) => props.theme.fontColors.primary};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 24px;
  font-weight: 600;
  margin: 60px 0 40px;
  text-align: center;
`;

const Number = styled(Header)`
  margin: 0;
`;

const SuccessHeader = styled(Header)`
  margin-bottom: 20px;
`;

const ErrorHeader = styled(Header)`
  color: ${(props) => props.theme.errorText};
  margin-bottom: 32px;
`;

const ErrorMessage = styled(Paragraph)`
  font-size: 13px;
  line-height: 18px;
`;

const Description = styled(Paragraph)`
  font-size: 15px;
  line-height: 18px;
`;

const NextButton = styled(GreenButton)`
  width: 120px;
  margin: 42px 0 260px;
  background-color: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.white};
  border-radius: 24px;

  :disabled {
    background-color: ${(props) => props.theme.disabledBackground};
    color: ${(props) => props.theme.disabledText};
    opacity: 1;
  }
`;

const BackButton = styled(GreenButton)`
  width: 120px;
  margin: 204px 0 32px;
  background-color: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.white};
`;

const DashRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 255px;
  justify-content: space-between;
  width: 100%;
`;

const FinePrintLink = styled.a`
  font-size: 16px;
  margin: 24px 0 0px;
  color: ${(props) => props.theme.fontColors.primary};
  font-family: ${(props) => props.theme.primaryFont};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
`;

const FinePrint = styled(Paragraph)`
  font-size: 13px;
  color: ${(props) => props.theme.gray};
  padding-bottom: 20px;
`;

const NonLicensedLink = styled(TextLink)`
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
  font-weight: normal;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export default Login;
