import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RadioGroup } from "coterie-ui-library";
import styled from "styled-components";
import format from "date-fns-tz/format";
import addDays from "date-fns/addDays";
import { Breadcrumb, Dropdown } from "styles";
import {
  ContentContainer,
  Title,
  TitleBarAccessory,
  TitleContainer,
  SearchContainer,
  SearchIconWrapper,
  SearchInput,
} from "styles/containerStyledComponents";
import { getUserExternalId } from "redux/selectors/user";
import useGetRenewals from "hooks/useGetRenewals";
import useGetAgencyProducers, { GetAgencyProducersResponse } from "hooks/useGetAgencyProducers";
import RenewalsTable from "./RenewalsTable";
import { ReactComponent as SearchIcon } from "images/search_icon.svg";
import PriceDrawer from "./PriceDrawer";

const nextRenewalPeriod = [
  {
    value: "30",
    label: "Next 30 Days",
  },
  {
    value: "60",
    label: "Next 60 Days",
  },
  {
    value: "90",
    label: "Next 90 Days",
  },
];

const Renewals = () => {
  const externalId = useSelector(getUserExternalId);
  const { data: producers } = useGetAgencyProducers({});

  const [currentPage, setCurrentPage] = useState(1);
  const [renewalSearchPeriod, setRenewalSearchPeriod] = useState("30");
  const [currentExternalId, setCurrentExternalId] = useState(externalId);
  const [searchInput, setSearchInput] = useState("");
  const [displayedData, setDisplayedData] = useState<Renewal[]>([]);

  const [selectedRenewalPolicyNumber, setSelectedRenewalPolicyNumber] = useState("");
  const [showPriceDrawer, setShowPriceDrawer] = useState(false);
  const [showPriceDrawerClosing, setShowPriceDrawerClosing] = useState(false);

  const handleSelectRenewalDetails = (policyNumber: string) => {
    setSelectedRenewalPolicyNumber(policyNumber);
    setShowPriceDrawer(true);
  };

  const searchStartDate = new Date();
  const [searchEndDate, setSearchEndDate] = useState(addDays(searchStartDate, parseInt(renewalSearchPeriod)));

  const hasSearchInput = searchInput.length > 0;
  const pageSize = 20;

  const renewalParams = {
    orderBy: "RenewalPremium",
    PageSize: pageSize,
    producerExternalId: currentExternalId,
    startDate: format(searchStartDate, "yyyy-MM-dd"),
    endDate: format(searchEndDate, "yyyy-MM-dd"),
    orderByAscending: "false",
    PageNumber: currentPage,
  };

  const { data: renewalData, isLoading } = useGetRenewals(renewalParams);
  const hasPolicyRenewals = !isLoading && renewalData?.policyRenewals !== undefined;

  const selectedRenewalDetails = showPriceDrawer
    ? renewalData?.policyRenewals.find(
        (renewal: Renewal) => renewal.expiringPolicyNumber === selectedRenewalPolicyNumber
      )
    : {};

  const updateCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleFilterOnChange = (producerOption: GetAgencyProducersResponse) => {
    setCurrentExternalId(!producerOption ? externalId : producerOption.value);
  };

  const handleDateFilter = (searchPeriodInDays: string) => {
    setRenewalSearchPeriod(searchPeriodInDays);
    setSearchEndDate(addDays(searchStartDate, parseInt(searchPeriodInDays)));
  };

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target) {
      const target = e.target as HTMLInputElement;
      setSearchInput(target.value);
    }
  };

  const filterDataBySearchInput = (renewalData: RenewalResponse, searchInput: string) => {
    let policyRenewals: Renewal[] = [];

    renewalData?.policyRenewals !== undefined &&
      renewalData.policyRenewals?.forEach((renewal) => {
        if (
          renewal.expiringPolicyNumber.toLowerCase().includes(searchInput.toLowerCase()) ||
          renewal.namedInsured.toLowerCase().includes(searchInput.toLowerCase()) ||
          renewal.businessName.toLowerCase().includes(searchInput.toLowerCase())
        ) {
          policyRenewals.push(renewal);
        }
      });

    setDisplayedData(policyRenewals);
  };

  useEffect(() => {
    filterDataBySearchInput(renewalData as RenewalResponse, searchInput as string);
  }, [searchInput]);

  const handlePriceDrawerClose = () => {
    setShowPriceDrawer(false);
    setShowPriceDrawerClosing(true);
    setTimeout(() => {
      setShowPriceDrawerClosing(false);
    }, 400);
  };

  return (
    <ContentContainer>
      <StyledBreadcrumb>Renewals</StyledBreadcrumb>
      <TitleContainer>
        <Title>Renewals</Title>
        <TitleBarAccessory>
          <SearchContainer>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <SearchInput
              autoFocus
              data-test="renewal-search-input"
              name="searchInput"
              type="text"
              value={searchInput}
              placeholder="Search by Named Insured or Policy #"
              onChange={handleSearchInput}
              disabled={!hasPolicyRenewals}
            />
          </SearchContainer>
        </TitleBarAccessory>
      </TitleContainer>
      <Subtitle>
        Need assistance with your renewal? Contact{" "}
        <a href="mailto: renewals@coterieinsurance.com">renewals@coterieinsurance.com</a>
      </Subtitle>
      <FilterContainer>
        <DateFilter>
          <RadioTitle>Date Range</RadioTitle>
          <RadioGroup
            name="renewalPeriodSelection"
            options={nextRenewalPeriod}
            handleChange={handleDateFilter}
            selectedValue={renewalSearchPeriod}
            orientation="row"
          />
        </DateFilter>
        <DropdownWrapper>
          {producers?.length ? (
            <>
              <DropdownLabel htmlFor="renewalsFilter-dropdown" data-cy="renewals-dropdown-filter">
                Filter by Users
              </DropdownLabel>
              <Dropdown
                onChange={handleFilterOnChange}
                options={producers}
                value={currentExternalId}
                name="renewalsFilter"
                labelIsHidden
                isClearable={externalId !== currentExternalId}
              />
            </>
          ) : null}
        </DropdownWrapper>
      </FilterContainer>

      {isLoading && <Subtitle>Loading...</Subtitle>}

      {!isLoading && renewalData?.policyRenewals === undefined && <Subtitle>No renewals found.</Subtitle>}

      {hasPolicyRenewals && (
        <RenewalsTable
          renewalsData={hasSearchInput ? displayedData : renewalData?.policyRenewals}
          currentPage={currentPage}
          pageCount={displayedData.length > 0 ? Math.ceil(displayedData.length / pageSize) : renewalData?.pageCount}
          updateCurrentPage={updateCurrentPage}
          handleSelectRenewalDetails={handleSelectRenewalDetails}
        />
      )}

      {(showPriceDrawer || showPriceDrawerClosing) && (
        <PriceDrawer
          onClose={handlePriceDrawerClose}
          selectedRenewalDetails={selectedRenewalDetails}
          showPriceDrawer={showPriceDrawer}
        />
      )}
    </ContentContainer>
  );
};

const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 24px;
`;

const Subtitle = styled.p`
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
  color: ${(props) => props.theme.colors.neutral.dark};
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DateFilter = styled.div`
  display: flex;
  align-items: center;
`;

const RadioTitle = styled.p`
  font-weight: 600;
  margin: 6px 16px 0 0;
`;

const DropdownWrapper = styled.div`
  align-items: flex-end;
  flex-direction: column;
  width: 255px;
  margin-left: auto;
  margin-top: 16px;
  z-index: 2;
  position: relative;
`;

const DropdownLabel = styled.label`
  color: ${(props) => props.theme.textNavyBlue};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.09px;
  line-height: 16px;
`;

export default Renewals;
