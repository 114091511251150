import React from "react";
import Modal from "shared/Modal";
import styled from "styled-components";
import {
  ModalContentContainer,
  ModalDivContainer,
  Title,
  ActionRow,
  SaveButton,
  CancelButton,
  ActionContainer,
} from "shared/ModalComponents";
import { Paragraph } from "styles";

interface PropTypes {
  modalIsOpen: boolean;
  closeModal: () => void;
  handleContinue: () => void;
}

const ConfirmationModal = ({ modalIsOpen, closeModal, handleContinue }: PropTypes) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      closeModal={closeModal}
      label="confirm-details"
      title="Removing Coverage from Additional Insured"
    >
      <ModalDivContainer>
        <ModalContentContainer>
          <Title>Do you want to remove coverages?</Title>
          <StyledParagraph>
            Removing Individual Additional Insured coverage will delete any details you have added.
          </StyledParagraph>
        </ModalContentContainer>
        <ActionContainer>
          <ActionRow>
            <StyledCancelButton
              color="cancel"
              onClick={closeModal}
              data-cy="cancel-changes"
              data-testid="cancel-changes"
            >
              Cancel
            </StyledCancelButton>
            <StyledSaveButton color="primary" onClick={handleContinue} data-cy="continue" data-testid="continue">
              Remove
            </StyledSaveButton>
          </ActionRow>
        </ActionContainer>
      </ModalDivContainer>
    </Modal>
  );
};

const StyledParagraph = styled(Paragraph)`
  padding: 24px 0;
`;

const StyledCancelButton = styled(CancelButton)`
  width: 144px;
`;

const StyledSaveButton = styled(SaveButton)`
  width: 144px;
`;

export default ConfirmationModal;
