interface UpdateIQCForm {
  type: "UPDATE_IQC_FORM";
  payload: ApplicationFailFast;
}

interface AddPreviousLoss {
  type: "ADD_PREVIOUS_LOSS";
  payload: Claim;
}

interface RemovePreviousLoss {
  type: "REMOVE_PREVIOUS_LOSS";
  payload: { index: number };
}

interface Clear {
  type: "RESET_IQC_FORM" | "CLEAR_PREVIOUS_LOSS" | "RESET_PREVIOUS_LOSS";
}

type Actions = Clear | UpdateIQCForm | AddPreviousLoss | RemovePreviousLoss;

export const initialState = {
  akHash: "",
  industry: "",
  industryId: 0,
  numEmployees: undefined,
  state: "",
  zip: "",
  previousLosses: null,
};

const qualificationFormReducer = (state: ApplicationFailFast, action: Actions) => {
  switch (action.type) {
    case "UPDATE_IQC_FORM":
      return { ...state, ...action.payload };
    case "RESET_IQC_FORM":
      return initialState;
    case "ADD_PREVIOUS_LOSS":
      return {
        ...state,
        previousLosses: Array.isArray(state.previousLosses)
          ? [...state.previousLosses, action.payload]
          : [action.payload],
      };
    case "REMOVE_PREVIOUS_LOSS":
      const filtered_previous_losses = state.previousLosses.filter((_: any, i: any) => i !== action.payload.index);
      return { ...state, previousLosses: filtered_previous_losses };
    case "CLEAR_PREVIOUS_LOSS":
      return { ...state, previousLosses: [] };
    case "RESET_PREVIOUS_LOSS":
      return { ...state, previousLosses: null };

    default:
      throw new Error();
  }
};

export default qualificationFormReducer;
