import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Alert from "reactstrap/lib/Alert";
import Api from "utils/api";
import { selectUserName } from "redux/selectors/user";
import FileUpload from "./FileUpload";
import AjaxLoader from "shared/AjaxLoader";
import FileIcon from "images/file.svg";
import trashcan_svg from "images/trashcan.svg";
import { GreenButton, Header4 } from "styles";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  userName: string;
}

const AppetiteChangesUploader = ({ userName }: PropTypes) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();
  const [state, setState] = React.useState<any>({});

  const handleDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let file = e.target.files[0];
      if (file != null) {
        setState({
          request: { fileName: file?.name },
          file: file,
        });
      }
    }
  };

  const handleDelete = () => setState({});

  const handleSubmit = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    if (state.file) {
      setState({
        loading: true,
        file: state.file,
      });

      const appetiteFormData = {
        file: URL.createObjectURL(state.file),
      };

      const formdata = new FormData();

      try {
        // turn object url to blob that form FormData can consume
        const file_data_url = await fetch(appetiteFormData.file);

        const file_blob = await Promise.all([file_data_url.blob()]);

        formdata.append("file", file_blob[0], state.file.name);
        formdata.append("createdBy", userName);
      } catch (error) {
        setState({ error: "Error uploading file(s). Try re-uploading file(s) again." });
      }

      api.coterieHash
        .post(formdata)
        .then((result: any) => {
          handleDelete();
          setState({ loading: false, result: result, error: null, file: state.file });
        })
        .catch((error: any) => {
          setState({ loading: false, result: null, error: error, file: state.file });
        });
    }
  };

  return (
    <>
      <FileUpload handleChange={handleDocumentChange} loading={state.loading} />

      {state.file && (
        <>
          <Header4 as="h3">Document to upload</Header4>
          <FileContainer>
            <File>
              <Icon src={FileIcon} alt="PDF File icon" />
              <FileName>{state.file.name}</FileName>
              <TrashCanButton onClick={() => handleDelete()}>
                <Icon src={trashcan_svg} alt="Trash can icon" />
              </TrashCanButton>
            </File>
          </FileContainer>
          <GreenButton type="button" onClick={handleSubmit}>
            Upload file
          </GreenButton>
          {state.loading && <AjaxLoader />}
          {state.result && (
            <div style={{ paddingTop: "1em" }}>
              <Alert
                color="success"
                style={{ whiteSpace: "pre", fontFamily: "courier" }}
                data-testid="successful-file-upload-alert"
              >
                {JSON.stringify(state.result, null, 2)}
              </Alert>
            </div>
          )}
          {state.error && (
            <div style={{ paddingTop: "1em" }}>
              <Alert
                color="danger"
                style={{ whiteSpace: "pre", fontFamily: "courier" }}
                data-testid="error-file-upload-alert"
              >
                {JSON.stringify(state.error, null, 2)}
              </Alert>
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  userName: selectUserName(state),
});

const File = styled.li`
  background: #fff;
  padding: 16px;
  list-style: none;
  width: 100%;
  margin: 8px 0;
  display: flex;

  & > *:last-child {
    margin-left: auto;
  }
`;

const FileContainer = styled.ul`
  margin: 16px 0;
  padding: 0;
  margin-bottom: 30px;
`;

const TrashCanButton = styled.button`
  border: none;
  background: transparent;
`;

const FileName = styled.p`
  color: ${(props) => props.theme.blue};
  font-size: 15px;
  font-weight: 600;
  margin: 0 10px;
`;

const Icon = styled.img`
  width: 15px;
`;

export default connect(mapStateToProps)(AppetiteChangesUploader);
