import React, { useState } from "react";
import styled from "styled-components";
import Highlighter from "shared/Highlighter";
import BoundPolicies from "./BoundPolicies";
import { ReactComponent as ArrowRight } from "images/arrow-right-blue.svg";

type PropTypes = { account: AccountType; searchTerm?: string };

const AccountSearchRow = ({ account, searchTerm = "" }: PropTypes) => {
  const [showPolicies, setShowPolicies] = useState(false);
  const hasPolicies = account.policies && account.policies.length > 0;

  return (
    <>
      <AccountRow>
        <td>
          <Highlighter searchTerm={searchTerm}>
            <PolicyLength>{hasPolicies ? `(${account.policies?.length})` : ""} </PolicyLength>
            <PolicyHolder
              data-cy="policy-row-policy-holder-name"
              onClick={() => hasPolicies && setShowPolicies(!showPolicies)}
            >
              {account.contactFirstName} {account.contactLastName}
            </PolicyHolder>
          </Highlighter>
        </td>
        <td>
          <Highlighter searchTerm={searchTerm}>{account.contactEmail}</Highlighter>
        </td>
        {account.lastUpdated && <td>{new Date(account.lastUpdated).toLocaleDateString()}</td>}
        <td>
          {hasPolicies && (
            <Button aria-label="Show policy details" onClick={() => setShowPolicies(!showPolicies)}>
              <IconContainer open={showPolicies}>
                <ArrowRight />
              </IconContainer>
            </Button>
          )}
        </td>
      </AccountRow>
      {hasPolicies && showPolicies && account.policies && <BoundPolicies policies={account.policies} />}
    </>
  );
};

const AccountRow = styled.tr`
  td {
    border: 1px solid transparent;
  }
  &:hover {
    td {
      border-top: 1px solid ${(props) => props.theme.linkHover};
      border-bottom: 1px solid ${(props) => props.theme.linkHover};
      &:first-of-type {
        border-left: 1px solid ${(props) => props.theme.linkHover};
      }
      &:last-of-type {
        border-right: 1px solid ${(props) => props.theme.linkHover};
      }
    }
  }
`;

const Button = styled.button`
  background: none;
`;

const PolicyHolder = styled.button`
  color: ${(props) => props.theme.linkHover};
  border: none;
  background: none;
  font-weight: 600;
`;

const PolicyLength = styled.span`
  color: ${(props) => props.theme.midGray};
  margin-right: 8px;
`;

const IconContainer = styled.div<{ open?: boolean }>`
  transition: 0.2s all ease;
  transform: rotate(${(props) => (props.open ? "90deg" : "0deg")});
  background: transparent;
  svg path {
    fill: ${(props) => props.theme.blue};
  }
`;

export default AccountSearchRow;
