import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { Label, RadioButton } from "styles";

const ExistingCard = ({ paymentProfile, selectedCard, setSelectedCard, setIsPaymentMethodSelected }: any) => {
  const handleRadioButtonChange = (e: any, selectedBtn: string) => {
    setSelectedCard(e.target.value || selectedBtn);
  };

  useEffect(() => {
    setIsPaymentMethodSelected(selectedCard === paymentProfile?.creditCard);
  }, [selectedCard]);

  return (
    <ContentDiv onClick={(e) => handleRadioButtonChange(e, paymentProfile?.creditCard)} tabIndex={0}>
      <Label>{paymentProfile?.cardType}</Label>
      <FlexDiv>
        <InfoText>**** **** **** {paymentProfile?.creditCard}</InfoText>
        <InfoText>exp. {paymentProfile?.expiration}</InfoText>
      </FlexDiv>
      <RadioButton
        value={paymentProfile?.creditCard}
        label=""
        checked={selectedCard === paymentProfile?.creditCard}
        groupName="card"
        handleClick={(e) => handleRadioButtonChange(e, paymentProfile?.creditCard)}
        inputStyles={RadioInputStyles}
        ariaLabel="Select default credit card"
      />
    </ContentDiv>
  );
};

const ContentDiv = styled.div`
  height: 80px;
  width: 440px;
  padding: 16px;
  background-color: ${(props) => props.theme.offWhite};
  border: 1px solid ${(props) => props.theme.regularGray};
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  margin: 8px 0px;
  :focus {
    border: 1px solid ${(props) => props.theme.blue};
  }
  &:hover {
    cursor: pointer;
  }
`;

const FlexDiv = styled.div`
  display: flex;
`;

export const InfoText = styled.label`
  color: ${(props) => props.theme.gray};
  font-family: Questrial;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 16px;
`;

const RadioInputStyles = css`
  position: absolute;
  right: 0;
  top: 35%;
  margin-right: 16px;
`;

export default ExistingCard;
