import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { RouteComponentProps } from "react-router-dom";
import AjaxLoader from "shared/AjaxLoader";
import { getUser } from "redux/reducers/_userReducer";
import TopNavigation from "layout/TopNavigation";
import PendingAffiliateBanner from "layout/PendingAffiliateBanner";
import { AffiliatedStatus } from "types/enums";
import { getAffiliatedStatus } from "redux/reducers/_affiliationReducer";
import { laptop, desktop } from "styles";
import {
  selectAuth0Id,
  selectApplicationFormUserEmail,
  selectUserName,
  getUserExternalId,
  selectUserAgencyName,
  selectUserAgencyExternalId,
} from "redux/selectors/user";
import SidebarNav from "layout/SidebarNav";
import AppRoutes from "layout/AppRoutes";
import { useLDClient } from "launchdarkly-react-client-sdk";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "hooks/useAuth";
import useFormotivRouteHandler from "hooks/useFormotivRouteHandler";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { logrocketIdentify, logrocketInit } from "utils/_init/logrocket";
import DadJokes from "dev_tools/dad_jokes";
import { salesforceLiveAgentInit } from "utils/_init/salesforceLiveAgent";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

declare global {
  interface Window {
    pendo: {
      initialize: any;
      validateInstall: any;
      BuildingBlocks: {
        BuildingBlockResourceCenter: {
          getResourceCenter: () => { hasResourceCenterContent: boolean };
        };
      };
    };

    formotiv: any; //to allow formotiv on the global window object
    embedded_svc: any; //to allow salesforce chat on the global window object
    wootric_survey_immediately: boolean;
    wootricSettings: {
      email: string;
      account_token: string;
      product_name: string;
    };
    wootric: (arg: string) => void;
  }
}

interface AppPropTypes extends RouteComponentProps {
  getUser: (arg0: APIVersionAuth) => void;
  isAffiliated: boolean | undefined;
  userEmail: string;
  userName: string;
  auth0Id: string;
  externalId?: string;
  agencyExternalId?: string;
  agencyName?: string;
}

const queryClient = new QueryClient();

const App = ({
  getUser,
  isAffiliated,
  userEmail,
  userName,
  auth0Id,
  externalId,
  agencyExternalId,
  agencyName,
  location,
}: AppPropTypes) => {
  const { getToken, isIAUser, isAuthenticated, isLoading, roles } = useAuth();
  const { apiVersion, hideSalesforceLiveAgent } = useFeatureFlags();
  const ldClient = useLDClient();
  useFormotivRouteHandler();

  const setUser = async () => {
    const token = await getToken();
    getUser({ apiVersion, token });
  };

  useEffect(() => {
    if (isAuthenticated) {
      setUser();
    }
  }, [isAuthenticated, getUser]);

  useEffect(() => {
    ldClient?.identify({
      key: auth0Id,
      name: userName,
      email: userEmail,
    });

    if (externalId) {
      logrocketIdentify(externalId, userName, userEmail);
    }

    if (
      (process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "sandbox") &&
      externalId
    ) {
      window.pendo?.initialize({
        visitor: {
          id: externalId,
          full_name: userName,
          email: userEmail,
          role: roles[0],
        },
        account: {
          id: agencyExternalId,
          name: agencyName,
        },
      });
    }

    window.wootric_survey_immediately = false; // Set to true to show survey immediately for testing purposes.
    window.wootricSettings = {
      email: userEmail,
      account_token: "NPS-3dcee617",
      product_name: process.env.REACT_APP_ENVIRONMENT === "production" ? "dashboard" : "dashboard_test",
    };
    if (process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "test") {
      window.wootric && window.wootric("run");
    }
  }, [userEmail]);

  useEffect(() => {
    logrocketInit();

    if (externalId && !hideSalesforceLiveAgent) {
      salesforceLiveAgentInit(externalId);
    }

    // Log latest short SHA of latest commit
    console.log(process.env.REACT_APP_GIT_SHA);
  }, []);

  const routesWithNoLeftNav = ["snapshot", "quoteproposal"];
  const fluidRoutes = ["resources", "simplybind", ...routesWithNoLeftNav];
  const currentRoute = location.pathname.split("/")[1];
  // This seems to be a temporary workaround to style the background. Tricky tricky.
  const useLegacyBackground = currentRoute !== "policy";

  if (isLoading) {
    return (
      <div>
        <TopNavigation />
        <LoadingContainer>
          <AjaxLoader />
        </LoadingContainer>
      </div>
    );
  }

  const isProdOrSandbox =
    process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "sandbox";

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        {isAuthenticated && <TopNavigation />}
        {isIAUser && !isAffiliated && <PendingAffiliateBanner />}
        <MainContainer>
          <SidebarNav isHidden={routesWithNoLeftNav.includes(currentRoute)} />
          <ContentContainer
            useLegacyBackground={useLegacyBackground}
            fluid={fluidRoutes.includes(currentRoute) || !isAuthenticated}
            noLeftNav={routesWithNoLeftNav.includes(currentRoute)}
          >
            <AppRoutes />
          </ContentContainer>
        </MainContainer>
        <ReactQueryDevtools />
        {!isProdOrSandbox && <DadJokes />}
      </QueryClientProvider>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  isAffiliated: getAffiliatedStatus(state) === AffiliatedStatus.Affiliated,
  userEmail: selectApplicationFormUserEmail(state),
  userName: selectUserName(state),
  auth0Id: selectAuth0Id(state),
  externalId: getUserExternalId(state),
  agencyExternalId: selectUserAgencyExternalId(state),
  agencyName: selectUserAgencyName(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getUser: (auth: any) => {
    dispatch(getUser(auth));
  },
});

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.offWhite};
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled.div<{ fluid: boolean; noLeftNav: boolean; useLegacyBackground: boolean }>`
  flex-grow: 1;
  background-color: ${({ theme, useLegacyBackground }) =>
    useLegacyBackground ? theme.pageComponents.global.legacyBackground : theme.pageComponents.global.background};
  min-height: calc(100vh - 56px);
  width: calc(100% - 240px);
  min-width: ${({ noLeftNav }) => (noLeftNav ? "100%" : "")};
  padding: ${({ fluid, useLegacyBackground }) => (fluid || !useLegacyBackground ? "0" : "30px 50px")};

  ${desktop} {
    padding: ${({ fluid, useLegacyBackground }) => (fluid || !useLegacyBackground ? "0" : "30px 3vw")};
  }

  ${laptop} {
    max-width: 71%;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(App);
