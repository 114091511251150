import React from "react";
import { connect } from "react-redux";
import { Container } from "../styles";
import Payment from "shared/Payment";
import PremiumHeader from "../PremiumHeader";
import { selectApplicationForm, selectApplicationQuote } from "redux/selectors/application";
import getCurrentApplication from "redux/actions/getCurrentApplication";
import { match } from "react-router";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface RouteParams {
  applicationId?: string;
}
interface PropTypes {
  quote: BindableQuote;
  application: Application;
  match: match<RouteParams>;
  getCurrentApplication: (arg0: { applicationId?: string; token: Token; apiVersion: string }) => void;
}

const BindConfirmation = ({ quote, application, match, getCurrentApplication }: PropTypes) => {
  const location = useLocation();
  const isLegacyQuote = new URLSearchParams(location.search).get("legacy") === "true";
  const { getToken } = useAuth();
  const { params } = match;
  const { apiVersion } = useFeatureFlags();

  useEffect(() => {
    const setCurrentApplication = async () => {
      const token = await getToken();
      const { applicationId } = params;
      getCurrentApplication({ applicationId, token, apiVersion });
    };

    if (isLegacyQuote) {
      setCurrentApplication();
    }
  }, [isLegacyQuote]);

  return (
    <Container>
      <PremiumHeader />
      <Payment isSimplybind={true} quote={quote} application={application} />
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  quote: selectApplicationQuote(state),
  application: selectApplicationForm(state),
});

const mapDispatchToProps = {
  getCurrentApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(BindConfirmation);
