import { createAsyncThunk } from "@reduxjs/toolkit";
import { addSource } from "utils/addSource";
import Api from "utils/api";
import applicationValidator from "utils/applicationValidator";
import { mapSimplyBindAvailableEndorsements, mapSimplybindEndorsementDetails } from "utils/mapSimplybindEndorsements";
import { setAlternateQuote, setUnderwritingModalIsOpen } from "redux/reducers/_global";
import {
  setApplicationFromServer,
  setAvailablePolicyTypes,
  setApplicationError,
  updateIndustryName,
  setQuoteErrors,
  setQuote,
  setBindable,
  setSimplybindApplicationFromServer,
  setUpdatingQuote,
  updateNaic,
  setTwoFourLimitsAvailable,
} from "redux/reducers/_applicationReducer";
import {
  updateUnderwritingProfile,
  setLoadingUnderwritingProfile,
  updateUnderwritingEndorsementDetails,
} from "redux/reducers/_underwritingProfileReducer";
import { updateAvailableEndorsements } from "redux/reducers/_availableEndorsements";
import getQuote from "./getQuote";
import omit from "lodash/omit";
import { reportError } from "utils/reportError";
import { applicationFormContactFields } from "utils/constants";
import { pipe } from "utils/functionalHelpers";
import validateApplicationStartDate from "utils/validateApplicationStartDate";
import { liquorClasses100 } from "utils/constants/liquorClasses";

interface UserAuthInformation {
  token: string;
  isIAUser: boolean;
  apiVersion: string;
}

const updateApplicationFromServer: any = createAsyncThunk(
  "application/updateEditedFromServer",
  ({ token, isIAUser, apiVersion }: UserAuthInformation, thunkAPI: any) => {
    const { application, underwritingProfile } = thunkAPI.getState();
    const { alternateQuote } = thunkAPI.getState().global;
    const { naic } = application;

    const api = new Api(apiVersion, token);
    const sourceData = addSource({ isDashboard: isIAUser });
    const isLegacyQuote = new URLSearchParams(window.location.search).get("legacy") === "true";
    const isConfirmPage = window.location.pathname.includes("confirm");
    const isLegacyPaymentScreen = isLegacyQuote && isConfirmPage;
    const shouldUseUnderwriting = !isLegacyPaymentScreen;

    if (shouldUseUnderwriting) {
      const validatedApplicationWithEdits = pipe(
        applicationValidator,
        validateApplicationStartDate
      )({
        ...application.form,
        ...(!application.form.sourceVersion ? sourceData : []),
      });

      const requestData = {
        legacyApplication: validatedApplicationWithEdits,
        ...sourceData,
      };

      thunkAPI.dispatch(setLoadingUnderwritingProfile(true));
      api.underwriting
        .put({
          ...omit(underwritingProfile, "validationErrors"),
          ...requestData,
        })
        .then((response) => {
          thunkAPI.dispatch(setApplicationError(null));
          thunkAPI.dispatch(updateIndustryName(response?.industryName));

          thunkAPI.dispatch(updateNaic(response?.naic));
          thunkAPI.dispatch(
            setSimplybindApplicationFromServer(omit(response?.legacyApplication, applicationFormContactFields))
          );
          thunkAPI.dispatch(updateUnderwritingProfile(response));
          thunkAPI.dispatch(setTwoFourLimitsAvailable(response?.twoFourLimitsAvailable));
          const hasNoSelectedPolicies = !response.legacyApplication.applicationTypes?.length;
          const applicationTypeIsPL = response.legacyApplication.applicationTypes?.join("") === "PL";

          mapSimplybindEndorsementDetails(
            {
              ...response?.legacyApplication?.endorsements,
              ...(response?.legacyApplication.limitDamagePremRented && {
                limitDamagePremRented: response?.legacyApplication.limitDamagePremRented,
              }),
            },
            applicationTypeIsPL || hasNoSelectedPolicies ? {} : response.endorsementDetails,
            (payload) => thunkAPI.dispatch(updateUnderwritingEndorsementDetails(payload)),
            liquorClasses100.includes(naic)
          );

          thunkAPI.dispatch(setAvailablePolicyTypes(response?.availableCoverages));
          thunkAPI.dispatch(
            updateAvailableEndorsements(mapSimplyBindAvailableEndorsements(response?.recommendedEndorsements))
          );

          const quoteErrors =
            response?.validationErrors?.length > 0
              ? response?.validationErrors?.map((e: any) => ({ message: e.message, invalidFields: e.invalidFields }))
              : [];
          thunkAPI.dispatch(setQuoteErrors(quoteErrors));
          thunkAPI.dispatch(setBindable(!response?.validationErrors));
          thunkAPI.dispatch(setQuote(response?.quoteSummary ?? {}));
          const currentQuoteIsOptIn = response.legacyApplication.lossControl === "OptIn";
          const alternateQuotePremium = currentQuoteIsOptIn
            ? alternateQuote?.lossControlOptIn?.premium
            : alternateQuote?.lossControlOptOut?.premium;
          /**
           * If the quoteSummary is undefined, we'll skip this step; I don't know why the quoteSummary would be undefined
           * and this may present potential bugs or side effects. The quoteSummary is only sometimes undefined, and I don't
           * know if this is a bug or if it's intentional.
           *
           * At the time of development, there were no BE resources dedicated to this initiative to deep dive this issue.
           */
          if (
            !applicationTypeIsPL &&
            response?.quoteSummary?.premium &&
            response?.quoteSummary?.premium !== alternateQuotePremium
          ) {
            const currentQuotePremium = response.quoteSummary.premium;
            const currentQuoteMonthlyPremium = response.quoteSummary.monthlyPremium;
            const currentQuoteMonth1Owed = response.quoteSummary.month1Owed;
            const currentQuoteTotalYearlyOwed = response.quoteSummary.totalYearlyOwed;
            api.underwriting
              .put({
                ...omit(underwritingProfile, "validationErrors"),
                legacyApplication: { ...application.form, lossControl: currentQuoteIsOptIn ? "OptOut" : "OptIn" },
              })
              .then((alternateQuoteResponse) => {
                const alternateQuotePremium = alternateQuoteResponse?.quoteSummary?.premium;
                const alternateQuoteMonthlyPremium = alternateQuoteResponse?.quoteSummary?.monthlyPremium;
                const alternateQuoteMonth1Owed = alternateQuoteResponse?.quoteSummary?.month1Owed;
                const alternateQuoteTotalYearlyOwed = alternateQuoteResponse?.quoteSummary?.totalYearlyOwed;
                const currentQuoteDetails = {
                  premium: currentQuotePremium,
                  monthlyPremium: currentQuoteMonthlyPremium,
                  month1Owed: currentQuoteMonth1Owed,
                  totalYearlyOwed: currentQuoteTotalYearlyOwed,
                };
                const alternateQuoteDetails = {
                  premium: alternateQuotePremium,
                  monthlyPremium: alternateQuoteMonthlyPremium,
                  month1Owed: alternateQuoteMonth1Owed,
                  totalYearlyOwed: alternateQuoteTotalYearlyOwed,
                };

                thunkAPI.dispatch(
                  setAlternateQuote(
                    currentQuoteIsOptIn
                      ? {
                          lossControlOptIn: currentQuoteDetails,
                          lossControlOptOut: alternateQuoteDetails,
                        }
                      : {
                          lossControlOptOut: currentQuoteDetails,
                          lossControlOptIn: alternateQuoteDetails,
                        }
                  )
                );
                api.underwriting
                  .put({
                    ...omit(underwritingProfile, "validationErrors"),
                    legacyApplication: { ...application.form, lossControl: currentQuoteIsOptIn ? "OptIn" : "OptOut" },
                  })
                  .then((latestQuote) => {
                    thunkAPI.dispatch(setQuote(latestQuote?.quoteSummary ?? {}));
                  });
              });
          }
          if (quoteErrors.length === 0) {
            setTimeout(() => {
              api.underwriting
                .prebind(response.underwritingProfileId)
                .then(() => {
                  thunkAPI.dispatch(setLoadingUnderwritingProfile(false));
                })
                .catch(() => {
                  thunkAPI.dispatch(setLoadingUnderwritingProfile(false));
                });
            }, 4000);
          } else {
            thunkAPI.dispatch(setLoadingUnderwritingProfile(false));
          }
        })
        .catch((err) => {
          /**
           * This catch will also handle any other errors, e.g. TypeError, ReferenceError, etc., that may occur
           * in the .then block above. The error _should_ be reported to Sentry, but this is not guaranteed.
           *
           * The error will be logged to the console for local troubleshooting.
           */
          console.error(err);
          thunkAPI.dispatch(setUnderwritingModalIsOpen(false));
          thunkAPI.dispatch(setApplicationError(err.status));
          // underwriting api will give an error status code but still return the response we need
          // need to talk with BE team to sort this out
          if (err.response?.data?.legacyApplication) {
            thunkAPI.dispatch(updateIndustryName(err.response?.data?.industryName));
            thunkAPI.dispatch(updateNaic(err.response?.data?.naic));
            thunkAPI.dispatch(
              setSimplybindApplicationFromServer(
                omit(err.response?.data?.legacyApplication, applicationFormContactFields)
              )
            );
            thunkAPI.dispatch(updateUnderwritingProfile(err.response?.data));
            thunkAPI.dispatch(setAvailablePolicyTypes(err.response?.data?.availableCoverages));
            thunkAPI.dispatch(
              updateAvailableEndorsements(
                mapSimplyBindAvailableEndorsements(err.response?.data?.recommendedEndorsements)
              )
            );
            thunkAPI.dispatch(setTwoFourLimitsAvailable(err.response?.data?.twoFourLimitsAvailable));
            const quoteErrors =
              err.response?.data?.validationErrors?.map((e: any) => ({
                message: e.message,
                invalidFields: e.invalidFields,
              })) ?? [];
            thunkAPI.dispatch(setQuoteErrors(quoteErrors));
            thunkAPI.dispatch(setQuote(err.response?.data?.quoteSummary ?? {}));
          }
          if (isConfirmPage && !isLegacyQuote) {
            api.underwriting
              .prebind(err.response?.data.underwritingProfileId)
              .then(() => thunkAPI.dispatch(setLoadingUnderwritingProfile(false)));
          } else {
            thunkAPI.dispatch(setLoadingUnderwritingProfile(false));
          }
          reportError(err);
        });
    } else {
      thunkAPI.dispatch(setUpdatingQuote(true));
      api.application
        .post(pipe(applicationValidator, validateApplicationStartDate)({ ...application.form, ...sourceData }))
        .then((response) => {
          if (application.form.industryId !== response.application.industryId) {
            const { akHash } = response.application;
            if (akHash) {
              api.industry.get(akHash).then((res) => {
                thunkAPI.dispatch(updateIndustryName(res));
              });
            }
          }

          thunkAPI.dispatch(setApplicationFromServer(response.application));
          thunkAPI.dispatch(setAvailablePolicyTypes(response.availablePolicyTypes));
          thunkAPI.dispatch(setQuoteErrors(response.errors));
          thunkAPI.dispatch(getQuote({ isSuccess: response.isSuccess, isUpdating: true, token, apiVersion }));
        })
        .catch((err) => {
          thunkAPI.dispatch(setUnderwritingModalIsOpen(false));
          thunkAPI.dispatch(setApplicationError(err));
          reportError(err);
        });
    }
  }
);

export default updateApplicationFromServer;
