import React, { ChangeEvent, forwardRef, ForwardedRef, useState } from "react";
import styled, { css } from "styled-components";
import NumberFormat from "react-number-format";
import { Label } from "styles";
import omit from "lodash/omit";
import ToolTipIcon from "shared/ToolTipIcon";
import { ReactComponent as AlertIcon } from "images/alert.svg";
import { ReactComponent as Caret } from "images/caret_up.svg";

export const input_styles = css<{
  error?: boolean;
  warning?: boolean;
  loading?: boolean;
  halfwidth?: boolean;
  readOnly?: boolean;
}>`
  height: 48px;
  background-color: ${(props) => (props.readOnly ? props.theme.colors.neutral.special : props.theme.white)};
  font-family: ${(props) => props.theme.secondaryFont};
  border: 1px solid ${(props) => (props.readOnly ? props.theme.offWhite : props.theme.lighterGray)};
  box-shadow: ${(props) => props.error && `0 0 0 1px ${props.theme.red}`};
  width: ${(props) => (props.halfwidth ? "255px" : "100%")};
  font-size: 15px;
  letter-spacing: 0;
  padding: 11px 15px;
  border-radius: 4px;
  margin-bottom: ${(props) => {
    if (props.warning) return "0";
    if (props.error) return "8px";
    return "32px";
  }};
  -moz-appearance: textfield;
  :focus {
    outline: none;
    box-shadow: 0 0 0 1px ${(props) => (props.error ? props.theme.red : props.theme.blue)};
  }
  :read-only {
    pointer-events: none;
  }
  ::-webkit-calendar-picker-indicator {
    fill: purple;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${(props) =>
    props.loading &&
    css`
      color: ${props.theme.connector};
    `}
  ${(props) =>
    props.readOnly &&
    css`
      color: ${props.theme.colors.neutral.regular};
    `}
`;

export const StyledInput = styled.input`
  ${input_styles}
`;

const TelephoneInput = styled(NumberFormat)`
  ${input_styles}
`;

export const MoneyInput = styled(NumberFormat)`
  ${input_styles}
`;

export const FormatInput = styled(NumberFormat)`
  ${input_styles}
`;

export const NumberInput = styled(NumberFormat)`
  ${input_styles}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

interface PropTypes {
  label?: string;
  labelIsHidden?: boolean;
  type?: any;
  value: any;
  name: string;
  id?: string;
  placeholder?: string;
  maxLength?: number;
  required?: boolean;
  readOnly?: boolean;
  pattern?: string;
  title?: string;
  style?: any;
  handleChange?: (arg0: any) => void;
  handleBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  prefix?: "$";
  labelStyles?: object;
  inputStyles?: object;
  datacy?: string;
  format?: any;
  isAllowed?: any;
  defaultValue?: any;
  error?: boolean;
  errorText?: string;
  warning?: boolean;
  warningText?: string;
  showWarningArrow?: boolean;
  loading?: boolean;
  halfwidth?: boolean;
  toolTipContent?: string;
  ariaLabel?: string;
  thousandSeparator?: boolean | string;
  isOptional?: boolean;
  decimalSeparator?: any;
  formotivName?: string;
}

export const Input = (
  {
    label,
    labelIsHidden = false,
    type,
    value,
    name,
    id,
    placeholder,
    handleChange,
    handleBlur,
    maxLength,
    required,
    readOnly = false,
    pattern,
    title,
    prefix,
    labelStyles = {},
    inputStyles = {},
    format,
    datacy,
    isAllowed,
    defaultValue,
    error,
    errorText,
    warning,
    warningText,
    showWarningArrow,
    loading,
    halfwidth,
    toolTipContent,
    ariaLabel,
    thousandSeparator,
    decimalSeparator,
    isOptional = false,
    formotivName,
  }: PropTypes,
  ref: ForwardedRef<HTMLInputElement | null>
) => {
  const common = {
    type,
    value,
    name,
    id: id ? id : name,
    "data-testid": name,
    placeholder,
    maxLength,
    onBlur: handleBlur,
    required,
    readOnly,
    pattern,
    title,
    style: inputStyles,
    ["data-cy"]: datacy || `${name}-input`,
    defaultValue,
    error,
    warning,
    loading,
    format,
    halfwidth,
    ["aria-label"]: ariaLabel ? ariaLabel : label,
    ["data-formotiv-input-name"]: formotivName,
  };

  const getInput = () => {
    if (type === "tel") {
      return <TelephoneInput {...common} onChange={handleChange} format="(###)###-####" mask="_" />;
    } else if (prefix === "$") {
      return (
        <MoneyInput
          thousandSeparator={true}
          prefix={prefix}
          {...common}
          onValueChange={handleChange}
          isAllowed={isAllowed}
          decimalSeparator={decimalSeparator}
        />
      );
    } else if (type === "number") {
      return (
        <NumberInput
          {...omit(common, "type")}
          onValueChange={handleChange}
          thousandSeparator={thousandSeparator}
          decimalSeparator={decimalSeparator}
        />
      );
    } else if (type === "creditCard") {
      return (
        <NumberInput {...omit(common, "type")} onValueChange={handleChange} format="#### #### #### ####" mask=" " />
      );
    } else if (type === "creditCardExpiry") {
      return (
        <NumberInput
          {...omit(common, "type")}
          onValueChange={handleChange}
          format="##/##"
          mask={["M", "M", "Y", "Y"]}
        />
      );
    } else if (format) {
      const addFormat = format ? { format } : {};
      return <FormatInput {...common} onChange={handleChange} {...addFormat} />;
    } else {
      return <StyledInput ref={ref} {...common} onChange={handleChange} />;
    }
  };

  const [showWarning, setShowWarning] = useState(true);

  return (
    <Column>
      {label && (
        <LabelContainer labelIsHidden={labelIsHidden}>
          <Label style={labelStyles} htmlFor={name}>
            {label} {isOptional && <OptionalText>(optional)</OptionalText>}
          </Label>

          {toolTipContent && <ToolTipIcon contentKey={toolTipContent} />}
        </LabelContainer>
      )}
      {getInput()}
      {warning && warningText && (
        <Warning>
          <WarningIcon width={16} height={16} />
          {showWarning ? (
            <WarningText data-cy="warning-text">{warningText}</WarningText>
          ) : (
            <WarningText data-cy="warning-text">View invalid email warning</WarningText>
          )}
          {showWarningArrow !== false && (
            <WarningCaret
              className={!showWarning ? "closed" : ""}
              width={16}
              height={16}
              onClick={() => setShowWarning(!showWarning)}
              data-testid="warning-caret"
            />
          )}
        </Warning>
      )}
      {error && errorText && <ErrorText data-cy="error-text">{errorText}</ErrorText>}
    </Column>
  );
};

const OptionalText = styled.span`
  color: ${(props) => props.theme.fontColors.subduedDark};
  margin-left: 4px;
  font-size: 12px;
  letter-spacing: 0.75px;
  font-weight: 600;
`;

export const ErrorText = styled.p`
  color: ${(props) => props.theme.errorText};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.25px;
`;

const Warning = styled.div`
  color: ${(props) => props.theme.colors.pl.dark};
  background-color: ${(props) => props.theme.colors.pl.ten};
  display: flex;
  flex-direction: row;
  padding: 8px;
`;

const WarningIcon = styled(AlertIcon)`
  min-width: 16px;

  g {
    fill: ${(props) => props.theme.colors.pl.zero};
  }
`;

const WarningCaret = styled(Caret)`
  margin-left: auto;
  min-width: 16px;
  cursor: pointer;

  &.closed {
    transform: rotate(180deg);
  }

  g {
    fill: ${(props) => props.theme.colors.pl.zero};
  }
`;

const WarningText = styled.p`
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.25px;
  margin: 0 8px 0 16px;
`;

const LabelContainer = styled.span<{ labelIsHidden?: boolean }>`
  display: flex;
  ${(props) => props.labelIsHidden && `height: 1px; width: 1px; overflow: hidden; position: absolute;`}
`;

export default forwardRef(Input);
