import React, { useContext } from "react";
import { RuleSearchContext } from "../RuleSearchContext";
import { theme } from "styles";
import styled from "styled-components";
import { Dropdown } from "styles";
import { OptionType, dropdownStyles } from "styles/Dropdown";
import { RuleStatus } from "../types";
import { arrayToDropdownOptionsList } from "../../Shared/utils";

const RuleStatusDropdown = () => {
  const { filters, setStatusFilter } = useContext(RuleSearchContext);
  return (
    <StatusDropdownContainer>
      <Dropdown
        onChange={(selection) => setStatusFilter(selection?.map((option: OptionType) => option.value) ?? [])}
        options={[
          { label: RuleStatus.Active, value: RuleStatus.Active },
          { label: RuleStatus.InActive, value: RuleStatus.InActive },
        ]}
        label="Status"
        labelStyles={{ fontSize: "12px", color: theme.textNavyBlue }}
        placeholder="Status"
        isMulti
        full
        isSearchable={false}
        customStyles={StatusDropdownStyles}
        multiValues={arrayToDropdownOptionsList(filters.statuses)}
      />
    </StatusDropdownContainer>
  );
};

const StatusDropdownStyles = {
  ...dropdownStyles,
  multiValue: (styles: any) => ({
    ...styles,
    ":not(:nth-last-child(2))": {
      marginRight: "10px",
    },
    borderRadius: "4px",
  }),
  multiValueLabel: (styles: any, { data }: any) => ({
    ...styles,
    background: data.value === RuleStatus.Active ? theme.green10 : theme.red20,
    color: theme.gray,
    fontSize: "11px",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1px",
  }),
  multiValueRemove: (styles: any, { data }: any) => ({
    ...styles,
    background: data.value === RuleStatus.Active ? theme.green10 : theme.red20,
    color: theme.mediumGray,
    fontSize: "12px",
    fontWeight: 600,

    ":hover": {
      ...styles[":hover"],
      background: data.value === RuleStatus.Active ? theme.green10 : theme.red20,
      color: theme.mediumGray,
      cursor: "pointer",
    },
  }),
};

const StatusDropdownContainer = styled.div`
  max-width: 270px;
`;

export default RuleStatusDropdown;
