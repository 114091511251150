import styled from "styled-components";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formotivInstance from "utils/formotiv";
import { NavLinkStyles, mobile } from "styles";
import { NavListItem } from "./styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const APIDocumentation = () => (
  <NavListItem onClick={() => formotivInstance.config && formotivInstance.submitFinal()}>
    <ApiLink target="_blank" rel="noopener noreferrer" href={`http://docs.${process.env.REACT_APP_DOMAIN}`}>
      API Documentation
      <FontAwesomeIcon icon={faExternalLinkAlt as IconProp} style={{ marginLeft: "0.3em", opacity: 0.5 }} />
    </ApiLink>
  </NavListItem>
);

export default APIDocumentation;

const ApiLink = styled.a`
  ${NavLinkStyles}

  &.active {
    border-radius: 20px 0 0 20px;
    color: ${(props) => props.theme.blue};
    background-color: ${(props) => props.theme.lightestPurple};

    ${mobile} {
      border-radius: 0;
    }
  }
`;
