import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { theme } from "styles";
import { formatCurrency } from "utils/formatNumber";

type PropTypes = {
  title: string;
  description: string;
  limit?: number;
  sublimit?: string;
};

const AddOnsItem = ({ title, description, limit, sublimit }: PropTypes) => (
  <View style={styles.container}>
    <View style={styles.titleLimitContainer}>
      <Text style={styles.title}>{title}</Text>
      {limit ? <Text style={styles.limitText}>{formatCurrency(limit)}</Text> : null}
    </View>
    {description && (
      <View style={styles.descriptionContainer}>
        <Text style={styles.description}>
          {description} {sublimit && <Text style={styles.sublimit}>{sublimit}</Text>}
        </Text>
      </View>
    )}
  </View>
);

const styles = StyleSheet.create({
  container: {
    marginTop: 12,
  },
  titleLimitContainer: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.light,
    paddingBottom: 6,
  },
  title: {
    fontSize: 10,
    color: theme.black,
    fontFamily: theme.font.typeface.primary,
    fontWeight: 600,
    width: 372,
  },
  limitText: {
    fontSize: 11,
    color: theme.black,
    fontFamily: theme.font.typeface.secondary,
    justifyContent: "flex-end",
  },
  descriptionContainer: {
    marginTop: 6,
  },
  description: {
    fontSize: 9,
    color: theme.black,
    fontFamily: theme.font.typeface.secondary,
    width: 390,
  },
  sublimit: {
    color: theme.colors.primary.zero,
  },
});

export default AddOnsItem;
