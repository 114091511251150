import React, { useState } from "react";
import BasicModal from "shared/ModalBasic";
import styled from "styled-components";
import { ModalContentContainer, ActionRow, SaveButton, ActionContainer } from "shared/ModalComponents";
import { Input } from "styles";
import useUpdateAgentPassword from "hooks/useUpdateAgentPassword";
import { toastSuccess } from "utils/toast";
import { reportFailedUpdatePassword } from "utils/reportError";
import { passwordInput } from "utils/constants";

interface PropTypes {
  modalIsOpen: boolean;
  onRequestClose: () => void;
  accountId: string;
}

const UpdateAgentPasswordModal = ({ modalIsOpen, onRequestClose, accountId }: PropTypes) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { passwordTitle, passwordPattern } = passwordInput;
  const isDisabled = password === "" || confirmPassword === "" || password !== confirmPassword;

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    let { name, value } = event.currentTarget;
    switch (name) {
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      default:
        return;
    }
  };

  const mutation = useUpdateAgentPassword(accountId);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    mutation.mutate(
      {
        password,
      },
      {
        onError: (res: any) => {
          reportFailedUpdatePassword(res);
          onRequestClose();
        },
        onSuccess: () => {
          toastSuccess("Password Updated");
          onRequestClose();
        },
      }
    );

    setPassword("");
    setConfirmPassword("");
  };

  const hasError = password !== confirmPassword;

  return (
    <BasicModal
      isOpen={modalIsOpen}
      closeModal={onRequestClose}
      label="update-agent-password-method"
      title="Update Agent Password"
    >
      <form onSubmit={handleSubmit}>
        <ModalContentContainer>
          <CenteredRow>
            <Input
              type="password"
              value={password}
              name="password"
              label="Set password"
              required
              aria-required
              pattern={passwordPattern}
              title={passwordTitle}
              handleChange={handleInputChange}
            />
            <Input
              type="password"
              value={confirmPassword}
              name="confirmPassword"
              label="Confirm password"
              required
              aria-required
              pattern={passwordPattern}
              title={passwordTitle}
              handleChange={handleInputChange}
              error={hasError}
              errorText="Error: passwords do not match"
            />
          </CenteredRow>
        </ModalContentContainer>
        <ActionContainer>
          <ActionRow>
            <SaveButton
              data-cy="submit-changes"
              color="primary"
              disabled={isDisabled}
              type="submit"
              data-testid="submit-form"
            >
              Update Password
            </SaveButton>
          </ActionRow>
        </ActionContainer>
      </form>
    </BasicModal>
  );
};

const CenteredRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  justify-content: center;
`;

export default UpdateAgentPasswordModal;
