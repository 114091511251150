import React from "react";
import { Modal, ModalActionBar, ModalBody } from "coterie-ui-library";
import ClaimOverview from "./Claims/ClaimsModalComponents/ClaimOverview";

interface ConfirmationModalProps {
  isOpen: boolean;
  confirmationHeader: string;
  confirmationText: string;
  confirmationButtonText: string;
  confirmationAction: (confirmed: boolean) => void;
  policyID: string;
  description: string;
}

const ConfirmationCheckModal = ({
  isOpen,
  confirmationHeader,
  confirmationAction,
  confirmationText,
  confirmationButtonText,
  policyID,
  description,
}: ConfirmationModalProps) => {
  return (
    <Modal title={confirmationHeader} isOpen={isOpen} closeModal={() => confirmationAction(false)} label="Delete Modal">
      <ModalBody>
        <p>
          <b>{confirmationText}</b>
        </p>
        <ClaimOverview policyID={policyID} description={description} />
      </ModalBody>
      <ModalActionBar
        ctaText={confirmationButtonText}
        secondaryText="Cancel"
        handleCTAOnClick={() => confirmationAction(true)}
      />
    </Modal>
  );
};

export default ConfirmationCheckModal;
