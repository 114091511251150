import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { History } from "history";
import { isEmpty } from "lodash";
import { PolicyType, PaymentFrequency } from "types/enums";
import { GreenButton } from "styles";
import { Container, CTAButtonContainer } from "../styles";
import PremiumHeader from "../PremiumHeader";
import LoadingModal from "../LoadingModal";
import LossControlModal from "../LossControlModal";
import Coverages from "shared/application/edit/Coverages/Coverages";
import Endorsements from "shared/application/edit/Endorsements";
import AdditionalInsureds from "shared/application/edit/Endorsements/AdditionalInsured/AdditionalInsureds";
import LossControl from "shared/application/edit/LossControl";
import BusinessBasics from "shared/application/edit/BusinessBasics";
import ContactInfo from "shared/application/edit/ContactInfo";
import {
  selectIsApplicationBindable,
  selectIsBusinessDetailsCompleted,
  selectIsContactDetailsCompleted,
  selectApplicationAvailablePolicyTypes,
  isFieldInvalid,
  selectIsAdditionalInsuredsDetailsCompleted,
  selectQuoteErrors,
  selectIsBOPCoverageCompleted,
  selectIsPLCoverageCompleted,
  selectIsGLCoverageCompleted,
  selectApplicationFormApplicationTypes,
  selectApplicationFormLossControl,
  selectApplicationFormDesiredPolicyType,
} from "redux/selectors/application";
import {
  selectUnderwritingProfile,
  selectUnderwritingProfileEndorsementDetails,
  selectUnderwritingProfilePaymentFrequency,
} from "redux/selectors/underwritingProfile";
import {
  selectIsValidPolicyStartDate,
  selectUnderwritingModalIsOpen,
  selectIsOptInLowerCost,
  selectOptInCostDifferenceMonthly,
  selectOptInCostDifferenceYearly,
} from "redux/selectors/global";
import { selectAvailableEndorsements } from "redux/reducers/_availableEndorsements";
import Card from "./Card";
import { setUnderwritingModalIsOpen } from "redux/reducers/_global";
import { updateApplicationForm } from "redux/reducers/_applicationReducer";
import { Spinner } from "reactstrap";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import { scrollToById } from "utils/scroll";
import { formatCurrency } from "utils/formatNumber";
import Checkmark from "images/checkmark-primary-blue.svg";
import Warning from "images/warning-orange.svg";

interface PropTypes {
  validationErrors: any;
  BOPIsCompleted: boolean;
  PLIsCompleted: boolean;
  GLIsCompleted: boolean;
  isBindable: boolean;
  isBusinessDetailsCompleted: boolean;
  isContactDetailsCompleted: boolean;
  isAdditionalInsuredsCompleted: boolean;
  callback: () => void;
  history: History;
  availablePolicyTypes: PolicyType[];
  underwritingProfile: UnderwritingProfile;
  endorsementDetails: EndorsementDetails;
  availableEndorsements: AvailableEndorsementsState;
  isEmailInvalid: boolean;
  isValidPolicyStartDate: boolean;
  applicationTypes: PolicyType[];
  lossControl?: string;
  isOptInLowerCost: boolean;
  optInCostDifferenceMonthly: number | undefined;
  optInCostDifferenceYearly: number | undefined;
  paymentFrequency: PaymentFrequency;
}

const QuoteEdits = ({
  validationErrors,
  BOPIsCompleted,
  PLIsCompleted,
  GLIsCompleted,
  isBindable,
  isBusinessDetailsCompleted,
  isContactDetailsCompleted,
  isAdditionalInsuredsCompleted,
  callback,
  history,
  availablePolicyTypes,
  underwritingProfile,
  endorsementDetails,
  availableEndorsements,
  isEmailInvalid,
  isValidPolicyStartDate,
  applicationTypes,
  lossControl,
  isOptInLowerCost,
  optInCostDifferenceMonthly,
  optInCostDifferenceYearly,
  paymentFrequency,
}: PropTypes) => {
  const { useErrorInFieldScroll, showHideBopOrRequote } = useFeatureFlags();

  const dispatch = useDispatch();

  const underwritingModalIsOpen = useSelector(selectUnderwritingModalIsOpen);
  const desiredPolicyTypes = useSelector(selectApplicationFormDesiredPolicyType);

  // if desired policy type is ['PL'] and available policy types includes 'BOP' and not "PL" then don't show BOP
  const pushToNeedsApprovalPage =
    desiredPolicyTypes.includes(PolicyType.PL) &&
    !desiredPolicyTypes.includes(PolicyType.BOP) &&
    availablePolicyTypes.includes(PolicyType.BOP) &&
    !availablePolicyTypes.includes(PolicyType.PL);

  const isMonthly = paymentFrequency === PaymentFrequency.Monthly;

  const containerRef = useRef<HTMLDivElement>(null);

  const [isEndorsementsOpen, setIsEndorsementsOpen] = useState(false);
  const [isLossControlOpen, setIsLossControlOpen] = useState(true);
  const [isAdditionalInsuredsOpen, setIsAdditionalInsuredsOpen] = useState(false);
  const [isBusinessBasicsOpen, setIsBusinessBasicsOpen] = useState(false);
  const [isContactInfoOpen, setIsContactInfoOpen] = useState(false);
  const [endorsementsAreInvalid, setEndorsementsAreInvalid] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState("");
  const [phoneValidationError, setPhoneValidationError] = useState(false);
  const [payButtonWasTouched, setPayButtonWasTouched] = useState(false);
  const [payButtonWasClicked, setPayButtonWasClicked] = useState(false);
  const [showLossControlModal, setShowLossControlModal] = useState(false);

  const errorsOrNotBindable =
    !isBindable ||
    underwritingProfile.loadingUnderwritingProfile ||
    endorsementsAreInvalid ||
    !isValidPolicyStartDate ||
    Boolean(emailValidationError) ||
    isEmailInvalid ||
    phoneValidationError ||
    !isAdditionalInsuredsCompleted;

  const showLossControl = Boolean(
    applicationTypes?.includes(PolicyType.BOP) || applicationTypes?.includes(PolicyType.GL)
  );
  const lossControlIsOptIn = lossControl === "OptIn";
  const lossControlIsNotSet = lossControl === "NotSet";

  const handlePayClick = () => {
    if (!useErrorInFieldScroll || !errorsOrNotBindable) {
      if (lossControlIsOptIn || !showLossControl) {
        callback();
      } else {
        setShowLossControlModal(true);
      }
    } else {
      setPayButtonWasClicked(true);
      openAccordionsIfErrorAndScroll();
      setPayButtonWasTouched(true);
    }
  };

  const continueLossControlModal = () => {
    setShowLossControlModal(false);
    callback();
  };

  const addAndContinueLossControlModal = () => {
    setShowLossControlModal(false);
    dispatch(updateApplicationForm({ lossControl: "OptIn" }));
    callback();
  };

  //useEffect that sets the accordion state to open if there is an error in that section on initial load.
  useEffect(() => {
    if (useErrorInFieldScroll) {
      openAccordions();
    }
  }, [
    endorsementsAreInvalid,
    isAdditionalInsuredsCompleted,
    isBusinessDetailsCompleted,
    isContactDetailsCompleted,
    isEmailInvalid,
    phoneValidationError,
  ]);

  useEffect(() => {
    if (!underwritingProfile.loadingUnderwritingProfile) {
      dispatch(setUnderwritingModalIsOpen(false));
    }
  }, [underwritingProfile.loadingUnderwritingProfile]);

  // Callback function to scroll to the error field after smooth collapse animation is completed
  const smoothCollapseCompleted = () => {
    if (useErrorInFieldScroll && isValidPolicyStartDate && payButtonWasClicked) {
      scrollToErrorField();
    }
    setPayButtonWasClicked(false);
  };

  const openAccordions = () => {
    let didAccordionOpen = false;
    if (endorsementsAreInvalid && !isEndorsementsOpen) {
      setIsEndorsementsOpen(true);
      didAccordionOpen = true;
    }
    if (!isAdditionalInsuredsCompleted && !isAdditionalInsuredsOpen) {
      setIsAdditionalInsuredsOpen(true);
      didAccordionOpen = true;
    }
    if (!isBusinessDetailsCompleted && !isBusinessBasicsOpen) {
      setIsBusinessBasicsOpen(true);
      didAccordionOpen = true;
    }
    if (!isContactDetailsCompleted || isEmailInvalid || phoneValidationError) {
      if (!isContactInfoOpen) {
        setIsContactInfoOpen(true);
        didAccordionOpen = true;
      }
    }
    if (!didAccordionOpen) {
      setPayButtonWasClicked(false);
    }
  };

  const scrollIfError = () => {
    if (!isValidPolicyStartDate) {
      scrollToErrorField();
    } else if (endorsementsAreInvalid && isEndorsementsOpen) {
      scrollToErrorField();
    } else if (!isAdditionalInsuredsCompleted && isAdditionalInsuredsOpen) {
      scrollToErrorField();
    } else if (!isBusinessDetailsCompleted && isBusinessBasicsOpen) {
      scrollToErrorField();
    } else if (!isContactDetailsCompleted || isEmailInvalid || phoneValidationError) {
      scrollToErrorField();
    }
  };

  const openAccordionsIfErrorAndScroll = () => {
    scrollIfError();
    openAccordions();
  };

  const scrollForEndorsements = () => {
    if (
      endorsementDetails.liquorLiabilityEndorsement &&
      !endorsementDetails.liquorLiabilityEndorsement?.questionHasBeenAnswered
    ) {
      scrollToById("Liquor-Liability-Endorsement-Question");
    } else if (
      endorsementDetails.hiredAutoAndNonOwnedAuto &&
      !endorsementDetails.hiredAutoAndNonOwnedAuto?.questionHasBeenAnswered
    ) {
      scrollToById("quote-hnoa-consent-checkbox");
    } else if (!endorsementDetails.limitDamagePremRented?.questionHasBeenAnswered) {
      scrollToById("quote-limit-damage-prem-rented");
    } else if (endorsementsAreInvalid) {
      scrollToById("suggested-coverages-section");
    }
  };

  const containsError = (fieldName: string): boolean => {
    return validationErrors.some((error: any) => error.field === fieldName);
  };

  const scrollForLimitsAndPropertyDetailsErrors = () => {
    if (containsError("BppLimit")) {
      scrollToById("quote-business-personal-property-limit");
    } else if (containsError("BuildingLimit")) {
      scrollToById("quote-building-limit");
    } else if (!BOPIsCompleted) {
      scrollToById("BOP-tab");
    } else if (!PLIsCompleted) {
      scrollToById("PL-tab");
    } else if (!GLIsCompleted) {
      scrollToById("GL-tab");
    }
  };

  const scrollForBusinessDetailsErrors = () => {
    if (containsError("BusinessName")) {
      scrollToById("quote-legal-entity-name");
    } else if (containsError("GrossAnnualSales")) {
      scrollToById("quote-gross-annual-sales");
    } else if (containsError("AnnualPayroll")) {
      scrollToById("quote-annual-payroll");
    } else if (containsError("NumberOfEmployees")) {
      scrollToById("quote-num-employees");
    } else if (containsError("BusinessAgeInMonths")) {
      scrollToById("quote-business-age");
    } else {
      scrollToById("business-details-section");
    }
  };

  const scrollForContactDetailsErrors = () => {
    if (containsError("ContactFirstName")) {
      scrollToById("quote-first-name");
    } else if (containsError("ContactLastName")) {
      scrollToById("quote-last-name");
    } else if (containsError("ContactPhone") || phoneValidationError) {
      scrollToById("quote-contact-phone");
    } else if (containsError("ContactEmail") || isEmailInvalid) {
      scrollToById("quote-contact-email");
    } else {
      scrollToById("contact-details-section");
    }
  };

  const scrollToErrorField = () => {
    switch (true) {
      case !isValidPolicyStartDate:
        scrollToById("Policy-Effective-Date");
        break;
      case !BOPIsCompleted || !PLIsCompleted || !GLIsCompleted:
        scrollForLimitsAndPropertyDetailsErrors();
        break;
      case endorsementsAreInvalid:
        scrollForEndorsements();
        break;
      case !isAdditionalInsuredsCompleted:
        scrollToById("quote-individual-insured-checkbox");
        break;
      case !isBusinessDetailsCompleted:
        scrollForBusinessDetailsErrors();
        break;
      case !isContactDetailsCompleted || isEmailInvalid || phoneValidationError:
        scrollForContactDetailsErrors();
        break;
      default:
        break;
    }
  };

  const EndorsementsDetails = [
    {
      id: "suggested-coverages",
      expanded: isEndorsementsOpen,
      toggleExpanded: setIsEndorsementsOpen,
      title: "Suggested Coverages",
      content: <Endorsements />,
    },
  ];

  const lossControlSectionSubtitle = () => {
    if (lossControlIsOptIn) {
      if (isOptInLowerCost) {
        return `Saving ${
          isMonthly
            ? `${formatCurrency(optInCostDifferenceMonthly)}/mo`
            : `${formatCurrency(optInCostDifferenceYearly)}/year`
        } with Manage My Risk discount`;
      } else {
        return "Maximizing business protection";
      }
    } else {
      if (isOptInLowerCost) {
        return `Save ${
          isMonthly
            ? `${formatCurrency(optInCostDifferenceMonthly)}/mo`
            : `${formatCurrency(optInCostDifferenceYearly)}/year`
        } with Manage My Risk discount`;
      } else return "Don’t miss out! Increase protection with Manage My Risk";
    }
  };

  const LossControlDetails = [
    {
      id: "loss-control",
      expanded: isLossControlOpen,
      toggleExpanded: setIsLossControlOpen,
      title: "Manage My Risk Program",
      subtitle: lossControlSectionSubtitle(),
      subtitleIcon: lossControlIsOptIn ? Checkmark : Warning,
      subtitleIsWarning: lossControlIsOptIn ? false : true,
      content: <LossControl lossControlIsOptIn={lossControlIsOptIn} lossControlIsNotSet={lossControlIsNotSet} />,
    },
  ];

  const AIDetails = [
    {
      id: "additional-insureds",
      expanded: isAdditionalInsuredsOpen,
      toggleExpanded: setIsAdditionalInsuredsOpen,
      title: "Additional Insureds",
      content: <AdditionalInsureds />,
    },
  ];

  const BusinessContactDetails = [
    {
      id: "business-details",
      expanded: isBusinessBasicsOpen,
      toggleExpanded: setIsBusinessBasicsOpen,
      title: "Business Details",
      content: <BusinessBasics />,
      invalid: !isBusinessDetailsCompleted,
    },
    {
      id: "contact-details",
      expanded: isContactInfoOpen,
      toggleExpanded: setIsContactInfoOpen,
      title: "Contact Details",
      content: (
        <ContactInfo
          isSimplybind={true}
          emailValidationError={emailValidationError}
          setEmailValidationError={setEmailValidationError}
          phoneValidationError={phoneValidationError}
          setPhoneValidationError={setPhoneValidationError}
          payButtonWasTouched={useErrorInFieldScroll ? payButtonWasTouched : !useErrorInFieldScroll}
        />
      ),
      invalid: !isContactDetailsCompleted || Boolean(emailValidationError) || Boolean(phoneValidationError),
      isLast: true,
    },
  ];

  useEffect(() => {
    if (underwritingProfile.underwritingProfileId && availablePolicyTypes?.length === 0) {
      history.push("/simplybind/coverage-unavailable");
    } else if (showHideBopOrRequote && pushToNeedsApprovalPage) {
      history.push("/simplybind/prof-liab-decline");
    }
  }, [availablePolicyTypes]);

  const endorsementsAreIncomplete = () => {
    return Object.keys(endorsementDetails).some((key) => {
      return !endorsementDetails[key as keyof EndorsementDetails]?.questionHasBeenAnswered;
    });
  };

  useEffect(() => {
    setEndorsementsAreInvalid(endorsementsAreIncomplete());
  }, [endorsementDetails]);

  return (
    <Container ref={containerRef}>
      {underwritingProfile.loadingUnderwritingProfile && underwritingModalIsOpen ? (
        <LoadingModal title="We're underwriting this business...">
          <p>This can take up to 10 seconds, make sure you don't close or refresh this page.</p>
        </LoadingModal>
      ) : null}

      <LossControlModal
        isOpen={showLossControlModal}
        closeModal={() => setShowLossControlModal(false)}
        onSubmit={addAndContinueLossControlModal}
        secondaryButtonAction={continueLossControlModal}
        isMonthly={isMonthly}
      />

      <PremiumHeader isQuoteEditPage />

      <Coverages />

      {!isEmpty(availableEndorsements) &&
        EndorsementsDetails.map((details) => (
          <Card
            key={details.id}
            details={details}
            isInvalid={endorsementsAreInvalid}
            smoothCollapseCompleted={() => smoothCollapseCompleted()}
            showErrorBorder={useErrorInFieldScroll ? payButtonWasTouched : !useErrorInFieldScroll}
          />
        ))}

      {showLossControl &&
        LossControlDetails.map((details) => (
          <Card key={details.id} details={details} smoothCollapseCompleted={() => smoothCollapseCompleted()} />
        ))}

      {AIDetails.map((details) => (
        <Card
          key={details.id}
          details={details}
          isInvalid={!isAdditionalInsuredsCompleted}
          smoothCollapseCompleted={() => smoothCollapseCompleted()}
          showErrorBorder={useErrorInFieldScroll ? payButtonWasTouched : !useErrorInFieldScroll}
        />
      ))}

      {BusinessContactDetails.map((details) => (
        <Card
          key={details.id}
          details={details}
          isInvalid={details?.invalid}
          isLast={details?.isLast}
          smoothCollapseCompleted={() => smoothCollapseCompleted()}
          showErrorBorder={useErrorInFieldScroll ? payButtonWasTouched : !useErrorInFieldScroll}
        />
      ))}

      <CTAButtonContainer>
        <GreenButton
          disabled={
            useErrorInFieldScroll
              ? underwritingProfile.loadingUnderwritingProfile || payButtonWasClicked
              : errorsOrNotBindable
          }
          onClick={handlePayClick}
          dataCy="quote-edit-pay-button"
        >
          {!underwritingProfile.loadingUnderwritingProfile ? "Review and pay" : <Spinner />}
        </GreenButton>
      </CTAButtonContainer>
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  validationErrors: selectQuoteErrors(state),
  BOPIsCompleted: selectIsBOPCoverageCompleted(state),
  PLIsCompleted: selectIsPLCoverageCompleted(state),
  GLIsCompleted: selectIsGLCoverageCompleted(state),
  isBindable: selectIsApplicationBindable(state),
  isBusinessDetailsCompleted: selectIsBusinessDetailsCompleted(state),
  isContactDetailsCompleted: selectIsContactDetailsCompleted(state),
  isAdditionalInsuredsCompleted: selectIsAdditionalInsuredsDetailsCompleted(state),
  availablePolicyTypes: selectApplicationAvailablePolicyTypes(state),
  underwritingProfile: selectUnderwritingProfile(state),
  endorsementDetails: selectUnderwritingProfileEndorsementDetails(state),
  availableEndorsements: selectAvailableEndorsements(state),
  isEmailInvalid: isFieldInvalid("ContactEmail")(state),
  isValidPolicyStartDate: selectIsValidPolicyStartDate(state),
  applicationTypes: selectApplicationFormApplicationTypes(state),
  lossControl: selectApplicationFormLossControl(state),
  isOptInLowerCost: selectIsOptInLowerCost(state),
  optInCostDifferenceMonthly: selectOptInCostDifferenceMonthly(state),
  optInCostDifferenceYearly: selectOptInCostDifferenceYearly(state),
  paymentFrequency: selectUnderwritingProfilePaymentFrequency(state),
});

export default connect(mapStateToProps)(QuoteEdits);
