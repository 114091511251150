import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import { PolicyType, PaymentFrequency } from "types/enums";
import { formatCurrency } from "utils/formatNumber";
import { Text } from "utils/toolTipContent";
import { Header1, Header2 } from "styles/headers";
import { Paragraph } from "styles/paragraph";
import { mobile, tablet } from "styles/responsive";
import AjaxLoader from "shared/AjaxLoader";
import { BindButton } from "shared/application/StyledComponents";
import ApplicationStatusDisplay from "shared/ApplicationStatusDisplay";
import Payment from "shared/Payment";
import ToolTipIcon, { Question as ToolTipQuestion } from "shared/ToolTipIcon";
import greenHalfCircles from "images/half_circles_background_green.svg";
import pl_icon from "images/pl_policy.svg";
import gl_icon from "images/gl_policy.svg";
import bop_icon from "images/bop_policy.svg";
import CheckmarkIcon from "images/checkmark-light-blue.svg";
import WarningIcon from "images/warning-orange.svg";
import useFees from "hooks/useFees";
import { selectSnapshotAlternateQuote } from "redux/reducers/_snapshotReducer";

interface PropTypes {
  loadingQuote: boolean;
  applicationTypes: string[];
  quote: BindableQuote;
  application: Application;
  lossControlIsOptIn: boolean;
}

const QuotePremium = ({ loadingQuote, applicationTypes = [], quote, application, lossControlIsOptIn }: PropTypes) => {
  const alternateQuote = useSelector(selectSnapshotAlternateQuote);
  const { isOptInLowerCost, optInCostDifferenceMonthly, optInCostDifferenceYearly, lossControlOptOut } =
    alternateQuote || {};

  const {
    month1Owed,
    totalYearlyOwed,
    monthlyOwed,
    policyFees,
    monthlyFees,
    installmentFees,
    lossControlFees,
    hasPolicyFee,
    feesSavings,
    hasFirstMonthWaived,
  } = useFees(false);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [frequency, setFrequency] = useState<PaymentFrequency>(PaymentFrequency.None);

  const isMonthly = frequency === PaymentFrequency.Monthly;

  const displayPaymentModal = () => setShowPaymentModal(!showPaymentModal);

  const policies = {
    PL: {
      icon: pl_icon,
      name: "Professional Liability Policy",
      description:
        "Liability insurance that covers you for failure to uphold contractual promises in mostly service-based professions.",
      isCheck: applicationTypes.includes(PolicyType.PL),
    },
    GL: {
      icon: gl_icon,
      name: "General Liability Policy",
      description:
        "Protect you and your business from being held  responsible for damage caused by someone’s property.",
      isCheck: applicationTypes.includes(PolicyType.GL),
    },
    BOP: {
      icon: bop_icon,
      name: "Business Owner’s Policy",
      description: "This policy protects both a business owner’s business and property — all in one package.",
      isCheck: applicationTypes.includes(PolicyType.BOP),
    },
  };

  const { premium = 0, monthlyPremium = 0 } = quote;
  const formattedPremium = isMonthly ? formatCurrency(monthlyPremium) : formatCurrency(premium);
  const formattedDueAtBind = isMonthly ? formatCurrency(month1Owed) : formatCurrency(totalYearlyOwed);
  const alternatePremium = isMonthly
    ? formatCurrency(lossControlOptOut?.monthlyPremium)
    : formatCurrency(lossControlOptOut?.premium);

  const getLossControlSavingsMessage = () => {
    if (isOptInLowerCost) {
      return `${lossControlIsOptIn ? "Saving" : "Save"} ${
        isMonthly ? formatCurrency(optInCostDifferenceMonthly) : formatCurrency(optInCostDifferenceYearly)
      }${isMonthly ? "/mo" : "/year"} with Manage My Risk discount`;
    } else if (lossControlIsOptIn) {
      return "Maximizing business protection with Manage My Risk";
    } else {
      return "Increase protection with Manage My Risk";
    }
  };

  const showPremiumStrikethrough = Boolean(lossControlIsOptIn && isOptInLowerCost);

  return (
    <Container>
      <StatusContainer>
        <ApplicationStatusDisplay isReady={true} />
      </StatusContainer>
      <StyledHeader1>Quote Snapshot</StyledHeader1>
      <StyledParagraph>
        This estimated premium is based on your policy selection and information provided.
      </StyledParagraph>
      <PolicyPremiumContainer>
        <PolicyCard isHidden={!policies.PL.isCheck}>
          <MobileRow>
            <img src={pl_icon} width={70} alt="policy icon" />
            <StyledParagraphBold>{policies.PL.name}</StyledParagraphBold>
          </MobileRow>
          <StyledParagraphSmall>{policies.PL.description}</StyledParagraphSmall>
        </PolicyCard>
        <MathHeader isHidden={applicationTypes.length <= 1}>+</MathHeader>
        <PolicyCard isHidden={!policies.GL.isCheck && !policies.BOP.isCheck}>
          <MobileRow>
            <img src={policies.BOP.isCheck ? policies.BOP.icon : policies.GL.icon} width={70} alt="policy icon" />
            <StyledParagraphBold>{policies.BOP.isCheck ? policies.BOP.name : policies.GL.name}</StyledParagraphBold>
          </MobileRow>
          <StyledParagraphSmall>
            {policies.BOP.isCheck ? policies.BOP.description : policies.GL.description}
          </StyledParagraphSmall>
        </PolicyCard>
        <MathHeader>=</MathHeader>
        <PremiumCardContainer>
          <PremiumCard>
            <TotalDueContainer>
              <TotalDue>Total due at bind</TotalDue>
              <StyledHeader2 data-cy="estimated-snapshot-total">
                {loadingQuote ? <AjaxLoader /> : formattedDueAtBind}
              </StyledHeader2>
              {!loadingQuote && premium === 0 && (
                <StyledParagraphSmallDarkGray>Not enough details to quote a premium.</StyledParagraphSmallDarkGray>
              )}
              {!loadingQuote && (
                <FrequencyButtonContainer>
                  <FrequencyButton
                    onClick={() => setFrequency(PaymentFrequency.Monthly)}
                    active={frequency === PaymentFrequency.Monthly}
                    data-cy="cc-pay-monthly"
                  >
                    Monthly
                  </FrequencyButton>
                  <FrequencyButton
                    onClick={() => setFrequency(PaymentFrequency.None)}
                    active={frequency === PaymentFrequency.None}
                    data-cy="cc-pay-in-full"
                  >
                    Yearly
                  </FrequencyButton>
                </FrequencyButtonContainer>
              )}
              {!loadingQuote && frequency === PaymentFrequency.None && (
                <StyledRecommendedLabel>Recommended</StyledRecommendedLabel>
              )}
            </TotalDueContainer>

            {!loadingQuote && premium !== 0 && (
              <BreakdownContainer>
                <BreakdownLine>
                  <BreakdownLineTitle>Premium</BreakdownLineTitle>
                  {showPremiumStrikethrough ? (
                    <PremiumAmountContainer>
                      <PremiumEntry data-cy="premium-snapshot">{formattedPremium}</PremiumEntry>
                      <StrikethroughPremiumEntry>{alternatePremium}</StrikethroughPremiumEntry>
                    </PremiumAmountContainer>
                  ) : (
                    <BreakdownLineEntry data-cy="premium-snapshot">{formattedPremium}</BreakdownLineEntry>
                  )}
                </BreakdownLine>
                {quote && hasPolicyFee && (
                  <BreakdownLine>
                    <BreakdownLineTitle>Annual Policy Fee</BreakdownLineTitle>
                    <BreakdownLineEntry data-cy="policy-fee-snapshot">
                      {formatCurrency(policyFees as number)}
                    </BreakdownLineEntry>
                  </BreakdownLine>
                )}

                {!hasFirstMonthWaived && (
                  <BreakdownLine>
                    <BreakdownLineTitle>Installment Fee</BreakdownLineTitle>
                    <BreakdownLineEntry data-cy="installment-fee-snapshot">
                      {formatCurrency(installmentFees as number)}
                    </BreakdownLineEntry>
                  </BreakdownLine>
                )}

                {lossControlIsOptIn && (
                  <BreakdownLine>
                    <BreakdownLineTitle>Manage My Risk Program</BreakdownLineTitle>
                    <BreakdownLineEntry data-cy="loss-control-fee-snapshot">
                      {isMonthly
                        ? formatCurrency(lossControlFees as number)
                        : formatCurrency((lossControlFees * 12) as number)}
                    </BreakdownLineEntry>
                  </BreakdownLine>
                )}

                <OverrideToolTipStyle />
                {isMonthly && (
                  <BreakdownLine monthlyPayment={true}>
                    <BreakdownLineTitle>Future Monthly Payments</BreakdownLineTitle>
                    <BreakdownLineEntry data-cy="future-monthly-snapshot">
                      {formatCurrency(monthlyOwed as number)}
                      {monthlyFees && (
                        <ToolTipIcon
                          content={
                            <Text>
                              Future monthly payments include the monthly premium and the following fee
                              {lossControlIsOptIn && "s"}:
                              <ul>
                                <li>{formatCurrency(installmentFees as number)}/mo installment fee</li>
                                {lossControlIsOptIn && (
                                  <li>{formatCurrency(lossControlFees as number)}/mo Manage My Risk</li>
                                )}
                              </ul>
                            </Text>
                          }
                        />
                      )}
                    </BreakdownLineEntry>
                  </BreakdownLine>
                )}
              </BreakdownContainer>
            )}
            <BindButton
              data-cy="snapshot-bind-button"
              disabled={loadingQuote || premium === 0}
              onClick={displayPaymentModal}
            >
              Buy Policy
            </BindButton>
            {showPaymentModal && (
              <Payment
                closeModal={displayPaymentModal}
                modalIsOpen={showPaymentModal}
                quote={quote}
                application={application}
                paymentFrequency={frequency}
              />
            )}
          </PremiumCard>
          {!loadingQuote && quote && feesSavings > 0 && (
            <SavingsText isWarningStyle={isMonthly}>
              <Icon>
                <img src={isMonthly ? WarningIcon : CheckmarkIcon} alt="Icon" />
              </Icon>
              {isMonthly ? "Save" : "Saving"} {formatCurrency(feesSavings)}
              {isMonthly && " in fees"} by selecting yearly plan
            </SavingsText>
          )}
          {!loadingQuote && quote && (
            <SavingsText isWarningStyle={!lossControlIsOptIn}>
              <Icon>
                <img src={lossControlIsOptIn ? CheckmarkIcon : WarningIcon} alt="Icon" />
              </Icon>
              {getLossControlSavingsMessage()}
            </SavingsText>
          )}
        </PremiumCardContainer>
      </PolicyPremiumContainer>
    </Container>
  );
};

const TotalDueContainer = styled.div``;

const BreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 0 16px;
  font-family: ${({ theme }) => theme.font.typeface.secondary};
`;

const BreakdownLine = styled.div<{ monthlyPayment?: boolean }>`
  order: 2;
  display: flex;
  flex-direction: row;
  color: #4a4a4d;
  line-height: 20px;
  margin-right: 16px;

  ${({ monthlyPayment }) => {
    if (monthlyPayment) {
      return `
        font-weight: 600;
        margin-top: 8px;
        margin-right: 2px;
      `;
    }
  }}
`;

const BreakdownLineTitle = styled.div`
  font-size: 14px;
  order: 1;
  flex-grow: 1;
  color: inherit;
  text-align: left;
`;

const BreakdownLineEntry = styled.div`
  font-size: 14px;
  order: 2;
  flex-grow: 1;
  color: inherit;
  text-align: right;
`;

const PremiumAmountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  order: 2;
`;

const PremiumEntry = styled.span`
  color: ${(props) => props.theme.textNavyBlue};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 2px;
  line-height: 18px;
`;

const StrikethroughPremiumEntry = styled.span`
  color: ${(props) => props.theme.blueStrikethroughColor};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 13px;
  font-weight: 400;
  text-decoration: line-through;
`;

const StatusContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 960px;
  justify-content: flex-end;
  margin-top: 16px;
  ${tablet} {
    max-width: 88vw;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  background-image: url(${greenHalfCircles});
  padding-bottom: 40px;
`;

const StyledHeader1 = styled(Header1)`
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  margin: 0 0 8px 0;
  line-height: 32px;
  ${mobile} {
    max-width: 88vw;
    text-align: center;
  }
`;

const StyledHeader2 = styled(Header2)`
  color: ${({ theme }) => theme.colors.tertiary.zero};
  font-size: 40px;
  font-weight: 600;
  white-space: nowrap;
  ${mobile} {
    font-size: 35px;
    line-height: 42px;
  }
  line-height: normal;
  margin: 8px 0;
`;

const MathHeader = styled.div<{ isHidden?: boolean }>`
  display: ${({ isHidden }) => (isHidden ? "none" : "flex")};
  align-items: center;
  color: ${({ theme }) => theme.colors.tertiary.zero};
  font-size: 60px;
  font-weight: 600;
  padding: 16px;
  ${mobile} {
    justify-content: center;
    height: 64px;
  }
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 16px;
  max-width: 600px;
  margin: auto;
  text-align: center;
  ${mobile} {
    max-width: 291px;
  }
`;

const StyledParagraphBold = styled(Paragraph)`
  font-size: 20px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.colors.tertiary.zero};
  line-height: 30px;
  margin-top: 8px;
  padding: 0 16px;
  ${mobile} {
    text-align: left;
    line-height: 24px;
    padding: 0 18px 0 16px;
    margin-top: 0;
  }
`;

const TotalDue = styled(Paragraph)`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.colors.tertiary.zero};
  margin: 0;
  padding: 0;
  line-height: 15px;
  letter-spacing: 1px;
  ${mobile} {
    text-align: left;
    line-height: 24px;
    padding: 0 18px 0 16px;
    margin-top: 0;
  }
`;

const StyledParagraphSmall = styled(Paragraph)`
  font-size: 14px;
  line-height: 24px;
  margin: 24px 8px 0;
  color: ${({ theme }) => theme.black};
  ${mobile} {
    text-align: left;
    margin: 8px 16px 16px;
  }
`;

const StyledParagraphSmallDarkGray = styled(StyledParagraphSmall)`
  color: ${({ theme }) => theme.colors?.neutral?.dark};
  margin: 24px 16px 0;
`;

const MobileRow = styled.div`
  ${mobile} {
    display: flex;
    padding: 0 0 0 16px;
  }
`;

const PolicyPremiumContainer = styled.div`
  display: flex;
  margin: 32px 0 0;
  ${mobile} {
    flex-direction: column;
    height: fit-content;
  }
`;

const PolicyCard = styled.div<{ isHidden?: boolean }>`
  display: ${({ isHidden }) => (isHidden ? "none" : "flex")};
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  width: 200px;
  height: 320px;
  padding-top: 24px;
  text-align: center;
  background-color: ${({ theme }) => theme.offWhite};
  ${mobile} {
    width: 288px;
    height: 160px;
    padding-top: 16px;
  }
`;

const PremiumCard = styled(PolicyCard)`
  color: ${({ theme }) => theme.textDarkBlue};
  padding-top: 0;
  justify-content: space-evenly;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.blue};
  width: 270px;
  height: 320px;
  ${mobile} {
    flex-direction: row;
    padding: 0 0 0 16px;
  }
`;

const FrequencyButtonContainer = styled.div``;

const FrequencyButton = styled.button<{ active: boolean }>`
  margin: 0;
  padding: 8px 16px;
  background-color: ${(props) => (props.active ? props.theme.blue : props.theme.offWhite)};
  color: ${(props) => (props.active ? props.theme.white : props.theme.colors.tertiary.zero)};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  :hover {
    background-color: ${(props) => props.theme.blue};
    color: ${(props) => props.theme.white};
    cursor: pointer;
  }
  :first-child {
    border-radius: 100px 0 0 100px;
    margin-right: 2px;
  }
  :last-child {
    border-radius: 0 20px 20px 0;
  }
`;

const StyledRecommendedLabel = styled.p`
  background-color: ${(props) => props.theme.colors.secondary.twentyFive};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.secondary.dark};
  color: ${(props) => props.theme.colors.secondary.dark};
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  position: static;
  width: fit-content;
  margin: 10px auto 0;
`;

const SavingsText = styled.div<{ isWarningStyle: boolean }>`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => (props.isWarningStyle ? props.theme.darkOrange : props.theme.blueStrikethroughColor)};
  background: ${(props) => (props.isWarningStyle ? props.theme.orange25 : "transparent")};
  margin: 8px 0 0;
  width: fit-content;
`;

const Icon = styled.span`
  display: flex;
  justify-content: flex-start;
`;

const PremiumCardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OverrideToolTipStyle = createGlobalStyle`
  ${ToolTipQuestion} {
    position: relative;
    bottom: 2px;
  }
`;

export default QuotePremium;
