import React, { useState } from "react";
import { Modal, ModalBody, ModalActionBar, Alert } from "coterie-ui-library";
import styled from "styled-components";
import useGetCancelPreview from "hooks/useGetCancelPreview";
import { useParams } from "react-router";
import { ReactComponent as CircleDollarIcon } from "images/hand-holding-circle-dollar.svg";
import { ReactComponent as WarningIcon } from "images/warning-yellow-dark.svg";
import { Checkbox } from "styles";
import { formatCurrency } from "utils/formatNumber";

type CancelPreviewModalProps = {
  open: boolean;
  isPending: boolean;
  cancelRequestError: boolean;
  openModal: (isOpen: boolean) => void;
  cancelRequest: () => void;
  cancelDate: string;
};

export default function CancelPreviewModal({
  open,
  openModal,
  cancelDate,
  cancelRequest,
  isPending,
  cancelRequestError,
}: CancelPreviewModalProps) {
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const { policyNumber } = useParams<{ policyNumber: string }>();

  const { data } = useGetCancelPreview(policyNumber, cancelDate);

  if (!data) return null;

  const { earnedPremium, writtenPremium, chargedPremium, refundRequestAmount, amountOwedByCustomer } = data;
  const isRefund = refundRequestAmount > 0;

  return (
    <Modal title="Cancellation Preview" label="cancel preview modal" isOpen={open} closeModal={() => openModal(!open)}>
      <ModalBody>
        {cancelRequestError && (
          <ErrorContainer>
            <Alert
              color="red"
              title="Server Error"
              message={`Unable to send cancel policy request for policy #${policyNumber}. Please try again.`}
            />
          </ErrorContainer>
        )}
        <LeadText>Here is the breakdown to be charged or refunded from this cancellation:</LeadText>
        <PreviewLineItems>
          <PreviewLineItem aria-label="Earned Premium">
            <span>Earned Premium:</span>
            <span className="amount">{formatCurrency(earnedPremium)}</span>
          </PreviewLineItem>
          <PreviewLineItem aria-label="Written Premium">
            <span>Written Premium:</span>
            <span className="amount">{formatCurrency(writtenPremium)}</span>
          </PreviewLineItem>
          <PreviewLineItem aria-label="Amount paid to date">
            <span>Amount paid to date:</span>
            <span className="amount">{formatCurrency(chargedPremium)}</span>
          </PreviewLineItem>
          <PreviewLineItem isTotal aria-label="Total">
            <span>{isRefund ? "Refund amount" : "Remaining premium"}:</span>
            <span className="amount">{formatCurrency(isRefund ? refundRequestAmount : amountOwedByCustomer)}</span>
          </PreviewLineItem>
        </PreviewLineItems>
        <AmountAlert isRefund={isRefund}>
          <AmountAlertIcon>{isRefund ? <CircleDollarIcon /> : <WarningIcon />}</AmountAlertIcon>
          <AmountAlertText isRefund={isRefund}>
            {isRefund
              ? `Insured will be refunded ${formatCurrency(refundRequestAmount)} to the credit card on file. Refunds may
                take up to 7-10 business days.`
              : `Monthly payments will be charged as scheduled until the cancellation effective date. If a refund is applicable, it will be processed after the cancellation effective date and may take up to 7-10 business days.`}
          </AmountAlertText>
        </AmountAlert>
        <ConfirmationText>
          By clicking submit, you confirm that you have reviewed all fields and want to proceed with canceling this
          policy.
        </ConfirmationText>
        <div>
          <Checkbox
            label={
              <span>
                I have reviewed and agree to Coterie’s{" "}
                <a href="https://coterieinsurance.com/terms-conditions/" target="_blank" rel="noreferrer">
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a href="https://coterieinsurance.com/privacy-policy/" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
                .
              </span>
            }
            name="consent"
            handleClick={({ target: { checked } }) => setTermsAccepted(checked)}
            checked={termsAccepted}
            required
          />
        </div>
      </ModalBody>
      <ModalActionBar handleCTAOnClick={cancelRequest} ctaDisabled={!termsAccepted || isPending} ctaText="Submit" />
    </Modal>
  );
}

const LeadText = styled.p`
  color: ${({ theme }) => theme.colors.tertiary.zero};
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
`;

const ConfirmationText = styled.p`
  color: ${({ theme }) => theme.colors.tertiary.zero};
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 24px 0 18px 0;
`;

const PreviewLineItems = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 360px;
`;

const PreviewLineItem = styled.li<{ isTotal?: boolean }>`
  color: ${({ theme, isTotal }) => (isTotal ? theme.colors.tertiary.zero : theme.colors.neutral.dark)};
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: ${({ isTotal }) => (isTotal ? "18px" : "16px")};
  font-style: normal;
  font-weight: ${({ isTotal }) => (isTotal ? "600" : "400")};
  line-height: 24px;
  display: flex;
  gap: 12px;
  margin: ${({ isTotal }) => (isTotal ? "24px 0" : "0 0 4px 0")};

  .amount {
    justify-content: end;
  }

  span {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: start;
  }
`;

const AmountAlert = styled.div<{ isRefund?: boolean }>`
  background: ${({ isRefund, theme }) => (isRefund ? theme.colors.secondary.twentyFive : theme.colors.pl.ten)};
  display: flex;
  border-radius: 4px;
  gap: 4px;
  padding: 8px 4px 8px 8px;
`;

const AmountAlertText = styled.p<{ isRefund?: boolean }>`
  margin: 0;
  overflow: hidden;
  color: ${({ isRefund, theme }) => (isRefund ? theme.colors.secondary.dark : theme.colors.pl.dark)};
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.09px;
`;

const AmountAlertIcon = styled.div``;

const ErrorContainer = styled.div`
  margin-bottom: 16px;
  width: 100%;
`;
