import React from "react";
import styled from "styled-components";
import { GhostButton } from "shared/SimplyBind/styles";
import { ModalContentContainer, ActionRow, ActionContainer, ModalText } from "shared/ModalComponents";
import ModalBasic from "shared/ModalBasic";
import { useHistory } from "react-router-dom";
import { GreenButton } from "styles";

interface ApiSourceEditQuoteModalProps {
  onClose: () => void;
  applicationId: string;
}

const ApiSourceEditQuoteModal = ({ onClose, applicationId }: ApiSourceEditQuoteModalProps) => {
  const history = useHistory();
  return (
    <ModalBasic isOpen={true} closeModal={onClose} label="Review Your Quote" title="Review Your Quote">
      <ModalContentContainer>
        <ModalText>
          Please review your coverage selections and complete any missing information.
          <DisclaimerText>
            There is a potential that editing the quote may result in a premium difference or declination from what was
            previously quoted.
          </DisclaimerText>
        </ModalText>
      </ModalContentContainer>
      <ActionContainer>
        <ActionRow>
          <StyledGhostButton onClick={onClose}>Cancel</StyledGhostButton>
          <StyledGreenButton onClick={() => history.push(`/simplybind/quote/${applicationId}`)}>
            Continue Quote
          </StyledGreenButton>
        </ActionRow>
      </ActionContainer>
    </ModalBasic>
  );
};

const StyledGhostButton = styled(GhostButton)`
  min-width: 156px;
`;

const StyledGreenButton = styled(GreenButton)`
  min-width: 156px;
`;

const DisclaimerText = styled.div`
  margin-top: 12px;
  margin-bottom: 20px;
`;

export default ApiSourceEditQuoteModal;
