import React, { useState } from "react";
import { Modal, ModalBody } from "coterie-ui-library";
import ModalActionBar from "./ClaimsModalComponents/ModalActionBar";
import { toastError, toastSuccess } from "utils/toast";
import { Progress } from "reactstrap";
import PolicyDetails from "./ClaimsModalComponents/PolicyDetails";
import ContactDetails from "./ClaimsModalComponents/ContactDetails";
import ClaimDetails from "./ClaimsModalComponents/ClaimDetails";
import ClaimOverview from "./ClaimsModalComponents/ClaimOverview";
import ReviewClaimSubmission from "./ClaimsModalComponents/ReviewClaimSubmission";
import useGetPolicy from "hooks/useGetPolicy";
import usePostCreateClaim from "hooks/claims/usePostCreateClaim";

interface ClaimsModalProps {
  isOpen: boolean;
  policyID: string;
  claimID: string;
  description: string;
  dateOfLoss: string;
  incidentTime: string;
  toggleModal: () => void;
}

const ClaimsModal = ({
  isOpen,
  toggleModal,
  policyID,
  claimID,
  description,
  dateOfLoss,
  incidentTime,
}: ClaimsModalProps) => {
  const [page, setPage] = useState<number>(0);
  const [formData, setFormData] = useState<ClaimFormData>();

  const { data: PolicyData, isLoading: fetchingPolicy } = useGetPolicy([policyID]);
  const { isPending: creatingClaim, mutate: postClaimData } = usePostCreateClaim({
    onSettled: () => toggleModal(),
    onSuccess: () => toastSuccess("FNOL Claim Processed Correctly."),
    onError: () => toastError("There was an error processing the FNOL Claim"),
  });

  const updateFormData = (formPage: string, payload: any) => {
    const updatedFormData: ClaimFormData = { ...formData };
    switch (formPage) {
      case "contact":
        updatedFormData.contactData = { ...payload };
        break;
      case "policy":
        updatedFormData.policyData = { ...payload };
        break;
      case "claim":
        updatedFormData.claimData = { ...payload };
        break;
      default:
        return;
    }
    setFormData(updatedFormData);
  };

  const progressForm = () => {
    page < 4 ? setPage(page + 1) : submitForm();
  };

  const backFormPage = () => {
    setPage(page - 1);
  };

  const submitForm = async () => {
    postClaimData({ formData, description, claimID });
  };

  const policy = Array.isArray(PolicyData) ? PolicyData[0] : {};

  const formPages = [
    <ClaimOverview policyID={policyID} description={description} />,
    <ContactDetails updateFormData={updateFormData} formData={formData} policy={policy} />,
    <PolicyDetails policyID={policyID} updateFormData={updateFormData} formData={formData} policy={policy} />,
    <ClaimDetails
      updateFormData={updateFormData}
      formData={formData}
      dateOfLoss={dateOfLoss}
      incidentTime={incidentTime}
    />,
    <ReviewClaimSubmission formData={formData} />,
  ];

  return (
    <Modal
      title="Create First Notice of Loss Claim"
      isOpen={isOpen}
      closeModal={() => toggleModal()}
      label="Claims Modal"
    >
      <Progress striped color="info" value={(page / 4) * 100}>
        {page} of 4
      </Progress>
      <ModalBody>{formPages[page]}</ModalBody>
      <ModalActionBar
        closeModal={toggleModal}
        disabled={fetchingPolicy || creatingClaim}
        onSubmit={progressForm}
        backFormPage={backFormPage}
        page={page}
      />
    </Modal>
  );
};

export default ClaimsModal;
