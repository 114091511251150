import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { portalApi } from "./useAPI/instances";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const ingestApplication = async (
  token: Token,
  apiVersion: string,
  payload?: Partial<Application>,
  applicationId?: string
) => {
  const api = portalApi(apiVersion, token);
  const { data } = await api.post(`/underwriting/${applicationId}/ingest`, payload);
  return data;
};

type Options = { onSuccess?: (res: any) => void; onError: (res: any) => void; enabled?: boolean };

const useUnderwritingIngestApplication = (
  applicationId?: string,
  payload?: Partial<Application>,
  options?: Options
) => {
  const token = useSelector((state: ReduxState) => state.token.token);
  const { apiVersion } = useFeatureFlags();
  return useMutation({
    mutationKey: [applicationId],
    mutationFn: () => ingestApplication(token, apiVersion, payload, applicationId),
    retry: 0,
    ...options,
  });
};

export default useUnderwritingIngestApplication;
