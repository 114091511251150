import React, { createContext, useReducer } from "react";
import {
  underwritingRulesReducer,
  underwritingRulesInitialState,
  UnderwritingRulesState,
} from "./UnderwritingRulesReducer";
import { Rule } from "../Shared/types";
import { UnderwritingRulesActionTypes } from "./types";

interface RuleBuilderContextProps extends UnderwritingRulesState {
  resetRule: () => void;
  updateRulePath: (path: string) => void;
  updateLogicBlock: (type: string) => void;
  setRule: (rule: Rule) => void;
  updateVariable: (variable: string) => void;
  updateMessage: (message: string) => void;
  updateStates: (states: string[]) => void;
  updateControlAction: (controlAction: string) => void;
  updateControlTarget: (controlTarget: string) => void;
  updateConditionTarget: (conditionTarget: string, index: number) => void;
  updateConditionOperator: (conditionOperator: string, index: number) => void;
  updatePropertyName: (name: string, index: number) => void;
  updatePropertyValue: (value: string, index: number) => void;
  addTagBlock: () => void;
  deleteTagBlock: () => void;
  setTags: (value: string[]) => void;
  addPropertyBlock: () => void;
  addMessageBlock: () => void;
  addControlBlock: () => void;
  addStatesBlock: () => void;
  addNestedSubrule: () => void;
  addSubrule: () => void;
  addRootRuleOption: () => void;
  deleteBlock: (blockType: string) => void;
  addMultiCondition: (aggregationType: string) => void;
  deleteMultiCondition: (index: number) => void;
  deleteProperty: (index: number) => void;
  toggleRuleDisabledState: () => void;
  toggleNegation: () => void;
  updateLabel: (label: string) => void;
}
export const RuleBuilderContext = createContext<RuleBuilderContextProps>({} as RuleBuilderContextProps);

const RuleBuilderProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(underwritingRulesReducer, underwritingRulesInitialState);

  const value = {
    rule: state.rule,
    ruleIsCreate: state.ruleIsCreate,
    activeRulePath: state.activeRulePath,
    activeLogicBlock: state.activeLogicBlock,
    resetRule: () => {
      dispatch({ type: UnderwritingRulesActionTypes.RESET_RULE });
    },
    updateLogicBlock: (type: string) => {
      dispatch({ type: UnderwritingRulesActionTypes.UPDATE_ACTIVE_LOGIC_BLOCK, payload: type });
    },
    updateRulePath: (path: string) => {
      dispatch({ type: UnderwritingRulesActionTypes.UPDATE_ACTIVE_RULE_PATH, payload: path });
    },
    setRule: (rule: Rule) => {
      dispatch({ type: UnderwritingRulesActionTypes.SET_RULE, payload: rule });
    },
    updateVariable: (variable: string) => {
      dispatch({ type: UnderwritingRulesActionTypes.UPDATE_VARIABLE, payload: variable });
    },
    updateMessage: (message: string) => {
      dispatch({ type: UnderwritingRulesActionTypes.UPDATE_MESSAGE, payload: message });
    },
    updateControlTarget: (controlTarget: string) => {
      dispatch({ type: UnderwritingRulesActionTypes.UPDATE_CONTROL_TARGET, payload: controlTarget });
    },
    updateControlAction: (controlAction: string) => {
      dispatch({ type: UnderwritingRulesActionTypes.UPDATE_CONTROL_ACTION, payload: controlAction });
    },
    updateConditionTarget: (conditionTarget: string, index: number) => {
      dispatch({
        type: UnderwritingRulesActionTypes.UPDATE_CONDITION_TARGET,
        payload: { conditionTarget: conditionTarget, index: index },
      });
    },
    updateConditionOperator: (conditionOperator: string, index: number) => {
      dispatch({
        type: UnderwritingRulesActionTypes.UPDATE_CONDITION_OPERATOR,
        payload: { conditionOperator: conditionOperator, index: index },
      });
    },
    updateStates: (states: string[]) => {
      dispatch({ type: UnderwritingRulesActionTypes.UPDATE_STATES, payload: states });
    },
    addMessageBlock: () => {
      dispatch({ type: UnderwritingRulesActionTypes.ADD_MESSAGE_BLOCK });
    },
    addControlBlock: () => {
      dispatch({ type: UnderwritingRulesActionTypes.ADD_CONTROL_BLOCK });
    },
    addStatesBlock: () => {
      dispatch({ type: UnderwritingRulesActionTypes.ADD_STATES_BLOCK });
    },
    addNestedSubrule: () => {
      dispatch({ type: UnderwritingRulesActionTypes.ADD_NESTED_SUBRULE });
    },
    addSubrule: () => {
      dispatch({ type: UnderwritingRulesActionTypes.ADD_SUBRULE });
    },
    addRootRuleOption: () => {
      dispatch({ type: UnderwritingRulesActionTypes.ADD_ROOT_RULE_OPTION });
    },
    addPropertyBlock: () => {
      dispatch({ type: UnderwritingRulesActionTypes.ADD_PROPERTY_BLOCK });
    },
    deleteProperty: (index: number) => {
      dispatch({ type: UnderwritingRulesActionTypes.DELETE_PROPERTY, payload: index });
    },
    addTagBlock: () => {
      dispatch({ type: UnderwritingRulesActionTypes.ADD_TAG_BLOCK });
    },
    deleteTagBlock: () => {
      dispatch({ type: UnderwritingRulesActionTypes.DELETE_TAG_BLOCK });
    },
    setTags: (tags: string[]) => {
      dispatch({ type: UnderwritingRulesActionTypes.SET_TAGS, payload: tags });
    },
    deleteBlock: (blockType: string) => {
      dispatch({ type: UnderwritingRulesActionTypes.DELETE_BLOCK, payload: blockType });
    },
    addMultiCondition: (aggregationType: string) => {
      dispatch({ type: UnderwritingRulesActionTypes.ADD_MULTI_CONDITION, payload: aggregationType });
    },
    deleteMultiCondition: (index: number) => {
      dispatch({ type: UnderwritingRulesActionTypes.DELETE_MULTI_CONDITION, payload: index });
    },
    toggleRuleDisabledState: () => {
      dispatch({ type: UnderwritingRulesActionTypes.TOGGLE_RULE_DISABLED_STATE });
    },
    toggleNegation: () => {
      dispatch({ type: UnderwritingRulesActionTypes.TOGGLE_NEGATION });
    },
    updateLabel: (label: string) => {
      dispatch({ type: UnderwritingRulesActionTypes.UPDATE_LABEL, payload: label });
    },
    updatePropertyName: (name: string, index: number) => {
      dispatch({ type: UnderwritingRulesActionTypes.UPDATE_PROPERTY_NAME, payload: { name: name, index: index } });
    },
    updatePropertyValue: (value: string, index: number) => {
      dispatch({ type: UnderwritingRulesActionTypes.UPDATE_PROPERTY_VALUE, payload: { value: value, index: index } });
    },
  };

  return <RuleBuilderContext.Provider value={value}>{children}</RuleBuilderContext.Provider>;
};

export default RuleBuilderProvider;
