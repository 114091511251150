import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  underwritingModalIsOpen: false,
  isValidPolicyStartDate: false,
  alternateQuote: {},
};

const global = createSlice({
  name: "global",
  initialState,
  reducers: {
    setUnderwritingModalIsOpen: (state, { payload }) => {
      state.underwritingModalIsOpen = payload;
    },
    setIsValidPolicyStartDate: (state, { payload }) => {
      state.isValidPolicyStartDate = payload;
    },
    setAlternateQuote: (state, { payload }) => {
      state.alternateQuote = payload;
    },
  },
});

export const { setUnderwritingModalIsOpen, setIsValidPolicyStartDate, setAlternateQuote } = global.actions;

export default global.reducer;
