import React, { useState } from "react";
import styled from "styled-components";
import { GreenButton, Label } from "styles";
import { Container, CTAButtonContainer } from "../styles";
import { StyledIndustrySearch } from "shared/application/StyledComponents";
import AjaxLoader from "shared/AjaxLoader";
import { reportIndustrySearchError } from "utils/reportError";

interface PropTypes {
  isSadPath: boolean;
  callback: (industryName: string, akHash: string) => void;
  handleShowErrorPage: () => void;
}

const IndustryInput = ({ isSadPath, callback, handleShowErrorPage }: PropTypes) => {
  const [industry, setIndustry] = useState({
    industryId: 0,
    industryName: "",
    akHash: "",
  });

  const updateIndustry = (industryId: number, industry: string, akHash: string) => {
    setIndustry({ industryId: industryId, industryName: industry, akHash: akHash });
  };

  const handleNextClick = () => callback(industry.industryName, industry.akHash);

  const disabledNextButton = !Boolean(industry.akHash) || industry.industryId === null;

  return (
    <Container>
      {isSadPath && (
        <SadPathIndustryHeaderText>
          Enter the industry that represents the majority of your client’s revenue
        </SadPathIndustryHeaderText>
      )}
      <InputContainer>
        <Label htmlFor="industry-search-input">Industry</Label>
        <StyledIndustrySearch
          selectIndustry={updateIndustry}
          industryId={industry.industryId}
          industryName={industry.industryName}
          hasError={industry.industryId === null}
          loadingElement={<AjaxLoader />}
          reportError={reportIndustrySearchError}
        />
      </InputContainer>
      <CTAButtonContainer>
        <GreenButton onClick={handleNextClick} disabled={disabledNextButton}>
          Next
        </GreenButton>
      </CTAButtonContainer>
    </Container>
  );
};

const InputContainer = styled.div`
  max-width: 512px;
  width: 90%;
`;

const SadPathIndustryHeaderText = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
`;

export default IndustryInput;
