import omit from "lodash/omit";
import { POLICY_DEFAULTS } from "utils/constants";
import { LocationTypes } from "types/enums";

const setValueHelper = (value, defaultValue) => {
  if (value === undefined || value === null) {
    return defaultValue;
  } else {
    return value;
  }
};

export const PL_LIMIT_OCC_AGG = {
  25000: 50000,
  50000: 100000,
  100000: 250000,
  250000: 500000,
  500000: 1000000,
  750000: 2000000,
  1000000: 3000000,
};

const applicationValidator = (application) => {
  try {
    const { applicationTypes } = application;
    if (!applicationTypes) {
      return application;
    }
    const isPL = applicationTypes.includes("PL");
    const isBOP = applicationTypes.includes("BOP");
    const isGL = applicationTypes.includes("GL");
    const isOnlyPL = isPL && applicationTypes.length === 1;

    const newApplication = { ...application };

    if (!isPL) {
      delete newApplication.professionalLiability;
    }

    if (!isGL && !isBOP) {
      delete newApplication.glLimit;
      delete newApplication.propertyDamageLiabilityDeductible;
    }

    if (!isBOP) {
      delete newApplication.bppDeductible;

      const locations =
        newApplication.locations?.length > 0
          ? omit(newApplication.locations[0], ["locationType", "bppLimit", "hasSprinklers", "buildingLimit"])
          : {};

      newApplication.locations = [locations];
    }

    if (isBOP && isGL) {
      newApplication.applicationTypes = newApplication.applicationTypes.filter((item) => item !== "GL");
    }

    if (!isBOP && (isPL || isGL) && newApplication.endorsements?.endorsementPackage) {
      newApplication.endorsements = { ...newApplication.endorsements, endorsementPackage: "None" };
    }

    if (isOnlyPL) {
      delete newApplication.glAggregateLimit;
      delete newApplication.glAggregatePcoLimit;
      newApplication.lossControl = "NotSet";
    }

    if (isPL && newApplication.professionalLiability) {
      newApplication.professionalLiability = {
        ...newApplication.professionalLiability,
        aggregateLimit: PL_LIMIT_OCC_AGG[newApplication.professionalLiability.occurrenceLimit],
        grossRevenue: newApplication.grossAnnualSales || 0,
      };
    }

    const glLimit = setValueHelper(newApplication.glLimit, POLICY_DEFAULTS.GL.glLimit);
    const propertyDamageLiabilityDeductible = setValueHelper(
      newApplication.propertyDamageLiabilityDeductible,
      POLICY_DEFAULTS.GL.propertyDamageLiabilityDeductible
    );
    const lossControlIsNotSetOrNull = !newApplication?.lossControl || newApplication.lossControl === "NotSet";
    const lossControlGLorBOP = lossControlIsNotSetOrNull
      ? "OptIn"
      : setValueHelper(newApplication.lossControl, POLICY_DEFAULTS.GL.lossControl);

    const GL_DEFAULTS =
      isGL || isBOP
        ? {
            glLimit,
            propertyDamageLiabilityDeductible,
            lossControl: lossControlGLorBOP,
          }
        : {};

    const bppDeductible = setValueHelper(newApplication.bppDeductible, POLICY_DEFAULTS.BOP.bppDeductible);

    if (Array.isArray(newApplication.locations) && typeof newApplication.locations[0]?.zip === "string") {
      newApplication.locations = [
        { ...newApplication.locations[0], zip: newApplication.locations[0].zip.replace(/\s/g, "") },
      ];
    }

    const { locations } = newApplication;
    const location = Boolean(Array.isArray(locations) && locations.length > 0) ? locations[0] : {};

    const bppLimit = setValueHelper(location.bppLimit, POLICY_DEFAULTS.BOP.locations[0].bppLimit);

    const hasBuildingLimit = location.locationType === LocationTypes.BuildingOwned && location.state !== "FL";
    const buildingLimit = hasBuildingLimit
      ? setValueHelper(location.buildingLimit, POLICY_DEFAULTS.BOP.locations[0].buildingLimit)
      : undefined;

    const BOP_DEFAULTS = isBOP
      ? {
          ...GL_DEFAULTS,
          bppDeductible,
          locations: [{ ...location, bppLimit, buildingLimit }],
        }
      : {};

    const deductibleAmount = setValueHelper(
      newApplication.professionalLiability?.deductibleAmount,
      POLICY_DEFAULTS.PL.professionalLiability.deductibleAmount
    );

    const occurrenceLimit = setValueHelper(
      newApplication.professionalLiability?.occurrenceLimit,
      POLICY_DEFAULTS.PL.professionalLiability.occurrenceLimit
    );

    const PL_DEFAULTS = isPL
      ? {
          professionalLiability: {
            ...newApplication.professionalLiability,
            deductibleAmount,
            occurrenceLimit,
          },
        }
      : {};

    return {
      ...newApplication,
      ...GL_DEFAULTS,
      ...BOP_DEFAULTS,
      ...PL_DEFAULTS,
    };
  } catch (error) {
    console.log(error);
  }
};

export default applicationValidator;
