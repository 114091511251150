import React from "react";
import styled, { css } from "styled-components";
import { StyledHeader3, CTAButtonContainer, HeaderTextContainer, PageHeaderContainer, GhostButton } from "./styles";
import { TextLink, tablet } from "styles";
import { errorData } from "./pages/ErrorScreen";
import { selectUnderwritingProfileDeclinationLedger } from "redux/selectors/underwritingProfile";
import { connect } from "react-redux";
import { flatten, uniq } from "lodash";
import { ReactComponent as CloseIcon } from "images/close.svg";
import { useHistory } from "react-router-dom";
import { isCanadaEnv } from "utils/environmentChecker";

interface DeclinationScreenProps {
  declinationLedger: DeclinationLedger;
}

const DeclinationScreen = ({ declinationLedger }: DeclinationScreenProps) => {
  const history = useHistory();
  const declinationMessages = flatten(
    Object.keys(declinationLedger.data).map((key) => declinationLedger.data[key].value)
  );
  const uniqueDeclinationMessages = !declinationMessages.length
    ? ["This coverage has been declined due to underwriting program guidelines."]
    : uniq(declinationMessages);

  const headerText = errorData()["coverage-unavailable"].headerText;
  const detailsText = "This is based on information you provided as well as publicly available information.";

  return (
    <>
      <PageHeaderContainer>
        <StyledHeaderTextContainer>
          <Header>{headerText}</Header>
        </StyledHeaderTextContainer>
      </PageHeaderContainer>
      <Container>
        {uniqueDeclinationMessages.slice(0, 3).map((message) => (
          <DeclinationMessageContainer>
            <CloseIconContainer>
              <CloseIcon height={12} width={12} />
            </CloseIconContainer>
            <DeclinationMessage>{message}</DeclinationMessage>
          </DeclinationMessageContainer>
        ))}
        <DetailsTextContainer>
          <DetailsText>{detailsText}</DetailsText>
          <DetailsText>
            Visit{" "}
            <DetailsTextLink
              as="a"
              href={`https://${process.env.REACT_APP_DOMAIN}/producer-resources`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`https://${process.env.REACT_APP_DOMAIN}/producer-resources`}
            </DetailsTextLink>{" "}
            for details about Coterie's speed, simplicity, and service. We look forward to your next submission!
          </DetailsText>
        </DetailsTextContainer>
      </Container>
      <CTAButtonContainer>
        <GhostButton onClick={() => history.push("/simplybind/get-started")}>Close</GhostButton>
      </CTAButtonContainer>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  declinationLedger: selectUnderwritingProfileDeclinationLedger(state),
});

const Container = styled.div`
  ${!isCanadaEnv() &&
  css`
    margin-top: 55px;
  `}
  padding: 0 50px;
  max-width: 80%;
  ${tablet} {
    max-width: 100%;
  }
`;

const Header = styled(StyledHeader3)`
  color: ${(props) => (isCanadaEnv() ? props.theme.styledHeader3 : props.theme.textNavyBlue)};
  padding: 0 3em;
  text-align: ${isCanadaEnv() ? "center" : "left"};
  ${tablet} {
    padding: 0 2em;
  }
`;

const StyledHeaderTextContainer = styled(HeaderTextContainer)`
  padding-bottom: 1em;
`;

const DeclinationMessageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

const DeclinationMessage = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
`;

const CloseIconContainer = styled.div`
  margin-right: 24px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.red};
  display: flex;
  align-items: center;
  padding: 10px;
  path {
    fill: ${(props) => props.theme.red};
  }
`;

const DetailsTextContainer = styled.div`
  margin-top: 40px;
`;

const DetailsText = styled.p`
  font-size: 18px;
  font-family: ${(props) => props.theme.secondaryFont};
`;

const DetailsTextLink = styled(TextLink)`
  font-size: 18px;
  font-weight: 400;
`;

export default connect(mapStateToProps)(DeclinationScreen);
