import AirKitEmbed from "ia/AirKitEmbed";
import React from "react";
import ModalBasic from "shared/ModalBasic";
import { ModalContentContainer } from "shared/ModalComponents";
import styled from "styled-components";
import { toastSuccess } from "utils/toast";

interface PropTypes {
  modalIsOpen: boolean;
  closeModal: () => void;
  policy: Policy;
  email?: string;
}

const ActivePolicyCancelModal = ({ policy, modalIsOpen, closeModal, email }: PropTypes) => {
  const handleCancelPolicySuccess = (event: Record<string, unknown>) => {
    closeModal();
    toastSuccess("Policy cancelled.");
    window.location.reload();
  };

  return (
    <ModalBasic isOpen={modalIsOpen} closeModal={closeModal} label="cancel-policy" title="Cancel Policy">
      <ModalContainer>
        <AirKitEmbed
          body={{ policyNumber: policy.policyNumber, agent: email }}
          url={process.env.REACT_APP_AIRKIT_CANCEL_URL as string}
          name="cancel-policy-iframe"
          onCancelSuccess={handleCancelPolicySuccess}
        />
      </ModalContainer>
    </ModalBasic>
  );
};

const ModalContainer = styled(ModalContentContainer)`
  overflow: hidden;
  padding: 0;
`;

export default ActivePolicyCancelModal;
