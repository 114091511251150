import { Rule } from "./RuleSearch";
import { Dictionary } from "lodash";
import { RuleSearchActions, RuleSearchState, RuleSearchActionTypes } from "./types";

export const ruleSearchInitialState = {
  filteredRules: [],
  availableVariables: [],
  variableQuery: "",
  variableOptions: [],
  sortType: "All Rules",
  filters: {
    variable: "",
    tags: [],
    statuses: [],
    control: "",
  },
};

const sortByOldest = (rules: Rule[]) => {
  return [...rules].sort((a, b) => +new Date(a.created) - +new Date(b.created));
};

const sortByNewest = (rules: Rule[]) => {
  return [...rules].sort((a, b) => +new Date(b.created) - +new Date(a.created));
};

const sortAlphabetically = (rules: Rule[]) => {
  return [...rules].sort((a, b) => a.variable.localeCompare(b.variable));
};

const sortByActive = (rules: Rule[]) => {
  return [...rules].sort((a, b) => Number(a.disabled) - Number(b.disabled));
};

const sortByInactive = (rules: Rule[]) => {
  return [...rules].sort((a, b) => Number(b.disabled) - Number(a.disabled));
};

const sortRules = (type: string, rules: Rule[]) => {
  switch (type) {
    case "Oldest":
      return sortByOldest(rules);
    case "Newest":
      return sortByNewest(rules);
    case "Active":
      return sortByActive(rules);
    case "Inactive":
      return sortByInactive(rules);
    default:
      return sortAlphabetically(rules);
  }
};

const findAllAvailableVariables = (rules: Dictionary<any>, arr: string[] = []) => {
  Object.keys(rules).forEach((key) => {
    if (rules[key] && typeof rules[key] === "object" && !Array.isArray(rules[key])) {
      findAllAvailableVariables(rules[key], arr);
    } else {
      if (key === "variable" && !arr.includes(rules[key])) arr.push(rules[key]);
    }
  });
  return arr.sort((a, b) => a.localeCompare(b));
};

const mapVariablesToOptionsArray = (allVariables: string[], searchedVariables?: string[]) => {
  const variablesToFilterBy = searchedVariables ?? allVariables;
  return variablesToFilterBy
    .filter((variable: string) => allVariables.includes(variable))
    .map((variable: string) => {
      return { value: variable, label: variable };
    });
};

export const ruleSearchReducer = (state: RuleSearchState, action: RuleSearchActions) => {
  switch (action.type) {
    case RuleSearchActionTypes.SET_FILTERED_RULES:
      return { ...state, filteredRules: sortRules(state.sortType, action.payload) };
    case RuleSearchActionTypes.SORT_RULES:
      return { ...state, sortType: action.payload, filteredRules: sortRules(action.payload, state.filteredRules) };
    case RuleSearchActionTypes.SET_AVAILABLE_VARIABLES:
      return { ...state, availableVariables: findAllAvailableVariables(action.payload) };
    case RuleSearchActionTypes.SET_VARIABLE_QUERY:
      return { ...state, variableQuery: action.payload };
    case RuleSearchActionTypes.SET_TAGS_FILTER:
      return { ...state, filters: { ...state.filters, tags: action.payload } };
    case RuleSearchActionTypes.SET_STATUS_FILTER:
      return { ...state, filters: { ...state.filters, statuses: action.payload } };
    case RuleSearchActionTypes.SET_CONTROL_FILTER:
      return { ...state, filters: { ...state.filters, control: action.payload } };
    case RuleSearchActionTypes.SET_VARIABLE_OPTIONS:
      return { ...state, variableOptions: mapVariablesToOptionsArray(state.availableVariables, action.payload) };
    case RuleSearchActionTypes.CLEAR_VARIABLE_FILTER:
      return {
        ...state,
        variableOptions: mapVariablesToOptionsArray(state.availableVariables),
        variableQuery: "",
        filters: { ...state.filters, variable: "" },
      };
    case RuleSearchActionTypes.CLEAR_FILTERS:
      return { ...state, filters: { ...ruleSearchInitialState.filters, variable: state.filters.variable } };
    case RuleSearchActionTypes.SET_VARIABLE_FILTER:
      return { ...state, filters: { ...state.filters, variable: action.payload } };
    default:
      throw new Error("Must use a valid action type for the Rule Search reducer");
  }
};
