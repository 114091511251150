import { theme as uiLibraryTheme } from "coterie-ui-library";
import { uxComponents } from "@coterieinsure/ux-components";

const theme = {
  ...uiLibraryTheme,
  uxComponents,
  feeTextColor: "#3491CC",
  darkOrange: "#8F6224",
  blueStrikethroughColor: "#3391CC",
  blueSavingsTextColor: "#4222FF",

  pageComponents: {
    global: {
      legacyBackground: uiLibraryTheme.colors.neutral.special,
      background: uiLibraryTheme.colors.neutral.white,
    },
    ia: {
      policyView: {
        bottomBorder: uiLibraryTheme.colors.neutral.light,
        overduePolicyToast: {
          title: {
            color: uiLibraryTheme.colors.alert.dark,
            fontFamily: uiLibraryTheme.font.typeface.primary,
          },
          subtitle: {
            color: uiLibraryTheme.colors.neutral.dark,
            fontFamily: uiLibraryTheme.font.typeface.primary,
          },
        },
        policyStatus: {
          active: {
            icon: uiLibraryTheme.colors.secondary.dark,
            text: uiLibraryTheme.colors.secondary.dark,
            bg: uiLibraryTheme.colors.secondary.eighty,
          },
          expired: {
            icon: uiLibraryTheme.colors.neutral.medium,
            text: uiLibraryTheme.colors.neutral.medium,
            bg: uiLibraryTheme.colors.neutral.light,
          },
          cancelled: {
            icon: uiLibraryTheme.colors.alert.dark,
            text: uiLibraryTheme.colors.alert.dark,
            bg: uiLibraryTheme.colors.alert.twenty,
          },
          pending: {
            icon: uiLibraryTheme.colors.pl.dark,
            text: uiLibraryTheme.colors.pl.dark,
            bg: uiLibraryTheme.colors.pl.twentyFive,
          },
          pendingCancellation: {
            icon: uiLibraryTheme.colors.pl.dark,
            text: uiLibraryTheme.colors.pl.dark,
            bg: uiLibraryTheme.colors.pl.twentyFive,
          },
        },
      },
      paymentMethodUpdate: {
        dotBg: uiLibraryTheme.colors.tertiary.eighty,
        card: {
          shadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.10)",
          bg: uiLibraryTheme.colors.neutral.white,
        },
        cardNum: {
          color: uiLibraryTheme.colors.tertiary.eighty,
          fontFamily: uiLibraryTheme.font.typeface.secondary,
        },
        cardExpLabel: {
          color: uiLibraryTheme.colors.tertiary.eighty,
          fontFamily: uiLibraryTheme.font.typeface.primary,
        },
        cardExp: {
          color: uiLibraryTheme.colors.tertiary.eighty,
          fontFamily: uiLibraryTheme.font.typeface.secondary,
        },
      },
      policyEdits: {
        headerText: uiLibraryTheme.colors.tertiary.zero,
        subHeaderText: uiLibraryTheme.colors.neutral.dark,
        goBackText: uiLibraryTheme.colors.primary.zero,
        goBackTextHover: uiLibraryTheme.colors.primary.fifty,
        errorIcon: uiLibraryTheme.colors.alert.dark,
      },
      policyTitle: {
        background: uiLibraryTheme.colors.neutral.white,
        bottomBorder: uiLibraryTheme.colors.neutral.light,
      },
      dbaTitle: {
        color: uiLibraryTheme.colors.neutral.medium,
      },
      yearMonthInterval: {
        color: uiLibraryTheme.colors.neutral.medium,
      },
      policyDetails: {
        background: uiLibraryTheme.colors.neutral.special,
      },
      policyInformation: {
        tableBackground: uiLibraryTheme.colors.neutral.white,
        productType: {
          bop: uiLibraryTheme.colors.bop.sixty,
          gl: uiLibraryTheme.colors.secondary.eighty,
          pl: uiLibraryTheme.colors.pl.fifty,
        } as { [key: string]: string },
        productTypeSubheader: {
          bop: uiLibraryTheme.colors.bop.thirty,
          gl: uiLibraryTheme.colors.secondary.twentyFive,
          pl: uiLibraryTheme.colors.pl.twentyFive,
        } as { [key: string]: string },
        secondaryHeader: uiLibraryTheme.colors.neutral.light,
      },
      policyDocuments: {
        productType: {
          bop: uiLibraryTheme.colors.bop.sixty,
          gl: uiLibraryTheme.colors.secondary.eighty,
          pl: uiLibraryTheme.colors.pl.fifty,
        } as { [key: string]: string },
        title: {
          color: uiLibraryTheme.colors.neutral.black,
          fontFamily: uiLibraryTheme.font.typeface.primary,
        },
        header: {
          color: uiLibraryTheme.colors.neutral.black,
          fontFamily: uiLibraryTheme.font.typeface.primary,
        },
        list: {
          odd: uiLibraryTheme.colors.neutral.white,
          even: uiLibraryTheme.colors.neutral.light,
        },
        listItem: {
          color: uiLibraryTheme.colors.neutral.dark,
          fontFamily: uiLibraryTheme.font.typeface.primary,
        },
      },
      paymentHistory: {
        overdue: uiLibraryTheme.colors.alert.twenty,
        overdueText: uiLibraryTheme.colors.alert.dark,
        paid: uiLibraryTheme.colors.neutral.dark,
        dot: uiLibraryTheme.colors.alert.dark,
      },
    },
    kit: {
      policyView: {
        policySelect: {
          productType: {
            bop: uiLibraryTheme.colors.bop.zero,
            gl: uiLibraryTheme.colors.secondary.zero,
            pl: uiLibraryTheme.colors.pl.zero,
          } as { [key: string]: string },
          boxShadow: uiLibraryTheme.dropShadows.ds2.boxShadow,
          background: {
            selected: uiLibraryTheme.colors.neutral.white,
            unselected: uiLibraryTheme.colors.neutral.special,
          },
          policyNumber: uiLibraryTheme.colors.neutral.medium,
        },
      },
      imageUpload: {
        imageUploadArea: {
          border: {
            error: uiLibraryTheme.fontColors.error,
            hasPreview: uiLibraryTheme.fontColors.primary,
            default: uiLibraryTheme.blue,
          },
        },
        buttonColor: uiLibraryTheme.colors.primary.zero,
        labelColor: {
          dragAndDrop: uiLibraryTheme.colors.tertiary.zero,
          browse: uiLibraryTheme.colors.primary.zero,
          accepted: uiLibraryTheme.colors.neutral.dark,
        },
        textColor: {
          fileName: uiLibraryTheme.colors.tertiary.zero,
          fileSize: uiLibraryTheme.colors.neutral.dark,
        },
      },
    },
    shared: {
      CoverageExclusionAccordion: {
        caretColor: uiLibraryTheme.colors.tertiary.zero,
        cardTitleColor: uiLibraryTheme.colors.tertiary.zero,
        cardBackgroundColor: uiLibraryTheme.colors.neutral.white,
        cardBulletColor: uiLibraryTheme.colors.tertiary.zero,
        cardListFont: uiLibraryTheme.font.typeface.secondary,
        bop: { cardBorderColor: uiLibraryTheme.colors.bop.zero, cardBackgroundColor: uiLibraryTheme.colors.bop.thirty },
        gl: {
          cardBorderColor: uiLibraryTheme.colors.secondary.zero,
          cardBackgroundColor: uiLibraryTheme.colors.secondary.eighty,
        },
      },
      confirmationPage: {
        title: {
          color: uiLibraryTheme.colors.tertiary.zero,
          font: uiLibraryTheme.font.typeface.secondary,
        },
        thankYou: {
          cardBackgroundColor: uiLibraryTheme.colors.tertiary.zero,
          fontColor: uiLibraryTheme.colors.neutral.white,
          font: uiLibraryTheme.font.typeface.secondary,
        },
        listItems: {
          font: uiLibraryTheme.font.typeface.secondary,
          color: uiLibraryTheme.gray,
        },
        emailColors: {
          supportEmail: uiLibraryTheme.colors.primary.zero,
          userEmails: uiLibraryTheme.colors.neutral.dark,
        },
        summarySeparator: `1px solid ${uiLibraryTheme.colors.neutral.regular}`,
      },
    },
  },
};

export default theme;
