import { Document, Font } from "@react-pdf/renderer";
import gilroySemibold from "./fonts/gilroy-semibold.woff";
import gilroySemibold2 from "./fonts/gilroy-semibold.woff2";
import questrial from "./fonts/questrial.woff";
import gilroyLight from "../../styles/fonts/Gilroy-Light/font.woff";
import gilroyLight2 from "../../styles/fonts/Gilroy-Light/font.woff2";
import gilroyBold from "../../styles/fonts/Gilroy-Bold/font.woff";
import gilroyBold2 from "../../styles/fonts/Gilroy-Bold/font.woff2";
import {
  Header,
  PremiumOptions,
  Page,
  CoverageOverview,
  CoverageTablePages,
  FormsTable,
  FinePrint,
  AdditionalInsureds,
  PolicyHolderDetails,
  AddOns,
  LossControl,
} from "./components";
import { theme } from "styles";
import { Forms, AddOnsType, AlternateQuoteType } from "utils/proposal-pdf/constants";
import { getBlanketAIs, getBlanketTitles } from "./helpers";
import { PolicyType } from "types/enums";

Font.register({
  family: theme.font.typeface.primary,
  fonts: [
    { src: gilroyBold, fontWeight: 600 },
    { src: gilroyBold2 },
    { src: gilroySemibold, fontWeight: 300 },
    { src: gilroySemibold2 },
    { src: gilroyLight, fontWeight: 200 },
    { src: gilroyLight2 },
  ],
});

Font.register({
  family: theme.font.typeface.secondary,
  fonts: [{ src: questrial }],
});

Font.registerHyphenationCallback((word) => {
  return [word];
});
interface CorePDFProps {
  forms?: Forms;
  quote: Quote;
  agentEmail?: string;
  agencyName?: string;
  agencyLogo?: string;
  application: Application;
  industryName: string;
  naicCode?: number;
  increaseFlPropertyMin: boolean;
  alternateQuote: AlternateQuoteType;
}

const CorePDF = ({
  quote,
  agentEmail,
  agencyName,
  agencyLogo,
  application,
  forms,
  industryName,
  naicCode,
  increaseFlPropertyMin,
  alternateQuote,
}: CorePDFProps) => {
  const addOns: AddOnsType = {
    equipmentBreakdownEndorsement: {
      title: "Equipment Breakdown Coverage",
      description:
        "This coverage helps protect your business equipment from unexpected events like power surges or outages.",
    },
    hiredAutoAndNonOwnedAuto: {
      title: "Hired and Non-Owned Auto Coverage",
      description:
        "This coverage protects your business when personal autos are used for work-related reasons. Limits are included in the policy's General Liability limits.",
    },
    liquorLiabilityEndorsement: {
      title: "Liquor Liability Coverage",
      description:
        "This coverage protects your business for the sales, service, and furnishing of alcoholic beverages.",
    },
    restaurantCoverage: {
      title: "Restaurant Coverage",
      description:
        "This coverage helps protect your restaurant from expenses associated with food spoilage or contamination.",
    },
    contractorsInstallationAndTools: {
      title: "Contractor Tools Coverage",
      description:
        "This coverage protects your tools and provides coverage for property you've sold which remains your responsibility until installation is complete.",
      limit: application?.endorsements?.contractorsInstallationAndTools?.selectedContractorsToolsLimit,
      sublimit: "There is a sublimit of $2,000 per tool.",
    },
  };

  const addOnsArray = Object.keys(application?.endorsements ?? {}).filter(
    (result: string) => addOns[result as keyof typeof addOns]
  );

  const { applicationTypes, endorsements } = application;

  const hasAddOns: boolean = addOnsArray.length > 0;
  const hasBOP = applicationTypes?.includes(PolicyType.BOP);
  const hasBOPorGL = Boolean(applicationTypes?.includes(PolicyType.BOP) || applicationTypes?.includes(PolicyType.GL));
  const blanketAIs = getBlanketAIs(endorsements);
  const blanketTitles = getBlanketTitles(blanketAIs);
  const hasBlanketAIs = Object.values(blanketAIs).some((value) => value);
  const hasIndividualAIs = endorsements?.additionalInsureds?.length;

  return (
    <Document>
      {/* Page 1 */}
      <Page>
        <Header agentEmail={agentEmail} agencyName={agencyName} agencyLogo={agencyLogo} />
        <PremiumOptions application={application} quote={quote} alternateQuote={alternateQuote} />
        <PolicyHolderDetails application={application} industryName={industryName} naicCode={naicCode} quote={quote} />
      </Page>

      {/* Page 2 */}
      <Page>
        <CoverageOverview application={application} increaseFlPropertyMin={increaseFlPropertyMin} />
      </Page>

      {/* Page 3 */}
      {(hasAddOns || hasBlanketAIs || hasIndividualAIs) && (
        <Page>
          {hasAddOns && <AddOns addOnsArray={Array.from(addOnsArray, (addOnKey: string) => addOns[addOnKey])} />}
          <AdditionalInsureds
            application={application}
            hasBlanketAIs={hasBlanketAIs}
            hasIndividualAIs={hasIndividualAIs}
            blanketTitles={blanketTitles}
          />
        </Page>
      )}

      {/* Page 4 */}
      {hasBOP && (
        <Page>
          <CoverageTablePages application={application} />
        </Page>
      )}

      {/* Page 5 */}
      <Page>{forms && <FormsTable forms={forms} />}</Page>

      {/* Page 6 */}
      <Page>
        <FinePrint application={application} quote={quote} />
      </Page>

      {/* Last Page if GL or BOP */}
      {hasBOPorGL && (
        <Page>
          <LossControl application={application} alternateQuote={alternateQuote} />
        </Page>
      )}
    </Document>
  );
};

export default CorePDF;
