import React from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "images/close.svg";
import { RuleStatusDropdown, TagsDropdown, FilterBubbleTriangle, UnderwritingActionFilter } from "./FilterComponents";

interface RuleFiltersProps {
  onClose: () => void;
}

const RuleFilters = ({ onClose }: RuleFiltersProps) => {
  return (
    <FilterContainer data-testid="filters-menu">
      <FilterBubbleTriangle />
      <CloseButton aria-label="Close filter options" onClick={onClose}>
        <CloseIcon width={16} height={16} />
      </CloseButton>
      <RuleStatusDropdown />
      <TagsDropdown />
      <UnderwritingActionFilter />
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  background: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.blue};
  border-radius: 4px;
  padding: 25px 75px;
  margin-top: 20px;
  width: 100%;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
`;

const CloseButton = styled.button`
  background: transparent;
  position: absolute;
  width: fit-content;
  right: 10px;
  top: 10px;
  svg path {
    fill: ${(props) => props.theme.blue};
  }
`;

export default RuleFilters;
