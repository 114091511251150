enum ProductLabel {
  BOP = "Business Owner's Policy",
  GL = "General Liability",
  PL = "Professional Liability",
}

export const PRODUCT_LABELS = {
  BOP: ProductLabel.BOP,
  GL: ProductLabel.GL,
  PL: ProductLabel.PL,
} as { [key: string]: string };
