import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AjaxLoader from "shared/AjaxLoader";
import ApplicationsTable from "./ApplicationsTable";
import { useHistory, useParams } from "react-router-dom";
import {
  SearchInput,
  SearchContainer,
  SearchIconWrapper,
  TitleContainer,
  ContentContainer,
  TitleBarAccessory,
  Title,
} from "styles/containerStyledComponents";
import { ApplicationSortBy, ApplicationStatus, SortDirection, ApplicationSource } from "types/enums";
import { Breadcrumb, Dropdown, theme } from "styles";
import debounce from "lodash.debounce";
import TabList from "shared/TabList";
import useAuth from "hooks/useAuth";
import { ReactComponent as SearchIcon } from "images/search_icon.svg";
import { DropdownWrapper, DropdownLabel, StyledLoadingDiv } from "ia/AccountSearch";
import useGetAgencyProducers, { GetAgencyProducersResponse } from "hooks/useGetAgencyProducers";
import { getUserExternalId } from "redux/selectors/user";
import useGetQuoteSearch from "hooks/useGetQuoteSearch";
import useGetLegacyApplication from "hooks/useGetLegacyApplication";
import MissingInfoModal from "shared/SimplyBind/MissingInfoModal";
import ReadyToPayModal from "./ReadyToPayModal";
import RequoteModal from "./RequoteModal";
import { resetUnderwritingProfile } from "redux/reducers/_underwritingProfileReducer";
import { resetApplication } from "redux/reducers/_applicationReducer";
import { connect } from "react-redux";
import ApiSourceEditQuoteModal from "./ApiSourceEditQuoteModal";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const tabListStyles = {
  tabContainerStyles: {
    background: "inherit",
    height: 48,
    maxWidth: 250,
    margin: "0 0 0",
    borderBottom: "1px solid lightgrey",
    marginTop: "-10px",
  },
  buttonStyles: { color: "black", fontSize: 18, background: "inherit" },
  selectedIndicator: `2px solid ${theme.blue}`,
};

const AppSortByMapper: Record<string, ApplicationSortBy> = {
  Status: ApplicationSortBy.Status,
  Name: ApplicationSortBy.Name,
  BusinessName: ApplicationSortBy.BusinessName,
  ApplicationTypes: ApplicationSortBy.ApplicationTypes,
  CreatedOn: ApplicationSortBy.CreatedOn,
};

const AppSortDirection: Record<string, SortDirection> = {
  Ascending: SortDirection.Ascending,
  Descending: SortDirection.Descending,
};

interface ApplicationSearchProps {
  resetUnderwritingProfile: ActionCreatorWithoutPayload<any>;
  resetApplication: ActionCreatorWithoutPayload<any>;
}

const ApplicationSearch = ({ resetUnderwritingProfile, resetApplication }: ApplicationSearchProps) => {
  const urlParams = new URLSearchParams(window.location.search);
  const paramQuery = urlParams.get("q") ?? "";
  const paramSortBy = AppSortByMapper[urlParams.get("sortBy") ?? ApplicationSortBy.CreatedOn];
  const paramSortDirection = AppSortDirection[urlParams.get("sortDirection") ?? SortDirection.Descending];

  const { showNonLicensedUserLink } = useFeatureFlags();
  const { isIAUser } = useAuth();
  const history = useHistory();
  const params: { applicationId: string } = useParams();
  const breadcrumb = "Quotes";

  const [query, setQuery] = useState(paramQuery);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(paramQuery);
  const [sortBy, setSortBy] = useState(paramSortBy);
  const [sortDirection, setSortDirection] = useState(paramSortDirection);
  const [selectedTab, setSelectedTab] = useState(1);

  const externalId = useSelector(getUserExternalId);

  const [producerId, setProducerId] = useState(externalId);
  const isOnArchiveTab = selectedTab !== 1;

  const [legacyApplicationId, setLegacyApplicationId] = useState("");
  const [showReadyToPayModal, setShowReadyToPayModal] = useState(false);
  const [showMissingInfoModal, setShowMissingInfoModal] = useState(false);
  const [showRequoteModal, setShowRequoteModal] = useState(false);
  const [showApiSourceEditQuoteModal, setShowApiSourceEditQuoteModal] = useState(false);

  const {
    data: legacyApplication,
    isLoading: isLoadingLegacyApplication,
    isSuccess: isSuccessLegacyApplication,
  } = useGetLegacyApplication(legacyApplicationId, {
    enabled: Boolean(legacyApplicationId),
  });

  useEffect(() => {
    if (isSuccessLegacyApplication) {
      if (legacyApplication?.application.source === ApplicationSource.API) {
        setShowApiSourceEditQuoteModal(true);
      } else if (legacyApplication?.application?.status === ApplicationStatus.ReadyToQuote) {
        setShowReadyToPayModal(true);
      } else {
        setShowRequoteModal(true);
      }
    }
  }, [isSuccessLegacyApplication, legacyApplication]);

  const { data: producers } = useGetAgencyProducers({
    enabled: showNonLicensedUserLink,
    shouldReturnNonLicensed: true,
  });
  const {
    data: quotes,
    isLoading,
    refetch,
  } = useGetQuoteSearch({
    searchText: debouncedSearchQuery,
    sortBy,
    sortDirection,
    producerId,
    isOnArchiveTab,
  });

  const onChangeInput = (event: React.FormEvent<HTMLInputElement>) => {
    let { value } = event.currentTarget;
    setQuery(value);
  };

  const sortApplications = (sortBy: ApplicationSortBy, sortDirection: SortDirection) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  const handleFilterOnChange = (producerOption: GetAgencyProducersResponse) => {
    const isClearing = producerOption === null;
    setProducerId(isClearing ? externalId : producerOption.value);
  };

  const debounceQuoteSearchQuery = useCallback(
    debounce((searchQuery) => setDebouncedSearchQuery(searchQuery), 500),
    []
  );

  useEffect(() => {
    debounceQuoteSearchQuery(query);
  }, [query]);

  useEffect(() => {
    history.replace({
      pathname: "/quotes",
      search: `?q=${debouncedSearchQuery}&sortBy=${sortBy}&sortDirection=${sortDirection}`,
    });
  }, [debouncedSearchQuery, sortBy, sortDirection]);

  useEffect(() => {
    if (params.applicationId) setLegacyApplicationId(params.applicationId);
  }, [params.applicationId]);

  const tabOptions = [
    {
      id: 1,
      label: "Active",
      value: "active",
    },
    {
      id: 2,
      label: "Archived",
      value: "archived",
    },
  ];

  useEffect(() => {
    resetUnderwritingProfile();
    resetApplication();
  }, []);

  return (
    <ContentContainer>
      {showRequoteModal && (
        <RequoteModal
          onClose={() => {
            setShowRequoteModal(false);
            setLegacyApplicationId("");
          }}
        />
      )}
      {showApiSourceEditQuoteModal && (
        <ApiSourceEditQuoteModal
          onClose={() => setShowApiSourceEditQuoteModal(false)}
          applicationId={legacyApplicationId}
        />
      )}
      {showReadyToPayModal && (
        <ReadyToPayModal
          applicationId={legacyApplicationId}
          onClose={() => setShowReadyToPayModal(false)}
          onGoToQuoteEdit={() => {
            setShowReadyToPayModal(false);
            setShowMissingInfoModal(true);
          }}
          editButtonIsLoading={isLoadingLegacyApplication}
        />
      )}
      {showMissingInfoModal && (
        <MissingInfoModal
          onClose={() => setShowMissingInfoModal(false)}
          applicationId={legacyApplicationId}
          onError={() => {
            setShowMissingInfoModal(false);
            setShowRequoteModal(true);
          }}
          application={legacyApplication?.application}
        />
      )}
      <Breadcrumb style={{ marginBottom: "20px" }}>{breadcrumb}</Breadcrumb>
      <StyledTitleContainer>
        {isIAUser && (
          <TabList
            title="Quote Search Sections"
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabOptions={tabOptions}
            styles={tabListStyles}
          />
        )}
        {!isIAUser && <Title>Quote Search</Title>}
        <TitleBarAccessory>
          <StyledLoadingDiv>{isLoading ? <AjaxLoader /> : <span />}</StyledLoadingDiv>
          <SearchContainer>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <SearchInput
              autoFocus
              data-cy="application-search-input"
              name="query"
              type="text"
              value={query}
              placeholder="Search Quotes"
              onChange={onChangeInput}
            />
          </SearchContainer>
          {showNonLicensedUserLink && (
            <DropdownWrapper>
              {producers && producers?.length > 0 && (
                <>
                  <DropdownLabel htmlFor="quotesFilter-dropdown">Filter by Users</DropdownLabel>
                  <Dropdown
                    onChange={handleFilterOnChange}
                    options={producers}
                    value={producerId}
                    name="quotesFilter"
                    labelIsHidden
                    isClearable
                  />
                </>
              )}
            </DropdownWrapper>
          )}
        </TitleBarAccessory>
      </StyledTitleContainer>
      {Array.isArray(quotes) && (
        <ApplicationsTable
          rows={quotes}
          changeSort={sortApplications}
          sortBy={sortBy}
          sortDirection={sortDirection}
          archiveStatus={isOnArchiveTab}
          query={query}
          loading={isLoading}
          performSearch={refetch}
        />
      )}
    </ContentContainer>
  );
};

const StyledTitleContainer = styled(TitleContainer)`
  justify-content: space-between;
`;

const mapDispatchToProps = {
  resetUnderwritingProfile,
  resetApplication,
};

export default connect(null, mapDispatchToProps)(ApplicationSearch);
