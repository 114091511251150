import {
  updateLiquorLiabilityCoverage,
  updateEquipmentBreakdownCoverage,
  updateRestaurantCoverage,
  updateApplicationFormAuto,
  updateLimitDamagePremRented,
  updateContractorsInstallationAndTools,
  updateFloodCoverage,
  updateCanadaEquipmentBreakdownCoverage,
  updateEarthquakeShockEndorsement,
  updateCrimeEndorsement,
  updatePrivacyBreachEndorsement,
  updateNonOwnedAutomobileEndorsement,
  updateProfitsActualLossSustainedEndorsement,
  updateSewerBackUpEndorsement,
  updateLegalGuardInformationServiceEndorsement,
} from "redux/reducers/_applicationReducer";
import { isCanadaEnv } from "utils/environmentChecker";
import ContractorsInstallationAndTools from "./questions/ContractorsInstallationAndTools";
import HiredNonOwnedAuto from "./questions/HiredNonOwnedAuto";
import LimitDamagePremRentedAddOn from "./questions/LimitDamagePremRentedAddOn";
import LiquorLiability from "./questions/LiquorLiability";
import BlueShield from "images/shield_blue.svg";

export interface EndorsementObj {
  LABEL: string;
  QUESTION_COPY: string;
  COVERAGE_COPY: string;
  DISABLED_DESCRIPTION?: string;
  action: (value: any) => Action;
  selector: string;
  question?: any;
  toolTipKey?: string;
  coverageAbbreviation?: string;
  coverageBullets?: Array<string>;
  packageInclusion?: PackageInclusion;
}

export interface PackageInclusion {
  inclusionHeader: string;
  inclusionBullets: Array<string>;
  inclusionIcon: string;
}

export type EndorsementsMap = {
  [key: string]: EndorsementObj;
};

const coreEndorsementsMap: EndorsementsMap = {
  liquorLiabilityEndorsement: {
    LABEL: "Liquor Liability Coverage",
    QUESTION_COPY: "Add this coverage if you sell, serve or furnish alcoholic beverages.",
    COVERAGE_COPY:
      "This coverage protects your business for the sales, service, and furnishing of alcoholic beverages. Limits match the policy's General Liability limits.",
    DISABLED_DESCRIPTION:
      "This coverage is not available with $2M/$4M liability limits. Please change your limits to add Liquor Liability Coverage.",
    action: updateLiquorLiabilityCoverage,
    selector: "endorsements.liquorLiabilityEndorsement.shouldInclude",
    question: LiquorLiability,
  },
  liquorLiabilityEndorsement100: {
    LABEL: "Liquor Liability Coverage",
    QUESTION_COPY: "Add this coverage if you sell, serve or furnish alcoholic beverages.",
    COVERAGE_COPY:
      "This coverage protects your business for the sales, service, and furnishing of alcoholic beverages. Limits match the policy's General Liability limits.",
    DISABLED_DESCRIPTION:
      "This coverage is not available with $2M/$4M liability limits. Please change your limits to add Liquor Liability Coverage.",
    action: updateLiquorLiabilityCoverage,
    selector: "endorsements.liquorLiabilityEndorsement.shouldInclude",
  },
  equipmentBreakdownEndorsement: {
    LABEL: "Equipment Breakdown Coverage",
    QUESTION_COPY:
      "Add this coverage to protect your business equipment from unexpected events like power surges or outages.",
    COVERAGE_COPY:
      "This coverage helps protect your business equipment from unexpected events like power surges or outages.",
    action: updateEquipmentBreakdownCoverage,
    selector: "endorsements.equipmentBreakdownEndorsement",
  },
  restaurantCoverage: {
    LABEL: "Restaurant Coverage",
    QUESTION_COPY:
      "Add this coverage to protect your restaurant from expenses associated with food spoilage or contamination.",
    COVERAGE_COPY:
      "This coverage helps protect your restaurant from expenses associated with food spoilage or contamination.",
    action: updateRestaurantCoverage,
    selector: "endorsements.restaurantCoverage",
  },
  hiredAutoAndNonOwnedAuto: {
    LABEL: "Hired and Non-Owned Auto Coverage",
    QUESTION_COPY: "Add this coverage if your business uses personal autos for work-related reasons.",
    COVERAGE_COPY:
      "This coverage protects your business when personal autos are used for work-related reasons. Limits match the policy's General Liability limits.",
    action: updateApplicationFormAuto,
    selector: "endorsements.hiredAutoAndNonOwnedAuto.shouldInclude",
    question: HiredNonOwnedAuto,
    toolTipKey: "HIRED_AUTO_AND_NON_OWNED_AUTO",
  },
  limitDamagePremRented: {
    LABEL: "Damages to Premises Rented to You (Higher Limits)",
    QUESTION_COPY:
      "Your policy includes $50,000 coverage for premises rented. Do you want to increase your total damage protection?",
    COVERAGE_COPY: "This increases your damage coverage to rented premises.",
    action: updateLimitDamagePremRented,
    selector: "limitDamagePremRented",
    question: LimitDamagePremRentedAddOn,
    toolTipKey: "DAMAGE_TO_PREMISES_RENTED_TO_YOU",
  },
  contractorsToolsEndorsement: {
    LABEL: "Contractor Tools Coverage",
    QUESTION_COPY: "Select additional coverage for tools and installation.",
    COVERAGE_COPY: "This coverage protects your tools and installation.",
    action: updateContractorsInstallationAndTools,
    selector: "endorsements.contractorsInstallationAndTools.selectedContractorsToolsLimit",
    question: ContractorsInstallationAndTools,
    toolTipKey: "CONTRACTORS_TOOLS_ENDORSEMENT",
  },
};

const canadaEndorsementsMap: EndorsementsMap = {
  // canada endorsement additions
  floodEndorsement: {
    LABEL: "Flood",
    QUESTION_COPY:
      "Add this coverage to insure against damage from any natural or artificial body of water including surface water, waves tides, tidal waves and tsunamis",
    COVERAGE_COPY:
      "This coverage insures against damage from any natural or artificial body of water including surface water, waves tides, tidal waves and tsunamis",
    action: updateFloodCoverage,
    selector: "endorsements.floodEndorsement.shouldInclude",
    coverageAbbreviation: "Flood",
    coverageBullets: ["Limits as per Building and/or Contents – Broad Form", "Deductible $50,000"],
  },
  canadaEquipmentBreakdownEndorsement: {
    LABEL: "Equipment Breakdown",
    QUESTION_COPY:
      "Add this coverage to insure against a sudden and accidental failure of equipment resulting in physical damage.",
    COVERAGE_COPY:
      "This coverage insures against a sudden and accidental failure of equipment resulting in physical damage.",
    action: updateCanadaEquipmentBreakdownCoverage,
    selector: "endorsements.canadaEquipmentBreakdownEndorsement.shouldInclude",
    coverageAbbreviation: "EqBrkDwn",
    coverageBullets: [
      "Limits as per Building and/or Contents – Broad Form, up to a maximum of $250,000",
      "Deductible $1,000",
    ],
  },
  earthquakeShockEndorsement: {
    LABEL: "Earthquake Shock",
    QUESTION_COPY:
      "Add this coverage to insure against a sudden accidental failure of equipment resulting in physical damage.",
    COVERAGE_COPY:
      "This coverage to insures against a sudden accidental failure of equipment resulting in physical damage.",
    action: updateEarthquakeShockEndorsement,
    selector: "endorsements.earthquakeShockEndorsement.shouldInclude",
    coverageAbbreviation: "EQShock",
    coverageBullets: [
      "Limits as per Building and/or Contents – Broad Form",
      "Deductible of 3% of Property Limit or $100,000, whichever is greater.",
    ],
  },
  crimeEndorsement: {
    LABEL: "Crime",
    QUESTION_COPY:
      "Add this coverage to insure against Employee Dishonesty, Theft, Robbery or Burglary, Fraud, and consequential professional fees and expenses.",
    COVERAGE_COPY:
      "This coverage insures against Employee Dishonesty, Theft, Robbery or Burglary, Fraud, and consequential professional fees and expenses.",
    action: updateCrimeEndorsement,
    selector: "endorsements.crimeEndorsement.shouldInclude",
    coverageAbbreviation: "Crime",
    coverageBullets: [
      "Limit $10,000 - Employee Dishonesty, Theft, Robbery or Burglary, and Fraud",
      "Blanket Limit $5,000 – Expenses",
      "No Deductible",
    ],
  },
  privacyBreachEndorsement: {
    LABEL: "Privacy Breach",
    QUESTION_COPY:
      "Add this coverage to provide payments for privacy breach compensatory damages, associated legal fees or defence expenses, business interruption loss, and reimbursement for remediation fees.",
    COVERAGE_COPY:
      "This coverage provides payments for privacy breach compensatory damages, associated legal fees or defence expenses, business interruption loss, and reimbursement for remediation fees.",
    action: updatePrivacyBreachEndorsement,
    selector: "endorsements.privacyBreachEndorsement.shouldInclude",
    coverageAbbreviation: "PrivBreach",
    coverageBullets: [
      "Privacy Breach Expenses – Aggregate Limit $25,000 with Deductible $1,000",
      "Privacy Breach Business Interruption – Aggregate Limit $25,000 with Waiting Period Deductible 24 hours ",
      "Privacy Breach Legal Expenses – Aggregate Limit $25,000 with no Deductible",
      "Privacy Breach Liability – Aggregate and Each Claim Limit $50,000 with no Deductible",
    ],
  },
  nonOwnedAutomobileCoveragesEndorsement: {
    LABEL: "Non-Owned Automobile Coverages",
    QUESTION_COPY:
      "Add this coverage to provide indemnity against liability to third parties for bodily injury and property damagearising from operating automobiles not owned by the insured, and loss or damage to hired automobiles.",
    COVERAGE_COPY:
      "This coverage provides indemnity against liability to third parties for bodily injury and property damagearising from operating automobiles not owned by the insured, and loss or damage to hired automobiles.",
    action: updateNonOwnedAutomobileEndorsement,
    selector: "endorsements.nonOwnedAutomobileCoveragesEndorsement.shouldInclude",
    coverageAbbreviation: "NOA",
    coverageBullets: [
      "S.P.F. No. 6 Supplementary NOA Coverage – Limit as per General Liability Limit selected ",
      "S.E.F No.99 Excluding Long Term Leased Vehicle Endorsement",
      "O.E.F. 98B Reduction of Coverage for Lessees or Drivers of Leased Vehicles Endorsement",
    ],
    packageInclusion: {
      inclusionHeader: "Below endorsements are attached only when Liability EDGE extension package is selected ",
      inclusionBullets: [
        "S.E.F. No. 94 Legal Liability for Damage to Hired Automobiles (Subsection 1 – ALL PERILS) – Limit $100,000 with no Deductible",
        "S.E.F. No. 96 Contractual Liability Endorsement",
      ],
      inclusionIcon: BlueShield,
    },
  },
  profitsActualLossSustainedEndorsement: {
    LABEL: "Profits Actual Loss Sustained",
    QUESTION_COPY:
      "Add this coverage to insure against loss from necessary business interruption caused by direct physical loss or damage to buildings, equipment or stock on the premises insured.",
    COVERAGE_COPY:
      "This coverage insures against loss from necessary business interruption caused by direct physical loss or damage to buildings, equipment or stock on the premises insured.",
    action: updateProfitsActualLossSustainedEndorsement,
    selector: "endorsements.profitsActualLossSustainedEndorsement.shouldInclude",
    coverageAbbreviation: "ProfitsALS",
    coverageBullets: ["Indemnity Period 12 months from occurrence of insured peril", "No deductible"],
  },
  sewerBackUpEndorsement: {
    LABEL: "Sewer Backup",
    QUESTION_COPY:
      "Add this coverage to insure against backing up or overflow of water from within sewers, sumps, septic tanks or drains within the bearing walls and foundations of the building described on the declaration page.",
    COVERAGE_COPY:
      "This coverage insures against backing up or overflow of water from within sewers, sumps, septic tanks or drains within the bearing walls and foundations of the building described on the declaration page.",
    action: updateSewerBackUpEndorsement,
    selector: "endorsements.sewerBackUpEndorsement.shouldInclude",
    coverageAbbreviation: "SBU",
    coverageBullets: [],
  },
  legalGuardInformationServiceEndorsement: {
    LABEL: "Legal Guard Information Service",
    QUESTION_COPY:
      "Add this coverage to provide Unlimited 24/7 telephone access to a legal information service for questions you have relating to your business.",
    COVERAGE_COPY:
      "This coverage to provide Unlimited 24/7 telephone access to a legal information service for questions you have relating to your business.",
    action: updateLegalGuardInformationServiceEndorsement,
    selector: "endorsements.legalGuardInformationServiceEndorsement.shouldInclude",
    coverageAbbreviation: "LegGrd",
    coverageBullets: [],
  },
};

export const endorsementsMap = () => (isCanadaEnv() ? canadaEndorsementsMap : coreEndorsementsMap);
