import React from "react";
import { TableContainer } from "styles/containerStyledComponents";
import styled from "styled-components";
import UpImg from "images/up.svg";
import DownImg from "images/down.svg";
import { AgentSortBy, SortDirection } from "types/enums";
import Highlighter from "shared/Highlighter";
import { ReactComponent as ArrowRight } from "images/arrow-right-blue.svg";

interface PropTypes {
  rows: AccountType[];
  search?: string;
  viewAgent: (agentId: string | undefined) => void;
  sortDirection: SortDirection;
  changeSort: (sortBy: AgentSortBy, sortDirection: SortDirection) => void;
  sortBy: AgentSortBy;
  loading: boolean;
}

const TABLE_HEADERS = ["Name", "Email", "ID"];

const GetSortBy = (header: string): AgentSortBy => {
  switch (header) {
    case "Name":
      return AgentSortBy.Name;
    case "Email":
      return AgentSortBy.Email;
    case "ID":
      return AgentSortBy.Id;
    default:
      return AgentSortBy.Name;
  }
};

const AgentSearchTable = ({ rows, search, viewAgent, sortDirection, changeSort, sortBy, loading }: PropTypes) => {
  const selectSortColumn = (column: AgentSortBy) => {
    changeSort(column, sortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending);
  };

  const resultRows = Array.isArray(rows)
    ? rows.map((agent: AccountType, index) => (
        <Tr key={index} data-cy={"agent-row"} onClick={() => viewAgent(agent.id)} tabIndex={0}>
          <td>
            <Highlighter loading={loading} searchTerm={search}>
              {(agent.firstName || "") + " " + (agent.lastName || "")}
            </Highlighter>
          </td>
          <td>
            <Highlighter loading={loading} searchTerm={search}>
              {agent.email}
            </Highlighter>
          </td>
          <td>
            <Highlighter loading={loading} searchTerm={search}>
              {agent.id}
            </Highlighter>
          </td>
          <td>
            <IconContainer>
              <ArrowRight />
            </IconContainer>
          </td>
        </Tr>
      ))
    : [];

  const arrow = () => {
    if (sortDirection === SortDirection.Descending) {
      return DownImg;
    } else if (sortDirection === SortDirection.Ascending) {
      return UpImg;
    } else {
      return "";
    }
  };

  return (
    <TableContainer className={"results"}>
      <table>
        <thead>
          <tr>
            {TABLE_HEADERS.map((header, index) => (
              <th key={`header-${index}`}>
                <HeaderRow>
                  <button
                    data-cy={`header-${header}`}
                    type="button"
                    onClick={() => selectSortColumn(GetSortBy(header))}
                    aria-label={`Sort by ${header}`}
                  >
                    {header}
                  </button>
                  {sortBy === GetSortBy(header) && <img src={arrow()} alt="arrow" />}
                </HeaderRow>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {resultRows.length > 0 ? (
            resultRows
          ) : (
            <tr>
              <td colSpan={10}>No agents matching your criteria</td>
            </tr>
          )}
        </tbody>
      </table>
    </TableContainer>
  );
};

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Tr = styled.tr`
  cursor: pointer;
`;

const IconContainer = styled.div`
  width: 10px;
  height: 14px;
  object-fit: contain;
  svg path {
    fill: ${(props) => props.theme.blue};
  }
`;

export default AgentSearchTable;
