import React, { ReactNode, useEffect, useRef, useState } from "react";
import { YesNoQuestion } from "coterie-ui-library";
import styled, { css } from "styled-components";
import { isEmpty, divide } from "lodash";
import { Dropdown, Input, RadioButton, Label, Checkbox } from "styles";
import { toastError } from "utils/toast";
import { ErrorText } from "styles/Input";
import { ReactComponent as CalendarIcon } from "images/calendar.svg";
import DatePicker from "react-date-picker";
import AddressSelector from "./application/AddressSelector";

interface PropTypes {
  questionsObj: questionsObjectType[];
  questionsCompleteChecker: (aarg1: boolean, aarg2: boolean, aarg3: string) => void;
  questionGroupId: string;
  questionsAnswered: (aarg: any) => void;
  showQuestionErrors: boolean;
}

const QuestionsCreator = (props: PropTypes) => {
  const { questionsCompleteChecker, questionsObj, questionGroupId, questionsAnswered, showQuestionErrors } = props;

  const arrayToObject = (arr: Array<questionsObjectType>): mappedObjectType => {
    return arr.reduce((acc: mappedObjectType, val: questionsObjectType) => {
      const groupedObject = {
        questionValue: val.questionValue,
        id: val.id,
        label: val.label,
        inputType: val.inputType,
        optional: val.optional,
      };

      if (val.children) {
        return { ...acc, [`${questionGroupId}${val.id}`]: groupedObject, ...arrayToObject(val.children) };
      } else {
        return { ...acc, [`${questionGroupId}${val.id}`]: groupedObject };
      }
    }, {});
  };

  useEffect(() => {
    if (questionGroupId.includes("questionInput")) {
      toastError("questionInput cannot be used as the ID");
    }
  }, [questionGroupId]);

  const [reloadPage, setReloadPage] = useState<boolean>(false);
  const [pageIds, setPageIds] = useState<string[]>([]);
  const [questionsMapObject, setQuestionsMapObject] = useState<mappedObjectType>({});

  const didMountRef = useRef(false);
  const inputTypes = ["number", "year", "percentage", "currency", "string"];

  useEffect(() => {
    setQuestionsMapObject(arrayToObject(questionsObj));
  }, [questionsObj]);

  const changeAddress = (address: addressType | null, isVerified: boolean, questionId: string) => {
    if (isVerified) {
      updateQuestionValue(questionId, "Address", address, isVerified);
    } else {
      updateQuestionValue(questionId, "Address", address, isVerified);
    }
  };

  const updateQuestionValue = (
    questionId: string,
    questionType: string,
    value: questionValueType,
    isVerified = false
  ) => {
    let questionValue: questionValueType = value;

    //check if value equals empty space or consists of empty spaces return
    if (typeof value === "string" && value.trim() === "") {
      questionValue = null;
    }

    if (questionType === "Address" && questionValue && typeof questionValue === "object") {
      // add is verified to the question value object for address
      questionValue = { ...(questionValue as addressType), isVerified };
      if (!questionValue.isVerified) {
        questionValue = { street: null, streetLineTwo: null, city: null, state: null, zip: null, isVerified: false };
      }
    }

    if (questionsMapObject) {
      const returnObj = {
        ...questionsMapObject,
        [`${questionGroupId}${questionId}`]: {
          ...questionsMapObject[`${questionGroupId}${questionId}`],
          questionValue: questionValue,
        },
      };

      const returnObjEmpty = {
        ...questionsMapObject,
        [`${questionGroupId}${questionId}`]: {
          ...questionsMapObject[`${questionGroupId}${questionId}`],
          questionValue: "",
        },
      };
      if (questionType === "percentage") {
        // Ensure value is a string before parsing
        const numericValue = parseInt(value as string);
        if (numericValue <= 100 && numericValue >= 0) {
          setQuestionsMapObject(returnObj);
        } else {
          setQuestionsMapObject(returnObjEmpty);
        }
      } else if (inputTypes.includes(questionType) && questionType !== "string") {
        if (questionType === "number" || questionType === "currency") {
          // Ensure value is a string before parsing
          const numericValue = parseInt(value as string);
          if (numericValue >= 0) {
            setQuestionsMapObject(returnObj);
          } else {
            setQuestionsMapObject(returnObjEmpty);
          }
        }
      } else {
        setQuestionsMapObject(returnObj);
      }
    }
  };

  const checkQuestionValue = (questionValue: string | boolean | null) => {
    switch (questionValue) {
      case "false":
        return false;
      case "true":
        return true;
      case null:
        return null;
      case undefined:
        return null;
      default:
        return Boolean(questionValue);
    }
  };

  // function to check if a value is null or undefined or empty
  const isNullOrUndefinedOrEmpty = (value: string | any[] | null | undefined) => {
    return value === null || value === undefined || value === "";
  };

  const checkIfAddressIsVerified = (questionValue: addressType) => {
    if (questionValue) {
      if (questionValue.street && questionValue.city && questionValue.state && questionValue.zip) {
        return true;
      }
    }
    return false;
  };

  const processCondition = (condition: conditionValueType, id: string, childrenToShow: Set<string>) => {
    const matchEquals = condition.operator === "equals" && condition.value === id;
    const matchIn = condition.operator === "in" && Array.isArray(condition.value) && condition.value.includes(id);
    if (matchEquals || matchIn) {
      condition.childrenShown.forEach((childId) => childrenToShow.add(childId));
      return true;
    }
    return false;
  };

  const getChildrenToShow = (question: questionsObjectType, questionValue: string | boolean) => {
    let childrenToShow = new Set<string>();
    const matchedAnswerAndValues: string[] = [];

    const selectorAnswerTypes: { [key: string]: boolean } = {
      Yes: true,
      No: false,
    };

    if (question.questionType === "Selector") {
      (question.conditions as conditionSelectorAndInputValueType[]).forEach(
        (condition: conditionSelectorAndInputValueType) => {
          if (selectorAnswerTypes[condition.value] === questionValue) {
            condition.childrenShown.forEach((childId: string) => childrenToShow.add(childId));
          }
        }
      );
    } else if (typeof questionValue !== "boolean" && question.questionType === "Input") {
      const inputAsFloat = parseFloat(questionValue);
      const isPercentage = question.inputType === "percentage";
      questionValue &&
        question.conditions.forEach((condition: conditionValueType) => {
          if (typeof condition.value === "string") {
            if (condition.operator === "equals") {
              if (parseFloat(condition.value) === (isPercentage ? divide(inputAsFloat, 100) : inputAsFloat)) {
                condition.childrenShown.forEach((childId: string) => childrenToShow.add(childId));
              }
            } else if (condition.operator === "greaterThanOrEqualTo") {
              if (parseFloat(condition.value) <= (isPercentage ? divide(inputAsFloat, 100) : inputAsFloat)) {
                condition.childrenShown.forEach((childId: string) => childrenToShow.add(childId));
              }
            } else if (condition.operator === "greaterThan") {
              if (parseFloat(condition.value) < (isPercentage ? divide(inputAsFloat, 100) : inputAsFloat)) {
                condition.childrenShown.forEach((childId: string) => childrenToShow.add(childId));
              }
            } else if (condition.operator === "lessThanOrEqualTo") {
              if (parseFloat(condition.value) >= (isPercentage ? divide(inputAsFloat, 100) : inputAsFloat)) {
                condition.childrenShown.forEach((childId: string) => childrenToShow.add(childId));
              }
            } else if (condition.operator === "lessThan") {
              if (parseFloat(condition.value) > (isPercentage ? divide(inputAsFloat, 100) : inputAsFloat)) {
                condition.childrenShown.forEach((childId: string) => childrenToShow.add(childId));
              }
            }
          }
        });
    } else {
      (question.answerOptions as answerOptionsType[]).forEach((option: answerOptionsType) => {
        if (Array.isArray(questionValue) ? questionValue.includes(option.value) : option.value === questionValue) {
          matchedAnswerAndValues.push(option.id);
        }
      });

      matchedAnswerAndValues.forEach((id) => {
        question.conditions.some((conditionObj: conditionValueType) =>
          processCondition(conditionObj, id, childrenToShow)
        );
      });
    }
    return Array.from(childrenToShow);
  };

  const filterChildrenToShow = (question: questionsObjectType, childrenToShowArray: string[]) => {
    return (
      question.children &&
      question.children.filter((child: questionsObjectType) => childrenToShowArray.includes(child.id))
    );
  };

  const addTwelveMonths = () => {
    let newDate = new Date();
    newDate.setFullYear(newDate.getFullYear() + 1); // Adding 12 months or 1 year
    return newDate;
  };

  const idsOnPage: Array<string> = [];

  const questionRenderer = (questionObj: questionsObjectType[], flattenedQuestions: mappedObjectType) => {
    const returnedQuestionsComponent = questionObj.flatMap((question: questionsObjectType) => {
      const returnedArray: ReactNode[] = [];

      let conditionalMet = false;
      if (!flattenedQuestions[`${questionGroupId}${question.id}`]) {
        return [];
      }

      if (idsOnPage.includes(question.id)) {
        return null;
      }

      idsOnPage.push(question.id);

      const questionValue = flattenedQuestions[`${questionGroupId}${question.id}`].questionValue;

      if (question.questionType === "Selector") {
        returnedArray.push(
          <div>
            <Label>
              {question.label}
              {question.optional && <OptionalText>(optional)</OptionalText>}
            </Label>
            <YesNoContainer key={question.id} id={`${questionGroupId}questioninput${question.id}`}>
              <YesNoQuestion
                question={""}
                value={checkQuestionValue(questionValue)}
                selectYes={() => updateQuestionValue(question.id, "Selector", true)}
                selectNo={() => updateQuestionValue(question.id, "Selector", false)}
                name={`${questionGroupId}questioninput${question.id}`}
              />
              {showQuestionErrors && !question.optional && isNullOrUndefinedOrEmpty(questionValue) && (
                <ErrorContainer>
                  <ErrorText>This field is required</ErrorText>
                </ErrorContainer>
              )}
            </YesNoContainer>
            <Hr />
          </div>
        );
      }

      if (question.questionType === "TextArea") {
        returnedArray.push(
          <div id={`${questionGroupId}questioninput${question.id}`}>
            <InputContainer key={question.id}>
              <Label>
                {question.label}
                {question.optional && <OptionalText>(optional)</OptionalText>}
              </Label>
              <Textarea
                value={questionValue || ""}
                name={`text-area${question.id}`}
                onChange={(e) => updateQuestionValue(question.id, question.inputType, e.target.value)}
                placeholder={question.placeholder}
                maxLength={1500}
                rows={4}
              />
            </InputContainer>
            {showQuestionErrors && !question.optional && isNullOrUndefinedOrEmpty(questionValue) && (
              <ErrorContainer>
                <ErrorText>This field is required</ErrorText>
              </ErrorContainer>
            )}
            <Hr />
          </div>
        );
      }

      if (question.questionType === "Input") {
        let inputType = question.inputType;
        let maxLength = undefined;
        let inputProps = {};
        if (question.inputType === "year") {
          maxLength = 4;
          inputType = "number";
        } else if (question.inputType === "percentage") {
          maxLength = 4;
          inputType = "";
          inputProps = { suffix: "%" };
        } else if (question.inputType === "currency") {
          inputType = "";
          inputProps = { prefix: "$" };
        }

        returnedArray.push(
          <div>
            <InputContainer key={question.id}>
              <Input
                value={questionValue || ""}
                name={`${questionGroupId}questioninput${question.id}`}
                label={question.label}
                readOnly={false}
                type={inputType}
                handleChange={(e) => {
                  if (question.inputType === "string") {
                    updateQuestionValue(question.id, question.inputType, e.currentTarget.value);
                  } else {
                    updateQuestionValue(question.id, question.inputType, e.value);
                  }
                }}
                inputStyles={{ width: "255px" }}
                decimalSeparator={false}
                placeholder={question.placeholder}
                maxLength={maxLength}
                {...inputProps}
                error={showQuestionErrors && !question.optional && isNullOrUndefinedOrEmpty(questionValue)}
                errorText="This field is required"
                isOptional={question.optional}
              />
            </InputContainer>
            <Hr />
          </div>
        );
      }

      if (question.questionType === "MultipleCheckboxSelect") {
        returnedArray.push(
          <div>
            <MultipleCheckboxSelectContainer key={question.id}>
              <Label>
                {question.label}
                {question.optional && <OptionalText>(optional)</OptionalText>}
              </Label>
              {(question.answerOptions as answerOptionsType[]).map((option: answerOptionsType, index: number) => (
                <CheckboxOption key={index} id={`${questionGroupId}questioninput${question.id}`}>
                  <Checkbox
                    handleClick={() => {
                      const newValues: string[] = questionValue ? [...questionValue] : [];
                      if (newValues.includes(option.value)) {
                        const index = newValues.indexOf(option.value);
                        newValues.splice(index, 1);
                      } else {
                        newValues.push(option.value);
                      }
                      updateQuestionValue(question.id, "MultipleCheckboxSelect", newValues);
                    }}
                    checked={questionValue && questionValue.includes(option.value)}
                    label={option.text}
                    name={`${question.id}-checkbox-option-${index}`}
                    id={`${question.id}-checkbox-option-${index}`}
                    checkBoxStyleOverride={{ width: "22px", height: "22px", minWidth: "22px" }}
                  />
                </CheckboxOption>
              ))}
              {showQuestionErrors && !question.optional && (!questionValue || isEmpty(questionValue)) && (
                <ErrorContainer>
                  <ErrorText>This field is required</ErrorText>
                </ErrorContainer>
              )}
            </MultipleCheckboxSelectContainer>
            <Hr />
          </div>
        );
      }

      if (question.questionType === "DatePicker") {
        returnedArray.push(
          <div id={`${questionGroupId}questioninput${question.id}`}>
            <div>
              <Label>
                {question.label}
                {question.optional && <OptionalText>(optional)</OptionalText>}
              </Label>
            </div>
            <StyledDatePicker
              name="Date"
              className="date-of-loss"
              value={questionValue}
              onChange={(date: Date) => updateQuestionValue(question.id, "DatePicker", date)}
              //TODO: MIN and MAX DATE ARE NOT DYNAMIC DON'T FORGET TO UPDATE ALL RELATED CSV FILES
              maxDate={addTwelveMonths()}
              minDate={new Date()}
              clearIcon={null}
              calendarIcon={
                <IconWrapper>
                  <CalendarIcon title="Calendar Icon" />
                </IconWrapper>
              }
              monthPlaceholder="MM"
              dayPlaceholder="DD"
              yearPlaceholder="YYYY"
              calendarType="US"
              error={false}
              disabled={false}
            />
            {showQuestionErrors && !question.optional && isNullOrUndefinedOrEmpty(questionValue) && (
              <ErrorContainer>
                <ErrorText>This field is required</ErrorText>
              </ErrorContainer>
            )}
            <Hr />
          </div>
        );
        if (questionValue) {
          conditionalMet = true;
        }
      }
      if (question.questionType === "Address") {
        returnedArray.push(
          <div id={`${questionGroupId}questioninput${question.id}`}>
            <Label>
              {question.label}
              {question.optional && <OptionalText>(optional)</OptionalText>}
            </Label>
            <AddressSelector
              street={questionValue?.street}
              streetLineTwo={questionValue?.streetLineTwo}
              city={questionValue?.city}
              state={questionValue?.state}
              zip={questionValue?.zip}
              changeAddress={(address, isVerified) => changeAddress(address, isVerified, question.id)}
              name="business"
              id={`${questionGroupId}AddressSelector${question.id}`}
              isSimplybind={true}
            />
            {showQuestionErrors &&
              !question.optional &&
              !checkIfAddressIsVerified(questionValue) &&
              !questionValue?.isVerified && (
                <ErrorContainer>
                  <ErrorText>This field is required</ErrorText>
                </ErrorContainer>
              )}
            <Hr />
          </div>
        );
        if (questionValue) {
          conditionalMet = true;
        }
      }

      if (question.questionType === "Dropdown") {
        const mappedOptions = (question.answerOptions as answerOptionsType[]).map((option: answerOptionsType) => {
          return {
            value: option.value,
            label: option.text,
          };
        });

        returnedArray.push(
          <div>
            <DropdownContainer key={question.id} id={`${questionGroupId}questioninput${question.id}`}>
              <Dropdown
                onChange={(e) => updateQuestionValue(question.id, "Dropdown", e.value)}
                options={mappedOptions}
                value={questionValue}
                full={false}
                name={"dropdown" + questionGroupId + question.id}
                label={question.label}
                error={showQuestionErrors && isNullOrUndefinedOrEmpty(questionValue)}
                errorText="This field is required"
              />
            </DropdownContainer>
            <Hr />
          </div>
        );
      }
      if (question.questionType === "RadioButton") {
        const radioButtonArray = (question.answerOptions as answerOptionsType[]).map(
          (e: answerOptionsType, index: number) => {
            return (
              <RadioButtonWrapper key={index + question.id}>
                <RadioButton
                  value={e.value}
                  checked={questionValue === e.value}
                  label={e.text}
                  groupName={"radio" + questionGroupId + question.id}
                  handleClick={() => updateQuestionValue(question.id, "Dropdown", e.value)}
                  inputStyles={RadioInputStyles}
                  labelStyles={RadioLabelStyles}
                />
              </RadioButtonWrapper>
            );
          }
        );
        returnedArray.push(
          <div>
            <RadioButtonContainer key={question.id} id={`${questionGroupId}questioninput${question.id}`}>
              <Label>
                {question.label}
                {question.optional && <OptionalText>(optional)</OptionalText>}
              </Label>
              <RadioGroupContainer>{radioButtonArray}</RadioGroupContainer>
              {showQuestionErrors && !question.optional && isNullOrUndefinedOrEmpty(questionValue) && (
                <ErrorContainer>
                  <ErrorText>This field is required</ErrorText>
                </ErrorContainer>
              )}
            </RadioButtonContainer>
            <Hr />
          </div>
        );
      }

      if (question.children && question.questionType !== "Address" && question.questionType !== "DatePicker") {
        const childrenToShowArray = getChildrenToShow(question, questionValue);

        if (question.children && childrenToShowArray.length > 0) {
          const filteredChildren = filterChildrenToShow(question, childrenToShowArray);
          if (filteredChildren) {
            returnedArray.push(questionRenderer(filteredChildren, flattenedQuestions));
          }
        }
      } else if (question.children && conditionalMet) {
        returnedArray.push(questionRenderer(question.children, flattenedQuestions));
      }
      return returnedArray;
    });

    return returnedQuestionsComponent;
  };

  useEffect(() => {
    const elements = document.querySelectorAll("*");
    const ids = [];
    if (didMountRef.current) {
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].id && elements[i].id.includes(`${questionGroupId}questioninput`)) {
          const unparsedString = elements[i].id;
          const parsedID = unparsedString.split("questioninput")[1];

          ids.push(`${questionGroupId}${parsedID}`);
        }
      }

      const uniqueArr = Array.from(new Set(ids));
      setPageIds(uniqueArr);
    } else {
      didMountRef.current = true;
      setReloadPage(!reloadPage);
    }
  }, [reloadPage, questionsMapObject]);

  useEffect(() => {
    const allQuestionsOrOneAnswered = () => {
      let oneQuestionHasAnswer = false;
      let allQuestionsHaveAnswers = true;
      let unansweredId = "";
      pageIds.forEach((key) => {
        let hasQuestionBeenAnswered = false;

        if (questionsMapObject[key]) {
          const questionObj = questionsMapObject[key];

          // Check if the questionValue is not null, not an empty string, and isVerified is not false.
          hasQuestionBeenAnswered =
            (questionObj.questionValue !== undefined &&
              questionObj.questionValue !== null &&
              questionObj.questionValue !== "" &&
              !(Array.isArray(questionObj.questionValue) && questionObj.questionValue.length === 0) &&
              questionObj.questionValue?.isVerified !== false) ||
            questionObj.optional === true;
        }

        if (!hasQuestionBeenAnswered) {
          allQuestionsHaveAnswers = false;
          if (unansweredId === "") {
            unansweredId = `${questionGroupId}questioninput${questionsMapObject[key].id}`;
          }
        } else {
          oneQuestionHasAnswer = true;
        }
      });
      questionsCompleteChecker(allQuestionsHaveAnswers, oneQuestionHasAnswer, unansweredId);
    };
    allQuestionsOrOneAnswered();
  });

  useEffect(() => {
    questionsAnswered(questionsToSubmit(questionsMapObject));
  }, [reloadPage, questionsMapObject, pageIds]);

  const questionsToSubmit = (questionsObject: mappedObjectType) => {
    const returnedQuestionsMap = Object.values(questionsObject).map((question: valueQuestionsType) => {
      if (
        pageIds.includes(`${questionGroupId}${question.id}`) &&
        (question.questionValue != null || question.optional)
      ) {
        return question;
      }
    });

    const filteredQuestions = returnedQuestionsMap.filter((question) => question);
    return filteredQuestions;
  };

  return (
    <div id="questionsPage" data-testid="questionsPage">
      {questionsObj && !isEmpty(questionsMapObject) && questionRenderer(questionsObj, questionsMapObject)}
    </div>
  );
};

const YesNoContainer = styled.div`
  max-width: 600px;
  margin-bottom: 45px;
  button {
    width: 40px;
    height: 28px;
    font-size: 13px;
  }
  > div {
    height: inherit;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const RadioInputStyles = css`
  margin-top: 0;
  vertical-align: middle;
  margin-right: 10px;
  padding: 0;

  &:checked + label {
    color: ${(props) => props.theme.black};
  }
`;

const RadioLabelStyles = css`
  display: inline-block;
  white-space: pre-wrap;
  vertical-align: middle;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
`;

const Hr = styled.hr`
  width: 100%;
  background: ${(props) => props.theme.offWhite};
  margin: 0 0 16px 0;
`;

const RadioGroupContainer = styled.div`
  column-gap: 16px;
  input {
    min-width: 24px;
    &:checked {
      border: 5px solid ${(props) => props.theme.blue};
      background: ${(props) => props.theme.blue};
    }
  }
`;

const IconWrapper = styled.div`
  svg {
    fill: ${(props) => props.theme.blue};
  }
`;

const StyledDatePicker = styled(DatePicker)<{ error: boolean }>`
  background: ${(props) => props.theme.white};
  height: 48px;
  width: 255px;
  z-index: 10;
  margin: 10px 0 20px 0;

  ${(props) =>
    props.error &&
    css`
      > div {
        border: 1px solid ${props.theme.red}!important;
      }

      div > div > input,
      div > div > span {
        color: ${props.theme.red};
        font-weight: bold;
      }
    `}
`;

const RadioButtonContainer = styled.div`
  margin-bottom: 30px;
`;

const DropdownContainer = styled.div`
  margin-bottom: 15px;
`;

const InputContainer = styled.div`
  max-width: 600px;
  margin-bottom: 15px;
`;

const ErrorContainer = styled.div`
  margin-top: 10px;
`;

const MultipleCheckboxSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
`;

const CheckboxOption = styled.div`
  display: flex;
  align-items: center;
`;

const Textarea = styled.textarea`
  width: 100%;
  background-color: ${(props) => props.theme.white};
  font-family: ${(props) => props.theme.secondaryFont};
  border: 1px solid ${(props) => props.theme.lighterGray};
  font-size: 15px;
  letter-spacing: 0;
  padding: 11px 15px;
  border-radius: 2px;
  margin-bottom: 8px;
  :focus {
    outline: none;
    box-shadow: 0 0 0 1px ${(props) => props.theme.blue};
  }
`;

const OptionalText = styled.span`
  color: ${(props) => props.theme.fontColors.subduedDark};
  margin-left: 4px;
  font-size: 12px;
  letter-spacing: 0.75px;
  font-weight: 600;
`;

export default QuestionsCreator;
