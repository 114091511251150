import React, { useState } from "react";
import Modal from "shared/Modal";
import styled from "styled-components";
import { ModalField, ShortModalInput, ModalContentContainer } from "shared/ModalComponents";

interface PropTypes extends AgencyType {
  isLoading: boolean;
  closeModal: () => void;
  modalIsOpen: boolean;
  save: (agency: AgencyType) => void;
}

const EditAgencyModal = (props: PropTypes) => {
  const [agency, setAgency] = useState<AgencyType>({
    name: props.name,
    contactPhone: props.contactPhone,
    mailingAddressStreet: props.mailingAddressStreet,
    mailingAddressCity: props.mailingAddressCity,
    mailingAddressState: props.mailingAddressState,
    mailingAddressZip: props.mailingAddressZip,
    owner: { id: "", email: "" },
  });

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    let { name, value } = event.currentTarget;
    setAgency((agency) => ({ ...agency, [name]: value }));
  };

  const saveChanges = () => {
    props.save(agency);
  };

  const {
    name,
    contactPhone,
    mailingAddressStreet,
    mailingAddressState,
    mailingAddressCity,
    mailingAddressZip,
  } = agency;

  return (
    <Modal
      isOpen={props.modalIsOpen}
      closeModal={props.closeModal}
      label="edit-agency"
      title="Edit Agency Info"
      loading={props.isLoading}
      onSubmit={saveChanges}
    >
      <ModalContentContainer>
        <Container>
          <Row>
            <ModalField>Agency Name</ModalField>
            <ShortModalInput
              name="name"
              value={name}
              onChange={handleChange}
              aria-label="Agency Name"
              placeholder="Agency Name"
              required
            />
          </Row>
          <Row>
            <ModalField>Phone Number</ModalField>
            <ShortModalInput
              name="contactPhone"
              value={contactPhone}
              onChange={handleChange}
              aria-label="Phone Number"
              placeholder="Phone Number"
            />
          </Row>
          <ModalField style={{ flexBasis: "100%" }}>Mailing Address</ModalField>
          <Row>
            <ModalField>Street</ModalField>
            <ShortModalInput
              data-cy="manual-street"
              name="mailingAddressStreet"
              value={mailingAddressStreet}
              onChange={handleChange}
              aria-label="Mailing Address Street"
              placeholder="Street"
            />
          </Row>
          <Row>
            <ModalField>City</ModalField>
            <ShortModalInput
              data-cy="manual-city"
              name="mailingAddressCity"
              value={mailingAddressCity}
              onChange={handleChange}
              aria-label="Mailing Address City"
              placeholder="City"
            />
          </Row>
          <Row>
            <ModalField>State Abbreviation</ModalField>
            <ShortModalInput
              data-cy="manual-state"
              name="mailingAddressState"
              value={mailingAddressState}
              maxLength={2}
              onChange={handleChange}
              aria-label="Mailing Address State"
              placeholder="State"
            />
          </Row>
          <Row>
            <ModalField>Zip</ModalField>
            <ShortModalInput
              data-cy="manual-zip"
              name="mailingAddressZip"
              value={mailingAddressZip}
              onChange={handleChange}
              aria-label="Mailing Address Zip"
              placeholder="Zip"
            />
          </Row>
        </Container>
      </ModalContentContainer>
    </Modal>
  );
};

const Row = styled.div`
  flex: 50%;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default EditAgencyModal;
