import React, { ChangeEvent, useState } from "react";
import Modal from "shared/Modal";
import { FormGroup, Input, InputGroup, InputGroupAddon } from "reactstrap";
import { ModalContentContainer } from "shared/ModalComponents";
import { ChargeType } from "types/enums";
import { Dropdown } from "styles";
import { enumToOptions } from "utils/enumToOptions";

interface PropTypes {
  modalIsOpen: boolean;
  closeModal: () => void;
  addCharge: (charge: Charge) => void;
  isAddingCharge: boolean;
}

const checkValue = (inputValue: string, chargeType: ChargeType): number => {
  let value = parseFloat(inputValue);

  if ((chargeType === ChargeType.Payment && value < 0) || (chargeType !== ChargeType.Payment && value > 0)) {
    value = value * -1;
  }
  return value;
};

const AddChargeModal = (props: PropTypes) => {
  const [amount, setAmount] = useState(0);
  const [feeAmount, setFeeAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [transactionFeeId, setTransactionFeeId] = useState("");
  const [chargeType, setChargeType] = useState(ChargeType.Payment);

  const { modalIsOpen, isAddingCharge } = props;

  const onAmountChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setAmount(checkValue(e.target.value, chargeType));
  };

  const onFeeAmountChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setFeeAmount(checkValue(e.target.value, chargeType));
  };

  const onTransactionIdChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setTransactionId(e.target.value);
  };

  const onTransactionFeeIdChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setTransactionFeeId(e.target.value);
  };

  const onDescriptionChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const onChargeTypeChanged = (e: any) => {
    setChargeType(e.value);
    let type = e.value as ChargeType;
    setFeeAmount(checkValue(feeAmount.toString(), type));
    setAmount(checkValue(amount.toString(), type));
  };

  const addCharge = () => {
    const charge = {
      amount: amount,
      feeAmount: feeAmount,
      transactionId: transactionId,
      feeId: transactionFeeId,
    } as Charge;

    props.addCharge(charge);
  };

  const cancelCharge = () => {
    props.closeModal();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      closeModal={cancelCharge}
      label={"add-charge"}
      title="Record Charge"
      buttonText="Add Charge"
      loading={isAddingCharge}
      onSubmit={addCharge}
    >
      <ModalContentContainer>
        <FormGroup>
          <InputGroup>
            <Dropdown
              label="Charge Type"
              value={chargeType}
              data-cy="manual-charge-charge-type"
              onChange={onChargeTypeChanged}
              aria-labelledby="charge-type-label"
              options={enumToOptions(ChargeType)}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <InputGroupAddon id="amount-label" addonType="prepend">
              Amount
            </InputGroupAddon>
            <Input
              type={"number"}
              value={amount}
              data-cy="manual-charge-amount"
              onChange={onAmountChanged}
              placeholder="Enter dollar amount"
              aria-labelledby="amount-label"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <InputGroupAddon id="transaction-id-label" addonType="prepend">
              Transaction ID
            </InputGroupAddon>
            <Input
              value={transactionId}
              data-cy="manual-charge-transaction-id"
              onChange={onTransactionIdChanged}
              placeholder="Transaction ID"
              aria-labelledby="transaction-id-label"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <InputGroupAddon id="fee-amount-label" addonType="prepend">
              Fee Amount
            </InputGroupAddon>
            <Input
              type={"number"}
              value={feeAmount}
              data-cy={"manual-charge-fee-amount"}
              onChange={onFeeAmountChanged}
              placeholder="Enter dollar amount"
              aria-labelledby="fee-amount-label"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup>
            <InputGroupAddon id="transaction-fee-id-label" addonType="prepend">
              Transaction Fee ID
            </InputGroupAddon>
            <Input
              value={transactionFeeId}
              data-cy="manual-charge-transaction-fee-id"
              onChange={onTransactionFeeIdChanged}
              placeholder="Transaction Fee ID"
              aria-labelledby="transaction-fee-id-label"
            />
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <InputGroup>
            <InputGroupAddon id="description-label" addonType="prepend">
              Description
            </InputGroupAddon>
            <Input
              value={description}
              data-cy="manual-charge-description"
              onChange={onDescriptionChanged}
              placeholder="Enter description"
              aria-labelledby="description-label"
            />
          </InputGroup>
        </FormGroup>
      </ModalContentContainer>
    </Modal>
  );
};

export default AddChargeModal;
