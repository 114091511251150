import React from "react";
import { connect } from "react-redux";
import styled, { DefaultTheme } from "styled-components";
import { TextLink, GreenButton, tablet } from "styles";
import { GhostButton } from "shared/SimplyBind/styles";
import { PolicyType } from "types/enums";
import { ReactComponent as AlertIcon } from "images/alert.svg";
import { ReactComponent as HalfCircle } from "images/LightGreenCircle.svg";
import { PolicyData } from "./Coverages";
import { ReactComponent as Caret } from "images/caret_up.svg";
import { CoverageExclusionAccordion } from "./CoverageExclusionAccordion";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import { selectUnderwritingProfileExclusions } from "redux/selectors/underwritingProfile";
import { useHistory } from "react-router-dom";

interface TabPanelProps {
  policyData: PolicyData;
  availablePolicyTypes: PolicyType[];
  selectedPolicyTypes: PolicyType[];
  handleSelectPolicy: (policy: PolicyType) => void;
  isActiveTab: boolean;
  tabNumber: number;
  deniedCoverages: PolicyType[];
  setActiveDeclinationModal: (policyType: PolicyType) => void;
  exclusions: string[];
}

const CoverageTabPanel = ({
  policyData,
  availablePolicyTypes,
  selectedPolicyTypes,
  handleSelectPolicy,
  tabNumber,
  isActiveTab,
  deniedCoverages,
  setActiveDeclinationModal,
  exclusions,
}: TabPanelProps) => {
  const { showDashboardExclusions, showSelectCoverage } = useFeatureFlags();
  const shouldShowSwitchToBOP = policyData.policy === PolicyType.GL && availablePolicyTypes.includes(PolicyType.BOP);
  const shouldShowSwitchToGL = policyData.policy === PolicyType.BOP && availablePolicyTypes.includes(PolicyType.GL);
  const shouldShowBOPDeclination = policyData.policy === PolicyType.GL && deniedCoverages?.includes(PolicyType.BOP);
  const history = useHistory();
  return (
    <TabPanelContainer isActiveTab={isActiveTab} data-testid={`${policyData.policy}-tab`}>
      <>
        {selectedPolicyTypes.includes(policyData.policy) ? (
          <>
            <HeaderContainer policyType={policyData.policy} tabNumber={tabNumber}>
              <PolicyDescription>
                {policyData.description}{" "}
                {shouldShowSwitchToBOP && (
                  <>
                    <span>Need property coverage?</span>
                    {showSelectCoverage && (
                      <SwitchPolicyButton
                        id="quote-switch-liability"
                        onClick={() => {
                          history.push("/simplybind/get-started");
                        }}
                      >
                        Start a New Quote
                      </SwitchPolicyButton>
                    )}
                    {!showSelectCoverage && (
                      <SwitchPolicyButton
                        id="quote-switch-liability"
                        onClick={() => handleSelectPolicy(PolicyType.BOP)}
                      >
                        Change to a BOP quote
                      </SwitchPolicyButton>
                    )}
                  </>
                )}
                {shouldShowSwitchToGL && (
                  <>
                    <span>Don't need property coverage?</span>
                    <SwitchPolicyButton id="quote-switch-liability" onClick={() => handleSelectPolicy(PolicyType.GL)}>
                      Change to a General Liability quote
                    </SwitchPolicyButton>
                  </>
                )}
              </PolicyDescription>
            </HeaderContainer>
            <CoverageContainer isVisible={selectedPolicyTypes.includes(policyData.policy)}>
              <fieldset
                disabled={!isActiveTab || !selectedPolicyTypes.includes(policyData.policy)}
                data-testid={`${policyData.policy}-fieldset`}
              >
                <policyData.coverageComponents />
              </fieldset>
              {showDashboardExclusions && (
                <CoverageExclusionAccordion isBOP={policyData.policy === PolicyType.BOP} exclusions={exclusions} />
              )}
              {shouldShowBOPDeclination && (
                <div>
                  <DeclinationHeader>Why can't I select BOP?</DeclinationHeader>
                  <p>
                    Property coverage, as offered in a BOP, was declined for this risk.{" "}
                    <TextLink onClick={() => setActiveDeclinationModal(PolicyType.BOP)}>
                      View Details
                      <span aria-hidden>
                        <StyledCaret />
                      </span>
                    </TextLink>
                  </p>
                </div>
              )}
            </CoverageContainer>
          </>
        ) : (
          <NoCoverageSelectedContainer data-testid={`${policyData.policy}-not-selected-panel`}>
            <div>
              <HeaderContainer />
              <PolicyNotSelectedIcon>
                <AlertIcon width={32} height={32} />
              </PolicyNotSelectedIcon>
              <NotAddedToQuoteText>{policyData.title} is not added to this quote</NotAddedToQuoteText>
              <DetailsText>{policyData.description}</DetailsText>
              <AddPolicyGreenButton
                disabled={!isActiveTab}
                onClick={() => handleSelectPolicy(policyData.policy)}
                data-cy={`add-policy-type-${policyData.policy}-from-not-selected`}
              >
                Add {policyData.title}
              </AddPolicyGreenButton>
              {shouldShowSwitchToGL && (
                <>
                  <ChangePolicyDetails>
                    Don't need property coverage? Quote a General Liability policy
                  </ChangePolicyDetails>
                  <AddPolicyGhostButton
                    isabled={!isActiveTab}
                    onClick={() => handleSelectPolicy(PolicyType.GL)}
                    data-cy={"add-policy-type-GL-from-not-selected"}
                  >
                    Add General Liability
                  </AddPolicyGhostButton>
                </>
              )}
              {shouldShowSwitchToBOP && (
                <>
                  <ChangePolicyDetails>
                    If your client needs commercial property coverage, we recommend quoting a Business Owners Policy.
                  </ChangePolicyDetails>
                  <AddPolicyGhostButton
                    disabled={!isActiveTab}
                    onClick={() => handleSelectPolicy(PolicyType.BOP)}
                    data-cy={"add-policy-type-BOP-from-not-selected"}
                  >
                    Add Business Owners Policy
                  </AddPolicyGhostButton>
                </>
              )}
            </div>
            <div>
              <HalfCircleContainer>
                <HalfCircle />
              </HalfCircleContainer>
            </div>
          </NoCoverageSelectedContainer>
        )}
      </>
    </TabPanelContainer>
  );
};

const headerBackgroundColor = (theme: DefaultTheme, policyType?: PolicyType) => {
  const policyTypes = {
    [PolicyType.BOP]: theme.yellow10,
    [PolicyType.GL]: theme.green10,
    [PolicyType.PL]: theme.orange10,
  };

  return policyType ? policyTypes[policyType] : theme.offWhite;
};

const TabPanelContainer = styled.div<{ isActiveTab: boolean }>`
  visibility: ${(props) => (props.isActiveTab ? "visible" : "hidden")};
  opacity: ${(props) => (props.isActiveTab ? "1" : "0")};
  transition: opacity 0.15s ease;
  display: block;
  margin-right: -100%;
  width: 100%;
  z-index: ${(props) => (props.isActiveTab ? "2" : "1")};
`;

const CoverageContainer = styled.div<{ isVisible: boolean }>`
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  padding: 0 40px 40px 40px;
`;

const NoCoverageSelectedContainer = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HeaderContainer = styled.div<{ policyType?: PolicyType; tabNumber?: number }>`
  display: flex;
  justify-content: ${(props) => (props.tabNumber === 0 ? "flex-start" : "flex-end")};
  padding: 16px 40px;
  align-items: center;
  background: ${(props) => headerBackgroundColor(props.theme, props.policyType)};
  min-height: 50px;
  width: 100%;
  margin-bottom: 24px;
  font-size: 15px;
  ${tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PolicyDescription = styled.p`
  margin-bottom: 0;
  font-family: questrial;
  ${tablet} {
    width: 100%;
  }
`;

const NotAddedToQuoteText = styled.p`
  font-size: 20px;
  font-weight: 600;
  padding: 0 40px;
  margin-top: 26px;
`;

const AddPolicyGreenButton = styled(GreenButton)`
  padding: 7px 16px;
  margin-top: 16px;
`;

const AddPolicyGhostButton = styled(GhostButton)`
  padding: 7px 16px;
`;

const DetailsText = styled.p`
  margin: 0;
  font-size: 15px;
`;

const ChangePolicyDetails = styled(DetailsText)`
  margin: 32px 0 16px 0;
  font-size: 15px;
`;

const PolicyNotSelectedIcon = styled.span`
  margin-bottom: 16px;
`;

const SwitchPolicyButton = styled(TextLink)`
  font-size: 15px;
  padding: 0;
  margin-bottom: -1px;
  margin-left: 5px;
`;

const HalfCircleContainer = styled.div`
  transform: rotate(180deg);
  margin-top: 60px;
  bottom: 0px;
  width: 100%;
  svg path {
    fill: ${(props) => props.theme.disabledBackground};
  }
`;

const DeclinationHeader = styled.p`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
`;

const StyledCaret = styled(Caret)`
  transform: rotate(90deg);
  margin-bottom: 3px;
`;

const mapStateToProps = (state: ReduxState) => ({
  exclusions: selectUnderwritingProfileExclusions(state),
});

export default connect(mapStateToProps)(CoverageTabPanel);
