import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StyledHeader3, HeaderTextContainer, PageHeaderContainer, PageHeaderSubText, LoaderContainer } from "../styles";
import { Paragraph } from "styles/paragraph";
import AjaxLoader from "shared/AjaxLoader";
import { ReactComponent as CheckmarkIcon } from "images/checkmark_securus.svg";

interface LoadingScreenProps {
  isLoading: boolean;
  loadingData?: { headerText: string; subHeaderText?: string; steps: string[] };
}

const LoadingScreen = ({ isLoading, loadingData }: LoadingScreenProps) => {
  const [mockLoadingStep, setMockLoadingStep] = useState(0);

  useEffect(() => {
    if (mockLoadingStep < (loadingData?.steps?.length ?? 0)) {
      const timeout = setTimeout(() => {
        setMockLoadingStep(mockLoadingStep + 1);
      }, 3200);
      return () => clearTimeout(timeout);
    }
  }, [mockLoadingStep]);

  return (
    <>
      <PageHeaderContainer>
        <LoaderContainer>
          <AjaxLoader height={64} width={80} />
        </LoaderContainer>
        <HeaderTextContainer>
          <StyledHeader3>{loadingData?.headerText}</StyledHeader3>
          {loadingData?.subHeaderText && <PageHeaderSubText>{loadingData?.subHeaderText}</PageHeaderSubText>}
        </HeaderTextContainer>
      </PageHeaderContainer>
      {Boolean(loadingData?.steps.length) && (
        <LoadingStepsContainer>
          {loadingData?.steps.map((step, index) => {
            const isLoaded = mockLoadingStep + 1 > index;
            const dividerIsLoaded = mockLoadingStep > index;
            const isMockLoading = mockLoadingStep === index;
            const dividerIsPending = mockLoadingStep < index;
            return (
              <>
                <FlexDiv>
                  <CheckmarkContainer isLoaded={isLoaded}>
                    <CheckmarkIcon title="verified" transform="translate(8,4)" />
                  </CheckmarkContainer>
                  <StyledParagraph isLoaded={isLoaded}>
                    {step}
                    {(isMockLoading || isLoading) && "…"}
                  </StyledParagraph>
                </FlexDiv>
                {loadingData?.steps.length - index > 1 && (
                  <Divider isLoaded={dividerIsLoaded} isLoading={isMockLoading} isPending={dividerIsPending} />
                )}
              </>
            );
          })}
        </LoadingStepsContainer>
      )}
    </>
  );
};

const LoadingStepsContainer = styled.div`
  margin-top: 32px;
  width: 336px;
`;

const FlexDiv = styled.div`
  display: flex;
  grid-gap: 16px;
`;

const CheckmarkContainer = styled.div<{ isLoaded: boolean }>`
  height: 33px;
  width: 33px;
  border: 1px solid ${(props) => (props.isLoaded ? props.theme.blue : props.theme.lighterGray)};
  border-radius: 100%;
  path {
    fill: ${(props) => (props.isLoaded ? props.theme.blue : props.theme.lighterGray)};
  }
`;

const StyledParagraph = styled(Paragraph)<{ isLoaded: boolean }>`
  color: ${(props) => (props.isLoaded ? props.theme.textNavyBlue : props.theme.lighterGray)};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  font-weight: 600;
  line-height: 33px;
`;

const Divider = styled.div<{ isLoaded: boolean; isLoading: boolean; isPending: boolean }>`
  height: 40px;
  width: 4px;
  border: ${(props) => {
    if (props.isLoaded) return `2px solid ${props.theme.blue}`;
    if (props.isPending) return `2px solid ${props.theme.lighterGray}`;
  }};
  background-image: ${(props) => {
    if (props.isLoading) return `linear-gradient(${props.theme.blue} 50%, ${props.theme.blue10} 50%)`;
  }};
  border-radius: 2px;
  margin: 4px 15px;
`;

export default LoadingScreen;
