import { useMutation } from "@tanstack/react-query";
import Api from "utils/api";
import useAuth from "./useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

export const usePostBindQuote = (options?: any) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const bindQuote = async (payload: BindRequestWithStripe) => {
    const token = await getToken();
    const api = new Api(apiVersion, token);
    return api.application.bindQuoteWithStripe(payload);
  };

  return useMutation({
    mutationFn: bindQuote,
    ...options,
  });
};
