import React, { useState } from "react";
import trash_can from "images/delete_blue.svg";
import file_icon from "images/icon-pdf_blue.svg";
import { ImageDetails, FileNameText, FileSizeText, ButtonsContainer, Button, FileIcon, TrashIcon } from "./styles";
import PdfHeaderPreview from "./PdfHeaderPreview";
import { Modal, ModalActionBar, ModalBody } from "coterie-ui-library";

type PropTypes = {
  fileName: string | null;
  fileSize: number | null;
  formatFileSize: (fileSize: number | null) => React.ReactNode;
  removeImage: () => void;
  logoUrl: string;
};

const ImageActions = ({ fileName, fileSize, formatFileSize, removeImage, logoUrl }: PropTypes) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  return (
    <ImageDetails>
      <Modal
        title="Delete this image?"
        label="Delete this image?"
        isOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
      >
        <ModalBody>
          <p> Are you sure you want to delete this image file as your agency's logo?</p>
        </ModalBody>
        <ModalActionBar
          handleCTAOnClick={() => {
            removeImage();
            setIsDeleteModalOpen(!isDeleteModalOpen);
          }}
          ctaText="Delete Image"
          handleSecondaryOnClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        />
      </Modal>
      <FileNameText>{fileName}</FileNameText>
      <FileSizeText>{formatFileSize(fileSize)}</FileSizeText>
      <ButtonsContainer>
        <Button data-testid="open_preview_modal" onClick={() => setIsModalOpen(true)}>
          <FileIcon src={file_icon} alt="preview quote proposal" />
          Preview Quote Proposal
        </Button>
        <Button data-testid="remove_preview_btn" onClick={() => setIsDeleteModalOpen(true)}>
          <TrashIcon src={trash_can} alt="remove image" />
          Delete image file
        </Button>
      </ButtonsContainer>
      <PdfHeaderPreview isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} agencyLogo={logoUrl} />
    </ImageDetails>
  );
};

export default ImageActions;
