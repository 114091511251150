import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { theme } from "styles";

const FormsHeader = () => (
  <View fixed>
    <Text style={styles.pageHeaderTitle}>Forms</Text>
    <View style={styles.headerBar}>
      <View style={styles.descriptionBox}>
        <Text style={styles.headerBarText}>Description</Text>
      </View>
      <View style={styles.formNumberBox}>
        <Text style={styles.headerBarText}>Form Number</Text>
      </View>
    </View>
  </View>
);

const styles = StyleSheet.create({
  pageHeaderTitle: {
    marginBottom: 16,
    fontSize: "18px",
    color: theme.colors.tertiary.zero,
  },

  headerBar: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 2,
  },
  headerBarText: {
    fontSize: 11,
    color: theme.white,
    marginLeft: 6,
    fontFamily: theme.font.typeface.primary,
    fontWeight: 400,
  },
  descriptionBox: {
    fontFamily: theme.font.typeface.primary,
    fontWeight: 400,
    fontSize: "11px",
    backgroundColor: theme.colors.tertiary.zero,
    width: 387,
    marginRight: 3,
    paddingTop: 3,
    paddingBottom: 3,
    marginBottom: 5,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
  },
  formNumberBox: {
    fontFamily: theme.font.typeface.primary,
    fontWeight: 400,
    fontSize: "11px",
    backgroundColor: theme.colors.tertiary.zero,
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    width: 150,
    paddingTop: 3,
    paddingBottom: 3,
    marginBottom: 5,
  },
});

export default FormsHeader;
