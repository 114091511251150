import React, { useState } from "react";
import AjaxLoader from "shared/AjaxLoader";
import { StyledIndustrySearch } from "shared/application/StyledComponents";
import styled from "styled-components";
import { Label } from "styles";
import { reportIndustrySearchError } from "utils/reportError";
import { ReactComponent as Close } from "images/close-blue.svg";

interface PropTypes {
  selectedIndustries: IndustryListType[];
  setSelectedIndustries: React.Dispatch<React.SetStateAction<IndustryListType[]>>;
}

const MultiSelectIndustrySearch = ({ selectedIndustries, setSelectedIndustries }: PropTypes) => {
  const [currentIndustry, setCurrentIndustry] = useState<IndustryListType>();
  const [limitError, setlimitError] = useState<{ hasTenItems: boolean; message: string }>({
    hasTenItems: false,
    message: "You have reached the maximum of 10 industries",
  });

  const addIndustryToList = (industryId: number, industry: string, akHash: string) => {
    setCurrentIndustry({ industryId: Number(industryId), industry: industry, akHash: akHash });
    //Limits the maximum to ten items
    if (selectedIndustries.length >= 10) {
      setlimitError({ ...limitError, hasTenItems: true });
      return;
    }
    //Stops duplicate values in the list
    if (selectedIndustries.find((industry: { akHash: string }) => industry.akHash === akHash)) {
      return;
    }
    setSelectedIndustries([
      ...selectedIndustries,
      { industryId: Number(industryId), industry: industry, akHash: akHash },
    ]);
  };

  const removeIndustryItem = (industry: IndustryListType) => {
    setlimitError({ ...limitError, hasTenItems: false });
    setCurrentIndustry({
      industryId: Number(industry.industryId),
      industry: industry.industry,
      akHash: industry.akHash,
    });
    setSelectedIndustries(
      selectedIndustries.filter(
        (selectedIndustry: { industry: string }) => selectedIndustry.industry !== industry.industry
      )
    );
  };

  return (
    <Container>
      <Label>Available Industries</Label>
      <MultiIndustrySearch
        selectIndustry={addIndustryToList}
        placeholder="Search Industry Keywords"
        industryName={currentIndustry?.industry}
        industryId={currentIndustry?.industryId}
        loadingElement={<AjaxLoader />}
        reportError={reportIndustrySearchError}
      />
      {limitError.hasTenItems ? (
        <HelperLabel error>{limitError.message}</HelperLabel>
      ) : (
        <HelperLabel>Please select up to 10 industries.</HelperLabel>
      )}
      {selectedIndustries.length > 0 ? (
        <ListContainer>
          <IndustryList>
            {selectedIndustries?.slice(0, 5).map((industry: IndustryListType, i: number) => (
              <IndustryListItem key={i}>
                {industry.industry}
                <CloseButton onClick={() => removeIndustryItem(industry)} />
              </IndustryListItem>
            ))}
          </IndustryList>
          <IndustryList>
            {selectedIndustries?.slice(5, 10).map((industry: IndustryListType, i: number) => (
              <IndustryListItem key={i}>
                {industry.industry}
                <CloseButton onClick={() => removeIndustryItem(industry)} />
              </IndustryListItem>
            ))}
          </IndustryList>
        </ListContainer>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 32px;
`;

const ListContainer = styled.div`
  display: flex;
`;

const IndustryList = styled.ul`
  display: flex;
  flex-flow: column wrap;
  padding: 0px;
  list-style-type: none;
  > li {
    background-color: white;
  }
`;

const IndustryListItem = styled.li`
  display: flex;
  justify-content: space-between;
  width: 240px;
  height: fit-content;
  color: ${({ theme }) => theme.blue};
  font-family: Questrial;
  font-size: 15px;
  letter-spacing: 0;
  box-sizing: border-box;
  line-height: 23px;
  margin: 5px 20px 5px 0px;
  padding: 8px;
  border-radius: 5px;
`;
const CloseButton = styled(Close)`
  min-height: 14px;
  min-width: 14px;
  max-height: 14px;
  max-width: 14px;
  margin: 4px;
  &:hover {
    cursor: pointer;
    opacity: 85%;
  }
`;

const HelperLabel = styled.label<{ error?: boolean }>`
  color: ${(props) => (props.error ? props.theme.errorText : props.theme.gray)};
  font-family: Gilroy;
  font-size: 12px;
  font-weight: ${(props) => (props.error ? "600" : "")};
  letter-spacing: 0.75px;
  line-height: 16px;
`;

const MultiIndustrySearch = styled(StyledIndustrySearch)`
  margin-bottom: 8px;

  p.header {
    display: none;
  }
  div {
    background-color: ${({ theme }) => theme.white};
    margin-top: 5px;
  }

  div > div > .no-results {
    margin: auto;
    padding: auto;
  }

  ul {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    margin: 0;
    li {
      padding: 10px 20px;
      margin: 0px;
    }
  }
  img {
    max-width: 40px;
    position: absolute;
    right: 0;
    top: 16px;
  }
  button {
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.white};
    font-weight: 600;
  }
`;

export default MultiSelectIndustrySearch;
