import React, { useState, useContext } from "react";
import useAPI from "hooks/useAPI";
import { cleanRuleForPost } from "./utils";
import ConditionBlock from "./ruleBuildingBlocks/ConditionBlock";
import ControlBlock from "./ruleBuildingBlocks/ControlBlock";
import MessageBlock from "./ruleBuildingBlocks/MessageBlock";
import StatesBlock from "./ruleBuildingBlocks/StatesBlock";
import { SubRules, FlexDiv, LogicBlockHeader } from "./styles";
import { createGlobalStyle } from "styled-components";
import { StyledSelect } from "styles/Dropdown";
import { UpdatePreviewModal, SuccessModal } from "./Modals";
import { RuleBuilderContext } from "./RuleBuilderContext";
import LogicButtonBar from "./LogicButtonBar";
import { reportFailedSubmitRule } from "utils/reportError";
import { Rule, RuleOptions } from "../Shared/types";
import styled from "styled-components";
import Properties from "./Properties";
import TagsBlock from "./ruleBuildingBlocks/TagsBlock";

export interface OptionType {
  value: string | number;
  label: string;
}

const UnderwritingRules = () => {
  const { rule, ruleIsCreate, updateRulePath, setRule } = useContext(RuleBuilderContext);
  const [submitModalIsOpen, setSubmitModalIsOpen] = useState(false);
  const [cleanRule, setCleanRule] = useState<null | Rule[]>(null);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);

  const { loading: isLoadingRuleUpdate } = useAPI("updateRule", [cleanRule], {
    waitFor: Boolean(cleanRule && !ruleIsCreate),
    onSuccess: (res) => {
      setSubmitModalIsOpen(false);
      setSuccessModalIsOpen(true);
      setRule(res[0]);
    },
    onError: (res) => reportFailedSubmitRule(res),
  });

  const { loading: isLoadingRuleCreate } = useAPI("createRule", [cleanRule], {
    waitFor: Boolean(cleanRule && ruleIsCreate),
    onSuccess: (res) => {
      setSubmitModalIsOpen(false);
      setSuccessModalIsOpen(true);
      setRule(res[0]);
    },
    onError: (res) => reportFailedSubmitRule(res),
  });

  const renderRule = (rule: Rule, index: number) => {
    return rule?.options?.map((ruleOption: RuleOptions, optionIndex: number) => {
      return (
        <div key={ruleOption.uniqueKey}>
          {index > 0 && <LogicBlockHeader>And</LogicBlockHeader>}
          <div
            onClick={() => {
              updateRulePath(ruleOption.path);
            }}
            onFocus={() => {
              updateRulePath(ruleOption.path);
            }}
          >
            {rule.variable !== null && (
              <ConditionBlock variable={rule.variable} ruleOption={ruleOption} isDisabled={Boolean(rule.disabled)} />
            )}
            {ruleOption.hasOwnProperty("states") && (
              <StatesBlock ruleOption={ruleOption} states={ruleOption.states as string[]} />
            )}
            {ruleOption.hasOwnProperty("controlAction") && <ControlBlock ruleOption={ruleOption} />}
            {ruleOption.hasOwnProperty("message") && <MessageBlock ruleOption={ruleOption} />}
            {optionIndex === 0 && Boolean(rule.properties?.length) && <Properties />}
            {optionIndex === 0 && Boolean(rule.tags) && <TagsBlock />}
          </div>
          {ruleOption.subRules?.length ? (
            <SubRules>
              <LogicBlockHeader>Subrules</LogicBlockHeader>
              {ruleOption.subRules?.map((rule: Rule, i: number) => renderRule(rule, i))}
            </SubRules>
          ) : null}
        </div>
      );
    });
  };

  return (
    <FlexDiv>
      <OverrideStyle />
      <RuleContainer>{renderRule(rule, 0)}</RuleContainer>
      <LogicButtonBar openSubmitModal={() => setSubmitModalIsOpen(true)} />
      <UpdatePreviewModal
        isOpen={submitModalIsOpen}
        setIsOpen={setSubmitModalIsOpen}
        handleSubmit={() => setCleanRule([cleanRuleForPost(rule)])}
        rule={rule}
        loading={isLoadingRuleCreate || isLoadingRuleUpdate}
      />
      <SuccessModal
        isOpen={successModalIsOpen}
        setIsOpen={setSuccessModalIsOpen}
        isNewRule={ruleIsCreate}
        ruleLabel={rule.label}
      />
    </FlexDiv>
  );
};

const OverrideStyle = createGlobalStyle`
  ${StyledSelect} {
    margin-bottom: 0;
  }
`;

const RuleContainer = styled.div`
  width: 100%;
`;

export default UnderwritingRules;
