import { createSelector } from "@reduxjs/toolkit";
import { getSnapshotProducerId } from "redux/reducers/_snapshotReducer";
import { Role } from "types/enums";

export const selectAccountUserName = (state: ReduxState) => {
  const hasAccountName =
    state.user.account &&
    typeof state.user.account.firstName !== "undefined" &&
    typeof state.user.account.lastName !== "undefined";

  return hasAccountName ? `${state.user.account.firstName} ${state.user.account.lastName}` : "";
};

export const selectApplicationFormUserEmail = (state: ReduxState) => {
  const accountEmail = state.user?.account?.email || "";
  const userEmail = state.user?.email || "";
  return userEmail || accountEmail || "";
};

export const selectUserAccount = (state: ReduxState) => state.user.account;
export const selectUserAccountId = (state: ReduxState) => selectUserAccount(state)?.id;
export const selectChannelPartner = (state: ReduxState) => state.user.channelPartner || {};
export const selectUserAgency = (state: ReduxState) => state.user.agency;
export const selectUserAgencyId = (state: ReduxState) => selectUserAgency(state)?.id;
export const selectUserAgencyName = (state: ReduxState) => selectUserAgency(state)?.name;
export const selectUserAgencyExternalId = (state: ReduxState) => selectUserAgency(state)?.externalId;
export const selectUserRoles = (state: ReduxState) => state.user.roles;
export const selectIsIAUser = (state: ReduxState) => {
  const userRoles = selectUserRoles(state);
  return userRoles.includes(Role.IAProducer) || userRoles.includes(Role.NonLicensed);
};
export const selectToken = (state: ReduxState) => state.token?.token || "";
export const selectAuth0Id = (state: ReduxState) => state.user.id;
export const getUserExternalId = (state: ReduxState) => selectUserAccount(state)?.externalId || "";
export const selectIsChannelPartnerOwner = (state: ReduxState) => {
  const channelPartner = selectChannelPartner(state);
  const account = selectUserAccount(state);
  return channelPartner?.accountId === account?.id;
};

export const selectUserName = createSelector(
  [selectAccountUserName, selectUserAgencyName, selectApplicationFormUserEmail],
  (username, agencyName, userEmail) => {
    if (username) {
      return username;
    } else if (agencyName) {
      return agencyName;
    } else if (userEmail) {
      return userEmail;
    } else {
      return "";
    }
  }
);

const selectApplicationForm = (state: ReduxState) => state.application.form || {};
const selectIaDetailsProducerExternalID = (state: ReduxState) =>
  selectApplicationForm(state).iaDetails?.producerExternalId || "";

export const selectProducerExternalId = createSelector(
  [getUserExternalId, getSnapshotProducerId, selectIaDetailsProducerExternalID, selectUserRoles],
  (currentUserExternalId, snapshotExternalId, applicationResponseExternalId, selectUserRoles) => {
    const isNonLicensedUser = Array.isArray(selectUserRoles) && selectUserRoles.includes(Role.NonLicensed);
    const bindAsExternalId = currentUserExternalId || applicationResponseExternalId || snapshotExternalId;
    return bindAsExternalId && !isNonLicensedUser ? bindAsExternalId : "";
  }
);
