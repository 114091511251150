import React, { useReducer, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import { PolicyType } from "types/enums";
import { stateAbbrevOptions } from "utils/constants";
import Dropdown from "styles/Dropdown";
import AjaxLoader from "shared/AjaxLoader";
import { SubTitle, StyledIndustrySearch } from "../application/StyledComponents";
import { ModalContentContainer, ModalField, Row, Title } from "shared/ModalComponents";
import { setAvailablePolicyTypes, resetApplication } from "redux/reducers/_applicationReducer";
import createApplicationFromQualificationChecker from "redux/actions/createApplication";
import Modal from "../Modal";
import qualificationFormReducer, { initialState } from "./reducer";
import Approved from "./Approved";
import Denied from "./Denied";
import { GetQualifiedButton, Card, Container } from "./styles";
import {
  selectIsApplicationLoading,
  selectApplicationAvailablePolicyTypes,
  selectApplicationFormId,
  selectApplicationNetworkError,
} from "redux/selectors/application";
import useAuth from "hooks/useAuth";
import ToolTipIcon from "shared/ToolTipIcon";

import { reportIndustrySearchError } from "utils/reportError";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  createApplicationFromQualificationChecker: (ar0: ApplicationFailFast) => void;
  resetApplication: ActionCreatorWithoutPayload<any>;
  availablePolicyTypes: [PolicyType];
  applicationId: string;
  networkError: any;
  loadingQuote: boolean;
}

const QualificationChecker = ({
  createApplicationFromQualificationChecker,
  availablePolicyTypes,
  applicationId,
  networkError,
  loadingQuote,
  resetApplication,
}: PropTypes) => {
  const [application, dispatch] = useReducer(qualificationFormReducer, initialState);
  const { getToken, isIAUser } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const approved = applicationId && Boolean(!networkError && availablePolicyTypes.length > 0 && application.industryId);
  const denied = Boolean(
    applicationId && (networkError || application.industryId === undefined || availablePolicyTypes.length <= 0)
  );

  useEffect(() => {
    resetIQC();
  }, []);

  const updateIndustry = (industryId: number, industry: string, akHash: string) => {
    dispatch({ type: "UPDATE_IQC_FORM", payload: { industry, industryId, akHash } });
  };

  const handleStateChange = (state: any) => {
    dispatch({ type: "UPDATE_IQC_FORM", payload: { state: state.value } });
  };

  const resetIQC = () => {
    dispatch({ type: "RESET_IQC_FORM" });
    resetApplication();
  };

  const { industryId, industry, state } = application;

  const isDisabled = industry === "" || industryId === 0 || industryId === null || state === "";

  const handleCheckAppetite = async () => {
    const token = await getToken();
    createApplicationFromQualificationChecker({ ...application, token, isIAUser, apiVersion });
  };

  return (
    <Wrapper>
      <StyledRow>
        <Title>Appetite Checker</Title>
        <StyledSubtitle>
          Check if a risk you want to write is in appetite. We rate every risk individually so risk classification can
          vary.
        </StyledSubtitle>
      </StyledRow>
      <StyledCard>
        <Container>
          <ModalField htmlFor="industry-search-input">
            Select industry
            <ToolTipIcon contentKey="SELECT_INDUSTRY" />
          </ModalField>
          <StyledIndustrySearch
            selectIndustry={updateIndustry}
            industryId={Number(industryId ?? 0)}
            industryName={industry}
            hasError={industryId === null}
            loadingElement={<AjaxLoader />}
            reportError={reportIndustrySearchError}
          />
          <Row>
            <Dropdown
              onChange={handleStateChange}
              options={stateAbbrevOptions}
              value={state || ""}
              label="State"
              name="state"
            />
          </Row>
        </Container>
      </StyledCard>
      <Section>
        <GetQualifiedButton
          onClick={handleCheckAppetite}
          data-cy="next-screen"
          data-testid="next-button"
          disabled={isDisabled}
        >
          {loadingQuote ? <Spinner color="light" /> : <span>Check Appetite</span>}
        </GetQualifiedButton>
      </Section>
      <Modal isOpen={approved || denied} closeModal={() => resetIQC()} title="Appetite Checker" label="iqc-modal">
        <ModalContentContainer>
          {approved ? (
            <Approved availablePolicyTypes={availablePolicyTypes} applicationId={applicationId} />
          ) : (
            <Denied resetIQC={resetIQC} />
          )}
        </ModalContentContainer>
      </Modal>
    </Wrapper>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  availablePolicyTypes: selectApplicationAvailablePolicyTypes(state),
  applicationId: selectApplicationFormId(state),
  networkError: selectApplicationNetworkError(state),
  loadingQuote: selectIsApplicationLoading(state),
});

const mapDispatchToProps = {
  createApplicationFromQualificationChecker,
  setAvailablePolicyTypes,
  resetApplication,
};

const StyledRow = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.lighterGray};
  padding-bottom: 16px;
`;

const StyledCard = styled(Card)`
  margin-top: 32px;
`;

const Section = styled.div`
  position: absolute;
  top: 0;
  margin: 85px 970px;

  @media screen and (max-width: 1400px) {
    position: initial;
    top: auto;
    right: auto;
    margin: 0;
  }
`;

const StyledSubtitle = styled(SubTitle)`
  max-width: 660px;
  width: 100%;
`;

const Wrapper = styled.div`
  max-width: 1100px;
  width: 100%;
`;

export default connect(mapStateToProps, mapDispatchToProps)(QualificationChecker);
