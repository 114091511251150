import useGetPaymentSummary from "hooks/useGetPaymentSummary";
import React, { useEffect, useState } from "react";
import AjaxLoader from "shared/AjaxLoader";
import LabelLarge from "shared/LabelLarge";
import Modal from "shared/ModalBasic";
import { ActionContainer, ModalContentContainer } from "shared/ModalComponents";
import { GhostButton } from "shared/SimplyBind/styles";
import styled from "styled-components";
import { GreenButton, TextLink } from "styles";
import { ErrorText } from "styles/Input";
import { reportError } from "utils/reportError";
import ChargeAmountSection from "./ChargeAmountSection";
import ExistingCard from "./ExistingCard";
import OneTimePaymentCard from "./OneTimePaymentCard";
import { formatCurrency } from "utils/formatNumber";

interface PropTypes {
  onRequestClose: () => void;
  modalTitle: string;
  modalIsOpen: boolean;
  setChargeRequestData: React.Dispatch<
    React.SetStateAction<{
      apiPost: OneTimePaymentPayload;
      lastFourDigits: string;
    }>
  >;
  setIsConfirmationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  policyNumber: string;
  transactionFeeAmount?: number;
}

const generateLabel = (modalTitle: string): string => {
  return modalTitle?.replace(/ /g, "-").toLowerCase();
};

const NEW_CARD = "NEW_CARD";

const OneTimePayment = ({
  onRequestClose,
  setChargeRequestData,
  setIsConfirmationModalOpen,
  modalTitle,
  modalIsOpen,
  policyNumber,
  transactionFeeAmount,
}: PropTypes) => {
  const [selectedCard, setSelectedCard] = useState("");
  const [isPaymentMethodSelected, setIsPaymentMethodSelected] = useState(false);
  const [enterAmountError, setEnterAmountError] = useState("");
  const [isMakeDefaultPaymentSelected, setIsMakeDefaultPaymentSelected] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState<number | undefined>(undefined);
  const [tokenData, setTokenData] = useState({ id: "", lastFourDigits: "" });
  const [isStripeTriggered, setIsStripeTriggered] = useState(false);

  const { data: paymentProfileData, isLoading, isSuccess, isError, error } = useGetPaymentSummary(policyNumber);

  useEffect(() => {
    if (isSuccess) {
      if (selectedCard !== NEW_CARD) {
        setSelectedCard(paymentProfileData?.paymentProfile?.creditCard);
      }
    }
  }, [isSuccess, selectedCard, paymentProfileData]);

  useEffect(() => {
    if (isError) {
      reportError(error);
    }
  }, [isError, error]);

  const isStripeProfile = Boolean(paymentProfileData?.customerProfileId?.includes("cus"));

  const submitActions = () => {
    setIsConfirmationModalOpen(true);
    onRequestClose();
  };

  const handleOnSubmit = () => {
    if (enteredAmount !== undefined && enteredAmount <= 0) {
      setEnterAmountError("Please enter an amount");
    } else if (isStripeProfile && selectedCard === NEW_CARD) {
      setIsStripeTriggered(true);
    } else {
      submitActions();
    }
  };

  useEffect(() => {
    if (selectedCard !== NEW_CARD) {
      setIsMakeDefaultPaymentSelected(false);
    }
  }, [selectedCard]);

  useEffect(() => {
    enteredAmount != null &&
      setChargeRequestData({
        apiPost: {
          amount: enteredAmount,
          sourceId: tokenData.id,
          updatePayment: isMakeDefaultPaymentSelected,
        },
        lastFourDigits: tokenData.lastFourDigits,
      });
  }, [enteredAmount, isMakeDefaultPaymentSelected, tokenData]);

  const isSubmitDisabled =
    !isPaymentMethodSelected ||
    enteredAmount === 0 ||
    enteredAmount === undefined ||
    enteredAmount > paymentProfileData?.amountOwed;

  return (
    <Modal isOpen={modalIsOpen} closeModal={onRequestClose} label={generateLabel(modalTitle)} title={modalTitle}>
      <ModalContentContainer maxHeight="614px" centered>
        {isLoading || !paymentProfileData ? (
          <AjaxLoader />
        ) : (
          <OneTimePaymentContainer aria-label="one-time-payment-content">
            <LabelLarge>Payment Method</LabelLarge>
            <DefaultLabel>Default</DefaultLabel>
            <ExistingCard
              paymentProfile={paymentProfileData?.paymentProfile}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              setIsPaymentMethodSelected={setIsPaymentMethodSelected}
            />
            {selectedCard === NEW_CARD ? (
              <OneTimePaymentCard
                customerProfileId={paymentProfileData.customerProfileId}
                setIsPaymentMethodSelected={setIsPaymentMethodSelected}
                isMakeDefaultPaymentSelected={isMakeDefaultPaymentSelected}
                setIsMakeDefaultPaymentSelected={setIsMakeDefaultPaymentSelected}
                setTokenData={setTokenData}
                isStripeTriggered={isStripeTriggered}
                submitActions={submitActions}
              />
            ) : (
              <AddNewPaymentLink
                onClick={() => {
                  setSelectedCard(NEW_CARD);
                }}
              >
                Add new payment method +
              </AddNewPaymentLink>
            )}
            <ChargeSectionContainer>
              <LabelLarge>Charge Amount</LabelLarge>
              {transactionFeeAmount && (
                <TransactionFeeWarning>{`Note: all one time charges will incur a ${formatCurrency(
                  transactionFeeAmount
                )} transaction fee`}</TransactionFeeWarning>
              )}
              <ErrorText>{enterAmountError}</ErrorText>
              <ChargeAmountSection
                amountOwed={paymentProfileData.amountOwed}
                enteredAmount={enteredAmount}
                setEnteredAmount={setEnteredAmount}
              />
            </ChargeSectionContainer>
            <ActionContainer>
              <CancelGhostButton onClick={onRequestClose}>Cancel</CancelGhostButton>
              <SubmitGreenButton
                onClick={handleOnSubmit}
                data-cy="cta-button"
                disabled={isSubmitDisabled}
                data-testid={isSubmitDisabled ? "cta-button-disabled" : "cta-button-active"}
              >
                Submit
              </SubmitGreenButton>
            </ActionContainer>
          </OneTimePaymentContainer>
        )}
      </ModalContentContainer>
    </Modal>
  );
};

const OneTimePaymentContainer = styled.div`
  position: relative;
`;
const DefaultLabel = styled.label`
  height: 13px;
  color: ${(props) => props.theme.gray};
  font-family: Questrial;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 16px;
`;

const ChargeSectionContainer = styled.div`
  margin: 70px 0px 40px;
`;
const AddNewPaymentLink = styled(TextLink)`
  position: absolute;
  right: 0;
`;
const CancelGhostButton = styled(GhostButton)`
  width: 156px;
  height: 46px;
  margin-right: 8px;
`;
const SubmitGreenButton = styled(GreenButton)`
  width: 156px;
  height: 46px;
  margin-left: 8px;
`;

const TransactionFeeWarning = styled.p`
  margin-top: -1rem;
  margin-bottom: 0;
`;

export default OneTimePayment;
