import { ReactNode } from "react";
import styled, { css } from "styled-components";
import { Header } from "coterie-ui-library";
import { Checkbox } from "styles";
import warning from "images/warning.svg";
import bop_gray from "images/bop_gray.svg";
import gl_gray from "images/gl_gray.svg";
import pl_gray from "images/pl_gray.svg";
import gl_policy from "images/gl_policy.svg";
import bop_policy from "images/bop_policy.svg";
import pl_policy from "images/pl_policy.svg";
import { PolicyType } from "types/enums";

const images: Record<PolicyType, Record<"gray" | "normal", string>> = {
  [PolicyType.BOP]: {
    gray: bop_gray,
    normal: bop_policy,
  },
  [PolicyType.GL]: {
    gray: gl_gray,
    normal: gl_policy,
  },
  [PolicyType.PL]: {
    gray: pl_gray,
    normal: pl_policy,
  },
};

interface PropTypes {
  title: string;
  titleColor: string;
  value: boolean;
  onChange: (value: boolean) => void;
  children?: ReactNode;
  unavailable?: boolean;
  unavailableMessage?: string;
  policyType: PolicyType;
  noPointer?: boolean;
}

const Coverage = ({
  title,
  titleColor,
  value,
  onChange,
  children,
  unavailable,
  unavailableMessage,
  policyType,
  noPointer,
}: PropTypes) => {
  return (
    <Container>
      <CoverageTitle
        titleColor={titleColor}
        noPointer={noPointer}
        onClick={() => onChange(!value)}
        className={value ? "checked" : ""}
      >
        <Checkbox
          checked={value}
          name={title}
          readOnly={noPointer}
          handleClick={() => onChange(!value)}
          ariaLabel={title}
        />
        {title}
        <Icon src={value ? images[policyType].normal : images[policyType].gray} alt={title} />
      </CoverageTitle>
      {children && value ? children : null}
      {unavailable && (
        <DisabledMessage>
          <Warning src={warning} alt="warning" />
          <Message as="h5" size="extra_small">
            {unavailableMessage}
          </Message>
        </DisabledMessage>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 16px;
`;

const CoverageTitle = styled.div<{ titleColor: string; noPointer: boolean | undefined }>`
  align-items: center;
  background-color: ${(props) => props.theme.colors.neutral.special};
  color: ${(props) => props.theme.colors.tertiary.zero};
  cursor: pointer;
  ${(props) =>
    props.noPointer &&
    css`
      cursor: not-allowed;
    `}

  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 8px 12px 16px;

  &.checked {
    background-color: ${(props) => props.titleColor};
  }

  input {
    margin: 0 16px 0 0;
  }
`;

const Message = styled(Header)`
  margin: 5px 0 0 0;
  color: ${(props) => props.theme.fontColors.secondary};
  letter-spacing: 1px;
`;

const DisabledMessage = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Warning = styled.img`
  width: 24px;
  margin-right: 4px;
`;

const Icon = styled.img`
  width: 62px;
  height: 40px;
  margin-left: auto;
  margin-bottom: -12px;
`;

export default Coverage;
