import { useMutation } from "@tanstack/react-query";
import Api from "utils/api";
import useAuth from "./useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const usePutAgencyLogo = (agencyId: string) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const putAgencyLogo = async (logo: FormData) => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api.agencyLogo.put(agencyId, logo);
  };

  const mutationFn = (file: File) => {
    const data = new FormData();
    data.append("file", file);

    return putAgencyLogo(data);
  };

  return useMutation({ mutationFn });
};

export default usePutAgencyLogo;
