import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useReducer } from "react";
import { CardInputWrapper } from "shared/Payment/UpdatePaymentMethodForm";
import styled from "styled-components";
import { theme } from "styles";
import { ErrorText } from "styles/Input";
import { stripeOptionsObject } from "utils/paymentUtils";
import { reportStripeError } from "utils/reportError";

interface OneTimePaymentStripeProps {
  setIsPaymentMethodSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setTokenData: React.Dispatch<
    React.SetStateAction<{
      id: string;
      lastFourDigits: string;
    }>
  >;
  isStripeTriggered: boolean;
  submitActions: () => void;
}

type StateType = {
  focusedInput: string | null;
  cardNumberError: string | null;
  expirationError: string | null;
  cvcError: string | null;
};

type Action =
  | { type: "focus_cc_number" }
  | { type: "focus_expiration" }
  | { type: "focus_cvc" }
  | { type: "blur_input" }
  | { type: "card-number-error"; payload: string | null }
  | { type: "cvc-error"; payload: string | null }
  | { type: "expiration-error"; payload: string | null };

const initialState = {
  focusedInput: null,
  cardNumberError: null,
  expirationError: null,
  cvcError: null,
};

function reducer(state: StateType, action: Action) {
  switch (action.type) {
    case "focus_cc_number":
      return {
        ...state,
        focusedInput: action.type,
      };
    case "focus_expiration":
      return {
        ...state,
        focusedInput: action.type,
      };
    case "focus_cvc":
      return {
        ...state,
        focusedInput: action.type,
      };
    case "card-number-error":
      return {
        ...state,
        cardNumberError: action.payload,
      };
    case "expiration-error":
      return {
        ...state,
        expirationError: action.payload,
      };
    case "cvc-error":
      return {
        ...state,
        cvcError: action.payload,
      };
    case "blur_input":
      return {
        ...state,
        focusedInput: null,
      };

    default:
      return state;
  }
}

const publishableKey = process.env.REACT_APP_STRIPE_KEY || "";
const stripePromise = loadStripe(publishableKey);

const OneTimePaymentStripeComponent = ({
  setIsPaymentMethodSelected,
  setTokenData,
  isStripeTriggered,
  submitActions,
}: OneTimePaymentStripeProps) => {
  const [{ focusedInput, cardNumberError, cvcError, expirationError }, dispatch] = useReducer(reducer, initialState);
  const stripe = useStripe();
  const elements = useElements();
  const hasFormError = cardNumberError !== "" || cvcError !== "" || expirationError !== "";

  const inputStyle = {
    fontWeight: "400",
    fontFamily: theme.secondaryFont,
    fontSize: "16px",
    lineHeight: "25px",
  };
  const cardNumberOptions = {
    placeholder: "Card Number",
    style: {
      base: inputStyle,
    },
  };
  const cardExpirationOptions = {
    placeholder: "MM/YY",
    style: {
      base: inputStyle,
    },
  };
  const cardCVCOptions = {
    placeholder: "CVC",
    style: {
      base: inputStyle,
    },
  };

  const submitStripeForm = async () => {
    const cardElement = elements && elements.getElement("cardNumber");

    if (stripe && cardElement) {
      const { token, error } = await stripe.createToken(cardElement);
      if (error) {
        if (error.type !== "card_error" && error.type !== "validation_error") {
          reportStripeError(error);
        }
      }
      if (token) {
        setTokenData({ id: token.id, lastFourDigits: token?.card?.last4 || "" });
        submitActions();
      }
    } else {
      reportStripeError("Stripe Failed To initialize", "An issue has occurred");
    }
  };

  const handleElementChange = (e: any) => {
    if (e?.elementType === "cardNumber") {
      if (e?.error) {
        dispatch({ type: "card-number-error", payload: e?.error?.message });
      } else {
        dispatch({ type: "card-number-error", payload: "" });
      }
    }
    if (e?.elementType === "cardExpiry") {
      if (e?.error) {
        dispatch({ type: "expiration-error", payload: e?.error?.message });
      } else {
        dispatch({ type: "expiration-error", payload: "" });
      }
    }
    if (e?.elementType === "cardCvc") {
      if (e?.error) {
        dispatch({ type: "cvc-error", payload: e?.error?.message });
      } else {
        dispatch({ type: "cvc-error", payload: "" });
      }
    }
  };

  useEffect(() => {
    if (!hasFormError) {
      setIsPaymentMethodSelected(true);
    } else {
      setIsPaymentMethodSelected(false);
    }
  }, [cardNumberError, cvcError, expirationError]);

  useEffect(() => {
    if (!hasFormError) {
      submitStripeForm();
    }
  }, [isStripeTriggered]);

  return (
    <>
      <StyledForm data-testid="stripe_form">
        <CardInputBorder width="235px" hasFocus={focusedInput === "focus_cc_number"}>
          <CardNumberElement
            id="credit_card_number"
            onChange={handleElementChange}
            onFocus={() => dispatch({ type: "focus_cc_number" })}
            onBlur={() => dispatch({ type: "blur_input" })}
            options={cardNumberOptions}
          />
        </CardInputBorder>
        <CardInputBorder width="82px" hasFocus={focusedInput === "focus_expiration"}>
          <CardExpiryElement
            id="expiration"
            onChange={handleElementChange}
            onFocus={() => dispatch({ type: "focus_expiration" })}
            onBlur={() => dispatch({ type: "blur_input" })}
            options={cardExpirationOptions}
          />
        </CardInputBorder>
        <CardInputBorder width="82px" hasFocus={focusedInput === "focus_cvc"}>
          <CardCvcElement
            id="cvc"
            onChange={handleElementChange}
            onFocus={() => dispatch({ type: "focus_cvc" })}
            onBlur={() => dispatch({ type: "blur_input" })}
            options={cardCVCOptions}
          />
        </CardInputBorder>
      </StyledForm>
      <ErrorText>{cardNumberError}</ErrorText>
      <ErrorText>{cvcError}</ErrorText>
      <ErrorText>{expirationError}</ErrorText>
    </>
  );
};

const OneTimePaymentStripe = ({
  setIsPaymentMethodSelected,
  setTokenData,
  isStripeTriggered,
  submitActions,
}: OneTimePaymentStripeProps) => {
  return (
    <Elements options={stripeOptionsObject} stripe={stripePromise}>
      <OneTimePaymentStripeComponent
        setTokenData={setTokenData}
        setIsPaymentMethodSelected={setIsPaymentMethodSelected}
        isStripeTriggered={isStripeTriggered}
        submitActions={submitActions}
      />
    </Elements>
  );
};

const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
  width: 438px;
`;

const CardInputBorder = styled(CardInputWrapper)<{ width: string }>`
  margin: 0;
  width: ${({ width }) => width};
`;

export default OneTimePaymentStripe;
