import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import Api from "utils/api";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

type RequestPayloadType = {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
};

const updateCurrentUserInfo = (
  apiVersion: string,
  token: Token,
  accountId: string,
  newUserInfo: RequestPayloadType
) => {
  Object.keys(newUserInfo).forEach(
    (key) =>
      newUserInfo[key as keyof RequestPayloadType] === undefined && delete newUserInfo[key as keyof RequestPayloadType]
  );

  const api = new Api(apiVersion, token);
  return api.updateCurrentUserInfo(accountId, newUserInfo);
};

const useUpdateCurrentUserInfo = (accountId: string) => {
  const token = useSelector((state: ReduxState) => state.token.token);
  const { apiVersion } = useFeatureFlags();
  const mutationFn = (newUserInfo: RequestPayloadType) => {
    return updateCurrentUserInfo(apiVersion, token, accountId, newUserInfo);
  };

  return useMutation({ mutationFn });
};

export default useUpdateCurrentUserInfo;
