import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import Api from "utils/api";
import { resetApplication } from "./_applicationReducer";

interface ApplicationAndAPIVersion {
  application: Application;
  apiVersion: string;
}

const fetchAvailableEndorsements = createAsyncThunk(
  "fetchAvailableEndorsements",
  async ({ application, apiVersion }: ApplicationAndAPIVersion, thunkAPI: any) => {
    const { auth } = thunkAPI.getState();
    const api = new Api(apiVersion, auth);
    const response = await api.availableEndorsements(application);
    return response;
  }
);

const initialState = {
  results: {},
  loading: false,
};

const availableEndorsementsSlice = createSlice({
  name: "availableEndorsements",
  initialState,
  reducers: {
    resetAvailableEndorsements: (state) => {
      state.results = {};
    },
    updateAvailableEndorsements: (state, { payload }) => {
      state.results = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableEndorsements.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAvailableEndorsements.fulfilled, (state, { payload }) => {
      state.results = payload.availableEndorsements;
      state.loading = false;
    });
    builder.addCase(resetApplication, (state) => {
      state.results = {};
      state.loading = false;
    });
  },
});

export const selectAvailableEndorsements = (state: ReduxState) => state.availableEndorsements?.results;
export const selectLoadingAvailableEndorsements = (state: ReduxState) => state.availableEndorsements.loading;
export const selectShowEndorsementPackages = (state: ReduxState) =>
  selectAvailableEndorsements(state)?.endorsementPackage?.length > 0;

export const selectHasNoAvailableEndorsements = (state: ReduxState) => isEmpty(selectAvailableEndorsements(state));
export const selectHasAddOns = (state: ReduxState) => {
  return (
    selectAvailableEndorsements(state) &&
    Object.keys(selectAvailableEndorsements(state))
      .filter((key) => key !== "endorsementPackage")
      .some((key) => key)
  );
};

export const { resetAvailableEndorsements, updateAvailableEndorsements } = availableEndorsementsSlice.actions;

export { fetchAvailableEndorsements };
export default availableEndorsementsSlice.reducer;
