import React from "react";
import styled from "styled-components";
import { theme } from "styles";
import { pages } from "./constants";

interface PropTypes {
  current: number;
  callback: (payload: any) => void;
}

const ProgressIndicator = ({ current, callback }: PropTypes) => {
  return (
    <Container>
      {pages.map((dot, index) => (
        <Dot
          key={dot.label[0]}
          dot={dot.step}
          current={current}
          disabled={index + 1 >= current}
          onClick={() => callback(index + 1)}
          aria-label={`${index + 1 < current ? "completed " : index + 1 === current ? "current " : ""}step ${
            index + 1
          } of 4`}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  column-gap: 8px;
  position: absolute;
  top: 32px;
`;

const Dot = styled.button<{ dot: number; current: number }>`
  height: 16px;
  width: 16px;
  border-radius: 20px;
  border: ${({ dot, current }) => (dot <= current ? `1px solid ${theme.blue}` : "1px solid #A091FF")};
  background: ${({ dot, current }) => (dot <= current ? theme.blue : "white")};
`;

export default ProgressIndicator;
