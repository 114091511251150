import { PaymentContext } from "../../../context/payment";
import { CTAButton, ContentTable, Header, Body } from "coterie-ui-library";
import { useContext } from "react";
import styled from "styled-components";
import { descriptionHandler, statusChipHandler } from "utils/paymentHistoryHelpers";
import { PolicyTabContainer } from "../PolicyTabContainer";

interface PolicyPaymentHistoryProps {
  policy: Policy;
  paymentHistory: any;
}

const columns = [
  {
    Header: "",
    accessor: "status",
    Cell: ({ value }: any) => {
      return statusChipHandler(value);
    },
    width: 140,
  },
  {
    Header: "Charge Date",
    accessor: "chargeDate",
    Cell: ({ value }: any) => {
      return <TableText size="small">{new Date(value).toLocaleDateString()}</TableText>;
    },
    width: 100,
  },
  {
    Header: "Amount",
    accessor: "premiumChargeAmount",
    Cell: ({ value }: any) => {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return <TableText size="small">{formatter.format(value)}</TableText>;
    },
  },
  {
    Header: "Description",
    accessor: "isOneTimePayment",
    Cell: ({ value, row }: any) => {
      return descriptionHandler(row.original.status, value);
    },
    width: 200,
  },
];

const PolicyPaymentHistory = ({ policy, paymentHistory }: PolicyPaymentHistoryProps) => {
  const { actions } = useContext(PaymentContext);
  const { setPolicyToCharge } = actions;

  const overduePayment = paymentHistory?.charges?.filter((charge: any) => charge.status !== "Processed");
  const topOverduePayment = paymentHistory?.isOverdue ? overduePayment.slice(0, 1) : [];

  const handlePayment = () => {
    setPolicyToCharge({
      ...policy,
      overdue: paymentHistory?.overdueAmount,
      transactionFeeAmount: paymentHistory?.transactionFeeAmount,
      transactionFeeEnabled: paymentHistory?.transactionFeeEnabled,
    });
  };

  const overdueColumns = [
    {
      Header: "",
      accessor: "status",
      Cell: ({ value }: any) => {
        return statusChipHandler(value);
      },
      width: 160,
    },
    {
      Header: "",
      accessor: "chargeDate",
      Cell: ({ value }: any) => {
        return (
          <TableText size="xs" data-cy="overdue-date">
            {new Date(value).toLocaleDateString()}
          </TableText>
        );
      },
      width: 100,
    },
    {
      Header: "",
      accessor: "premiumChargeAmount",
      Cell: ({ value }: any) => {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        return (
          <TableText size="small" data-cy="overdue-amount">{`Amount Due: ${formatter.format(
            paymentHistory?.overdueAmount
          )}`}</TableText>
        );
      },
      width: 150,
    },
    {
      Header: "",
      accessor: "isOneTimePayment",
      Cell: ({ value, row }: any) => {
        return descriptionHandler(row.original.status, value);
      },
      width: 200,
    },
    {
      Header: "",
      accessor: "transactionFeeAmount",
      Cell: ({ value, row }: any) => {
        return (
          <CTAButton
            isSmall
            isMaxWidth
            data-testid="payment-button"
            data-cy="overdue-payment-button"
            onClick={handlePayment}
          >
            View Details
          </CTAButton>
        );
      },
      width: 200,
    },
  ];

  return (
    <PolicyTabContainer headerLabel="full-payment-history" heading="Full Payment History">
      {paymentHistory?.isOverdue && (
        <>
          <Header as="h2">Actionable Items</Header>
          <ContentTable
            data={topOverduePayment ?? []}
            columns={overdueColumns}
            label="overduePayment"
            data-testid="overdue-payment-table"
            data-cy="overdue-payment-table"
          />
        </>
      )}
      <PaymentHistoryTable>
        <ContentTable
          data={paymentHistory?.charges ?? []}
          columns={columns}
          label="paymentHistory"
          data-testid="payment-history-table"
          data-cy="payment-history-table"
        />
      </PaymentHistoryTable>
    </PolicyTabContainer>
  );
};

export default PolicyPaymentHistory;

const PaymentHistoryTable = styled.div`
  margin-top: 30px;
`;

const TableText = styled(Body)`
  margin-bottom: 0;
`;
