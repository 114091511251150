import React, { useState } from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassClock } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faBan, faTimer, faXmarkLarge } from "@fortawesome/pro-solid-svg-icons";
import { theme } from "styles";

const {
  pageComponents: {
    ia: {
      policyView: { policyStatus },
    },
  },
} = theme;

export const Container = styled.div<{ bgColor: string; expanded?: boolean }>`
  background-color: ${({ bgColor }) => bgColor};
  display: inline-flex;
  padding: 0px 16px;
  height: 30px;
  border-radius: 16px;
  align-items: center;
  margin: 0px 10px 0px 0px;
  cursor: pointer;

  ${(props) =>
    !props.expanded &&
    css`
      max-width: 50px;
    `}
`;

const StatusText = styled.p`
  margin: 0 0 0 8px;
  padding: 0;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 15px;
  text-transform: uppercase;
  color: ${({ color }) => color};
`;

const StatusIcon = styled(FontAwesomeIcon)`
  color: ${({ color }) => color};
`;

interface PropTypes {
  status: string;
  expanded?: boolean;
}

export const POLICY_STATUSES: any = {
  Active: {
    icon: faCheck,
    iconColor: policyStatus.active.icon,
    bgColor: policyStatus.active.bg,
    textColor: policyStatus.active.text,
    label: "Active",
  },
  Pending: {
    icon: faTimer,
    iconColor: policyStatus.pending.icon,
    bgColor: policyStatus.pending.bg,
    textColor: policyStatus.pending.text,
    label: "Pending",
  },
  Expired: {
    icon: faXmarkLarge,
    iconColor: policyStatus.expired.icon,
    bgColor: policyStatus.expired.bg,
    textColor: policyStatus.expired.text,
    label: "Expired",
  },
  Cancelled: {
    icon: faBan,
    iconColor: policyStatus.cancelled.icon,
    bgColor: policyStatus.cancelled.bg,
    textColor: policyStatus.cancelled.text,
    label: "Cancelled",
  },
  PendingCancellation: {
    icon: faHourglassClock,
    iconColor: policyStatus.pendingCancellation.icon,
    bgColor: policyStatus.pendingCancellation.bg,
    textColor: policyStatus.pendingCancellation.text,
    label: "Pending Cancel",
  },
};

const StatusSymbol = ({ status, expanded = false }: PropTypes) => {
  const [show, toggleshow] = useState(false);
  const currentStatus = POLICY_STATUSES[status];
  if (!currentStatus) return null;
  return (
    <Container
      data-testid="status-symbol"
      onMouseEnter={() => toggleshow(!show)}
      onMouseLeave={() => toggleshow(!show)}
      bgColor={currentStatus.bgColor}
      expanded={show || expanded}
    >
      <StatusIcon
        icon={currentStatus.icon}
        color={currentStatus.iconColor}
        aria-label={`Status is ${currentStatus.label}`}
      />
      {(show || expanded) && <StatusText color={currentStatus.textColor}>{currentStatus.label || status}</StatusText>}
    </Container>
  );
};

export default StatusSymbol;
