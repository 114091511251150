import React, { useState, useContext } from "react";
import styled from "styled-components";
import SmoothCollapse from "react-smooth-collapse";
import { ReactComponent as CaretDown } from "images/caret_down.svg";
import { ReactComponent as CaretUp } from "images/caret_up.svg";
import RuleDSL from "../Shared/RuleDSL";
import { format } from "date-fns";
import { TextLink, tablet } from "styles";
import { Rule } from "./RuleSearch";
import { EditRuleModal } from "../RuleBuilder/Modals";
import { RuleBuilderContext } from "../RuleBuilder/RuleBuilderContext";
import { toastSuccess } from "utils/toast";
import { cloneDeep } from "lodash";

interface RuleCardProps {
  rule: Rule;
  goToRuleBuilder: (ruleToEdit: Rule) => void;
}

const RuleCard = ({ rule, goToRuleBuilder }: RuleCardProps) => {
  const { setRule } = useContext(RuleBuilderContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <RuleContainer isDisabled={rule.disabled} key={rule.id} data-testid="rule-card">
        <TopRow onClick={() => setIsExpanded(!isExpanded)}>
          <RuleName>
            {rule.variable}
            {rule.label ? <RuleLabel> - {rule.label}</RuleLabel> : null}
          </RuleName>
          {isExpanded && <EditRuleButton onClick={() => setModalIsOpen(true)}>Edit Rule</EditRuleButton>}
          <EndContainer>
            {rule.tags && (
              <TagContainer>
                {rule.tags.map((tag) => (
                  <Tag>{tag}</Tag>
                ))}
              </TagContainer>
            )}
            <StatusChip isDisabled={rule.disabled}>
              <StatusChipText>{rule.disabled ? "Inactive" : "Active"}</StatusChipText>
            </StatusChip>
            <CaretButton
              onClick={() => setIsExpanded(!isExpanded)}
              aria-label={`${isExpanded ? "Collapse" : "Expand"} ${rule.label}`}
            >
              <CaretIconWrapper>
                {isExpanded ? <CaretUp height={22} width={22} /> : <CaretDown height={22} width={22} />}
              </CaretIconWrapper>
            </CaretButton>
          </EndContainer>
        </TopRow>
        <StyledSmoothCollapse expanded={isExpanded}>
          <RuleDSL rule={rule} isRuleSearch />
          <DateCreated>
            Date Created: <span>{format(new Date(rule.created), "MM/dd/yyyy")}</span>
          </DateCreated>
        </StyledSmoothCollapse>
      </RuleContainer>
      <EditRuleModal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        handleSubmit={() => {
          goToRuleBuilder(rule);
          setRule(cloneDeep(rule));
          toastSuccess("Success! Rule is ready to be edited.");
        }}
      />
    </>
  );
};

const CaretIconWrapper = styled.div`
  svg {
    fill: ${(props) => props.theme.blue};
  }
`;

const RuleContainer = styled.div<{ isDisabled: boolean }>`
  background: ${(props) => props.theme.white};
  border: ${(props) => `1px solid ${props.isDisabled ? props.theme.regularGray : props.theme.blue}`};
  border-radius: 4px;
  margin: 16px 0;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const StyledSmoothCollapse = styled(SmoothCollapse)`
  margin-top: 20px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RuleName = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  max-width: 70%;
  overflow-wrap: break-word;
  ${tablet} {
    max-width: 50%;
  }
`;

const CaretButton = styled.button`
  background: none;
  margin-right: 10px;
`;

const StatusChip = styled.div<{ isDisabled: boolean }>`
  background: ${(props) => (props.isDisabled ? "#FFDAE3" : "#CFFFEB")};
  text-transform: uppercase;
  border-radius: 16px;
  margin: 0 32px 0 0;
  color: ${(props) => props.theme.gray};
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 80px;
  ${tablet} {
    margin-right: 0;
  }
`;

const StatusChipText = styled.p`
  margin: 0;
  letter-spacing: 1px;
  font-size: 11px;
  font-weight: 600;
`;

const EndContainer = styled.div`
  display: flex;
  align-items: center:
`;

const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.primary.zero};
  background-color: ${(props) => props.theme.colors.primary.ten};
  padding: 2px 5px;
  border-radius: 2px;
  border: 1px solid black;
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
`;

const DateCreated = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.fontColors?.primary};
  font-weight: 600;
  margin-top: 24px;
  span {
    color: ${(props) => props.theme.gray};
    font-weight: 400;
  }
`;

const EditRuleButton = styled(TextLink)`
  margin: 0 auto 0 32px;
`;

const RuleLabel = styled.span`
  font-weight: 400;
`;

export default RuleCard;
