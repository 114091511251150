import styled from "styled-components";
import { GreenButton } from "styles";

export const FlexDiv = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RuleLogicBlock = styled(FlexDiv)<{ isSelected?: boolean }>`
  padding: 1.5em;
  margin: 10px 0;
  border: ${(props) => (props.isSelected ? `1px solid ${props.theme.blue}` : "none")};
  cursor: pointer;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: ${(props) => (props.isSelected ? "0 2px 10px 0 rgba(0,0,0,0.1)" : "none")};
  flex-direction: column;
  position: relative;
`;

export const AttributeBlock = styled(RuleLogicBlock)`
  background: ${(props) => props.theme.blue10};
  padding: 0.5em;
`;

export const SubRules = styled.div`
  margin-left: 2em;
  padding: 1em;
`;

export const ButtonContainer = styled(FlexDiv)`
  margin-top: 1em;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 10px;
  height: fit-content;
`;

export const LogicButton = styled.button<{ disabled?: boolean }>`
  background: ${(props) => (props.disabled ? props.theme.disabledBackground : props.theme.blue10)};
  color: ${(props) => (props.disabled ? props.theme.disabledText : props.theme.blue)};
  border: ${(props) => `1px solid ${props.disabled ? props.theme.borderGray : props.theme.blue}`};
  border-radius: 20px;
  height: 35px;
  width: 120px;
  margin-bottom: 16px;
  font-weight: 600;
`;

export const SubmitButton = styled(GreenButton)`
  height: 35px;
  width: 120px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textNavyBlue};
`;

export const ResetButton = styled(LogicButton)`
  background: transparent;
  border-color: ${(props) => props.theme.regularGray};
  color: ${(props) => props.theme.textNavyBlue};
`;

export const LogicBlockHeader = styled.p`
  margin: 0;
  margin-right: auto;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-self: center;
`;

export const LogicButtonHelperText = styled.p`
  max-width: 70%;
  margin-top: 2em;
  text-align: center;
`;

export const DeleteLogicBlockButtonContainer = styled.span`
  margin-left: 5px;
  position: absolute;
  right: 10px;
  top: 14px;
`;
