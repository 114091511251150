import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "coterie-ui-library";
import useGetCommissions from "hooks/useGetCommissions";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { selectUserAgency } from "redux/selectors/user";
import styled from "styled-components";
import { Breadcrumb, Dropdown } from "styles";
import { ContentContainer, Title, TitleContainer } from "styles/containerStyledComponents";
import CommissionsTable from "./CommissionsTable";
import AjaxLoader from "shared/AjaxLoader";
import { format, sub } from "date-fns";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type AccountingPeriodOption = {
  label: string;
  value: string;
};

type AccountingPeriodParam = {
  month: number;
  year: number;
};

const csvHeaders = [
  { label: "Policy Number", key: "policyNumber" },
  { label: "Insurance Carrier", key: "insuranceCarrier" },
  { label: "Policy State", key: "policyState" },
  { label: "Product", key: "policyProduct" },
  { label: "Insured Business Name", key: "insuredBusinessName" },
  { label: "Producer Agency", key: "producerAgency" },
  { label: "Producer Last Name", key: "producerLastName" },
  { label: "Producer First Name", key: "producerFirstName" },
  { label: "Policy Effective Date", key: "policyEffectiveDate" },
  { label: "Policy Expiration Date", key: "policyExpirationDate" },
  { label: "Accounting Period", key: "accountingPeriod" },
  { label: "Written Premiums", key: "writtenPremiums" },
  { label: "Written Commissions", key: "writtenCommissions" },
];

const CommissionStatements = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [orderby, setOrderBy] = useState<SortValue[]>([{ id: "ProducerAgency", desc: false }]);
  const [selectedAccountingPeriodOption, setSelectedAccountingPeriodOption] = useState<string>();
  const [accountingPeriod, setAccountingPeriod] = useState<AccountingPeriodParam>();
  const agency = useSelector((state: ReduxState) => selectUserAgency(state));
  const { data, isLoading, isFetching, isError, isSuccess, error } = useGetCommissions(
    agency.channelPartnerId as number,
    {
      month: accountingPeriod?.month,
      year: accountingPeriod?.year,
      pagesize: 10,
      pageNumber,
      orderby: orderby[0]?.id,
      orderbyAscending: !orderby[0]?.desc,
    },
    { enabled: Boolean(agency.channelPartnerId) }
  );
  // Need to fetch ALL commissions for the CSV download
  const { data: csv, isLoading: isLoadingCSV } = useGetCommissions(
    agency.channelPartnerId as number,
    {
      pagesize: data?.totalCount,
      orderby: orderby[0]?.id,
      orderbyAscending: !orderby[0]?.desc,
      month: accountingPeriod?.month,
      year: accountingPeriod?.year,
    },
    { enabled: Boolean(data) }
  );

  const handleAccountingPeriodChange = (option: AccountingPeriodOption) => {
    const [year, month] = option.value.split("-");
    setAccountingPeriod({ month: Number(month), year: Number(year) });
    setSelectedAccountingPeriodOption(option.value);
    setPageNumber(1);
  };

  // Create accounting period options for the dropdown. Returns a descending list of the previous n months.
  // Example return value: [{label: "January 2021", value: "2021-01"}, {label: "December 2020", value: "2020-12"}]
  const createAccoutingPeriodOptions = (previousMonths: number): AccountingPeriodOption[] => {
    const period = data?.latestAccountingPeriod;
    return period
      ? Array.from(Array(previousMonths))
          .reverse()
          .map((_, index) => {
            const label = format(sub(new Date(period.year, period.month, 15), { months: index + 1 }), "MMMM yyyy");
            const value = format(sub(new Date(period.year, period.month, 15), { months: index + 1 }), "yyyy-MM");
            return { label, value };
          })
      : [];
  };

  const options = createAccoutingPeriodOptions(12);

  return (
    <ContentContainer>
      <StyledBreadcrumb>Commission Statements</StyledBreadcrumb>
      <CommissionTitleContainer>
        <Title>Commissions</Title>
      </CommissionTitleContainer>
      <CommissionsFilter>
        <Actions>
          {isLoadingCSV ? (
            <AjaxLoader />
          ) : (
            <>
              {csv && (
                <CSVLink
                  data={
                    csv?.items.map((commission) => ({
                      ...commission,
                      policyEffectiveDate: format(new Date(commission.policyEffectiveDate), "MMMM dd, yyyy"),
                      policyExpirationDate: format(new Date(commission.policyExpirationDate), "MMMM dd, yyyy"),
                      accountingPeriod: format(
                        new Date(commission.accountingPeriod.year, commission.accountingPeriod.month - 1),
                        "MMMM yyyy"
                      ),
                    })) || []
                  }
                  headers={csvHeaders}
                  filename={`commission-statements.csv`}
                >
                  <FontAwesomeIcon icon={faDownload as IconProp} style={{ marginRight: "0.3em" }} /> Download CSV
                </CSVLink>
              )}
            </>
          )}
        </Actions>
        {data?.items && (
          <DropdownWrapper>
            <DropdownLabel htmlFor="accountingPeriodFilter-dropdown" data-cy="accountingPeriod-dropdown-filter">
              Filter by Accounting Period
            </DropdownLabel>
            <Dropdown
              onChange={(value) => handleAccountingPeriodChange(value as AccountingPeriodOption)}
              options={options}
              value={selectedAccountingPeriodOption || options[0].value}
              name="accountingPeriodFilter"
              labelIsHidden
              isClearable={false}
            />
          </DropdownWrapper>
        )}
      </CommissionsFilter>

      {data?.items && (
        <CommissionsTable commissions={data} setPageNumber={setPageNumber} setOrderBy={setOrderBy} orderby={orderby} />
      )}
      {isLoading && isFetching && <p>Loading commission statements...</p>}
      {isSuccess && data && data.items.length === 0 && (
        <p>No commission statements were found for this accounting period.</p>
      )}
      {isError && (
        <Alert
          message={error?.message || "There was an error while making this request."}
          color="red"
          title="Server Error"
        />
      )}
    </ContentContainer>
  );
};

const CommissionTitleContainer = styled(TitleContainer)`
  margin-bottom: 16px;
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 20px;
`;

export const DropdownWrapper = styled.div`
  align-items: flex-end;
  flex-direction: column;
  width: 255px;
  margin-left: auto;
  z-index: 2;
  position: relative;
`;

export const DropdownLabel = styled.label`
  color: ${(props) => props.theme.textNavyBlue};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.75px;
  line-height: 16px;
`;

const Actions = styled.div`
  text-align: right;
  a,
  .btn-link,
  svg {
    margin-left: 0.5em;
  }
`;

const CommissionsFilter = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
`;

export default CommissionStatements;
