import React from "react";
import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import { theme } from "styles";
import ShieldLossControl from "images/shield_loss_control.png";
import CheckmarkIcon from "images/checkmark-secondary-green.png";
import WarningIcon from "images/warning-orange.png";
import { AlternateQuoteType } from "utils/proposal-pdf/constants";
import { formatCurrency } from "utils/formatNumber";

interface LossControlPropTypes {
  application: Application;
  alternateQuote: AlternateQuoteType;
}

const LossControl = ({ application, alternateQuote }: LossControlPropTypes) => {
  const { isOptInLowerCost, optInCostDifferenceYearly } = alternateQuote;

  const hasLossControl = application.lossControl === "OptIn";

  const badgeStyles = hasLossControl
    ? styles.badge
    : [styles.badge, { backgroundColor: theme.white, borderColor: theme.regularGray, color: theme.gray }];

  const getLossControlSavingsMessage = () => {
    if (isOptInLowerCost && optInCostDifferenceYearly) {
      return `${hasLossControl ? "You are saving" : "You could save"} ${formatCurrency(
        optInCostDifferenceYearly
      )}/year with Manage My Risk discount`;
    } else if (hasLossControl) {
      return "Maximizing business protection with Manage My Risk";
    } else {
      return "Increase protection with Manage My Risk";
    }
  };

  const discountMessageStyle = [
    styles.discountMessage,
    hasLossControl
      ? { backgroundColor: "transparent", color: "#0F8054" }
      : { backgroundColor: theme.orange10, color: theme.darkOrange },
  ];

  return (
    <View>
      <View style={styles.header}>
        <Image style={styles.image} src={ShieldLossControl} />
        <Text style={styles.title}>Manage My Risk Program</Text>
        <Text style={badgeStyles}>{hasLossControl ? "You're enrolled!" : "Not enrolled"}</Text>
      </View>
      <View style={styles.container}>
        <View style={styles.discountContainer}>
          <View style={discountMessageStyle}>
            <Image style={styles.discountIcon} src={hasLossControl ? CheckmarkIcon : WarningIcon}></Image>
            <Text>{getLossControlSavingsMessage()}</Text>
          </View>
        </View>
        <Text style={styles.body}>
          Sign up for our self-guided and self-directed loss-control services. While there are no program requirements
          at this time, we recommend using the program to secure your business.
        </Text>
        <View style={styles.list}>
          <View style={[styles.body, styles.li, styles.marginBottom]}>
            <View style={styles.bullet}>
              <Text>•</Text>
            </View>
            <Text>
              Safety Equipment Marketplace: Access to industry-specific safety equipment to secure your business and
              prevent losses.
            </Text>
          </View>
          <View style={[styles.body, styles.li, styles.marginBottom]}>
            <View style={styles.bullet}>
              <Text>•</Text>
            </View>
            <Text>
              Industry-Specific Training Resources: Specific training for your industry to understand your risk and
              secure your business.
            </Text>
          </View>
          <View style={[styles.body, styles.li]}>
            <View style={styles.bullet}>
              <Text>•</Text>
            </View>
            <Text>
              Curated Content: Targeted content around loss prevention and risk mitigation so you can learn how to
              protect your business.
            </Text>
          </View>
        </View>
        <Text style={styles.body}>
          {hasLossControl
            ? "Once enrolled, we’ll email you login information about how to access our program within 24-48 hours of purchasing your policy."
            : "Ask your insurance agent or broker about enrollment."}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    padding: "8px",
    backgroundColor: theme.colors.neutral.special,
    height: "42px",
    width: "100%",
    position: "relative",
  },
  image: {
    width: "24px",
    height: "24px",
    position: "absolute",
    left: "16px",
    top: "9px",
  },
  title: {
    color: theme.colors.tertiary.zero,
    fontFamily: theme.font.typeface.primary,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "normal",
    position: "absolute",
    left: "56px",
    top: "12px",
  },
  badge: {
    fontFamily: theme.font.typeface.primary,
    fontSize: "10px",
    padding: "5px 6px 3px 8px",
    textTransform: "uppercase",
    backgroundColor: theme.green10,
    color: theme.colors.secondary.dark,
    border: `1px solid ${theme.colors.secondary.dark}`,
    borderRadius: "4px",
    left: "415px",
    top: "11px",
    position: "absolute",
    letterSpacing: "1px",
  },
  container: {
    margin: "16px 8px",
  },
  list: {
    margin: "16px",
  },
  li: {
    display: "flex",
    flexDirection: "row",
  },
  marginBottom: {
    marginBottom: "8px",
  },
  bullet: {
    marginRight: "8px",
  },
  body: {
    fontSize: "11px",
    fontFamily: theme.font.typeface.secondary,
    fontWeight: 400,
    lineHeight: "1.5",
  },
  discountContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  discountMessage: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 4px",
    height: "24px",
    marginBottom: "12px",
    fontFamily: theme.font.typeface.secondary,
    fontSize: "11px",
    fontWeight: 400,
    borderRadius: "4px",
    gap: "4px",
  },
  discountIcon: {
    width: "16px",
    height: "16px",
  },
});

export default LossControl;
