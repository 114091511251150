import React from "react";
import styled, { css } from "styled-components";
import { mobile } from "styles";

interface PropTypes {
  display?: "inline" | "block";
  handleClick?: () => void | undefined;
  className?: string;
  children: any;
}

interface InputProps {
  width: string;
  hasError?: string;
  theme: ThemeInterface;
}

export const textInputStyles = css`
  appearance: none;
  border-radius: 20px;
  background-color: ${(props) => props.theme.textInputBackground};
  color: ${(props) => props.theme.textInput};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  border: 1px solid transparent;
  margin-top: 8px;
  padding: 14px 20px;
  transition: all 0.1s;
  box-sizing: border-box;

  width: ${(props: InputProps) => {
    switch (props.width) {
      case "full":
        return "100%";
      case "half":
        return "50%";
      case "quarter":
        return "25%";
      default:
        return "100%";
    }
  }};
  ${(props: InputProps) =>
    props.hasError
      ? `
    border: 1px solid ${props.theme.errorText};
  `
      : ""}
  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.textInputBorderFocus};
  }

  &::placeholder {
    color: ${(props) => props.theme.textInputPlaceholder};
  }

  ${mobile} {
    width: ${(props) => {
      switch (props.width) {
        case "full":
          return "100%";
        case "half":
          return "50%";
        case "quarter":
          return "25%";
        default:
          return "100%";
      }
    }};
  }
`;

export const TextInput = styled.input`
  ${textInputStyles}
`;

interface TextProps {
  display?: "inline" | "block";
  theme: ThemeInterface;
  center?: string;
  white?: string;
}

const Text = styled.p`
  font-size: 20px;
  font-family: ${(props) => props.theme.primaryFont};
  color: ${(props: TextProps) => (props.white ? "white" : props.theme.charcoalGrey)};
  margin: ${(props: TextProps) => (props.display === "inline" ? "10px 5px 5px 0" : "10px 0px 5px 0")};
  font-weight: bold;
  line-height: 1.3;
  display: ${(props: TextProps) => props.display || "block"};
  text-align: ${(props: TextProps) => (props.center ? "center" : "left")};
`;

export const LabelLarge = (props: PropTypes) => (
  <Text display={props.display} {...props}>
    {props.children}
  </Text>
);

const SmallText = styled.p`
  font-size: 14px;
  font-family: ${(props) => props.theme.secondaryFont};
  color: ${(props) => props.theme.midGray};
  font-weight: 300;
  margin: 0;
  display: ${(props: TextProps) => props.display || "block"};
`;

export const LabelSmall = (props: PropTypes) => (
  <SmallText {...props} className={props.className} onClick={props.handleClick || undefined}>
    {props.children}
  </SmallText>
);
