import { createSlice } from "@reduxjs/toolkit";
import { PaymentFrequency } from "types/enums";
import omit from "lodash/omit";

const initialState: underwritingProfileState = {
  validationErrors: {},
  availableCoverages: [],
  industryName: "",
  naic: 0,
  underwritingProfileId: "",
  businessProfileId: "",
  deniedCoverages: [],
  underwritingState: "",
  isDisqualified: false,
  details: {},
  legacyApplication: {},
  legacyQuotes: [],
  appliedActions: [],
  recommendedEndorsements: {},
  conditionallyMandatoryForms: [],
  riskMultiplier: 0,
  loadingUnderwritingProfile: false,
  paymentFrequency: PaymentFrequency.None,
  endorsementDetails: {},
  declinationLedger: { data: {} },
  exclusions: [],
};

const underwritingProfileSlice = createSlice({
  name: "underwritingProfile",
  initialState,
  reducers: {
    updateUnderwritingProfile: (state, { payload }) => {
      state.validationErrors = payload?.validationErrors ?? {};
      state.availableCoverages = payload?.availableCoverages ?? [];
      state.industryName = payload?.industryName ?? "";
      state.naic = payload?.naic ?? 0;
      state.underwritingProfileId = payload?.underwritingProfileId ?? "";
      state.businessProfileId = payload?.businessProfileId ?? "";
      state.deniedCoverages = payload?.deniedCoverages ?? [];
      state.declinationLedger = payload?.declinationLedger ?? { data: {} };
      state.underwritingState = payload?.underwritingState ?? "";
      state.isDisqualified = payload?.isDisqualified ?? false;
      state.details = payload?.details ?? {};
      state.legacyApplication = payload?.legacyApplication ?? {};
      state.legacyQuotes = payload?.legacyQuotes ?? [];
      state.appliedActions = payload?.appliedActions ?? [];
      state.recommendedEndorsements = payload?.recommendedEndorsements ?? {};
      state.conditionallyMandatoryForms = payload?.conditionallyMandatoryForms ?? [];
      state.riskMultiplier = payload?.riskMultiplier ?? 0;
      state.industrySuggestions = payload?.industrySuggestions ?? [];
      state.exclusions = payload?.exclusions ?? [];
    },
    resetUnderwritingProfile: (state) => {
      state.validationErrors = {};
      state.availableCoverages = [];
      state.industryName = "";
      state.naic = 0;
      state.underwritingProfileId = "";
      state.businessProfileId = "";
      state.deniedCoverages = [];
      state.underwritingState = "";
      state.isDisqualified = false;
      state.details = {};
      state.legacyApplication = {};
      state.legacyQuotes = [];
      state.appliedActions = [];
      state.recommendedEndorsements = {};
      state.conditionallyMandatoryForms = [];
      state.riskMultiplier = 0;
      state.loadingUnderwritingProfile = false;
      state.paymentFrequency = PaymentFrequency.None;
      state.endorsementDetails = {};
      state.industrySuggestions = [];
      state.declinationLedger = { data: {} };
      state.exclusions = [];
    },
    updateUnderwritingProfileId: (state, { payload }) => {
      state.underwritingProfileId = payload;
    },
    updateUnderwritingProfileDetailsData: (state, { payload }) => {
      state.details.data = payload;
    },
    updateUnderwritingEndorsementDetails: (state, { payload }) => {
      state.endorsementDetails = payload;
    },
    updateEndorsementQuestionState: (state, { payload }) => {
      state.endorsementDetails = {
        ...state.endorsementDetails,
        [payload.key]: { questionHasBeenAnswered: payload.questionHasBeenAnswered },
      };
    },
    deleteEndorsementDetail: (state, { payload }) => {
      state.endorsementDetails = omit(state.endorsementDetails, payload);
    },
    setLoadingUnderwritingProfile: (state, { payload }) => {
      state.loadingUnderwritingProfile = payload;
    },
    setSimplybindPaymentFrequency: (state, { payload }) => {
      state.paymentFrequency = payload;
    },
  },
});

export const {
  updateUnderwritingProfile,
  resetUnderwritingProfile,
  updateUnderwritingProfileId,
  updateUnderwritingProfileDetailsData,
  setLoadingUnderwritingProfile,
  setSimplybindPaymentFrequency,
  updateEndorsementQuestionState,
  updateUnderwritingEndorsementDetails,
  deleteEndorsementDetail,
} = underwritingProfileSlice.actions;

export default underwritingProfileSlice.reducer;
