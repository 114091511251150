import React, { FunctionComponent } from "react";
import styled from "styled-components";
import ReactModal from "react-modal";
import { Spinner } from "reactstrap";
import { ModalFormContainer, ActionContainer, TextButton } from "shared/ModalComponents";
import { GreenButton, theme } from "styles";
import Close from "images/close.svg";

if (process.env.NODE_ENV !== "test") ReactModal.setAppElement("#root");

interface PropTypes {
  isOpen: boolean;
  closeModal: () => void;
  label: string;
  title: string;
  loading?: boolean;
  onSubmit?: (arg0: any) => void;
  secondaryButtonAction?: (arg0: any) => void;
  buttonText?: string;
  secondaryButtonText?: string;
  disabled?: boolean;
  renderCloseButton?: boolean;
  children?: React.ReactNode;
}

const Modal: FunctionComponent<PropTypes> = ({
  isOpen,
  closeModal,
  label,
  title,
  onSubmit,
  secondaryButtonAction,
  loading,
  children,
  buttonText,
  secondaryButtonText,
  disabled = false,
  renderCloseButton = true,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      style={ModalStyles}
      onRequestClose={closeModal}
      contentLabel={label}
      shouldCloseOnOverlayClick={true}
    >
      <ModalFormContainer
        data-cy={`${label}-modal`}
        data-testid={`${label}-modal`}
        disabled={Boolean(loading)}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit && onSubmit(e);
        }}
      >
        <ModalTopBar>
          <ModalTitle data-cy={`${label}-header`}>{title}</ModalTitle>
          {renderCloseButton && (
            <CloseModal data-cy="close-modal" data-testid="close-modal" onClick={closeModal}>
              <img src={Close} alt="Close Modal" />
            </CloseModal>
          )}
        </ModalTopBar>
        {children}
        {onSubmit && (
          <ActionContainer>
            {secondaryButtonAction && (
              <TextButton onClick={secondaryButtonAction} data-cy="modal-secondary-button">
                {secondaryButtonText}
              </TextButton>
            )}
            <GreenButton data-cy="submit" type="submit" disabled={loading || disabled} data-testid="modal-cta-button">
              {loading ? <Spinner color="light" /> : <span>{buttonText || "Save"}</span>}
            </GreenButton>
          </ActionContainer>
        )}
      </ModalFormContainer>
    </ReactModal>
  );
};

const ModalTopBar = styled.div`
  background: ${(props) => props.theme.modalTitleBg};
  padding: 20px;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
`;

const CloseModal = styled.button`
  display: inline-block;
  float: right;
  background: ${(props) => props.theme.modalTitleBg};
  border: ${(props) => props.theme.modalTitleBg};
  margin-top: -7px;
  margin-right: -7px;
`;

const ModalTitle = styled.h2`
  color: ${(props) => props.theme.white};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 18px;
  display: inline-block;
  margin: 0px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
`;

const ModalStyles: ReactModal.Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    position: "fixed",
    border: "none",
    borderRadius: "4px",
  },
  overlay: {
    zIndex: 10,
    backgroundColor: theme.modalBg,
  },
};

export default Modal;
