export const selectUnderwritingModalIsOpen = (state: ReduxState) => state.global?.underwritingModalIsOpen;
export const selectIsValidPolicyStartDate = (state: ReduxState) => state.global?.isValidPolicyStartDate;

export const selectAlternateQuote = (state: ReduxState) => state.global?.alternateQuote;
export const selectLossControlOptInMonth1Owed = (state: ReduxState) =>
  state.global?.alternateQuote?.lossControlOptIn?.month1Owed;
export const selectLossControlOptInTotalYearlyOwed = (state: ReduxState) =>
  state.global?.alternateQuote?.lossControlOptIn?.totalYearlyOwed;
export const selectLossControlOptInPremium = (state: ReduxState) =>
  state.global?.alternateQuote?.lossControlOptIn?.premium;
export const selectLossControlOptInMonthlyPremium = (state: ReduxState) =>
  state.global?.alternateQuote?.lossControlOptIn?.monthlyPremium;
export const selectLossControlOptOutMonth1Owed = (state: ReduxState) =>
  state.global?.alternateQuote?.lossControlOptOut?.month1Owed;
export const selectLossControlOptOutTotalYearlyOwed = (state: ReduxState) =>
  state.global?.alternateQuote?.lossControlOptOut?.totalYearlyOwed;
export const selectLossControlOptOutPremium = (state: ReduxState) =>
  state.global?.alternateQuote?.lossControlOptOut?.premium;
export const selectLossControlOptOutMonthlyPremium = (state: ReduxState) =>
  state.global?.alternateQuote?.lossControlOptOut?.monthlyPremium;
export const selectIsOptInLowerCost = (state: ReduxState) =>
  state.global?.alternateQuote?.lossControlOptIn?.month1Owed <
  state.global?.alternateQuote?.lossControlOptOut?.month1Owed;
export const selectOptInCostDifferenceMonthly = (state: ReduxState) =>
  isNaN(Math.abs(selectLossControlOptOutMonth1Owed(state) - selectLossControlOptInMonth1Owed(state)))
    ? undefined
    : Math.abs(selectLossControlOptOutMonth1Owed(state) - selectLossControlOptInMonth1Owed(state));
export const selectOptInCostDifferenceYearly = (state: ReduxState) =>
  isNaN(Math.abs(selectLossControlOptOutTotalYearlyOwed(state) - selectLossControlOptInTotalYearlyOwed(state)))
    ? undefined
    : Math.abs(selectLossControlOptOutTotalYearlyOwed(state) - selectLossControlOptInTotalYearlyOwed(state));
