import styled, { css } from "styled-components";
import { Body, Icon, StatusChip } from "coterie-ui-library";
import { theme } from "styles";

export const descriptionHandler = (status: string, isOTP: boolean) => {
  if (status === "Processed") {
    return (
      <Description>
        <DescriptionText size="small" isSuccessful={true}>
          Successful {isOTP ? "one-time charge" : " recurring charge"}
        </DescriptionText>
      </Description>
    );
  } else if (status === "Refunded") {
    return (
      <Description>
        <DescriptionText size="small" isSuccessful={true}>
          Successful refund
        </DescriptionText>
      </Description>
    );
  } else {
    return (
      <Description>
        <DescriptionText size="small" isSuccessful={false}>
          Failed {isOTP ? "one-time charge" : " recurring charge"}
        </DescriptionText>
      </Description>
    );
  }
};

export const statusChipHandler = (chargeStatus: string) => {
  if (chargeStatus === "Processed") {
    return (
      <IconWrapperStatusChip>
        <StatusChip text="SUCCESSFUL" icon="checkmark" />
      </IconWrapperStatusChip>
    );
  } else if (chargeStatus === "Refunded") {
    return (
      <IconWrapperStatusChip>
        <StatusChip text="REFUNDED" icon="checkmark" />
      </IconWrapperStatusChip>
    );
  } else {
    return (
      <IconWrapperStatusChip>
        <StatusChip
          text="FAILED"
          color={theme.pageComponents.ia.paymentHistory.overdueText}
          backgroundColor={theme.pageComponents.ia.paymentHistory.overdue}
          icon="alert"
        />
      </IconWrapperStatusChip>
    );
  }
};

// TODO: Remove this wrapper when we have a better way to handle the icon
const IconWrapperStatusChip = styled.div`
  svg {
    transform: translateY(-2px);
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
`;

const DescriptionText = styled(Body)<{ isSuccessful: boolean }>`
  margin-bottom: 0;
  margin-right: 8px;
  color: ${(props) =>
    props.isSuccessful
      ? props.theme.pageComponents.ia.paymentHistory.paid
      : props.theme.pageComponents.ia.paymentHistory.overdueText};
`;
