import { useReducer, createContext, Dispatch, useMemo } from "react";

const initialState = {
  isDrawerOpen: false,
  policyToCharge: null,
};

enum ACTION_TYPE {
  SET_POLICY_TO_CHARGE = "SET_POLICY_TO_CHARGE",
  CLOSE_DRAWER = "CLOSE_DRAWER",
}

interface Action {
  type: ACTION_TYPE;
  payload?: any;
}

const reducer = (state: typeof initialState, action: Action) => {
  switch (action.type) {
    case ACTION_TYPE.SET_POLICY_TO_CHARGE:
      return {
        ...state,
        isDrawerOpen: true,
        policyToCharge: action.payload.policyToCharge,
      };
    case ACTION_TYPE.CLOSE_DRAWER:
      return {
        ...state,
        isDrawerOpen: false,
        policyToCharge: null,
      };
    default:
      return state;
  }
};

const getPaymentActions = (dispatch: Dispatch<any>) => ({
  setPolicyToCharge: (policyToCharge: any) =>
    dispatch({
      type: ACTION_TYPE.SET_POLICY_TO_CHARGE,
      payload: { policyToCharge },
    }),
  closeDrawer: () =>
    dispatch({
      type: ACTION_TYPE.CLOSE_DRAWER,
    }),
});

export const PaymentContext = createContext<any>(null);

interface PaymentProviderProps {
  children: React.ReactNode;
}

export const PaymentProvider = ({ children }: PaymentProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useMemo(() => getPaymentActions(dispatch), [dispatch]);

  return <PaymentContext.Provider value={{ actions, state }}>{children}</PaymentContext.Provider>;
};
