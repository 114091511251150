import styled from "styled-components";
import { Body as UIBody } from "coterie-ui-library";
import { Checkbox } from "styles";

interface Option {
  value: boolean;
  onChange: (value: boolean) => void;
  label: string;
}

interface PropTypes {
  options: Option[];
}

const BopOptions = ({ options }: PropTypes) => {
  return (
    <Options>
      <Body size="small">Select at least one of the following:</Body>
      {options.map((option) => (
        <Selection key={option.label}>
          <Checkbox
            checked={option.value}
            name={option.label}
            handleClick={() => option.onChange(!option.value)}
            ariaLabel={option.label}
          />
          <Body size="regular" onClick={() => option.onChange(!option.value)}>
            {option.label}
          </Body>
        </Selection>
      ))}
    </Options>
  );
};

const Body = styled(UIBody)`
  cursor: pointer;
  margin: 0;
`;

const Options = styled.div`
  background-color: ${(props) => props.theme.yellow10};
  padding: 16px 48px;
`;

const Selection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;

  input {
    margin: 0 8px 0 0;
  }
`;

export default BopOptions;
