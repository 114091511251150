import React from "react";
import styled from "styled-components";
import StatusSymbol from "shared/StatusSymbol";
import { Link } from "react-router-dom";
import bop_icon from "images/bop.svg";
import gl_icon from "images/gl.svg";
import pl_icon from "images/pl.svg";
import { ReactComponent as ArrowRight } from "images/arrow-right-blue.svg";
import { formatBackendPolicyDate } from "utils/formatBackendPolicyDate";

interface PolicyAsset {
  [key: string]: { image: string; title: string };
}

const policyAssets: PolicyAsset = {
  BOP: { image: bop_icon, title: "Business Owner’s Policy" },
  PL: { image: pl_icon, title: "Professional Liability Policy" },
  GL: { image: gl_icon, title: "General Liability Policy" },
};

type PropTypes = {
  policies: Policy[];
};

const BoundPolicies = ({ policies }: PropTypes) => {
  const policyNumbers = policies.map((p) => p.policyNumber);
  return (
    <Tr>
      <Td colSpan={6}>
        <Table>
          <THead>
            <tr>
              <th>Policy Type</th>
              <th>Status</th>
              <th>Policy Number</th>
              <th>Expiration</th>
              <th></th>
            </tr>
          </THead>
          <tbody>
            {policies.map((policy) => {
              const currentPolicyNumbers = [
                policy.policyNumber,
                ...policyNumbers.filter((p) => p !== policy.policyNumber),
              ];
              return (
                <Tr key={policy.policyNumber}>
                  <Policy>
                    <img src={policyAssets[policy.policyType].image} alt={policyAssets[policy.policyType].title} />
                    {policyAssets[policy.policyType].title}
                  </Policy>
                  <Td>
                    <StatusSymbol status={policy.status} expanded />
                  </Td>
                  <Td>
                    <Link
                      to={`/policy?policyIds=${currentPolicyNumbers}`}
                      data-testid="policy-row-policy-link"
                      data-cy="policy-row-policy-link"
                    >
                      {policy.policyNumber}
                    </Link>
                  </Td>
                  <Td>{formatBackendPolicyDate(new Date(policy.endDate)).toLocaleDateString()}</Td>
                  <Td>
                    <Link
                      to={`/policy?policyIds=${currentPolicyNumbers}`}
                      data-cy="policy-row-policy-link"
                      data-testid="policy-row-policy-link-icon"
                    >
                      <IconContainer>
                        <ArrowRight />
                      </IconContainer>
                    </Link>
                  </Td>
                </Tr>
              );
            })}
          </tbody>
        </Table>
      </Td>
    </Tr>
  );
};

const Table = styled.table`
  display: inline-table;
  width: 100%;
`;

const THead = styled.thead`
  > tr > th {
    color: ${(props) => props.theme.midGray}!important;
    font-size: 11px !important;
    font-weight: 600 !important;
    letter-spacing: 1px !important;
    padding: 0 !important;
  }
`;

const Policy = styled.td`
  img {
    margin-right: 8px;
  }
`;

const Td = styled.td`
  background-color: transparent !important;
  padding: 5px 0;

  td:nth-child(1) {
    width: 400px;
  }

  td:nth-child(2) {
    width: 50px;

    > div {
      min-width: 110px;
    }
  }

  td:nth-child(4) {
    text-align: left;
  }
  td:nth-child(5) {
    width: 25px;
    text-align: left;
  }
`;

const Tr = styled.tr`
  background-color: transparent !important;
`;

const IconContainer = styled.div`
  svg path {
    fill: ${(props) => props.theme.blue};
  }
`;

export default BoundPolicies;
