import { POLICY_ARIA_LABELS, POLICY_OPTIONS } from "utils/constants";
import { EndorsementPackages } from "types/enums";
import { formatCurrency } from "utils/formatNumber";
import { carriers } from "utils/paymentUtils";

interface PolicyCoverage {
  label: string;
  value: string;
  description?: string;
}
interface PolicyCoverageDetails {
  title: string;
  coverages: PolicyCoverage[];
}

interface CarrierDictionary {
  [key: string]: {
    rating: string;
    naic: string;
    displayName: string;
    financialSizeCategory?: string;
  };
}

export interface Forms {
  [key: string]: string;
}

export interface BlanketAITypes {
  [key: string]: boolean | undefined;
}

export type AddOnObj = {
  title: string;
  description: string;
  limit?: number;
  sublimit?: string;
};

export type AddOnsType = {
  [key: string]: AddOnObj;
};

export const blanketOptions: { [key: string]: string } = {
  additionalInsuredsPrimaryAndNonContributory: "Blanket Primary and Non-Contributory",
  additionalInsuredsWaiverOfTransfer: "Blanket Waiver of Subrogation",
  additionalInsuredsConstructionContractRequirementEndorsement: "Construction Contract Requirement",
  additionalInsuredsManagersOrLessorsPremisesEndorsement: "Blanket Managers or Lessors of Premises",
  additionalInsuredsLessorOfLeasedEquipmentEndorsement: "Blanket Lessor of Leased Equipment",
};

export const coverageDetailsPL = (application: Application): PolicyCoverageDetails => ({
  title: "Professional Liability Policy Coverage",
  coverages: [
    {
      label: "Professional Liability Limit",
      value: formatCurrency(application.professionalLiability?.occurrenceLimit || 0),
    },
    {
      label: "Deductible",
      value: formatCurrency(application.professionalLiability?.deductibleAmount || 0),
    },
    {
      label: "Years of prior acts",
      value: String(application.professionalLiability?.yearsOfPriorActs || 0),
    },
  ],
});

export const coverageDetailsGL = (application: Application): PolicyCoverageDetails => ({
  title: "Liability Coverage Overview",
  coverages: [
    {
      label: "Property Damage Deductible",
      value: formatCurrency(application.propertyDamageLiabilityDeductible || 0),
    },
    {
      label: "General Liability (Each Occurrence)",
      value: formatCurrency(application.glLimit || 0),
      description: "(Includes Bodily Injury, Property Damage and Personal & Advertising Injury)",
    },
    {
      label: "General Liability (Annual Aggregate)",
      value: formatCurrency(application.glAggregateLimit || 0),
      description: "(Includes Bodily Injury, Property Damage and Personal & Advertising Injury)",
    },
    {
      label: "Products/Completed Operations Annual Aggregate",
      value: formatCurrency(application.glAggregateLimit || 0),
    },
    {
      label: "Damage to Premises Rented to You",
      value: "$50,000",
    },
    {
      label: "Medical Expense Limit",
      value: "$5,000",
    },
  ],
});

export const coverageDetailsBOP = (
  application: Application,
  increaseFlPropertyMin: boolean = false
): PolicyCoverageDetails => {
  const location = application && application.locations && application?.locations[0];
  const locationOwned = location?.locationType === "BuildingOwned" || location?.locationType === "CommercialCondoOwned";

  return {
    title: "Property Coverage Overview",
    coverages: [
      {
        label: "Property Deductible - All Other Perils",
        value: formatCurrency(application.bppDeductible || 0),
        description: "applies per occurrence",
      },
      {
        label: "Property Deductible - Windstorm and Hail",
        value: "5%",
        description: "applies per coverage item (subject to $2,500 minimum deductible) ",
      },
      {
        label: POLICY_ARIA_LABELS[POLICY_OPTIONS.BUSINESS_PERSONAL_PROPERTY_LIMIT],
        value: formatCurrency(location?.bppLimit || 0),
      },
      {
        label: POLICY_ARIA_LABELS[POLICY_OPTIONS.BUILDING_LIMIT],
        value: formatCurrency(location?.buildingLimit),
      },
    ]
      .filter((item) => (locationOwned ? true : item.label !== POLICY_ARIA_LABELS[POLICY_OPTIONS.BUILDING_LIMIT]))
      .filter((item) => {
        if (
          (!increaseFlPropertyMin || location?.state !== "FL") &&
          item.label === "Property Deductible - Windstorm and Hail"
        ) {
          return false;
        }
        return true;
      }),
  };
};

export const extraCoverages = (deductible: number, endorsement?: EndorsementPackages) => {
  const deductibleAmount = formatCurrency(deductible);
  const limit_values = [
    {
      coverage: "Accounts Receivable on/off Premises",
      toolTipContentKey: "ACCOUNTS_RECEIVABLE",
      deductible: deductibleAmount,
      limit: {
        None: "$10,000/$5,000",
        Silver: "Blanket Limit of $150,000",
        Gold: "Blanket Limit of $350,000",
        Platinum: "Blanket Limit of $500,000",
      },
    },
    {
      coverage: "Backup of Sewers and Drains",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "Included",
        Gold: "Included",
        Platinum: "Included",
      },
    },
    {
      coverage: "Brands and Labels",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "Included",
        Gold: "Included",
        Platinum: "Included",
      },
    },
    {
      coverage: "Business Income and Extra Expense - Websites",
      toolTipContentKey: "BUSINESS_INCOME_EXTRA_EXPENSE",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "$50,000",
        Gold: "$50,000",
        Platinum: "$50,000",
      },
    },
    {
      coverage: "Business Income and Extra Expense",
      toolTipContentKey: "BUSINESS_INCOME_EXTRA_EXPENSE",
      deductible: "72 Hours",
      limit: { None: "ALS", Silver: "ALS", Gold: "ALS", Platinum: "ALS" },
    },
    {
      coverage: "Business Income from Dependent Properties",
      toolTipContentKey: "BUSINESS_INCOME_DEPENDENT_PROPERTIES",
      deductible: "N/A",
      limit: {
        None: "$5,000",
        Silver: "$50,000",
        Gold: "$50,000",
        Platinum: "$50,000",
      },
    },
    {
      coverage: "Business Personal Property - Temporary portable storage units",
      toolTipContentKey: "PROPERTY_IN_STORAGE",
      deductible: deductibleAmount,
      limit: { None: "$10,000", Silver: "$10,000", Gold: "$10,000", Platinum: "$10,000" },
    },
    {
      coverage: "Business Personal Property at Other Premises",
      deductible: deductibleAmount,
      limit: {
        None: "$10,000",
        Silver: "$25,000",
        Gold: "$50,000",
        Platinum: "$50,000",
      },
    },
    {
      coverage: "Business Personal Property Off Premises",
      toolTipContentKey: "PERSONAL_PROPERTY_OFF_PREMISES",
      deductible: deductibleAmount,
      limit: {
        None: "$10,000",
        Silver: "$25,000",
        Gold: "$25,000",
        Platinum: "$25,000",
      },
    },
    {
      coverage: "Civil Authority",
      deductible: "N/A",
      toolTipContentKey: "CIVIL_AUTHORITY",
      limit: {
        None: "up to Business Income limit",
        Silver: "up to Business Income limit",
        Gold: "up to Business Income limit",
        Platinum: "up to Business Income limit",
      },
    },
    {
      coverage: "Collapse",
      deductible: deductibleAmount,
      limit: {
        None: "up to Building limit",
        Silver: "up to Building limit",
        Gold: "up to Building limit",
        Platinum: "up to Building limit",
      },
    },
    {
      coverage: "Computer Equipment",
      deductible: deductibleAmount,
      limit: {
        None: "up to BPP limit",
        Silver: "Blanket Limit of $150,000",
        Gold: "Blanket Limit of $350,000",
        Platinum: "Blanket Limit of $500,000",
      },
    },
    {
      coverage: "Computer Fraud",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "$5,000",
        Gold: "$5,000",
        Platinum: "$5,000",
      },
    },
    {
      coverage: "Contract Penalties",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "Not Included",
        Gold: "$1,000",
        Platinum: "$1,000",
      },
    },
    {
      coverage: "Debris Removal",
      deductible: deductibleAmount,
      limit: {
        None: "$25,000",
        Silver: "Blanket Limit of $150,000",
        Gold: "Blanket Limit of $350,000",
        Platinum: "Blanket Limit of $500,000",
      },
    },
    {
      coverage: "Electronic Data",
      toolTipContentKey: "ELECTRONIC_DATA",
      deductible: deductibleAmount,
      limit: { None: "$10,000", Silver: "$10,000", Gold: "$10,000", Platinum: "$10,000" },
    },
    {
      coverage: "Employee Dishonesty including ERISA compliance",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "$25,000",
        Gold: "$25,000",
        Platinum: "$25,000",
      },
    },
    {
      coverage: "Extended Business Income",
      deductible: deductibleAmount,
      limit: {
        None: "60 days",
        Silver: "90 days",
        Gold: "12 months",
        Platinum: "12 months",
      },
    },
    {
      coverage: "Fine Art",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "$25,000",
        Gold: "$50,000",
        Platinum: "$75,000",
      },
    },
    {
      coverage: "Fire Department Service Charge",
      toolTipContentKey: "FD_SERVICE_CHARGE",
      deductible: "N/A",
      limit: { None: "$2,500", Silver: "$2,500", Gold: "$2,500", Platinum: "$2,500" },
    },
    {
      coverage: "Fire Extinguisher Systems Recharge Expense",
      toolTipContentKey: "FIRE_EXTINGUISHER_RECHARGE",
      deductible: "N/A",
      limit: { None: "$5,000", Silver: "$5,000", Gold: "$5,000", Platinum: "$5,000" },
    },
    {
      coverage: "Forgery or Alteration",
      toolTipContentKey: "FORGERY",
      deductible: "N/A",
      limit: {
        None: "$2,500",
        Silver: "$25,000",
        Gold: "$25,000",
        Platinum: "$35,000",
      },
    },
    {
      coverage: "Increased Cost of Construction",
      toolTipContentKey: "CONSTRUCTION_COST",
      deductible: deductibleAmount,
      limit: { None: "$10,000", Silver: "$10,000", Gold: "$10,000", Platinum: "$10,000" },
    },
    {
      coverage: "Interruption of Computer Operations",
      toolTipContentKey: "INTERRUPTION_OF_COMPUTER_OPERATIONS",
      deductible: deductibleAmount,
      limit: { None: "$10,000", Silver: "$10,000", Gold: "$10,000", Platinum: "$10,000" },
    },
    {
      coverage: "Liability Claims Expense",
      deductible: deductibleAmount,
      limit: { None: "$250/day", Silver: "$250/day", Gold: "$250/day", Platinum: "$250/day" },
    },
    {
      coverage: "Limited Coverage for Fungi, Wet Rot or Dry Rot",
      deductible: deductibleAmount,
      limit: { None: "$15,000", Silver: "$15,000", Gold: "$15,000", Platinum: "$15,000" },
    },
    {
      coverage: "Money Orders & Counterfeit Money",
      toolTipContentKey: "MONEY_ORDERS_COUNTERFEIT",
      deductible: deductibleAmount,
      limit: { None: "$1,000", Silver: "$1,000", Gold: "$1,000", Platinum: "$1,000" },
    },
    {
      coverage: "Newly Acquired or Constructed Property",
      deductible: deductibleAmount,
      toolTipContentKey: "NEW_PROPERTY",
      limit: {
        None: "30 days",
        Silver: "60 days",
        Gold: "120 days",
        Platinum: "180 days",
      },
    },
    {
      coverage: "Newly Acquired or Constructed Property - Building",
      deductible: deductibleAmount,
      toolTipContentKey: "NEW_PROPERTY",
      limit: {
        None: "$250,000",
        Silver: "$1,000,000",
        Gold: "$1,000,000",
        Platinum: "$1,000,000",
      },
    },
    {
      coverage: "Newly Acquired or Constructed Property - BPP",
      deductible: deductibleAmount,
      limit: {
        None: "$100,000",
        Silver: "$500,000",
        Gold: "$500,000",
        Platinum: "$500,000",
      },
    },
    {
      coverage: "Ordinance or Law - Demolition Cost",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "Not Included",
        Gold: "$25,000",
        Platinum: "$25,000",
      },
    },
    {
      coverage: "Ordinance or Law - Increased Cost of Construction",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "Not Included",
        Gold: "$50,000",
        Platinum: "$50,000",
      },
    },
    {
      coverage: "Ordinance or Law - Loss to Undamaged Portion of Building",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "Not Included",
        Gold: "Building limit",
        Platinum: "Building limit",
      },
    },
    {
      coverage: "Outdoor Property",
      toolTipContentKey: "OUTDOOR_PROPERTY",
      deductible: deductibleAmount,
      limit: {
        None: "$2,500",
        Silver: "$25,000",
        Gold: "$25,000",
        Platinum: "$25,000",
      },
    },
    {
      coverage: "Outdoor Property - Any 1 Tree, Shrub, or Plant",
      deductible: deductibleAmount,
      limit: {
        None: "$1,000",
        Silver: "$1,000",
        Gold: "$1,000",
        Platinum: "$1,000",
      },
    },
    {
      coverage: "Outdoor signs (detached) - per sign",
      deductible: "N/A",
      limit: {
        None: "$1,000",
        Silver: "$1,000",
        Gold: "Building limit",
        Platinum: "Building limit",
      },
    },
    {
      coverage: "Pairs or Sets",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "Included",
        Gold: "Included",
        Platinum: "Included",
      },
    },
    {
      coverage: "Personal Effects",
      toolTipContentKey: "PERSONAL_EFFECTS",
      deductible: deductibleAmount,
      limit: {
        None: "$2,500",
        Silver: "$60,000",
        Gold: "$60,000",
        Platinum: "$60,000",
      },
    },
    {
      coverage: "Personal Property in Transit",
      deductible: deductibleAmount,
      limit: {
        None: "$10,000",
        Silver: "$10,000",
        Gold: "$25,000",
        Platinum: "$25,000",
      },
    },
    {
      coverage: "Personal Property Of Others - Replacement Cost",
      deductible: deductibleAmount,
      limit: {
        None: "Up to BPP limit",
        Silver: "Blanket Limit of $150,000",
        Gold: "Blanket Limit of $350,000",
        Platinum: "Blanket Limit of $500,000",
      },
    },
    {
      coverage: "Pollutant Clean Up & Removal",
      deductible: deductibleAmount,
      limit: { None: "$10,000", Silver: "$10,000", Gold: "$10,000", Platinum: "$10,000" },
    },
    {
      coverage: "Preservation of Property",
      toolTipContentKey: "PRESERVATION_OF_PROPERTY",
      deductible: deductibleAmount,
      limit: { None: "30 days", Silver: "30 days", Gold: "30 days", Platinum: "30 days" },
    },
    {
      coverage: "Property Claims Expense",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "$10,000",
        Gold: "$10,000",
        Platinum: "$10,000",
      },
    },
    {
      coverage: "Sales Representative Samples",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "$5,000",
        Gold: "$25,000",
        Platinum: "$25,000",
      },
    },
    {
      coverage: "Sump Overflow or Sump Pump Failure",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "$50,000",
        Gold: "$50,000",
        Platinum: "$50,000",
      },
    },
    {
      coverage: "Tenant Building Coverage - Required by Lease",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "$20,000",
        Gold: "$20,000",
        Platinum: "$20,000",
      },
    },
    {
      coverage: "Tenant Glass - Exterior",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "Not Included",
        Gold: "Included",
        Platinum: "Included",
      },
    },
    {
      coverage: "Tenant Glass - Interior",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "Not Included",
        Gold: "Included",
        Platinum: "Included",
      },
    },
    {
      coverage: "Theft - Furs",
      deductible: deductibleAmount,
      limit: { None: "$2,500", Silver: "$2,500", Gold: "$2,500", Platinum: "$2,500" },
    },
    {
      coverage: "Theft - Jewelry",
      deductible: deductibleAmount,
      limit: {
        None: "$2,500",
        Silver: "$2,500",
        Gold: "$5,000",
        Platinum: "$5,000",
      },
    },
    {
      coverage: "Theft - Patterns, dies, molds and forms",
      deductible: deductibleAmount,
      limit: {
        None: "$2,500",
        Silver: "$2,500",
        Gold: "Up to BPP limit",
        Platinum: "Up to BPP limit",
      },
    },
    {
      coverage: "Theft - Precious Metal and Alloys",
      deductible: deductibleAmount,
      limit: {
        None: "$2,500",
        Silver: "$25,000",
        Gold: "Up to BPP limit",
        Platinum: "Up to BPP limit",
      },
    },
    {
      coverage: "Unauthorized Business Credit Card Use",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "$5,000",
        Gold: "$5,000",
        Platinum: "$5,000",
      },
    },
    {
      coverage: "Utility Services - Business Income",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "$50,000",
        Gold: "$50,000",
        Platinum: "$50,000",
      },
    },
    {
      coverage: "Utility Services - Direct Damage (covered cause of loss)",
      deductible: deductibleAmount,
      limit: {
        None: "Included",
        Silver: "$25,000",
        Gold: "$25,000",
        Platinum: "$25,000",
      },
    },
    {
      coverage: "Valuable Papers & Records Off Premises",
      toolTipContentKey: "VALUABLE_PAPERS",
      deductible: deductibleAmount,
      limit: {
        None: "$5,000",
        Silver: "Blanket Limit of $150,000",
        Gold: "Blanket Limit of $350,000",
        Platinum: "Blanket Limit of $500,000",
      },
    },
    {
      coverage: "Valuable Papers & Records On Premises",
      toolTipContentKey: "VALUABLE_PAPERS",
      deductible: deductibleAmount,
      limit: {
        None: "$10,000",
        Silver: "Blanket Limit of $150,000",
        Gold: "Blanket Limit of $350,000",
        Platinum: "Blanket Limit of $500,000",
      },
    },
    {
      coverage: "Valuation - Commodity, Finished and Mercantile Stock",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "Market Price",
        Gold: "Market Price",
        Platinum: "Market Price",
      },
    },
    {
      coverage: "Worldwide Property Off Premises - Laptops",
      deductible: deductibleAmount,
      limit: {
        None: "Not Included",
        Silver: "$10,000",
        Gold: "$10,000",
        Platinum: "$15,000",
      },
    },
  ];

  return limit_values.map((coverage) => {
    const limit = !endorsement ? coverage.limit.None : coverage.limit[endorsement];
    return {
      ...coverage,
      limit,
      None: coverage.limit.None,
      Silver: coverage.limit.Silver,
      Gold: coverage.limit.Gold,
      Platinum: coverage.limit.Platinum,
    };
  });
};

export const endorsementPackagesEnums = [
  EndorsementPackages.None,
  EndorsementPackages.Silver,
  EndorsementPackages.Gold,
  EndorsementPackages.Platinum,
];

export const policyFinePrint =
  "The information contained is subject to rate changes and does not in any way bind, amend or alter any policy of insurance or coverage. For a complete understanding of the coverage available, please review the terms, conditions, definitions, and exclusions of your policy in addition to Coterie's terms & conditions, available at ";

export const policyFinePrintCanada =
  "The information contained is subject to rate changes and does not in any way bind, amend or alter any policy of insurance or coverage.  For a complete understanding of the coverage available, please consult the terms, conditions, definitions, and exclusions of your insurance policy.";

export const carrierTypes: CarrierDictionary = {
  [carriers.BENCHMARK]: {
    rating: "A (Excellent)",
    naic: "41394",
    displayName: "Benchmark Insurance Company",
    financialSizeCategory: "9",
  },
  [carriers.SPINNAKER]: {
    rating: "A- (Excellent)",
    naic: "24376",
    displayName: "Spinnaker Insurance Company",
    financialSizeCategory: "8",
  },
  [carriers.CLEARSPRING]: {
    rating: "A- (Excellent)",
    naic: "15563",
    displayName: "Clear Spring Property and Casualty",
    financialSizeCategory: "8",
  },
  [carriers.INTACT]: {
    rating: "A- (Excellent)",
    naic: "2089555x",
    displayName: "Intact",
  },
};
export const coterieAddress = "Coterie Insurance Agency, LLC \nP.O. Box 8327 \nCincinnati, OH 45208";
export const BrokerLinkAddress = "BrokerLink \n7909 Flint Rd SE Suite 102 \nCalgary, AB, T2H 1G3 Canada";

export interface AlternateQuoteType {
  isOptInLowerCost: boolean;
  lossControlOptOutPremium: number;
  lossControlOptOutMonthlyPremium: number;
  optInCostDifferenceYearly: number | undefined;
}
