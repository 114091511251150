import React from "react";
import { connect } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import { CoverageCard, CoverageType } from "shared/application/StyledComponents";
import {
  selectApplicationFormGLLimit,
  selectTwoFourLimitsAvailable,
  selectApplicationFormLiquorEndorsement,
} from "redux/selectors/application";
import { updateApplicationForm, updateApplicationFormLocation } from "redux/reducers/_applicationReducer";
import Dropdown, { StyledSelect } from "styles/Dropdown";
import { tablet, Label } from "styles";
import formotivInstance from "utils/formotiv";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  glLimit: number;
  twoFourLimitsAvailable: boolean;
  updateApplicationForm: (arg0: any) => void;
  hasLiquorLiability: boolean;
}

const GLCoverages = ({ glLimit, twoFourLimitsAvailable, updateApplicationForm, hasLiquorLiability }: PropTypes) => {
  const { showTwoFourGlLimit } = useFeatureFlags();

  const updateGlLiability = (amount: number) => {
    if (window.formotiv) {
      formotivInstance.trackCustom("quote-general-liability-limits");
    }
    updateApplicationForm({ glLimit: amount });
  };

  const isTwoFourLimitDisabled = !twoFourLimitsAvailable || hasLiquorLiability;

  let twoFourHelperText;
  if (!twoFourLimitsAvailable) {
    twoFourHelperText = "Unavailable for selected industry and location";
  } else if (hasLiquorLiability) {
    twoFourHelperText = "Unavailable when Liquor Liability is added";
  }

  const generalLiabilityLimitOptions = [
    { value: 300_000, label: "$300,000 per occ / $600,000 agg", disabled: false },
    { value: 500_000, label: "$500,000 per occ / $1,000,000 agg", disabled: false },
    { value: 1_000_000, label: "$1,000,000 per occ / $2,000,000 agg", disabled: false },
    ...(showTwoFourGlLimit
      ? [
          {
            value: 2_000_000,
            label: "$2,000,000 per occ / $4,000,000 agg",
            disabled: isTwoFourLimitDisabled,
            helperText: twoFourHelperText,
          },
        ]
      : []),
  ];

  return (
    <CoverageCard>
      <OverrideStyle />
      <LiabilityLimits>Liability Limits</LiabilityLimits>
      <FlexContainer>
        <InputContainer width={312}>
          <Dropdown
            id="quote-general-liability-limits"
            options={generalLiabilityLimitOptions}
            onChange={(option) => updateGlLiability(option.value)}
            value={glLimit}
            name="GeneralLiabilityLimits"
            label="General Liability Limits"
            full
          />
        </InputContainer>
      </FlexContainer>
    </CoverageCard>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  glLimit: selectApplicationFormGLLimit(state),
  twoFourLimitsAvailable: selectTwoFourLimitsAvailable(state),
  hasLiquorLiability: Boolean(selectApplicationFormLiquorEndorsement(state)),
});

const mapDispatchToProps = { updateApplicationForm, updateApplicationFormLocation };

const FlexContainer = styled.div`
  display: flex;
  column-gap: 24px;
  ${tablet} {
    flex-wrap: wrap;
  }
`;

const InputContainer = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  margin-bottom: 24px;
  ${tablet} {
    width: 100%;
  }
`;

const OverrideStyle = createGlobalStyle`
  ${FlexContainer} {
    ${Label} {
      font-size: 13px;
    }
    ${StyledSelect} {
     margin-bottom: 0
    }
  }
`;

const LiabilityLimits = styled(CoverageType)`
  margin-bottom: 24px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(GLCoverages);
