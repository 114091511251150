import AgentResources from "../AgentResources";
import APIDocumentation from "../APIDocumentation";
import { Role } from "types/enums";

export const navigation = [
  {
    name: "Quotes",
    to: "/quotes",
    ia: true,
    roles: [Role.ChannelPartner, Role.Developer, Role.NonLicensed],
    dataCy: "quote-search-link",
    id: 1,
  },
  { name: "Policies", to: "/accounts", ia: true, dataCy: "policies-nav-link", id: 2 },
  { name: "My Account", to: "/my-account/account", ia: true, dataCy: "my-account-nav-link", id: 3 },
  {
    name: "Failed Transactions",
    to: "/failed-transactions",
    ia: true,
    roles: [Role.ChannelPartner, Role.Developer, Role.NonLicensed],
    dataCy: "failed-transaction-report-link",
    id: 20,
  },
  {
    name: "Renewals",
    to: "/renewals",
    ia: true,
    roles: [Role.ChannelPartner, Role.NonLicensed],
    dataCy: "renewals-report-link",
    id: 21,
  },
  {
    name: "Commissions",
    to: "/commissions",
    ia: true,
    roles: [Role.ChannelPartner],
    dataCy: "commissions-nav-link",
    id: 22,
  },
  {
    name: "Appetite Checker",
    to: "/appetite-checker",
    ia: true,
    roles: [Role.ChannelPartner, Role.Agency, Role.Developer, Role.NonLicensed],
    dataCy: "appetite-nav-link",
    id: 23,
  },
  {
    name: "Resources",
    to: "resources",
    ia: true,
    custom: AgentResources,
    id: 4,
  },
  { name: "Home", to: "/", ia: false, exact: true, id: 5 },
  {
    name: "Policy Search",
    to: "/policies",
    ia: false,
    roles: [Role.ChannelPartner, Role.Developer],
    dataCy: "policy-search-link",
    id: 6,
  },
  {
    name: "Channel Partners",
    to: "/channel-partners",
    ia: false,
    roles: [Role.Developer],
    dataCy: "channel-partner-link",
    id: 7,
  },
  { name: "View Logs", to: "/logs", ia: false, roles: [Role.ChannelPartner, Role.Developer], id: 8 },
  { name: "Third Party Logs", to: "/third-party-logs", ia: false, roles: [Role.Developer], id: 9 },
  { name: "API Key", to: "/api-key", ia: false, roles: [Role.ChannelPartner], id: 10 },
  {
    name: "Charge Report",
    to: "/reports/charge-report",
    ia: false,
    roles: [Role.Developer],
    dataCy: "charge-report-link",
    id: 11,
  },
  {
    name: "Open Policy Report",
    to: "/reports/open-policy-report",
    ia: false,
    id: 12,
  },
  {
    name: "Failed Transactions",
    to: "/reports/failed-transaction-report",
    ia: false,
    roles: [Role.Developer],
    dataCy: "failed-transaction-report-link",
    id: 13,
  },
  {
    name: "Open Project Policies",
    to: "/reports/open-project-policy-report",
    ia: false,
    roles: [Role.ChannelPartner, Role.Developer],
    dataCy: "open-project-policies",
    id: 14,
  },
  {
    ia: false,
    roles: [Role.ChannelPartner],
    custom: APIDocumentation,
    id: 15,
  },
  {
    name: "Application Search",
    to: "/quotes",
    ia: false,
    roles: [Role.Developer],
    dataCy: "application-search",
    id: 16,
  },
  {
    name: "First Notice of Loss",
    to: "/first-notice-of-loss",
    ia: false,
    roles: [Role.Developer],
    id: 23,
  },
  {
    name: "Account Search",
    to: "/account-search",
    ia: false,
    roles: [Role.Developer],
    dataCy: "account-search",
    id: 17,
  },
  {
    name: "Developer Tools",
    to: "/developer-tools",
    ia: false,
    roles: [Role.Developer],
    id: 18,
  },
  {
    name: "Agent Search",
    to: "/agents",
    ia: false,
    roles: [Role.Developer],
    dataCy: "agent-search",
    id: 19,
  },
  {
    name: "Agent Sign Up",
    to: "/agent-sign-up",
    ia: false,
    roles: [Role.Developer],
    id: 20,
  },
];
