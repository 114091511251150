import React from "react";
import ToolTipIcon from "shared/ToolTipIcon";
import styled from "styled-components";
import { Label } from "styles";
import DragDropImageUpload from "kit/imageUpload/DragDropImageUpload";

const CompanyLogoUpload = ({ setLogoSrc }: { setLogoSrc: React.Dispatch<React.SetStateAction<string>> }) => {
  return (
    <SectionWrapper>
      <Label>Company Logo</Label>
      {/* TODO remove once this feature is using portal API */}
      <WarningLabel>
        DO NOT USE IN PRODUCTION
        <ToolTipIcon contentKey="TEMP_LOGO_UPLOAD" />
      </WarningLabel>
      <DragDropImageUpload setLogoSrc={setLogoSrc} />
    </SectionWrapper>
  );
};

// TODO remove once this feature is using portal API
const WarningLabel = styled(Label)`
  display: flex;
  flex-direction: row;
  color: red;
`;

const SectionWrapper = styled.div`
  margin: 19px 18px 0;
`;

export default CompanyLogoUpload;
