export function downloadPDF(url: string) {
  const anchorElement = document.createElement("a");
  anchorElement.style.cssText = "display: none;";
  anchorElement.href = url;
  anchorElement.id = "pdf-download-blob";
  anchorElement.download = "quoteproposal.pdf";
  if (anchorElement) {
    anchorElement.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    anchorElement.remove();
  }
}
