import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Spinner } from "reactstrap";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NarrowButton } from "styles";
import styled from "styled-components";
import Api from "utils/api";
import {
  ViewContainer,
  CardContainer,
  ColumnContainer,
  ContentContainer,
  TitleContainer,
  Title,
  Subtitle,
} from "styles/containerStyledComponents";
import ChargeReportTable from "kit/ChargeReportTable";
import AddChargeModal from "./AddChargeModal";
import AdditionalInsuredsCard from "shared/AdditionalInsuredsCard";
import CoverageInfoCard from "./CoverageInfoCard";
import ChangeLog from "./ChangeLog";
import EditPolicyModal from "./EditPolicyModal";
import Endorsements from "./Endorsements";
import Locations from "./Locations";
import MailingAddressCard from "./MailingAddressCard";
import PaymentInfoCard from "shared/PaymentInfoCard";
import PolicyInfo from "./PolicyInfo";
import PrimaryInsured from "shared/PrimaryInsured";
import RenewModal from "./RenewModal";
import UpdatePaymentMethodModal from "kit/updatePaymentMethod/UpdatePaymentMethodModal";
import { toastError, toastSuccess } from "utils/toast";
import getCurrentApplication from "redux/actions/getCurrentApplication";
import { selectApplicationFormApplicationTypes, selectEndorsements } from "redux/selectors/application";
import BusinessBasics from "shared/application/view/BusinessBasics";
import Coverages from "shared/application/view/Coverages";
import PreviousLosses from "shared/application/view/PreviousLosses";
import CancelModal from "./CancelModal";
import LossRunModal from "ia/Policy/LossRunRequest";
import useAuth from "hooks/useAuth";
import ActionsDropdown from "styles/ActionsDropdown";
import StatusSymbol from "shared/StatusSymbol";
import OneTimePaymentModal from "kit/oneTimePayment/OneTimePaymentModal";
import {
  reportCancelPolicyError,
  reportError,
  reportGetAuditTrailMsg,
  reportGetPolicyChargesError,
  reportGetPolicyDocsError,
  reportSendPolicyDocsError,
  reportUpdatePolicySettingsError,
  reportUpdateQuotePolicyError,
} from "utils/reportError";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import ShieldLossControl from "images/shield_loss_control.svg";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CTAButton } from "coterie-ui-library";

interface PropTypes {
  policy: Policy;
  onPolicyChange: () => void;
  getCurrentApplication: (arg0: any) => any;
  applicationTypes: any;
  applicationEndorsements: any;
}

interface DocsType {
  link: string;
  name: string;
}

const PolicyView = ({
  policy,
  onPolicyChange,
  getCurrentApplication,
  applicationTypes,
  applicationEndorsements,
}: PropTypes) => {
  const { showChargeEdit, showLossControl, apiVersion, showAuditTab } = useFeatureFlags();

  const { getToken } = useAuth();

  const [activeTab, setActiveTab] = useState(0);
  const [docs, setDocs] = useState<DocsType[]>([]);
  const [charges, setCharges] = useState<PolicyChargeReportItem[]>([]);
  const [auditTrail, setAuditTrail] = useState<PolicyAuditItem[]>([]);
  const [policyChangeQuoteResult, setPolicyChangeQuoteResult] = useState<QuotePolicyUpdateResponse | undefined>(
    undefined
  );
  const [isLoadingDocs, setIsLoadingDocs] = useState(true);
  const [isLoadingCharges, setIsLoadingCharges] = useState(true);
  const [isLoadingAuditTrail, setIsLoadingAuditTrail] = useState(true);
  const [isSavingPolicyChanges, setIsSavingPolicyChanges] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddChargeModal, setShowAddChargeModal] = useState(false);
  const [isAddingCharge, setIsAddingCharge] = useState(false);
  const [showUpdatePaymentMethodModal, setShowUpdatePaymentMethodModal] = useState(false);
  const [showOneTimePaymentModal, setShowOneTimePaymentModal] = useState(false);
  const [isRenewing, setIsRenewing] = useState(false);
  const [showRenewModal, setShowRenewModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showLossRunModal, setShowLossRunModal] = useState(false);

  const showFeeWarning = policy.transactionFeeEnabled;
  const transactionFeeAmount = policy.transactionFeeAmount;
  const lossControlFee = policy?.fees?.find((fee) => fee?.feeType === "LossControlFee") ?? { amount: 0 };
  const hasLossControl = showLossControl && lossControlFee?.amount > 0;

  useEffect(() => {
    loadPolicyDocs();
    loadAuditTrail();
    loadPolicyCharges();
    loadApplication();
  }, []);

  const loadPolicyDocs = async () => {
    setIsLoadingDocs(true);

    const token = await getToken();
    const api = new Api(apiVersion, token);

    api
      .getPolicyDocumentList(policy.policyNumber, apiVersion)
      .then((docs) => {
        setDocs(docs);
      })
      .catch((error) => reportGetPolicyDocsError(error))
      .finally(() => {
        setIsLoadingDocs(false);
      });
  };

  const loadPolicyCharges = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    api
      .getPolicyCharges(policy.policyNumber)
      .then((charges) => {
        setCharges(charges);
      })
      .catch((error) => reportGetPolicyChargesError(error))
      .finally(() => {
        setIsLoadingCharges(false);
      });
  };

  const loadAuditTrail = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    api
      .getAuditTrail(policy.policyNumber)
      .then((auditTrail) => {
        setAuditTrail(auditTrail);
      })
      .catch((error) => reportGetAuditTrailMsg(error))
      .finally(() => {
        setIsLoadingAuditTrail(false);
      });
  };

  const loadApplication = async () => {
    const token = await getToken();
    const applicationId = policy.quote?.applicationId;
    if (applicationId) {
      getCurrentApplication({ applicationId, token, apiVersion });
    }
  };

  const toggleAutoExtend = async () => {
    const { policyNumber, autoExtend } = policy;
    const confirmMessage = `Turn AutoExtend ${autoExtend ? "Off" : "On"}?`;

    if (!window.confirm(confirmMessage)) return;

    setIsSavingPolicyChanges(true);
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api.policies
      .updateSettings(policyNumber, { autoExtend: !autoExtend })
      .then(() => onPolicyChange())
      .catch((error) => reportUpdatePolicySettingsError(error))
      .finally(() => {
        setIsSavingPolicyChanges(false);
      });
  };

  const renewPolicy = async (annualPayroll: number, grossAnnualSales: number) => {
    const { policyNumber } = policy;
    setIsRenewing(true);
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api.policies
      .renew(policyNumber, { annualPayroll: annualPayroll, grossAnnualSales: grossAnnualSales })
      .then(() => {
        onPolicyChange();
        setShowRenewModal(!showRenewModal);
        toastSuccess("Policy renewed for one year.");
      })
      .catch((error) => reportUpdatePolicySettingsError(error))
      .finally(() => {
        setIsRenewing(false);
      });
  };

  const setCurrentTab = (tab: number) => {
    const maxTab = showAuditTab ? 4 : 3;
    if (tab > maxTab || tab < 0) {
      tab = 0;
    }
    setActiveTab(tab);
  };

  const savePolicyChanges = async (changes: PolicyChangeRequest) => {
    setIsSavingPolicyChanges(true);
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api
      .updatePolicy(policy.policyNumber, changes)
      .then(() => {
        onPolicyChange();
        loadPolicyDocs();
        loadPolicyCharges();
        loadAuditTrail();
        setShowEditModal(!showEditModal);
      })
      .catch((error) => reportUpdatePolicySettingsError(error))
      .finally(() => {
        setIsSavingPolicyChanges(false);
      });
  };

  const quotePolicyChanges = async (changes: PolicyChangeRequest) => {
    setIsSavingPolicyChanges(true);
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api
      .quotePolicyUpdate(policy.policyNumber, changes)
      .then((result) => setPolicyChangeQuoteResult(result))
      .catch((error) => reportUpdateQuotePolicyError(error))
      .finally(() => {
        setIsSavingPolicyChanges(false);
      });
  };

  const addCharge = async (charge: Charge) => {
    if (window.confirm("Are you sure you want to add this manual charge?")) {
      setIsAddingCharge(true);
      const { policyNumber } = policy;

      const token = await getToken();
      const api = new Api(apiVersion, token);

      api
        .addCharge(policyNumber, charge)
        .then((response) => {
          if (response.errors) {
            let errors = (response.errors as [{ message: string }]).map((e) => e.message).join("\n");
            toastError(errors);
          } else {
            onPolicyChange();
            loadPolicyCharges();
            toastSuccess("Charge added!");
            setShowAddChargeModal(!showAddChargeModal);
          }
        })
        .catch((error) => reportError(error))
        .finally(() => {
          setIsAddingCharge(false);
        });
    }
  };

  const cancelPolicy = async (cancelDate: any) => {
    setIsSavingPolicyChanges(true);
    const token = await getToken();
    const api = new Api(apiVersion, token);

    api
      .cancelPolicy(policy.policyNumber, { cancelDate: cancelDate })
      .then((result) => {
        if (result.success) {
          toastSuccess("Policy cancelled!");
          setShowCancelModal(!showCancelModal);
          onPolicyChange();
        } else {
          reportCancelPolicyError(result.error);
        }
      })
      .catch((error) => reportCancelPolicyError(error))
      .finally(() => {
        setIsSavingPolicyChanges(false);
      });
  };

  const locations = policy?.locations || [];
  const endorsements = policy?.endorsements || {};
  const { additionalInsureds, ...otherEndorsements } = endorsements;
  const chargesCheck = charges || [];
  const docsCheck = docs || [];

  const sendPolicyDocs = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    api
      .sendPolicyDocs(policy.policyNumber)
      .then((result) => {
        if (result.success) {
          toastSuccess(`Policy docs sent to '${policy.contactEmail}'`);
        } else {
          reportError(result.error);
        }
      })
      .catch((error) => reportSendPolicyDocsError(error));
  };

  const renderPolicy = () => {
    const dropdownActions: ActionsType[] = [
      { name: "Edit Policy", action: () => setShowEditModal(true) },
      { name: "Email Policy Docs", action: sendPolicyDocs },
      { name: "Toggle AutoExtend On/Off", action: toggleAutoExtend },
      { name: "Cancel Policy", action: () => setShowCancelModal(true), hide: policy.status === "Cancelled" },
      { name: "Update Payment Method", action: () => setShowUpdatePaymentMethodModal(true) },
      { name: "Renew Policy", action: () => setShowRenewModal(true) },
      { name: "Loss Run Request", action: () => setShowLossRunModal(true) },
    ];

    const ratingDetailsJSON = JSON.stringify(policy?.ratingDetails);

    const copyJSONToClipboard = () => {
      navigator.clipboard.writeText(ratingDetailsJSON);
      toastSuccess("Copied to clipboard!");
    };

    return (
      <ViewContainer data-cy="policy-view">
        {isSavingPolicyChanges ? (
          <LoadingIndicator>
            <Spinner color="primary" />
          </LoadingIndicator>
        ) : (
          <span />
        )}

        <TitleContainer>
          <Title hasStatus>{policy.businessName}</Title>
          <StatusSymbol expanded status={policy.status} />
          <ActionsDropdown actions={dropdownActions} />
        </TitleContainer>
        {Boolean(policy?.dba) && <DbaTitle>DBA {policy?.dba}</DbaTitle>}
        <Subtitle>
          <p data-cy="subtitle">{policy.channelPartnerName}</p>
        </Subtitle>

        <PolicyTabs>
          <li className={activeTab === 0 ? "active" : ""}>
            <TabButton onClick={() => setCurrentTab(0)}>Policy Information</TabButton>
          </li>
          <li className={activeTab === 1 ? "active" : ""}>
            <TabButton onClick={() => setCurrentTab(1)}>Payment History</TabButton>
          </li>
          <li className={activeTab === 2 ? "active" : ""}>
            <TabButton onClick={() => setCurrentTab(2)}>Change Log</TabButton>
          </li>
          <li className={activeTab === 3 ? "active" : ""}>
            <TabButton onClick={() => setCurrentTab(3)}>Application View</TabButton>
          </li>
          {showAuditTab && (
            <li className={activeTab === 4 ? "active" : ""}>
              <TabButton onClick={() => setCurrentTab(4)}>Audit</TabButton>
            </li>
          )}
        </PolicyTabs>

        {activeTab === 0 && (
          <React.Fragment>
            <CardContainer>
              <ColumnContainer>
                <PrimaryInsured primaryInsured={policy} isEditable={false} />
                <MailingAddressCard address={policy.mailingAddress} isEditable={false} />

                <Card>
                  <CardBody>
                    <CardTitle>
                      Policy Documents
                      {
                        //eslint-disable-next-line
                        <a href="#" style={{ float: "right" }} onClick={loadPolicyDocs}>
                          <FontAwesomeIcon icon={faSync as IconProp} />
                        </a>
                      }
                    </CardTitle>
                    {isLoadingDocs ? (
                      <div>Loading...</div>
                    ) : docsCheck.length > 0 ? (
                      <ul>
                        {docsCheck.map((doc, i) => (
                          <li key={i}>
                            <a target="_blank" rel="noopener noreferrer" href={doc.link}>
                              {doc.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div>
                        <ul>
                          <li>None</li>
                        </ul>
                      </div>
                    )}
                  </CardBody>
                </Card>

                <AdditionalInsuredsCard endorsements={policy.endorsements} />

                <Card>
                  <CardTitle>Other Endorsements</CardTitle>
                  <CardBody>
                    <div className="endorsements-card-body">
                      {otherEndorsements ? <Endorsements endorsements={otherEndorsements} /> : <div>None</div>}
                    </div>
                  </CardBody>
                </Card>

                {hasLossControl && (
                  <Card>
                    <CardTitle>Added Services</CardTitle>
                    <CardBody>
                      <div className="endorsements-card-body">
                        <b>
                          <img src={ShieldLossControl} alt="" /> Manage My Risk Program
                        </b>{" "}
                        Enrolled
                      </div>
                    </CardBody>
                  </Card>
                )}
              </ColumnContainer>
              <ColumnContainer>
                <PolicyInfo policy={policy} />
                <CoverageInfoCard policy={policy} />
                <PaymentInfoCard policy={policy} />

                <Card>
                  <CardTitle>Locations</CardTitle>
                  <CardBody>
                    {locations && locations.length > 0 ? <Locations locations={locations} /> : <div>None</div>}
                  </CardBody>
                </Card>
              </ColumnContainer>
            </CardContainer>
          </React.Fragment>
        )}

        {activeTab === 1 && (
          <ContentContainer>
            <Card>
              <CardTitle>Charges</CardTitle>
              <CardBody>
                <div className="charges-body">
                  {isLoadingCharges ? (
                    <div>Loading...</div>
                  ) : (
                    <ChargeReportTable charges={chargesCheck} defaultPageSize={5} />
                  )}
                  {showChargeEdit ? (
                    <BottomPanel>
                      <BottomPanelButton
                        onClick={() => setShowAddChargeModal(!showAddChargeModal)}
                        data-cy="add-charge-button"
                      >
                        Add Charge
                      </BottomPanelButton>
                      <BottomPanelButton
                        onClick={() => setShowOneTimePaymentModal(true)}
                        data-cy="charge-policy-button"
                      >
                        Charge Policy
                      </BottomPanelButton>
                    </BottomPanel>
                  ) : (
                    <div></div>
                  )}
                </div>
              </CardBody>
            </Card>
          </ContentContainer>
        )}

        {activeTab === 2 && (
          <ContentContainer>
            <ChangeLog auditTrail={auditTrail} isLoading={isLoadingAuditTrail} />
          </ContentContainer>
        )}

        {activeTab === 3 && (
          <ContentContainer>
            <CardContainer>
              <ColumnContainer>
                <BusinessBasics />
                <PreviousLosses />
              </ColumnContainer>
              <ColumnContainer>
                <Coverages isReadOnly />
                {/* endorsements below temporarily removed for BOP 2.0 rollout {applicationTypes.includes(PolicyType.BOP) && <Endorsements isReadOnly />} */}
                <AdditionalInsuredsCard endorsements={applicationEndorsements} />
              </ColumnContainer>
            </CardContainer>
          </ContentContainer>
        )}

        {showAuditTab && activeTab === 4 && (
          <CardContainer>
            <Card>
              <CardTitle>Rating Details</CardTitle>
              <CardBody>
                <StyledTextArea readOnly>{ratingDetailsJSON}</StyledTextArea>
                <div>
                  <CTAButton onClick={copyJSONToClipboard}>Copy to Clipboard</CTAButton>
                </div>
              </CardBody>
            </Card>
          </CardContainer>
        )}

        <EditPolicyModal
          saveChanges={savePolicyChanges}
          quoteChanges={quotePolicyChanges}
          quoteResult={policyChangeQuoteResult}
          isLoading={isSavingPolicyChanges}
          closeModal={() => setShowEditModal(!showEditModal)}
          modalIsOpen={showEditModal}
          policy={policy}
        />
        <AddChargeModal
          modalIsOpen={showAddChargeModal}
          closeModal={() => setShowAddChargeModal(!showAddChargeModal)}
          addCharge={addCharge}
          isAddingCharge={isAddingCharge}
        />
        <UpdatePaymentMethodModal
          modalIsOpen={showUpdatePaymentMethodModal}
          onRequestClose={() => setShowUpdatePaymentMethodModal(!showUpdatePaymentMethodModal)}
          accountId={policy.accountId}
        />
        <OneTimePaymentModal
          modalIsOpen={showOneTimePaymentModal}
          onRequestClose={() => setShowOneTimePaymentModal(false)}
          policy={policy}
          modalTitle="Charge Policy"
          {...(showFeeWarning && { transactionFeeAmount: transactionFeeAmount })}
        />
        <RenewModal
          modalIsOpen={showRenewModal}
          closeModal={() => setShowRenewModal(!showRenewModal)}
          renewPolicy={renewPolicy}
          isRenewing={isRenewing}
          policy={policy}
        />
        <CancelModal
          modalIsOpen={showCancelModal}
          closeModal={() => setShowCancelModal(!showCancelModal)}
          cancelPolicy={cancelPolicy}
          policy={policy}
        />
        <LossRunModal
          modalIsOpen={showLossRunModal}
          closeModal={() => setShowLossRunModal(!showLossRunModal)}
          policy={policy}
        />
      </ViewContainer>
    );
  };

  return policy.policyNumber ? renderPolicy() : <div>Policy not found</div>;
};

const mapStateToProps = (state: ReduxState) => ({
  applicationTypes: selectApplicationFormApplicationTypes(state),
  applicationEndorsements: selectEndorsements(state),
});

const mapDispatchToProps = {
  getCurrentApplication,
};

const StyledTextArea = styled.textarea`
  width: 600px;
  height: 400px;
  padding: 12px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`;

const LoadingIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  background-color: white;
  opacity: 0.8;
  text-align: center;
  padding-top: 50%;
`;

const PolicyTabs = styled.ul`
  display: flex;
  list-style: none;
  padding-left: 0;

  li {
    line-height: 22px;
    font-family: ${(props) => props.theme.primaryFont};
    color: ${(props) => props.theme.fontColors?.primary};
    font-size: 18px;
    border-bottom: 2px solid lightgray;
    padding: 10px 20px;
    opacity: 0.75;
    cursor: pointer;

    &.active {
      border-bottom: 3px solid ${(props) => props.theme.green};
      opacity: 1;
    }
  }
`;

const BottomPanel = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 300px;
  margin: 8px;
`;

const BottomPanelButton = styled(NarrowButton)`
  padding: 10px 18px;
  margin: 16px 0px 16px 0px;
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.blue};
`;

const TabButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

const DbaTitle = styled(Title)`
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 32px;
`;

export { PolicyView };
export default connect(mapStateToProps, mapDispatchToProps)(PolicyView);
