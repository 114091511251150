import React, { useState } from "react";
import styled from "styled-components";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { ReactComponent as BlueDots } from "images/icon-dots.svg";
import { ReactComponent as Archive } from "images/archive.svg";
import { ReactComponent as Edit } from "images/edit.svg";

interface PropTypes {
  actions: ActionsType[];
}

interface IconsType {
  [key: string]: any;
}

const icons: IconsType = {
  "Edit Quote": <Edit />,
  "Archive Quote": <Archive />,
  "Archive Quotes": <Archive />,
};

const QuoteDropdown = ({ actions }: PropTypes) => {
  const [toggle, updateToggle] = useState(false);

  const handleToggle = () => {
    updateToggle(!toggle);
  };

  return (
    <Container data-testid="quote-dropdown">
      <Dropdown isOpen={toggle} toggle={handleToggle}>
        <DropdownToggle data-cy={"quote-dropdown-toggle"}>
          <DotsWrapper>
            <BlueDots title="blue dots" />
          </DotsWrapper>
        </DropdownToggle>
        <DropdownMenu right>
          {actions
            .filter((item: ActionsType) => !item.hide)
            .map((item: ActionsType, i: number) => (
              <Item key={i} data-cy={`dropdown-item-${i}`} onClick={item.action}>
                <IconContainer>
                  <IconsSpan>{icons[item.name]}</IconsSpan>
                  {item.name}
                </IconContainer>
              </Item>
            ))}
        </DropdownMenu>
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  margin-left: auto;
  .dropdown {
    margin-left: auto;
  }
  .dropdown:not(.show) {
    z-index: 1;
  }

  .dropdown-item {
    padding: 6px;
    padding-right: 20px;
  }

  .dropdown .btn {
    background-color: ${(props) => props.theme.white};
    border: none;
    display: inline-block;
    padding: 10px;

    &:focus,
    &:active {
      box-shadow: none;
      background: none;
      border: none;
    }
  }
`;

const Item = styled(DropdownItem)`
  color: ${(props) => props.theme.gray};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 23px;
  padding: 0;
`;

const DotsWrapper = styled.div`
  svg {
    fill: ${(props) => props.theme.blue};
  }
  width: 20px;
`;

const IconsSpan = styled.span`
  padding: 3px 6px 6px 11px;
`;

const IconContainer = styled.div`
  svg path {
    fill: ${(props) => props.theme.blue};
  }
`;

export default QuoteDropdown;
