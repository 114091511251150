import React, { useState, useEffect, useContext } from "react";
import { RuleLogicBlock, LogicBlockHeader, FlexDiv, DeleteLogicBlockButtonContainer } from "../styles";
import { Checkbox } from "styles";
import { stateAbbrevOptions } from "utils/constants";
import styled from "styled-components";
import { RuleOptions } from "../../Shared/types";
import { RuleBuilderContext } from "../RuleBuilderContext";
import DeleteButton from "../DeleteButton";

interface StatesBlockProps {
  ruleOption: RuleOptions;
  states: string[];
}

const StatesBlock = ({ states, ruleOption }: StatesBlockProps) => {
  const [previouslySelected, setPreviouslySelected] = useState<string[]>([]);
  const [allStatesChecked, setAllStatesChecked] = useState<boolean>(false);
  const [selectedStatesChecked, setSelectedStatesChecked] = useState<boolean>(true);
  const { updateLogicBlock, updateStates, activeRulePath, activeLogicBlock, deleteBlock } =
    useContext(RuleBuilderContext);

  const isFirstRuleOption = ruleOption.path === "0.options.0";
  const isSelected = ruleOption.path === activeRulePath && activeLogicBlock === "states";

  useEffect(() => {
    if (allStatesChecked) {
      setSelectedStatesChecked(false);
      updateStates(stateAbbrevOptions.map((option) => option.value));
    } else {
      setSelectedStatesChecked(true);
      if (previouslySelected.length) updateStates(previouslySelected);
    }
  }, [allStatesChecked]);

  const handleStateSelect = (state: string) => {
    const currentlySelected = [...states];
    if (currentlySelected.includes(state)) {
      currentlySelected.splice(currentlySelected.indexOf(state), 1);
    } else {
      currentlySelected.push(state);
    }
    updateStates(currentlySelected);
  };

  return (
    <>
      {isFirstRuleOption && <LogicBlockHeader>In States...</LogicBlockHeader>}
      <StatesLogicBlock
        isSelected={isSelected}
        onFocus={() => updateLogicBlock("states")}
        onClick={() => updateLogicBlock("states")}
      >
        <FlexDiv>{!isFirstRuleOption && <LogicBlockHeader>In States...</LogicBlockHeader>}</FlexDiv>
        <HeaderButtons>
          <Checkbox
            checked={allStatesChecked}
            ariaLabel="All States"
            handleClick={() => {
              if (!allStatesChecked) {
                setPreviouslySelected(states);
              }
              setAllStatesChecked(true);
            }}
            name="All States"
            label="All States"
          />
          <Checkbox
            checked={selectedStatesChecked}
            ariaLabel="Selected States"
            handleClick={() => {
              setAllStatesChecked(false);
              setSelectedStatesChecked(true);
            }}
            name="Selected States"
            label="Selected States"
          />

          <ClearButton onClick={() => updateStates([])}>Clear States</ClearButton>
        </HeaderButtons>
        <StatesContainer>
          {stateAbbrevOptions.map((option) => {
            return (
              <StateButton selected={states?.includes(option.value)} onClick={() => handleStateSelect(option.value)}>
                {option.value}
              </StateButton>
            );
          })}
        </StatesContainer>
        <DeleteLogicBlockButtonContainer>
          <DeleteButton
            ariaLabel="delete states"
            isDisabled={!isSelected}
            onClick={() => deleteBlock("states")}
          ></DeleteButton>
        </DeleteLogicBlockButtonContainer>
      </StatesLogicBlock>
    </>
  );
};

const ClearButton = styled.button`
  background: none;
  color: ${(props) => `${props.theme.blue}`};
  font-weight: bold;
  margin-left: auto;
`;

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
`;

const StatesLogicBlock = styled(RuleLogicBlock)`
  flex-direction: column;
`;

const StatesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 8px 8px;
`;

const StateButton = styled.button<{ selected: boolean }>`
  height: 32px;
  background: ${(props) => (props.selected ? props.theme.blue10 : props.theme.white)};
  color: ${(props) => props.theme.fontColors?.primary};
  border: ${(props) => `1px solid ${props.theme.blue}`};
  border-radius: 4px;
`;

export default StatesBlock;
