import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import styled from "styled-components";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { reportToSentry } from "utils/sentry";
import { reportPaymentError, reportStripeError } from "utils/reportError";
import { toastError } from "utils/toast";
import formotivInstance from "utils/formotiv";
import updateApplicationFromServer from "redux/actions/updateApplicationFromServer";
import { selectIsApplicationLoading, selectIsApplicationUpdating } from "redux/selectors/application";
import { updateApplicationFormStartDate } from "redux/reducers/_applicationReducer";
import { getSnapshotProducerId, setSnapshotPolicies, getSnapshotPartnerId } from "redux/reducers/_snapshotReducer";
import { selectProducerExternalId } from "redux/selectors/user";
import {
  selectUnderwritingProfileIsLoading,
  selectUnderwritingProfilePaymentFrequency,
} from "redux/selectors/underwritingProfile";
import { PaymentFrequency, PolicyType } from "types/enums";
import useAuth from "hooks/useAuth";
import { usePostBindQuote } from "hooks/usePostBindQuote";
import useGetAgencyProducers from "hooks/useGetAgencyProducers";
import useGetLegacyApplication from "hooks/useGetLegacyApplication";
import { GreenButton } from "styles";
import { ErrorText } from "styles/Input";
import Modal from "shared/Modal";
import { ModalContentContainer, ModalField, ModalSubtitle } from "shared/ModalComponents";
import { CTAButtonContainer, GhostButton } from "shared/SimplyBind/styles";
import MissingInfoModal from "shared/SimplyBind/MissingInfoModal";
import RequoteModal from "shared/ApplicationSearch/RequoteModal";
import { BusinessName, Subtitle } from "../../application/StyledComponents";
import PaymentDetailsInputs from "./DetailsInputs";
import { selectIsValidPolicyStartDate } from "redux/selectors/global";
import { formatCurrency } from "utils/formatNumber";
import useFees from "hooks/useFees";
import { addSource } from "utils/addSource";
import { useIsFetching } from "@tanstack/react-query";
import AjaxLoader from "shared/AjaxLoader";

declare global {
  interface Window {
    Accept: any;
  }
}

interface PropTypes {
  application: Application;
  closeModal?: () => void;
  modalIsOpen?: boolean;
  quote: BindableQuote;
  updateApplicationFromServer: (arg0: APIVersionAuth) => void;
  updateApplicationFormStartDate: (arg0: Application) => void;
  isSimplybind?: boolean;
  paymentFrequency?: PaymentFrequency;
  setSnapshotPolicies: (policies: Policy[]) => void;
  quoteIsUpdating: boolean;
  isSnapshot: boolean;
  channelPartnerPublishableKey: string;
  producerExternalId: string;
  isValidPolicyStartDate: boolean;
  simplyBindPaymentFrequency: PaymentFrequency;
}

const StripePayment = ({
  closeModal,
  modalIsOpen,
  quote,
  application,
  isSimplybind = false,
  paymentFrequency,
  setSnapshotPolicies,
  quoteIsUpdating,
  isSnapshot,
  channelPartnerPublishableKey,
  producerExternalId,
  isValidPolicyStartDate,
  simplyBindPaymentFrequency,
}: PropTypes) => {
  const location = useLocation();
  const history = useHistory();
  const isLegacyQuote = new URLSearchParams(location.search).get("legacy") === "true";
  const { isIAUser, isDeveloper } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const isFetching = useIsFetching();

  const [sendDocs, setDocs] = useState<boolean>(true);
  const [frequency, setFrequency] = useState<PaymentFrequency>(paymentFrequency ?? simplyBindPaymentFrequency);
  const [bindingQuote, setBindingQuote] = useState(false);
  const [producerId, setProducerId] = useState(producerExternalId);
  const [errorMessage, setErrorMessage] = useState("");
  const [allTermsChecked, setAllTermsChecked] = useState(!isSimplybind);
  const [snapshotPlReviewChecked, setSnapshotPlReviewChecked] = useState(false);
  const [showMissingInfoModal, setShowMissingInfoModal] = useState(false);
  const [showRequoteModal, setShowRequoteModal] = useState(false);
  const [stateRequirementsIsChecked, setStateRequirementsIsChecked] = useState(false);

  const {
    businessName,
    legalBusinessName,
    mailingAddressStreet,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZip,
    applicationId,
  } = application;
  const { monthlyPremium, premium } = quote;
  const hasPL = application.applicationTypes?.includes(PolicyType.PL);

  const { month1Owed, totalYearlyOwed } = useFees(isSimplybind);

  const showTotalDue = () => {
    const dueAtBind = frequency === PaymentFrequency.Monthly ? month1Owed : totalYearlyOwed;
    return formatCurrency(dueAtBind);
  };

  const {
    data: legacyApplication,
    isLoading: isLoadingLegacyApplication,
    isSuccess: isSuccessLegacyApplication,
  } = useGetLegacyApplication(applicationId ?? "", {
    enabled: !isIAUser && Boolean(applicationId) && !isSnapshot,
  });

  useEffect(() => {
    if (isSuccessLegacyApplication) {
      setProducerId(legacyApplication?.application?.iaDetails?.producerExternalID ?? "");
    }
  }, [isSuccessLegacyApplication, legacyApplication?.application?.iaDetails?.producerExternalID]);

  const { mutate: postBindRequest } = usePostBindQuote({
    onSuccess: (response: any) => {
      if (response.isSuccess) {
        if (!isSnapshot) {
          const policyNumbers = response.policies.map((p: Policy) => p.policyNumber);
          const bindSuccessUrl = isIAUser
            ? `/policies/confirmation?policyIds=${policyNumbers.join(",")}`
            : `/policies/${response.policies[0].policyNumber}`;
          history.push(bindSuccessUrl);
        } else {
          history.push(`/snapshot/${application.applicationId}/policy`);
          setSnapshotPolicies(response.policies);
          if (closeModal) {
            closeModal();
          }
        }
      } else {
        reportToSentry("Failed to Bind!", {
          name: "bind quote error",
          data: {
            quote,
            application: application,
            response: JSON.stringify(response),
            errors: JSON.stringify(response.errors),
          },
        });
        const errorResponse = response.errors[0]?.message || response.errors[0];
        toastError(`Failed to Bind! ${errorResponse ? `- ${errorResponse}` : ""}`);
      }
      setBindingQuote(false);
    },
    onError: (error: any) => {
      reportToSentry("Failed to Bind!", {
        name: "bind quote error",
        data: {
          application,
          quote,
          errors: JSON.stringify(error.errors),
        },
      });
      if (error?.response?.data?.message?.includes("Agent with NPN")) {
        toastError("Our records indicate that you do not hold an active license in this state.");
      } else {
        reportPaymentError(error);
      }
      setBindingQuote(false);
    },
  });

  const cancelBind = () => {
    if (closeModal) {
      closeModal();
    }
  };

  const createStripeToken = async () => {
    setBindingQuote(true);
    const cardElement = elements && elements.getElement("cardNumber");
    if (stripe && cardElement) {
      const { token, error } = await stripe.createToken(cardElement);
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setErrorMessage(error.message || "");
        } else {
          reportStripeError(error);
        }
        setBindingQuote(false);
      }
      if (token) {
        const quoteId = quote.externalId ?? quote.parentExternalId;
        formotivInstance.submitFinal();
        bindQuote(quoteId, token.id, isSnapshot);
      }
    } else {
      reportStripeError("Stripe Failed To initialize", "An issue has occurred");
    }
  };

  const bindQuote = async (quoteId: any, tokenizedPaymentId: string, isSnapshot: boolean) => {
    const sourceData = addSource({ isDashboard: isIAUser });
    const bindArguments = {
      quoteId,
      tokenizedPaymentId,
      paymentInterval: frequency,
      sendDocs,
      isSnapshot,
      producerId,
    };
    const publicBindArguments = { ...bindArguments, producerExternalId, channelPartnerPublishableKey };
    const bindCallArguments = isSnapshot ? publicBindArguments : { ...bindArguments, ...sourceData };

    // TODO - asserting to any as a temp fix for the issue with the typescript inference
    return postBindRequest(bindCallArguments as any);
  };

  const { isLoading: isLoadingProducers } = useGetAgencyProducers({ enabled: !isSnapshot });

  const canProceedToBindStepDisabled =
    !allTermsChecked ||
    isLoadingProducers ||
    !isValidPolicyStartDate ||
    producerId === "" ||
    (!isSimplybind && hasPL && !snapshotPlReviewChecked) ||
    bindingQuote ||
    quoteIsUpdating ||
    (!isSimplybind && !stateRequirementsIsChecked);

  const payButtonIsLoading = quoteIsUpdating || isLoadingLegacyApplication || bindingQuote;

  return (
    <>
      {isSimplybind ? (
        <>
          {showMissingInfoModal && (
            <MissingInfoModal
              onClose={() => setShowMissingInfoModal(false)}
              onError={() => {
                setShowMissingInfoModal(false);
                setShowRequoteModal(true);
              }}
              applicationId={application.applicationId}
              application={application}
            />
          )}
          {showRequoteModal && <RequoteModal onClose={() => setShowRequoteModal(false)} />}
          <PaymentDetailsInputs
            frequency={frequency}
            setFrequency={setFrequency}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            sendDocs={sendDocs}
            setDocs={setDocs}
            isSimplybind={true}
            quote={quote}
            isSnapshot={isSnapshot}
            setAllTermsChecked={setAllTermsChecked}
            handleBindAsChange={setProducerId}
            application={application}
            stateRequirementsIsChecked={stateRequirementsIsChecked}
          />

          <CTAButtonContainer>
            <GhostButton
              onClick={() => {
                if (isLegacyQuote) {
                  setShowMissingInfoModal(true);
                } else {
                  history.push(`/simplybind/quote/${application.applicationId}`);
                }
              }}
              dataCy="back-button"
            >
              Back
            </GhostButton>
            <GreenButton disabled={canProceedToBindStepDisabled} onClick={createStripeToken}>
              {payButtonIsLoading ? <Spinner color="light" /> : <span>Pay</span>}
            </GreenButton>
          </CTAButtonContainer>
        </>
      ) : (
        <Modal
          isOpen={Boolean(modalIsOpen)}
          closeModal={cancelBind}
          label="bind-payment"
          title="Buy Policy"
          buttonText="Buy Policy"
          onSubmit={createStripeToken}
          loading={bindingQuote}
          disabled={canProceedToBindStepDisabled}
        >
          <StyledModalSubtitle>
            <div>
              <QuoteForHeader>QUOTE FOR</QuoteForHeader>
              <BusinessName>{businessName ?? legalBusinessName}</BusinessName>
              <Subtitle>{mailingAddressStreet}</Subtitle>
              <Subtitle>{`${mailingAddressCity}, ${mailingAddressState} ${mailingAddressZip}`}</Subtitle>
              {isDeveloper && !producerId && (
                <ErrorText>Quote does not have producer information and cannot be bound</ErrorText>
              )}
            </div>
            {isFetching ? (
              <AjaxLoader />
            ) : (
              <>
                {monthlyPremium && frequency !== PaymentFrequency.None && (
                  <PremiumBox>
                    <PremiumHeader>
                      <PremiumText>Total due at bind</PremiumText>
                    </PremiumHeader>
                    <LargePayment data-cy="total-due-amount">{showTotalDue()}</LargePayment>
                  </PremiumBox>
                )}

                {premium && frequency === PaymentFrequency.None && (
                  <PremiumBox>
                    <PremiumHeader>
                      <PremiumText>Total due at bind</PremiumText>
                    </PremiumHeader>
                    <LargePayment data-cy="total-due-amount">{showTotalDue()}</LargePayment>
                  </PremiumBox>
                )}
              </>
            )}
          </StyledModalSubtitle>
          <ModalContentContainer>
            <PaymentDetailsInputs
              frequency={frequency}
              setFrequency={setFrequency}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              sendDocs={sendDocs}
              setDocs={setDocs}
              quote={quote}
              isSnapshot={isSnapshot}
              setPlReviewChecked={setSnapshotPlReviewChecked}
              plReviewChecked={snapshotPlReviewChecked}
              handleBindAsChange={setProducerId}
              setAllTermsChecked={setAllTermsChecked}
              application={application}
              stateRequirementsIsChecked={stateRequirementsIsChecked}
              setStateRequirementsIsChecked={setStateRequirementsIsChecked}
            />
          </ModalContentContainer>
        </Modal>
      )}
    </>
  );
};

const LargePayment = styled.h2`
  color: ${(props) => props.theme.blue};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 30px;
  margin: 0px;
  font-weight: 600;
  line-height: 34px;
  background: ${(props) => props.theme.white};
  padding: 8px 32px;
`;

const PremiumBox = styled.div`
  float: right;
  display: inline-block;
`;

const PremiumHeader = styled.div`
  background: ${(props) => props.theme.bgDarkBlue};
  height: 28px;
  border-radius: 2px 2px 0 0;
`;

const PremiumText = styled(ModalField)`
  color: ${(props) => props.theme.white};
  text-align: center;
  padding-top: 6px;
  font-size: 13px;
`;

const QuoteForHeader = styled.p`
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1.18px;
  font-family: ${(props) => props.theme.primaryFont};
  color: ${(props) => props.theme.fontColors.primary};
  margin: 0;
`;

const StyledModalSubtitle = styled(ModalSubtitle)`
  padding: 20px 25px;
`;

const mapDispatchToProps = {
  updateApplicationFromServer,
  updateApplicationFormStartDate,
  setSnapshotPolicies,
};

const mapStateToProps = (state: ReduxState) => ({
  quoteIsUpdating:
    selectIsApplicationLoading(state) ||
    selectIsApplicationUpdating(state) ||
    selectUnderwritingProfileIsLoading(state),
  isSnapshot: Boolean(getSnapshotProducerId(state)),
  channelPartnerPublishableKey: getSnapshotPartnerId(state),
  producerExternalId: selectProducerExternalId(state),
  isValidPolicyStartDate: selectIsValidPolicyStartDate(state),
  simplyBindPaymentFrequency: selectUnderwritingProfilePaymentFrequency(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(StripePayment);
