import React, { useState } from "react";
import styled, { css, DefaultTheme } from "styled-components";
import { Checkbox, tablet, TextLink } from "styles";
import { RedDot } from "shared/SimplyBind/styles";
import { PolicyType } from "types/enums";
import { useEffect } from "react";
import { PolicyData } from "./Coverages";
import CoverageTabPanel from "./CoverageTabPanel";
import usePrevious from "utils/usePrevious";
import { isCanadaEnv } from "utils/environmentChecker";
import { ReactComponent as CloseIcon } from "images/close.svg";
import { ReactComponent as Caret } from "images/caret_up.svg";
import DeclinationModal from "./DeclinationModal";
import { uniq } from "lodash";
import { useSelector } from "react-redux";
import { selectApplicationFormDesiredPolicyType } from "redux/selectors/application";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const TabTitle = ({ policy }: { policy: PolicyType }) => {
  const renderTitleComponents = () => {
    switch (policy) {
      case PolicyType.BOP:
        return (
          <p>
            {isCanadaEnv() ? "Commercial Package " : "Business Owner's "}
            <span>Policy</span>
          </p>
        );
      case PolicyType.GL:
        return <p>General Liability</p>;
      case PolicyType.PL:
        return <p>Professional Liability</p>;
      default:
        return null;
    }
  };
  return <TabTitleContainer>{renderTitleComponents()}</TabTitleContainer>;
};
interface CoverageTabsProps {
  selectPolicy: (policyType: PolicyType) => void;
  availablePolicyTypes: PolicyType[];
  selectedPolicyTypes: PolicyType[];
  policyData: PolicyData[];
  BOPHasError: boolean;
  PLHasError: boolean;
  GLHasError?: boolean;
  deniedCoverages: PolicyType[];
  declinationLedger: DeclinationLedger;
}

const CoverageTabs = ({
  selectPolicy,
  availablePolicyTypes,
  selectedPolicyTypes,
  policyData,
  BOPHasError,
  GLHasError,
  PLHasError,
  deniedCoverages,
  declinationLedger,
}: CoverageTabsProps) => {
  const defaultTab = () => {
    if (selectedPolicyTypes.length) return [...selectedPolicyTypes].sort()[0];
    else return availablePolicyTypes[0];
  };

  const { showHideBopOrRequote } = useFeatureFlags();

  const [activeTab, setActiveTab] = useState(defaultTab());
  const [availableTabs, setAvailableTabs] = useState<PolicyType[]>([]);
  const [activeDeclinationModal, setActiveDeclinationModal] = useState<null | PolicyType>(null);
  const previouslySelectedPolicyTypes = usePrevious(selectedPolicyTypes);
  const onlyOnePolicySelected = selectedPolicyTypes.length === 1;

  const findAvailableTabs = () => {
    const allTabs = uniq([...availablePolicyTypes, ...(deniedCoverages ?? [])]).sort();
    const GLIsDeclined = !availablePolicyTypes?.includes(PolicyType.GL);
    const BOPIsDeclined = !availablePolicyTypes?.includes(PolicyType.BOP);
    const BOPisSelected = selectedPolicyTypes.includes(PolicyType.BOP);
    const GLisSelected = selectedPolicyTypes.includes(PolicyType.GL);
    const neitherBopOrGLSelected = !BOPIsDeclined && !BOPisSelected && !GLIsDeclined && !GLisSelected;
    const BOPAvailableButGLPreviouslySelected =
      !BOPIsDeclined && previouslySelectedPolicyTypes?.includes(PolicyType.GL);

    return allTabs.filter((policyType) => {
      if (BOPisSelected || (neitherBopOrGLSelected && !BOPAvailableButGLPreviouslySelected) || GLIsDeclined) {
        return policyType !== PolicyType.GL;
      } else if (GLisSelected || BOPAvailableButGLPreviouslySelected || BOPIsDeclined) {
        return policyType !== PolicyType.BOP;
      } else {
        return policyType;
      }
    });
  };

  const tabHasError = (policy: PolicyType) => {
    switch (policy) {
      case PolicyType.BOP:
        return BOPHasError;
      case PolicyType.GL:
        return GLHasError;
      case PolicyType.PL:
        return PLHasError;
      default:
        return false;
    }
  };

  const handleTabKeypress = (e: React.KeyboardEvent, policy: PolicyType) => {
    if (e.key === "Enter" || e.key === "Space") {
      setActiveTab(policy);
    }
  };

  useEffect(() => {
    setAvailableTabs(findAvailableTabs());
    setActiveTab(defaultTab());
  }, [JSON.stringify(selectedPolicyTypes)]);

  useEffect(() => {
    setAvailableTabs(findAvailableTabs());
  }, [JSON.stringify(availablePolicyTypes)]);

  const desiredPolicyTypes = useSelector(selectApplicationFormDesiredPolicyType);

  // if desired policy type is ['PL'] and available policy types includes 'BOP' then don't show BOP
  const dontShowBOP =
    desiredPolicyTypes.includes(PolicyType.PL) &&
    !desiredPolicyTypes.includes(PolicyType.BOP) &&
    availablePolicyTypes.includes(PolicyType.BOP);

  const filteredAvailableTabs = availableTabs.filter((tab) => {
    if (dontShowBOP && tab === PolicyType.BOP) {
      return false;
    }
    return true;
  });

  const tabsOnPage = showHideBopOrRequote ? filteredAvailableTabs : availableTabs;

  return (
    <Container data-cy="coverage-tabs">
      <TabContainer>
        {tabsOnPage.map((policyType) => {
          const policyTabData = policyData.find((policyTabData) => policyTabData.policy === policyType);
          if (policyTabData) {
            const isDeclined = deniedCoverages?.includes(policyTabData?.policy);
            return (
              <Tab
                key={policyTabData.policy}
                policyType={policyTabData.policy}
                policyTypeSelected={selectedPolicyTypes.includes(policyTabData.policy)}
                tabSelected={activeTab === policyTabData?.policy}
                tabIndex={isDeclined ? -1 : 0}
                aria-label={`See ${policyTabData?.title} details`}
                onClick={() => {
                  if (isDeclined) return;
                  setActiveTab(policyTabData.policy);
                }}
                onKeyPress={(e) => handleTabKeypress(e, policyTabData.policy)}
              >
                {isDeclined ? (
                  <CloseIconContainer>
                    <CloseIcon width={10} height={10} />
                  </CloseIconContainer>
                ) : (
                  <Checkbox
                    key={policyTabData.policy}
                    id="quote-policy-type-checkbox"
                    ariaLabel={`Select ${policyTabData.title} Coverage`}
                    handleClick={() => selectPolicy(policyTabData.policy)}
                    name={`${policyTabData.policy}`}
                    checked={selectedPolicyTypes.includes(policyTabData.policy)}
                    readOnly={onlyOnePolicySelected && policyTabData.policy === selectedPolicyTypes[0]}
                    readOnlyAlertText={
                      "Cannot deselect all coverages. Please make sure at least one coverage remains selected in order to continue this quote."
                    }
                  />
                )}
                <TabTitle policy={policyTabData.policy} />
                {tabHasError(policyTabData.policy) && (
                  <TabErrorRedDot data-testid={`${policyTabData.policy}-error-indicator`} />
                )}
                {isDeclined ? (
                  <ViewDeclineButton onClick={() => setActiveDeclinationModal(policyTabData.policy)}>
                    View Decline
                    <span aria-hidden>
                      <StyledCaret />
                    </span>
                  </ViewDeclineButton>
                ) : (
                  !isCanadaEnv() && (
                    <TabImage
                      isGreyscale={!selectedPolicyTypes.includes(policyTabData.policy)}
                      src={policyTabData.image}
                      alt=""
                    ></TabImage>
                  )
                )}
              </Tab>
            );
          } else {
            return null;
          }
        })}
      </TabContainer>
      <TabPanelsWrapper>
        {policyData
          .filter((policyTabData) => tabsOnPage.includes(policyTabData.policy))
          .map((policyTabData, i) => {
            return (
              <CoverageTabPanel
                key={policyTabData.policy}
                policyData={policyTabData}
                availablePolicyTypes={availablePolicyTypes}
                selectedPolicyTypes={selectedPolicyTypes}
                handleSelectPolicy={(policy: PolicyType) => {
                  selectPolicy(policy);
                  setActiveTab(policy);
                }}
                isActiveTab={policyTabData.policy === activeTab}
                tabNumber={tabsOnPage.indexOf(activeTab)}
                deniedCoverages={deniedCoverages}
                setActiveDeclinationModal={setActiveDeclinationModal}
              />
            );
          })}
      </TabPanelsWrapper>
      {activeDeclinationModal && (
        <DeclinationModal
          policyData={policyData.find((policyTabData) => policyTabData.policy === activeDeclinationModal)!}
          closeModal={() => setActiveDeclinationModal(null)}
          declinationMessages={declinationLedger.data[activeDeclinationModal].value}
        />
      )}
    </Container>
  );
};

const tabBackgroundColor = (theme: DefaultTheme, selected: boolean, policyType?: PolicyType) => {
  if (!selected) {
    return theme.disabledBackground;
  } else if (policyType === PolicyType.BOP) {
    return theme.yellow30;
  } else if (policyType === PolicyType.GL) {
    return theme.green80;
  } else if (policyType === PolicyType.PL) {
    return theme.orange25;
  }
};

const TabImage = styled.img<{ isGreyscale: boolean }>`
  width: 60px;
  align-self: flex-end;
  margin-left: auto;
  right: 10px;
  z-index: 2;
  position: relative;
  bottom: 1px;
  ${(props) => props.isGreyscale && `{filter: grayscale(100%);}`}
  ${tablet} {
    display: none;
  }
`;

const TabPanelsWrapper = styled.div`
  border: ${(props) => `1px solid ${props.theme.blue}`};
  border-radius: 0 0 4px 4px;
  display: flex;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  max-width: 1044px;
  width: 100%;
`;

const Tab = styled.div<{ tabSelected: boolean; policyType?: PolicyType; policyTypeSelected: boolean }>`
  max-width: 50%;
  width: 100%;
  height: 56px;
  font-size: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  background: ${(props) => tabBackgroundColor(props.theme, props.policyTypeSelected, props.policyType)};
  ${(props) =>
    props.tabSelected &&
    css`
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      border: ${(props: any) => `1px solid ${isCanadaEnv() ? props.theme.black : props.theme.blue}`};
      border-radius: 4px 4px 0 0;
      border-bottom: none;
      position: relative;
      :after {
        content: "";
        width: 100%;
        position: absolute;
        height: 4px;
        background: ${(props: any) => tabBackgroundColor(props.theme, props.policyTypeSelected, props.policyType)};
        left: 0;
        bottom: -2px;
      }
    `}
  ${tablet} {
    height: 76px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  column-gap: 20px;
`;

const TabTitleContainer = styled.span`
  font-weight: 600;
  p {
    margin: 0;
  }
  ${tablet} {
    max-width: 50%;
    span {
      display: none;
    }
  }
`;

const TabErrorRedDot = styled(RedDot)`
  margin-bottom: 6px;
  margin-left: 15px;
  ${tablet} {
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: 25px;
  }
`;

const CloseIconContainer = styled.div`
  background: ${(props) => props.theme.red20};
  width: 26px;
  display: flex;
  align-items: center;
  height: 26px;
  border-radius: 50%;
  justify-content: center;
  margin: 0 13px;
  svg path {
    fill: ${(props) => props.theme.red};
  }
`;

const ViewDeclineButton = styled(TextLink)`
  color: ${(props) => props.theme.red};
  margin-left: auto;
  margin-right: 1em;
  :hover {
    color: ${(props) => props.theme.red};
  }
`;

const StyledCaret = styled(Caret)`
  transform: rotate(90deg);
  margin-left: 8px;
  path {
    fill: ${(props) => props.theme.red};
  }
`;

export default CoverageTabs;
