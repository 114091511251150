import React, { useState, useCallback, useRef, useContext } from "react";
import { Dropdown, Switch } from "styles";
import { Condition, RuleOptions } from "../../Shared/types";
import RuleTextInput from "./RuleTextInput";
import { debounce } from "lodash";
import { RuleLogicBlock, LogicBlockHeader, FlexDiv, FlexColumn, DeleteLogicBlockButtonContainer } from "../styles";
import { useEffect } from "react";
import { InputActionMeta } from "react-select";
import { ruleOperatorOptions } from "../constants";
import { stateAbbrevOptions } from "utils/constants";
import useGetUnderwritingRuleVariables from "hooks/underwritingRules/useGetUnderwritingVariables";
import { reportFailedFetchVariables } from "utils/reportError";
import { RuleBuilderContext } from "../RuleBuilderContext";
import { DeleteVariableModal } from "../Modals";
import styled from "styled-components";
import { arrayToDropdownOptionsList } from "../../Shared/utils";
import DeleteButton from "../DeleteButton";
import LookinSourceDropdown from "./LookinSourceDropdown";

interface OptionType {
  value: string | number;
  label: string;
}

interface ConditionBlockProps {
  ruleOption: RuleOptions;
  variable: string;
  isDisabled: boolean;
}

const ConditionBlock = ({ ruleOption, variable, isDisabled }: ConditionBlockProps) => {
  const [variableResults, setVariableResults] = useState<OptionType[] | null>();
  const [variableSearch, setVariableSearch] = useState<string>("");
  const {
    updateVariable,
    updateLogicBlock,
    updateConditionTarget,
    updateConditionOperator,
    deleteBlock,
    deleteMultiCondition,
    toggleRuleDisabledState,
    activeRulePath,
    activeLogicBlock,
  } = useContext(RuleBuilderContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const isSelected = ruleOption.path === activeRulePath && activeLogicBlock === "condition";
  const isFirstRuleOption = ruleOption.path === "options.0";

  const { data: variableSearchResults, error } = useGetUnderwritingRuleVariables(variableSearch, {
    enabled: Boolean(variableSearch),
  });

  useEffect(() => {
    if (error) {
      reportFailedFetchVariables(error);
    }
  }, [error]);

  useEffect(() => {
    if (variableSearchResults && variableSearch) {
      setVariableResults(arrayToDropdownOptionsList(variableSearchResults) ?? []);
    }
  }, [variableSearchResults]);

  useEffect(() => {
    if (!variable) {
      setVariableResults(null);
      setVariableSearch("");
    } else {
      setVariableResults([{ value: variable, label: variable }]);
    }
  }, [variable]);

  const debounceVariableSearch = useCallback(
    debounce((search) => setVariableSearch(search), 300),
    []
  );

  const shouldRenderDropdown = ruleOption.path.split(".").length > 2 || ruleOption.path === "options.0";

  const conditionTargetIsTextInput = (conditionObj: Condition) => {
    return !["not in states", "lookin", "not lookin"].includes(conditionObj.conditionOperator);
  };

  return (
    <>
      {isFirstRuleOption && <LogicBlockHeader>If...</LogicBlockHeader>}
      <RuleLogicBlock
        isSelected={isSelected}
        onFocus={() => updateLogicBlock("condition")}
        onClick={() => updateLogicBlock("condition")}
        ref={containerRef}
      >
        <FlexDiv>{!isFirstRuleOption && <LogicBlockHeader>If...</LogicBlockHeader>} </FlexDiv>
        <FlexDiv>
          <FlexColumn onClick={(e) => e.stopPropagation()}>
            {shouldRenderDropdown ? (
              <Dropdown
                isClearable
                options={variableResults ?? []}
                placeholder="variable, eg: roofAge"
                name="variable"
                label="variable"
                labelIsHidden
                onChange={(selection) => {
                  if (selection?.value !== variable) {
                    updateVariable(selection?.value ?? "");
                    if (containerRef && containerRef.current) {
                      containerRef.current.scrollIntoView();
                    }
                  }
                }}
                onInputChange={(value: string, changeEvent?: InputActionMeta) => {
                  if (changeEvent?.action === "input-change") {
                    debounceVariableSearch(value);
                  }
                }}
                value={variable ?? ""}
              />
            ) : (
              <StaticVariable>{variable}</StaticVariable>
            )}

            {ruleOption.conditions && ruleOption.conditions?.length > 1 && (
              <MultiConditionIndicators>
                <AggregationIndicator>{ruleOption.aggregationType}</AggregationIndicator>
                {ruleOption.isNegated && <NegationIndicator data-testid="negation-indicator">not</NegationIndicator>}
              </MultiConditionIndicators>
            )}
          </FlexColumn>
          {ruleOption.isNegated && ruleOption.conditions && ruleOption.conditions?.length <= 1 && (
            <NegationIndicator data-testid="negation-indicator">not</NegationIndicator>
          )}
          <div>
            {ruleOption.conditions?.map((conditionObj, index) => (
              <ConditionInputsContainer key={ruleOption.path + "conditions" + index}>
                <Dropdown
                  value={conditionObj.conditionOperator ?? ""}
                  options={ruleOperatorOptions}
                  name="conditionOperator"
                  label="condition operator"
                  labelIsHidden
                  onChange={(selection) => {
                    updateConditionOperator(selection.value, index);
                  }}
                />
                {conditionObj.conditionOperator !== "notnull" && (
                  <>
                    {conditionObj.conditionOperator === "not in states" && (
                      <ConditionTargetContainer>
                        <Dropdown
                          label="not in states"
                          labelIsHidden
                          options={stateAbbrevOptions}
                          isMulti
                          multiValues={stateAbbrevOptions.filter((opt) =>
                            conditionObj.conditionTarget
                              ?.split(",")
                              .map((s) => s.trim())
                              .includes(opt.value)
                          )}
                          onChange={(selection) => {
                            updateConditionTarget(
                              selection?.map((option: OptionType) => option.value).join(", ") ?? "",
                              index
                            );
                          }}
                        />
                      </ConditionTargetContainer>
                    )}
                    {["lookin", "not lookin"].includes(conditionObj.conditionOperator) && (
                      <LookinSourceDropdown conditionIndex={index} conditionObj={conditionObj} />
                    )}
                    {conditionTargetIsTextInput(conditionObj) && (
                      <RuleTextInput
                        type={
                          ruleOperatorOptions.find((option) => option.value === conditionObj.conditionOperator)
                            ?.inputType
                        }
                        defaultValue={conditionObj.conditionTarget ?? ""}
                        name={`conditionTarget-${ruleOption.path}-${index}`}
                        updateKey="conditionTarget"
                        inputStyles={{ marginBottom: 8, marginLeft: 8 }}
                        updateRuleProperty={(conditionTarget: string) => updateConditionTarget(conditionTarget, index)}
                        updatePropertyIndex={index}
                        label="condition target"
                      />
                    )}
                  </>
                )}
                {index > 0 && (
                  <DeleteMultiConditionButtonContainer>
                    <DeleteButton ariaLabel="delete condition" onClick={() => deleteMultiCondition(index)} />
                  </DeleteMultiConditionButtonContainer>
                )}
              </ConditionInputsContainer>
            ))}
          </div>

          <ToggleContainer>
            <Switch
              onChange={() => {
                if (isSelected) toggleRuleDisabledState();
              }}
              checked={!isDisabled}
              ariaLabel="Activate rule"
              dataTestId="activate-rule"
            />
          </ToggleContainer>
        </FlexDiv>
        {ruleOption.path !== "options.0" && (
          <DeleteLogicBlockButtonContainer>
            <DeleteButton
              ariaLabel="delete condition block"
              isDisabled={!isSelected}
              onClick={() => setDeleteModalIsOpen(true)}
            />
          </DeleteLogicBlockButtonContainer>
        )}
      </RuleLogicBlock>
      <DeleteVariableModal
        isOpen={deleteModalIsOpen}
        setIsOpen={setDeleteModalIsOpen}
        handleSubmit={() => deleteBlock("condition")}
      />
    </>
  );
};

const NegationIndicator = styled.p`
  margin: 0 5px;
  font-weight: bold;
`;

const AggregationIndicator = styled.p`
  margin: 0;
`;

const MultiConditionIndicators = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ConditionTargetContainer = styled.div`
  margin-left: 8px;
`;

const ConditionInputsContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ToggleContainer = styled.span`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const StaticVariable = styled.p`
  margin: 16px 0 0 8px;
  display: flex;
  align-self: center;
`;

const DeleteMultiConditionButtonContainer = styled.span`
  margin: 1em 0 0 1em;
`;

export default ConditionBlock;
