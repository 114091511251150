import React, { ChangeEvent, useState, useEffect, useCallback } from "react";
import { History } from "history";
import debounce from "lodash.debounce";
import styled from "styled-components";
import AccountSearchTable from "./AccountSearchTable";
import { AccountSortBy, SortDirection } from "types/enums";
import AjaxLoader from "shared/AjaxLoader";
import {
  LoadingDiv,
  SearchInput,
  SearchContainer,
  SearchIconWrapper,
  Title,
  TitleBarAccessory,
  TitleContainer,
  ContentContainer,
} from "styles/containerStyledComponents";
import { ReactComponent as SearchIcon } from "images/search_icon.svg";
import { Breadcrumb, Dropdown } from "styles";
import useGetAccountPolicies from "hooks/useGetAccountPolicies";
import { reportError } from "utils/reportError";
import useGetAgencyProducers, { GetAgencyProducersResponse } from "hooks/useGetAgencyProducers";
import { useSelector } from "react-redux";
import { getUserExternalId } from "redux/selectors/user";
import { useFlags } from "launchdarkly-react-client-sdk";

interface PropTypes {
  history: History;
  searchTerm: string;
}

// in the UI, this shows as "policy search" as an IA
const AccountSearch = ({ history }: PropTypes) => {
  const { showNonLicensedUserLink } = useFlags();
  const params = new URLSearchParams(window.location.search);
  const externalId = useSelector(getUserExternalId);
  const q = params.get("q") ?? "";
  const [producerId, setProducerId] = useState(externalId);
  const [searchInput, setSearchInput] = useState(q);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(q);
  const [sortBy, setSortBy] = useState(AccountSortBy.Name);
  const [sortDirection, setSortDirection] = useState(SortDirection.Descending);
  const { data: accounts, isLoading, error } = useGetAccountPolicies({
    searchText: debouncedSearchQuery,
    sortBy,
    sortDirection,
    producerId,
  });
  const { data: producers } = useGetAgencyProducers({});

  useEffect(() => {
    if (error) {
      reportError(error);
    }
  }, [error])

  const searchForAccountOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target) {
      const target = e.target as HTMLInputElement;
      setSearchInput(target.value);

      history.replace({
        pathname: "/accounts",
        search: target.value.length > 0 ? "?q=" + target.value : "",
      });
    }
  };

  const debounceSearchForAccounts = useCallback(
    debounce((searchInput) => setDebouncedSearchQuery(searchInput), 500),
    []
  );

  const sortAccounts = (sortBy: AccountSortBy, sortDirection: SortDirection) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  const handleFilterOnChange = (producerOption: GetAgencyProducersResponse) => {
    const isClearing = producerOption === null;
    setProducerId(isClearing ? externalId : producerOption.value);
  };

  useEffect(() => {
    debounceSearchForAccounts(searchInput);
  }, [searchInput]);

  return (
    <ContentContainer data-cy="policy-search">
      <StyledBreadcrumb>Policies</StyledBreadcrumb>
      <TitleContainer>
        <Title>Policies</Title>
        <TitleBarAccessory>
          <StyledLoadingDiv>{isLoading ? <AjaxLoader /> : <span />}</StyledLoadingDiv>
          <SearchContainer>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <SearchInput
              autoFocus
              data-cy="policy-search-input"
              name="searchInput"
              type="text"
              value={searchInput}
              placeholder="Search Policies"
              onChange={searchForAccountOnChange}
            />
          </SearchContainer>
          {showNonLicensedUserLink && (
            <DropdownWrapper>
              {(producers as any[])?.length > 0 && (
                <>
                  <DropdownLabel htmlFor="policiesFilter-dropdown">Filter by Users</DropdownLabel>
                  <Dropdown
                    onChange={handleFilterOnChange}
                    options={producers as any[]}
                    value={producerId}
                    name="policiesFilter"
                    labelIsHidden
                    isClearable
                  />
                </>
              )}
            </DropdownWrapper>
          )}
        </TitleBarAccessory>
      </TitleContainer>
      {Array.isArray(accounts) && (
        <AccountSearchTable
          accounts={accounts}
          search={searchInput}
          sortBy={sortBy}
          sortDirection={sortDirection}
          changeSort={sortAccounts}
        />
      )}
    </ContentContainer>
  );
};

const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 20px;
`;

export const DropdownWrapper = styled.div`
  align-items: flex-end;
  flex-direction: column;
  width: 255px;
  margin-left: auto;
  margin-top: 16px;
  z-index: 2;
  position: relative;
`;

export const DropdownLabel = styled.label`
  color: ${(props) => props.theme.textNavyBlue};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.75px;
  line-height: 16px;
`;

export const StyledLoadingDiv = styled(LoadingDiv)`
  top: -10%;
`;

export default AccountSearch;
