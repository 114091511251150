import React, { useState } from "react";
import styled from "styled-components";
import { CardBody } from "reactstrap";
import { connect } from "react-redux";
import { toastSuccess } from "utils/toast";
import { Header4, GreenButton } from "styles";
import { Wrapper } from "styles/IAStyledComponents";
import { ReactComponent as EditIcon } from "images/edit.svg";
import EditAccountModal from "./EditAccountModal";
import { getUser } from "redux/reducers/_userReducer";
import { selectUserAccount } from "redux/selectors/user";
import Field from "shared/Field";
import { Row, EditButton } from "shared/application/StyledComponents";
import useAPI from "hooks/useAPI";
import useUpdateCurrentUserInfo from "hooks/useUpdateCurrentUserInfo";

interface PropTypes {
  getUser: (auth: APIVersionAuth) => void;
  account: AccountType;
}

const defaultAccount = { id: "", firstName: "", lastName: "", email: "" };

const Account = ({ account = defaultAccount, getUser }: PropTypes) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [firstName, setFirstName] = useState<string | undefined>(account.firstName ?? undefined);
  const [lastName, setLastName] = useState<string | undefined>(account.lastName ?? undefined);
  const [email, setEmail] = useState<string | undefined>(account.email ?? undefined);

  const { mutate: saveCurrentUserInfo, isPending: editing } = useUpdateCurrentUserInfo(account.id.toString());

  const { callback: requestPasswordReset, loading: requestingReset } = useAPI("requestPasswordReset", [account.id]);

  const handleSubmit = (fName: string, lName: string, newEmail: string) => {
    const updatedFirstName = fName === firstName ? undefined : fName;
    const updatedLastName = lName === lastName ? undefined : lName;
    const updatedEmail = newEmail === email ? undefined : newEmail;

    saveCurrentUserInfo(
      {
        firstName: updatedFirstName,
        lastName: updatedLastName,
        email: updatedEmail,
      },
      {
        onError: (res: any) => {
          console.error(res);
        },
        onSuccess: () => {
          toastSuccess("User Updated");
          fName !== firstName && setFirstName(updatedFirstName);
          lName !== lastName && setLastName(updatedLastName);
          newEmail !== email && setEmail(updatedEmail);
        },
      }
    );
  };

  const resetPassword = () => {
    requestPasswordReset().then(() => {
      toastSuccess("A password reset link has been sent to your email address.");
    });
  };

  const displayModal = () => {
    setShowEditModal(true);
  };

  const closeModal = () => {
    setShowEditModal(false);
  };

  return (
    <Container>
      <Row>
        <Header4>Account Information</Header4>
        <EditButton aria-label="edit icon" data-cy="edit-account-info" onClick={displayModal}>
          <EditIconWrapper>
            <EditIcon title="edit icon" />
          </EditIconWrapper>
        </EditButton>
      </Row>
      <Wrapper data-cy="account-info-table">
        <StyledCardBody>
          <Field value={firstName} label="First Name" aria-label="First Name" data-testid="firstName" />
          <Field
            data-cy="account-last-name"
            value={lastName}
            label="Last Name"
            aria-label="Last Name"
            data-testid="lastName"
          />
          <Field value={email} data-cy="account-email" label="Email" aria-label="Account Email" data-testid="email" />
        </StyledCardBody>
        <EditAccountModal
          isLoading={editing}
          closeModal={closeModal}
          modalIsOpen={showEditModal}
          firstName={firstName ?? ""}
          lastName={lastName ?? ""}
          email={email ?? ""}
          save={handleSubmit}
        />
      </Wrapper>

      <Title>Password Reset</Title>
      <GreenButton data-cy="reset-password" onClick={resetPassword} disabled={requestingReset}>
        {requestingReset ? "requesting..." : "Reset Your Password"}
      </GreenButton>
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  account: selectUserAccount(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getUser: (auth: APIVersionAuth) => {
    dispatch(getUser(auth));
  },
});

const EditIconWrapper = styled.div`
  svg {
    fill: ${(props) => props.theme.blue};
  }
`;

const StyledCardBody = styled(CardBody)`
  padding: 0;
`;

const Container = styled.div`
  max-width: 1121px;
  width: 100%;
`;

const Title = styled(Header4)`
  padding: 16px 0;
  margin-bottom: 0px;
  border-top: 1px solid ${(props) => props.theme.lighterGray};
`;

export default connect(mapStateToProps, mapDispatchToProps)(Account);
