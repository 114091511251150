import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { PolicyType, ApplicationSource } from "types/enums";
import { Input, Label, tablet } from "styles";
import { PanelContainer } from "../StyledComponents";
import PreviousLosses from "../PreviousLosses";
import { StyledInput, FormatInput, MoneyInput, ErrorText, NumberInput } from "styles/Input";
import {
  selectApplicationFormEmployees,
  selectApplicationFormPayroll,
  selectApplicationFormSales,
  selectApplicationFormApplicationTypes,
  selectApplicationFormBusinessAge,
  selectApplicationFormLosses,
  selectApplicationFormLocation,
  selectApplicationFormIndustryId,
  selectIndustryName,
  selectApplicationQuoteErrors,
  selectApplicationFormBusinessName,
  selectApplicationFormDba,
  selectApplicationFormSquareFootage,
  selectApplicationSourceVersion,
  selectIsAPISourcedApplication,
} from "redux/selectors/application";
import {
  updateApplicationForm,
  updateApplicationFormLocation,
  updateApplicationFormAddPreviousLoss,
  updateApplicationFormRemovePreviousLoss,
  updateApplicationFormClearPreviousLoss,
  updateApplicationFormResetPreviousLosses,
} from "redux/reducers/_applicationReducer";
import { findAndFormatErrorMessage } from "utils/formatErrorString";
import AddressSelector from "shared/application/AddressSelector";
import styled, { createGlobalStyle } from "styled-components";
import RiskAttributes from "shared/SimplyBind/pages/RiskAttributes";
import { StyledSelect } from "styles/Dropdown";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";

interface PropTypes {
  industry?: string;
  updateApplicationForm: (arg0: Application) => void;
  updateApplicationFormLocation: (arg0: ApplicationLocation) => void;
  updateApplicationFormAddPreviousLoss: (claim: Claim) => void;
  updateApplicationFormRemovePreviousLoss: (index: number) => void;
  updateApplicationFormClearPreviousLoss: ActionCreatorWithoutPayload<any>;
  updateApplicationFormResetPreviousLosses: ActionCreatorWithoutPayload<any>;
  locations: any;
  numEmployees?: number;
  annualPayroll: number;
  grossAnnualSales: number;
  applicationTypes: PolicyType[];
  businessAgeInMonths?: number;
  previousLosses: any;
  industryId: number;
  industryName: string;
  invalidFields: InvalidField[];
  isSimplybind?: boolean;
  businessName: string;
  dba?: string;
  squareFootage?: number;
  isAPISourcedApplication: boolean;
}

const BusinessBasics = ({
  updateApplicationForm,
  updateApplicationFormLocation,
  updateApplicationFormAddPreviousLoss,
  updateApplicationFormRemovePreviousLoss,
  updateApplicationFormClearPreviousLoss,
  updateApplicationFormResetPreviousLosses,
  locations,
  applicationTypes,
  businessAgeInMonths,
  previousLosses,
  invalidFields,
  isSimplybind,
  isAPISourcedApplication,
  ...props
}: PropTypes) => {
  const [businessAge, setBusinessAge] = useState(Math.round((businessAgeInMonths ?? 0) / 12));
  const [businessName, setBusinessName] = useState(props.businessName);
  const [dba, setDba] = useState(props.dba);
  const [industryName, setIndustryName] = useState<string | undefined>(props.industryName);
  const [numEmployees, setNumEmployees] = useState(props.numEmployees);
  const [annualPayroll, setAnnualPayroll] = useState(props.annualPayroll);
  const [grossAnnualSales, setGrossAnnualSales] = useState(props.grossAnnualSales);
  const [squareFootage, setSquareFootage] = useState(Number(props.squareFootage));

  const { showRiskAttributes } = useFlags();

  useEffect(() => {
    setIndustryName(props.industryName);
  }, [props.industryName]);

  useEffect(() => {
    setNumEmployees(props.numEmployees);
  }, [props.numEmployees]);

  useEffect(() => {
    setAnnualPayroll(props.annualPayroll);
  }, [props.annualPayroll]);

  useEffect(() => {
    setGrossAnnualSales(props.grossAnnualSales);
  }, [props.grossAnnualSales]);

  useEffect(() => {
    setSquareFootage(Number(props.squareFootage));
  }, [props.squareFootage]);

  useEffect(() => {
    setBusinessName(props.businessName);
  }, [props.businessName]);

  useEffect(() => {
    setDba(props.dba);
  }, [props.dba]);

  const updateBusinessAge = (e: React.FormEvent<HTMLInputElement>) => {
    const calculatedAge = e.currentTarget.value === "0" ? 1 : Math.round(Number(e.currentTarget.value) * 12);
    updateApplicationForm({ businessAgeInMonths: calculatedAge });
  };

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: any = event.currentTarget;
    switch (name) {
      case "businessName":
        setBusinessName(value);
        break;
      case "dba":
        setDba(value);
        break;
      default:
        return;
    }
  };

  const handleNumberInputChange = (values: { floatValue: number }, name: string) => {
    const { floatValue } = values;
    switch (name) {
      case "annualPayroll":
        setAnnualPayroll(floatValue);
        break;
      case "grossAnnualSales":
        setGrossAnnualSales(floatValue);
        break;
      case "businessAge":
        setBusinessAge(floatValue);
        break;
      case "numEmployees":
        setNumEmployees(floatValue);
        break;
      case "squareFootage":
        setSquareFootage(floatValue);
        break;
      default:
        break;
    }
  };

  const handleBlur = (event: React.FormEvent<HTMLInputElement>) => {
    let { name, value } = event.currentTarget;
    const formattedNumberFields = ["annualPayroll", "grossAnnualSales", "squareFootage"];
    const finalValue = formattedNumberFields.includes(name) ? Number(value.replace(/[^0-9.]+/g, "")) : value;
    if (name === "squareFootage" && typeof finalValue === "number") {
      updateApplicationFormLocation({ squareFootage: finalValue });
    } else {
      updateApplicationForm({ [name]: finalValue });
    }
  };

  return (
    <PanelContainer>
      <OverrideStyle />
      <GridContainer>
        <Input
          id="quote-legal-entity-name"
          datacy="legal-entity-name"
          name="businessName"
          value={businessName}
          handleChange={handleInputChange}
          handleBlur={handleBlur}
          placeholder="Enter the legal entity name as the First Named Insured"
          label="Legal Business Name"
          toolTipContent="BUSINESS_NAME_SIMPLYBIND"
          error={Boolean(invalidFields.find((f) => f.field === "BusinessName"))}
          errorText={findAndFormatErrorMessage("BusinessName", invalidFields)}
        />
        <Input
          id="quote-dba"
          datacy="dba"
          name="dba"
          value={dba}
          handleChange={handleInputChange}
          handleBlur={handleBlur}
          placeholder="Enter business name"
          label="Doing Business As"
          toolTipContent="DOING_BUSINESS_AS_SIMPLYBIND"
          isOptional={true}
          error={Boolean(invalidFields.find((f) => f.field === "dba"))}
          errorText={findAndFormatErrorMessage("dba", invalidFields)}
        />
        <Input id="quote-industry-type" name="industryType" label="Industry Type" value={industryName} readOnly />
        <div>
          <FlexContainer>
            <Label>Business Address</Label>
          </FlexContainer>
          <AddressSelector
            id="quote-business-address"
            street={locations.street}
            city={locations.city}
            state={locations.state}
            zip={locations.zip}
            changeAddress={() => null}
            name="business"
            hideManualAddressButton
            readOnly
          />
        </div>
        <Input
          id="quote-gross-annual-sales"
          datacy="annual-sales"
          name="grossAnnualSales"
          value={grossAnnualSales}
          handleChange={(values) => handleNumberInputChange(values, "grossAnnualSales")}
          handleBlur={handleBlur}
          label="Estimated Revenue"
          prefix="$"
          error={Boolean(invalidFields.find((f) => f.field === "GrossAnnualSales"))}
          errorText={findAndFormatErrorMessage("GrossAnnualSales", invalidFields)}
          toolTipContent="ANNUAL_SALES"
          decimalSeparator={false}
        />
        <Input
          id="quote-annual-payroll"
          datacy="annual-payroll"
          name="annualPayroll"
          value={annualPayroll}
          handleChange={(values) => handleNumberInputChange(values, "annualPayroll")}
          handleBlur={handleBlur}
          label="Estimated Annual Payroll"
          prefix="$"
          error={Boolean(invalidFields.find((f) => f.field === "AnnualPayroll"))}
          errorText={findAndFormatErrorMessage("AnnualPayroll", invalidFields)}
          toolTipContent="ANNUAL_PAYROLL"
          decimalSeparator={false}
        />
        <Input
          id="quote-num-employees"
          datacy="num-employees"
          name="numEmployees"
          type="number"
          value={numEmployees}
          handleChange={(values) => handleNumberInputChange(values, "numEmployees")}
          handleBlur={handleBlur}
          label="Number of Employees (plus owner)"
          error={Boolean(invalidFields.find((f) => f.field === "NumberOfEmployees"))}
          errorText={findAndFormatErrorMessage("NumberOfEmployees", invalidFields)}
          decimalSeparator={false}
        />
        <Input
          id="quote-business-age"
          label="Business Age (years)"
          name="businessAge"
          type="number"
          datacy="business-age"
          handleBlur={updateBusinessAge}
          handleChange={(values) => handleNumberInputChange(values, "businessAge")}
          value={businessAge}
          error={Boolean(invalidFields.find((f) => f.field === "BusinessAgeInMonths"))}
          errorText={findAndFormatErrorMessage("BusinessAgeInMonths", invalidFields)}
        />
        <Input
          id="quote-primary-location-square-footage"
          datacy="squareFootage"
          name="squareFootage"
          value={squareFootage}
          handleChange={(values) => handleNumberInputChange(values, "squareFootage")}
          handleBlur={handleBlur}
          label="Premises Square Footage"
          type="number"
          thousandSeparator={true}
        />
      </GridContainer>
      {isSimplybind && showRiskAttributes && <RiskAttributes />}
      {isSimplybind ? null : (
        <PreviousLosses
          add={updateApplicationFormAddPreviousLoss}
          remove={updateApplicationFormRemovePreviousLoss}
          previousLosses={previousLosses}
          clear={updateApplicationFormClearPreviousLoss}
          reset={updateApplicationFormResetPreviousLosses}
        />
      )}
    </PanelContainer>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  locations: selectApplicationFormLocation(state),
  numEmployees: selectApplicationFormEmployees(state),
  annualPayroll: selectApplicationFormPayroll(state),
  grossAnnualSales: selectApplicationFormSales(state),
  applicationTypes: selectApplicationFormApplicationTypes(state),
  businessAgeInMonths: selectApplicationFormBusinessAge(state),
  previousLosses: selectApplicationFormLosses(state),
  industryId: selectApplicationFormIndustryId(state),
  industryName: selectIndustryName(state),
  invalidFields: selectApplicationQuoteErrors(state),
  businessName: selectApplicationFormBusinessName(state),
  dba: selectApplicationFormDba(state),
  squareFootage: selectApplicationFormSquareFootage(state),
  isSimplybind: selectApplicationSourceVersion(state) === ApplicationSource.underwriting,
  isAPISourcedApplication: selectIsAPISourcedApplication(state),
});

const mapDispatchToProps = {
  updateApplicationForm,
  updateApplicationFormLocation,
  updateApplicationFormAddPreviousLoss,
  updateApplicationFormRemovePreviousLoss,
  updateApplicationFormClearPreviousLoss,
  updateApplicationFormResetPreviousLosses,
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 8px;
  ${tablet} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const FlexContainer = styled.div`
  display: flex;
`;

const OverrideStyle = createGlobalStyle`
  ${GridContainer} {
    ${StyledInput}, ${FormatInput}, ${MoneyInput}, ${NumberInput}, ${ErrorText}, ${StyledSelect} {
      margin-bottom: 0 !important;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(BusinessBasics);
