import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { getBrand } from "utils/getBrand";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { selectUserName } from "redux/selectors/user";
import { hideForPrint } from "styles";
import useAuth from "hooks/useAuth";
import { getSnapshotAgencyName } from "redux/reducers/_snapshotReducer";
import { isCanadaEnv } from "utils/environmentChecker";

interface PropTypes {
  userName: string;
  agencyName: string;
}

const TopNavigation = ({ userName, agencyName }: PropTypes) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { isAuthenticated, logout, roles } = useAuth();
  const brand = getBrand(roles);

  return (
    <NavBar data-testid="top-navigation" style={brand.navStyle}>
      <MainTitle>{brand.title}</MainTitle>
      <a href="/">
        <LogoImg src={brand.logo} alt={isCanadaEnv() ? "Brokerlink Logo" : "Coterie Insurance Logo"} />
      </a>
      <TopMenu>
        {isAuthenticated ? (
          <Dropdown
            data-testid="top-nav-dropdown"
            style={{ cursor: "pointer" }}
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
          >
            <User data-cy="user-dropdown" tag="div" caret>
              {userName}
            </User>
            <DropdownMenu>
              <Item>
                <Logout data-cy="logout-button" onClick={() => logout({ returnTo: window.location.origin })}>
                  Logout
                </Logout>
              </Item>
            </DropdownMenu>
          </Dropdown>
        ) : (
          <>{agencyName && <Agency>{agencyName}</Agency>}</>
        )}
      </TopMenu>
    </NavBar>
  );
};

const Agency = styled.p`
  font-size: 16px;
  font-weight: 600;
  padding: 16px;
`;

const NavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 4em;

  ${hideForPrint}
`;

const logoTypeHeight: string = isCanadaEnv() ? "30px" : "24px";

const LogoImg = styled.img`
  height: ${logoTypeHeight};
  margin: 0 20px;
`;

const MainTitle = styled.h1`
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0;
  white-space: nowrap;
  pointer-events: none;
`;

const TopMenu = styled.div`
  margin-left: auto;
  margin-right: 20px;
  height: 4em;
  .dropdown-toggle {
    cursor: pointer;
    height: 4em;
    padding-top: 1.25em;
  }
  .dropdown-toggle::after {
    margin-left: 0.5em;
  }

  .dropdown-menu.show {
    min-width: 8rem;
  }
`;

const Item = styled(DropdownItem)`
  color: ${(props) => props.theme.navText};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
`;

const User = styled(DropdownToggle)`
  color: ${(props) => props.theme.white};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
`;

const Logout = styled.button`
  background: none;
  font-weight: 600;
`;

const mapStateToProps = (state: ReduxState) => ({
  userName: selectUserName(state),
  agencyName: getSnapshotAgencyName(state),
});

export default connect(mapStateToProps)(TopNavigation);
