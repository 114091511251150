import { BrandIcon, theme, SecondaryButton } from "coterie-ui-library";
import styled from "styled-components";
import { useState } from "react";
import matchCardType from "utils/matchCardType";
import useGetPaymentSummary from "hooks/useGetPaymentSummary";
import UpdatePaymentMethodModal from "kit/updatePaymentMethod/UpdatePaymentMethodModal";
import { useQueryClient } from "@tanstack/react-query";
import { PolicyTabContainer } from "../PolicyTabContainer";
import creditCard from "images/creditcard-bg.svg";

type UpdatePaymentMethodProps = {
  policy: Policy;
};

const PolicyPaymentMethod = ({ policy }: UpdatePaymentMethodProps) => {
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching, isError } = useGetPaymentSummary(policy.policyNumber);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const cardType = !isLoading && !isFetching && !isError && data ? data.paymentProfile.cardType : "----";
  const lastFour = !isLoading && !isFetching && !isError && data ? data.paymentProfile.creditCard : "****";
  const expiration = !isLoading && !isFetching && !isError && data ? data.paymentProfile.expiration : "--/----";

  return (
    <PolicyTabContainer data-testid="content-policy-payment-method" heading="Your Default Payment Method">
      <CardContainer>
        <CardInfo>
          <div data-testid={`${cardType}-icon`}>
            <BrandIcon name={matchCardType(cardType)} color={theme.colors.tertiary.eighty} height={45} width={45} />
          </div>
          <CardNum data-cy="last-four" data-testid="last-four">
            <Dots /> {lastFour}
          </CardNum>
          <div>
            <CardExpLabel>EXPIRATION DATE</CardExpLabel>
            <CardExp data-testid="expiration-date">{expiration}</CardExp>
          </div>
        </CardInfo>
      </CardContainer>
      <UpdatePaymentButton onClick={() => setIsModalOpen(true)} data-cy="update-payment-method-button">
        Update payment method
      </UpdatePaymentButton>
      <UpdatePaymentMethodModal
        accountId={policy.payerAccountId}
        modalIsOpen={isModalOpen}
        afterPaymentUpdate={() => queryClient.invalidateQueries({ queryKey: ["payment_summary"] })}
        onRequestClose={() => setIsModalOpen(false)}
      />
    </PolicyTabContainer>
  );
};

export default PolicyPaymentMethod;

const buildGenericArr = (num: number) => new Array(num).fill(0);

const Dots = () => (
  <DotCont data-testid="dots">
    {buildGenericArr(3).map((_, i) => (
      <DotGroup key={`dot_group-${i}`}>
        {buildGenericArr(4).map((_, i) => (
          <Dot key={`dot-${i}`} />
        ))}
      </DotGroup>
    ))}
  </DotCont>
);

const Dot = styled.span`
  background-color: ${({ theme }) => theme.pageComponents.ia.paymentMethodUpdate.dotBg};
  height: 12px;
  width: 12px;
  border-radius: 50%;
`;

const DotGroup = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60px;
  margin-right: 16px;
`;

const DotCont = styled.span`
  display: flex;
  flex-direction: row;
  margin-right: 8px;
`;

const CardContainer = styled.div`
  height: 200px;
  width: 360px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.pageComponents.ia.paymentMethodUpdate.card.bg};
  box-shadow: ${({ theme }) => theme.pageComponents.ia.paymentMethodUpdate.card.shadow};
  border-radius: 20px;
  position: relative;
  margin-top: 24px;
  background-image: url(${creditCard});
`;

const CardInfo = styled.div`
  margin: 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardNum = styled.p`
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.pageComponents.ia.paymentMethodUpdate.cardNum.fontFamily};
  color: ${({ theme }) => theme.pageComponents.ia.paymentMethodUpdate.cardNum.color};
  font-size: 18px;
  margin: 0;
`;

const CardExpLabel = styled.p`
  font-family: ${({ theme }) => theme.pageComponents.ia.paymentMethodUpdate.cardExpLabel.fontFamily};
  color: ${({ theme }) => theme.pageComponents.ia.paymentMethodUpdate.cardExpLabel.color};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 0 4px;
`;

const CardExp = styled.p`
  font-family: ${({ theme }) => theme.pageComponents.ia.paymentMethodUpdate.cardExp.fontFamily};
  color: ${({ theme }) => theme.pageComponents.ia.paymentMethodUpdate.cardExp.color};
  font-size: 18px;
  margin: 0;
`;

const UpdatePaymentButton = styled(SecondaryButton)`
  margin-top: 32px;
  padding: 0 16px;
`;
