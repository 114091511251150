import { useQuery } from "@tanstack/react-query";
import { reportError } from "utils/reportError";
import { coterieAPI_v1_4 } from "./useAPI/instances";

const getCommercialForms = async (applicationId: string) => {
  const api = coterieAPI_v1_4();
  const { data } = await api.get(`/commercial/quotes/${applicationId}/forms`).catch((error) => {
    reportError(error);

    return Promise.reject(error);
  });

  return data;
};

type Options = {
  enabled?: boolean;
  select?: (data: any) => any;
};

const useGetPublicForms = (applicationId: string, options?: Options) => {
  return useQuery({
    queryKey: ["commercial_forms", applicationId],
    queryFn: () => getCommercialForms(applicationId),
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...options,
  });
};

export default useGetPublicForms;
