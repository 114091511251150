import styled from "styled-components";

interface ReviewClaimSubmissionProps {
  formData?: ClaimFormData;
}

const ReviewClaimSubmission = ({ formData }: ReviewClaimSubmissionProps) => {
  const formDataDisplay = (dataToDisplay: any) =>
    Object.entries(dataToDisplay).map(([key, value]: any) => {
      return (
        <p>
          <b>
            {key.replace(/([A-Z])/g, " $1").replace(/^./, function (str: string) {
              return str.toUpperCase();
            })}
            :
          </b>{" "}
          {value.toString()}
        </p>
      );
    });

  return (
    <FormContainer>
      <h2>Review Claim Details</h2>
      <br />
      <h3>Contact Details</h3>
      {formDataDisplay(formData?.contactData)}
      <br />
      <h3>Policy Details</h3>
      {formDataDisplay(formData?.policyData)}
      <br />
      <h3>Claim Details</h3>
      {formDataDisplay(formData?.claimData)}
    </FormContainer>
  );
};

const FormContainer = styled.div`
  min-width: 550px;
  padding: 0 25px;
`;

export default ReviewClaimSubmission;
