import React from "react";
import { Badge } from "reactstrap";
import styled, { css } from "styled-components";
import Highlighter from "shared/Highlighter";
import { prettyPrintJSON } from "utils/stringify";
import { Header4 } from "styles";

interface LogsTableRowProps {
  logItem: LogItem;
  searchTerm?: string;
  columns?: number;
}

interface StateTypes {
  showDetails: boolean;
}

const LogsTableRow = ({ logItem, searchTerm = "", columns = 4 }: LogsTableRowProps) => {
  const [showDetails, setShowDetails] = React.useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const createdOn = new Date(logItem.createdOn);
  const createdOnDateString = createdOn.getFullYear() + "/" + (createdOn.getMonth() + 1) + "/" + createdOn.getDate();
  const createdOnString = createdOnDateString + " " + createdOn.toLocaleTimeString();

  const { request, response } = logItem;

  let reqBody = request.Body;

  if (typeof reqBody === "object") {
    reqBody = JSON.stringify(reqBody);
  }

  let respBody = response.Body;
  if (typeof respBody === "object") {
    respBody = JSON.stringify(respBody);
  }

  const requestBody = prettyPrintJSON(`${reqBody}`);
  const responseBody = prettyPrintJSON(`${respBody}`);

  let badgeColor;
  switch (response.StatusCode) {
    case 200:
    case 201:
    case 204:
      badgeColor = "success";
      break;
    case 400:
    case 404:
    case 500:
      badgeColor = "danger";
      break;

    default:
      badgeColor = "secondary";
  }

  const service = logItem.service ? logItem.service : logItem.request.Host;

  return (
    <>
      <LogRow key={logItem.id} onClick={toggleDetails} showDetails={showDetails}>
        <td style={{ width: "1px", whiteSpace: "nowrap" }}>
          <Badge color={badgeColor}>{logItem.response.StatusCode}</Badge>
        </td>
        <td style={{ width: "20px", whiteSpace: "nowrap" }}>
          <Highlighter searchTerm={searchTerm}>{service}</Highlighter>
        </td>
        <td style={{ width: "auto" }}>
          {logItem.request.Method}&nbsp;
          <Highlighter searchTerm={searchTerm}>{logItem.request.Path}</Highlighter>
        </td>
        <td style={{ width: "1px", whiteSpace: "nowrap" }}>{createdOnString}</td>
      </LogRow>
      {showDetails ? (
        <LogDetailRow>
          <td colSpan={columns}>
            <LogSummary>
              <LogSummaryDetails>
                <div>
                  <div>
                    <label>Scheme</label>
                    <Highlighter searchTerm={searchTerm}>{request.Scheme}</Highlighter>
                  </div>
                  <div>
                    <label>Content Type</label>
                    {request.ContentType}
                  </div>
                  <div>
                    <label>Time</label>
                    {createdOnString}
                  </div>
                </div>
                <div>
                  <div>
                    <label>Host</label>
                    <Highlighter searchTerm={searchTerm}>{request.Host}</Highlighter>
                  </div>
                  <div>
                    <label>Path</label>
                    <Highlighter searchTerm={searchTerm}>{request.Path}</Highlighter>
                  </div>
                  <div>
                    <label>Query</label>
                    <Highlighter searchTerm={searchTerm}>{request.Query}</Highlighter>
                  </div>
                </div>
              </LogSummaryDetails>
            </LogSummary>
            <LogDetails>
              <Request>
                <Header4>Request</Header4>
                <Code>
                  <pre>
                    <Highlighter searchTerm={searchTerm}>{requestBody}</Highlighter>
                  </pre>
                </Code>
              </Request>

              <Response>
                <Header4>Response</Header4>
                <Code>
                  <pre>
                    <Highlighter searchTerm={searchTerm}>{responseBody}</Highlighter>
                  </pre>
                </Code>
              </Response>
            </LogDetails>
          </td>
        </LogDetailRow>
      ) : null}
    </>
  );
};

export default LogsTableRow;

const LogRow = styled.tr<{ showDetails: boolean }>`
  cursor: pointer;
  background-color: ${(props) => props.theme.white};
  border-radius: 2px;
  margin: 5px 0;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${(props) => props.theme.linkHover};
  }

  td {
    border-top: none !important;
  }

  ${(props) =>
    props.showDetails &&
    css`
      background: ${(props) => props.theme.lightestPurple};

      td {
        border-top: 1px solid ${(props) => props.theme.linkHover}!important;

        &:first-of-type {
          border-left: 1px solid ${(props) => props.theme.linkHover}!important;
        }
        &:last-of-type {
          border-right: 1px solid ${(props) => props.theme.linkHover}!important;
        }
      }
    `}
`;

const LogDetailRow = styled.tr`
  background-color: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.linkHover};
`;

const LogSummary = styled.div`
  margin-bottom: 10px;
`;

const LogSummaryDetails = styled.div`
  background-color: ${(props) => props.theme.offWhite};
  display: flex;
  flex-direction: row;

  label {
    font-weight: bold;
    width: 180px;
  }

  & > div {
    flex: 1;
    padding: 10px;
  }
`;

const LogDetails = styled.div`
  display: flex;
  flex-direction: row;
`;

const Request = styled.div`
  flex: 1;
  padding-right: 10px;
  overflow: auto;
`;

const Response = styled.div`
  flex: 1;
  overflow: auto;
  white-space: pre-wrap;
  max-width: 500px;
`;

const Code = styled.div`
  padding: 10px;
  background-color: ${(props) => props.theme.offWhite};
  font-size: 0.9em;
  height: 400px;
  overflow: auto;
  max-width: 500px;
`;
