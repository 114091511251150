import { Dictionary, isEmpty } from "lodash";
import React, { useReducer } from "react";
import styled from "styled-components";
import allSeeingEye from "images/all_seeing_eye.svg";
import copyIcon from "images/copy.svg";

const dropInSource = `https://cdn.${process.env.REACT_APP_DOMAIN}/dropin/coterie-dropin@1.2.0.min.js`;

interface DropInScriptPreviewProps {
  selectedIndustries?: IndustryListType[];
  token?: string;
  modalSizes?: SizeAttrTypes;
  color?: ColorAttrTypes;
  logoSrc?: string;
}

type StateType = {
  shouldFadeOut: boolean;
  shouldRenderCopiedPopUp: boolean;
};

type ActionType = {
  type: string;
};

const actions = {
  START_FADE_OUT: "START_FADE_OUT",
  SHOW_COPIED_POP_UP: "SHOW_COPIED_POP_UP",
  HIDE_COPIED_POP_UP: "HIDE_COPIED_POP_UP",
};

const validateAttributes = ({ selectedIndustries, token, modalSizes, color, logoSrc }: DropInScriptPreviewProps) => {
  const shouldSetStylesAttr = Boolean(
    color && !isEmpty(color) && color.primary && color.primaryOne && color.secondary && logoSrc
  );
  const shouldSetIndustriesAttr = Boolean(Array.isArray(selectedIndustries) && selectedIndustries.length > 0);
  const shouldSetTokenAttr = Boolean(token);
  const shouldSetSizesAttrs = Boolean(
    modalSizes && !isEmpty(modalSizes) && modalSizes.modalHeight && modalSizes.modalWidth
  );

  return {
    shouldSetStylesAttr,
    shouldSetIndustriesAttr,
    shouldSetTokenAttr,
    shouldSetSizesAttrs,
  };
};

const dropInPreviewReducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case actions.SHOW_COPIED_POP_UP:
      return { shouldFadeOut: false, shouldRenderCopiedPopUp: true };
    case actions.START_FADE_OUT:
      return { ...state, shouldFadeOut: true };
    case actions.HIDE_COPIED_POP_UP:
      return { ...state, shouldRenderCopiedPopUp: false };
    default:
      return state;
  }
};

const dropInPreviewInitialState = {
  shouldFadeOut: false,
  shouldRenderCopiedPopUp: false,
};

const DropInScriptPreview = ({ selectedIndustries, token, modalSizes, color, logoSrc }: DropInScriptPreviewProps) => {
  const [state, dispatch] = useReducer(dropInPreviewReducer, dropInPreviewInitialState);

  const showCopiedPopUp = () => dispatch({ type: actions.SHOW_COPIED_POP_UP });
  const startFadeOut = () => dispatch({ type: actions.START_FADE_OUT });
  const hideCopiedPopUp = () => dispatch({ type: actions.HIDE_COPIED_POP_UP });

  const { shouldFadeOut, shouldRenderCopiedPopUp } = state;

  const { shouldSetStylesAttr, shouldSetIndustriesAttr, shouldSetTokenAttr, shouldSetSizesAttrs } = validateAttributes({
    selectedIndustries,
    token,
    modalSizes,
    color,
    logoSrc,
  });

  const indentation = "\n    ";
  const akHashList =
    shouldSetIndustriesAttr && selectedIndustries?.map((industry: { akHash: string }) => industry.akHash).join(",");
  const stylesAttr = shouldSetStylesAttr
    ? `${indentation}data-styles='{"primary":"${color?.primary}","primaryOne":"${color?.primaryOne}","secondary":"${color?.secondary}","logoSrc":"${logoSrc}"}'`
    : null;
  const idsAttr = shouldSetIndustriesAttr ? `${indentation}data-ids="${akHashList}"` : null;
  const tokenAttr = shouldSetTokenAttr ? `${indentation}data-token="${token}"` : null;
  const widthAttr = shouldSetSizesAttrs ? `${indentation}data-width="${modalSizes?.modalWidth}"` : null;
  const heightAttr = shouldSetSizesAttrs ? `${indentation}data-height="${modalSizes?.modalHeight}"` : null;

  const customAttrs = [stylesAttr, idsAttr, tokenAttr, widthAttr, heightAttr];
  const scriptTag = `<script type="text/javascript" src="${dropInSource}""></script>`;
  const script = `<div${indentation}id="Coterie_Widget"${customAttrs
    .filter((attr) => Boolean(attr))
    .join("")}\n></div>\n${scriptTag}`;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(script).then(() => {
      showCopiedPopUp();
      setTimeout(() => startFadeOut(), 1000);
      setTimeout(() => hideCopiedPopUp(), 2000);
    });
  };

  return (
    <DropInScriptCont>
      <ScriptPreview data-testid="script">{script}</ScriptPreview>
      <CopyToClipboardCont>
        <CopyToClipboardBtn type="button" onClick={handleCopyToClipboard}>
          <img src={copyIcon} alt="Copy to Clipboard icon" />
          Copy to Clipboard
        </CopyToClipboardBtn>
        {shouldRenderCopiedPopUp && <CopiedPopUp shouldFadeOut={shouldFadeOut}>Copied!</CopiedPopUp>}
      </CopyToClipboardCont>
    </DropInScriptCont>
  );
};

export default DropInScriptPreview;

export const DropInScriptPrevBtn = ({
  selectedIndustries,
  token,
  modalSizes,
  color,
  logoSrc,
}: DropInScriptPreviewProps) => {
  const { shouldSetStylesAttr, shouldSetIndustriesAttr, shouldSetSizesAttrs } = validateAttributes({
    selectedIndustries,
    token,
    modalSizes,
    color,
    logoSrc,
  });
  const akHashList =
    shouldSetIndustriesAttr && selectedIndustries?.map((industry: { akHash: string }) => industry.akHash).join(",");

  const handlePreview = () => {
    document.querySelector(".dropInScript")?.remove();
    const script = document.createElement("script");
    script.src = dropInSource;
    script.type = "text/javascript";
    script.addEventListener("load", function () {
      const previewWidget = document.querySelector<HTMLElement>(".activateButton");
      if (previewWidget) {
        previewWidget.setAttribute("type", "button");
        previewWidget!.style.display = "none";
        previewWidget.click();
      }
    });

    document.head.appendChild(script);
  };

  const getPreviewProps = () => {
    const previewProps: Dictionary<string> = {};

    if (shouldSetStylesAttr) {
      previewProps[
        "data-styles"
      ] = `{"primary":"${color?.primary}","primaryOne":"${color?.primaryOne}","secondary":"${color?.secondary}","logoSrc":"${logoSrc}"}`;
    }
    if (shouldSetSizesAttrs) {
      previewProps["data-height"] = `${modalSizes?.modalHeight}`;
      previewProps["data-width"] = `${modalSizes?.modalWidth}`;
    }
    if (shouldSetIndustriesAttr) {
      previewProps["data-ids"] = `${akHashList}`;
    }

    return previewProps;
  };

  return (
    <>
      {/* TODO update data-env to sandbox once QF is deployed */}
      <div id="Coterie_Widget" data-buttonText="Preview Drop-In" data-env="test" {...getPreviewProps()} />
      <PreviewBtn onClick={handlePreview} type="button">
        <img src={allSeeingEye} alt="show preview icon" /> Preview Drop-In
      </PreviewBtn>
    </>
  );
};

const DropInScriptCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const CopyToClipboardCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CopiedPopUp = styled.div<{ shouldFadeOut: boolean }>`
  color: ${({ theme }) => theme.blue};
  border: 1px solid ${({ theme }) => theme.blue};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.blue10};
  height: 32px;
  width: 64px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  ${({ shouldFadeOut }) =>
    shouldFadeOut
      ? `animation-name: fadeOut;
      -webkit-animation-duration: 1s;animation-duration: 1s;
      -webkit-animation-fill-mode: both;animation-fill-mode: both;

      @keyframes fadeOut {
        0% {opacity: 1;}
        100% {opacity: 0;}
      };`
      : `opacity: 1;`}
`;

const PreviewBtn = styled.button`
  background: none;
  border: none;
  padding: none;
  outline: none;
  font: ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.blue};
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  margin: 10px 0 5px 0;

  img {
    margin: 4px 8px 4px 0;
    height: 16px;
  }
`;

const ScriptPreview = styled.pre`
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
  color: ${({ theme }) => theme.red};
  width: 100%;
  padding: 16px 20px;
`;

const CopyToClipboardBtn = styled.button`
  background: none;
  border: none;
  padding: none;
  outline: none;
  font: ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.blue};
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  display: flex;

  img {
    margin: 4px 8px 4px 0;
  }
`;
