import React, { useContext, useState, useEffect, useRef } from "react";
import { AttributeBlock, LogicBlockHeader, DeleteLogicBlockButtonContainer } from "../styles";
import { RuleBuilderContext } from "../RuleBuilderContext";
import styled, { createGlobalStyle } from "styled-components";
import Dropdown, { StyledCreatableSelect } from "styles/Dropdown";
import { Textarea } from "styles";
import { ReactComponent as CloseIcon } from "images/close-blue.svg";
import useGetUnderwritingPropertyValues from "hooks/underwritingRules/useGetUnderwritingPropertyValues";
import { reportFailFetchRuleMetadata } from "utils/reportError";
import { arrayToDropdownOptionsList } from "../../Shared/utils";
import DeleteButton from "../DeleteButton";
import { Property } from "../../Shared/types";

interface PropertyBlockProps {
  property: Property;
  propertyIndex: number;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  existingPropertyNames: string[];
}

const PropertyBlock = ({
  property,
  propertyIndex,
  activeIndex,
  setActiveIndex,
  existingPropertyNames,
}: PropertyBlockProps) => {
  const { updateLogicBlock, activeLogicBlock, deleteProperty, updatePropertyName, updatePropertyValue, rule } =
    useContext(RuleBuilderContext);

  const [useExistingValue, setUseExistingValue] = useState(Boolean(property.value));
  const isSelected = propertyIndex === activeIndex && activeLogicBlock === "property";
  const ExistingValueButtonRef = useRef<HTMLButtonElement | null>(null);
  const usedPropertyNames = rule.properties?.map((property) => property.name);
  const availablePropertyNames = existingPropertyNames?.filter(
    (name) => name === property.name || !usedPropertyNames?.includes(name)
  );

  const { data: propertyValues, error } = useGetUnderwritingPropertyValues(property.name, { enabled: Boolean(property.name) });

  useEffect(() => {
    if (error) {
      reportFailFetchRuleMetadata(error);
    }
  }, [error]);

  const clearPropertyValue = () => updatePropertyValue("", propertyIndex);

  const handleBlockFocus = () => {
    updateLogicBlock("property");
    setActiveIndex(propertyIndex);
  };

  const handlePropertyValueBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const clickedIsExistingPropertyValueButton = ExistingValueButtonRef?.current?.isSameNode(e.relatedTarget as Node);
    if (!clickedIsExistingPropertyValueButton) {
      updatePropertyValue(e.target.value ?? "", propertyIndex);
    }
  };

  const handleExistingPropertyValuesChange = () => {
    if (useExistingValue) {
      if (propertyValues && !propertyValues?.includes(property.value)) {
        setUseExistingValue(false);
      }
    } else {
      if (propertyValues?.includes(property.value)) {
        setUseExistingValue(true);
      }
    }
  };

  useEffect(() => {
    handleExistingPropertyValuesChange();
  }, [propertyValues]);

  const newOptionIsValid = (value: string) => {
    const normalizedPropertyNames = rule.properties?.map((property) => property.name.toLowerCase()) ?? [];
    const normalizedExistingPropertyNames =
      existingPropertyNames?.map((propertyName) => propertyName.toLowerCase()) ?? [];
    const allPropertyNames = [...normalizedPropertyNames, ...normalizedExistingPropertyNames];
    const propertyNameIsUnique = !allPropertyNames.includes(value.toLowerCase());
    return Boolean(value && propertyNameIsUnique);
  };

  const propertyNameOptions = () => {
    if (property.name && !availablePropertyNames?.includes(property.name)) {
      return arrayToDropdownOptionsList([...(availablePropertyNames ?? []), property.name]);
    } else {
      return arrayToDropdownOptionsList(availablePropertyNames);
    }
  };

  return (
    <>
      <OverrideDropdownStyle />
      <LogicBlockHeader>Add Property...</LogicBlockHeader>
      <AttributeBlock isSelected={isSelected} onFocus={handleBlockFocus} onClick={handleBlockFocus}>
        <InnerWrapper>
          <Row>
            <PropertyTypeButtonContainer>
              <PropertyTypeButton
                isSelected={!useExistingValue}
                typeIsNew
                onClick={() => {
                  setUseExistingValue(false);
                  clearPropertyValue();
                }}
              >
                New
              </PropertyTypeButton>
              <PropertyTypeButton
                isSelected={useExistingValue}
                onClick={() => {
                  setUseExistingValue(true);
                  clearPropertyValue();
                }}
                disabled={!property.name || !propertyValues?.length}
                placeholder="Property Name"
                ref={ExistingValueButtonRef}
              >
                Existing
              </PropertyTypeButton>
            </PropertyTypeButtonContainer>
            <PropertyNameDropdownContainer>
              <Dropdown
                options={propertyNameOptions()}
                onChange={(value) => updatePropertyName(value.label, propertyIndex)}
                isCreatable
                value={property.name}
                labelIsHidden
                placeholder="Property Name"
                isValidNewOption={newOptionIsValid}
                label="Property Names"
                full
              />
            </PropertyNameDropdownContainer>
          </Row>
          {property.value ? (
            <PropertyValueContainer>
              <PropertyValue>{property.value}</PropertyValue>
              <PropertyValueDeleteButton onClick={clearPropertyValue} aria-label="Delete Property Value">
                <CloseIcon width={10} height={10} />
              </PropertyValueDeleteButton>
            </PropertyValueContainer>
          ) : (
            <>
              {useExistingValue ? (
                <Dropdown
                  options={arrayToDropdownOptionsList(propertyValues)}
                  onChange={(value) => {
                    updatePropertyValue(value.label, propertyIndex);
                  }}
                  labelIsHidden
                  placeholder="Property Value"
                  label="Existing Property Values"
                  value={property.value}
                  full
                />
              ) : (
                <PropertyValueTextArea
                  onBlur={handlePropertyValueBlur}
                  placeholder="Property Value"
                  aria-label="New Property Value"
                />
              )}
            </>
          )}
          <DeleteLogicBlockButtonContainer>
            <DeleteButton
              ariaLabel="Delete Property"
              isDisabled={!isSelected}
              onClick={() => deleteProperty(propertyIndex)}
            ></DeleteButton>
          </DeleteLogicBlockButtonContainer>
        </InnerWrapper>
      </AttributeBlock>
    </>
  );
};

const InnerWrapper = styled.div`
  background: ${(props) => props.theme.white};
  padding: 1em;
  border-radius: 4px;
`;

const PropertyValueContainer = styled.div`
  background: ${(props) => props.theme.blue10};
  border: ${(props) => `1px solid ${props.theme.blue}`};
  border-radius: 4px;
  display: flex;
  padding: 16px;
  cursor: default;
  align-items: flex-start;
`;

const PropertyValue = styled.p`
  font-weight: 600;
  font-size: 11px;
  margin-bottom: 0;
  max-width: 98%;
  color: ${(props) => props.theme.blue};
`;

const PropertyValueDeleteButton = styled.button`
  background: transparent;
  position: relative;
  margin-left: auto;
  top: -15px;
  right: -15px;
`;

const PropertyValueTextArea = styled(Textarea)`
  height: 100px;
  margin-bottom: 0;
`;

const PropertyTypeButtonContainer = styled.div`
  margin-right: 40px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
`;

const OverrideDropdownStyle = createGlobalStyle`
  ${StyledCreatableSelect} {
    margin-bottom: 0;
  }
`;

const PropertyTypeButton = styled.button<{ typeIsNew?: boolean; isSelected: boolean; disabled?: boolean }>`
  border-radius: ${(props) => (props.typeIsNew ? "24px 0 0 24px" : "0 24px 24px 0")};
  height: 32px;
  width: 60px;
  font-size: 13px;
  font-weight: 600;
  background: ${(props) => (props.isSelected ? props.theme.blue : props.theme.offWhite)};
  color: ${(props) => {
    if (props.isSelected) return props.theme.white;
    if (props.disabled) return props.theme.disabledText;
    else return props.theme.textNavyBlue;
  }};
`;

const PropertyNameDropdownContainer = styled.div`
  width: 324px;
`;

export default PropertyBlock;
