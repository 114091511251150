import React from "react";
import styled from "styled-components";
import { ParagraphLight, GreenButton, Header3White, Header4White, TextLink } from "styles";
import { mobile } from "styles/responsive";
import chat_icon from "images/chat_icon.svg";
import phone_icon from "images/phone_icon.svg";
import email_icon from "images/email_icon.svg";

const ContactUs = () => (
  <Container>
    <StyledHeader3>Looking for some help?</StyledHeader3>
    <Row>
      <ContactMethod>
        <FlexDiv>
          <img src={chat_icon} alt="Chat icon" />
          <StyledHeader4>Chat With Us</StyledHeader4>
        </FlexDiv>
        <ContactText>Between you and me, this is probably the best way to get ahold of us.</ContactText>
        <StyledGreenButton
          target="_blank"
          rel="noopener noreferrer"
          href={`https://${process.env.REACT_APP_DOMAIN}/support`}
        >
          Chat
        </StyledGreenButton>
      </ContactMethod>
      <ContactMethod>
        <FlexDiv>
          <img src={phone_icon} alt="Phone icon" />
          <StyledHeader4>Call Us</StyledHeader4>
        </FlexDiv>
        <ContactText>Want to hear a friendly voice? Like a bit of a personal touch? Give us a call!</ContactText>
        <StyledTextLink as="a" href="tel:855-566–1011">
          (855) 566–1011
        </StyledTextLink>
      </ContactMethod>
      <ContactMethod>
        <FlexDiv>
          <img src={email_icon} alt="Email icon" />
          <StyledHeader4>Email Us</StyledHeader4>
        </FlexDiv>
        <ContactText>A human being will open your email, read it, and respond. No robots.</ContactText>
        <StyledGreenButton target="_blank" rel="noopener noreferrer" href="mailto:support@coterieinsurance.com">
          Contact Us
        </StyledGreenButton>
      </ContactMethod>
    </Row>
  </Container>
);

const Container = styled.div`
  background: ${(props) => props.theme.bgDarkBlue};
  text-align: center;
`;

const Row = styled.div`
  max-width: 1184px;
  margin: auto;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 32px 96px 40px;
  ${mobile} {
    padding: 32px 0 40px;
    grid-gap: 32px;
  }
`;

const StyledHeader3 = styled(Header3White)`
  margin: 0;
  padding-top: 32px;
  font-size: 31px;
  font-weight: 700;
`;

const StyledHeader4 = styled(Header4White)`
  font-size: 20px;
  line-height: 50px;
  margin: 0 0 0 8px;
`;

const StyledTextLink = styled(TextLink)`
  font-size: 20px;
  line-height: 45px;
  color: ${({ theme }) => theme.green};
  padding: 0;
  &:hover,
  &:active {
    color: ${({ theme }) => theme.green};
  }
  ${mobile} {
    line-height: 24px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  ${mobile} {
    justify-content: center;
    margin-right: 8px;
  }
`;

const ContactMethod = styled.div`
  text-align: left;
  max-width: 272px;
  margin: auto;
  ${mobile} {
    text-align: center;
  }
`;

const ContactText = styled(ParagraphLight)`
  font-size: 16px;
  margin: 8px 0 16px;
`;

const StyledGreenButton = styled(GreenButton)`
  width: 128px;
`;

export default ContactUs;
