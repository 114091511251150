import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  updateIndustryName,
  updateApplicationForm,
  setAvailablePolicyTypes,
  setApplicationError,
  resetApplication,
  setQuoteErrors,
  setLoadingQuote,
} from "redux/reducers/_applicationReducer";
import Api from "utils/api";
import getQuote from "./getQuote";
import { reportError } from "utils/reportError";

import validateApplicationStartDate from "utils/validateApplicationStartDate";
import { pipe } from "utils/functionalHelpers";

const getCurrentApplication = createAsyncThunk(
  "application/getCurrentApplication",
  ({ applicationId, token, apiVersion }: any, thunkAPI: any) => {
    thunkAPI.dispatch(setLoadingQuote(true));
    thunkAPI.dispatch(resetApplication());

    const api = new Api(apiVersion, token);

    api.application
      .get(applicationId)
      .then((response) => {
        if (response.application.akHash) {
          const { akHash } = response.application;
          api.industry.get(akHash).then((res) => {
            thunkAPI.dispatch(updateIndustryName(res));
          });
        }

        const validatedApplication = pipe(updateApplicationForm, validateApplicationStartDate)(response.application);

        thunkAPI.dispatch(validatedApplication);
        thunkAPI.dispatch(setAvailablePolicyTypes(response.availablePolicyTypes));
        thunkAPI.dispatch(getQuote({ isSuccess: response.isSuccess, token, apiVersion }));
        thunkAPI.dispatch(setQuoteErrors(response.errors));
      })
      .catch((err: string) => {
        thunkAPI.dispatch(setApplicationError(err));
        reportError(err);
      });
  }
);

export default getCurrentApplication;
