import { useContext } from "react";
import { PaymentDrawer as SharedPaymentDrawer } from "@coterieinsure/ux-components";
import usePutUpdateDefaultPaymentMethod from "hooks/usePutUpdateDefaultPaymentMethod";
import usePostOneTimePaymentDashboard from "hooks/usePostOneTimePaymentDashboard";
import useGetPaymentSummary from "hooks/useGetPaymentSummary";
import { reportStripeError } from "utils/reportError";
import { PaymentContext } from "context/payment";
import useGetBillingBalance from "hooks/useGetBillingBalance";
import "./paymentDrawer.css";
import { toastError } from "utils/toast";

type PaymentDrawerProps = {
  onOneTimePaymentSuccess?: () => void;
  onUpdatePaymentSuccess?: () => void;
};

const PaymentDrawer = ({ onOneTimePaymentSuccess, onUpdatePaymentSuccess }: PaymentDrawerProps) => {
  const { state, actions } = useContext(PaymentContext);
  const { isDrawerOpen, policyToCharge } = state;
  const { closeDrawer } = actions;

  const { refetch: refetchPolicyBalance } = useGetBillingBalance(policyToCharge?.policyNumber);

  const { data: paymentSummary, refetch: refetchPaymentSummary } = useGetPaymentSummary(policyToCharge?.policyNumber, {
    enabled: Boolean(policyToCharge),
  });
  // TODO temp fix to fix typescript inference issue
  const cardData = (paymentSummary as any)?.paymentProfile;

  const amount = policyToCharge?.overdue;

  const {
    mutate: updatePaymentMethodCallback,
    isPending: isUpdatePaymentMethodLoading,
    isError: isUpdatePaymentMethodError,
  } = usePutUpdateDefaultPaymentMethod({
    accountId: policyToCharge?.payerAccountId,
    options: {
      onSuccess: () => {
        refetchPaymentSummary();
        if (onUpdatePaymentSuccess) onUpdatePaymentSuccess();
      },
    },
  });
  const { mutate: oneTimePaymentCallback, isPending: isOneTimePaymentLoading } = usePostOneTimePaymentDashboard({
    policyNumber: policyToCharge?.policyNumber,
    amount,
    options: {
      onSuccess: () => {
        refetchPolicyBalance();
        if (onOneTimePaymentSuccess) onOneTimePaymentSuccess();
      },
      onError: (error) => {
        toastError("An error occurred while processing your payment. Please try again.");
      },
    },
  });

  return (
    <SharedPaymentDrawer
      cardData={cardData || {}}
      policyToCharge={policyToCharge}
      updatePaymentMethodCallback={updatePaymentMethodCallback}
      oneTimePaymentCallback={oneTimePaymentCallback}
      closeDrawer={closeDrawer}
      isUpdatePaymentMethodLoading={isUpdatePaymentMethodLoading}
      isUpdatePaymentMethodError={isUpdatePaymentMethodError}
      isOneTimePaymentLoading={isOneTimePaymentLoading}
      isDrawerOpen={isDrawerOpen}
      reportStripeError={(error: any) => reportStripeError(error)}
      reportStripeInitError={() => reportStripeError("Stripe Failed To initialize", "An issue has occurred")}
      stripePublishableKey={process.env.REACT_APP_STRIPE_KEY as string}
    />
  );
};

export default PaymentDrawer;
