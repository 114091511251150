import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import { addSource } from "utils/addSource";
import { GreenButton, Input, Label, Checkbox } from "styles";
import { Container, CTAButtonContainer, SectionHeaderText } from "../styles";
import AddressSelector from "shared/application/AddressSelector";
import ToolTipIcon from "shared/ToolTipIcon";
import { updateApplicationForm } from "redux/reducers/_applicationReducer";
import useAuth from "hooks/useAuth";
import useVerifyAddressStateAndZip from "hooks/useVerifyAddressStateAndZip";
import SelectCoverages from "../SelectCoverages";

interface PropTypes {
  callback: (payload: any) => void;
  updateApplicationForm: (arg0: Application) => void;
}

const NameAndAddress = ({ callback, updateApplicationForm }: PropTypes) => {
  const { isIAUser } = useAuth();
  const { showSelectCoverage } = useFeatureFlags();

  const [businessName, setBusinessName] = useState("");
  const [dba, setDba] = useState("");
  const [sameBusinessName, setSameBusinessName] = useState(true);

  const [businessAddress, setBusinessAddress] = useState<Address>({
    street: "",
    streetLineTwo: "",
    city: "",
    state: "",
    zip: "",
  });
  const [verifiedAddress, setVerifiedAddress] = useState(false);
  const [isManualAddressEntry, setIsManualAddressEntry] = useState(false);

  const [isCoverageSelectionValid, setIsCoverageSelectionValid] = useState(true);

  const { isError: stateAndZipAreInvalid, isLoading: isVerifyingStateAndZip } = useVerifyAddressStateAndZip({
    state: businessAddress.state,
    zip: businessAddress.zip,
  });

  const handleBusinessNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setBusinessName(event.currentTarget.value);
    updateApplicationForm({
      businessName: event.currentTarget.value,
    });
  };

  const handleDBAChange = (event: React.FormEvent<HTMLInputElement>) => {
    setDba(event.currentTarget.value);
  };

  const changeAddress = (address: Address, isVerified: boolean, isManualAddressEntry: boolean) => {
    if (isVerified) {
      setBusinessAddress({
        street: address.street,
        streetLineTwo: address.streetLineTwo ?? "",
        city: address.city,
        state: address.state,
        zip: address.zip,
      });
      updateApplicationForm({
        locations: [{ street: address.street, city: address.city, state: address.state, zip: address.zip }],
      });
    }
    setVerifiedAddress(isVerified);
    setIsManualAddressEntry(isManualAddressEntry);
  };

  const handleNextClick = () => {
    const sourceData = addSource({ isDashboard: isIAUser });
    const streetAddress = businessAddress.streetLineTwo
      ? businessAddress.street + " " + businessAddress.streetLineTwo
      : businessAddress.street;
    const zipCode =
      typeof businessAddress.zip === "string" ? businessAddress.zip.replace(/\s/g, "") : businessAddress.zip;
    const businessNameAndAddress = {
      CompanyName: businessName,
      streetAddress: streetAddress?.trim(),
      City: businessAddress.city?.trim(),
      StateCode: businessAddress.state,
      ZipCode: zipCode,
      dba: sameBusinessName ? "" : dba,
      isManualAddressEntry: isManualAddressEntry,
      ...sourceData,
    };
    callback(businessNameAndAddress);
  };

  const isCheckedAndDBA = () => {
    if (sameBusinessName) {
      return false;
    } else if (!sameBusinessName && !!dba) {
      return false;
    } else {
      return true;
    }
  };

  const disabledNextButton = Boolean(
    !businessName ||
      !verifiedAddress ||
      isVerifyingStateAndZip ||
      stateAndZipAreInvalid ||
      isCheckedAndDBA() ||
      !isCoverageSelectionValid
  );

  const onCoveragesValidate = useCallback((isValid: boolean) => {
    setIsCoverageSelectionValid(isValid);
  }, []);

  return (
    <Container>
      <BodyContainer>
        <SectionHeaderText leftAlign={showSelectCoverage}>
          Enter your client’s full business name and address:
        </SectionHeaderText>
        <div style={{ marginBottom: 16 }}>
          <Input
            type="text"
            value={businessName}
            name="businessName"
            id="getting-started-business-name"
            label="Legal Business Name"
            handleChange={(e) => handleBusinessNameChange(e)}
            toolTipContent="BUSINESS_NAME_SIMPLYBIND"
            inputStyles={{ marginBottom: "16px" }}
          />
          <Checkbox
            label="This business has a separate DBA or Operating Name"
            name="sameBusinessName"
            checked={!sameBusinessName}
            handleClick={() => setSameBusinessName(!sameBusinessName)}
            marginOverride="0 12px 0 0"
          />
        </div>
        {!sameBusinessName && (
          <Input
            type="text"
            value={dba}
            toolTipContent="DOING_BUSINESS_AS_NAME_AND_ADDRESS_SIMPLYBIND"
            name="dba"
            label="Doing Business As"
            handleChange={(e) => handleDBAChange(e)}
            inputStyles={{ marginBottom: 16 }}
          />
        )}
        <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
          <FlexDiv>
            <Label htmlFor="getting-started-business-address">Business Address</Label>
            <ToolTipIcon contentKey="BUSINESS_ADDRESS_SIMPLYBIND" />
          </FlexDiv>
          <AddressSelector
            street={businessAddress.street}
            streetLineTwo={businessAddress.streetLineTwo}
            city={businessAddress.city}
            state={businessAddress.state}
            zip={businessAddress.zip}
            changeAddress={changeAddress}
            name="business"
            id="getting-started-business-address"
            isSimplybind={true}
          />
        </form>
        {showSelectCoverage && (
          <SelectCoverages businessAddressState={businessAddress.state ?? ""} onValidate={onCoveragesValidate} />
        )}
      </BodyContainer>
      <CTAButtonContainer>
        <GreenButton onClick={handleNextClick} disabled={disabledNextButton}>
          Next
        </GreenButton>
      </CTAButtonContainer>
    </Container>
  );
};

const BodyContainer = styled.div`
  margin-bottom: 48px;
  width: 90%;
  @media (min-width: 767px) {
    max-width: 480px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
`;

const mapDispatchToProps = { updateApplicationForm };

export default connect(null, mapDispatchToProps)(NameAndAddress);
