import { useQuery } from "@tanstack/react-query";
import { ApplicationSortBy, SortDirection } from "types/enums";
import { portalApi } from "./useAPI/instances";
import useAuth from "./useAuth";
import { reportError } from "utils/reportError";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface GetQuoteSearch {
  searchText: string;
  sortBy: ApplicationSortBy;
  sortDirection: SortDirection;
  producerId: string;
  isOnArchiveTab: boolean;
}

const useGetQuoteSearch = ({
  searchText,
  sortBy = ApplicationSortBy.Name,
  sortDirection = SortDirection.Descending,
  producerId,
  isOnArchiveTab,
}: GetQuoteSearch) => {
  const { getToken, isIAUser } = useAuth();
  const { apiVersion, searchEndpointUrl } = useFeatureFlags();

  const getQuoteSearch = async () => {
    const token = await getToken();
    const portalAPI = portalApi(apiVersion, token);
    const archiveParam = isIAUser ? `&archived=${isOnArchiveTab}` : "";
    const producerIdParam = producerId ? `&producerExternalId=${producerId}` : "";

    return portalAPI
      .get(
        `/applications/${searchEndpointUrl}?q=${searchText}&sort_by=${sortBy}&sort_direction=${sortDirection}&limit=250${archiveParam}${producerIdParam}`
      )
      .catch((error) => {
        reportError(error);

        return Promise.reject(error);
      });
  };

  return useQuery({
    queryKey: ["get_quote_search", searchText, sortBy, sortDirection, producerId, isOnArchiveTab],
    queryFn: getQuoteSearch,
    select: (res) => res.data,
  });
};

export default useGetQuoteSearch;
