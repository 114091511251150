import React, { useState } from "react";
import { connect } from "react-redux";
import { VALUE_OPTIONS, POLICY_OPTIONS, POLICY_ARIA_LABELS } from "utils/constants";
import { formatCurrency } from "utils/formatNumber";
import { findAndFormatErrorMessage } from "utils/formatErrorString";
import { Dropdown, Input, Label, TextLink, tablet } from "styles";
import {
  selectApplicationFormPLLimit,
  selectApplicationFormYearsOfProfessionalExperience,
  selectApplicationFormYearsOfPriorActs,
  selectApplicationFormPLDeductible,
  selectApplicationFormPeriodLoading,
  selectApplicationQuoteErrors,
  selectApplicationFormIndustryId,
} from "redux/selectors/application";
import { InvalidApplicationKeys, ProfessionalLiability } from "types/enums";
import { updateApplicationForm, updateApplicationFormPL } from "redux/reducers/_applicationReducer";
import styled, { createGlobalStyle } from "styled-components";
import useGetAcknowledgements from "hooks/useGetAcknowledgements";
import { CoverageType } from "shared/application/StyledComponents";
import { StyledSelect } from "styles/Dropdown";
import formotivInstance from "utils/formotiv";

interface PropTypes {
  updateApplicationFormPL: (arg0: object) => void;
  plLimit: number;
  yearsOfProfessionalExperience: number;
  yearsOfPriorActs: number;
  plDeductible: number;
  periodLoading: number;
  invalidFields: InvalidField[];
  industryId: number;
}

const PLCoverages = ({
  plLimit,
  plDeductible,
  yearsOfProfessionalExperience,
  yearsOfPriorActs,
  periodLoading,
  updateApplicationFormPL,
  invalidFields,
  industryId,
}: PropTypes) => {
  const [yearsOfProfessionalExperienceInput, setYearsOfProfessionalExperienceInput] =
    useState(yearsOfProfessionalExperience);

  const { data: acknowledgements } = useGetAcknowledgements(industryId, { enabled: Boolean(industryId) });

  const updatePlCoverage = (amount: number, name?: any, id?: string) => {
    if (id && window.formotiv) {
      formotivInstance.trackCustom(id);
    }
    updateApplicationFormPL({ [name]: amount });
  };

  const handleChangeAndUpdateYearsOfProfessionalExp = (e: React.ChangeEvent) => {
    const years = yearsOfProfessionalExperienceInput || 0;
    if (years === 0) {
      setYearsOfProfessionalExperienceInput(0);
    }
    updatePlCoverage(years, ProfessionalLiability.yearsOfProfessionalExperience);
  };

  const handleSetYearsOfProfessionalExperienceInput = (value: string) => {
    setYearsOfProfessionalExperienceInput(parseInt(value));
  };

  const hasPLDeductibleError = Boolean(
    invalidFields.find((f) => f.field === InvalidApplicationKeys.PlDeductibleAmount)
  );

  return (
    <>
      <OverrideStyle />
      <StyledCoverageType>Coverage Details</StyledCoverageType>
      <FlexContainer>
        <InputContainer width={312}>
          <Dropdown
            id="quote-PL-limits"
            name="professionalLiabilityLimit"
            options={professionalLiabilityLimitOptions}
            onChange={(option) =>
              updatePlCoverage(option.value, ProfessionalLiability.occurrenceLimit, "quote-PL-limits")
            }
            label="Professional Liability Limit"
            value={plLimit}
            full
          />
        </InputContainer>
        <InputContainer width={248}>
          <Dropdown
            id="quote-PL-deductible"
            name="deductible"
            options={propertyDamageDeductibleOptions}
            onChange={(option) =>
              updatePlCoverage(option.value, ProfessionalLiability.deductibleAmount, "quote-PL-limits")
            }
            label="Deductible"
            value={plDeductible}
            error={hasPLDeductibleError}
            errorText={findAndFormatErrorMessage(InvalidApplicationKeys.PlDeductibleAmount, invalidFields)}
            full
          />
        </InputContainer>
      </FlexContainer>
      <FlexContainer>
        <InputContainer width={312}>
          <Dropdown
            label={POLICY_ARIA_LABELS[POLICY_OPTIONS.PERIOD_LOADING]}
            id="quote-PL-extended-reporting-period"
            name="extendedReportingPeriod"
            onChange={(selectedOption) =>
              updatePlCoverage(
                selectedOption.value,
                ProfessionalLiability.periodLoading,
                "quote-PL-extended-reporting-period"
              )
            }
            options={extendedReportingPeriodOptions}
            value={periodLoading}
            toolTipContent="EXTENDED_REPORTING_PERIOD"
            full
          />
        </InputContainer>
        <InputContainer width={248}>
          <Input
            id="quote-PL-years-of-professional-experience"
            type="number"
            name={ProfessionalLiability.yearsOfProfessionalExperience}
            value={yearsOfProfessionalExperienceInput}
            handleBlur={handleChangeAndUpdateYearsOfProfessionalExp}
            handleChange={(target) => handleSetYearsOfProfessionalExperienceInput(target.value)}
            decimalSeparator={false}
            label={POLICY_ARIA_LABELS[POLICY_OPTIONS.YEARS_OF_PROFESSIONAL_EXPERIENCE]}
            error={Boolean(invalidFields.find((f) => f.field === "YearsOfProfessionalExperience"))}
            errorText={findAndFormatErrorMessage("YearsOfProfessionalExperience", invalidFields)}
            toolTipContent="YEARS_OF_PROFESSIONAL_EXPERIENCE"
            data-testid="yearsOfProfessionalExperience"
            placeholder="Enter Number Of Years"
            inputStyles={{ marginBottom: 0 }}
          />
        </InputContainer>
        <InputContainer width={248} data-cy="yearsOfProfessionalExperience-div">
          <Dropdown
            label={POLICY_ARIA_LABELS[POLICY_OPTIONS.YEARS_OF_PRIOR_ACTS]}
            id="quote-PL-years-of-prior-acts"
            name="yearsOfPriorActs"
            onChange={(selectedOption) =>
              updatePlCoverage(
                selectedOption.value,
                ProfessionalLiability.yearsOfPriorActs,
                "quote-PL-years-of-prior-acts"
              )
            }
            options={yearsOfPriorActsOptions}
            value={yearsOfPriorActs ?? undefined}
            error={Boolean(invalidFields.find((f) => f.field === "YearsOfPriorActs"))}
            errorText={findAndFormatErrorMessage("YearsOfPriorActs", invalidFields)}
            toolTipContent="YEARS_OF_PRIOR_ACTS"
            full
          />
        </InputContainer>
      </FlexContainer>
      {acknowledgements?.pl_url && (
        <DownloadTerms>
          Download and review the &nbsp;
          <TextLink as="a" href={acknowledgements?.pl_url} target="_blank" rel="noopener noreferrer">
            Professional Liability Terms &amp; Exclusions.
          </TextLink>
        </DownloadTerms>
      )}
    </>
  );
};

const professionalLiabilityLimitOptions = [
  { value: 25000, label: "$25,000 per occ / $50,000 agg" },
  { value: 50000, label: "$50,000 per occ / $100,000 agg" },
  { value: 250000, label: "$250,000 per occ / $500,000 agg" },
  { value: 500000, label: "$500,000 per occ / $1,000,000 agg" },
  { value: 1000000, label: "$1,000,000 per occ / $3,000,000 agg" },
];

const propertyDamageDeductibleOptions = VALUE_OPTIONS[POLICY_OPTIONS.DEDUCTIBLE].map((amount) => {
  return { value: amount, label: formatCurrency(amount) };
});

const yearsOfPriorActsOptions = [
  { value: 0, label: "None" },
  { value: 1, label: "1 Year" },
  { value: 2, label: "2 Years" },
  { value: 3, label: "3 Years" },
  { value: 4, label: "4 Years" },
  { value: 5, label: "5 Years" },
  { value: 6, label: "6 Years" },
];

export const extendedReportingPeriodOptions = [
  { value: 0, label: "60 days (included)" },
  { value: 1, label: "1 Year" },
  { value: 2, label: "2 Years" },
  { value: 3, label: "3 Years" },
  { value: 4, label: "4 Years" },
  { value: 5, label: "5 Years" },
  { value: 6, label: "6 Years" },
];

const mapStateToProps = (state: ReduxState) => ({
  plLimit: selectApplicationFormPLLimit(state),
  yearsOfPriorActs: selectApplicationFormYearsOfPriorActs(state),
  plDeductible: selectApplicationFormPLDeductible(state),
  yearsOfProfessionalExperience: selectApplicationFormYearsOfProfessionalExperience(state),
  periodLoading: selectApplicationFormPeriodLoading(state),
  invalidFields: selectApplicationQuoteErrors(state),
  industryId: selectApplicationFormIndustryId(state),
});

const mapDispatchToProps = { updateApplicationForm, updateApplicationFormPL };

const FlexContainer = styled.div`
  display: flex;
  column-gap: 24px;
  flex-wrap: wrap;
  ${tablet} {
    margin-bottom: 0;
  }
`;

const InputContainer = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  margin-bottom: 24px;
  ${tablet} {
    width: 100%;
  }
`;

const DownloadTerms = styled.div`
  margin-top: 6px;
  font-size: 15px;
`;

const StyledCoverageType = styled(CoverageType)`
  margin-bottom: 24px;
`;

const OverrideStyle = createGlobalStyle`
  ${FlexContainer} {
    ${Label} {
      font-size: 13px;
    }
   ${StyledSelect} {
    margin-bottom: 0;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(PLCoverages);
