import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Card, CardSectionFullWidth, NoDetails, CardSectionTitle } from "./tabContentStyles";
import { extraCoverages } from "utils/proposal-pdf/constants";
import {
  selectApplicationFormEndorsementPackage,
  selectApplicationFormLocationState,
} from "redux/selectors/application";
import endorsement_silver_icon from "images/endorsement_silver.svg";
import endorsement_gold_icon from "images/endorsement_gold.svg";
import endorsement_platinum_icon from "images/endorsement_platinum.svg";
import { EndorsementPackages } from "types/enums";
import { selectShowEndorsementPackages } from "redux/reducers/_availableEndorsements";
import { getSnapshotQuotePremium } from "redux/reducers/_snapshotReducer";
import { theme } from "coterie-ui-library";

interface PropTypes {
  application: Application;
  showEndorsementPackages: boolean;
  endorsementPackage: EndorsementPackages;
}

interface PackageObj {
  icon?: any;
  title: string;
  type: EndorsementPackages;
}

const CoverageComparison = ({ application, showEndorsementPackages }: PropTypes) => {
  const hasBOP = application?.applicationTypes?.includes("BOP");

  const endorsement_packages: PackageObj[] = [
    {
      title: "Base Coverage",
      type: EndorsementPackages.None,
    },
    {
      icon: endorsement_silver_icon,
      title: "Silver Coverage",
      type: EndorsementPackages.Silver,
    },
    {
      icon: endorsement_gold_icon,
      title: "Gold Coverage",
      type: EndorsementPackages.Gold,
    },
    {
      icon: endorsement_platinum_icon,
      title: "Platinum Coverage",
      type: EndorsementPackages.Platinum,
    },
  ];

  return (
    <Card data-cy="policy-details">
      {hasBOP ? (
        <CardSectionFullWidth>
          <CardSectionTitle>Coverage Comparison and Details</CardSectionTitle>
          <CoverageSection>
            <Row>
              <CoverageTitle>{""}</CoverageTitle>
              {endorsement_packages
                .filter((p) => {
                  if (showEndorsementPackages) {
                    return true;
                  } else {
                    const endorsements = [
                      EndorsementPackages.Silver,
                      EndorsementPackages.Gold,
                      EndorsementPackages.Platinum,
                    ];

                    if (endorsements.includes(p.type)) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                })
                .map((endorsement) => {
                  return (
                    <Column key={endorsement.title}>
                      {endorsement.icon ? <Icon src={endorsement.icon} alt="endorsement icon" /> : <Spacer></Spacer>}
                      <HeaderText selectedPackage={application.endorsements?.endorsementPackage === endorsement.type}>
                        {endorsement.title}
                      </HeaderText>
                    </Column>
                  );
                })}
              <Column>
                <DeductibleSpacer></DeductibleSpacer>
                <HeaderText>Deductible</HeaderText>
              </Column>
            </Row>
          </CoverageSection>
          {extraCoverages(application.bppDeductible || 0).map((item) => (
            <Row key={item.coverage}>
              <CoverageTitle>{item.coverage}</CoverageTitle>
              <TableValue>{item.None}</TableValue>
              {showEndorsementPackages && <TableValue>{item.Silver}</TableValue>}
              {showEndorsementPackages && <TableValue>{item.Gold}</TableValue>}
              {showEndorsementPackages && <TableValue>{item.Platinum}</TableValue>}

              <TableValue>{item.deductible}</TableValue>
            </Row>
          ))}
          {showEndorsementPackages && (
            <Highlight selectedHighlight={application.endorsements?.endorsementPackage}></Highlight>
          )}
        </CardSectionFullWidth>
      ) : (
        <NoDetails>There aren't any additional details for this type of policy.</NoDetails>
      )}
    </Card>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableValue = styled.p`
  color: ${(props) => props.theme.fontColors?.primary};
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  width: 97px;
`;

const CoverageTitle = styled.p`
  font-size: 15px;
  font-weight: 300;
  width: 272px;
  padding-right: 85px;
  font-family: ${theme.font.typeface.secondary};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px dotted ${(props) => props.theme.lighterPurple};
  margin-bottom: 12px;
`;

const CoverageSection = styled.div`
  text-align: center;
`;

const HeaderText = styled.p<{ selectedPackage?: boolean }>`
  font-size: 15px;
  font-weight: ${(props) => (props.selectedPackage ? "600" : "300")};
  width: 97px;
  margin-bottom: 12px;
`;

const Icon = styled.img`
  height: 28px;
  width: 28px;
  margin: auto;
`;

const Spacer = styled.div`
  height: 64px;
`;

const DeductibleSpacer = styled.div`
  height: 84px;
`;

const Highlight = styled.div<{ selectedHighlight: EndorsementPackages }>`
  position: absolute;
  background: rgb(66, 35, 255, 0.1);
  width: 112px;
  height: 100%;
  border-radius: 4px;
  z-index: 0;
  top: 38px;
  right: ${({ selectedHighlight }) => {
    switch (selectedHighlight) {
      case EndorsementPackages.None:
        return "55%";
      case EndorsementPackages.Silver:
        return "41%";
      case EndorsementPackages.Gold:
        return "27%";
      case EndorsementPackages.Platinum:
        return "13%";
      default:
        return "55%";
    }
  }};
`;

const mapStateToProps = (state: ReduxState) => ({
  showEndorsementPackages: selectShowEndorsementPackages(state),
  endorsementPackage: selectApplicationFormEndorsementPackage(state),
  locationState: selectApplicationFormLocationState(state),
  premium: getSnapshotQuotePremium(state),
});

export default connect(mapStateToProps)(CoverageComparison);
