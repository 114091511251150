import React, { useState } from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { RadioButton, Header3, laptop } from "styles";
import { PremiumAmountText } from "./styles";
import PolicyStartDatePicker from "styles/PolicyStartDatepicker";
import { formatCurrency } from "utils/formatNumber";
import formotivInstance from "utils/formotiv";
import { formatBackendPolicyDate } from "utils/formatBackendPolicyDate";
import { PaymentFrequency, PolicyType } from "types/enums";
import ActionButtons from "shared/application/ActionButtons";
import AjaxLoader from "shared/AjaxLoader";
import {
  selectApplicationFormDba,
  selectApplicationFormStartDate,
  selectApplicationFormBusinessName,
  selectApplicationQuote,
  selectTotalYearlyOwed,
  selectMonth1Owed,
  selectApplicationFormLossControl,
  selectApplicationFormApplicationTypes,
} from "redux/selectors/application";
import { updateApplicationFormStartDate } from "redux/reducers/_applicationReducer";
import { setSimplybindPaymentFrequency } from "redux/reducers/_underwritingProfileReducer";
import { selectUnderwritingProfilePaymentFrequency } from "redux/selectors/underwritingProfile";
import PriceDrawer from "./PriceDrawer";
import ArrowRightIcon from "images/arrow-right-green.svg";

interface PropTypes {
  startDate: Date;
  updateApplicationFormStartDate: (startDate: string) => void;
  setSimplybindPaymentFrequency: (frequency: PaymentFrequency) => void;
  paymentFrequency: PaymentFrequency;
  dba: string;
  businessName: string;
  quote: Quote;
  totalYearlyOwed: number;
  month1Owed: number;
  lossControl: string | undefined;
  applicationTypes: PolicyType[];
  isQuoteEditPage?: boolean;
}

const PremiumHeader = ({
  startDate,
  updateApplicationFormStartDate,
  setSimplybindPaymentFrequency,
  paymentFrequency,
  businessName,
  dba,
  quote,
  totalYearlyOwed,
  month1Owed,
  lossControl,
  applicationTypes,
  isQuoteEditPage,
}: PropTypes) => {
  const [showPriceDrawer, setShowPriceDrawer] = useState(false);
  const [showPriceDrawerClosing, setShowPriceDrawerClosing] = useState(false);

  const { monthlyPremium = 0, premium = 0 } = quote;
  const isMonthly = paymentFrequency === PaymentFrequency.Monthly;
  const isYearly = paymentFrequency === PaymentFrequency.None;

  const hasBOPorGL = Boolean(applicationTypes?.includes(PolicyType.BOP) || applicationTypes?.includes(PolicyType.GL));
  const lossControlIsOptIn = lossControl === "OptIn";

  const showRecommendedLabel = () => {
    if (hasBOPorGL) {
      return lossControlIsOptIn && isYearly;
    } else {
      return isYearly;
    }
  };

  const handlePriceDrawerClose = () => {
    setShowPriceDrawer(false);
    setShowPriceDrawerClosing(true);
    setTimeout(() => {
      setShowPriceDrawerClosing(false);
    }, 400);
  };

  const formatPremium = () => {
    if (monthlyPremium !== 0 && premium !== 0) {
      return (
        <>
          <CurrencyText>$</CurrencyText>
          {isMonthly ? `${formatCurrency(monthlyPremium).slice(1)}/mo` : `${formatCurrency(premium).slice(1)}/yr`}
        </>
      );
    } else return <AjaxLoader height={26} />;
  };

  const formattedTotalDue = isMonthly ? formatCurrency(month1Owed) : formatCurrency(totalYearlyOwed);

  const paymentFrequencyOptions = [
    { label: "Monthly", checked: isMonthly },
    { label: "Yearly", checked: isYearly },
  ];

  const handleChangeStartDate = (startDate: Date) => {
    formotivInstance.trackCustom("quote-policy-effective-date", "calendar", startDate.toDateString());
    updateApplicationFormStartDate(startDate.toDateString());
  };

  const premiumHeaderTitle = isQuoteEditPage
    ? `${businessName} ${dba && `DBA ${dba}`}`
    : `Purchase policy for ${businessName} ${dba && `DBA ${dba}`}`;

  return (
    <>
      <BlueHeader isQuoteEditPage={isQuoteEditPage}>
        <HeaderContainer>
          <Header isQuoteEditPage={isQuoteEditPage}>{premiumHeaderTitle}</Header>
          <ActionButtons />
        </HeaderContainer>

        {isQuoteEditPage && (
          <PremiumContainer>
            <PremiumText>Premium:</PremiumText>
            {showRecommendedLabel() && <RecommendedLabel>Recommended</RecommendedLabel>}
            <FlexDiv>
              <div>
                <PremiumAmountText isLoading={monthlyPremium === 0} minWidth={224}>
                  {formatPremium()}
                </PremiumAmountText>
                <div style={{ display: "flex", columnGap: "8px" }}>
                  <TotalDueText>Total due today: {formattedTotalDue}</TotalDueText>
                  <TotalDueButton onClick={() => setShowPriceDrawer(true)} data-cy="open-price-breakdown-button">
                    <img src={ArrowRightIcon} alt="Open price breakdown" />
                  </TotalDueButton>
                </div>
              </div>
              <PaymentFrequencyContainer>
                {paymentFrequencyOptions.map((e) => (
                  <div key={e.label}>
                    <RadioButton
                      value={e.label}
                      checked={e.checked}
                      label={e.label}
                      groupName="premiumFrequency"
                      handleClick={() => {
                        setSimplybindPaymentFrequency(
                          e.label === "Monthly" ? PaymentFrequency.Monthly : PaymentFrequency.None
                        );
                      }}
                      inputStyles={radioInputStyles}
                      labelStyles={radioLabelStyles}
                    />
                  </div>
                ))}
              </PaymentFrequencyContainer>
            </FlexDiv>
          </PremiumContainer>
        )}
      </BlueHeader>

      {isQuoteEditPage && (
        <StartDateContainer id="Policy-Effective-Date">
          <PolicyStartDatePicker
            startDate={formatBackendPolicyDate(startDate)}
            onChange={handleChangeStartDate}
            isDisabled={!isQuoteEditPage}
            headerText="Policy Effective Date"
          />
        </StartDateContainer>
      )}

      {(showPriceDrawer || showPriceDrawerClosing) && (
        <PriceDrawer
          onClose={handlePriceDrawerClose}
          isMonthly={isMonthly}
          monthlyPremium={monthlyPremium}
          premium={premium}
          showPriceDrawer={showPriceDrawer}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  startDate: new Date(selectApplicationFormStartDate(state)),
  paymentFrequency: selectUnderwritingProfilePaymentFrequency(state),
  businessName: selectApplicationFormBusinessName(state),
  dba: selectApplicationFormDba(state),
  quote: selectApplicationQuote(state),
  totalYearlyOwed: selectTotalYearlyOwed(state),
  month1Owed: selectMonth1Owed(state),
  lossControl: selectApplicationFormLossControl(state),
  applicationTypes: selectApplicationFormApplicationTypes(state),
});

const mapDispatchToProps = {
  updateApplicationFormStartDate,
  setSimplybindPaymentFrequency,
};

const BlueHeader = styled.div<{ isQuoteEditPage?: boolean }>`
  z-index: 98;
  position: ${(props) => (props.isQuoteEditPage ? "sticky" : "static")};
  top: 0;
  display: flex;
  column-gap: 24px;
  background: ${(props) => props.theme.textNavyBlue};
  padding: 0 48px 0 64px;
  border-radius: 4px 4px 0 0;
  width: 100%;
  min-height: 112px;
  justify-content: space-between;
  margin-bottom: 32px;
  ${laptop} {
    flex-direction: column;
    column-gap: 8px;
    padding: 6px 24px 0;
  }
`;

const HeaderContainer = styled.div`
  max-width: 576px;
`;

const Header = styled(Header3)<{ isQuoteEditPage?: boolean }>`
  font-size: 24px;
  color: white;
  margin-top: ${(props) => (props.isQuoteEditPage ? "24px" : "16px")};
  margin-bottom: ${(props) => (props.isQuoteEditPage ? "16px" : "8px")};
  ${laptop} {
    margin-top: 8px;
  }
`;

const PremiumContainer = styled.div`
  padding-top: 16px;
  margin-bottom: 16px;
  ${laptop} {
    padding-top: 8px;
    margin-bottom: 8px;
  }
`;

const PremiumText = styled(Header3)`
  color: white;
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  margin: 0;
  ${laptop} {
    font-size: 12px;
    line-height: 15px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  column-gap: 16px;
  width: 414px;
`;

const CurrencyText = styled.span`
  font-size: 16px;
  vertical-align: super;
`;

const PaymentFrequencyContainer = styled.div`
  display: flex;
  column-gap: 16px;
  justify-content: space-evenly;
  padding-top: 4px;
  ${laptop} {
    padding-top: 4px;
  }
`;

const TotalDueText = styled.p`
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  display: flex;
  column-gap: 16px;
  justify-content: space-evenly;
  padding-top: 4px;
  color: ${(props) => props.theme.colors.secondary.darkTen};
`;

const TotalDueButton = styled.button`
  display: flex;
  background: transparent;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  border: ${(props) => props.theme.colors.secondary.darkTen} solid 1px;
`;

const RecommendedLabel = styled.div`
  font-weight: 600;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.secondary.dark};
  background-color: ${(props) => props.theme.colors.secondary.twentyFive};
  padding: 4px 8px;
  color: ${(props) => props.theme.colors.secondary.dark};
  width: 115px;
  position: absolute;
  margin-top: -22px;
  margin-left: 170px;
`;

const radioInputStyles = css`
  &:checked + label {
    color: white;
  }
`;

const radioLabelStyles = css`
  color: white;
`;

const StartDateContainer = styled.div`
  max-width: 634px;
  width: 90%;
  margin-bottom: 45px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(PremiumHeader);
