import { Input } from "styles";
import styled from "styled-components";
import React, { useState } from "react";

interface PropTypes {
  modalSize: SizeAttrTypes;
  updateModalSize: React.Dispatch<React.SetStateAction<SizeAttrTypes>>;
}
interface errorType {
  isValidated: boolean;
  errorMessage: string;
}

interface sizeType {
  modalHeight: string;
  modalWidth: string;
}
const OptionalDropin = ({ modalSize, updateModalSize }: PropTypes) => {
  const [temp, setTemp] = useState<sizeType>({ modalHeight: "", modalWidth: "" });
  const [errorWidth, setErrorWidth] = useState<errorType>({ isValidated: true, errorMessage: "" });
  const [errorHeight, setErrorHeight] = useState<errorType>({ isValidated: true, errorMessage: "" });

  const assignErrors = (name: string, message: string) => {
    if (name === "modalWidth" && message === "") {
      setErrorWidth({ isValidated: true, errorMessage: message });
      return;
    }
    if (name === "modalHeight" && message === "") {
      setErrorHeight({ isValidated: true, errorMessage: message });
      return;
    }
    if (name === "modalWidth") {
      setErrorWidth({ isValidated: false, errorMessage: message });
      return;
    }
    if (name === "modalHeight") {
      setErrorHeight({ isValidated: false, errorMessage: message });
      return;
    }
  };
  const isValidCssHeightWidthValue = (value: string) => {
    const style = new Option().style;
    style.height = value;
    return style.height !== "";
  };
  const validateSizeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (val === "") {
      assignErrors(e.target.name, "");
      return;
    }

    if (!isValidCssHeightWidthValue(val)) {
      assignErrors(e.target.name, "Please enter a valid css unit.(px, em, vh, vw, rem, %)");
      return;
    }
    assignErrors(e.target.name, "");
    updateModalSize({ ...modalSize, [e.target.name]: e.target.value });
  };

  return (
    <HeightWidthWrapper>
      <InputLayout>
        <Input
          label="Modal Height"
          type="text"
          name="modalHeight"
          value={temp.modalHeight}
          placeholder="600px"
          error={!errorHeight.isValidated}
          errorText={errorHeight.errorMessage}
          handleChange={(e) => setTemp({ ...temp, [e.target.name]: e.target.value })}
          handleBlur={(e) => validateSizeInput(e)}
        ></Input>
        {errorHeight.isValidated && (
          <HelperLabel id="helper-label">Valid css units are (px, em, vh, rem, %)</HelperLabel>
        )}
      </InputLayout>
      <InputLayout>
        <Input
          label="Modal Width"
          type="text"
          name="modalWidth"
          value={temp.modalWidth}
          placeholder="600px"
          error={!errorWidth.isValidated}
          errorText={errorWidth.errorMessage}
          handleChange={(e) => setTemp({ ...temp, [e.target.name]: e.target.value })}
          handleBlur={(e) => validateSizeInput(e)}
        ></Input>
        {errorWidth.isValidated && (
          <HelperLabel id="helper-label">Valid css units are (px, em, vw, rem, %)</HelperLabel>
        )}
      </InputLayout>
    </HeightWidthWrapper>
  );
};

const HeightWidthWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 380px;
  margin-top: 25px;
`;
const InputLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 169.81px;
`;
const HelperLabel = styled.label`
  color: #4a4a4d;
  font-family: Questrial;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -27px;
  margin-bottom: 0;
`;

export default OptionalDropin;
