import React, { useEffect, useState } from "react";
import { CSSObject } from "styled-components";
import { Input, Textarea } from "styles";

interface RuleTextInputProps {
  defaultValue?: string | null;
  name: string;
  updateRuleProperty: (value: string, index?: number) => void;
  updateKey: string;
  inputStyles: CSSObject;
  type?: string;
  updatePropertyIndex?: number;
  onChange?: (inputValue: string) => void;
  label?: string;
}

const RuleTextInput = ({
  defaultValue,
  name,
  updateRuleProperty,
  updateKey,
  inputStyles,
  type = "text",
  updatePropertyIndex,
  onChange,
  label,
}: RuleTextInputProps) => {
  const [localValue, setLocalValue] = useState("");
  useEffect(() => {
    setLocalValue(defaultValue ?? "");
  }, [defaultValue]);
  return (
    <>
      {type === "textArea" ? (
        <Textarea
          aria-label={label}
          value={localValue}
          name={name}
          onChange={(e: React.ChangeEvent) => {
            setLocalValue((e.currentTarget as HTMLInputElement)?.value);
            if (onChange) onChange((e.currentTarget as HTMLInputElement)?.value);
          }}
          onBlur={(e: React.ChangeEvent) =>
            updateRuleProperty((e.currentTarget as HTMLInputElement)?.value, updatePropertyIndex)
          }
          style={{ marginBottom: "0" }}
        />
      ) : (
        <Input
          label={label}
          labelIsHidden
          type={type}
          value={localValue}
          name={name}
          inputStyles={inputStyles}
          handleChange={(e) => {
            setLocalValue(e.currentTarget?.value);
            if (onChange) onChange(e.currentTarget?.value);
          }}
          handleBlur={(e) => updateRuleProperty(e.currentTarget?.value, updatePropertyIndex)}
        />
      )}
    </>
  );
};

export default RuleTextInput;
