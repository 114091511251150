import { getAffiliatedStatus } from "redux/reducers/_affiliationReducer";
import { createSelector } from "reselect";
import { NonOwnedCoverageTypes, HiredCoverageTypes, AffiliatedStatus, PolicyType } from "types/enums";
import { POLICY_DEFAULTS } from "utils/constants";
import { carriers } from "utils/paymentUtils";
import { selectApplicationFormUserEmail } from "./user";
import { validatePreviousLossesClaims, validatePreviousLossesNumber } from "utils/previousLosses";
import { ApplicationSource } from "types/enums";
import { getSnapshotApplicationIndustryId, getSnapshotApplicationTypes } from "redux/reducers/_snapshotReducer";
import { liquorClasses100 } from "utils/constants/liquorClasses";

export const isFieldInvalid = (field: any) => {
  return createSelector(selectQuoteErrors, (quoteErrors) => {
    return Boolean(quoteErrors.find((item: any) => item.field === field)?.field);
  });
};

export const selectInvalidField = (field: any) => {
  return createSelector(selectQuoteErrors, (quoteErrors) => {
    return quoteErrors.find((item: any) => item.field === field);
  });
};

export const selectApplicationForm = (state: ReduxState) => state.application.form || {};
const selectApplication = (state: ReduxState) => state.application;

export const selectApplicationFormFirstName = (state: ReduxState) =>
  selectApplicationForm(state).contactFirstName || "";
export const selectApplicationFormLastName = (state: ReduxState) => selectApplicationForm(state).contactLastName || "";
export const selectApplicationFormPhone = (state: ReduxState) => selectApplicationForm(state).contactPhone || "";
export const selectApplicationFormEmail = (state: ReduxState) => selectApplicationForm(state).contactEmail || "";
export const selectApplicationFormStreet = (state: ReduxState) =>
  selectApplicationForm(state).mailingAddressStreet || "";
export const selectApplicationFormCity = (state: ReduxState) => selectApplicationForm(state).mailingAddressCity || "";
export const selectApplicationFormState = (state: ReduxState) => selectApplicationForm(state).mailingAddressState || "";
export const selectApplicationFormZip = (state: ReduxState) => selectApplicationForm(state).mailingAddressZip || "";
export const selectApplicationFormBusinessName = (state: ReduxState) => state.application.form?.businessName || "";
export const selectApplicationFormDba = (state: ReduxState) => selectApplicationForm(state).dba || "";
export const selectApplicationFormIndustryId = (state: ReduxState) => selectApplicationForm(state).industryId || 0;
export const selectApplicationFormLimitDamagePremRented = (state: ReduxState) =>
  selectApplicationForm(state).limitDamagePremRented || undefined!;
export const selectIaDetailsProducerExternalID = (state: ReduxState) =>
  selectApplicationForm(state).iaDetails?.producerExternalId || "";
export const selectMailingAddress = createSelector(
  [selectApplicationFormStreet, selectApplicationFormCity, selectApplicationFormState, selectApplicationFormZip],
  (street, city, state, zip) => {
    if (street) {
      return { street, city, state, zip };
    } else {
      return {};
    }
  }
);

export const selectQuoteErrors = (state: ReduxState) => {
  const clientSideQuoteErrors = Object.keys(CLIENT_SIDE_INVALID_FIELDS).reduce((acc: any, curr: any) => {
    const invalidField = CLIENT_SIDE_INVALID_FIELDS[curr];
    const fieldComplete = invalidField.selector(state);
    if (!fieldComplete) {
      return [...acc, { field: invalidField.field, message: invalidField.message }];
    } else {
      return acc;
    }
  }, []);
  return [...state.application.quoteErrors, ...clientSideQuoteErrors];
};

export const selectApplicationFormEmployees = (state: ReduxState) =>
  selectApplicationForm(state).numEmployees || undefined;
export const selectApplicationFormPayroll = (state: ReduxState) =>
  selectApplicationForm(state).annualPayroll || undefined!;
export const selectApplicationFormSales = (state: ReduxState) =>
  selectApplicationForm(state).grossAnnualSales || undefined!;
export const selectApplicationFormApplicationTypes = (state: ReduxState) =>
  selectApplicationForm(state).applicationTypes || [];
export const selectApplicationFormBusinessAge = (state: ReduxState) =>
  selectApplicationForm(state).businessAgeInMonths || undefined;
export const selectApplicationFormLosses = (state: ReduxState) => selectApplicationForm(state).previousLosses || [];
export const selectApplicationFormLocation = (state: ReduxState) =>
  state.application.form?.locations?.[0] || { zip: "", street: "", state: "", city: "", locationType: "" };
export const selectApplicationFormLocationState = (state: ReduxState) =>
  state.application.form?.locations?.[0]?.state || "";
export const selectApplicationFormLocationStreet = (state: ReduxState) =>
  state.application.form?.locations?.[0]?.street || "";
export const selectApplicationFormLocationType = (state: ReduxState) =>
  state.application.form?.locations?.[0]?.locationType || "";
export const selectApplicationFormSquareFootage = (state: ReduxState) =>
  state.application.form?.locations?.[0]?.squareFootage || 0;

export const selectApplicationFormBPPLimit = (state: ReduxState) =>
  state.application.form?.locations?.[0]?.bppLimit || 0;

export const selectApplicationFormBuildingLimit = (state: ReduxState) =>
  state.application.form?.locations?.[0]?.buildingLimit || 0;

// export const selectApplicationFormHasSprinklers = (state: ReduxState) => {
//   const locationPresent = state.application.form.locations && state.application.form.locations.length > 0;
//   if (
//     locationPresent &&
//     state.application.form.locations &&
//     state.application.form.locations[0].hasSprinklers === true
//   ) {
//     return "Yes";
//   } else if (
//     locationPresent &&
//     state.application.form.locations &&
//     state.application.form.locations[0].hasSprinklers === false
//   ) {
//     return "No";
//   } else {
//     return undefined;
//   }
// };

export const selectApplicationFormHasSprinklers = (state: ReduxState) =>
  state.application.form?.locations?.[0]?.hasSprinklers;

export const selectApplicationFormPLLimit = (state: ReduxState) =>
  selectApplicationForm(state).professionalLiability?.occurrenceLimit ||
  POLICY_DEFAULTS.PL.professionalLiability.occurrenceLimit;

export const selectApplicationFormPLDeductible = (state: ReduxState) =>
  selectApplicationForm(state).professionalLiability?.deductibleAmount || 0;

export const selectApplicationFormYearsOfProfessionalExperience = (state: ReduxState) =>
  selectApplicationForm(state).professionalLiability?.yearsOfProfessionalExperience ?? undefined!;

export const selectApplicationFormYearsOfPriorActs = (state: ReduxState) =>
  selectApplicationForm(state).professionalLiability?.yearsOfPriorActs ?? undefined!;

export const selectApplicationFormPeriodLoading = (state: ReduxState) =>
  selectApplicationForm(state).professionalLiability?.periodLoading || 0;

export const selectApplicationFormBPPDeductible = (state: ReduxState) =>
  selectApplicationForm(state).bppDeductible || 0;

export const selectApplicationFormGLLimit = (state: ReduxState) => selectApplicationForm(state).glLimit || 0;
export const selectApplicationFormGLAggregatePcoLimit = (state: ReduxState) =>
  selectApplicationForm(state).glAggregatePcoLimit || 0;

export const selectApplicationFormPropertyDamageLiabilityDeductible = (state: ReduxState) =>
  selectApplicationForm(state).propertyDamageLiabilityDeductible || 0;

export const selectApplicationFormNonOwnedCoverage = (state: ReduxState) =>
  selectApplicationForm(state)?.endorsements?.hiredAutoAndNonOwnedAuto?.nonOwnedCoverage || "";

export const selectApplicationFormHiredCoverage = (state: ReduxState) =>
  selectApplicationForm(state)?.endorsements?.hiredAutoAndNonOwnedAuto?.hiredCoverage || "";

export const selectApplicationFormEmployeeVehicles = (state: ReduxState) => {
  if (selectApplicationFormNonOwnedCoverage(state) === 0) {
    return NonOwnedCoverageTypes.None;
  } else if (selectApplicationFormNonOwnedCoverage(state) === 1) {
    return "Non-owned auto liability with delivery service";
  } else if (selectApplicationFormNonOwnedCoverage(state) === 2) {
    return "Non-owned auto liability without delivery service";
  } else {
    return "None";
  }
};

export const selectApplicationFormRentVehicles = (state: ReduxState) => {
  if (selectApplicationFormHiredCoverage(state) === 0) {
    return HiredCoverageTypes.None;
  } else if (selectApplicationFormHiredCoverage(state) === 1) {
    return "Hired auto liability";
  } else {
    return HiredCoverageTypes.None;
  }
};

export const selectApplicationFormDesiredPolicyType = (state: ReduxState) =>
  state.application.form?.desiredPolicyType || [];
export const selectApplicationFormBppCoverage = (state: ReduxState) => state.application.form?.bppCoverage || false;
export const selectApplicationFormBuildingCoverage = (state: ReduxState) =>
  state.application.form?.buildingCoverage || false;

export const selectAllEndorsements = (state: ReduxState) => state.application.form?.endorsements;

export const selectApplicationFormIndividualAdditionalInsureds = (state: ReduxState) =>
  state.application.form?.endorsements?.additionalInsureds || [];

export const selectApplicationFormPreviousLosses = (state: ReduxState) => state.application.form?.previousLosses || [];

export const selectEndorsements = (state: ReduxState) => selectApplicationForm(state).endorsements || {};

export const selectApplicationFormStartDate = (state: ReduxState) =>
  selectApplicationForm(state).policyStartDate || new Date();
export const selectApplicationFormEndorsementPackage = (state: ReduxState) =>
  selectApplicationForm(state).endorsements?.endorsementPackage || "None";
export const selectApplicationFormLiquorEndorsement = (state: ReduxState) =>
  selectApplicationForm(state).endorsements?.liquorLiabilityEndorsement;
export const selectApplicationFormRestaurantEndorsement = (state: ReduxState) =>
  selectApplicationForm(state).endorsements?.restaurantCoverage;
export const selectApplicationFormEquipmentEndorsement = (state: ReduxState) =>
  selectApplicationForm(state).endorsements?.equipmentBreakdownEndorsement;

export const selectHasEmptyIndividualAI = (state: ReduxState) =>
  selectApplicationFormIndividualAdditionalInsureds(state)[0]?.name === "";

export const selectApplicationFormId = (state: ReduxState) => selectApplicationForm(state).applicationId || "";

export const selectApplicationFormLossControl = (state: ReduxState) => selectApplicationForm(state).lossControl;

export const selectIndustryName = (state: ReduxState) => selectApplication(state).industryName;
export const selectNaic = (state: ReduxState) => selectApplication(state).naic;
export const selectIsApplicationLoading = (state: ReduxState) => selectApplication(state).loadingQuote;
export const selectIsApplicationUpdating = (state: ReduxState) => selectApplication(state).updatingQuote;
export const selectApplicationAvailablePolicyTypes = (state: ReduxState) =>
  selectApplication(state).availablePolicyTypes || [];
export const selectTwoFourLimitsAvailable = (state: ReduxState) => selectApplication(state).twoFourLimitsAvailable;

export const selectApplicationNetworkError = (state: ReduxState) => selectApplication(state).networkError;
export const selectApplicationQuoteErrors = (state: ReduxState) => selectQuoteErrors(state);
export const selectApplicationQuote = (state: ReduxState) => selectApplication(state).quote;
export const selectApplicationQuotePremium = (state: ReduxState) => selectApplicationQuote(state).premium;
export const selectApplicationCoverageQuotes = (state: ReduxState) => selectApplicationQuote(state)?.quotes || [];

export const selectIsApplicationBindable = (state: ReduxState) => {
  const isBindable = selectApplication(state).bindable;
  const hasLiquorSalesSelected = selectApplicationFormLiquorEndorsement(state)?.alcoholicBeverageSales;
  const previousLosses = selectApplicationFormPreviousLosses(state);
  const naic = selectNaic(state);
  const isLiquorException = liquorClasses100.includes(naic);

  if (hasLiquorSalesSelected && !isLiquorException) {
    const isLiquorLiabilityLimitValid = hasLiquorSalesSelected <= selectApplicationFormSales(state) / 2;
    return isLiquorLiabilityLimitValid && isBindable;
  }
  const hasTooManyEmployees = (selectApplicationFormEmployees(state) ?? 0) > 50;
  const hasInvalidClaim = validatePreviousLossesNumber(previousLosses) || validatePreviousLossesClaims(previousLosses);
  const hasIncompleteCoverages = !selectIsCoveragesCompleted(state);
  const hasEmptyIndividualAI = selectHasEmptyIndividualAI(state);
  const hasQuoteErrors = selectQuoteErrors(state).length > 0;

  if (hasTooManyEmployees || hasInvalidClaim || hasIncompleteCoverages || hasQuoteErrors || hasEmptyIndividualAI) {
    return false;
  }

  return isBindable;
};

export const selectIsBusinessDetailsCompleted = createSelector(
  [selectQuoteErrors, selectApplicationFormPreviousLosses, selectApplicationFormEmployees],
  (quoteErrors, previousLosses, numEmployees) => {
    const hasInvalidClaim =
      validatePreviousLossesNumber(previousLosses) || validatePreviousLossesClaims(previousLosses);
    const hasTooManyEmployees = (numEmployees ?? 0) > 50;
    const invalidFields = quoteErrors.map((field: InvalidField) => field.field);

    return (
      !invalidFields.includes("AnnualPayroll") &&
      !invalidFields.includes("GrossAnnualSales") &&
      !invalidFields.includes("NumberOfEmployees") &&
      !invalidFields.includes("BusinessAgeInMonths") &&
      !invalidFields.includes("BusinessName") &&
      !invalidFields.includes("LegalEntityName") &&
      !hasInvalidClaim &&
      !hasTooManyEmployees
    );
  }
);

export const selectIsCoveragesSelected = createSelector(selectQuoteErrors, (quoteErrors) => {
  const invalidFields = quoteErrors.map((field: InvalidField) => field.field);
  return !invalidFields.includes("ApplicationTypes");
});

export const selectIsBOPCoverageCompleted = createSelector(selectQuoteErrors, (quoteErrors) => {
  const invalidFields = quoteErrors.map((field: InvalidField) => field.field);
  return (
    !invalidFields.includes("BppLimit") &&
    !invalidFields.includes("BuildingLimit") &&
    !invalidFields.includes("BusinessAddress")
  );
});

export const selectIsGLCoverageCompleted = createSelector(selectQuoteErrors, (quoteErrors) => {
  const invalidFields = quoteErrors.map((field: InvalidField) => field.field);
  return !invalidFields.includes("GlLimit");
});

export const selectIsPLCoverageCompleted = createSelector(selectQuoteErrors, (quoteErrors) => {
  const invalidFields = quoteErrors.map((field: InvalidField) => field.field);
  return !invalidFields.includes("YearsOfPriorActs") && !invalidFields.includes("YearsOfProfessionalExperience");
});

export const selectIsCoveragesCompleted = createSelector(selectQuoteErrors, (quoteErrors) => {
  const invalidFields = quoteErrors.map((field: InvalidField) => field.field);
  return (
    !invalidFields.includes("BppLimit") &&
    !invalidFields.includes("BuildingLimit") &&
    !invalidFields.includes("YearsOfPriorActs") &&
    !invalidFields.includes("YearsOfProfessionalExperience") &&
    !invalidFields.includes("BusinessAddress")
  );
});

export const selectIsContactDetailsCompleted = createSelector(selectQuoteErrors, (quoteErrors) => {
  const invalidFields = quoteErrors.map((field: InvalidField) => field.field);
  return (
    !invalidFields.includes("ContactEmail") &&
    !invalidFields.includes("ContactFirstName") &&
    !invalidFields.includes("ContactLastName") &&
    !invalidFields.includes("MailingAddressStreet") &&
    !invalidFields.includes("ContactPhone")
  );
});

export const selectIsAdditionalInsuredsDetailsCompleted = createSelector(
  [selectQuoteErrors, selectHasEmptyIndividualAI],
  (quoteErrors, hasEmptyIndividualAI) => {
    const invalidFields = quoteErrors.map((field: InvalidField) => field.field);

    return !invalidFields.includes("Endorsements") && !hasEmptyIndividualAI;
  }
);

export const selectQuotePremium = (state: ReduxState) => selectApplicationQuote(state)?.monthlyPremium;

export const selectIsReadyToBindKit = createSelector(
  [selectIsApplicationBindable, selectQuotePremium, selectIsApplicationLoading],
  (isApplicationBindable, selectQuotePremium, loading) => {
    return Boolean(isApplicationBindable && Boolean(selectQuotePremium) && !loading);
  }
);

export const selectIsReadyToBindDash = createSelector(
  [selectIsReadyToBindKit, getAffiliatedStatus],
  (readyToBindKit, affiliatedStatus) => {
    const isAffiliated = affiliatedStatus === AffiliatedStatus.Affiliated;
    return Boolean(readyToBindKit && isAffiliated);
  }
);

export const selectIsApplicationReadyToView = createSelector(
  [selectIsApplicationBindable, selectQuotePremium, selectIsApplicationLoading, selectIsApplicationUpdating],
  (isApplicationBindable, selectQuotePremium, loading, updating) => {
    return Boolean(isApplicationBindable && Boolean(selectQuotePremium) && !loading && !updating);
  }
);

export const selectApplicationHasBopCoverage = createSelector(
  selectApplicationFormApplicationTypes,
  (applicationTypes) => applicationTypes.includes(PolicyType.BOP)
);

export const selectQuoteHasSpinnakerCarrier = createSelector(selectApplicationCoverageQuotes, (coverageQuotes) =>
  Boolean(
    coverageQuotes.find((quote: any) => quote.policyType === PolicyType.BOP || quote.policyType === PolicyType.GL)
      ?.insuranceCarrier === carriers.SPINNAKER
  )
);

export const isBusinessAddressComplete = createSelector(
  [selectApplicationHasBopCoverage, selectApplicationFormLocationStreet],
  (isBOP, hasBusinessAddress) => {
    return (isBOP && hasBusinessAddress?.length > 0) || !isBOP;
  }
);

export const isEmailAddressUniqueToProducersEmail = createSelector(
  [selectApplicationFormEmail, selectApplicationFormUserEmail],
  (policyholderEmail, usersEmail) => {
    if (!policyholderEmail) {
      return true;
    } else {
      return policyholderEmail.toLowerCase() !== usersEmail.toLowerCase();
    }
  }
);

const CLIENT_SIDE_INVALID_FIELDS: any = {
  businessAddress: {
    field: "BusinessAddress",
    message: "Business address is required",
    selector: isBusinessAddressComplete,
  },
  uniquePolicyholderEmailAddress: {
    selector: isEmailAddressUniqueToProducersEmail,
    field: "ContactEmail",
    message: "Policyholder email must differ from producer",
  },
};

export const selectApplicationSourceVersion = (state: ReduxState) => selectApplicationForm(state).sourceVersion;
export const selectApplicationSource = (state: ReduxState) => selectApplicationForm(state).source;
export const selectIsAPISourcedApplication = (state: ReduxState) =>
  selectApplicationSource(state) === ApplicationSource.API;

export const selectApplicationTypesForPayment = createSelector(
  [selectApplicationFormApplicationTypes, getSnapshotApplicationTypes],
  (applicationFormApplicationTypes, snapshotApplicationTypes) => {
    return applicationFormApplicationTypes.length > 0 ? applicationFormApplicationTypes : snapshotApplicationTypes;
  }
);

export const selectIndustryIdForPayment = createSelector(
  [selectApplicationFormIndustryId, getSnapshotApplicationIndustryId],
  (applicationFormIndustryId, snapshotIndustryId) => {
    return applicationFormIndustryId || snapshotIndustryId;
  }
);

export const selectFees = (state: ReduxState) => selectApplicationQuote(state)?.quotes?.[0]?.fees ?? [];

// policy fees and installment fees
export const selectMonth1Fees = (state: ReduxState) => selectApplicationQuote(state)?.month1Fees;

// monthly premium + month1Fees
export const selectMonth1Owed = (state: ReduxState) => selectApplicationQuote(state)?.month1Owed;

// policy fees + installment fees, when user pays yearly
export const selectTotalYearlyFees = (state: ReduxState) => selectApplicationQuote(state)?.totalYearlyFees;

// yearly premium + totalYearlyFees, when user pays yearly
export const selectTotalYearlyOwed = (state: ReduxState) => selectApplicationQuote(state)?.totalYearlyOwed;

// future monthly fees per month
export const selectMonthlyFees = (state: ReduxState) => selectApplicationQuote(state)?.monthlyFees;

// future monthly premium + monthlyFees
export const selectMonthlyOwed = (state: ReduxState) => selectApplicationQuote(state)?.monthlyOwed;

export const selectPolicyFees = (state: ReduxState) => selectApplicationQuote(state)?.policyFees;
export const selectInstallmentFees = (state: ReduxState) => selectApplicationQuote(state)?.installmentFees;
export const selectLossControlFees = (state: ReduxState) => selectApplicationQuote(state)?.lossControlFees;

export const selectHasFees = (state: ReduxState) => Boolean(selectTotalYearlyFees(state));
export const selectHasPolicyFee = (state: ReduxState) => Boolean(selectPolicyFees(state));
export const selectFeesSavings = (state: ReduxState) => selectInstallmentFees(state) * 11;

export const selectHasFirstMonthWaived = (state: ReduxState) =>
  selectFees(state).find((fee: Fee) => fee?.description === "Installment Fee")?.offset === 1;
