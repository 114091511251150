import React from "react";
import { connect, useSelector } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import updateApplicationFromServer from "redux/actions/updateApplicationFromServer";
import {
  selectApplicationFormStartDate,
  selectApplicationTypesForPayment,
  selectIndustryIdForPayment,
} from "redux/selectors/application";
import { selectUnderwritingProfilePaymentFrequency } from "redux/selectors/underwritingProfile";
import { selectAccountUserName, getUserExternalId } from "redux/selectors/user";
import {
  getSnapshotApplicationStartDate,
  getSnapshotProducerId,
  getSnapshotPartnerId,
  setSnapshotApplicationStartDate,
} from "redux/reducers/_snapshotReducer";
import { updateApplicationFormStartDate } from "redux/reducers/_applicationReducer";
import { setSimplybindPaymentFrequency } from "redux/reducers/_underwritingProfileReducer";
import useAuth from "hooks/useAuth";
import useGetAcknowledgements from "hooks/useGetAcknowledgements";
import useGetAgencyProducers from "hooks/useGetAgencyProducers";
import useFees from "hooks/useFees";
import { PaymentFrequency, PolicyType } from "types/enums";
import formotivInstance from "utils/formotiv";
import { formatCurrency } from "utils/formatNumber";
import { formatBackendPolicyDate } from "utils/formatBackendPolicyDate";
import { Checkbox, TextLink, laptop, Dropdown, Header3 } from "styles";
import PolicyStartDatePicker from "styles/PolicyStartDatepicker";
import { OptionType } from "styles/Dropdown";
import {
  Error,
  StateNoticeText as StateNoticeTextDefault,
  SimplybindStateNoticeText,
} from "shared/application/StyledComponents";
import { Row, Column } from "shared/ModalComponents";
import AjaxLoader from "shared/AjaxLoader";
import ToolTipIcon from "shared/ToolTipIcon";
import PaymentConfirmationInputs from "./ConfirmationInputs";
import PaymentCreditCard from "./CreditCard";
import usePostPublicApplication from "hooks/usePostPublicApplication";
import { useQueryClient, useIsFetching } from "@tanstack/react-query";
import { stateRequirementsTextSnapshot } from "../constants";
import CheckmarkIcon from "images/checkmark-light-blue.svg";
import WarningIcon from "images/warning-orange.svg";
import { Text } from "utils/toolTipContent";
import {
  selectIsOptInLowerCost,
  selectLossControlOptOutPremium,
  selectLossControlOptOutMonthlyPremium,
  selectOptInCostDifferenceMonthly,
  selectOptInCostDifferenceYearly,
} from "redux/selectors/global";

interface PaymentDetailsInputsProps {
  isSimplybind?: boolean;
  quote: BindableQuote;
  startDate: Date;
  snapshotStartDate: Date;
  updateApplicationFormStartDate: (startDate: string) => void;
  updateApplicationFromServer: (arg0: APIVersionAuth) => void;
  industryId: number;
  frequency: PaymentFrequency;
  setFrequency: (frequency: PaymentFrequency) => void;
  errorMessage: string;
  setErrorMessage: (error: string) => void;
  sendDocs: boolean;
  setDocs: any;
  isSnapshot: boolean;
  setAllTermsChecked: (checked: boolean) => void;
  setSimplybindPaymentFrequency: (frequency: PaymentFrequency) => void;
  simplyBindPaymentFrequency: PaymentFrequency;
  setPlReviewChecked?: (checked: boolean) => void;
  plReviewChecked?: boolean;
  handleBindAsChange?: any;
  currentUserExternalId?: string;
  userName?: string;
  applicationTypes: PolicyType[];
  application: Application;
  producerExternalId?: string;
  snapshotPartnerId?: string;
  stateRequirementsIsChecked?: boolean;
  setStateRequirementsIsChecked?: (checked: boolean) => void;
}

const PaymentDetailsInputs = ({
  isSimplybind,
  quote,
  startDate,
  snapshotStartDate,
  updateApplicationFormStartDate,
  updateApplicationFromServer,
  industryId,
  frequency,
  setFrequency,
  errorMessage,
  setErrorMessage,
  sendDocs,
  setDocs,
  isSnapshot,
  setAllTermsChecked,
  setSimplybindPaymentFrequency,
  simplyBindPaymentFrequency,
  setPlReviewChecked,
  plReviewChecked,
  handleBindAsChange,
  currentUserExternalId,
  userName,
  applicationTypes,
  application,
  producerExternalId,
  snapshotPartnerId,
  stateRequirementsIsChecked,
  setStateRequirementsIsChecked,
}: PaymentDetailsInputsProps) => {
  const { isDeveloper, isNonlicensedUser, isIAUser, getToken } = useAuth();
  const { apiVersion, showNonLicensedUserLink, allowDateChangeInSnapshot } = useFeatureFlags();

  const { monthlyPremium, premium, stateNoticeText } = quote;

  const {
    monthlyFees,
    month1Owed,
    totalYearlyOwed,
    monthlyOwed,
    policyFees,
    installmentFees,
    lossControlFees,
    hasPolicyFee,
    feesSavings,
    hasFirstMonthWaived,
  } = useFees(isSimplybind);

  const isMonthly = isSimplybind
    ? simplyBindPaymentFrequency === PaymentFrequency.Monthly
    : frequency === PaymentFrequency.Monthly;

  const lossControlIsOptIn = Boolean(lossControlFees);
  const renderLossControl = applicationTypes.includes(PolicyType.BOP) || applicationTypes.includes(PolicyType.GL);
  const isOptInLowerCost = useSelector(selectIsOptInLowerCost);
  const lossControlOptOutPremium = useSelector(selectLossControlOptOutPremium);
  const lossControlOptOutMonthlyPremium = useSelector(selectLossControlOptOutMonthlyPremium);
  const optInCostDifferenceMonthly = useSelector(selectOptInCostDifferenceMonthly);
  const optInCostDifferenceYearly = useSelector(selectOptInCostDifferenceYearly);

  const alternatePremium = isMonthly ? lossControlOptOutMonthlyPremium : lossControlOptOutPremium;

  const showPremiumStrikethrough = (label: string) => {
    return label === "Premium" && lossControlIsOptIn && isOptInLowerCost;
  };

  const getLossControlSavingsMessage = () => {
    if (isOptInLowerCost) {
      return `${lossControlIsOptIn ? "Saving" : "Save"} ${
        isMonthly ? formatCurrency(optInCostDifferenceMonthly) : formatCurrency(optInCostDifferenceYearly)
      }${isMonthly ? "/mo" : "/year"} with Manage My Risk discount`;
    } else if (lossControlIsOptIn) {
      return "Maximizing business protection with Manage My Risk";
    } else {
      return "Increase protection with Manage My Risk";
    }
  };

  const PriceDetails = [
    { label: "Premium", amount: isMonthly ? monthlyPremium : premium, isVisible: true },
    { label: "Annual Policy Fee", amount: policyFees, isVisible: hasPolicyFee },
    { label: "Installment Fee", amount: installmentFees, isVisible: !hasFirstMonthWaived && Boolean(installmentFees) },
    {
      label: "Manage My Risk Program",
      amount: isMonthly ? lossControlFees : lossControlFees * 12,
      isVisible: renderLossControl && lossControlIsOptIn,
    },
  ];

  const queryClient = useQueryClient();
  const isFetching = useIsFetching();

  const { mutate: saveApplication } = usePostPublicApplication(() => {
    queryClient.invalidateQueries({
      queryKey: ["commercial_application", application.applicationId],
    });
  });

  const handleChangeStartDate = async (startDate: Date) => {
    const token = await getToken();
    formotivInstance.trackCustom("comfirm-policy-start-date", "calendar");
    if (!isSimplybind) {
      const updatedApplication = {
        ...application,
        policyStartDate: startDate.toDateString(),
      } as Application;
      updatedApplication.producerExternalID = producerExternalId;
      setSnapshotApplicationStartDate(startDate.toDateString());
      saveApplication({ application: updatedApplication, token: snapshotPartnerId });
    } else {
      updateApplicationFormStartDate(startDate.toDateString());
      updateApplicationFromServer({ token, isIAUser, apiVersion });
    }
  };

  const handleBindAs = (bindAs: OptionType) => {
    handleBindAsChange(bindAs.value);
  };

  const { isLoading, data } = useGetAcknowledgements(industryId);

  const { isLoading: isLoadingProducers, data: producers } = useGetAgencyProducers({
    enabled: !isSnapshot,
  });

  const FrequencyButton = isSimplybind ? SimplybindFrequencyButton : PaymentButton;
  const StateNoticeText = isSimplybind ? SimplybindStateNoticeText : StateNoticeTextDefault;
  const reviewPaymentDetailsText =
    "Review and confirm the items in the checkboxes, enter your payment details, then click Pay to bind the policy.";
  const showBindAs =
    !isDeveloper && !isSnapshot && currentUserExternalId && userName && producers && (producers as any[]).length > 0;

  const handleFrequencyChange = (frequency: PaymentFrequency) => {
    if (isSimplybind) {
      formotivInstance.trackCustom(
        `confirm-billing-plan-frequency-${frequency === "Monthly" ? "monthly" : "yearly"}`,
        "billing select"
      );
      setFrequency(frequency);
      setSimplybindPaymentFrequency(frequency);
    } else {
      setFrequency(frequency);
    }
  };

  const formattedTotalDue = isMonthly ? formatCurrency(month1Owed) : formatCurrency(totalYearlyOwed);

  const showRecommendedLabel = () => {
    if (renderLossControl) {
      return lossControlIsOptIn && !isMonthly;
    } else {
      return !isMonthly;
    }
  };

  return (
    <FlexContainer>
      {isSimplybind ? <OverrideStyle /> : null}
      {isSimplybind && (
        <FlexColumn isSimplybind={isSimplybind}>
          <ReviewPaymentDetailsText>{reviewPaymentDetailsText}</ReviewPaymentDetailsText>
          <PaymentConfirmationInputs
            setAllTermsChecked={setAllTermsChecked}
            PLConsentShouldShow={applicationTypes.includes(PolicyType.PL)}
            industryId={industryId}
            setDocs={setDocs}
            sendDocs={sendDocs}
          />
        </FlexColumn>
      )}
      <FlexColumn isSimplybind={isSimplybind}>
        {isSimplybind && (
          <PaymentDetailsColumn>
            <Title>Payment Details</Title>
            <TotalDueRow>
              <Subtitle>Total Due:</Subtitle>
              {showRecommendedLabel() && (
                <FlexEndDiv>
                  <StyledRecommendedLabel>Recommended</StyledRecommendedLabel>
                </FlexEndDiv>
              )}
            </TotalDueRow>
            <TotalAmountRow>
              <TotalDueAmountText data-cy="modal-total-due-details" isLoading={false}>
                {isFetching ? <AjaxLoader /> : formattedTotalDue}
              </TotalDueAmountText>
              <PaymentFrequencyContainer isSimplybind={isSimplybind}>
                <PaymentButtonContainer isSimplybind={isSimplybind}>
                  <FrequencyButton
                    data-cy="cc-pay-monthly"
                    onClick={() => {
                      handleFrequencyChange(PaymentFrequency.Monthly);
                    }}
                    active={isMonthly}
                    type="button"
                  >
                    Monthly
                  </FrequencyButton>
                  <FrequencyButton
                    data-cy="cc-pay-in-full"
                    onClick={() => {
                      handleFrequencyChange(PaymentFrequency.None);
                    }}
                    active={frequency === PaymentFrequency.None}
                    type="button"
                  >
                    Yearly
                  </FrequencyButton>
                </PaymentButtonContainer>
              </PaymentFrequencyContainer>
            </TotalAmountRow>
            <PriceDetailsContainer>
              {PriceDetails.filter((e) => e.isVisible).map((detail) => (
                <SpaceBetweenDiv key={detail.label}>
                  <PriceLabel>{`${detail.label}:`}</PriceLabel>
                  {showPremiumStrikethrough(detail.label) ? (
                    <PremiumAmountSpan>
                      <PremiumLabel>{formatCurrency(detail.amount)}</PremiumLabel>
                      <StrikethroughPremiumLabel>{formatCurrency(alternatePremium)}</StrikethroughPremiumLabel>
                    </PremiumAmountSpan>
                  ) : (
                    <PriceLabel>{formatCurrency(detail.amount)}</PriceLabel>
                  )}
                </SpaceBetweenDiv>
              ))}
              {isMonthly && (
                <FuturePaymentContainer>
                  <FuturePaymentLabel>
                    Future Monthly Payments:
                    {monthlyFees && (
                      <ToolTipIcon
                        content={
                          <Text>
                            Future monthly payments include {formatCurrency(monthlyFees as number)} in monthly fees with
                            the monthly premium payment.
                          </Text>
                        }
                      />
                    )}
                  </FuturePaymentLabel>
                  <PriceLabel>{formatCurrency(monthlyOwed)}</PriceLabel>
                </FuturePaymentContainer>
              )}
            </PriceDetailsContainer>
            {(Boolean(feesSavings) || renderLossControl) && (
              <SavingsContainer>
                {Boolean(feesSavings) && (
                  <SavingsText isWarningStyle={isMonthly}>
                    <Icon>
                      <img src={isMonthly ? WarningIcon : CheckmarkIcon} alt="Icon" />
                    </Icon>
                    {isMonthly ? "Save" : "Saving"} {formatCurrency(feesSavings)} by selecting yearly plan
                  </SavingsText>
                )}
                {renderLossControl && (
                  <SavingsText isWarningStyle={!lossControlIsOptIn}>
                    <Icon>
                      <img src={lossControlIsOptIn ? CheckmarkIcon : WarningIcon} alt="Icon" />
                    </Icon>
                    {getLossControlSavingsMessage()}
                  </SavingsText>
                )}
              </SavingsContainer>
            )}
          </PaymentDetailsColumn>
        )}
        <StyledRow>
          <ColumnWidth100>
            <PolicyStartDatePicker
              startDate={isSnapshot ? formatBackendPolicyDate(snapshotStartDate) : formatBackendPolicyDate(startDate)}
              onChange={handleChangeStartDate}
              isDisabled={isSnapshot && !allowDateChangeInSnapshot}
            />
          </ColumnWidth100>
        </StyledRow>
        <PaymentCreditCard
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          isSnapshot={Boolean(isSnapshot)}
        />
        <Error>{errorMessage}</Error>
        <StyledRow>
          <StyledColumn>
            {showNonLicensedUserLink && showBindAs && (
              <Dropdown
                onChange={handleBindAs}
                defaultValue={
                  isNonlicensedUser
                    ? ({ value: "Select", label: "Select a User" } as OptionType)
                    : ({ value: currentUserExternalId, label: userName } as OptionType)
                }
                options={producers as OptionType[]}
                value={"options"}
                full={true}
                name="bindAsOptions"
                label="Bind as"
                toolTipContent="BIND_AS_DIFF_USER"
                isDisabled={isLoadingProducers}
              />
            )}
          </StyledColumn>
        </StyledRow>
        {!isSimplybind && (
          <>
            {applicationTypes.includes(PolicyType.PL) &&
              setPlReviewChecked &&
              plReviewChecked !== undefined &&
              !isLoading && (
                <Checkbox
                  label={
                    <LabelText>
                      I have read the Professional Liability{" "}
                      <StyledTextLink as="a" href={data?.pl_url} target="_blank" rel="noopener noreferrer">
                        Terms & Exclusions.
                      </StyledTextLink>
                    </LabelText>
                  }
                  name="reviewPlTerms"
                  handleClick={() => {
                    setPlReviewChecked(!plReviewChecked);
                  }}
                  checked={Boolean(plReviewChecked)}
                />
              )}
            <Checkbox
              label={stateRequirementsTextSnapshot}
              name="stateRequirements"
              handleClick={() => {
                if (setStateRequirementsIsChecked) {
                  setStateRequirementsIsChecked(!stateRequirementsIsChecked);
                }
              }}
              checked={stateRequirementsIsChecked as boolean}
              labelStyleOverride={{ marginTop: "16px" }}
              containerStyleOverride={{ alignItems: "flex-start" }}
            />
            <Checkbox
              label="Send policy docs directly to policy holder"
              name="sendDocs"
              handleClick={() => {
                setDocs(!sendDocs);
              }}
              checked={sendDocs}
            />
          </>
        )}
        <StateNoticeText>{stateNoticeText}</StateNoticeText>
      </FlexColumn>
    </FlexContainer>
  );
};

const mapDispatchToProps = {
  updateApplicationFromServer,
  updateApplicationFormStartDate,
  setSimplybindPaymentFrequency,
};

const mapStateToProps = (state: ReduxState) => ({
  startDate: new Date(selectApplicationFormStartDate(state)),
  snapshotStartDate: new Date(getSnapshotApplicationStartDate(state)),
  applicationTypes: selectApplicationTypesForPayment(state),
  industryId: selectIndustryIdForPayment(state),
  simplyBindPaymentFrequency: selectUnderwritingProfilePaymentFrequency(state),
  currentUserExternalId: getUserExternalId(state),
  userName: selectAccountUserName(state),
  producerExternalId: getSnapshotProducerId(state),
  snapshotPartnerId: getSnapshotPartnerId(state),
});

const PaymentButtonContainer = styled.div<{ isSimplybind?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  ${(props) => !props.isSimplybind && "flex: 1;"}
`;

const PaymentButton = styled.button<{ active: boolean }>`
  margin: 0;
  padding: 8px 16px;
  background-color: ${(props) => (props.active ? props.theme.blue : props.theme.offWhite)};
  color: ${(props) => (props.active ? props.theme.white : props.theme.bgDarkBlue)};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  :hover {
    background-color: ${(props) => props.theme.blue};
    color: ${(props) => props.theme.white};
    cursor: pointer;
  }
  :first-child {
    border-radius: 100px 0 0 100px;
    margin-right: 2px;
  }
  :last-child {
    border-radius: 0 20px 20px 0;
  }
`;

const SimplybindFrequencyButton = styled(PaymentButton)`
  height: 32px;
  width: 75px;
  font-size: 13px;
  border-radius: 24px 0 0 24px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 18px;
  align-items: flex-start;
`;

const Subtitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
`;

const FlexColumn = styled.div<{ isSimplybind?: boolean }>`
  width: 100%;
  :first-child {
    padding: ${(props) => (props.isSimplybind ? "0 127px 0 60px" : "")};
  }
  :last-child {
    margin-right: ${(props) => (props.isSimplybind ? "60px" : "")};
  }
  ${laptop} {
    padding: 0 60px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  margin-bottom: 120px;
  ${laptop} {
    flex-direction: column;
  }
`;

const ColumnWidth100 = styled(Column)`
  width: 100%;
`;

const StyledColumn = styled(ColumnWidth100)<{ isSimplybind?: boolean }>`
  flex-direction: ${(props) => (props.isSimplybind ? "row" : "column")};
  ${(props) => props.isSimplybind && "margin-top: 16px;"}
`;

const PaymentFrequencyContainer = styled.div<{ isSimplybind?: boolean }>`
  ${(props) =>
    props.isSimplybind &&
    `{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin-left: 30px;
        position: relative;
        top: 15px;
    }`}
  ${(props) =>
    !props.isSimplybind &&
    `{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        position: relative;
        top: 15px;
    }`}
`;

const ReviewPaymentDetailsText = styled.p`
  margin: 0 0 48px 12px;
  font-size: 15px;
  color: ${(props) => props.theme.textNavyBlue};
  font-family: ${(props) => props.theme.secondaryFont};
`;

const OverrideStyle = createGlobalStyle`
  input[name="creditCardNumber"] {
    margin-bottom: 16px;
  }
`;
const LabelText = styled.span`
  display: block;
`;

const StyledTextLink = styled(TextLink)`
  font-size: 15px;
  font-weight: 400;
  font-family: ${(props) => props.theme.secondaryFont};
`;

const Title = styled.div`
  align-items: flex-start;
  display: flex;
  color: ${(props) => props.theme.textNavyBlue};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 16px;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexEndDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PriceLabel = styled.span`
  color: ${(props) => props.theme.mediumGray};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const FuturePaymentContainer = styled.span`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 4px;
`;

const FuturePaymentLabel = styled.span`
  color: ${(props) => props.theme.textNavyBlue};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
`;

const StrikethroughPremiumLabel = styled.span`
  color: ${(props) => props.theme.blueStrikethroughColor};
  text-align: right;
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: line-through;
`;

const PremiumLabel = styled.span`
  color: ${(props) => props.theme.blue};
  text-align: right;
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const PremiumAmountSpan = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledRecommendedLabel = styled.span`
  background-color: ${(props) => props.theme.colors.secondary.twentyFive};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.secondary.dark};
  color: ${(props) => props.theme.colors.secondary.dark};
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  padding: 4px 8px;
  width: fit-content;
  padding-left: 13.5px;
  padding-right: 13.5px;
`;

const PriceDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0px;
`;

const Icon = styled.span`
  display: flex;
  justify-content: flex-start;
`;

const SavingsContainer = styled.div`
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid ${(props) => props.theme.borderGrayLight};
`;

const SavingsText = styled.div<{ isWarningStyle: boolean }>`
  display: flex;
  padding: 4px 8px;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  border-radius: 4px;
  background: ${(props) => (props.isWarningStyle ? props.theme.orange25 : "transparent")};
  color: ${(props) => (props.isWarningStyle ? props.theme.darkOrange : props.theme.blueStrikethroughColor)};
  font-family: ${(props) => props.theme.secondaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const TotalDueRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TotalAmountRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const TotalDueAmountText = styled(Header3)<{ isLoading?: boolean; minWidth?: number }>`
  color: ${(props) => props.theme.blue};
  background: ${(props) => props.theme.colors.neutral.white};
  border-radius: 4px;
  font-weight: 600;
  padding: ${(props) => (props.isLoading ? "8px 0" : "0 0")};
  flex-grow: 1;
  ${(props) => props.minWidth && `min-width: ${props.minWidth}px`};
`;

const PaymentDetailsColumn = styled.div`
  width: 400px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsInputs);
