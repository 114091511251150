import React, { useContext, useState, useEffect } from "react";
import { RuleBuilderContext } from "./RuleBuilderContext";
import PropertyBlock from "./ruleBuildingBlocks/PropertyBlock";
import useGetUnderwritingRulePropertyNames from "hooks/underwritingRules/useGetUnderwritingPropertyNames";
import { reportFailFetchRuleMetadata } from "utils/reportError";

const Properties = () => {
  const { rule } = useContext(RuleBuilderContext);
  const [activeIndex, setActiveIndex] = useState(0);

  const { data: propertyNames, error } = useGetUnderwritingRulePropertyNames();

  useEffect(() => {
    if (error) {
      reportFailFetchRuleMetadata(error);
    }
  }, [error]);

  return (
    <>
      {rule.properties?.map((property, index) => (
        <PropertyBlock
          key={index}
          property={property}
          propertyIndex={index}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          existingPropertyNames={propertyNames}
        />
      ))}
    </>
  );
};
export default Properties;
