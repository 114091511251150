import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Highlighter from "shared/Highlighter";
import StatusSymbol from "shared/StatusSymbol";
import { formatBackendPolicyDate } from "utils/formatBackendPolicyDate";

interface PropTypes {
  policy: Policy;
  searchTerm?: string;
}

const PolicySearchRow = ({ policy, searchTerm }: PropTypes) => {
  return (
    <PolicyRow>
      <td>
        <StatusSymbol status={policy.status} />
      </td>
      <td>
        <Highlighter searchTerm={searchTerm}>
          {policy.contactFirstName} {policy.contactLastName}
        </Highlighter>
      </td>
      <td>
        <Highlighter searchTerm={searchTerm}>{policy.contactEmail}</Highlighter>
      </td>
      <td>
        <Link data-cy={"link-to-policy"} to={{ pathname: `/policies/${policy.policyNumber}` }}>
          <Highlighter searchTerm={searchTerm}>{policy.policyNumber}</Highlighter>
        </Link>
      </td>
      <td>{formatBackendPolicyDate(new Date(policy.endDate)).toLocaleDateString()}</td>
    </PolicyRow>
  );
};

const PolicyRow = styled.tr`
  td {
    border: 1px solid transparent;
  }
  &:hover {
    td {
      border-top: 1px solid ${(props) => props.theme.linkHover};
      border-bottom: 1px solid ${(props) => props.theme.linkHover};
      &:first-of-type {
        border-left: 1px solid ${(props) => props.theme.linkHover};
      }
      &:last-of-type {
        border-right: 1px solid ${(props) => props.theme.linkHover};
      }
    }
  }
`;

export default PolicySearchRow;
