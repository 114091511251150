import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { portalApi } from "hooks/useAPI/instances";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const getPropertyValues = async (token: Token, apiVersion: string, propertyName: string) => {
  const api = portalApi(apiVersion, token);
  const { data } = await api.get(`/UnderwritingRule/metadata/values?property=${propertyName}`);

  return data;
};

type Options = { enabled?: boolean };

const useGetUnderwritingRulePropertyValues = (propertyName: string, options?: Options) => {
  const { apiVersion } = useFeatureFlags();
  const token = useSelector((state: ReduxState) => state.token.token);

  return useQuery({
    queryKey: [propertyName],
    queryFn: () => getPropertyValues(token, apiVersion, propertyName),
    retry: 1,
    ...options,
  });
};

export default useGetUnderwritingRulePropertyValues;
