import React from "react";
import styled from "styled-components";
import { Paragraph } from "styles/paragraph";
import { Header2 } from "styles/headers";
import { format } from "date-fns";
import { formatCurrency } from "utils/formatNumber";
import StatusSymbol from "shared/StatusSymbol";
import greyHalfCircles from "images/half_circles_background_grey.svg";
import { formatBackendPolicyDate } from "utils/formatBackendPolicyDate";
import { StatusChip } from "coterie-ui-library";
import { theme } from "styles";
import useFees from "hooks/useFees";

interface PropTypes {
  policies: Policy[];
}

const Confirmation = ({ policies }: PropTypes) => {
  const policy = policies[0];
  const startDate = policy?.startDate ? formatBackendPolicyDate(new Date(policy.startDate)) : new Date();
  const nextPaymentDate = policy?.nextPaymentDate ? new Date(policy?.nextPaymentDate) : new Date();

  const { month1Fees, totalYearlyFees } = useFees(false);
  const isMonthly = policies[0].paymentInterval === "Monthly";
  const premiumPaid = policies.reduce((acc, pol) => acc + pol.premiumPaid, 0);
  const feesPaid = isMonthly ? month1Fees : totalYearlyFees;
  const totalPaidToday = premiumPaid + feesPaid;

  return (
    <Content>
      <StatusContainer>
        <StatusSymbol expanded status={policy?.status} />
      </StatusContainer>
      <Title>Quote Snapshot</Title>
      <Wrapper>
        <Subtitle data-cy="snapshot-confirmation">Congrats, You're Insured!</Subtitle>
        <PolicyInfo>
          <PolicyNumberTitle>POLICY NUMBER</PolicyNumberTitle>
          <PolicyNumbers>
            {policies.map((pol) => (
              <PolicyNumber key={pol.policyNumber}>
                <PolicyTypeChip policy={pol} />
                {pol.policyNumber}
              </PolicyNumber>
            ))}
          </PolicyNumbers>
        </PolicyInfo>
        <PaymentDetails>
          <PaymentTotal>Total Amount Paid Today</PaymentTotal>
          <PaymentTotal>{formatCurrency(totalPaidToday)}</PaymentTotal>
          <PaymentItemIndent>Premium Paid</PaymentItemIndent>
          <Paragraph>{formatCurrency(premiumPaid)}</Paragraph>
          <PaymentItemIndent>Fees Paid</PaymentItemIndent>
          <Paragraph>{formatCurrency(feesPaid)}</Paragraph>
          <PolicyStartDate>Policy Start Date</PolicyStartDate>
          <PolicyStartDate>{format(startDate, "MMM dd, yyyy")}</PolicyStartDate>
          {policy?.nextPaymentDate && (
            <>
              <Paragraph>Next Payment Date</Paragraph>
              <Paragraph>{format(nextPaymentDate, "MMMM dd, yyyy")}</Paragraph>
            </>
          )}
        </PaymentDetails>
      </Wrapper>
      <NoticeText>
        Your payment has been received and your insurance agent will be in touch with next steps and policy
        documentation.
      </NoticeText>
    </Content>
  );
};

const PolicyTypeChip = ({ policy }: { policy: Policy }) => {
  const { secondary: gl, pl, bop } = theme.colors;
  switch (policy.policyType) {
    case "GL":
      return (
        <TypeChip text="GL" textColor={gl.dark} backgroundColor={gl.twentyFive} borderColor={gl.darkTen} isSmall />
      );
    case "PL":
      return <TypeChip text="PL" textColor={pl.dark} backgroundColor={pl.twentyFive} borderColor={pl.zero} isSmall />;
    case "BOP":
      return <TypeChip text="BOP" textColor="#A68E2B" backgroundColor={bop.thirty} borderColor={bop.zero} isSmall />;
    default:
      return null;
  }
};

const Content = styled.div`
  background-image: url(${greyHalfCircles});
  padding-bottom: 100px;
`;

const StatusContainer = styled.div`
  margin: 20px 0 0 1100px;
  width: 116px;
`;

const Title = styled(Header2)`
  text-align: center;
`;

const Wrapper = styled.div`
  max-width: 480px;
  margin: auto;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 20px;
  font-weight: 600;
`;

const TypeChip = styled(StatusChip)<{ borderColor: string; textColor: string }>`
  margin-right: 16px;
  border: 1px solid ${({ borderColor }) => borderColor};
  color: ${({ textColor }) => textColor};
`;

const PolicyNumberTitle = styled.p`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
`;

const PolicyNumbers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

const PolicyNumber = styled.p`
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PolicyNumberDetail = styled(Paragraph)`
  font-size: 18px;
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  margin: 0;
  line-height: 26px;
`;

const NoticeText = styled(PolicyNumberDetail)`
  max-width: 640px;
  margin: auto;
  text-align: center;
`;

const PolicyInfo = styled.div`
  margin: 28px 0;
`;

const PaymentDetails = styled.div`
  display: grid;
  justify-content: end;
  grid-template-columns: 240px 160px;
  margin: 24px 0;
  text-align: justify;
`;

const PaymentItemIndent = styled(Paragraph)`
  margin-left: 16px;
`;

const PolicyStartDate = styled(Paragraph)`
  margin-top: 24px;
`;

const PaymentTotal = styled(Paragraph)`
  font-size: 16px;
  color: ${({ theme }) => theme?.colors?.tertiary.fifty};
  font-weight: 600;
`;

export default Confirmation;
