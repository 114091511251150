import { toast } from "react-toastify";

export const toastError = (message: any) => {
  console.error(message?.message || message);
  toast.error(message?.message || message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "toast-error",
    autoClose: 5000,
  });
};

export const toastSuccess = (message: any) => {
  console.info(message?.message || message);
  toast.success(message?.message || message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "toast-success",
    autoClose: 5000,
  });
};
