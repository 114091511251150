import React, { useState } from "react";
import Modal from "shared/Modal";
import { ModalField, Column, Row, ShortModalInput, ModalContentContainer } from "shared/ModalComponents";

interface PropTypes {
  isLoading: boolean;
  closeModal: () => void;
  modalIsOpen: boolean;
  save: (updateRequest: UpdateChannelPartnerRequest) => void;
  name: string;
  phoneNumber: string;
  channelPartnerId: number;
}

const EditPartnerModal = (props: PropTypes) => {
  const [values, setValues] = useState({
    name: props.name,
    phoneNumber: props.phoneNumber,
  });

  function editPartner() {
    props.save({ name: values.name });
  }

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    let { name, value } = event.currentTarget;

    setValues((values) => ({ ...values, [name]: value }));
  }

  const { name } = values;

  return (
    <Modal
      isOpen={props.modalIsOpen}
      closeModal={props.closeModal}
      label="edit-partner"
      title="Edit Partner Info"
      onSubmit={editPartner}
      loading={props.isLoading}
    >
      <ModalContentContainer>
        <Row>
          <Column>
            <ModalField>Partner Name</ModalField>
            <ShortModalInput
              name="name"
              value={name}
              onChange={handleChange}
              aria-label="Partner Name"
              placeholder="Enter partner's name"
              required
            />
          </Column>
          {/*<Column>*/}
          {/*  <ModalField>Phone Number</ModalField>*/}
          {/*  <ShortModalInput*/}
          {/*    name="phoneNumber"*/}
          {/*    value={phoneNumber}*/}
          {/*    onChange={this.handleChange}*/}
          {/*    aria-label="Phone Number"*/}
          {/*    placeholder="Enter phone number with area code"*/}
          {/*  />*/}
          {/*</Column>*/}
        </Row>
        {/*<ModalField>Mailing Address</ModalField>*/}
        {/*<LongModalInput*/}
        {/*  name="mailingAddress"*/}
        {/*  value={mailingAddress}*/}
        {/*  onChange={this.handleChange}*/}
        {/*  aria-label="Mailing Address"*/}
        {/*  placeholder="Type your full address"*/}
        {/*/>*/}
      </ModalContentContainer>
    </Modal>
  );
};

export default EditPartnerModal;
