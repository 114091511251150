import React, { ReactNode } from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import styled from "styled-components";
import blueHalfCircles from "images/half_circles_background_blue.svg";
import upload from "images/upload.svg";

type FileUploaderProps = DropzoneOptions & {
  acceptedFormatsText?: ReactNode;
  acceptedSizeText?: ReactNode;
  width?: string;
  height?: string;
};

export default function FileUploader({
  acceptedFormatsText = ".png",
  acceptedSizeText = "1MB",
  height = "146px",
  width = "304px",
  maxSize = 1048576,
  ...dropzoneProps
}: FileUploaderProps) {
  const { getRootProps, getInputProps } = useDropzone({
    ...dropzoneProps,
  });

  return (
    <ImageUploadContainer>
      <ImageUploadArea aria-label="Upload files here" height={height} width={width} {...getRootProps()}>
        <FileInput role="textbox" {...getInputProps()} />
        <UploadLogo src={upload} alt="file upload image" />
        <DragAndDropLabel>
          Drag and drop or <BrowseLabel>Browse files</BrowseLabel>
        </DragAndDropLabel>
        <AcceptedLabel>Accepted formats: {acceptedFormatsText}</AcceptedLabel>
        <AcceptedLabel>Maximum file size: {acceptedSizeText}</AcceptedLabel>
      </ImageUploadArea>
    </ImageUploadContainer>
  );
}

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageUploadArea = styled.div<{ height?: string; width?: string }>`
  border: 1px dashed ${({ theme }) => theme.pageComponents.kit.imageUpload.imageUploadArea.border.default};
  width: ${({ width }) => width};
  cursor: pointer;
  height: ${({ height }) => height};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${blueHalfCircles});
  background-size: cover;
  background-color: #ffffff;
`;

const AcceptedLabel = styled.label`
  color: ${({ theme }) => theme.colors.neutral.dark};
  text-align: center;
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
`;

const FileInput = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  position: absolute;
`;

const UploadLogo = styled.img`
  margin-bottom: 16px;
`;

const DragAndDropLabel = styled.label`
  color: ${({ theme }) => theme.pageComponents.kit.imageUpload.labelColor.dragAndDrop};
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 16px;
`;

const BrowseLabel = styled.span`
  color: ${({ theme }) => theme.pageComponents.kit.imageUpload.labelColor.browse};
`;
