import { useQuery } from "@tanstack/react-query";
import useAuth from "./useAuth";
import Api from "utils/api";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const useGetCancelReasons = () => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const getPolicyDocuments = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api.cancel.getCancelReasons();
  };

  return useQuery({
    queryKey: ["getCancelReasons"],
    queryFn: getPolicyDocuments,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export default useGetCancelReasons;
