import React from "react";
import { Rule, RuleOptions } from "./types";
import styled from "styled-components";

const renderRuleDSL = (rule: Rule, isIndented?: boolean) => {
  return rule?.options?.map((ruleOption: RuleOptions, i) => {
    return (
      <IndentedDiv isIndented={isIndented} key={`${rule?.id}-${i}`}>
        <div>
          IF {rule.variable} {ruleOption.condition}
          {ruleOption.states && ruleOption.states?.length > 0 && (
            <span> INSTATES ({ruleOption.states.join(", ")})</span>
          )}
          {ruleOption.control && <span> THEN {ruleOption.control}</span>}
          {ruleOption.message && <span> WITHMESSAGE {ruleOption.message}</span>}
        </div>
        {ruleOption.subRules?.length ? (
          <IndentedDiv>
            <span>INCL [</span>
            {ruleOption.subRules.map((subRule: Rule, i: number) => {
              return renderRuleDSL(subRule, true);
            })}
            <span>]</span>
          </IndentedDiv>
        ) : null}
        {i !== rule?.options.length - 1 && <div>AND</div>}
      </IndentedDiv>
    );
  });
};

interface RuleDSLProps {
  rule: any;
  isRuleSearch?: boolean;
}

const RuleDSL = ({ rule, isRuleSearch }: RuleDSLProps) => {
  return (
    <RuleOutputContainer data-testid="rule-DSL" isRuleSearch={isRuleSearch}>
      {renderRuleDSL(rule)}
    </RuleOutputContainer>
  );
};

const RuleOutputContainer = styled.div<{ isRuleSearch?: boolean }>`
  background: ${(props) => (props.isRuleSearch ? props.theme.white : props.theme.offWhite)};
  color: ${(props) => (props.isRuleSearch ? props.theme.textNavyBlue : "inherit")};
  padding: ${(props) => (props.isRuleSearch ? "0" : "8px")};
  overflow: scroll;
  margin-bottom: 1em;
  font-family: ${(props) => props.theme.secondaryFont};
  ${(props) => props.isRuleSearch && "font-size: 15px; max-width: 80%"}
  ${(props) => !props.isRuleSearch && "max-height: 275px;"}
`;

const IndentedDiv = styled.div<{ isIndented?: boolean }>`
  ${(props) => props.isIndented && "margin-left: 12px"}
`;

export default RuleDSL;
