import { useMutation } from "@tanstack/react-query";
import Api from "utils/api";
import useAuth from "./useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

export const usePutDetachPolicy = (options?: any) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const detachPolicyRequest = async (requestData: DetachRequestData) => {
    const { payload, policyNumber } = requestData;
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api.policies.detach(policyNumber, payload);
  };

  return useMutation({
    mutationFn: detachPolicyRequest,
    ...options,
  });
};
