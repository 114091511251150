import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import Api from "utils/api";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const getRenewals = async (apiVersion: string, token: Token, renewalParams: RenewalParams) => {
  const api = new Api(apiVersion, token);

  return await api.renewals.getRenewals(renewalParams);
};

type Options = { enabled?: boolean };

const useGetRenewals = (renewalParams: RenewalParams, options?: Options) => {
  const { apiVersion } = useFeatureFlags();
  const token = useSelector((state: ReduxState) => state.token.token);

  return useQuery({
    queryKey: ["renewals", renewalParams],
    queryFn: () => getRenewals(apiVersion, token, renewalParams),
    retry: (failureCount, error: AxiosError) => (error.response?.status === 404 ? false : failureCount < 2 && true),
    ...options,
  });
};

export default useGetRenewals;
