import { theme } from "coterie-ui-library";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { TextLink } from "styles";
import { reportAirkitError } from "utils/reportError";

type AirKitEmbedProps = {
  body: Record<string, unknown>;
  name?: string;
  url: string;
  height?: string | number;
  onCancelSuccess?: (event: Record<string, unknown>) => void;
};

const AirKitEmbed = ({ body = {}, url, height = "400px", name, onCancelSuccess }: AirKitEmbedProps) => {
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    try {
      //@ts-ignore
      const sdk = new window.AirkitWebSDK({
        renderMode: "inline",
        iframeConfig: { class: name, style: { height } },
      });
      sdk.render({
        url,
        options: {
          body,
        },
      });
      if (onCancelSuccess) {
        sdk.on("outgoing-event", (event: Record<string, unknown>) => {
          onCancelSuccess(event);
        });
      }
    } catch (e) {
      setHasError(true);
      reportAirkitError(e as Error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !hasError ? (
    <AirKitEmbedContainer id="airkit-sdk" height={height} />
  ) : (
    <ErrorText>
      There was an error loading this form. Please refresh the page and try again. If you're still having issues, please
      contact us through our{" "}
      <TextLink as="a" href="https://knowledge.coterieinsurance.com/knowledge" target="_blank">
        Knowledge Center
      </TextLink>
      .
    </ErrorText>
  );
};

const AirKitEmbedContainer = styled.div<{ height: string | number }>`
  min-height: auto;
  height: ${({ height }) => height};
`;

const ErrorText = styled.div`
  font-family: ${theme.font.typeface.secondary};
  font-size: 13px;
  letter-spacing: 0.75px;
  line-height: 16px;
  padding: 24px 24px;
  text-align: center;
  a {
    font-size: 13px;
  }
`;

export default AirKitEmbed;
