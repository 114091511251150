import React from "react";
import styled, { css } from "styled-components";
import Floater from "react-floater";
import toolTipContent from "utils/toolTipContent";
import { theme } from "styles";

interface PropTypes {
  contentKey?: string | undefined;
  content?: string | React.ReactNode;
}

const ToolTipIcon = ({ contentKey, content }: PropTypes) => {
  return (
    <Floater
      content={content || (contentKey && toolTipContent[contentKey])}
      event="hover"
      placement="top"
      hideArrow
      styles={{
        floater: {
          maxWidth: 320,
          filter: "none",
        },
        container: {
          backgroundColor: theme.toolTip_FloaterBG,
          border: `1px solid ${theme.toolTip_FloaterBorder}`,
          borderRadius: 4,
          color: `1px solid ${theme.toolTip_FloaterColor}`,
          filter: "none",
          minHeight: "none",
          maxWidth: 320,
          padding: 8,
        },
      }}
    >
      <Question>?</Question>
    </Floater>
  );
};

export const Question = styled.div`
  display: inline-block;
  margin-left: 4px;
  color: ${(props) => props.theme.blue};
  background: ${(props) => props.theme.toolTip_BG};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-weight: 600;
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 12px;
  ${css`
    &:hover {
      color: ${(props) => props.theme.white};
      background: ${(props) => props.theme.blue};
    }
  `}
`;

export default ToolTipIcon;
