import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { TableContainer } from "styles/containerStyledComponents";
import DownImg from "images/down.svg";
import UpImg from "images/up.svg";
import { ApplicationSortBy, SortDirection } from "types/enums";
import { Checkbox } from "styles";
import { InputCheckbox } from "styles/Checkbox";
import Api from "utils/api";
import { toastSuccess } from "utils/toast";
import ApplicationRow from "./ApplicationRow";
import { reportError } from "utils/reportError";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  rows: Application[];
  search?: string;
  changeSort: (sortBy: ApplicationSortBy, sortDirection: SortDirection) => void;
  sortBy: ApplicationSortBy;
  sortDirection: SortDirection;
  archiveStatus: boolean;
  query: string;
  performSearch: () => void;
  loading: boolean;
}

const ApplicationsTable = ({
  rows,
  changeSort,
  sortBy,
  sortDirection,
  archiveStatus,
  performSearch,
  query,
  loading,
}: PropTypes) => {
  const { getToken, isIAUser } = useAuth();
  const [selectedQuoteIds, setSelectedQuoteIds] = useState<string[]>([]);
  const [isAllSelected, setAllSelected] = useState(false);

  const selectSortColumn = (column: ApplicationSortBy) => {
    changeSort(column, sortDirection === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending);
  };
  const { apiVersion } = useFeatureFlags();

  const archiveQuote = async () => {
    const token = await getToken();
    const api = new Api(apiVersion, token);
    api.application
      .archive(selectedQuoteIds)
      .then(() => {
        toastSuccess("Quote archived!");
        performSearch();
      })
      .catch((err) => reportError(err));
  };

  const handleCheckbox = (quoteId: string) => {
    if (selectedQuoteIds.includes(quoteId)) {
      const removeQuote = selectedQuoteIds.filter((quote) => quote !== quoteId);
      setSelectedQuoteIds(removeQuote);
    } else {
      setSelectedQuoteIds([...selectedQuoteIds, quoteId]);
    }
  };

  const handleSelectAll = () => {
    setAllSelected(!isAllSelected);
    if (isAllSelected) {
      setSelectedQuoteIds([]);
    } else {
      const selected = rows.map((app: any, index) => {
        return app.applicationId;
      });
      setSelectedQuoteIds(selected);
    }
  };

  const arrow = () => {
    if (sortDirection === SortDirection.Descending) {
      return DownImg;
    } else if (sortDirection === SortDirection.Ascending) {
      return UpImg;
    } else {
      return "";
    }
  };

  return (
    <TableContainer className={"results"}>
      <table>
        <thead>
          <tr>
            {!archiveStatus && isIAUser && (
              <th>
                <CheckboxHeaderRow>
                  <OverrideStyle />
                  <Checkbox name="archiveAll" handleClick={handleSelectAll} checked={isAllSelected} />
                </CheckboxHeaderRow>
              </th>
            )}
            <th>
              <HeaderRow>
                <Button type="button" onClick={() => selectSortColumn(ApplicationSortBy.Status)}>
                  Status
                </Button>
                {sortBy === ApplicationSortBy.Status && <img src={arrow()} alt="arrow" />}
              </HeaderRow>
            </th>
            <th>
              <HeaderRow>
                <Button type="button" onClick={() => selectSortColumn(ApplicationSortBy.BusinessName)}>
                  Business Name
                </Button>
                {sortBy === ApplicationSortBy.BusinessName && <img src={arrow()} alt="arrow" />}
              </HeaderRow>
            </th>
            <th>
              <HeaderRow>
                <Button type="button" onClick={() => selectSortColumn(ApplicationSortBy.Name)}>
                  Contact Name
                </Button>
                {sortBy === ApplicationSortBy.Name && <img src={arrow()} alt="arrow" />}
              </HeaderRow>
            </th>
            <th>
              <HeaderRow>
                <Button type="button" onClick={() => selectSortColumn(ApplicationSortBy.ApplicationTypes)}>
                  Quote Types
                </Button>
                {sortBy === ApplicationSortBy.ApplicationTypes && <img src={arrow()} alt="arrow" />}
              </HeaderRow>
            </th>
            <th>
              <HeaderRow>
                <Button
                  data-cy="application-search-date-sort-button"
                  type="button"
                  onClick={() => selectSortColumn(ApplicationSortBy.CreatedOn)}
                >
                  Date
                </Button>
                {sortBy === ApplicationSortBy.CreatedOn && <img src={arrow()} alt="arrow" />}
              </HeaderRow>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows?.length > 0 && !loading ? (
            rows?.map((row, index) => (
              <ApplicationRow
                app={row}
                index={index}
                archiveQuote={archiveQuote}
                selectedQuoteIds={selectedQuoteIds}
                handleCheckbox={handleCheckbox}
                search={query}
                archiveStatus={archiveStatus}
              />
            ))
          ) : (
            <tr>
              <td colSpan={5}>No Results</td>
            </tr>
          )}
        </tbody>
      </table>
    </TableContainer>
  );
};

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckboxHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 18px;
`;

const Button = styled.button`
  color: ${(props) => props.theme.fontColors?.primary};
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
`;

const OverrideStyle = createGlobalStyle`
  ${InputCheckbox} {
    height: 16px;
    width: 16px;
    background-size: 16px 16px;
    margin: 0;
  }
`;

export default ApplicationsTable;
