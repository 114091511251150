import { difference } from "lodash";
import { isCanadaEnv } from "utils/environmentChecker";
import { PolicyType } from "types/enums";
import { BlanketAITypes, blanketOptions } from "./constants";

export const getCoverageTitle = (applicationTypes: PolicyType[]) => {
  if (difference(applicationTypes, ["BOP"]).length === 0) {
    return isCanadaEnv() ? "Commercial Package Policy" : "Business Owners Policy";
  } else if (difference(applicationTypes, ["PL"]).length === 0) {
    return "Professional Liability Policy";
  } else if (difference(applicationTypes, ["GL"]).length === 0) {
    return "General Liability Policy";
  } else if (difference(applicationTypes, ["GL", "PL"]).length === 0) {
    return "General Liability & Professional Liability Policy";
  } else if (difference(applicationTypes, ["BOP", "PL"]).length === 0) {
    return isCanadaEnv()
      ? "Commercial Package Policy & Professional Liability Policy"
      : "Business Owners & Professional Liability Policy";
  } else {
    return "No coverage selected";
  }
};

export const getBlanketAIs = (endorsements: EndorsementsType | undefined): BlanketAITypes => {
  return {
    additionalInsuredsPrimaryAndNonContributory: endorsements?.additionalInsuredsPrimaryAndNonContributory,
    additionalInsuredsWaiverOfTransfer: endorsements?.additionalInsuredsWaiverOfTransfer,
    additionalInsuredsConstructionContractRequirementEndorsement: Boolean(
      endorsements?.additionalInsuredsConstructionContractRequirementEndorsement
    ),
    additionalInsuredsManagersOrLessorsPremisesEndorsement: Boolean(
      endorsements?.additionalInsuredsManagersOrLessorsPremisesEndorsement
    ),
    additionalInsuredsLessorOfLeasedEquipmentEndorsement: Boolean(
      endorsements?.additionalInsuredsLessorOfLeasedEquipmentEndorsement
    ),
  };
};

export const getBlanketTitles = (blanketAIs: BlanketAITypes) => {
  return Object.entries(blanketAIs).reduce((list, [label, value]) => {
    if (!value) return list;
    return [...list, blanketOptions[label]];
  }, [] as string[]);
};
