import React from "react";
import styled from "styled-components";
import formatNumber from "utils/formatNumber";
import TrashCan from "images/trashcan.svg";

interface PropTypes {
  amount: any;
  description: any;
  removeLoss: any;
  index: any;
}

const Loss = ({ amount, description, removeLoss, index }: PropTypes) => {
  return (
    <LossContainer>
      <div>
        <LossAmount>{formatNumber(amount, true)}</LossAmount>
        <LossDescription data-cy="loss-description">{description}</LossDescription>
      </div>
      <div style={{ display: "flex" }}>
        <TrashCanButton onClick={() => removeLoss(index)} data-testid="remove-loss">
          <TrashCanImage src={TrashCan} role="img" alt="Trash can" />
        </TrashCanButton>
      </div>
    </LossContainer>
  );
};

const LossContainer = styled.div`
  margin: 10px 5px;
  border-radius: 4px;
  padding: 10px;
  background-color: ${(props) => props.theme.offWhite};
  display: flex;
  justify-content: space-between;
`;
const LossAmount = styled.p`
  margin: 0;
  font-weight: bold;
  font-family: ${(props) => props.theme.secondaryFont};
  color: ${(props) => props.theme.charcoalGrey};
  font-variant: small-caps;
`;
const LossDescription = styled.p`
  margin: 0;
  font-family: ${(props) => props.theme.secondaryFont};
  color: ${(props) => props.theme.charcoalGrey};
`;

const TrashCanButton = styled.button`
  border: none;
  background: transparent;
`;

const TrashCanImage = styled.img`
  width: 15px;
  min-width: 15px;
`;

export default Loss;
