import { combineReducers } from "redux";
import user from "./_userReducer";
import application from "./_applicationReducer";
import affiliation from "./_affiliationReducer";
import token from "./_tokenReducer";
import availableEndorsements from "./_availableEndorsements";
import snapshot from "./_snapshotReducer";
import underwritingProfile from "./_underwritingProfileReducer";
import underwritingQuestions from "./_underwritingQuestionsReducer";
import global from "./_global";

export default combineReducers({
  user,
  application,
  affiliation,
  token,
  availableEndorsements,
  snapshot,
  underwritingProfile,
  underwritingQuestions,
  global,
});
