import React from "react";
import styled from "styled-components";

interface PropTypes {
  isLoading: boolean;
  quoteResult?: QuotePolicyUpdateResponse;
  policy: Policy;
}

const ConfirmChangesContainer = styled.div`
  .premium {
    font-size: 20px;
  }
`;

export default class PolicyChangeConfirmation extends React.Component<PropTypes> {
  render(): React.ReactNode {
    let { isLoading, quoteResult, policy } = this.props;

    let nextPaymentDate = "";
    let paymentInterval = "None";
    let paymentDescription = <span />;
    let premiumChangeText = <p>Your premium is not changing</p>;
    let premiumChanged = false;
    let changes = (
      <div>
        <p>No Changes</p>
      </div>
    );

    if (quoteResult) {
      premiumChanged = quoteResult.policy.premium !== policy.premium;

      if (premiumChanged) {
        premiumChangeText = (
          <p>
            Your total premium is changing from <span className={"premium"}>${policy.premium}</span> to{" "}
            <span className={"premium"}>${quoteResult.policy.premium}</span>
          </p>
        );
      }

      nextPaymentDate = new Date(quoteResult.policy.nextPaymentDate).toLocaleDateString();
      paymentInterval = quoteResult.policy.paymentInterval;

      if (paymentInterval === "None") {
        paymentDescription = (
          <div>
            <p>
              You paid in full for your policy, so you will be charged{" "}
              <span className="premium">${quoteResult.nextPaymentAmount}</span> immediately.
            </p>
          </div>
        );
      } else {
        paymentDescription = (
          <div>
            <p>
              You are paying monthly for your policy, so your monthly payment will be adjusted to reflect the changes.
            </p>
            <p>
              Your new monthly premium will be <span className="premium">{quoteResult.nextPaymentAmount}</span>
            </p>
            <p>
              Your next payment date is <strong>{nextPaymentDate}</strong>
            </p>
          </div>
        );
      }

      if (quoteResult.changes.length > 0) {
        changes = (
          <div>
            {quoteResult.changes.map((c, i) => (
              <p key={i}>{c}</p>
            ))}
          </div>
        );
      }
    }

    return (
      <ConfirmChangesContainer data-cy={"confirm-changes-container"}>
        {isLoading || !quoteResult ? (
          <div>
            <span>Generating quote...</span>
          </div>
        ) : (
          <div>
            <h3>Premium Changes</h3>
            {premiumChanged ? (
              <div>
                <div data-cy={"premium-changed-text"}>{premiumChangeText}</div>
                <div data-cy={"payment-description"}>{paymentDescription}</div>
              </div>
            ) : (
              <p data-cy={"premium-unchanged-text"}>The policy premium is unchanged</p>
            )}

            <h4>Change Description</h4>
            <div data-cy={"change-description"}>{changes}</div>
          </div>
        )}
      </ConfirmChangesContainer>
    );
  }
}
