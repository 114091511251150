import React from "react";
import StripePayment from "./StripePayment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripeOptionsObject } from "utils/paymentUtils";
import { PaymentFrequency } from "types/enums";

interface PropTypes {
  application: any;
  closeModal?: () => void;
  modalIsOpen?: boolean;
  quote: BindableQuote;
  isSimplybind?: boolean;
  paymentFrequency?: PaymentFrequency;
}

const publishableKey = process.env.REACT_APP_STRIPE_KEY || "";
const stripePromise = loadStripe(publishableKey);

const Payment = ({
  closeModal,
  modalIsOpen,
  quote,
  application,
  isSimplybind = false,
  paymentFrequency,
}: PropTypes) => {
  return (
    <Elements options={stripeOptionsObject} stripe={stripePromise}>
      <StripePayment
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        quote={quote}
        application={application}
        isSimplybind={isSimplybind}
        paymentFrequency={paymentFrequency}
      />
    </Elements>
  );
};

// Actual payment in Dashboard
export default Payment;
