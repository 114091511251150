import ToolTipIcon from "shared/ToolTipIcon";
import styled from "styled-components";
import { formatCurrency } from "utils/formatNumber";

interface PolicyInformationProps {
  policy: Policy;
}

export const BOPCoverage = ({ policy }: PolicyInformationProps) => {
  return (
    <tbody>
      <tr data-cy="building">
        <TData>Building</TData>
        <TData>
          {Boolean(policy.locations[0].buildingLimit) ? formatCurrency(policy.locations[0].buildingLimit) : "N/A"}
        </TData>
        <TData style={{ paddingLeft: 0 }}>
          {Boolean(policy.buildingDeductible) ? formatCurrency(policy.buildingDeductible as number) : "N/A"}
        </TData>
      </tr>
      <tr data-cy="business-personal-property">
        <TData>Business Personal Property</TData>
        <TData>{Boolean(policy.locations[0].bppLimit) ? formatCurrency(policy.locations[0].bppLimit) : "N/A"}</TData>
        <TData style={{ paddingLeft: 0 }}>
          {Boolean(policy.bppDeductible) ? formatCurrency(policy.bppDeductible!) : "N/A"}
        </TData>
      </tr>
      <tr data-cy="general-liability">
        <TData>General Liability</TData>
        <TData>{`${formatCurrency(policy.glLimit!)} / ${formatCurrency(policy.glAggregateLimit!)}`}</TData>
        <TData style={{ paddingLeft: 0 }}>
          {formatCurrency(policy.propertyDamageLiabilityDeductible!)}
          <ToolTipIcon contentKey="GL_DEDUCTIBLE" />
        </TData>
      </tr>
      <tr data-cy="products-completed-liability">
        <TData>Products Completed Liability</TData>
        <TData>{formatCurrency(policy.glAggregatePcoLimit!)}</TData>
        <TData style={{ paddingLeft: 0 }}>N/A</TData>
      </tr>
    </tbody>
  );
};

const TData = styled.td`
  font-weight: 400;
  padding: 10px 0px 10px 20px;
  font-family: ${({ theme }) => theme.secondaryFont};
  color: ${({ theme }) => theme.gray};
  font-size: 15px;
`;
