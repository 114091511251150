import React, { useState, useEffect, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { isEmpty } from "lodash";
import { PaymentFrequency, UnderwritingActions } from "types/enums";
import { mapSimplyBindAvailableEndorsements, mapSimplybindEndorsementDetails } from "utils/mapSimplybindEndorsements";
import applicationValidator from "utils/applicationValidator";
import formotivInstance from "utils/formotiv";
import { checkValidDate } from "utils/checkValidDate";
import useAPI from "hooks/useAPI";
import useGetUnderwritingProfile from "hooks/useGetUnderwritingProfile";
import { pages, headerTextObject, pageHeaderTextLoading } from "./constants";
import ProgressIndicator from "./ProgressIndicator";
import NameAndAddress from "./pages/NameAndAddress";
import IndustryConfirmation from "./pages/IndustryConfirmation";
import IndustryInput from "./pages/IndustryInput";
import BusinessDetails from "./pages/BusinessDetails";
import QuoteEdits from "./pages/QuoteEdits";
import BindConfirmation from "./pages/BindConfirmation";
import ErrorScreen from "./pages/ErrorScreen";
import LoadingScreen from "./pages/LoadingScreen";
import DeclinationScreen from "./DeclinationScreen";
import BridgingLossControlScreen from "./BridgingLossControlScreen";
import greenCircle from "images/LightGreenCircle.svg";
import redCircle from "images/LightRedCircle.svg";
import ToolTipIcon from "shared/ToolTipIcon";
import {
  StyledHeader3,
  PageHeaderContainer,
  PageHeaderSubText,
  PageHeaderSubTextContainer,
  HeaderTextContainer,
} from "./styles";
import { selectUserAccountId } from "redux/selectors/user";
import {
  selectUnderwritingProfile,
  selectUnderwritingProfileId,
  selectUnderwritingProfileIndustrySuggestions,
} from "redux/selectors/underwritingProfile";
import {
  selectApplicationForm,
  selectApplicationNetworkError,
  selectApplicationFormDesiredPolicyType,
  selectApplicationFormBppCoverage,
  selectApplicationFormBuildingCoverage,
} from "redux/selectors/application";
import {
  updateUnderwritingProfile,
  resetUnderwritingProfile,
  updateUnderwritingProfileId,
  setLoadingUnderwritingProfile,
  setSimplybindPaymentFrequency,
  updateUnderwritingEndorsementDetails,
} from "redux/reducers/_underwritingProfileReducer";
import {
  updateApplicationForm,
  updateIndustryName,
  setQuote,
  resetApplication,
  setApplicationError, // TODO network errors
  setQuoteErrors,
  setAvailablePolicyTypes,
  setBindable,
  setApplicationFromServer,
  updateNaic,
  setTwoFourLimitsAvailable,
} from "redux/reducers/_applicationReducer";
import { resetAvailableEndorsements, updateAvailableEndorsements } from "redux/reducers/_availableEndorsements";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { setIsValidPolicyStartDate, setAlternateQuote } from "redux/reducers/_global";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import { ultrawide } from "styles";

interface RouteParams {
  pageLabel?: string;
  applicationId?: string;
}

interface LocationState {
  from?: string;
}

interface PropTypes extends RouteComponentProps<RouteParams> {
  application: Application;
  applicationNetworkError: any;
  bppCoverage?: boolean;
  buildingCoverage?: boolean;
  desiredPolicyType?: string[];
  industrySuggestions?: IndustrySuggestion[];
  resetApplication: ActionCreatorWithoutPayload<any>;
  resetAvailableEndorsements: ActionCreatorWithoutPayload<any>;
  resetUnderwritingProfile: ActionCreatorWithoutPayload<any>;
  setApplicationError: (arg0: any) => void;
  setApplicationFromServer: (application: Application) => void;
  setAvailablePolicyTypes: (arg0: []) => void;
  setBindable: (arg0: boolean) => void;
  setLoadingUnderwritingProfile: (arg0: boolean) => void;
  setQuote: (arg0: Quote | {}) => void;
  setQuoteErrors: (arg0: []) => void;
  setSimplybindPaymentFrequency: (frequency: PaymentFrequency) => void;
  underwritingProfile: UnderwritingProfile;
  underwritingProfileId: string;
  updateApplicationForm: (arg0: Application) => void;
  updateAvailableEndorsements: (arg0: any) => void;
  updateIndustryName: (arg0: string) => void;
  updateNaic: (arg0: number) => void;
  setTwoFourLimitsAvailable: (arg0: boolean) => void;
  updateUnderwritingEndorsementDetails: (endorsementDetails: EndorsementDetails) => void;
  updateUnderwritingProfile: (arg0: underwritingProfileState | {}) => void;
  updateUnderwritingProfileId: (arg0: string) => void;
  setIsValidPolicyStartDate: (isValid: boolean) => void;
  setAlternateQuote: (alternateQuote: any) => void;
}

interface NameAndAddressType {
  CompanyName: string;
  streetAddress?: string;
  City?: string;
  StateCode?: string;
  ZipCode?: string;
  source?: string;
  desiredPolicyType?: string[];
  bppCoverage?: boolean;
  buildingCoverage?: boolean;
}

const SimplyBindContainer = ({
  match,
  history,
  underwritingProfile,
  underwritingProfileId,
  application,
  applicationNetworkError,
  updateUnderwritingProfile,
  resetUnderwritingProfile,
  updateApplicationForm,
  setApplicationFromServer,
  updateIndustryName,
  updateNaic,
  setTwoFourLimitsAvailable,
  setQuote,
  resetApplication,
  resetAvailableEndorsements,
  updateAvailableEndorsements,
  setApplicationError,
  setQuoteErrors,
  setAvailablePolicyTypes,
  setBindable,
  setSimplybindPaymentFrequency,
  updateUnderwritingEndorsementDetails,
  setLoadingUnderwritingProfile,
  industrySuggestions,
  setIsValidPolicyStartDate,
  setAlternateQuote,
  bppCoverage,
  buildingCoverage,
  desiredPolicyType,
}: PropTypes) => {
  const { params } = match;
  const formotiv = window.formotiv;
  const location = useLocation();
  const isLegacyQuote = new URLSearchParams(location.search).get("legacy") === "true";
  const pageLabel = params?.pageLabel ?? "get-started";
  const pageId = pages.find((e: any) => e.label.includes(pageLabel))?.step ?? 0;
  const { showSelectCoverage, useSelectedCoverage } = useFeatureFlags();

  const applicationId = params?.applicationId;
  const agentId = useSelector((state: ReduxState) => selectUserAccountId(state));

  const [industry, setIndustry] = useState<IndustrySuggestion | {}>({});
  const [showLossControlBridging, setShowLossControlBridging] = useState(false);
  const [isMockLoadingFetchApplication, setIsMockLoadingFetchApplication] = useState(false);
  const [isMockLoadingUpdateApplication, setIsMockLoadingUpdateApplication] = useState(false);
  const isSadPath = !industrySuggestions?.length;

  const setProgress = (payload: number) => {
    const label = pages.find((e: any) => e.step === payload)?.label ?? "";
    history.push(`/simplybind/${label}`);
  };

  const shouldRedirectToIndustryConfirmation = (appliedActions: string[]) => {
    return (
      !appliedActions?.includes(UnderwritingActions.ConfirmIndustry) &&
      !appliedActions?.includes(UnderwritingActions.Confirmed) &&
      !appliedActions?.includes(UnderwritingActions.Underwrite)
    );
  };

  const {
    data: underwritingProfileResponse,
    isSuccess: getUnderwritingProfileIsSuccess,
    isLoading: loadingGetUnderwritingProfile,
    error: underwritingProfileError,
    refetch: fetchUnderwritingProfile,
  } = useGetUnderwritingProfile(applicationId!, { enabled: false });

  const fetchUnderwritingProfileWithDelay = () => {
    setIsMockLoadingFetchApplication(true);
    fetchUnderwritingProfile();
    resetAvailableEndorsements();

    setTimeout(() => {
      setIsMockLoadingFetchApplication(false);
      history.replace({ state: {} });
    }, 4000);
  };

  useEffect(() => {
    if (pageLabel === "get-started") {
      resetUnderwritingProfile();
      resetApplication();
      resetAvailableEndorsements();
      setQuote({});
      setAlternateQuote({});
      updateIndustryName("");
      updateNaic(0);
      setApplicationError(null);
      setQuoteErrors([]);
      setAvailablePolicyTypes([]);
      setBindable(false);
      setSimplybindPaymentFrequency(PaymentFrequency.Monthly);
    }

    if (formotiv) {
      if (!formotivInstance.config) {
        formotivInstance.createInstance();
      }
      formotivInstance.init(agentId, pageLabel, applicationId, underwritingProfileId);
      return () => {
        formotivInstance.submit();
      };
    }
  }, [pageLabel, formotiv]);

  useEffect(() => {
    if (applicationId && applicationId !== underwritingProfile.legacyApplication?.applicationId) {
      if (isLegacyQuote && pageLabel === "confirm") return;
      fetchUnderwritingProfileWithDelay();
    }
  }, [applicationId]);

  /* TODO: fix UnderwritingProfile type so that it contains all expected response items and replace 'any' */
  const updateUnderwritingProfileAndApplication = useCallback(
    (resultsUnderwritingProfile: any) => {
      if (resultsUnderwritingProfile?.legacyApplication) {
        updateUnderwritingProfile(resultsUnderwritingProfile);
        setApplicationFromServer(resultsUnderwritingProfile.legacyApplication);
        setAvailablePolicyTypes(resultsUnderwritingProfile?.availableCoverages);
        setQuote(resultsUnderwritingProfile?.quoteSummary ?? {});

        if (resultsUnderwritingProfile?.legacyApplication.policyStartDate) {
          const policyStartDate = resultsUnderwritingProfile?.legacyApplication.policyStartDate;

          if (checkValidDate(policyStartDate)) {
            setIsValidPolicyStartDate(true);
          } else {
            setIsValidPolicyStartDate(false);
          }
        }

        mapSimplybindEndorsementDetails(
          {
            ...resultsUnderwritingProfile?.legacyApplication.endorsements,
            ...(resultsUnderwritingProfile?.legacyApplication.limitDamagePremRented && {
              limitDamagePremRented: resultsUnderwritingProfile?.legacyApplication.limitDamagePremRented,
            }),
          },
          resultsUnderwritingProfile.endorsementDetails,
          updateUnderwritingEndorsementDetails
        );

        if (resultsUnderwritingProfile?.recommendedEndorsements) {
          updateAvailableEndorsements(
            mapSimplyBindAvailableEndorsements(resultsUnderwritingProfile?.recommendedEndorsements)
          );
        }
        updateIndustryName(resultsUnderwritingProfile?.industryName);
        updateNaic(resultsUnderwritingProfile?.naic);
        setTwoFourLimitsAvailable(resultsUnderwritingProfile?.twoFourLimitsAvailable);
        const quoteErrors =
          resultsUnderwritingProfile?.validationErrors?.map((e: any) => ({
            message: e.message,
            invalidFields: e.invalidFields,
          })) ?? [];
        setQuoteErrors(quoteErrors);
        setBindable(!resultsUnderwritingProfile?.validationErrors);
      }
    },
    [
      setApplicationFromServer,
      setAvailablePolicyTypes,
      setQuote,
      setIsValidPolicyStartDate,
      updateAvailableEndorsements,
      updateIndustryName,
      updateNaic,
      setTwoFourLimitsAvailable,
      updateUnderwritingEndorsementDetails,
      updateUnderwritingProfile,
      setBindable,
      setQuoteErrors,
    ]
  );

  const handleShowErrorPage = useCallback(() => {
    history.push("/simplybind/error");
  }, [history]);

  useEffect(() => {
    if (getUnderwritingProfileIsSuccess) {
      if (shouldRedirectToIndustryConfirmation(underwritingProfileResponse.appliedActions)) {
        if (underwritingProfileResponse.industrySuggestions) {
          history.push("/simplybind/industry");
        } else {
          //sad path
          history.push("/simplybind/new-industry");
        }
        updateUnderwritingProfile(underwritingProfileResponse);
      } else {
        updateUnderwritingProfileAndApplication(underwritingProfileResponse);
      }

      if (
        (underwritingProfileResponse?.legacyApplication?.applicationTypes?.includes("BOP") ||
          underwritingProfileResponse?.legacyApplication?.applicationTypes?.includes("GL")) &&
        underwritingProfileResponse?.legacyApplication?.lossControl === "NotSet"
      ) {
        updateApplicationForm({ lossControl: "OptIn" });
        setShowLossControlBridging(true);
      }

      updateApplicationForm({ forceMiddleware: null });
    }
  }, [
    getUnderwritingProfileIsSuccess,
    underwritingProfileResponse,
    history,
    updateApplicationForm,
    updateUnderwritingProfile,
    updateUnderwritingProfileAndApplication,
  ]);

  useEffect(() => {
    if (underwritingProfileError) {
      handleShowErrorPage();
    }
  }, [underwritingProfileError, handleShowErrorPage]);

  const handleIndustryConfirmationError = (statusCode: number) => {
    switch (statusCode) {
      case 409:
        history.push("/simplybind/not-eligible");
        break;
      case 500:
        handleShowErrorPage();
        break;
    }
  };

  const handleConfidenceDataError = (statusCode: number) => {
    if (statusCode === 400) {
      handleShowErrorPage();
    }
  };

  const handleUpdateUnderwritingProfileError = (statusCode: number) => {
    setLoadingUnderwritingProfile(false);
    switch (statusCode) {
      case 409:
        history.push("/simplybind/coverage-unavailable");
        break;
      case 500:
        handleShowErrorPage();
        break;
    }
  };

  useEffect(() => {
    if (applicationNetworkError) handleUpdateUnderwritingProfileError(applicationNetworkError);
  }, [applicationNetworkError]);

  const { callback: createUnderwritingProfile, loading: loadingCreateUnderwritingProfile } = useAPI(
    "createUnderwritingProfile",
    [],
    {
      skipErrorMessageStatusCodes: [409],
      onSuccess: (res) => {
        updateUnderwritingProfile(res);
        history.push("/simplybind/industry");
      },
      onError: (err) => {
        if (err.response.status === 409) {
          updateUnderwritingProfile(err.response.data);
          history.push("/simplybind/new-industry");
        }
      },
    }
  );

  const { callback: confirmUnderwritingProfile, loading: loadingConfirmUnderwritingProfile } = useAPI(
    "confirmUnderwritingProfile",
    [underwritingProfileId],
    {
      skipErrorMessageStatusCodes: [415, 409],
      onSuccess: (res) => {
        updateUnderwritingProfile(res);
        getConfidenceData();
      },
      onError: (err) => handleIndustryConfirmationError(err.response?.status),
    }
  );

  const { callback: getConfidenceData, loading: loadingGetConfidenceData } = useAPI(
    "getUnderwritingProfileConfidenceData",
    [underwritingProfileId],
    {
      skipErrorMessageStatusCodes: [409, 400],
      onSuccess: (res) => {
        updateUnderwritingProfile(res);
        setAvailablePolicyTypes(res?.availableCoverages);
        updateApplicationForm(res?.legacyApplication);
        history.push("/simplybind/business-details");
      },
      onError: (err) => handleConfidenceDataError(err.response?.status),
    }
  );

  const { loading: loadingUpdateUnderwritingProfile, callback: updateUnderwritingProfileCallback } = useAPI(
    "updateUnderwritingProfile",
    [],
    {
      skipErrorMessageStatusCodes: [409, 400, 304],
      onSuccess: (res) => {
        if (!isEmpty(res)) {
          if (pageLabel === "business-details") {
            history.push(`/simplybind/quote/${application?.applicationId}`, { from: "/business-details" });
          }
          updateUnderwritingProfileAndApplication(res);
        }
        updateApplicationForm({ forceMiddleware: null });
        setLoadingUnderwritingProfile(false);
        setApplicationError(null);
      },
      onError: (err) => {
        if (err.response?.data) updateUnderwritingProfileAndApplication(err.response.data);
        handleUpdateUnderwritingProfileError(err.response?.status);
        setLoadingUnderwritingProfile(false);
      },
    }
  );

  const handleCallbackNameAndAddress = (payload: NameAndAddressType) => {
    createUnderwritingProfile(
      showSelectCoverage ? { ...payload, desiredPolicyType, bppCoverage, buildingCoverage } : payload
    );
  };

  const handleCallbackIndustryConfirmation = (payload: IndustrySuggestion) => {
    setIndustry(payload);
    updateIndustryName(payload.description);
    confirmUnderwritingProfile({ coterieHashId: payload.simpleCoterieHashId });
  };

  const handleCallbackIndustryInput = (industryName: string, akHash: string) => {
    updateIndustryName(industryName);
    confirmUnderwritingProfile({ akHash: akHash });
  };

  const selectDefaultPolicyTypes = () => {
    let defaultSelectedPolicyTypes = [];
    if (underwritingProfile?.availableCoverages.includes("PL")) {
      defaultSelectedPolicyTypes.push("PL");
    }
    if (underwritingProfile?.availableCoverages.includes("BOP")) {
      defaultSelectedPolicyTypes.push("BOP");
    } else if (underwritingProfile?.availableCoverages.includes("GL")) {
      defaultSelectedPolicyTypes.push("GL");
    }
    return defaultSelectedPolicyTypes;
  };

  const setSelectedCoverage = (availableCoverages: string[], desiredPolicyType: string[]): string[] => {
    const desired = desiredPolicyType.filter((type) => {
      return availableCoverages.includes(type);
    });

    if (desired.length) {
      return desired;
    }

    return availableCoverages.filter((type) => {
      if (type === "GL" && availableCoverages.includes("BOP")) return false;
      return true;
    });
  };

  const handleCallbackBusinessDetailsEdit = (payload: any) => {
    const shouldAddDesiredCoverage =
      useSelectedCoverage && desiredPolicyType && desiredPolicyType.length > 0
        ? {
            applicationTypes: setSelectedCoverage(
              underwritingProfile.availableCoverages,
              desiredPolicyType as string[]
            ),
          }
        : {};

    setIsMockLoadingUpdateApplication(true);

    updateUnderwritingProfileCallback({
      ...underwritingProfile,
      legacyApplication: applicationValidator({
        ...underwritingProfile.legacyApplication,
        ...{
          grossAnnualSales: payload.grossAnnualSales,
          annualPayroll: payload.annualPayroll,
          numEmployees: payload.numEmployees,
          businessName: payload.businessName,
          dba: payload.dba,
          businessAgeInMonths: payload.businessAgeInMonths,
          locations: [
            {
              ...underwritingProfile.legacyApplication.locations[0],
              locationType: payload.locationType,
              squareFootage: payload.squareFootage,
            },
          ],
          applicationTypes: selectDefaultPolicyTypes(),
          policyStartDate: new Date(),
          ...shouldAddDesiredCoverage,
        },
      }),
    });
    setTimeout(() => {
      setIsMockLoadingUpdateApplication(false);
    }, 6000);
  };

  const handleQuotePayCallback = () => {
    history.push(`/simplybind/confirm/${application?.applicationId}`);
  };

  const shouldShowGenericLoader =
    isMockLoadingFetchApplication && (location.state as LocationState)?.from !== "/business-details";

  const genericLoadingData = { headerText: "Hold on! We're fetching this application.", steps: [] };

  if (
    loadingGetUnderwritingProfile ||
    isMockLoadingFetchApplication ||
    isMockLoadingUpdateApplication ||
    loadingCreateUnderwritingProfile ||
    loadingConfirmUnderwritingProfile ||
    loadingGetConfidenceData ||
    loadingUpdateUnderwritingProfile
  ) {
    return (
      <ContainerWrapper>
        <Container>
          <LoadingScreen
            isLoading={
              isMockLoadingFetchApplication ||
              loadingCreateUnderwritingProfile ||
              loadingConfirmUnderwritingProfile ||
              loadingGetConfidenceData ||
              loadingUpdateUnderwritingProfile ||
              false
            }
            loadingData={
              shouldShowGenericLoader
                ? genericLoadingData
                : pageHeaderTextLoading.find((headerData: any) => pageLabel === headerData.route)
            }
          />
        </Container>
      </ContainerWrapper>
    );
  }

  if (showLossControlBridging) {
    return (
      <ContainerWrapperWhite>
        <Container>
          <BridgingLossControlScreen setShowLossControlBridging={setShowLossControlBridging} />
        </Container>
      </ContainerWrapperWhite>
    );
  }

  if (["error", "not-eligible", "not-province-eligible", "prof-liab-decline"].includes(pageLabel)) {
    return (
      <ContainerWrapper>
        <Container>
          <ErrorScreen pageLabel={pageLabel} />
        </Container>
      </ContainerWrapper>
    );
  }

  if (pageLabel === "coverage-unavailable") {
    return (
      <ContainerWrapper isError>
        <Container>
          <DeclinationScreen />
        </Container>
      </ContainerWrapper>
    );
  }

  if (["quote", "confirm"].includes(pageLabel))
    return (
      <ContainerWrapper>
        {pageLabel === "quote" && <QuoteEdits history={history} callback={handleQuotePayCallback} />}
        {pageLabel === "confirm" && <BindConfirmation match={match} />}
      </ContainerWrapper>
    );

  const shouldShowProgressIndicator = [1, 2, 3].includes(pageId);

  return (
    <ContainerWrapper>
      <Container>
        <PageHeaderContainer>
          {shouldShowProgressIndicator && <ProgressIndicator current={pageId} callback={setProgress} />}
          <HeaderTextContainer>
            <StyledHeader3>{headerTextObject(isSadPath)[pageLabel]?.headerText}</StyledHeader3>
            {headerTextObject(isSadPath)[pageLabel]?.subHeaderText && (
              <PageHeaderSubTextContainer>
                <PageHeaderSubText>{headerTextObject(isSadPath)[pageLabel]?.subHeaderText}</PageHeaderSubText>
                {headerTextObject(isSadPath)[pageLabel]?.toolTipContent && (
                  <ToolTipIcon contentKey={headerTextObject(isSadPath)[pageLabel]?.toolTipContent} />
                )}
              </PageHeaderSubTextContainer>
            )}
          </HeaderTextContainer>
        </PageHeaderContainer>
        <ContentContainer>
          {pageLabel === "get-started" && <NameAndAddress callback={handleCallbackNameAndAddress} />}
          {pageLabel === "industry" && (
            <IndustryConfirmation
              savedIndustry={industry}
              callback={handleCallbackIndustryConfirmation}
              history={history}
            />
          )}
          {pageLabel === "new-industry" && (
            <IndustryInput
              isSadPath={isSadPath}
              callback={handleCallbackIndustryInput}
              handleShowErrorPage={() => handleShowErrorPage()}
            />
          )}
          {pageLabel === "business-details" && (
            <BusinessDetails
              callbackEdit={handleCallbackBusinessDetailsEdit}
              history={history}
              handleShowErrorPage={() => handleShowErrorPage()}
            />
          )}
        </ContentContainer>
      </Container>
    </ContainerWrapper>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  underwritingProfile: selectUnderwritingProfile(state),
  underwritingProfileId: selectUnderwritingProfileId(state),
  application: selectApplicationForm(state),
  applicationNetworkError: selectApplicationNetworkError(state),
  industrySuggestions: selectUnderwritingProfileIndustrySuggestions(state),
  bppCoverage: selectApplicationFormBppCoverage(state),
  buildingCoverage: selectApplicationFormBuildingCoverage(state),
  desiredPolicyType: selectApplicationFormDesiredPolicyType(state),
});

const mapDispatchToProps = {
  updateUnderwritingProfile,
  resetUnderwritingProfile,
  updateUnderwritingProfileId,
  setLoadingUnderwritingProfile,
  updateApplicationForm,
  setApplicationFromServer,
  updateIndustryName,
  updateNaic,
  setTwoFourLimitsAvailable,
  setQuote,
  resetApplication,
  resetAvailableEndorsements,
  updateAvailableEndorsements,
  setApplicationError,
  setQuoteErrors,
  setAvailablePolicyTypes,
  setBindable,
  setSimplybindPaymentFrequency,
  updateUnderwritingEndorsementDetails,
  setIsValidPolicyStartDate,
  setAlternateQuote,
};

const ContainerWrapper = styled.div<{ isError?: boolean }>`
  background: white;
  height: calc(100% - 104px);
  margin: 24px;
  background-image: url(${(props) => (props.isError ? redCircle : greenCircle)});
  background-repeat: no-repeat;
  background-position: center top;
  border-radius: 4px 4px 0 0;
`;

const ContainerWrapperWhite = styled.div`
  background: white;
  height: calc(100% - 104px);
  margin: 24px;
  border-radius: 4px 4px 0 0;
`;

const Container = styled.div`
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${ultrawide} {
    margin: 0;
    max-width: 1200px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  margin-top: 32px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(SimplyBindContainer);
