import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { History } from "history";
import { GreenButton, Input, Dropdown, mobile } from "styles";
import { Container, CTAButtonContainer } from "../styles";
import { formatNumberLong } from "utils/formatNumber";
import {
  selectApplicationFormBusinessName,
  selectApplicationFormDba,
  selectApplicationFormLocation,
  selectIndustryName,
  selectApplicationFormSales,
  selectApplicationFormPayroll,
  selectApplicationFormEmployees,
  selectApplicationFormLocationType,
  selectApplicationFormSquareFootage,
  selectApplicationFormBusinessAge,
} from "redux/selectors/application";
import { locationOptions } from "shared/application/edit/Coverages/BOPCoverages";
import formotivInstance from "utils/formotiv";

interface PropTypes {
  callbackEdit: (payload: any) => void;
  history: History;
  businessName: string;
  dba?: string;
  businessAgeInMonths?: number;
  address: Address;
  industryName: string;
  annualPayroll: number;
  grossAnnualSales: number;
  numEmployees?: number;
  locationType: string;
  squareFootage?: number;
  handleShowErrorPage: () => void;
}

interface BusinessDataType {
  businessName: string;
  dba?: string;
  address: string;
  industry: string;
  grossAnnualSales: number;
  annualPayroll: number;
  numEmployees?: number;
  businessAge?: number;
  locationType: string;
  squareFootage: string;
}

const BusinessDetails = ({
  callbackEdit,
  businessName,
  dba,
  businessAgeInMonths,
  address,
  industryName,
  grossAnnualSales,
  annualPayroll,
  numEmployees,
  locationType,
  squareFootage,
}: PropTypes) => {
  const businessAgeInYears = businessAgeInMonths ? Math.round(businessAgeInMonths / 12) : undefined;
  const streetAddress = `${address.street}, ${address.city}, ${address.state}, ${address.zip}`;
  const [touchedInputs, setTouchedInputs] = useState<string[]>([]);
  const [businessData, setBusinessData] = useState<BusinessDataType>({
    businessName: businessName,
    dba: dba,
    industry: industryName,
    address: streetAddress,
    grossAnnualSales: grossAnnualSales,
    annualPayroll: annualPayroll,
    numEmployees: numEmployees,
    businessAge: businessAgeInYears,
    squareFootage: formatNumberLong(squareFootage),
    locationType: locationType || "Home",
  });

  const businessNameRef = useRef<HTMLInputElement | null>(null);

  const validateBusinessAge = (value: number | string | undefined) =>
    Boolean(Number(value) <= 270 && Number(value) >= 0);

  useEffect(() => {
    businessNameRef.current?.focus();
  }, []);

  const updateTouchedInputs = (name: string) => {
    if (!touchedInputs.includes(name)) setTouchedInputs([...touchedInputs, name]);
  };

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    updateTouchedInputs(name);
    setBusinessData({
      ...businessData,
      [name]: value,
    });
  };

  const inputErrorText = (name: string, label?: string) => {
    if (!touchedInputs.includes(name)) return;
    let errorText = "";
    const value = businessData[name as keyof typeof businessData];
    const cannotBeZero = ["squareFootage", "numEmployees", "annualPayroll", "grossAnnualSales"].includes(name);
    const invalidBusinessAge = name === "businessAge" && !validateBusinessAge(value);
    const optionalFields = ["squareFootage", "dba"];
    if (cannotBeZero && (value === "0" || value === 0) && !optionalFields.includes(name)) {
      errorText = `${label} must be greater than 0.`;
    } else if (invalidBusinessAge) {
      errorText = "Enter business age in years from 0-270";
    } else if (!Boolean(value) && !optionalFields.includes(name)) {
      errorText = `${label} is required.`;
    }
    return errorText;
  };

  const anyInputHasError = () => {
    return Object.keys(businessData).some((key) => {
      const value = businessData[key as keyof typeof businessData];
      const cannotBeZero = ["squareFootage", "numEmployees", "annualPayroll", "grossAnnualSales"].includes(key);
      const optionalFields = ["squareFootage", "dba"];
      const valueIsEqualToZeroAndNotAllowedToBe = cannotBeZero && (value === "0" || value === 0);
      const valueIsEqualToZeroNullOrUndefined = value !== "0" && value !== 0 && !value;
      const businessAgeIsNotValid = key === "businessAge" && !validateBusinessAge(value);
      return (
        (valueIsEqualToZeroAndNotAllowedToBe || valueIsEqualToZeroNullOrUndefined || businessAgeIsNotValid) &&
        !optionalFields.includes(key)
      );
    });
  };

  const handleSqFtChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const valueWholePositiveNumber = Math.round(Number(value.replace(/[^0-9.]+/g, "")));
    const formattedValue = formatNumberLong(valueWholePositiveNumber);
    updateTouchedInputs("squareFootage");
    setBusinessData({
      ...businessData,
      squareFootage: formattedValue,
    });
  };

  const handleSalesChange = (values: { floatValue: number }) => {
    const { floatValue } = values;
    updateTouchedInputs("grossAnnualSales");
    setBusinessData({
      ...businessData,
      grossAnnualSales: floatValue,
    });
  };

  const handlePayrollChange = (values: { floatValue: number }) => {
    const { floatValue } = values;
    updateTouchedInputs("annualPayroll");
    setBusinessData({
      ...businessData,
      annualPayroll: floatValue,
    });
  };

  const updateLocationType = (selectedOption: any) => {
    updateTouchedInputs("locationType");
    if (window.formotiv) {
      formotivInstance.trackCustom("business-details-location-type");
    }
    setBusinessData({
      ...businessData,
      locationType: selectedOption.value,
    });
  };

  const setAllInputsAsTouched = () => {
    setTouchedInputs([
      "businessName",
      "address",
      "industry",
      "grossAnnualSales",
      "annualPayroll",
      "numEmployees",
      "businessAge",
      "squareFootage",
      "locationType",
      "dba",
    ]);
  };

  const handleNextClick = () => {
    if (anyInputHasError()) {
      setAllInputsAsTouched();
      return;
    }

    const businessAgeInMonths =
      businessData.businessAge === 0 ? 1 : Math.round((businessData.businessAge as number) * 12);

    callbackEdit({
      businessName: businessData.businessName,
      grossAnnualSales: businessData.grossAnnualSales,
      annualPayroll: businessData.annualPayroll,
      numEmployees: businessData.numEmployees,
      locationType: businessData.locationType,
      squareFootage: businessData.squareFootage === "" ? null : businessData.squareFootage,
      businessAgeInMonths: businessAgeInMonths,
      dba: businessData.dba,
    });
  };

  const handleBusinessAgeChange = (values: { floatValue: number }) => {
    const { floatValue } = values;
    updateTouchedInputs("businessAge");
    setBusinessData({
      ...businessData,
      businessAge: floatValue,
    });
  };

  const handleNumEmployeesChange = (values: { floatValue: number }) => {
    const { floatValue } = values;
    updateTouchedInputs("numEmployees");
    setBusinessData({
      ...businessData,
      numEmployees: floatValue,
    });
  };

  return (
    <Container>
      <CardContainer>
        <Input
          value={businessData.businessName}
          id="business-details-legal-name"
          name="businessName"
          label="Legal Business Name"
          handleChange={handleInputChange}
          inputStyles={{
            marginBottom: `${Boolean(inputErrorText("businessName", "Business Name")) ? "4px" : "16px"}`,
            width: "100%",
          }}
          errorText={inputErrorText("businessName", "Business Name")}
          error={Boolean(inputErrorText("businessName", "Business Name"))}
          toolTipContent="BUSINESS_NAME_SIMPLYBIND"
          placeholder="Enter the legal business name"
          ref={businessNameRef}
        />
        <Input
          value={businessData.dba}
          id="business-details-dba"
          name="dba"
          label="Doing Business As"
          handleChange={handleInputChange}
          isOptional={true}
          inputStyles={{ marginBottom: "16px", width: "100%" }}
          toolTipContent="DOING_BUSINESS_AS_SIMPLYBIND"
        />
        <Input
          value={businessData.address}
          id="business-details-business-address"
          name="address"
          label="Business Address"
          readOnly={true}
          handleChange={() => null}
          inputStyles={{ marginBottom: "16px", width: "100%" }}
        />
        <Input
          value={businessData.industry}
          name="industry"
          id="business-details-industry"
          label="Industry Type"
          readOnly={true}
          handleChange={() => null}
          inputStyles={{ marginBottom: "16px", width: "100%" }}
        />
        <Input
          value={businessData.grossAnnualSales}
          id="business-details-gross-annual-sales"
          name="grossAnnualSales"
          label="Estimated Revenue"
          readOnly={false}
          prefix="$"
          handleChange={handleSalesChange}
          inputStyles={{
            marginBottom: `${Boolean(inputErrorText("grossAnnualSales", "Estimated Revenue")) ? "4px" : "16px"}`,
            width: "100%",
          }}
          errorText={inputErrorText("grossAnnualSales", "Estimated Revenue")}
          error={Boolean(inputErrorText("grossAnnualSales", "Estimated Revenue"))}
          toolTipContent="ANNUAL_SALES"
          decimalSeparator={false}
        />
        <Input
          value={businessData.annualPayroll}
          name="annualPayroll"
          id="business-details-annual-payroll"
          label="Estimated Annual Payroll"
          readOnly={false}
          prefix="$"
          handleChange={handlePayrollChange}
          inputStyles={{
            marginBottom: `${Boolean(inputErrorText("annualPayroll", "Estimated Annual Payroll")) ? "4px" : "16px"}`,
            width: "100%",
          }}
          errorText={inputErrorText("annualPayroll", "Estimated Annual Payroll")}
          error={Boolean(inputErrorText("annualPayroll", "Estimated Annual Payroll"))}
          toolTipContent="ANNUAL_PAYROLL"
          decimalSeparator={false}
        />
        <Input
          type="number"
          id="business-details-num-employees"
          value={businessData.numEmployees}
          name="numEmployees"
          label="Number of Employees"
          readOnly={false}
          handleChange={handleNumEmployeesChange}
          inputStyles={{
            marginBottom: `${Boolean(inputErrorText("numEmployees", "Number of Employees")) ? "4px" : "16px"}`,
            width: "100%",
          }}
          errorText={inputErrorText("numEmployees", "Number of Employees")}
          error={Boolean(inputErrorText("numEmployees", "Number of Employees"))}
          decimalSeparator={false}
        />
        <Input
          type="number"
          id="business-details-business-age"
          value={businessData.businessAge}
          name="businessAge"
          label="Business Age (years)"
          readOnly={false}
          handleChange={handleBusinessAgeChange}
          inputStyles={{
            marginBottom: `${Boolean(inputErrorText("businessAge", "Business Age (years)")) ? "4px" : "16px"}`,
            width: "100%",
          }}
          errorText={inputErrorText("businessAge", "Business Age (years)")}
          error={Boolean(inputErrorText("businessAge", "Business Age (years)"))}
        />
        <Input
          value={businessData.squareFootage}
          id="business-details-primary-location-square-footage"
          name="squareFootage"
          label="Premises Square Footage"
          readOnly={false}
          handleChange={handleSqFtChange}
          inputStyles={{
            marginBottom: `${Boolean(inputErrorText("squareFootage", "Premises Square Footage")) ? "4px" : "16px"}`,
            width: "100%",
          }}
          errorText={inputErrorText("squareFootage", "Premises Square Footage")}
          error={Boolean(inputErrorText("squareFootage", "Premises Square Footage"))}
        />
        <Dropdown
          id="business-details-location-type"
          label="Premises Type"
          name="locationType"
          onChange={updateLocationType}
          options={locationOptions}
          value={businessData.locationType}
          full={true}
        />
      </CardContainer>
      <CTAButtonContainer>
        <GreenButton aria-disabled={anyInputHasError()} onClick={handleNextClick}>
          Confirm
        </GreenButton>
      </CTAButtonContainer>
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  businessName: selectApplicationFormBusinessName(state),
  dba: selectApplicationFormDba(state),
  businessAgeInMonths: selectApplicationFormBusinessAge(state),
  address: selectApplicationFormLocation(state),
  industryName: selectIndustryName(state),
  grossAnnualSales: selectApplicationFormSales(state),
  annualPayroll: selectApplicationFormPayroll(state),
  numEmployees: selectApplicationFormEmployees(state),
  locationType: selectApplicationFormLocationType(state),
  squareFootage: selectApplicationFormSquareFootage(state),
});

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 62px;
  width: 100%;
  padding: 0 10% 5% 10%;
  ${mobile} {
    grid-template-columns: 1fr;
  }
`;

export default connect(mapStateToProps)(BusinessDetails);
