import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { reportError } from "utils/reportError";
import Api from "utils/api";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const getFormQuestions = async (apiVersion: string, token: Token, catagoryId: string) => {
  const api = new Api(apiVersion, token);

  return await api.selfService.getAllQuestions(catagoryId).catch((error) => {
    reportError(error);

    return Promise.reject(error);
  });
};

type Options = { enabled?: boolean };

const useGetFormQuestions = (catagoryId: string, options?: Options) => {
  const { apiVersion } = useFeatureFlags();
  const token = useSelector((state: ReduxState) => state.token.token);
  return useQuery({
    queryKey: ["form_questions", catagoryId],
    queryFn: () => getFormQuestions(apiVersion, token, catagoryId),
    retry: 1,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export default useGetFormQuestions;
