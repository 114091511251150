import React, { Component } from "react";
import styled from "styled-components";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";

interface StateTypes {
  name: string;
  street: string;
  city: string;
  zip: string;
  state: string;
  email: string;
}

interface PropTypes {
  additionalInsureds: AdditionalInsured[];
  addAdditionalInsured: (additionalInsured: AdditionalInsured) => boolean;
  removeAdditionalInsured: (additionalInsured: AdditionalInsured) => boolean;
}

export default class AdditionalInsureds extends Component<PropTypes, StateTypes> {
  state = {
    name: "",
    street: "",
    city: "",
    zip: "",
    state: "",
    email: "",
  };
  addLocation = ({ city, street, state, zip }: StateTypes) => {
    this.setState({ city, street, state, zip });
  };

  submitAdditionalInsured = () => {
    const insured: AdditionalInsured = {
      name: this.state.name,
      street: this.state.street,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      email: this.state.email,
      description: "",
    };

    this.props.addAdditionalInsured(insured);
    this.setState({
      name: "",
      street: "",
      city: "",
      zip: "",
      state: "",
      email: "",
    });
  };

  deleteAddlIns = (insured: AdditionalInsured) => {
    this.props.removeAdditionalInsured(insured);
  };

  render() {
    const { additionalInsureds } = this.props;
    const { city, street, state, zip, name, email } = this.state;
    return (
      <div>
        {additionalInsureds.map((insured) => (
          <ExistingAddlIns data-cy="additional-insured" onClick={() => this.deleteAddlIns(insured)} key={insured.name}>
            {insured.name} {insured.email} <br />
            <small>{`${insured.street} ${insured.city} ${insured.state} ${insured.zip}`}</small>
          </ExistingAddlIns>
        ))}

        <h4 id="additionalInsuredTitle">New Additional Insured</h4>
        <AdditionalInsuredForm>
          <InputCont>
            <InputGroup>
              <InputGroupAddon addonType="prepend" id="nameLabel">
                Name
              </InputGroupAddon>
              <Input
                width="full"
                name="name"
                value={name}
                onChange={(e) => this.setState({ name: e.currentTarget.value })}
                data-cy="additional-insured-name"
                aria-labelledby="additionalInsuredTitle nameLabel"
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend" id="emailLabel">
                Email
              </InputGroupAddon>
              <Input
                name="email"
                value={email}
                onChange={(e) => this.setState({ email: e.currentTarget.value })}
                data-cy="additional-insured-email"
                aria-labelledby="additionalInsuredTitle emailLabel"
              />
            </InputGroup>
          </InputCont>

          <InputCont>
            <InputGroup>
              <InputGroupAddon addonType="prepend" id="streetLabel">
                Street
              </InputGroupAddon>
              <Input
                width="half"
                name="street"
                value={street}
                onChange={(e) => this.setState({ street: e.currentTarget.value })}
                data-cy="additional-insured-street"
                aria-labelledby="additionalInsuredTitle streetLabel"
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend" id="cityLabel">
                City
              </InputGroupAddon>
              <Input
                width="half"
                name="city"
                value={city}
                onChange={(e) => this.setState({ city: e.currentTarget.value })}
                data-cy="additional-insured-city"
                aria-labelledby="additionalInsuredTitle cityLabel"
              />
            </InputGroup>
          </InputCont>
          <InputCont>
            <InputGroup>
              <InputGroupAddon addonType="prepend" id="stateLabel">
                State
              </InputGroupAddon>
              <Input
                width="half"
                name="state"
                value={state}
                maxLength={2}
                onChange={(e) => this.setState({ state: e.currentTarget.value })}
                data-cy="additional-insured-state"
                aria-labelledby="additionalInsuredTitle stateLabel"
              />
            </InputGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend" id="zipLabel">
                Zip
              </InputGroupAddon>
              <Input
                width="half"
                name="zip"
                value={zip}
                onChange={(e) => this.setState({ zip: e.currentTarget.value })}
                data-cy="additional-insured-zip"
                aria-labelledby="additionalInsuredTitle zipLabel"
              />
            </InputGroup>
          </InputCont>
        </AdditionalInsuredForm>

        <Button onClick={this.submitAdditionalInsured} data-cy="add-additional-insured">
          ADD
        </Button>
      </div>
    );
  }
}

const InputCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
`;
const Button = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  background-color: green;
  color: white;
  font-size: 20px;
`;
const ExistingAddlIns = styled.h4`
  margin: 5px 0;
  padding: 5px 0;
  :hover {
    cursor: pointer;
    background-color: red;
    color: white;
  }
`;

const AdditionalInsuredForm = styled.div`
  display: flex;
  flex-direction: column;

  .input-group-prepend {
    width: 65px;
    background-color: ${(props) => props.theme.labelGray};
  }

  .input-group:first-child {
    margin-right: 5px;
  }
`;
