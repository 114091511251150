import { useEffect, useState } from "react";
import { FormGroup, Label, Input as ReactStrapInput } from "reactstrap";
import { Input, ParagraphSmall } from "styles";
import { isBefore } from "date-fns";
import styled from "styled-components";

interface ClaimDetailsProps {
  updateFormData: (arg0: string, arg1: any) => void;
  formData?: ClaimFormData;
  dateOfLoss: string;
  incidentTime: string;
}

const ClaimDetails = ({ updateFormData, formData, dateOfLoss, incidentTime }: ClaimDetailsProps) => {
  const [lossOccurrenceDate, setLossOccurrenceDate] = useState<string>(
    formData?.claimData?.lossOccurrenceDate || dateOfLoss || ""
  );
  const [lossOccurrenceTime, setLossOccurrenceTime] = useState<string>(
    formData?.claimData?.lossOccurrenceTime || incidentTime || "12:00"
  );
  const [lossOccurrenceDateTime, setLossOccurrenceDateTime] = useState<Date>(
    new Date(`${lossOccurrenceDate} ${lossOccurrenceTime}`)
  );
  const [claimReportDate, setClaimReportDate] = useState<string>(
    formData?.claimData?.lossOccurrenceDate || dateOfLoss || ""
  );
  const [claimReportTime, setClaimReportTime] = useState<string>("12:00");
  const [claimReportDateTime, setClaimReportDateTime] = useState<Date>(
    new Date(`${claimReportDate} ${claimReportTime}`)
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");

  useEffect(() => {
    const claimObject = {
      incidentType: "Claim",
      lossOccurrenceDate,
      lossOccurrenceTime,
    };
    updateFormData("claim", claimObject);
  }, [lossOccurrenceDate, lossOccurrenceTime]);

  useEffect(() => {
    setLossOccurrenceDateTime(new Date(`${lossOccurrenceDate} ${lossOccurrenceTime}`));
  }, [lossOccurrenceDate, lossOccurrenceTime]);

  useEffect(() => {
    setClaimReportDateTime(new Date(`${claimReportDate} ${claimReportTime}`));
  }, [claimReportDate, claimReportTime]);

  useEffect(() => {
    checkDates();
  }, [lossOccurrenceDateTime, claimReportDateTime]);

  const checkDates = () => {
    if (isBefore(claimReportDateTime, lossOccurrenceDateTime)) {
      setIsError(true);
      setErrorText("Can not report loss before it happens. Please add a correct date.");
    } else {
      setIsError(false);
      setErrorText("");
    }
  };

  return (
    <FormContainer>
      <h2>Claim Details</h2>

      <Input
        label="Date of Loss"
        name="lossOccurrenceDate"
        type="date"
        value={lossOccurrenceDate}
        handleChange={(event) => setLossOccurrenceDate(event.target.value)}
        placeholder="Loss Occurrence Date"
        required
        inputStyles={{ marginBottom: "16px" }}
      />
      <FormGroup>
        <Label for="Loss Occurrence Time">Time of Loss</Label>
        <ReactStrapInput
          type="time"
          name="Loss Occurrence Time"
          value={lossOccurrenceTime}
          onChange={(event) => setLossOccurrenceTime(event.target.value)}
        />
      </FormGroup>

      <Input
        label="Report of Loss Date"
        name="claimReportDate"
        type="date"
        value={claimReportDate}
        handleChange={(event) => setClaimReportDate(event.target.value)}
        placeholder="Claim Report Date"
        required
        inputStyles={{ marginBottom: "16px" }}
      />
      <FormGroup>
        <Label for="Report of Claim Time">Loss Report Time</Label>
        <ReactStrapInput
          type="time"
          name="Claim Report Time"
          value={claimReportTime}
          onChange={(event) => setClaimReportTime(event.target.value)}
        />
      </FormGroup>
      {isError && <ErrorText>{errorText}</ErrorText>}
    </FormContainer>
  );
};

const ErrorText = styled(ParagraphSmall)`
  color: red;
  margin-bottom: 16px;
`;

const FormContainer = styled.div`
  min-width: 550px;
  padding: 0 25px;
`;

export default ClaimDetails;
