import React from "react";
import styled, { DefaultTheme, useTheme } from "styled-components";
import { ViewContainer } from "styles/containerStyledComponents";
import StatusSymbol from "shared/StatusSymbol";
import { tablet, mobile, Paragraph, GreenButton } from "styles";
import { formatInTimeZone } from "date-fns-tz";
import { formatCurrency } from "utils/formatNumber";
import { PolicyTypeChip, PolicyTypeChipTypes, StatusChip } from "coterie-ui-library";
import useFees from "hooks/useFees";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface PropTypes {
  policies: Policy[];
  initialPolicy: any;
}

const ConfirmationDetails = ({ policies, initialPolicy }: PropTypes) => {
  const { showLossControl } = useFeatureFlags();

  const { businessName, status, dba, contactEmail } = policies[0];

  const { month1Fees, totalYearlyFees } = useFees(true);
  const isMonthly = policies[0].paymentInterval === "Monthly";
  const premiumPaid = policies.reduce((acc, obj) => acc + obj.premiumPaid, 0);
  const feesPaid = isMonthly ? month1Fees : totalYearlyFees;
  const totalPaidToday = premiumPaid + feesPaid;

  const policyNumbers = policies.map((p) => p.policyNumber);

  const hasControlFee = Boolean(initialPolicy?.fees?.find((fee: Fee) => fee?.feeType === "LossControlFee")?.amount > 0);
  const hasLossControl = showLossControl && hasControlFee;
  const theme: DefaultTheme = useTheme();

  return (
    <ViewContainer data-cy="policy-confirmation">
      <TitleContainer>
        <BusinessName>
          {businessName}
          {dba && ` DBA ${dba}`}
        </BusinessName>
        <StatusSymbol expanded status={status} />
      </TitleContainer>
      <ContainerContainer>
        <LeftContainer>
          <ThankYouBar>
            <ThankYouWords>Thank you for your business!</ThankYouWords>
          </ThankYouBar>
          <ContentContainer>
            <SummarySubtitle>Policy Summary</SummarySubtitle>
            {policies.map((policy: Policy) => {
              const isMonthly = policy.paymentInterval === "Monthly";
              return (
                <PolicyInfoContainer>
                  <PolicyInfoRow>
                    <LeftSeparator>
                      <PolicyChipWrapper>
                        <PolicyTypeChip name={PolicyTypeChipTypes[policy.policyType as "BOP" | "GL" | "PL"]} />
                      </PolicyChipWrapper>
                      <PolicyNumber>#{policy.policyNumber}</PolicyNumber>
                    </LeftSeparator>
                    <RightSeparator>
                      <StatusChip
                        color={theme.colors?.tertiary?.zero}
                        backgroundColor={theme.colors?.neutral?.special}
                        text={isMonthly ? "MONTHLY BILLING" : "YEARLY BILLING"}
                      />
                    </RightSeparator>
                  </PolicyInfoRow>
                  <PolicyInfoRow>
                    <LeftSeparator>
                      <PaymentItem>Policy Start Date</PaymentItem>
                    </LeftSeparator>
                    <RightSeparator>
                      <PaymentItem>{
                        policy.startDate
                          ? formatInTimeZone(new Date(policy.startDate), "GMT", "MMMM dd, yyyy")
                          : "..."
                        }</PaymentItem>
                    </RightSeparator>
                  </PolicyInfoRow>
                  <PolicyInfoRow>
                    <LeftSeparator>
                      <PaymentItem>{isMonthly ? "Monthly Premium Paid" : "Policy Premium"}</PaymentItem>
                    </LeftSeparator>
                    <RightSeparator>
                      <PaymentItem>
                        {formatCurrency(policy.premiumPaid)}
                        {isMonthly && "/mo"}
                      </PaymentItem>
                    </RightSeparator>
                  </PolicyInfoRow>
                  <PolicyInfoRow>
                    <LeftSeparator>
                      <PaymentItem>{isMonthly ? "Next Payment Date" : "Paid in full"}</PaymentItem>
                    </LeftSeparator>
                    <RightSeparator>
                      {isMonthly && (
                        <PaymentItem>{
                          policy.nextPaymentDate
                            ? formatInTimeZone(new Date(policy.nextPaymentDate), "GMT", "MMMM dd, yyyy")
                            : "..."
                        }</PaymentItem>
                      )}
                    </RightSeparator>
                  </PolicyInfoRow>
                  {isMonthly && (
                    <PolicyInfoRow>
                      <PaymentItemNote>
                        Note: future monthly payments will be subject to additional fees
                      </PaymentItemNote>
                    </PolicyInfoRow>
                  )}
                </PolicyInfoContainer>
              );
            })}
            <PaymentSummaryContainer>
              <SummarySubtitle>Payment Summary</SummarySubtitle>
              <PolicyInfoRow>
                <LeftSeparator>
                  <PaymentItem>Premium Paid</PaymentItem>
                </LeftSeparator>
                <RightSeparator>
                  <PaymentItem>{formatCurrency(premiumPaid)}</PaymentItem>
                </RightSeparator>
              </PolicyInfoRow>
              {Boolean(feesPaid) && (
                <PolicyInfoRow>
                  <LeftSeparator>
                    <PaymentItem>Fees Paid</PaymentItem>
                  </LeftSeparator>
                  <RightSeparator>
                    <PaymentItem>{formatCurrency(feesPaid as number)}</PaymentItem>
                  </RightSeparator>
                </PolicyInfoRow>
              )}
              <PolicyInfoRow>
                <LeftSeparator>
                  <TotalAmountPaidTitle>Total Amount Paid Today</TotalAmountPaidTitle>
                </LeftSeparator>
                <RightSeparator>
                  <TotalAmountPaid>{formatCurrency(totalPaidToday)}</TotalAmountPaid>
                </RightSeparator>
              </PolicyInfoRow>
            </PaymentSummaryContainer>
          </ContentContainer>
        </LeftContainer>
        <RightContainer>
          <ContentContainer>
            <SummarySubtitle>Here's what happens next:</SummarySubtitle>
            <OrderedList>
              <ListItem>
                We'll send policy documentation to the policyholder's email: <DarkGray>{contactEmail}</DarkGray>. You
                are only responsible for delivering documents if their email is not <DarkGray>{contactEmail}</DarkGray>.
              </ListItem>
              <ListItem>Click below to view policy details to grab the policy documents.</ListItem>
              {hasLossControl && (
                <ListItem>
                  Within 24-48 business hours, your client will receive an email on how to access Coterie's Manage My
                  Risk portal and resources.
                </ListItem>
              )}
            </OrderedList>
            <PolicyDetailsButton data-testid="policy-details" href={`/policy?policyIds=${policyNumbers}`}>
              View Policy Details
            </PolicyDetailsButton>
            <PaymentItem>
              For policy issues, email{" "}
              <BlueText target="_blank" rel="noopener noreferrer" href="mailto:support@coterieinsurance.com">
                support@coterieinsurance.com
              </BlueText>
              . For payment specific questions,{" "}
              <BlueText target="_blank" rel="noopener noreferrer" href="mailto:payments@coterieinsurance.com">
                payments@coterieinsurance.com
              </BlueText>
              .
            </PaymentItem>
          </ContentContainer>
        </RightContainer>
      </ContainerContainer>
    </ViewContainer>
  );
};

const TitleContainer = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  grid-column-gap: 8px;

  ${tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ContainerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const LeftContainer = styled.div`
  background-color: white;
  flex: 1 1 0px;
  max-width: 600px;
  min-width: 450px;
  margin-right: 24px;
  margin-bottom: 24px;
`;
const RightContainer = styled(LeftContainer)`
  margin-right: 0;
`;

const ContentContainer = styled.div`
  background-color: white;
  padding: 30px 40px 110px 30px;

  display: flex;
  flex-direction: column;
  grid-column-gap: 48px;
`;

const BusinessName = styled.h1`
  color: ${(props) => props.theme.pageComponents.shared.confirmationPage.title.color}};
  font-family: ${(props) => props.theme.pageComponents.shared.confirmationPage.title.font};
  font-size: 24px;
  margin: auto 0;
  font-weight: 600;
`;
const ThankYouBar = styled.div`
  background-color: ${(props) => props.theme.pageComponents.shared.confirmationPage.thankYou.cardBackgroundColor};
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
`;

const ThankYouWords = styled.h2`
  color: ${(props) => props.theme.pageComponents.shared.confirmationPage.thankYou.fontColor};
  font-family: ${(props) => props.theme.pageComponents.shared.confirmationPage.thankYou.font};
  font-size: 22px;
  font-weight: 600;
  margin: 15px 0 auto 27px;
`;

const SummarySubtitle = styled.h2`
color: ${(props) => props.theme.pageComponents.shared.confirmationPage.title.color}};
font-family: ${(props) => props.theme.pageComponents.shared.confirmationPage.title.font};
  font-size: 16px;
  font-weight: 600;
  margin: auto 0 20px 0;
`;

const PolicyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PolicyInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 8px 0;
`;

const PolicyInfoRowSeparator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 150px;
`;

const LeftSeparator = styled(PolicyInfoRowSeparator)`
  justify-content: flex-start;
`;
const RightSeparator = styled(PolicyInfoRowSeparator)`
  justify-content: flex-end;
  ${mobile} {
    justify-content: flex-start;
  }
`;

const PolicyChipWrapper = styled.div`
  margin-right: 16px;
`;

const PolicyNumber = styled(SummarySubtitle)`
  margin: 0;
`;
const PaymentItem = styled(Paragraph)``;

const PaymentItemNote = styled(PaymentItem)`
  font-size: 13px;
`;

const PaymentSummaryContainer = styled(ContentContainer)`
  border-top: ${(props) => props.theme.pageComponents.shared.confirmationPage.summarySeparator};
  margin-top: 60px;
  padding: 20px 0 0 0;
`;

const TotalAmountPaidTitle = styled(SummarySubtitle)`
  margin: auto 0;
`;
const TotalAmountPaid = styled(BusinessName)``;

const OrderedList = styled.ol`
  padding-left: 14px;
  margin: 0;
`;

const ListItem = styled.li`
  margin-bottom: 16px;
  font-size: 15px;
  font-weight: 400;
  font-family: ${(props) => props.theme.pageComponents.shared.confirmationPage.listItems.font};
  color: ${(props) => props.theme.pageComponents.shared.confirmationPage.listItems.color};
`;

const PolicyDetailsButton = styled(GreenButton)`
  width: 201px;
  margin-bottom: 24px;
`;

const BlueText = styled.a`
  color: ${(props) => props.theme.pageComponents.shared.confirmationPage.emailColors.supportEmail};
`;

const DarkGray = styled.span`
  color: ${(props) => props.theme.pageComponents.shared.confirmationPage.emailColors.userEmails};
`;

export default ConfirmationDetails;
