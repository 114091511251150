import React from "react";
import { StyledHeader3, CTAButtonContainer, HeaderTextContainer, PageHeaderContainer } from "../styles";
import { GreenButton, TextLink } from "styles";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

interface ErrorScreenProps {
  pageLabel: string;
}

interface ErrorTextData {
  headerText: string;
  subText?: string;
}

interface ErrorText {
  [key: string]: ErrorTextData;
}

export const errorData = (): ErrorText => {
  return {
    "not-eligible": {
      headerText: `This business' primary operation is not within Coterie's current risk appetite.`,
      subText: "We are continuously updating our eligibility and appreciate your feedback.",
    },
    "coverage-unavailable": {
      headerText: `We evaluated this business and based on the specific risk characteristics and exposures to loss, it does not meet our underwriting guidelines for the following reasons:`,
    },
    "not-province-eligible": {
      headerText: "This business' location is not within the current geographic appetite.",
      subText:
        "Based on the business information entered, this business is not within the current appetite. If you would like to start a new quote for another business, please start over using the new quote button.",
    },
    "prof-liab-decline": {
      headerText: "Declined For Professional Liability Policy",
      subText:
        "We declined this business for a Professional Liability Policy but it may be eligible for a Business Owners Policy (BOP). Please submit a new quote if you'd like to try a BOP.",
    },
    error: { headerText: "It looks like something went wrong." },
  };
};

const ErrorScreen = ({ pageLabel }: ErrorScreenProps) => {
  const errorInfo = errorData();
  const headerText = errorInfo[pageLabel].headerText;
  const subHeaderText = errorInfo[pageLabel].subText;
  const history = useHistory();
  return (
    <>
      <PageHeaderContainer>
        <HeaderTextContainer>
          <StyledHeader3>{headerText}</StyledHeader3>
        </HeaderTextContainer>
        <SubTextContainer>
          {subHeaderText && <SubText>{subHeaderText}</SubText>}
          {pageLabel !== "error" && pageLabel !== "prof-liab-decline" && (
            <ResourceText>
              Visit{" "}
              <SubTextLink
                as="a"
                href={`https://${process.env.REACT_APP_DOMAIN}/producer-resources`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`https://${process.env.REACT_APP_DOMAIN}/producer-resources`}
              </SubTextLink>{" "}
              for details about Coterie's speed, simplicity, and service. We look forward to your next submission!
            </ResourceText>
          )}
        </SubTextContainer>
      </PageHeaderContainer>

      <CTAButtonContainer>
        <GreenButton
          onClick={() => {
            history.push("/simplybind/get-started");
          }}
        >
          New Quote
        </GreenButton>
      </CTAButtonContainer>
    </>
  );
};

const SubText = styled.p`
  max-width: 696px;
  font-size: 18px;
  font-family: ${(props) => props.theme.secondaryFont};
  color: ${(props) => props.theme.gray};
  text-align: center;
`;

const SubTextContainer = styled.div`
  margin-top: 55px;
`;

const ResourceText = styled(SubText)`
  margin-bottom: 25px;
`;

const SubTextLink = styled(TextLink)`
  font-size: 18px;
  font-weight: 400;
`;

export default ErrorScreen;
