import { CancelFile, CancellationForm } from "./types";
import * as yup from "yup";

export const cancelRequestSchema: yup.ObjectSchema<CancellationForm> = yup.object({
  additionalInformation: yup.string().when("reason", {
    is: (value: { value: string }) => value?.value === "CR",
    then: () => yup.string().required("This field is required").max(255, "Maximum of 255 characters"),
    otherwise: () => yup.string().optional(),
  }),
  reason: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .nullable()
    .required("Cancellation reason is required"),
  isNonRenew: yup.string().oneOf(["true", "false"]).required("This field is required"),
  cancelDate: yup.date().nullable().required("This field is required"),
  files: yup
    .array(yup.mixed<CancelFile>().required())
    .test("invalid-files", "Please remove any invalid files", (file) => file?.every((f) => f.status === "valid"))
    .min(1, "Please attach the required documents")
    .required("This field is required"),
});
