import React from "react";
import { GhostButton } from "shared/SimplyBind/styles";
import { ReactComponent as FilterIcon } from "images/filter.svg";
import { ReactComponent as SelectedFilterIcon } from "images/filter_selected.svg";
import styled from "styled-components";

interface FilterButtonProps {
  onClick: () => void;
  isSelected: boolean;
}
const FilterButton = ({ onClick, isSelected }: FilterButtonProps) => {
  return (
    <StyledGhostButton
      onClick={onClick}
      isSelected={isSelected}
      aria-label={isSelected ? "Close Filter Options" : "Open Filters Options"}
    >
      <span aria-hidden>
        {isSelected ? <StyledSelectedFilterIcon width={12} height={12} /> : <StyledFilterIcon width={12} height={12} />}
      </span>
      Filters
    </StyledGhostButton>
  );
};

const StyledFilterIcon = styled(FilterIcon)`
  margin-right: 8px;
  path {
    fill: ${(props) => props.theme.textNavyBlue};
  }
`;

const StyledSelectedFilterIcon = styled(SelectedFilterIcon)`
  margin-right: 8px;
  path {
    fill: ${(props) => props.theme.white};
  }
`;

const StyledGhostButton = styled(GhostButton)<{ isSelected: boolean }>`
  transition: none;
  :hover {
    ${(props) =>
      !props.isSelected &&
      `background: transparent;
       color: inherit;`}
  }
  ${(props) =>
    props.isSelected &&
    `background-color: ${props.theme.buttonHover};
     color: ${props.theme.buttonTextHover}`}
`;

export default FilterButton;
