import React from "react";
import styled from "styled-components";
import { RequoteRequired, ReadyToBind, PendingCompletion, StyledStatus } from "shared/application/StyledComponents";
import ReadyImg from "images/ready.svg";
import PendingImg from "images/pending.svg";
import { ReactComponent as Exclamation } from "images/exclamation.svg";
import { tablet } from "styles";

interface PropTypes {
  isReady: boolean;
  shouldResponsiveShrink?: boolean;
  isLegacyQuote?: boolean;
}

const ApplicationStatusDisplay = ({ isReady, isLegacyQuote, shouldResponsiveShrink = false }: PropTypes) => {
  if (isReady) {
    return (
      <ReadyToBind shouldResponsiveShrink={shouldResponsiveShrink}>
        <StyledStatus src={ReadyImg} alt="checkmark" noPaddingTop={true} />
        <P shouldResponsiveShrink={shouldResponsiveShrink}>READY TO BIND</P>
      </ReadyToBind>
    );
  } else if (!isReady && isLegacyQuote) {
    return (
      <RequoteRequired shouldResponsiveShrink={shouldResponsiveShrink}>
        <Exclamation />
        <P shouldResponsiveShrink={shouldResponsiveShrink}>REQUOTE REQUIRED</P>
      </RequoteRequired>
    );
  } else {
    return (
      <PendingCompletion shouldResponsiveShrink={shouldResponsiveShrink}>
        <StyledStatus src={PendingImg} alt="three dots" />
        <P shouldResponsiveShrink={shouldResponsiveShrink}>PENDING COMPLETION</P>
      </PendingCompletion>
    );
  }
};

const P = styled.p<{ shouldResponsiveShrink: boolean }>`
  ${tablet} {
    display: ${(props) => (props.shouldResponsiveShrink ? "none" : "block")};
  }
`;

export default ApplicationStatusDisplay;
