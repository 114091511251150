import axios from "axios";
import { CoterieAPIVersion } from "types/enums";
import { coterieAPIBaseURL } from "utils/api";

export const portalApi = (apiVersion: string, token: Token) =>
  axios.create({
    baseURL: `${process.env.REACT_APP_PORTAL_API_BASE_URL}/${apiVersion}`,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

export const coterieAPI = () =>
  axios.create({
    baseURL: coterieAPIBaseURL(CoterieAPIVersion.v1),
    headers: {
      Authorization: "token f6f8a0bc-ae66-4e92-87a8-ffcdd6e0afa5",
    },
  });

export const coterieAPI_v1_2 = () =>
  axios.create({
    baseURL: coterieAPIBaseURL(CoterieAPIVersion.v1_2),
    headers: {
      Authorization: "token f6f8a0bc-ae66-4e92-87a8-ffcdd6e0afa5",
    },
  });
export const coterieAPI_v1_4 = () =>
  axios.create({
    baseURL: coterieAPIBaseURL(CoterieAPIVersion.v1_4),
    headers: {
      Authorization: "token f6f8a0bc-ae66-4e92-87a8-ffcdd6e0afa5",
    },
  });
export const underwritingAPI = () =>
  axios.create({
    baseURL: process.env.REACT_APP_UW_RULES_ENGINE_API_BASE_URL,
    headers: {
      Authorization: "token a3e88b39-b569-44ef-aa80-d136f58dcb6b",
      "x-api-key": "a3e88b39-b569-44ef-aa80-d136f58dcb6b",
    },
  });
