import React, { useState } from "react";
import styled from "styled-components";
import { CardBody } from "reactstrap";
import { connect } from "react-redux";
import { Header4 } from "styles";
import { Wrapper } from "styles/IAStyledComponents";
import EditAgencyModal from "./EditAgencyModal";
import { getUser } from "redux/reducers/_userReducer";
import { selectUserAgency } from "redux/selectors/user";
import AgencyLogo from "./AgencyLogo";
import ProducersTable from "./ProducersTable";
import Field from "shared/Field";
import ClipboardInput from "shared/ClipboardInput";
import { Row, EditButton } from "shared/application/StyledComponents";
import { ReactComponent as EditIcon } from "images/edit.svg";
import useAPI from "hooks/useAPI";
import useAuth from "hooks/useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import MyDigitalAgencyLink from "./MyDigitalAgencyLink";

interface PropTypes {
  agency: AgencyType;
  getUser: (auth: APIVersionAuth) => void;
}

const Agency = ({ agency, getUser }: PropTypes) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const { getToken, isAgency, isChannelPartner } = useAuth();

  const { apiVersion, showAgencyLogoUpload: showAgencyLogoUploadFlag, showMyDigitalAgency } = useFeatureFlags();
  const agencyId = String(agency?.id) ?? "";
  const showLogoUpload = isAgency && agencyId && showAgencyLogoUploadFlag;

  const onSuccess = async () => {
    const token = await getToken();
    getUser({ apiVersion, token });
    setShowEditModal(false);
  };

  const { callback, loading: editing } = useAPI("updateAgency", [agency?.id], {
    onSuccess,
    successMessage: "Account updated successfully!",
  });

  const { loading, results } = useAPI("getChannelPartner", [agency?.channelPartnerId], {
    waitFor: isAgency,
  });

  const handleSubmit = (agencyUpdates: AgencyType) => {
    const updatedAgency = Object.assign({}, agency, { ...agencyUpdates });
    callback(updatedAgency);
  };

  const displayModal = () => {
    setShowEditModal(true);
  };

  const closeModal = () => {
    setShowEditModal(false);
  };

  const mailingAddress = `${agency?.mailingAddressStreet ?? ""} ${agency?.mailingAddressCity ?? ""} ${
    agency?.mailingAddressState ?? ""
  } ${agency?.mailingAddressZip ?? ""}`;

  const quoteFlowUrl = process.env.REACT_APP_QUOTE_FLOW_URL;

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ width: "44%" }}>
          <Row>
            <Header4 data-cy="agency-page-title">Agency Information</Header4>
            {isAgency && !loading && (
              <EditButton aria-label="edit icon" data-cy="edit-account-info" onClick={displayModal}>
                <EditIconWrapper>
                  <EditIcon title="edit icon" />
                </EditIconWrapper>
              </EditButton>
            )}
          </Row>
          <Row>
            <Wrapper data-cy="agency-info-table">
              <Row>
                <StyledCardBody>
                  <Field value={agency?.name} label="Agency Name" aria-label="Agency Name" />
                  <Field value={agency?.contactPhone} label="Phone Number" aria-label="Agency Contact Phone" />
                  <Field value={mailingAddress} label="Mailing Address" aria-label="Agency Mailing Address" />
                  {isAgency && !loading && !showMyDigitalAgency && (
                    <MagicLinkContainer>
                      <ClipboardInput
                        label="Quick Quote"
                        value={`${quoteFlowUrl}/agency/${agency?.externalId}/${results.publishableKey}`}
                        tooltipContentKey="AGENCY_LINK"
                      />
                    </MagicLinkContainer>
                  )}
                </StyledCardBody>
              </Row>
              <EditAgencyModal
                isLoading={editing}
                closeModal={closeModal}
                modalIsOpen={showEditModal}
                {...agency}
                save={handleSubmit}
              />
            </Wrapper>
          </Row>
        </div>
        <div style={{ width: "44%" }}>{showLogoUpload && <AgencyLogo agencyId={agencyId} />}</div>
      </div>
      {showMyDigitalAgency && <MyDigitalAgencyLink />}
      {(isChannelPartner || isAgency) && <ProducersTable />}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  agency: selectUserAgency(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getUser: (auth: APIVersionAuth) => dispatch(getUser(auth)),
});

const EditIconWrapper = styled.div`
  svg {
    fill: ${(props) => props.theme.blue};
  }
`;

const MagicLinkContainer = styled.div`
  position: relative;
  > span {
    position: absolute;
    right: 2px;
    top: 22px;
  }
`;

const StyledCardBody = styled(CardBody)`
  padding: 0;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Agency);
