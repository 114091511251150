import { Title } from "styles/containerStyledComponents";
import { formatCurrency } from "utils/formatNumber";
import StatusSymbol from "shared/StatusSymbol";
import styled from "styled-components";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";
import ActionsDropdown from "styles/ActionsDropdown";
import { useHistory } from "react-router-dom";
import { FormCategory } from "types/enums";
import { useSelector } from "react-redux";
import { selectProducerExternalId } from "redux/selectors/user";

interface ActivePolicyTitleProps {
  selectedPolicy: Policy;
  openCOIModal: () => void;
  openCancelModal: () => void;
  openLossRunModal: () => void;
}

const ActivePolicyTitle = ({
  selectedPolicy,
  openCOIModal,
  openCancelModal,
  openLossRunModal,
}: ActivePolicyTitleProps) => {
  const isMonthlyInterval = selectedPolicy?.paymentInterval === "Monthly";
  const producerGuid = useSelector((state: any) => selectProducerExternalId(state));
  const {
    showPolicyActions,
    showCancelPolicyAction,
    submitChangeRequestUrl,
    showlossrun,
    showAddAdditionalInsureds,
    showAddEndorsementsForm,
    showUpdatePolicyInformation,
    showCancels,
  } = useFeatureFlags();
  const url = new URL(window.location.href);
  const policyIdsParam = url.searchParams.get("policyIds");
  const policyIds = policyIdsParam ? policyIdsParam.split(",") : [];
  const history = useHistory();

  const prioritizePolicyNumber = (policyIds: Array<string>, selectedPolicyNumber: string) => {
    const uniquePolicyIds = Array.from(new Set([selectedPolicyNumber, ...policyIds]));
    uniquePolicyIds.join(",");
    return uniquePolicyIds;
  };
  prioritizePolicyNumber(policyIds, selectedPolicy.policyNumber);

  const goToFormQuestions = (questionsCatagory: string) => {
    history.push(`/policyEdits/${questionsCatagory}/${prioritizePolicyNumber(policyIds, selectedPolicy.policyNumber)}`);
  };

  // TODO: Will be used once the new cancel policy page is ready.
  const goToPolicyCancel = () => {
    history.push(`/policy/cancel/${selectedPolicy.policyNumber}?referrer=/policy?policyIds=${policyIdsParam}`);
  };

  const isNonLicensedUser = producerGuid === "";

  const dropdownActions: ActionsType[] = [
    {
      name: "Submit Change Request",
      action: () => window.open(submitChangeRequestUrl, "_blank"),
      hide: !submitChangeRequestUrl,
    },
    {
      name: "Add Additional Insureds",
      action: () => goToFormQuestions(FormCategory.AdditionalInsured),
      hide: !showAddAdditionalInsureds || isNonLicensedUser,
    },
    {
      name: "Add Endorsements",
      action: () => goToFormQuestions(FormCategory.Endorsement),
      hide: !showAddEndorsementsForm || isNonLicensedUser,
    },
    {
      name: "Create COI",
      action: () => openCOIModal(),
      hide: selectedPolicy?.status !== "Active" && selectedPolicy?.status !== "Pending",
    },
    {
      name: "Update Policy Information",
      action: () => goToFormQuestions(FormCategory.PolicyInformation),
      hide: !showUpdatePolicyInformation || isNonLicensedUser,
    },
    {
      name: "Cancel Policy",
      action: () => openCancelModal(),
      hide:
        (selectedPolicy?.status !== "Active" && selectedPolicy?.status !== "Pending") ||
        !showCancelPolicyAction ||
        showCancels,
    },
    {
      name: "Request Policy Cancel",
      action: () => goToPolicyCancel(),
      hide:
        (selectedPolicy?.status !== "Active" && selectedPolicy?.status !== "Pending") ||
        !showCancelPolicyAction ||
        !showCancels,
    },
    {
      name: "Loss Run Request",
      action: () => openLossRunModal(),
      hide: !showlossrun,
    },
  ];

  const hasDBA = Boolean(selectedPolicy?.dba);

  return selectedPolicy?.policyNumber ? (
    <PolicyHeaderContainer>
      <PolicyTitle>
        <Title hasStatus data-cy="policy-view-header-business-name">
          {selectedPolicy.businessName}
        </Title>
        <StatusSymbol expanded status={selectedPolicy.status} />
      </PolicyTitle>
      {hasDBA && (
        <PolicyHolderName data-cy="policy-view-header-subtitle">{`DBA ${selectedPolicy.dba}`}</PolicyHolderName>
      )}
      <PolicyHeaderDetails data-cy="policy-view-header-premium">
        <Amount>
          {isMonthlyInterval ? formatCurrency(selectedPolicy.premium / 12) : formatCurrency(selectedPolicy.premium)}
        </Amount>
        <Unit>PER {isMonthlyInterval ? "MONTH" : "YEAR"}</Unit>
        {showPolicyActions && <ActionsDropdown actions={dropdownActions} policyView={true} />}
      </PolicyHeaderDetails>
    </PolicyHeaderContainer>
  ) : null;
};

export default ActivePolicyTitle;

const PolicyHeaderContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.pageComponents.ia.policyTitle.bottomBorder};
  margin-bottom: 32px;
`;

const PolicyTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 16px;
`;

const PolicyHolderName = styled.span`
  color: ${({ theme }) => theme.pageComponents.ia.dbaTitle.color};
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.33px;
`;

const PolicyHeaderDetails = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 16px 0;
`;

const Amount = styled.span`
  font-size: 36px;
  font-weight: 600;
  color: ${(props) => props.theme.blue};
`;

const Unit = styled.span`
  color: ${({ theme }) => theme.pageComponents.ia.yearMonthInterval.color};
  font-weight: 600;
  margin: 8px;
  letter-spacing: 1.33px;
`;
