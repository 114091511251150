import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const usePostLogoToImgur = (setLogoSrc: any, setImageUploadError: any) => {
  const isProd =
    window.location.hostname === `kit.${process.env.REACT_APP_DOMAIN}` ||
    window.location.hostname === `dashboard.${process.env.REACT_APP_DOMAIN}`;

  // This is a temp api until the portal API is complete and must not be used in production
  const imgurAuthHeader = isProd ? "nonsense!" : "Client-ID cbd853cee3496c5";

  const uploadEndpoint = "https://api.imgur.com/3/image";

  const mutationFn = (file: File) => {
    const data = new FormData();
    data.append("image", file, file.name);
    const config = {
      headers: {
        Authorization: imgurAuthHeader,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    return axios
      .post(uploadEndpoint, data, config)
      .then((res) => {
        setLogoSrc(res?.data?.data?.link);
      })
      .catch((e) => {
        console.error(e);
        setImageUploadError();
      });
  };

  const mutation = useMutation({ mutationFn });

  return mutation;
};

export default usePostLogoToImgur;
