import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import Api from "utils/api";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

const putChannelPartnerEdits = (
  apiVersion: string,
  token: Token,
  id: number,
  channelPartnerUpdate: UpdateChannelPartnerRequest
): Promise<ChannelPartner> => {
  const api = new Api(apiVersion, token);

  return api.channelPartners.update(id, channelPartnerUpdate);
};

export default function usePutChannelPartner(id: number) {
  const token = useSelector((state: ReduxState) => state.token.token);
  const { apiVersion } = useFeatureFlags();

  const mutationFn = (channelPartnerData: UpdateChannelPartnerRequest) => {
    return putChannelPartnerEdits(apiVersion, token, id, channelPartnerData);
  };

  return useMutation({ mutationFn });
}
