import { useMutation } from "@tanstack/react-query";
import Api from "utils/api";
import useAuth from "./useAuth";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

type RequestPayloadType = {
  policyNumber: string;
  apiData: OneTimePaymentPayload;
};

type OneTimePaymentChargeOptions = {
  onSuccess: (response: any) => void;
  onError: (response: any) => void;
};

const useOneTimePaymentCharge = ({ onSuccess, onError }: OneTimePaymentChargeOptions) => {
  const { getToken } = useAuth();
  const { apiVersion } = useFeatureFlags();

  const postOneTimePaymentCharge = async (requestPayload: RequestPayloadType) => {
    const token = await getToken();
    const api = new Api(apiVersion, token);

    return api.addOneTimePaymentCharge(requestPayload);
  };

  return useMutation({
    mutationFn: postOneTimePaymentCharge,
    onError,
    onSuccess,
  });
};

export default useOneTimePaymentCharge;
