import { Role, AffiliatedStatus } from "types/enums";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "utils/api";
import { useFeatureFlags } from "toggle_tools/featureFlagTools";

interface UserState {
  loading: boolean;
  error?: string;
  email: string;
  givenName: string;
  id: string;
  role: Role; //TODO: remove later
  roles: Role[];
  userName: string;
  account?: AccountType;
  channelPartner?: ChannelPartner;
  agency?: AgencyType;
  affiliatedStatus?: AffiliatedStatus;
}

const initialState = {
  loading: false,
  error: undefined,
  email: "",
  givenName: "",
  id: "",
  role: Role.User,
  roles: [Role.User],
  userName: "",
  account: undefined,
  channelPartner: undefined,
  agency: undefined,
  affiliatedStatus: AffiliatedStatus.NonAffiliated,
};

const getCurrentUser = createAsyncThunk<CoterieUserIdentity, any>("users/current", async (auth: APIVersionAuth) => {
  const { apiVersion, token } = auth;
  const api = new Api(apiVersion, token);
  return (await api.getCurrentUser()) as CoterieUserIdentity;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    unsetUser: {
      reducer: (state: UserState | undefined, { payload }) => {
        state = undefined;
      },
      prepare: (user?: CoterieUserIdentity) => {
        return { payload: user, meta: {}, error: {} };
      },
    },
    setAffiliatedStatus: (state, { payload }) => {
      state.affiliatedStatus = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.pending, (state: UserState) => {
        state.loading = true;
      })
      .addCase(getCurrentUser.fulfilled, (state: UserState, { payload = {} }) => {
        state.loading = false;
        state.email = payload.email || "";
        state.givenName = payload.givenName || "";
        state.id = payload.id || "";
        state.role = payload.role;
        state.roles = payload.roles ?? [payload.role];
        state.userName = payload.userName || "";
        state.account = payload.account;
        state.channelPartner = payload.channelPartner;
        state.agency = payload.agency;
        state.affiliatedStatus = payload.account?.affiliatedStatus;
      });
  },
});

export const getUser = getCurrentUser;
export const { unsetUser, setAffiliatedStatus } = userSlice.actions;
export default userSlice.reducer;
