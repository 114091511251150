import { lightFormat, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Input } from "styles";
import { policyIDParser } from "utils/policyIDParser";

interface PolicyDetailsProps {
  policyID: string;
  formData?: ClaimFormData;
  updateFormData: (arg0: string, arg1: any) => void;
  policy: Policy;
}

const PolicyDetails = ({ policyID, formData, updateFormData, policy }: PolicyDetailsProps) => {
  const { policyType, policyCarrier } = policyIDParser(policyID);
  const coverageTrigger = policyType !== "PL" ? "occurrence" : "claimsMade";

  const [businessName, setBusinessName] = useState<string>(formData?.policyData?.businessName || policy.businessName);

  useEffect(() => {
    const policyObject = {
      lineOfBusiness: policyType,
      carrier: policyCarrier,
      businessName,
      policyNumber: policyID,
      policyEffectiveDate: policy.effectiveStartDate,
      policyExpirationDate: policy.effectiveEndDate,
      coverageTrigger,
    };

    updateFormData("policy", policyObject);
  }, [businessName]);

  return (
    <FormContainer>
      <h2>Policy Details</h2>
      <Input
        label="Legal Business Name"
        name="businessName"
        type="text"
        value={businessName}
        handleChange={(event) => setBusinessName(event.target.value)}
        placeholder="Business Name"
        required
        inputStyles={{ marginBottom: "16px" }}
      />
      <p>
        <b>Policy Start Date: </b>{" "}
        {lightFormat(parse(policy.effectiveStartDate, "yyyy-MM-dd", new Date()), "MM/dd/yyyy")}
      </p>
      <p>
        <b>Policy End Date: </b> {lightFormat(parse(policy.effectiveStartDate, "yyyy-MM-dd", new Date()), "MM/dd/yyyy")}
      </p>
      <p>
        <b>Line of Business: </b> {policyType}
      </p>
      <p>
        <b>Coverage Trigger: </b>
        {policyType !== "PL" ? "Occurrence (BOP, GL)" : "Claims Made (PL only)"}
      </p>
      <p>
        <b>Carrier: </b> {policyCarrier}
      </p>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  min-width: 550px;
  padding: 0 25px;
`;

export default PolicyDetails;
