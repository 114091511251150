import React from "react";
import styled from "styled-components";
import cloud_svg from "images/cloud.svg";
import pending_svg from "images/pending.svg";

interface PropTypes {
  handleChange: (argO: React.ChangeEvent<HTMLInputElement>) => void;
  loading?: boolean;
}

const FileUpload = ({ handleChange, loading = false }: PropTypes) => (
  <FileUploadContainer>
    <FileInput
      type="file"
      id="file-upload"
      name="file-upload"
      accept=".xlsx,.xls"
      onChange={handleChange}
      data-cy="file-upload"
      placeholder="file-upload"
    />
    <FileContent>
      {!loading ? (
        <>
          <img src={cloud_svg} alt="cloud" />
          <p>
            Drag & Drop File here <br /> or
          </p>

          <FileLabel data-cy={"file-upload"} htmlFor="file-upload">
            Browse Files
          </FileLabel>
        </>
      ) : (
        <>
          <img src={pending_svg} alt="" />
          <br />
          Uploading
        </>
      )}
    </FileContent>
  </FileUploadContainer>
);

const FileInput = styled.input`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 0;
  opacity: 0;
  cursor: pointer;
`;

const FileUploadContainer = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  background: #fff;

  outline: dashed;
  outline-color: #5438ff;
  outline-width: 1px;
  outline-offset: -20px;

  margin: 24px 0;
`;

const FileContent = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  padding-top: 50px;
  pointer-events: none;

  P {
    color: ${(props) => props.theme.buttonText};
    font-family: ${(props) => props.theme.primaryFont};
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
  }
`;

const FileLabel = styled.label`
  color: ${(props) => props.theme.blue};
  font-family: ${(props) => props.theme.primaryFont};
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
`;

export default FileUpload;
