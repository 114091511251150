import React from "react";
import { Header4 } from "styles";
import { connect } from "react-redux";
import styled from "styled-components";
import ClipboardInput from "shared/ClipboardInput";
import { selectUserAccount } from "redux/selectors/user";
import { toastSuccess } from "utils/toast";

interface PropTypes {
  user: AccountType;
}

export const MyDigitalAgencyLink = ({ user }: PropTypes) => {
  return (
    <Container>
      <Title>My Digital Agency</Title>
      <Subtitle>
        Maximize your online sales potential with My Digital Agency, a 24/7 digital quoting solution by Coterie
        Insurance.
      </Subtitle>
      <InfoText>
        Copy this link and strategically place it on your website, email signature, and elsewhere to make it easy for
        potential clients to quote and purchase commercial insurance from you, anytime.
      </InfoText>
      <MagicLinkContainer>
        <ClipboardInput
          labelHeight="40px"
          label="My Digital Agency Link"
          value={`app.coterieinsurance.com/quote?p=${encodeURIComponent(user.email ?? "")}`}
          successOverride={() => {
            toastSuccess("Link copied to clipboard");
          }}
        />
      </MagicLinkContainer>
      <Link href="https://coterieinsurance.com/my-digital-agency/" target="__blank">
        Learn more about My Digital Agency including instructions, default coverages and FAQs.
      </Link>
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  user: selectUserAccount(state),
});

const Container = styled.div`
  max-width: 1121px;
  width: 100%;
  padding: 40px 0;
`;
const Title = styled(Header4)`
  padding: 16px 0;
  margin-bottom: 0px;
  border-top: 1px solid ${(props) => props.theme.lighterGray};
  padding-top: 40px;
`;

const InfoText = styled.p`
  font-family: Questrial;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.neutral.dark};
`;

const Subtitle = styled(InfoText)`
  font-size: 18px;
`;
const MagicLinkContainer = styled.div`
  position: relative;
  width: 489px;
  margin-bottom: 12px;
  > span {
    position: absolute;
    right: 2px;
    top: 22px;
  }
`;

const Link = styled.a`
  font-weight: 600;
`;

export default connect(mapStateToProps)(MyDigitalAgencyLink);
