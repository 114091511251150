import React from "react";
import LogsContainer from "kit/logs/LogsContainer";

const ThirdPartyLogs = () => {
  return (
    <LogsContainer
      apiRequest="logs.getThirdParty"
      headers={["Status", "Service", "Description", "Created On"]}
      title="Third Party Logs"
      pathName="/third-party-logs"
      enableSearch={true}
    />
  );
};

export default ThirdPartyLogs;
