export const policyIDParser = (policyID: string) => {
  let policyType;
  let policyCarrier;

  const re = /TEST/i;

  if (re.test(policyID)) {
    policyID.split("");

    policyID[5] === "B" && (policyType = "BOP");
    policyID[5] === "G" && (policyType = "GL");
    policyID[5] === "P" && (policyType = "PL");

    policyID[4] === "B" && (policyCarrier = "Benchmark Insurance Company");
    policyID[4] === "S" && (policyCarrier = "Spinnaker Insurance Company");
    policyID[4] === "C" && (policyCarrier = "Clear Spring Property & Casualty Company");
  } else {
    policyID.split("");

    policyID[1] === "B" && (policyType = "BOP");
    policyID[1] === "G" && (policyType = "GL");
    policyID[1] === "P" && (policyType = "PL");

    policyID[2] === "B" && (policyCarrier = "Benchmark Insurance Company");
    policyID[2] === "S" && (policyCarrier = "Spinnaker Insurance Company");
    policyID[2] === "C" && (policyCarrier = "Clear Spring Property & Casualty Company");
  }

  return { policyType, policyCarrier };
};

export const carrierParse = (carrier: string) => {
  switch (carrier) {
    case "Spinnaker Insurance Company":
      return "Spinnaker";
    case "Benchmark Insurance Company":
      return "Benchmark";
    case "Clear Spring Property & Casualty Company":
      return "Clear Spring";
    default:
      return carrier;
  }
};
